import { FieldGroup, FieldGroupTypeLabels } from './field-groups.model';
import { FieldGroupsActionTypes, FieldGroupActions } from './field-groups.actions';
import { AppState } from '../app-reducer';
import { createSelector } from '@ngrx/store';
import { orderBy } from 'lodash-es';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';

export interface FieldGroupState {
  loaded: boolean;
  loading: boolean;
  error: string;
  activeFieldGroupId: number;
  db: { [key: number]: FieldGroup };
  pageView: any;
}

const initialState: FieldGroupState = {
  loaded: false,
  loading: false,
  activeFieldGroupId: null,
  error: null,
  db: {},
  pageView: {
    pageIndex: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions,
    total: 0
  }
};

export function fieldGroupsReducer(state = initialState, action: FieldGroupActions) {
  switch (action.type) {
    case FieldGroupsActionTypes.CREATE:
    case FieldGroupsActionTypes.UPDATE:
    case FieldGroupsActionTypes.DELETE:
    case FieldGroupsActionTypes.LOAD_ONE:
    case FieldGroupsActionTypes.LOAD: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case FieldGroupsActionTypes.SET_ACTIVE: {
      const db = { ...state.db };
      delete db[action.payload];
      return {
        ...state,
        db,
        activeFieldGroupId: action.payload,
      };
    }

    case FieldGroupsActionTypes.LOAD_SUCCESS: {
      const data: FieldGroup[] = action.payload.fieldGroups;
      const meta: any = action.payload.meta;
      const fieldGroupsDb = data.reduce((acc, ct) => {
        acc[ct.id] = ct;
        return acc;
      }, {});

      return {
        ...state,
        db: fieldGroupsDb,
        pageView: {
          ...state.pageView,
          total: meta.page.total
        },
        loaded: true,
        loading: false,
        error: null,
      };
    }

    case FieldGroupsActionTypes.CREATE_SUCCESS: {
      const newFieldGroup: FieldGroup = action.payload;
      return {
        ...state,
        activeFieldGroupId: action.payload.id,
        db: { ...state.db, [newFieldGroup.id]: newFieldGroup },
        loading: false,
        error: null,
      };
    }

    case FieldGroupsActionTypes.LOAD_ONE_SUCCESS:
    case FieldGroupsActionTypes.UPDATE_SUCCESS: {
      const newFieldGroup: FieldGroup = action.payload;
      return {
        ...state,
        db: { ...state.db, [newFieldGroup.id]: newFieldGroup },
        loading: false,
        error: null,
      };
    }

    case FieldGroupsActionTypes.DELETE_SUCCESS: {
      if (!action.payload) {
        return { ...state, loading: false };
      }
      const newDb = { ...state.db };
      delete newDb[action.payload];
      return {
        ...state,
        db: newDb,
        loading: false,
        pageView: { ...state.pageView, total: Object.keys(newDb).length }
      };
    }

    case FieldGroupsActionTypes.SET_FIELD_GROUPS_PAGE_VIEW: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        pageView: {
          ...state.pageView,
          pageIndex: pageIndex || 0,
          pageSize: pageSize || state.pageView.pageSize
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getFieldGroupsState = (state: AppState) => state.fieldGroups;
export const getFieldGroups = (state: AppState) => state.fieldGroups.db;
export const getFieldGroupsPageView = (state: AppState) => state.fieldGroups.pageView;

export const getFieldGroupLoading = createSelector(
  getFieldGroupsState,
  (collectionTypeState: FieldGroupState) => collectionTypeState.loading
);

export const geFieldGroupLoaded = createSelector(
  getFieldGroupsState,
  (fieldGroupsState: FieldGroupState) => fieldGroupsState.loaded
);

export const getActiveFieldGroup = createSelector(
  getFieldGroupsState,
  (fieldGroupsState: FieldGroupState) =>
    fieldGroupsState.db[fieldGroupsState.activeFieldGroupId] || null
);

export const getFieldGroupsList = createSelector(
  getFieldGroupsState,
  (fieldGroupsState: FieldGroupState) =>
    Object.values(fieldGroupsState.db)
      .sort((a: any, b: any) => a.updatedAt > b.updatedAt ? -1 : 1)
      .map((fg: FieldGroup) => {
        const fieldDefinitions = fg.fieldDefinitions || [];
        return {
          ...fg,
          typeLabel: FieldGroupTypeLabels[fg.type],
          groupsCount: fieldDefinitions.length,
          fieldsCount: fieldDefinitions.reduce((count, fgd) => count + fgd.fields.length, 0),
        };
      })
);

export const getDefaultFirstSortedFieldGroupsList = createSelector(
  getFieldGroupsList,
  (fieldGroups: FieldGroup[]) => orderBy(fieldGroups, ['defaultType', 'name'], ['desc', 'asc'])
);

export const getFieldGroupById = (id: number) => {
  return createSelector(
    getFieldGroupsState,
    (collectionTypeState: FieldGroupState) => collectionTypeState.db[id] || null
  );
};
