
import {debounceTime} from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { FacebookService } from '../../../core/api/facebook/facebook.service';
import { environment } from '../../../../environments/environment';
import { ScriptInjectorService } from '../../../core/miscellaneous/script-injector.service';
import { BidiService } from '../../../core/i18n/bidi.service';

declare const FB: any;

@Component({
  selector: 'gd-embed-facebook-dialog',
  templateUrl: './embed-facebook-dialog.component.html',
  styleUrls: ['./embed-facebook-dialog.component.scss']
})
export class EmbedFacebookDialogComponent implements OnInit, OnDestroy {
  validatorThrottleTimeout;
  postUrl = '';

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: [null],
    url: ['', Validators.required],
  });

  blockId;

  itemDetails = null;
  safeUrl = null;
  urlSubscription: Subscription;
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  get urlControl() {
    return this.embeddableForm.get('url');
  }

  get idControl() {
    return this.embeddableForm.get('id');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedFacebookDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private facebookService: FacebookService,
    private scriptInjector: ScriptInjectorService,
    private bidiService: BidiService,
  ) { }

  ngOnInit(): void {
    this.urlSubscription = this.urlControl.valueChanges.pipe(debounceTime(200)).subscribe(
      value => {
        if (!value) {
          this.urlControl.setErrors({'required': true});
          this.postUrl = '';
          return this.loadScriptAndInitFB();
        }
        this.facebookService.isFacebookPostUrlValid(value).subscribe(output => {
          if (output) {
            this.postUrl = value;
            this.urlControl.setErrors(null);
            this.idControl.setValue(output.fbPostId);
          } else {
            this.postUrl = '';
            this.urlControl.setErrors({'invalidUrl': true});
            this.idControl.setValue(null);
          }
          return this.loadScriptAndInitFB();
        });
      }
    );

    this.loadScriptAndInitFB();
    this.embeddableForm.patchValue(this.dialogInputData);
    this.blockId = this.dialogInputData.blockId;
  }

  save() {
    const formModel = this.embeddableForm.value;
    this.dialogRef.close({
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel
    });
  }

  initFB(): void {
    FB.init({
      appId: environment.facebookAppId,
      status: true,
      xfbml: true,
      version: 'v3.1'
    });
  }

  // run facebook SDK script to load post
  loadScriptAndInitFB() {
    if (document.getElementById('facebook-sdk')) {
      this.initFB();
      return;
    }
    this.scriptInjector
      .injectScript('https://connect.facebook.net/en_US/sdk.js', { id : 'facebook-sdk' })
      .then(() => this.initFB())
      .catch(() => console.log('Error: Facebook software development kit failed to load'));
  }

  ngOnDestroy(): void {
    this.urlSubscription.unsubscribe();
  }

}
