import { UnsafeAction } from '../unsafe-action.interface';

export const GET_CONTENT_TAGS = '[Content Tags] Get Content Tags';
export class GetContentTagsAction implements UnsafeAction {
  readonly type = GET_CONTENT_TAGS;
  constructor(public payload: any = {}) {}
}

export const GET_CONTENT_TAGS_SUCCESS = '[Content Tags] Get Content Tags success';
export class GetContentTagsSuccessAction implements UnsafeAction {
  readonly type = GET_CONTENT_TAGS_SUCCESS;
  constructor(public payload: any) {}
}

export const GET_CONTENT_TAG = '[Content Tags] Get Content Tag';
export class GetContentTagAction implements UnsafeAction {
  readonly type = GET_CONTENT_TAG;
  constructor(public payload: any) {}
}

export const GET_CONTENT_TAG_SUCCESS = '[Content Tags] Get Content Tag success';
export class GetContentTagSuccessAction implements UnsafeAction {
  readonly type = GET_CONTENT_TAG_SUCCESS;
  constructor(public payload: any) {}
}

export const CREATE_CONTENT_TAG = '[Content Tags] Create Content Tag';
export class CreateContentTagAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_TAG;
  constructor(public payload: any) { }
}

export const CREATE_CONTENT_TAG_SUCCESS = '[Content Tags] Create Content Tag success';
export class CreateContentTagSuccessAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_TAG_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_CONTENT_TAG = '[Content Tags] Update Content Tag';
export class UpdateContentTagAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_TAG;
  constructor(public payload: any) { }
}

export const UPDATE_CONTENT_TAG_SUCCESS = '[Content Tags] Update Content Tag success';
export class UpdateContentTagSuccessAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_TAG_SUCCESS;
  constructor(public payload: any) { }
}

export const DELETE_CONTENT_TAG = '[Content Tags] Delete Content Tag';
export class DeleteContentTagAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_TAG;
  constructor(public payload: number) { }
}

export const DELETE_CONTENT_TAG_SUCCESS = '[Content Tags] Delete Content Tag success';
export class DeleteContentTagSuccessAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_TAG_SUCCESS;
  constructor(public payload: number) { }
}

export const CONTENT_TAG_ACTION_FAILED = '[Content Tags] Generic failure action';
export class ContentTagFailedAction implements UnsafeAction {
  readonly type = CONTENT_TAG_ACTION_FAILED;
  constructor(public payload: any) { }
}
