import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelValueListFormComponent } from './label-value-list-form/label-value-list-form.component';
import { LabelValueEditModalComponent } from './label-value-edit-modal/label-value-edit-modal.component';
import { MaterialModule } from '../../shared/material-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [
    LabelValueListFormComponent,
    LabelValueEditModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  exports: [
    LabelValueListFormComponent,
    LabelValueEditModalComponent
  ]
})
export class LabelValueListModule { }
