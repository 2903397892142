<form [formGroup]="passwordForm"
      (ngSubmit)="submit()"
      novalidate>
  <div fxLayout="column">

    <h2 mat-dialog-title>Session Expired</h2>
    <section *ngIf="!ssoUser; else ssoSection">
      <p>Please enter your password to continue working</p>

      <mat-form-field class="gd-extend-session-dialog__input">
        <mat-label>Password</mat-label>
        <input matInput
               type="password"
               formControlName="password">
        <mat-error *ngIf="passwordForm.get('password').hasError('required')">
          Required
        </mat-error>
        <mat-error
                   *ngIf="passwordForm.get('password').hasError('invalidPassword')">
          Invalid password
        </mat-error>
      </mat-form-field>
    </section>

    <ng-template #ssoSection>
      <section fxLayout="column" fxLayoutGap="20px" style="margin-bottom: 20px">
        <span>Please sign in with SSO to continue working</span>
      </section>
    </ng-template>

    <!-- buttons -->
    <section fxFlex="100%"
             fxLayout="row"
             fxLayoutAlign="flex-end flex-end"
             fxLayoutGap="12px">
      <div class=".gd-extend-session-dialog__loader"
           *ngIf="makingRequest">
        <mat-spinner mode="indeterminate"
                     diameter="30">
        </mat-spinner>
      </div>
      <button *ngIf="!ssoUser; else ssoLogin"
              mat-raised-button
              color="primary"
              type="submit"
              class="gd-change-password-dialog-buttons--save"
              [disabled]="passwordForm.invalid || makingRequest">
        Continue working
      </button>
      <ng-template #ssoLogin>
        <button mat-raised-button
                color="primary"
                type="button"
                fxLayout="row"
                fxLayoutAlign="center"
                (click)="samlLogin()">
          <mat-icon matSuffix
                    class="gd-reset-password__show-hide-icon"
                    fontSet="fal"
                    fontIcon="fa-key"></mat-icon>
          <span>
            Continue with SSO
          </span>
        </button>
      </ng-template>
      <button mat-button
              color="danger"
              (click)="exit()"
              mat-dialog-close>
        Log out
      </button>
    </section>

  </div>
</form>
