<div class="gd-embed-facebook-dialog"
[dir]="dir$ | async">
  <form fxLayout="column" fxLayoutAlign="space-around strech" [formGroup]="embeddableForm" (ngSubmit)="save()" novalidate>
    <h2 mat-dialog-title i18n="Modal headline">Embed Facebook Post</h2>

    <section>
      <mat-form-field class="gd-embed-facebook-dialog__input-post-url"
                      appearance="outline">
        <mat-label i18n>Post URL</mat-label>
        <input matInput type="text" formControlName="url" autocomplete="off">
        <mat-error *ngIf="urlControl.hasError('required')" i18n="Validation message">URL field cannot be blank.</mat-error>
        <mat-error *ngIf="!urlControl.hasError('required') && urlControl.hasError('invalidUrl')" i18n="Validation message">Please insert a valid post URL.</mat-error>
      </mat-form-field>
    </section>

    <section class="gd-embed-facebook-dialog__section-preview">
      <mat-card class="gd-embed-facebook-dialog__post-preview" [class.content-loaded]="postUrl">
        <div class="fb-post" [attr.data-href]="postUrl" data-width="510"></div>
        <div class="gd-embed-facebook-dialog__loader">
            <mat-progress-bar mode="indeterminate" [style.opacity]="postUrl ? 1 : 0"></mat-progress-bar>
        </div>
      </mat-card>
    </section>

    <section class="gd-embed-facebook-dialog__section-buttons">
      <button mat-raised-button color="primary" type="submit" class="gd-embed-facebook-dialog-buttons--save" [disabled]="!postUrl" i18n>Save</button>
      <button mat-raised-button color="danger" mat-dialog-close i18n>Cancel</button>
    </section>

  </form>

</div>
