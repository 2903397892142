<div class="gd-field-array-builder">
  <div class="group" *ngFor="let item of arrayFieldControl.controls;let j = index;">
    <div *ngFor="let nestedField of arrayFieldConfig.fields">
      <gd-field-builder *ngIf="!nestedField.hidden"
                        [fieldConfig]="nestedField"
                        [isEditingMode]="isEditingMode"
                        [fieldControl]="item.get(nestedField.key)"
                        [hasActionPermission]="hasActionPermission"
                        [usage]="usage"
                        [readOnlyMode]="readOnlyMode">
      </gd-field-builder>
    </div>
    <a *ngIf="!readOnlyMode" mat-raised-button color="accent" class="gd-field-array-builder__remove-row-btn" (click)="removeGroupFromArrayFor($event)">X</a>
  </div>
  <a *ngIf="!readOnlyMode" mat-raised-button color="primary" (click)="addGroupToArrayFor($event)">Add {{arrayFieldConfig.label}} group</a>
</div>
