<div class="gd-embed-tiktok-dialog"
[dir]="dir$ | async">
  <form fxLayout="column"
        fxLayoutAlign="space-around strech"
        [formGroup]="embeddableForm"
        (ngSubmit)="save()"
        novalidate>
    <h2 mat-dialog-title i18n="Modal headline">Embed TikTok Post</h2>

    <section>
      <mat-form-field class="gd-embed-tiktok-dialog__input-post-url"
                      appearance="outline">
        <mat-label i18n>Post URL</mat-label>
        <input matInput
               type="text"
               formControlName="url"
               autocomplete="off">
        <mat-error *ngIf="urlControl.hasError('required')" i18n="Validation message">URL field cannot be blank.</mat-error>
        <mat-error *ngIf="!urlControl.hasError('required') && urlControl.hasError('invalidUrl')" i18n="Validation message">Please insert a valid
          post URL.</mat-error>
      </mat-form-field>
    </section>

    <section class="gd-embed-tiktok-dialog__section-preview">
      <mat-card class="gd-embed-tiktok-dialog__post-preview"
                [class.post-loaded]="postLoaded">
        <div id="tiktok-container"
             style="padding: 0 16px"></div>
        <div id="tiktokPostContainer"></div>
      </mat-card>
    </section>

    <section class="gd-embed-tiktok-dialog__section-buttons">
      <button mat-raised-button
              color="primary"
              type="submit"
              class="gd-embed-tiktok-dialog-buttons--save"
              [disabled]="!postLoaded" i18n>Save</button>
      <button mat-raised-button
              color="danger"
              mat-dialog-close i18n>Cancel</button>
    </section>
  </form>
</div>
