import { UnsafeAction } from '../unsafe-action.interface';

export const GET_FILES = '[Files] Get files';
export class GetFilesAction implements UnsafeAction {
  readonly type = GET_FILES;
  constructor(public payload: any) {}
}

export const GET_FILES_COMPLETE = '[Files] Get files completed';
export class GetFilesCompleteAction implements UnsafeAction {
  readonly type = GET_FILES_COMPLETE;
  constructor(public payload: any) {}
}

export const GET_FILES_FAILED = '[Files] Get files failed';
export class GetFilesFailed implements UnsafeAction {
  readonly type = GET_FILES_FAILED;
  constructor(public payload: any) {}
}

export const DELETE_FOLDER = '[Files] Delete folder';
export class DeleteFolderAction implements UnsafeAction {
  readonly type = DELETE_FOLDER;
  constructor(public payload: any) {}
}

export const DELETE_FOLDER_SUCCESS = '[Files] Delete folder success';
export class DeleteFolderSuccessAction implements UnsafeAction {
  readonly type = DELETE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export const DELETE_FILE = '[Files] Delete File';
export class DeleteFileAction implements UnsafeAction {
  readonly type = DELETE_FILE;
  constructor(public payload: any) {}
}

export const CREATE_FOLDER = '[Files] Create folder';
export class CreateFolderAction implements UnsafeAction {
  readonly type = CREATE_FOLDER;
  constructor(public payload: any) {}
}

export const CREATE_FOLDER_SUCCESS = '[Files] Create folder success';
export class CreateFolderSuccessAction implements UnsafeAction {
  readonly type = CREATE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export const CREATE_FOLDER_FAILED = '[Files] Create folder failed';
export class CreateFolderFailedAction implements UnsafeAction {
  readonly type = CREATE_FOLDER_FAILED;
  constructor(public payload: any) {}
}

export const UPDATE_FILE_DETAILS = '[Files] Update file details';
export class UpdateFileDetailsAction implements UnsafeAction {
  readonly type = UPDATE_FILE_DETAILS;
  constructor(public payload: any) {}
}

export const UPDATE_FILE_DETAILS_COMPLETE = '[Files] Update file details completed';
export class UpdateFileDetailsCompletedAction implements UnsafeAction {
  readonly type = UPDATE_FILE_DETAILS_COMPLETE;
  constructor(public payload: any) {}
}


export const UPDATE_FOLDER_DETAILS = '[Files] Update folder details';
export class UpdateFolderDetailsAction implements UnsafeAction {
  readonly type = UPDATE_FOLDER_DETAILS;
  constructor(public payload: any) {}
}

export const UPDATE_FOLDER_DETAILS_COMPLETE = '[Files] Update folder details completed';
export class UpdateFolderDetailsCompletedAction implements UnsafeAction {
  readonly type = UPDATE_FOLDER_DETAILS_COMPLETE;
  constructor(public payload: any) {}
}

export const FILES_ACTION_FAILED = '[Files] Generic failure action';
export class FilesFailedAction implements UnsafeAction {
  readonly type = FILES_ACTION_FAILED;
  constructor(public payload: any) {}
}

export const RESET_FILES_LIST = '[Files] Reset files list';
export class ResetFilesListAction implements UnsafeAction {
  readonly type = RESET_FILES_LIST;
  constructor() {}
}

export const SET_ACTIVE_FOLDER = '[Files] Set active folder';
export class SetActiveFolderAction implements UnsafeAction {
  readonly type = SET_ACTIVE_FOLDER;
  constructor(public payload: any) {}
}

export const SET_FILES_PAGE_VIEW_OPTIONS = '[Files] Set page view options';
export class SetFilesPageViewOptions implements UnsafeAction {
  readonly type = SET_FILES_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}
