import { mergeMap, catchError, map, withLatestFrom, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';

import { CancelSystemNotificationSuccessAction,
  CANCEL_SYSTEM_NOTIFICATION,
  CreateSystemNotificationSuccessAction,
  CREATE_SYSTEM_NOTIFICATION,
  GetSystemNotificationsSuccessAction,
  GET_SYSTEM_NOTIFICATIONS,
  SystemNotificationFailedAction,
  SYSTEM_NOTIFICATION_ACTION_FAILED,
  SetSystemNotificationLoadingFlagAction,
  UpdateSystemNotificationSuccessAction,
  UPDATE_SYSTEM_NOTIFICATION,
  DeleteSystemNotificationSuccessAction,
  DELETE_SYSTEM_NOTIFICATION} from './system-notifications.action';
import { SystemNotificationService } from '../../api/system-notifications/system-notifications.service';

@Injectable()
export class SystemNotificationEffects {

  loadSystemNotifications$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_SYSTEM_NOTIFICATIONS),
    withLatestFrom(this.store.select((state) => state.systemNotifications.systemNotificationView)),
    mergeMap(([action, pageView]: [UnsafeAction, any]) => {
      return this.systemNotificationsService.getSystemNotifications({ ...pageView, ...action.payload }).pipe(
        map((response) => new GetSystemNotificationsSuccessAction(response)),
        catchError((e) => of(new SystemNotificationFailedAction(e)))
      );
    })
  ));


  createSystemNotification$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_SYSTEM_NOTIFICATION),
    mergeMap((action: UnsafeAction) => {
      let systemNotification = action.payload;
      delete systemNotification.id;
      systemNotification = {...systemNotification}
      return this.systemNotificationsService.createSystemNotification(systemNotification).pipe(
        map((data) => new CreateSystemNotificationSuccessAction(data)),
        tap(() => {
          this.snackBar.open($localize`System notification created successfully.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/notifications/system-notifications']);
        }),
        catchError((e) => of(new SystemNotificationFailedAction(e)))
      );
    })
  ));


  cancelSystemNotification$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CANCEL_SYSTEM_NOTIFICATION),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.systemNotificationsService.cancelSystemNotification(id).pipe(
        map(() => new CancelSystemNotificationSuccessAction(id)),
        tap(() => this.snackBar.open($localize`System notification canceled.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Cannot cancel system notification.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new SetSystemNotificationLoadingFlagAction(false));
        })
      );
    })
  ));

  deleteSystemNotification$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_SYSTEM_NOTIFICATION),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.systemNotificationsService.deleteSystemNotification(id).pipe(
        map(() => new DeleteSystemNotificationSuccessAction(id)),
        tap(() => this.snackBar.open($localize`System notification deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Cannot delete system notification.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new SetSystemNotificationLoadingFlagAction(false));
        })
      );
    })
  ));

  updateSystemNotification$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_SYSTEM_NOTIFICATION),
    mergeMap((action: UnsafeAction) => {
      return this.systemNotificationsService.updateSystemNotification(action.payload).pipe(
        map((data) => new UpdateSystemNotificationSuccessAction(data)),
        tap(() => {
          this.router.navigate(['/notifications/system-notifications']);
          this.snackBar.open($localize`System Notification successfully updated.`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update system notification!`, $localize`Close`);
          return of(new SetSystemNotificationLoadingFlagAction(e));
        })
      );
    })
  ));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SYSTEM_NOTIFICATION_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private systemNotificationsService: SystemNotificationService
  ) {}
}
