
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ContentPanel } from '../../store/content-panels/content-panels.model';

@Injectable()
export class ContentPanelsService {
  constructor(
    private rest: RestService
  ) {}

  getAllContentPanels() {
    return this.rest.get('content-panels').pipe(map(response => response.data));
  }

  getContentPanelById(id: number) {
    return this.rest.get('content-panels/' + id).pipe(map(response => response.data));
  }

  createContentPanel(payload: ContentPanel) {
    return this.rest.post('content-panels', payload).pipe(map(response => response.data));
  }

  updateContentPanel(contentPanel: ContentPanel) {
    return this.rest
      .put('content-panels/' + contentPanel.id, contentPanel).pipe(map(response => response.data));
  }

  deleteContentPanel(id: number) {
    return this.rest.delete('content-panels/' + id);
  }

  updateContentPanelsPosition(contentPanels) {
    return this.rest
    .put('content-panels/', contentPanels).pipe(map(response => response.data));
  }
}
