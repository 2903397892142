import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './fury-core/admin/admin.component';
import { AuthGuard } from './core/api';

const routes: Routes = [
  // Unguarded routes
  { path: 'auth', loadChildren: () => import('./+auth-pages/auth-pages.module').then(m => m.AuthPagesModule) },
  // Guarded routes
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
      { path: 'misc', loadChildren: () => import('./+misc/misc.module').then(m => m.MiscModule) },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class RoutingModule { }
