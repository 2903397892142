<div class="favorites-container" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="row">
    <!-- remove matTooltip from button to enable drag & drop -->
    <button *ngFor="let favorite of currentFavorites" class="favorite" type="button" mat-icon-button [routerLink]="[favorite.route]"
            [matTooltip]="favorite.name" matTooltipPosition="below">
      <mat-icon *ngIf="favorite.icon && !favorite.isCustomSvgIcon" fontSet="fal" fontIcon="{{ favorite.icon }}"></mat-icon>
      <mat-icon *ngIf="favorite.icon && favorite.isCustomSvgIcon" svgIcon="{{ favorite.icon }}"></mat-icon>
      <span class="letter-icon" *ngIf="!favorite.icon">{{ favorite.name.charAt(0).toUpperCase() }}</span>
    </button>
  </div>
  <button class="add-favorite" type="button" mat-icon-button
          matTooltip="Add/Remove favourites" i18n-matTooltip
          matTooltipPosition="below"
          [matMenuTriggerFor]="favoriteMenu" (onMenuClose)="clearSearchInput()">
    <mat-icon fontSet="fas" fontIcon="fa-star"></mat-icon>
  </button>
</div>

<mat-menu xPosition=after yPosition="below" [overlapTrigger]="false" #favoriteMenu="matMenu">
  <div class="favorite-menu" (click)="$event.stopPropagation()" (keydown)="handleKeyDown($event)">
    <div class="favorite-search" fxLayout="row">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon fontSet="fal" fontIcon="fa-search"></mat-icon>
      </div>
      <input class="favorite-search-input" type="search" placeholder="Search..." i18n-placeholder [(ngModel)]="searchValue">
    </div>
    <mat-divider></mat-divider>
    <!-- <div class="favorite-results" [perfect-scrollbar]> -->
    <div class="favorite-results" >
      <button mat-menu-item fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of displayingItems" (click)="toggleFavorite(item)">
        <mat-icon *ngIf="!item.isCustomSvgIcon"  fontSet="fal" fontIcon="{{item.icon}}"></mat-icon>
        <mat-icon *ngIf="item.isCustomSvgIcon" svgIcon="{{item.icon}}"></mat-icon>
        <span>{{ item.name }}</span>
        <span fxFlex></span>
        <mat-icon class="favorite_star-icon selected" *ngIf="isFavorite(item)" fontSet="fas" fontIcon="fa-star"></mat-icon>
        <mat-icon class="favorite_star-icon" *ngIf="!isFavorite(item)" fontSet="fal" fontIcon="fa-star"></mat-icon>
      </button>
    </div>
  </div>
</mat-menu>
