import { UnsafeAction } from '../unsafe-action.interface';

import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_COMPLETE,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_COMPLETE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_COMPLETE,
  GET_ROLES,
  GET_ROLES_COMPLETE,
  CREATE_ROLE,
  CREATE_ROLE_COMPLETE,
  GET_PERMISSIONS_COMPLETE,
  DELETE_ROLE,
  DELETE_ROLE_COMPLETE,
  GET_USERS,
  GET_USERS_COMPLETE,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_COMPLETE,
  CREATE_USER,
  CREATE_USER_COMPLETE,
  UPDATE_USER,
  UPDATE_USER_COMPLETE,
  DEACTIVATE_USER_COMPLETE,
  SET_USERS_LOADING_FLAG,
  SET_PAGE_VIEW_OPTIONS,
  UPDATE_USER_PROFILE_COMPLETE,
  GET_ROLE_TYPES_COMPLETE, GET_USER_COMPLETE, RESET_EDITED_USER, GET_USER, RESET_USERS_STATE, CLEAR_USERS_LIST
} from './users.actions';
import { createSelector } from '@ngrx/store';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import { AccountModel } from '../auth';

export interface User {
  id: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  accounts?: any[];
  roles?: any[];
  active?: boolean;
  thirdParty: boolean;
}

export interface UsersState {
  loading: boolean;
  loaded: boolean;
  accounts: {};
  roles: {};
  sectionPermissions: any[];
  rawSectionPermissions: any[];
  usersList: User[];
  usersMap: { [id: number] : User };
  editedUser: {};
  usersView?: {};
  filter: string;
  taxonomyPermissions?: any[];
  fieldPermissions?: any[];
  roleTypes: any[];
}

const initialState: UsersState = {
  loading: false,
  loaded: false,
  accounts: {},
  roles: {},
  sectionPermissions: [],
  rawSectionPermissions: [],
  usersList: [],
  usersMap: {},
  editedUser: null,
  usersView: {
    pageIndex: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  filter: '',
  fieldPermissions: [],
  roleTypes: [],
};


export function usersReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {

    case GET_ACCOUNTS:
    case CREATE_ACCOUNT:
    case UPDATE_ACCOUNT:
    case DELETE_ACCOUNT:
    case GET_ROLES:
    case CREATE_ROLE:
    case DELETE_ROLE:
    case GET_USERS:
    case GET_USER:
    case CREATE_USER:
    case UPDATE_USER: {
      return {
        ...state,
        loading: true,
        loaded: false,
        filter: ''
      };
    }

    case GET_ACCOUNTS_COMPLETE: {
      return {
        ...state,
        accounts: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case CREATE_ACCOUNT_COMPLETE: {
      const createdAccount = action.payload;
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [createdAccount.id]: createdAccount
        },
        loaded: true,
        loading: false
      };
    }

    case UPDATE_ACCOUNT_COMPLETE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case DELETE_ACCOUNT_COMPLETE: {
      const { accountId } = action.payload;
      const newAccountsMap = { ...state.accounts };
      delete newAccountsMap[accountId];
      return {
        ...state,
        accounts: newAccountsMap,
        loading: false,
        loaded: true
      };
    }

    case GET_ROLES_COMPLETE: {
      return {
        ...state,
        roles: { ...action.payload },
        loaded: true,
        loading: false
      };
    }

    case CREATE_ROLE_COMPLETE: {
      return {
        ...state,
        loaded: true,
        loading: false
      };
    }

    case GET_PERMISSIONS_COMPLETE: {
      return {
        ...state,
        sectionPermissions: [...action.payload.sectionPermissions.permissions],
        rawSectionPermissions: [...action.payload.sectionPermissions.rawPermissions],
        taxonomyPermissions: [...action.payload.taxonomyPermissions],
        fieldPermissions: [...action.payload.fieldPermissions],
        loaded: true,
        loading: false
      };
    }

    case DELETE_ROLE_COMPLETE: {
      const roleId = action.payload;
      const newRolesMap = { ...state.roles };
      delete newRolesMap[roleId];
      return {
        ...state,
        roles: newRolesMap,
        loaded: true,
        loading: false
      };
    }

    case GET_USERS_COMPLETE: {
      const { users, deactivatedUsers = [] } = action.payload;
      const newUsersView = {
        total : users.meta.page.total,
        pageIndex: users.meta.page.number,
        pageSize: users.meta.page.size
      };

      return {
        ...state,
        usersList: users.data,
        usersMap: {
          ...state.usersMap,
          ...users.data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
          ...deactivatedUsers.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
        },
        loading: false,
        loaded: true,
        usersView: {
          ...newUsersView,
        }
      };
    }

    case CREATE_USER_COMPLETE: {
      const createdUser = action.payload;
      return {
        ...state,
        usersMap: {
          ...state.usersMap,
          [createdUser.id]: createdUser
        },
        loading: false,
        loaded: true
      };
    }

    case UPDATE_USER_COMPLETE: {
      const updatedUser = action.payload;
      return {
        ...state,
        usersMap: {
          ...state.usersMap,
          [updatedUser.id]: updatedUser
        },
        loading: false,
        loaded: true
      };
    }

    case DEACTIVATE_USER_COMPLETE: {
      const deletedId = action.payload.id;
      const users = { ...state.usersMap };
      delete users[deletedId];
      const total = Object.keys(users).length;
      return {
        ...state,
        usersMap: users,
        loading: false,
        loaded: true,
        usersView: {
          ...state.usersView,
          total
        }
      };
    }

    case SET_PAGE_VIEW_OPTIONS: {
      return {
        ...state,
        usersView: {
          ...state.usersView,
          pageIndex: action.payload.pageIndex,
          pageSize: action.payload.pageSize
        }
      };
    }

    case SET_USERS_LOADING_FLAG: {
      const loading = action.payload;
      return {
        ...state,
        loading
      };
    }

    case UPDATE_USER_PROFILE_COMPLETE: {
      const updatedUser = action.payload;
      const userOldData = state.usersMap[updatedUser.id];
      const newUserData = {
        ...userOldData,
        ...updatedUser
      };
      return {
        ...state,
        usersMap: {
          ...state.usersMap,
          [updatedUser.id]: newUserData
        },
        loading: false,
        loaded: true
      };
    }

    case GET_ROLE_TYPES_COMPLETE: {
      return { ...state, roleTypes: action.payload };
    }

    case GET_USER_COMPLETE: {
      const user = action.payload;
      return {
        ...state,
        editedUser: user,
        loading: false,
        loaded: true
      };
    }

    case RESET_EDITED_USER: {
      return {
        ...state,
        editedUser: {}
      };
    }

    case RESET_USERS_STATE: {
      return initialState;
    }

    case CLEAR_USERS_LIST: {
      return {
        ...state,
        usersList: []
      }
    }

    default: {
      return state;
    }
  }
}

export const getAccountsArray = (state) => Object.values(state.users.accounts).map((account: AccountModel) => account);
export const getAccountsLoaded = (state) => state.users.loaded;
export const getRoles = (state) => state.users.roles;
export const getRolesArray = (state) => Object.values(state.users.roles).map(role => role);
export const getNormalizedSectionPermissions = (state) => state.users.sectionPermissions;
export const getRawSectionPermissionsMap = (state) => state.users.rawSectionPermissions
  .reduce((acc, permission) => ({ ...acc, [permission.name]: { id: permission.id } }), {});

export const getUsersState = (state) => state.users;
// export const getUsersMap = createSelector(getUsersState, state => state.usersMap);
// export const getUsersList = createSelector(getUsersState, state => state.usersList);
export const getUsersMap = (state) => state.users.usersMap;
export const getUsersList = (state) => state.users.usersList;

export const getUsersPageView = createSelector(getUsersState, state => state.usersView);
export const getUsersTotal = createSelector(getUsersPageView, state => state.total);

export const getPredefinedTaxonomyPermissions = (state) => state.users.taxonomyPermissions;
export const getEditedUser = createSelector(getUsersState, state => state.editedUser);

export const getRoleTypes = createSelector(getUsersState, state => state.roleTypes || []);
export const getFieldPermissions = createSelector(getUsersState, state => state.fieldPermissions);
