import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { RestService } from '../rest.service';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { HttpClient } from '@angular/common/http';
import { LabelValue, Subscriber } from '../../store/subscribers/subscribers.model';

@Injectable({
  providedIn: 'root',
})
export class SubscribersService {
  constructor(private rest: RestService) { }

  defaultParams = {
    service: 'glideTransmit',
  };

  getSubscribers({ name = '', currentPage = 0, pageSize = defaultPageSize }: any = {}) {
    return this.rest.get(`subscribers`, this.defaultParams).pipe(
      map((res: any) => {
        let data = res.data;

        name = (name || '').trim();
        if (name) {
          data = data.filter((x) => x.name.toLowerCase().includes(name.toLowerCase()));
        }
        const offset = currentPage * pageSize;
        const totalLength = data.length;
        data = data.slice(offset, offset + pageSize);
        const meta = { page: { number: currentPage, size: pageSize, total: totalLength } };
        return { ...res, data, meta };
      })
    );
  }

  getSubscriberById(id: string) {
    return this.rest.get(`subscribers/${id}`, this.defaultParams).pipe(
      map((res: any) => {
        let subscriber = res.data;
        if (subscriber.headers) {
          subscriber.headers = this.objectToArray(subscriber.headers);
        }
        return subscriber;
      }));
  }

  createSubscriber(subscriber: Subscriber) {

    const transformedSubscriber = this.transformDataForPost(subscriber);
    return this.rest
      .post(`subscribers`, transformedSubscriber, this.defaultParams)
      .pipe(map((res: any) => res.data));
  }

  updateSubscriber(subscriber: Subscriber) {

    const transformedSubscriber = this.transformDataForPost(subscriber);
    return this.rest
      .put(`subscribers/${subscriber.id}`, transformedSubscriber, this.defaultParams)
      .pipe(map((res: any) => res.data));
  }

  deleteSubscriber(id: string) {
    return this.rest
      .delete(`subscribers/${id}`, this.defaultParams)
      .pipe(map((res: any) => res.data));
  }

  getTransmitEvents() {
    return this.rest.get(`event-list`, this.defaultParams).pipe(map((res: any) => res.data));
  }

  objectToArray(headers: object): LabelValue[] {
    return Object.entries(headers).map(header => {
      return { label: header[0], value: header[1] };
    });
  }

  arrayToObject(headers) {
    return headers.reduce((acc, item) => {
      acc[item.label] = item.value;
      return acc;
    }, {});
  };

  transformDataForPost(subscriber: Subscriber) {

    const newHeaders = subscriber.headers ? this.arrayToObject(subscriber.headers) : {};
    subscriber.config = { headers: newHeaders, url: subscriber.url };
    delete subscriber.headers;
    delete subscriber.url;
    return subscriber;
  }

  getMultichannelPublishingSentInfo(id) {
    return this.rest.get(`mcp-history-list/${id}`, this.defaultParams).pipe(
      map((res: any) => res.data))
  }

  getPreviewEvents() {
    return this.rest.get(`preview-events`, this.defaultParams).pipe(map((res: any) => res.data || []));
  }
}
