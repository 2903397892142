import { AdvertGroup } from './adverts-group.model';
import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import * as advertsGroupAction from './adverts-group.actions';

export interface AdvertGroupsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  items: AdvertGroup[];
  advertGroupsView: {};
}

const initialState: AdvertGroupsState = {
  loaded: false,
  loading: false,
  error: null,
  items: [],
  advertGroupsView: {
    currentPage: 0,
    total: 0,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 40]
  },
};

export function advertsGroupReducer(state: AdvertGroupsState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case advertsGroupAction.GET_ADVERTS_GROUP: {
      return { ...state, loading: true };
    }

    case advertsGroupAction.GET_ADVERTS_GROUP_SUCCESS: {
      const advertsGroup = action.payload;
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        items: advertsGroup,
        advertGroupsView: { ...state.advertGroupsView, total: advertsGroup.length }
      });
    }
    case advertsGroupAction.DELETE_ADVERTS_GROUP:
    case advertsGroupAction.UPDATE_ADVERTS_GROUP:
    case advertsGroupAction.CREATE_ADVERTS_GROUP: {
      return { ...state, loading: true };
    }

    case advertsGroupAction.CREATE_ADVERTS_GROUP_SUCCESS: {
      const advertsGroup = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, advertsGroup]
      };
    }

    case advertsGroupAction.DELETE_ADVERTS_GROUP_SUCCESS: {
      const deleteId = action.payload;
      const items = state.items.filter(advert => advert.id !== deleteId);
      return {
        ...state,
        loading: false,
        error: null,
        items,
        advertGroupsView: { ...state.advertGroupsView, total: items.length },

      };
    }

    case advertsGroupAction.UPDATE_ADVERTS_GROUP_SUCCESS: {
      const updateAdvertsGroup: AdvertGroup = action.payload;
      const updatedIndex = state.items.findIndex(wt => wt.id === updateAdvertsGroup.id);
      const newItems = [
        ...state.items.slice(0, updatedIndex),
        updateAdvertsGroup,
        ...state.items.slice(updatedIndex + 1)
      ];
      return {
        ...state,
        loading: false,
        error: null,
        items: newItems
      };
    }

    case advertsGroupAction.GET_ADVERTS_GROUP_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case advertsGroupAction.SET_ADVERT_GROUPS_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        advertGroupsView: {
          ...state.advertGroupsView,
          currentPage: pageIndex,
          pageSize: pageSize
        }
      };
    }

    default: {
      return state;
    }

  }
}

export const getAdvertsGroupState = (state) => state.advertsGroup;
export const getAdvertsGroupList = createSelector(getAdvertsGroupState, advertsGroup => advertsGroup.items);
export const getAdvertsGroupLoading = createSelector(getAdvertsGroupState, advertsGroup => advertsGroup.loading);
export const getAdvertsGroupLoaded = createSelector(getAdvertsGroupState, advertsGroup => advertsGroup.loaded);
export const getAdvertGroupsPageView = (state) => state.advertsGroup.advertGroupsView;
