import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AppState } from '../../store/app-reducer';
import { Store } from '@ngrx/store';
import { RefreshAccountSettingsAction } from '../../store/account-settings/account-settings.actions';
import { StorageService } from '../storage/storage.service';
import { BroadcastService } from '../../tab-broadcast/broadcast.service';
import { replaceAccountSlugInURL } from './domain-account-slug-utilities';
import { LoadActiveUserDetailsAction } from '../../store/auth';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private storageService: StorageService,
    private broadcastService: BroadcastService,
    private snackbar: MatSnackBar,
  ) { }

  async canActivate() {
    const queryParams: any = this.getURLQueryParams();
    const { refreshToken, accountId, refreshSession } = queryParams;

    if(queryParams.authError || queryParams.authError === '') {
      console.log('SSO auth error, code: ' + (queryParams.authError || 'unknown'));
      this.snackbar.open(
        $localize`Login failed. Check if account access details are correct`,
        $localize`Close`,
        {duration: 60000}
      );
    }

    if (refreshToken && accountId) {
      this.authService.logout({ redirectToLogin: false, resetRedirectUrl: false });
      const err = await this.authService.refreshAuthToken(accountId, refreshToken).toPromise()
        .then(() => {
          this.store.dispatch(new RefreshAccountSettingsAction())
          this.store.dispatch(new LoadActiveUserDetailsAction())
        })
        .catch((err) => {
          const fallbackAccountId = this.authService.fallbackAccountId;
          if(fallbackAccountId) {
            const hrefWithFallbackAccount = location.href.replace(/accountId=[\d]+/, `accountId=${fallbackAccountId}`)
            location.href = replaceAccountSlugInURL(hrefWithFallbackAccount, this.authService.fallbackAccountSlug);
            return err;
          }
          // without fallback account, go to login screen
          if(err.message.includes('Failed to access account!')) {
            location.href = '/auth/login';
          }
          return err;
        });

      if(err) {
        console.log('Error In login guard, trying to recover...');
        return;
      }

      const activeUser = this.storageService.loadUserFromStore();
      this.broadcastService.publish({ type: 'SSO_REFRESH_SESSION', payload: activeUser })
      this.storageService.saveUserInStore({
        ...activeUser,
        ssoLogin: true
      });
    }
    if (!this.authService.isLoggedIn) {
      return true;
    }

    if (refreshSession) {
      window.close();
    } else {
      this.router.navigate(['dashboard']);
    }
    return false;
  }

  getURLQueryParams() {
    const search = window.location.search && window.location.search.slice(1);
    if (!search) {
      return {};
    }
    const queryParams = search.split('&').reduce((acc, item) => {
      const [key, value] = item.split('=');
      return { ...acc, [key]: value };
    }, {});

    return queryParams;
  }

}
