import { Injectable } from '@angular/core';
import { environment } from './environment';

enum FeatureFlags {
  GAIAVoice = 'GAIA_VOICE'
};

@Injectable({ providedIn: 'root' })
export class EnvSettingService {

  constructor() {}

  getFeatureFlags() {
    if (!environment.featureFlags) {
      return [];
    }
    try {
      const ff = JSON.parse(environment.featureFlags);
      const configValid = Array.isArray(ff) && ff.every(key => typeof key === 'string');
      if (configValid) {
        return ff;
      }
      console.error('Invalid feature flags configuration');
      return [];
    } catch (error) {
      console.error('Invalid feature flags configuration');
      return [];
    }
  }

  isGAIAVoiceEnabled() {
    return !!this.getFeatureFlags().find(key => key === FeatureFlags.GAIAVoice);
  }
}
