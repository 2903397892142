import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { ChangePasswordDialogComponent } from '../../glide-users/change-password-dialog/change-password-dialog.component';
import { ModalsService } from '../modals/modals.service';
import { Permissions } from '../../core/store/auth/permissions';
@Component({
  selector: 'gd-user-details-form',
  templateUrl: './user-details-form.component.html',
  styleUrls: ['./user-details-form.component.scss']
})
export class UserDetailsFormComponent {
  hasPasswordChangePermission = this.permissionService.hasPermission(Permissions.GU_PASSWORD_CHANGE);

  get usernameControl() {
    return <UntypedFormControl>this.userForm.get('username');
  }

  get passwordControl() {
    return <UntypedFormControl>this.userForm.get('password');
  }

  get confirmPasswordControl() {
    return <UntypedFormControl>this.userForm.get('confirmPassword');
  }

  @Input() userForm;
  @Input() userEdited;
  @Input() editMode: boolean = false;

  constructor(
    private modalsService: ModalsService,
    private permissionService: PermissionService,
  ) { }

  openChangePasswordDialog() {
    const config = {
      data: {
        userData: this.userEdited
      },
      width: '600px'
    };
    this.modalsService.custom($localize`Change Password`, ChangePasswordDialogComponent, config);
  }
}
