import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gd-ellipsis-label-tooltip',
  templateUrl: './ellipsis-label-tooltip.component.html',
  styleUrls: ['./ellipsis-label-tooltip.component.scss'],
})
export class EllipsisLabelTooltipComponent implements OnInit {

  @Input() availableSpace: number = 0;
  @Input() tooltipLabel: string = '';
  @Input() usage: string = '';

  canvas

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.canvas = this.createCanvas();
  }

  constructor() {}
  ngOnInit(): void {
    this.canvas = this.createCanvas();
  }

  createCanvas() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = this.getFontStyle()
    return context;
  }

  getFontStyle() {
    switch (this.usage) {
      case 'config-export':
        return '16px Roboto, "Helvetica Neue", sans-serif';

      case 'pages-list':
      default:
        return window.innerWidth > 1441
          ? '14px Roboto, "Helvetica Neue", sans-serif'
          : '12px Roboto, "Helvetica Neue", sans-serif';
    }
  }

  measureText(text: string) {
    return this.canvas.measureText(text).width;
  }

  disableTooltip(text: string) {
    return this.measureText(text) <= this.availableSpace;
  }
}
