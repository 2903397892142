import { Component, OnInit, Inject, DoCheck } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable , Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FilesService } from '../../core/api/files/files.service';
import { UserPreferencesService } from '../../core/api/user-preferences/user-preferences.service';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-move-to',
  templateUrl: './move-to.component.html',
  styleUrls: ['./move-to.component.scss'],
})
export class MoveToComponent implements OnInit, DoCheck {
  currentFolder: any = { path: '', folderId: null };
  selectedFolder: any = { path: '', folderId: null };

  displayedColumns = ['filename'];
  folderData$: Observable<any>;
  folderListTrigger = new Subject<any>();
  loaded = false;
  theme = "default";
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    public dialogRef: MatDialogRef<MoveToComponent>,
    private filesService: FilesService,
    private userPreferenceService: UserPreferencesService,
    private bidiService: BidiService,
  ) { }

  ngDoCheck() {
    if (this.theme !== this.userPreferenceService.getUserPreference('theme')) {
      this.theme = this.userPreferenceService.getUserPreference('theme');
      this.ngOnInit;
    }
  }

  ngOnInit() {
    const path = this.dialogInputData.data.path;
    this.currentFolder.path = path.substr(0, path.lastIndexOf('/'));
    this.folderData$ = this.folderListTrigger.asObservable().pipe(
      mergeMap(() => this.filesService.getFiles({ folderPath: this.currentFolder.path })),
      map(data => {
        this.loaded = true;
        this.currentFolder = { ...data, folderId: data.id };
        this.selectedFolder = { ...this.currentFolder };
        const content = data.content
          .filter(file => !file.path.startsWith(this.dialogInputData.forbiddenPath))
          .map(item => ({ ...item, thumbnail: this.resolveThumbnailImg(item), isCustomSvgIcon: this.resolveThumbnailImg(item).startsWith('glideCustomIcon_') ? true : false  }));
        return content;
      })
    );
    setTimeout(() => this.folderListTrigger.next(true), 0);
  }

  moveFolder() {
    this.dialogRef.close(this.selectedFolder);
  }

  openFolder(event, { filename, path }) {
    event.stopPropagation();
    this.loaded = false;
    this.currentFolder.name = filename;
    this.currentFolder.path = path;
    this.folderListTrigger.next(true);
  }

  goBack() {
    this.currentFolder.path = this.currentFolder.path.substr(0, this.currentFolder.path.lastIndexOf('/'));
    this.folderListTrigger.next(true);
  }

  selectFolder({ id, path }) {
    if (this.selectedFolder.folderId === id) {
      // unselect action
      return this.selectedFolder = { ...this.currentFolder };
    }
    this.selectedFolder = { folderId: id, path };
  }

  resolveThumbnailImg(item) {
    if (item.type === 'folder') {
      return 'fa-folder';
    }
    const regex = /[^.]+$/g;
    const type = item.filename ? item.filename.match(regex)[0] : '';
      return this.filesService.resolveFileThumbnail(type);
  }

  generateMoveMessage() {

    return this.selectedFolder.folderId === this.currentFolder.folderId ? $localize`Here` : '';
  }
}
