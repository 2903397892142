import {filter, throttleTime,  map ,  debounceTime } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable ,  Subject ,  Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { Permissions } from '../../core/store/auth/permissions';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { getImagesTags } from '../../core/store/images/images.reducer';
import { GetTagsAction } from '../../core/store/images/images.actions';

@Component({
  selector: 'gd-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styleUrls: ['./tags-autocomplete.component.scss']
})
export class TagsAutocompleteComponent implements OnInit, OnDestroy {

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteControl;

  tagInputFormControl = new UntypedFormControl();
  filteredOptions$: Observable<string[]>;

  @Input() group = null;
  @Input() loading = false;
  @Input() hasCreatePermission = true;
  @Output() tagSelectedEvent = new EventEmitter();

  tagSelectedEmitter$ = new Subject();
  hasWriteTagPermission = this.permissionService.hasPermission(Permissions.GM_TAG_WRITE);

  componentSub: Subscription = new Subscription();

  constructor(
    private permissionService: PermissionService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    if (!this.hasWriteTagPermission) {
      this.tagInputFormControl.disable();
    }

    this.filteredOptions$ = this.store.select(getImagesTags)
      .pipe(map(tags => {
        const searchedTerm = (this.tagInputFormControl.value || '').trim();
        return searchedTerm ? tags.map(tag => tag.name) : [];
      }));

    this.componentSub.add(
      this.tagInputFormControl.valueChanges
        .pipe(debounceTime(300), map(keystroke => (keystroke || '').trim()))
        .subscribe(name => this.store.dispatch(new GetTagsAction({ group: this.group, name })))
    );

    this.tagSelectedObservable().pipe(
      throttleTime(160),
      filter(val => val !== ''))
      .subscribe(value => this.tagSelectedEvent.next(value));
  }

  tagSelectedObservable() {
    return this.tagSelectedEmitter$.asObservable();
  }

  handleTagSelected(event) {
    const { value } = event.option;
    this.tagSelectedEmitter$.next(value);
    this.resetAutocompleteState();
  }

  handleValueSelected(value) {
    this.tagSelectedEmitter$.next(value);
    this.resetAutocompleteState();
  }

  resetAutocompleteState() {
    setTimeout(() => {
      this.tagInputFormControl.reset();
      this.autocompleteControl.closePanel();
      // this.tagInputFormControl.markAsPristine();
    }, 200);
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }

}
