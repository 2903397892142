import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationsListComponent } from './localizations-list/localizations-list.component';
import { MaterialModule } from '../../shared/material-module.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalizeModeDialogComponent } from './localize-mode-dialog/localize-mode-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslationLoaderDialogComponent } from './translation-loader-dialog/translation-loader-dialog.component';



@NgModule({
  declarations: [
    LocalizationsListComponent,
    LocalizeModeDialogComponent,
    TranslationLoaderDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    LocalizationsListComponent
  ]
})
export class ContentLocaleComponentsModule { }

// src/app/gpp-shared/content-locale-components/content-locale-components.module.ts
