<div class="gd-file-download" [dir]="dir$ | async">
  <div class="gd-file-download__content">
    <mat-form-field class="gd-file-download__input">
      <mat-label style="font-size: 22px" i18n>File URL</mat-label>
      <input matInput #inputField [value]="file.url" readonly="readonly" />
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="8px">
      <mat-icon
        color="primary"
        matTooltip="Copy to clipboard"
        i18n-matTooltip
        (click)="handleCopyFileLink(file.url)"
        fontSet="fal"
        fontIcon="fa-copy"
        ></mat-icon
      >
      <mat-icon color="primary" (click)="handleFileDownload(file.url)">launch</mat-icon>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="flex-end" fxLayoutGap="12px">
    <button mat-button mat-raised-button mat-dialog-close i18n>Cancel</button>
  </div>
</div>
