import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app-reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { UnsafeAction } from '../unsafe-action.interface';
import { LiveReportsService } from '../../api/live-reports/live-reports.service';
import { CreateLiveReportSuccess, CreateSummarySuccess, CREATE_LIVE_REPORT, CREATE_SUMMARY, GetActiveLiveReportSuccessAction, GetSummarySuccessAction, GET_ACTIVE_LIVE_REPORT, GET_SUMMARY, LiveReportFailedAction, SummaryFailedAction, PublishLiveReportSuccessAction, PublishSummarySuccess, PUBLISH_LIVE_REPORT, PUBLISH_SUMMARY, UnpublishLiveReportSuccessAction, UnpublishSummarySuccess, UNPUBLISH_LIVE_REPORT, UNPUBLISH_SUMMARY, UpdateLiveReportSuccessAction, UpdateSummarySuccess, UPDATE_LIVE_REPORT, UPDATE_SUMMARY, LOAD_LIVE_REPORT_RELATED_ARTICLES, GET_ACTIVE_LIVE_REPORT_SUCCESS, UPDATE_LIVE_REPORT_SUCCESS, CREATE_LIVE_REPORT_SUCCESS, SetLiveReportLoadingFlagAction, LoadLiveReportRelatedArticlesSuccessAction, CreateSummaryAction } from './live-report.actions';
import { getActiveLiveReportState } from './live-report.reducer';
import { AccountSettingsService } from '../../api/account-settings/accounts-settings.service';

@Injectable()
export class LiveReportEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private router: Router,
    private liveReportsService: LiveReportsService,
    private accountSettingsService: AccountSettingsService,
  ) { }

  
  loadActiveLiveReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACTIVE_LIVE_REPORT),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.getLiveReport(action.payload).pipe(
        map((liveReport) => {
          return new GetActiveLiveReportSuccessAction(liveReport)
        }),
        catchError((e) => {
          this.router.navigate(['live-reporting/']);
          this.snackBar.open($localize`Live Report not found.`, $localize`Close`, { duration: 4000 });

          return of(new LiveReportFailedAction(e));
        })
      )
    })
  ));

  
  loadActiveSummary$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_SUMMARY),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.getSummary(action.payload).pipe(
        map((summary) => {
          return new GetSummarySuccessAction(summary)
        }),
        catchError((e) => of(new SummaryFailedAction({ error: e, action })))
      )
    })
  ));
  
  createSummary$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_SUMMARY),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.createSummary(action.payload.id, {}).pipe(
        map((summary) => new CreateSummarySuccess(summary)),
        catchError((e) => of(new SummaryFailedAction({ error: e, action })))
      )
    })
  ));
  
  updateSummary$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_SUMMARY),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.updateSummary(action.payload.id, action.payload.data).pipe(
        map((summary) => new UpdateSummarySuccess(summary)),
        tap(() => {
          this.snackBar.open($localize`Summary saved`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => of(new SummaryFailedAction({ error: e, action })))
      )
    })
  ));
  
  publishSummary$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PUBLISH_SUMMARY),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.publishSummary(action.payload).pipe(
        map((summary) => new PublishSummarySuccess(summary)),
        tap(() => {
          this.snackBar.open($localize`Summary published`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => of(new SummaryFailedAction({ error: e, action })))
      )
    })
  ));
  
  unpublishSummary$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UNPUBLISH_SUMMARY),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.unpublishSummary(action.payload).pipe(
        map((summary) => new UnpublishSummarySuccess(summary)),
        tap(() => {
          this.snackBar.open($localize`Summary unpublished`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => of(new SummaryFailedAction({ error: e, action })))
      )
    })
  ));
  
  createLiveReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_LIVE_REPORT),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.createLiveReport(action.payload).pipe(
        tap((liveReport) => {
          this.snackBar.open($localize`Live Report saved`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['live-reporting/', liveReport && liveReport.id]);
          this.store.dispatch(new CreateSummaryAction({ id: liveReport.id }))
        }),
        map((liveReport) => new CreateLiveReportSuccess(liveReport)),
        catchError((e) => of(new LiveReportFailedAction({ error: e, action })))
      )
    })
  ))
  
  updateLiveReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_LIVE_REPORT),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.updateLiveReport(action.payload).pipe(
        tap((liveReport) => {
          this.snackBar.open($localize`Live Report saved`, $localize`Close`, { duration: 4000 });
        }),
        map((liveReport) => new UpdateLiveReportSuccessAction(liveReport)),
        catchError((e) => of(new LiveReportFailedAction({ error: e, action }))
        )
      )
    })
  ));
  
  publishLiveReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PUBLISH_LIVE_REPORT),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.publishLiveReport(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Live Report published`, $localize`Close`, { duration: 4000 });
        }),
        map((liveReport) => new PublishLiveReportSuccessAction(liveReport)),
        catchError((e) => of(new LiveReportFailedAction({ error: e, action }))
        )
      )
    })
  ));
  
  unpublishLiveReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UNPUBLISH_LIVE_REPORT),
    mergeMap((action: UnsafeAction) => {
      return this.liveReportsService.unpublishLiveReport(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Live Report unpublished`, $localize`Close`, { duration: 4000 });
        }),
        map((liveReport) => new UnpublishLiveReportSuccessAction(liveReport)),
        catchError((e) => of(new LiveReportFailedAction({ error: e, action }))
        )
      )
    })
  ));

  
  getRelatedArticles$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_LIVE_REPORT_RELATED_ARTICLES, GET_ACTIVE_LIVE_REPORT_SUCCESS, UPDATE_LIVE_REPORT_SUCCESS, CREATE_LIVE_REPORT_SUCCESS),
      mergeMap(({ type, payload }: UnsafeAction) => {
        let forceWorkingRevision = payload.hasOwnProperty('forceWorkingRevision') ? payload.forceWorkingRevision : true;
        if (type === GET_ACTIVE_LIVE_REPORT_SUCCESS) {
          forceWorkingRevision = false;
        }
        return this.store.select(getActiveLiveReportState).pipe(
          take(1),
          map((liveReport) => {
            if (!liveReport.id) {
              return null;
            }

            let revision = (liveReport.publishedRevision || liveReport.workingRevision);
            if (forceWorkingRevision) {
              revision = liveReport.workingRevision;
            }
            const isAutomatic = payload.hasOwnProperty('isAutomatic') ? payload.isAutomatic : revision.useAutomaticRelatedArticles;

            return {
              liveReportId: liveReport.id,
              revisionId: revision.id,
              useAutomaticRelatedArticles: isAutomatic,
            };
          })
        );
      }),
      filter((data) => !!data && !!data.revisionId),
      mergeMap(({ liveReportId, revisionId, useAutomaticRelatedArticles }) => {
        this.store.dispatch(new SetLiveReportLoadingFlagAction(true));
        const size = this.accountSettingsService.getNumberOfRelatedArticles();
        return this.liveReportsService.getRelatedArticles({
          liveReportId,
          revisionId,
          size,
          useAutomaticRelatedArticles
        });
      }),
      map((relatedArticles) => new LoadLiveReportRelatedArticlesSuccessAction(relatedArticles)),
      catchError((e) => of(new LiveReportFailedAction({ error: e })))
    ));


}

