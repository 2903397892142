import { AccessBundlesService } from '../../api/access-bundles/access-bundles.service';
import { UnsafeAction } from '../unsafe-action.interface';
import { GET_ACCESS_BUNDLES, GET_ACCESS_BUNDLES_SUCCESS, GET_ACCESS_BUNDLE, GET_ACCESS_BUNDLE_SUCCESS, CREATE_ACCESS_BUNDLE, UPDATE_ACCESS_BUNDLE, CREATE_ACCESS_BUNDLE_SUCCESS, UPDATE_ACCESS_BUNDLE_SUCCESS, CHANGE_ACCESS_BUNDLE_REVISION, CHANGE_ACCESS_BUNDLE_REVISION_SUCCESS, PUBLISH_ACCESS_BUNDLE, UNPUBLISH_ACCESS_BUNDLE, PUBLISH_ACCESS_BUNDLE_SUCCESS, UNPUBLISH_ACCESS_BUNDLE_SUCCESS, RESET_ACTIVE_ACCESS_BUNDLE_STATE, ACCESS_BUNDLES_ACTION_FAILED, DELETE_ACCESS_BUNDLE, DELETE_ACCESS_BUNDLE_SUCCESS, CLEAR_ACCESS_BUNDLES_STATE } from './access-bundles.actions';
import { createSelector } from 'reselect';
import { WorkflowStatus } from '../workflows/workflow.model';
import { AppState } from '../app-reducer';

export interface AccessBundlesState {
  loaded: boolean;
  loading: boolean;
  error: string;
  accessBundles: any[];
  activeAccessBundle: ActiveAccessBundle;
}

 export interface ActiveAccessBundle {
   id: number;
   createdAt: number;
   updatedAt: number;
   firstPublishedAt: number;
   lastPublishedAt: number;
   createdBy: number;
   updatedBy: number;
   firstPublishedBy: number;
   lastPublishedBy: number;
   latestRevision: AccessBundleRevision;
   latestRevisionId: number;
   publishedRevisionId: number;
   workingRevision: AccessBundleRevision;
   revisions: AccessBundleRevision[];
   revisionMap: any[];
 }

 export interface AccessBundleRevision {
   id: number;
   name: string;
   createdAt?: number;
   identifier?: string;
   status: AccessBundleStatus;
   colour?: string;
   position?: number;
   restrictedArticleTypes: any[];
   restrictedTaxonomies: any[];
   contentAccessRules?: any;
 }

 export interface AccessBundleStatus {
   id: number;
   name: string;
   color: string;
   backgroundColor: string;
   publishable: boolean;
   initial: boolean;
   published: boolean;
   previewable: boolean;
   unpublished: boolean;
 }

const initialState: AccessBundlesState = {
  loaded: false,
  loading: false,
  error: null,
  accessBundles: [],
  activeAccessBundle: null,
};

export function accessBundlesReducer(state: AccessBundlesState = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_ACCESS_BUNDLES:
    case DELETE_ACCESS_BUNDLE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_ACCESS_BUNDLE_SUCCESS: {
      const deletedId = action.payload;
      const accessBundles = state.accessBundles.filter(accessBundle => accessBundle.id !== deletedId);
      return {
        ...state,
        loading: false,
        loaded: true,
        accessBundles,
        activeAccessBundle: null,
      };
    }
    case GET_ACCESS_BUNDLES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        accessBundles: action.payload
      }
    }
    case GET_ACCESS_BUNDLE: {
      return {
        ...state,
        loading: true,
        activeAccessBundle: null
      };
    }
    case GET_ACCESS_BUNDLE_SUCCESS:
    case CREATE_ACCESS_BUNDLE_SUCCESS:
    case UPDATE_ACCESS_BUNDLE_SUCCESS: {
      const accessBundle = action.payload;
      const latestRevision = accessBundle.latestRevision;
      const revisionMap = state.activeAccessBundle ? state.activeAccessBundle.revisionMap : {};
      return {
        ...state,
        loading: false,
        activeAccessBundle: {
          ...accessBundle,
          workingRevision: { ...latestRevision },
          revisionMap: { ...revisionMap, [latestRevision.id]: { ...latestRevision } }
        }
      };
    }
    case CREATE_ACCESS_BUNDLE:
    case UPDATE_ACCESS_BUNDLE:
    case CHANGE_ACCESS_BUNDLE_REVISION:
    case PUBLISH_ACCESS_BUNDLE:
    case UNPUBLISH_ACCESS_BUNDLE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHANGE_ACCESS_BUNDLE_REVISION_SUCCESS: {
      const workingRevision = action.payload;
      const accessBundle = state.activeAccessBundle;
      return {
        ...state,
        loading: false,
        activeAccessBundle: {
          ...accessBundle,
          workingRevision,
          revisionMap: { ...accessBundle.revisionMap, [workingRevision.id]: { ...workingRevision } }
        }
      };
    }
    case PUBLISH_ACCESS_BUNDLE_SUCCESS:
    case UNPUBLISH_ACCESS_BUNDLE_SUCCESS: {
      const { workingRevision } = action.payload;
      const accessBundle = state.activeAccessBundle;
      const publishedRevisionId = action.type === PUBLISH_ACCESS_BUNDLE_SUCCESS ? action.payload.publishedRevisionId : null;
      return {
        ...state,
        loading: false,
        activeAccessBundle: {
          ...accessBundle,
          ...action.payload,
          publishedRevisionId,
          revisionMap: { ...accessBundle.revisionMap, [workingRevision.id]: { ...workingRevision } }
        }
      };
    }
    case RESET_ACTIVE_ACCESS_BUNDLE_STATE: {
      return { ...state, activeAccessBundle: null };
    }
    case ACCESS_BUNDLES_ACTION_FAILED: {
      return { ...state, loading: false };
    }
    case CLEAR_ACCESS_BUNDLES_STATE : {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}

export const getAccessBundlesState = (state: AppState) => state.accessBundles;
export const getAccessBundlesList = createSelector(getAccessBundlesState, state => state.accessBundles);
export const getAccessBundlesLoading = createSelector(getAccessBundlesState, state => state.loading);
export const getAccessBundlesLoaded = createSelector(getAccessBundlesState, state => state.loaded);
export const getActiveAccessBundle = createSelector(getAccessBundlesState, state => state.activeAccessBundle);
export const getAccessBundleRevisionList = createSelector(getActiveAccessBundle, accessBundle => accessBundle ? accessBundle.revisions : []);
export const getAccessBundleRevisionMap = createSelector(getActiveAccessBundle, accessBundle => accessBundle ? accessBundle.revisionMap : {});
