import { SetIsDisplayedFlagAction } from './../ui-section-title/ui-section-title.actions';
import { AppState } from './../app-reducer';
import { mergeMap, tap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  FieldGroupsActionTypes,
  LoadFieldGroupsSuccessAction,
  FailureFieldGroupAction,
  LoadOneFieldGroupAction,
  LoadOneFieldGroupSuccessAction,
  CreateFieldGroupAction,
  CreateFieldGroupSuccessAction,
  UpdateFieldGroupAction,
  UpdateFieldGroupSuccessAction,
  DeleteFieldGroupAction,
  DeleteFieldGroupSuccessAction,
} from './field-groups.actions';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { FieldGroup } from './field-groups.model';
import { FieldGroupsService } from '../../api/field-groups/field-groups.service';
import { UnsafeAction } from '../unsafe-action.interface';
import { SetSectionTitleAction } from '../ui-section-title/ui-section-title.actions';

@Injectable()
export class FieldGroupsEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private fieldGroupsService: FieldGroupsService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}


  loadFieldGroupsS$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(FieldGroupsActionTypes.LOAD))
    .pipe(
      mergeMap((action: UnsafeAction) =>
        this.fieldGroupsService.getFieldGroups(action.payload).pipe(
          map((fgd) => new LoadFieldGroupsSuccessAction(fgd)),
          catchError((e) => of(new FailureFieldGroupAction({ error: e })))
        )
      )
    ));


  loadOneFieldGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(FieldGroupsActionTypes.LOAD_ONE),
    mergeMap((action: LoadOneFieldGroupAction) =>
      this.fieldGroupsService.getFieldGroup(action.payload).pipe(
        map((t: any) => new LoadOneFieldGroupSuccessAction(t)),
        catchError((e) => {
          this.router.navigate(['/site-builder/custom-field-groups/']);
          this.snackBar.open($localize`Custom Field Group not found.`, $localize`Close`, { duration: 4000 });
          return of(new FailureFieldGroupAction({ error: e }))})
      )
    )
  ));


  createFieldGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(FieldGroupsActionTypes.CREATE),
    mergeMap((action: CreateFieldGroupAction) => {
      return this.fieldGroupsService.createFieldGroup(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Custom field group created`, $localize`Close`, { duration: 2000 })),
        tap((newFieldGroup: FieldGroup) => {
          this.router.navigate(['site-builder/custom-field-groups/' + newFieldGroup.id]);
          // update section title after creating the CFG
          setTimeout(() => this.store.dispatch(new SetSectionTitleAction(newFieldGroup.name)), 200);
        }),
        map((newFieldGroup: FieldGroup) => new CreateFieldGroupSuccessAction(newFieldGroup)),
        catchError((e) => of(new FailureFieldGroupAction({ error: e })))
      );
    })
  ));


  updateFieldGroup$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(FieldGroupsActionTypes.UPDATE))
    .pipe(
      mergeMap((action: UpdateFieldGroupAction) => {
        return this.fieldGroupsService.updateFieldGroup(action.payload).pipe(
          tap(() => this.snackBar.open($localize`Custom field group updated`, $localize`Close`, { duration: 2000 })),
          map((newFieldGroup: FieldGroup) => new UpdateFieldGroupSuccessAction(newFieldGroup)),
          catchError((e) => of(new FailureFieldGroupAction({ error: e })))
        );
      })
    ));


  deleteFieldGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(FieldGroupsActionTypes.DELETE),
    mergeMap((action: DeleteFieldGroupAction) => {
      const idToDelete = action.payload;
      return this.fieldGroupsService.deleteFieldGroup(idToDelete).pipe(
        tap(() => this.snackBar.open($localize`Custom field group deleted`, $localize`Close`, { duration: 2000 })),
        map(() => new DeleteFieldGroupSuccessAction(idToDelete)),
        catchError(() => {
          this.snackBar.open($localize`Cannot delete Collection Type assigned to an Collection.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new DeleteFieldGroupSuccessAction(null));
        })
      );
    })
  ));
}
