import { UnsafeAction } from '../unsafe-action.interface';
import { Gallery } from './galleries.model';

export const GET_GALLERIES = '[Galleries] Get galleries';
export class GetGalleriesAction implements UnsafeAction {
  readonly type = GET_GALLERIES;
  constructor(public payload: any = {}) { }
}

export const GET_GALLERIES_COMPLETE = '[Galleries] Get galleries completed';
export class GetGalleriesCompleteAction implements UnsafeAction {
  readonly type = GET_GALLERIES_COMPLETE;
  constructor(public payload: any) { }
}

export const CREATE_GALLERY = '[Galleries] Create gallery';
export class CreateGalleryAction implements UnsafeAction {
  readonly type = CREATE_GALLERY;
  constructor(public payload: any) { }
}

export const CREATE_GALLERY_COMPLETE = '[Galleries] Create gallery completed';
export class CreateGalleryCompleteAction implements UnsafeAction {
  readonly type = CREATE_GALLERY_COMPLETE;
  constructor(public payload: any) { }
}

export const CREATE_GALLERY_REVISION = '[Galleries] Create gallery revision';
export class CreateGalleryRevisionAction implements UnsafeAction {
  readonly type = CREATE_GALLERY_REVISION;
  constructor(public payload: any) { }
}

export const CREATE_GALLERY_REVISION_COMPLETE = '[Galleries] Create gallery revision completed';
export class CreateGalleryRevisionCompleteAction implements UnsafeAction {
  readonly type = CREATE_GALLERY_REVISION_COMPLETE;
  constructor(public payload: any) { }
}

export const GET_GALLERY = '[Galleries] Get single gallery details';
export class GetGalleryAction implements UnsafeAction {
  readonly type = GET_GALLERY;
  constructor(public payload: any) { }
}

export const GET_GALLERY_COMPLETE = '[Galleries] Get single gallery details completed';
export class GetGalleryCompleteAction implements UnsafeAction {
  readonly type = GET_GALLERY_COMPLETE;
  constructor(public payload: any) { }
}

export const GET_GALLERY_REVISION = '[Galleries] Get gallery revision';
export class GetGalleryRevisionAction implements UnsafeAction {
  readonly type = GET_GALLERY_REVISION;
  constructor(public payload: any) { }
}

export const GET_GALLERY_REVISION_COMPLETE = '[Galleries] Get gallery revision completed';
export class GetGalleryRevisionCompleteAction implements UnsafeAction {
  readonly type = GET_GALLERY_REVISION_COMPLETE;
  constructor(public payload: any) { }
}

export const SET_GALLERY_ACTIVE_REVISION = '[Galleries] Set gallery active revision';
export class SetGalleryActiveRevisionAction implements UnsafeAction {
  readonly type = SET_GALLERY_ACTIVE_REVISION;
  constructor(public payload: any) { }
}

export const DELETE_GALLERY = '[Galleries] Delete gallery';
export class DeleteGalleryAction implements UnsafeAction {
  readonly type = DELETE_GALLERY;
  constructor(public payload: any) { }
}

export const DELETE_GALLERY_COMPLETE = '[Galleries] Delete gallery complete';
export class DeleteGalleryCompleteAction implements UnsafeAction {
  readonly type = DELETE_GALLERY_COMPLETE;
  constructor(public payload: any) { }
}

export const GALLERIES_ACTION_FAILED = '[Galleries] Generic failure action';
export class GalleriesFailedAction implements UnsafeAction {
  readonly type = GALLERIES_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[Galleries] Set page view options';
export class SetPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const PUBLISH_GALLERY_REVISION = '[Galleries] Publish gallery revision';
export class PublishGalleryRevisionAction implements UnsafeAction {
  readonly type = PUBLISH_GALLERY_REVISION;
  constructor(public payload: any) { }
}

export const SET_GALLERY_LOADING_FLAG = '[Galleries] Set gallery loading flag';
export class SetGalleryLoadingFlagAction implements UnsafeAction {
  readonly type = SET_GALLERY_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const GALLERIES_ACTION_NOT_FOUND = '[Galleries] Generic failure action';
export class GalleriesNotFoundAction implements UnsafeAction {
  readonly type = GALLERIES_ACTION_NOT_FOUND;
  constructor(public payload: any) { }
}

export const GET_LOCALIZED_VERSIONS_GALLERIES = '[Galleries] Get Localized versions for Galleries';
export class GetLocalizedVersions4Galleries implements UnsafeAction {
  readonly type = GET_LOCALIZED_VERSIONS_GALLERIES;
  constructor(public galleryIds: number[]) { }
}

export const GET_LOCALIZED_VERSIONS_GALLERIES_SUCCESS = '[Galleries] Get Localized versions for Galleries Success';
export class GetLocalizedVersions4GalleriesSuccess implements UnsafeAction {
  readonly type = GET_LOCALIZED_VERSIONS_GALLERIES_SUCCESS;
  constructor(public localizedVersions: any[]) { }
}

export const LOAD_LOCALIZED_VERSIONS = '[Galleries] Load localized versions';
export class LoadLocalizedVersionsAction implements UnsafeAction {
  readonly type = LOAD_LOCALIZED_VERSIONS;
  constructor(public payload: any) { }
}

export const LOCALIZE_GALLERY = '[Galleries] Localize gallery by id';
export class LocalizeGalleryAction implements UnsafeAction {
  readonly type = LOCALIZE_GALLERY;
  constructor(public payload: {
    mode: string,
    id: number,
    contentLocaleId: number,
    localizationId: string
  }) { }
}

export const LOCALIZE_GALLERY_SUCCESS = '[Galleries] Localize gallery by id done';
export class LocalizeGallerySuccessAction implements UnsafeAction {
  readonly type = LOCALIZE_GALLERY_SUCCESS;
  constructor(public payload: any) { }
}

export const CLEAR_GALLERIES = '[Galleries] Clear Galleries State';
export class ClearGalleriesAction implements UnsafeAction {
  readonly type = CLEAR_GALLERIES;
  constructor() {}
}
