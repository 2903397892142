import { isArray, isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, AbstractControl, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { formControlFactory } from '../../../glide-create/+site-builder/+templates/widget-form-utilities';
import { FormGroupOptions, WidgetTypeFieldConfiguration, LocalizedDefaultValue } from '../../../core/store/widget-types/widget-types.model';
import { DataSourceOptions } from '../../custom-form-builder/data-source-options.enum';
import { doubleUnderscorePrefixValidator } from './double-underscore-prefix-validator';
import { getCustomFieldKeyUniquenessValidator } from './custom-field-key-uniqueness-validator';
import { FieldGroup, flattenFieldGroupDefinitions } from '../../../core/store/field-groups/field-groups.model';

/**
 * This service is used to instantiate form groups and form controls according to Glide custom field
 * definitions, as well as creating the configuration forms for custom fields. So basically, form model
 * handling for both custom field definition and created instances.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomFormConstructorService {

  constructor(private formBuilder: UntypedFormBuilder) {}

  /**
   * This function is used to create form groups based on the array of field configuration objects.
   * The initial values can be assigned by being passed as `initialValues` object, values will be matched by the field key.
   *
   * For custom field group field configurations, an empty FormGroup will be created and `initialValues` will be added to it
   * as a form control with key `__initialValues` so that initial values can be treated once their config loads
   *
   * @param formFieldsConfig list of field configuration objects for fields to be constructed from
   * @param initialValues initial values object, mapped by field key
   *
   * @returns instance of a FormGroup with controls created according to inputs
   */
  public createFormGroup(
    formFieldsConfig: WidgetTypeFieldConfiguration[],
    initialValues = {},
    options: FormGroupOptions = { editMode: true, contentLocaleId: null }
  ): UntypedFormGroup {

    if (!formFieldsConfig) {
      return this.formBuilder.group({});
    }

    // form model will be a map of string to FormControl or FormGroup (common type for which is AbstractControl)
    const formModel: any = formFieldsConfig.reduce(
      (formModelAccumulator: { [key: string]: AbstractControl }, fieldConfig: WidgetTypeFieldConfiguration) => {
        const fieldKey = fieldConfig.key;

        formModelAccumulator.__sysDataSource.setValue({
          ...formModelAccumulator.__sysDataSource.value,
          [fieldConfig.key]: { type: fieldConfig.dataSource?.type, value: fieldConfig.dataSource?.value || null }
        })

        // note the default initial value is an empty string, this is done to reduce possible breakage
        // as the '' was previous default value if there was no other data for the field
        // TODO null is a better alternative
        const fieldInitialValue = initialValues && initialValues[fieldKey] || '';

        // TODO clean up leftover __initialValues here

        // create the form control based on the field configuration object
        const formControl = formControlFactory(fieldConfig, this.formBuilder, {
          // Note: initial values for CFGs are handled in `initializeCfgFormGroup` and `initializeCfgFormArray`
          initialValue: fieldInitialValue,
          editMode: options.editMode,
          contentLocaleId: options.contentLocaleId,
        });

        formModelAccumulator[fieldKey] = formControl;
        return formModelAccumulator;
      },
      {
        // TODO clean up if empty
        __sysDataSource: this.formBuilder.control({})
      }
    );

    return this.formBuilder.group(formModel);
  }


  initializeCfgFormGroup(cfg: FieldGroup, fieldControlGroup, isEditingMode, activeLocale, initialValues, initialContextValues) {
    // flatten CFG field definitions and create the form group model for the CFG
    const flatFieldDefinitions = flattenFieldGroupDefinitions(cfg.fieldDefinitions);
    // determine which fields will be mapped from/to parent form group
    const listOfRootPropertyMappings = get(cfg, 'propertyMapping.mapToRoot', null);

    // pass in both initial and initial context values
    this.applySingleCfgConfigToFormGroup(
      flatFieldDefinitions,
      fieldControlGroup,
      {
        listOfRootPropertyMappings,
        editMode: isEditingMode,
        initialValues,
        initialContextValues,
        contentLocaleId: activeLocale?.id || null,
      }
    );

    // bind mapped fields so that their values are synced in the parent form
    this.initControlsToBeMappedToRoot(listOfRootPropertyMappings, fieldControlGroup);
    // add id of the group to the saved data for easier processing on the Composer
    fieldControlGroup.addControl('__cfgId', new UntypedFormControl(cfg.id));

    // if any field is of EDS type and has saveLabels set to true, add __sysLabels control
    if (this.anyFieldHasSaveLabelsEnabled(flatFieldDefinitions)) {
      fieldControlGroup.addControl('__sysLabels', new UntypedFormControl({}));
    }

    if(this.anyFieldIsExternalDataSource(flatFieldDefinitions)) {
      fieldControlGroup.addControl('__sysEdsUserData', new UntypedFormControl({}));
      fieldControlGroup.addControl('__sysEdsOptionsData', new UntypedFormControl({}));
    }
  }

  /**
   * This method instantiates form controls based on the `fieldConfigs` input and adds them to the
   * `formGroup` provided. Primary use case of the method is for custom field groups - once the
   * custom field group is fetched then this function is invoked to generate form group as per
   * the custom field group definition.
   *
   * It is separate from `createFormGroup` as the logic is slightly different, but field instantiation
   * logic is almost the same.
   *
   * @param fieldConfigs array of field configurations describing how to instantiate form controls
   * @param formGroup this is the FormGroup on which the fields specified by `fieldConfigs` will be added
   * @param options currently allows for initial values to be passed in or loaded from form group
   * @returns `formGroup` after mutation, can be ignored as the input itself will be modified
   */
  applySingleCfgConfigToFormGroup(
    fieldConfigs: WidgetTypeFieldConfiguration[],
    formGroup: UntypedFormGroup,
    options = {
      initialValues: null,
      initialContextValues: null,
      listOfRootPropertyMappings: null,
      editMode: true,
      contentLocaleId: null
    }
  ): UntypedFormGroup {
    if (!formGroup || !fieldConfigs) {
      console.warn('Missing mandatory method parameters!');
      return;
    }

    // resolve initial values object
    let initialValues = options.initialValues || {};
    // extract context values
    const initialContextValues = options.initialContextValues || {};

    // assign initial values for root mapped fields, i.e. PULL the values from parent form into the CFG
    // note that initial data pull will overwrite the initial CFG values
    // i.e. values on root form overwrite initial values in CFG form if the mapping is defined
    const listOfRootPropertyMappings: string[] = get(options, 'listOfRootPropertyMappings', null);
    if (listOfRootPropertyMappings) {
      listOfRootPropertyMappings.forEach(keyToBeMapped => {
        // if the initial context value is null or undefined, fallback to default initial value
        // note that empty strings from context level WILL be pulled into the cfg, but not null or undefined
        const initialValueFromContext = initialContextValues[keyToBeMapped] ?? initialValues[keyToBeMapped];
        if (initialValueFromContext) {
          initialValues[keyToBeMapped] = initialValueFromContext;
        }
      });
    }

    // we're saving data for selected options, as well as potential arbitrary data from the users
    if(this.anyFieldIsExternalDataSource(fieldConfigs)) {
      if (formGroup.get('__sysEdsOptionsData')) {
        formGroup.get('__sysEdsOptionsData').setValue(initialValues.__sysEdsOptionsData || {});
      } else {
        formGroup.addControl('__sysEdsOptionsData', new UntypedFormControl(initialValues.__sysEdsOptionsData || {}));
      }

      if (formGroup.get('__sysEdsUserData')) {
        formGroup.get('__sysEdsUserData').setValue(initialValues.__sysEdsUserData || {});
      } else {
        formGroup.addControl('__sysEdsUserData', new UntypedFormControl(initialValues.__sysEdsUserData || {}));
      }
    }

    // if any field is of EDS type and has saveLabels set to true, add __sysLabels control
    const addLabelsControlWithInitialValues = initialValues?.__sysLabels && this.anyFieldHasSaveLabelsEnabled(fieldConfigs);
    if (addLabelsControlWithInitialValues) {
      const sanitizedInitialValues = this.sanitizeSystemLabelsInitialData(
        initialValues.__sysLabels,
        fieldConfigs
      );

      // if the field control exists, set its value, else add it with the initial value
      if (formGroup.get('__sysLabels')) {
        formGroup.get('__sysLabels').setValue(sanitizedInitialValues);
      } else {
        formGroup.addControl('__sysLabels', new UntypedFormControl(sanitizedInitialValues));
      }
    }

     let cfgFieldDataSources = {};

    // instantiate and attach form controls to form group
    for (const fieldConfig of fieldConfigs) {
      const fieldKey = fieldConfig.key;
      const fieldInitialValue = initialValues && initialValues[fieldKey];
      const formControl = formControlFactory(fieldConfig, this.formBuilder, {
        editMode: options.editMode,
        initialValue: fieldInitialValue,
        contentLocaleId: options.contentLocaleId,
      });
      formGroup.addControl(fieldKey, formControl);

      cfgFieldDataSources = {
        ...cfgFieldDataSources,
        [fieldKey]: { type: fieldConfig.dataSource?.type, value: fieldConfig.dataSource?.value || null }
      }
    }

    // TODO clean up if empty
    const cfgFieldDataSourcesControl = new UntypedFormControl(cfgFieldDataSources);
    formGroup.addControl('__sysDataSource', cfgFieldDataSourcesControl);

    return formGroup;
  }

  /**
   * This method will make sure that if the root mapping is set up for a field inside CFG with a given key
   *
   * It ensures that parent form has controls in the form model into which mapped values can
   * be pushed into, by creating form controls where needed (without UI elements), as well as setting
   * their initial value
   *
   * This has been separated out of `FieldGroupBuilderComponent` since this bit of code would not execute if the
   * field was hidden. The reactive binding for UI controls is still in the `FieldGroupBuilderComponent` within
   * the original `bindControlsToBeMappedToRoot` method
   *
   * @param listOfRootPropertyMappings list of keys for which mappings are done
   */
  private initControlsToBeMappedToRoot(
    listOfRootPropertyMappings: string[],
    fieldControlGroup: UntypedFormGroup
  ) {
    if (!listOfRootPropertyMappings) {
      return;
    }

    // form group in which the CFG is embedded
    const parentFormGroup = fieldControlGroup.parent as UntypedFormGroup;
    const parentFormValues = parentFormGroup.value;

    listOfRootPropertyMappings.forEach((keyToBeMapped) => {
      // if missing, add a form control to parent form context so that root mapping can be achieved
      // without this - there is nowhere to map the value from the CFG and into parent form!
      if (!parentFormGroup.get(keyToBeMapped)) {
        parentFormGroup.addControl(keyToBeMapped, new UntypedFormControl(parentFormValues[keyToBeMapped]));
      }

      const cfgControlToMap = fieldControlGroup.get(keyToBeMapped);
      // bail if the specified key does not exist in the CFG
      if (!cfgControlToMap) {
        return;
      }

      // set the initial value for the root level mapped field
      parentFormGroup.get(keyToBeMapped).patchValue(cfgControlToMap.value);
    });
  }



  // make this usable
  initializeCfgFormArray(cfg: FieldGroup, formArray, isEditingMode, activeLocale, initialValues) {
    const flatFieldDefinitions = flattenFieldGroupDefinitions(cfg.fieldDefinitions);

    this.updateFormArrayWithConfig(
      flatFieldDefinitions,
      formArray,
      {
        loadInitialValuesFromFormArray: false,
        editMode: isEditingMode,
        initialValues,
        contentLocaleId: activeLocale?.id || null
      }
    );
    return;
  }

  /**
   * Similar to `updateFormGroupWithConfig` but intended for CFG lists, it is
   * intended to work with array of from fragments instead of a single form fragment
   * (i.e. with FormArray made out of CFG configurations rather than a single FormGroup made
   * with CFG configuration)
   */
  updateFormArrayWithConfig(
    fieldConfigs: WidgetTypeFieldConfiguration[],
    formArray: UntypedFormArray,
    options = {
      loadInitialValuesFromFormArray: true,
      initialValues: null,
      editMode: true,
      contentLocaleId: null
    }
  ): UntypedFormArray {
    // resolve initial values object
    let initialValues: any[] = options.initialValues || [];

    const initialValuesNotArray = !isArray(initialValues);
    if (!!initialValues && initialValuesNotArray) {
      console.warn('Incompatible initial values for CFG list, data may be overwritten! Verify form configuration.\n'
        + 'Current initial values: ', initialValues);
    }

    // in the case we have invalid or no initial values for the list, start with an empty list
    const invalidArrayInitialValues = initialValuesNotArray || isEmpty(initialValues);
    if (invalidArrayInitialValues) {
      if (formArray && formArray.clear) {
        formArray.clear();
      }
      return formArray;
    }

    initialValues
      .forEach(iv =>
        this.pushCustomFieldGroupIntoFormArray(
          fieldConfigs,
          formArray,
          { initialValues: iv, editMode: options.editMode,
            contentLocaleId: options.contentLocaleId
          }
        )
      );

    return formArray;
  }

  /**
   * This method can be used to create a FormGroup based on custom field definitions,
   * and push it into a given formArray. Initial values can be passed via options.
   * Main intended usage is for CGF list form fragments.
   *
   * @param fieldConfigs list of custom field configurations
   * @param formArray Angular FormArray into which the new group is pushed
   * @param options contains initial values and editMode flag
   * @returns `formArray`
   */
  pushCustomFieldGroupIntoFormArray(
    fieldConfigs: WidgetTypeFieldConfiguration[],
    formArray: UntypedFormArray,
    options = {
      initialValues: null,
      editMode: true,
      contentLocaleId: null
    }
  ): UntypedFormArray {
    let cfgFieldDataSources = {};

    const formGroup = this.formBuilder.group({});
    for (const fieldConfig of fieldConfigs) {
      const fieldKey = fieldConfig.key;
      const fieldInitialValue = options.initialValues ? options.initialValues[fieldKey] : null;
      const formControl = formControlFactory(fieldConfig, this.formBuilder, {
        editMode: options.editMode,
        initialValue: fieldInitialValue,
        contentLocaleId: options.contentLocaleId
      });
      formGroup.addControl(fieldKey, formControl);

      cfgFieldDataSources = {
        ...cfgFieldDataSources,
        [fieldKey]: { type: fieldConfig.dataSource?.type, value: fieldConfig.dataSource?.value || null }
      }
    }

    // TODO clean up if empty
    const cfgFieldDataSourcesControl = new UntypedFormControl(cfgFieldDataSources);
    formGroup.addControl('__sysDataSource', cfgFieldDataSourcesControl);

    // if any field is of EDS type and has saveLabels set to true, add __sysLabels control
    if (this.anyFieldHasSaveLabelsEnabled(fieldConfigs)) {
      formGroup.addControl('__sysLabels', new UntypedFormControl({}));
    }

    if(this.anyFieldIsExternalDataSource(fieldConfigs)) {
      formGroup.addControl('__sysEdsOptionsData', new UntypedFormControl({}));
      formGroup.addControl('__sysEdsUserData', new UntypedFormControl({}));
    }

    formArray.push(formGroup);
    return formArray;
  }

  /**
   * Used to initialize reactive form models for custom data panel config from GPP field config array.
   * E.g. custom data panel on article type, collection type, CFG forms etc.
   *
   * @param panelConfiguration list of GPP custom field definitions
   * @param panelFormArray valid FormArray instance which will be used for panel form model
   * @returns void, as form fields are added to `panelFormArray`
   */
  initializeCustomDataPanelForm(
    panelConfiguration: WidgetTypeFieldConfiguration[],
    panelFormArray: UntypedFormArray
  ) {
    if (!panelConfiguration) {
      return;
    }

    const validFormArrayProvided = !!panelFormArray && panelFormArray instanceof UntypedFormArray;
    if (!validFormArrayProvided) {
      throw new Error('A valid FormArray instance must be passed for custom data model to be initialized!');
    }

    panelFormArray.clear();

    (panelConfiguration || []).forEach(confObject => {
      this.addCustomFieldToFormArray(
        panelFormArray,
        { initialConfiguration: confObject }
      );
    });
  }

  /**
   * This method is used to add a new custom field definition form model to a list of custom fields.
   * Operates on parentFormArray and returns void. Is used both to insert new empty fields, and to
   * recreate already configured fields with values passed in initialConfiguration.
   *
   * When creating new custom field configuration, `createGroup` can be passed in options to
   * create a new CFG form fragment config. This is the only way to create new group type fields.
   * Though note that previously created configs will still be correctly populated from `initialConfiguration`
   * irrespective of `createGroup` flag.
   *
   * @param parentFormArray FormArray of custom field definitions
   * @param options is the definition for CFG and values for populating the form
   * @returns the modified `parentFormArray`
   */
  addCustomFieldToFormArray(
    parentFormArray: UntypedFormArray,
    options: {
      createGroup?: boolean;
      initialConfiguration?: WidgetTypeFieldConfiguration;
    } = {}
  ) {
    const createGroup = get(options, 'createGroup', false);
    // create a reactive form model for the field configuration
    const fg: UntypedFormGroup = this.formBuilder.group({
      key: [
        '',
        [Validators.required, doubleUnderscorePrefixValidator],
        [getCustomFieldKeyUniquenessValidator(parentFormArray)],
      ],
      label: [''],
      description: [''],
      fieldType: [createGroup ? 'group' : 'input'],
      inputType: [createGroup ? 'single' : 'text'],
      defaultValue: [],
      localizedDefaultValues: this.formBuilder.array([]),
      readOnly: [false],
      hidden: [false],
      dataSource: this.formBuilder.group({
        type: [createGroup ? DataSourceOptions.Group : DataSourceOptions.NoData ],
        value: [],
        allowedTypes: [[]],
        allowedTaxonomies: [[]],
        allowedTaxonomySubtrees: [[]],
      }),
      validators: this.formBuilder.array([]),
      allowedTypes: [[]],
      displayOptions: this.formBuilder.group({
        formFragmentListLabelField: [null]
      }),
      allowedTaxonomies: [[]],
      allowedTaxonomySubtrees: [[]],
    });

    // if the initial configuration is supplied, use that to seed the above form group
    if (options.initialConfiguration) {
      const configuration: WidgetTypeFieldConfiguration = options.initialConfiguration;

      // if there are validators in the configuration, prime the `validators` FormArray,
      // otherwise the validators array cannot be properly patched with initial configuration
      if (configuration.validators && configuration.validators.length) {
        const validatorControls: UntypedFormArray = fg.get('validators') as UntypedFormArray;
        for (const v of configuration.validators) {
          validatorControls.push(this.formBuilder.control(null));
        }
      }

      // add values for the localized variants of default values
      const localizedDefaultValuesConfig = get(options, 'initialConfiguration.localizedDefaultValues', []);
      const localizedDefaultValuesForm = (fg.get('localizedDefaultValues') as UntypedFormArray);
      localizedDefaultValuesConfig.forEach(localizedDefaultValue => {
        const ldfFormGroup = this.buildLocalizedDefaultValuesFormGroup(localizedDefaultValue, configuration);
        localizedDefaultValuesForm.push(ldfFormGroup)
      });

      // added to handle breaking change caused by adding "mediaSelect" field type
      const isMediaField = ['images', 'galleries', 'files'].includes((configuration.dataSource?.value));
      if (isMediaField) {
        configuration.fieldType = 'mediaSelect';
        configuration.inputType = ['single', 'multiple'].includes(configuration.inputType) ? configuration.inputType : null;
      }

      if (configuration.dataSource === null) {
        delete configuration.dataSource;
      }
      fg.patchValue(configuration);
    }

    parentFormArray.push(fg);
    return parentFormArray;
  }

  buildLocalizedDefaultValuesFormGroup(localizedDefaultValue: LocalizedDefaultValue, fieldConfig): UntypedFormGroup {
    const formControl = formControlFactory(fieldConfig, this.formBuilder, {
      editMode: true,
      initialValue: localizedDefaultValue.value,
      contentLocaleId: null,
    });
    const formGroup = this.formBuilder.group({
      contentLocaleId: [localizedDefaultValue.contentLocaleId],
      value: formControl
    });
    return formGroup;
  }

  getFieldKeyPatternValidator() {
    const forbiddenPattern = /^(?!__).*/;
    return (selectedKeyControl: AbstractControl): { [key: string]: any | null } => {
      return new Promise((resolve) => setTimeout(() => resolve(null), 100)).then(() => {
        if (!forbiddenPattern.test(selectedKeyControl.value)) {
          return { pattern: true };
        }
        return null
      });
    };
  }

  anyFieldHasSaveLabelsEnabled(flatFieldDefinitions: WidgetTypeFieldConfiguration[]) {
    return flatFieldDefinitions.some((fieldConfig: WidgetTypeFieldConfiguration) =>
      get(fieldConfig, 'dataSource.value.saveLabels', false)
    );
  }

  anyFieldIsExternalDataSource(flatFieldDefinitions: WidgetTypeFieldConfiguration[]) {
    return flatFieldDefinitions.some((fieldConfig: WidgetTypeFieldConfiguration) => {
      const fieldDataSourceType = get(fieldConfig, 'dataSource.type', null);
      return fieldDataSourceType === DataSourceOptions.ExternalData
    });
  }

  private sanitizeSystemLabelsInitialData(initialData, fields) {
    if (get(fields, 'length', 0) === 0) {
      return {};
    }

    const keys: string[] = fields
      .filter((fieldConfig) => get(fieldConfig, 'dataSource.value.saveLabels', false))
      .map((fieldConfig) => fieldConfig.key);

    const sanitizedInitialValues = Object.entries(initialData)
      .filter(([key]) => keys.includes(key))
      .reduce((acc, [key, labelValue]) => {
        acc[key] = labelValue;
        return acc;
      }, {});

    return sanitizedInitialValues;
  }
}
