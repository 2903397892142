import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { get } from 'lodash-es';
import { Validators } from '@angular/forms';
import { checkIfRichTextEditorIsSet } from './rich-text-editor-validator';
import { hasRequiredValidation } from '../../widget-configuration/field-builder/has-required-validation/has-required-validation';
import { RichTextEditorTransformService } from './rich-text-editor-transform.service';
import { generateShortId } from '../../../shared/shared-functions';
import { getLocaleCode } from '../../../shared/editors/get-editor-locale';
import { addRelLink, addTargetLink, createEditorPreferencesToolbarOption } from '../../../shared/editors/froala/froala-config';
import { ModalsService } from '../../../shared/modals/modals.service';
import { clearTextEditor } from '../../content-editor/froala-clear-all-button';
import { UserPreferencesService } from '../../../core/api/user-preferences/user-preferences.service';
import { AccountSettingsService } from '../../../core/api/account-settings/accounts-settings.service';
import { convertStraightToCurlyQuotes } from '../../../core/store/utility-functions/convert-straight-to-curly-quotes.utilities';
import * as striptags from 'striptags';

declare const $: any;

@Component({
  selector: 'gd-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RichTextEditorTransformService]
})
export class RichTextEditorComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('editor', { static: true }) editorBodyRef: ElementRef;

  @Output() editorChange = new EventEmitter<any>();
  @Input() editorData;
  @Input() froalaConfigOptions;
  @Input() hasActionPermission = true;
  @Input() authorEditorButtons;
  @Input() fieldControl;
  @Input() fieldConfig;
  @Input() readOnlyMode = false;
  @Input() usage = '';

  // flag handled by parent component, note that this controls the onchanges hook
  @Input() editorChangesEnabled = false;
  @Input() transformServiceEnabled = false;

  isFormControlRequired = false;
  froalaToolbarButtons = [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'fontSize',
    'subscript',
    'superscript',
    '|',
    'paragraphFormat',
    '|',
    'insertLink',
    'specialCharacters',
    'insertHR',
    '|',
    'clearFormatting',
    'clearTextEditor',
    '|',
    'undo',
    'redo',
    '|',
  ];
  froalaConfig = {
    language: getLocaleCode(),
    key: environment.froalaKey,
    iconsTemplate: 'font_awesome_5',
    imageDefaultDisplay: 'block',
    imageSplitHTML: true,
    imageUpload: false,
    toolbarButtons: this.froalaToolbarButtons,
    toolbarButtonsMD: this.froalaToolbarButtons,
    toolbarButtonsSM: this.froalaToolbarButtons,
    toolbarButtonsXS: this.froalaToolbarButtons,
    scrollableContainer: '.main-container',
    heightMin: 300,
    imageDefaultWidth: 0,
    shortcutsEnabled: [
      'show',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'outdent',
      'undo',
      'redo',
      'insertLink',
      'help',
    ],
    imageResize: false,
    placeholderText: 'Type something',
    charCounterCount: false,
    quickInsertEnabled: true,
    linkInsertButtons: ['linkBack'],
    linkEditButtons: ['linkOpen', 'linkEdit', 'addRel', 'linkRemove'],
  };

  get smartQuotesEnabled() {
    const userDefinedValue = this.userPreferenceService.getUserPreference('editorPreferences.smartQuotesEnabled');
    const systemDefinedValue = this.accountSettingsService.getSmartQuotesEnabledFlag();

    return typeof userDefinedValue === 'boolean' ? userDefinedValue : systemDefinedValue;
  }

  constructor(
    private rteTransform: RichTextEditorTransformService,
    private modalsService: ModalsService,
    private userPreferenceService: UserPreferencesService,
    private accountSettingsService: AccountSettingsService,
  ) { }

  editor = null;
  contentModel;
  counterChangeTimeout;
  editorId;
  ngOnInit() {
    this.editorId = generateShortId();
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);

    if (this.isFormControlRequired) {
      this.fieldControl.setValidators([Validators.required, checkIfRichTextEditorIsSet]);
      this.fieldControl.updateValueAndValidity();
    }
    const toolbarButtons = this.authorEditorButtons || this.froalaToolbarButtons;
    if (this.accountSettingsService.getShowEditorPreferencesFlag()) {
      toolbarButtons.push(`editorPreferences${this.editorId}`);
      createEditorPreferencesToolbarOption(this, `editorPreferences${this.editorId}`);
    }
    this.froalaConfig = {
      ...this.froalaConfig,
      ...this.froalaConfigOptions,
      toolbarButtons: toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsXS: toolbarButtons,
    };
    this.editor = $(this.editorBodyRef.nativeElement)
      .on('froalaEditor.initialized', (e, editor) => {
        if (this.editorData) {
          editor.$el[0].innerHTML = this.editorData;
        }
        if (!this.hasActionPermission || this.readOnlyMode) {
          editor.edit.off();
        }
        // Custom counter for words and characters
        this.addCustomWordCounter(editor);
        this.resetTimeout(editor);
      })
      .froalaEditor(this.froalaConfig)
      .on('froalaEditor.contentChanged', (e, editor) => {
        this.translateAndEmit(editor);
      })
      .on('froalaEditor.paste.beforeCleanup', (e, editor, clipboardHtml) => {
        if (!clipboardHtml.match || !this.transformServiceEnabled) {
          return;
        }
        // check for post mention url match
        const postMentionLink = clipboardHtml.match(/(?:live-reporting\/)([0-9]+)\/posts\/([0-9]+)/);
        // return modified post mention
        if (postMentionLink) {
          return this.rteTransform.transformPostLink(clipboardHtml);
        }
        return clipboardHtml;
      })
      .on('froalaEditor.events.keypress', (_, editor, event) => {
        const eventKey = event.originalEvent.key;
        if (!this.smartQuotesEnabled || !['\'', '"'].includes(eventKey)) {
          return true;
        }
        // if smart quotes are enabled then ' and " need special treatment (need to be converted to curly)
        let pElement = editor.selection.element();
        if (pElement.tagName !== 'P') {
          pElement = this.getMainParentParent(pElement);
        }
        const characterMap = {
          '\'': '&#39;',
          '"': '&quot;',
          '‘': '&lsquo;',
          '’': '&rsquo;',
          '“': '&ldquo;',
          '”': '&rdquo;',
        };
        let text = striptags(pElement.outerHTML, []).replace(/&nbsp;|\u200B|\u200D|\uFEFF/g, ' ');
        text = striptags(convertStraightToCurlyQuotes('<p>' + text + characterMap[eventKey] + '</p>'), []);
        const newKey = Object.keys(characterMap).find((key) => text.endsWith(characterMap[key]));
        if (!newKey) {
          return true;
        }
        editor.html.insert(newKey);
        event.stopPropagation();
        event.preventDefault();
        return false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const editorInstance = this.editor?.data('froala.editor');
    // eslint-disable-next-line guard-for-in
    for (const propName in changes) {
      const isChangeValid = editorInstance && changes.hasOwnProperty(propName) && this.editorChangesEnabled;
      if (!isChangeValid) {
        return;
      }
      switch (propName) {
        case 'editorData': {
          editorInstance.html.set(this.editorData);
          this.resetTimeout(editorInstance);
          break;
        }
        case 'hasActionPermission': {
          this.toggleEditorMode(this.hasActionPermission, editorInstance);
          break;
        }
      }
    }
  }

  // ensure placeholder text doesn't go over editor content
  // note: we're using onChanges hook and modifying the editor html without destroying the editor instance
  ngAfterViewInit() {
    addRelLink();
    this.editor.froalaEditor('placeholder.refresh');
    clearTextEditor(this.modalsService);
  }

  // disable or enable editor based on hasActionPermission
  toggleEditorMode(state, editor): void {
    if (state === true) {
      editor.edit.on();
    } else {
      editor.edit.off();
    }
  }

  resetTimeout(editor): void {
    clearTimeout(this.counterChangeTimeout);
    this.counterChangeTimeout = setTimeout(() => {
      this.updateFroalaCounter(editor);
    }, 200);
  }

  translateAndEmit(editor) {

    const elements = editor.$el.get();
    const emptyEditor = this.checkIfEmptyEditor(elements);

    if (emptyEditor) {
      this.editorChange.emit('');
      this.resetTimeout(editor);
      return;
    }

    const editorContent = editor.$el[0].innerHTML;
    this.editorChange.emit(editorContent);
    this.resetTimeout(editor);
  }

  updateFroalaCounter(editor) {
    // updated selector due to old one not reliably finding the element in DOM
    const element = editor.$oel[0].querySelector('.fr-counter');
    if (!element) {
      return;
    }
    const counter = { word: 0, char: 0 };
    editor.html.blocks().forEach((block) => {
      const response = this.countWordsInBlock(block);
      counter.word += response.word;
      counter.char += response.char;
    });
    element.innerHTML = $localize `Words: ${counter.word} | Characters: ${counter.char}`;
  }

  countWordsInBlock(block): any {
    const counter = { word: 0, char: 0 };
    block.childNodes.forEach((node) => {
      if (node.nodeName === 'GD-EDITOR-NOTE') {
        return counter;
      }
      if (node.nodeName === '#text') {
        const words =
          (node.nodeValue.match(/\S+/g) &&
            node.nodeValue.match(/\S+/g).filter((word) => word.length > 0 && word !== '&#8203;')) ||
          [];

        counter.word += words.length;
        counter.char += node.nodeValue.length;
        return counter;
      }
      const tmpCounter =
        (!node.classList.contains('gd-ember-wrapper') && this.countWordsInBlock(node)) || counter;
      counter.word += tmpCounter.word;
      counter.char += tmpCounter.char;
      return counter;
    });
    return counter;
  }

  addCustomWordCounter(editor) {
    const counterRef = document.getElementsByClassName('fr-counter')[0];
    if (!counterRef) {
      const counter = document.createElement('SPAN');
      counter.innerText = '0';
      counter.classList.add('fr-counter');
      editor.$oel[0].appendChild(counter);
    }
  }

  checkIfEmptyEditor(htmlElements) {

    if (htmlElements.length === 0) {
      return true;
    }

    if (htmlElements.length > 1) {
      return false;
    }

    const firstElement = htmlElements[0];

    if (firstElement.childNodes.length > 1) {
      return false;
    }

    const firstChildNode = firstElement.childNodes[0];

    const brTagInsideEmptyParagraph = firstChildNode.nodeName === 'P'
      && firstChildNode.childNodes.length === 1
      && firstChildNode.childNodes[0].nodeName === 'BR';

    const noAttributes = firstChildNode.attributes.length === 0;

    const emptyStyleAttribute = firstChildNode.attributes.length === 1
      && firstChildNode.attributes[0].nodeName === 'style'
      && firstChildNode.attributes[0].value === "";

    // to cover case with no attributes on paragraph (<p><br></p>) as well as case with empty style attribute (<p style=""><br></p>)
    const defaultAttributes = noAttributes || emptyStyleAttribute

    if (brTagInsideEmptyParagraph && defaultAttributes) {
      return true;
    }

    return false;
  }

  handleSmartQuotesSettingChange(smartQuotesEnabled) {
    this.userPreferenceService.setUserPreference('editorPreferences.smartQuotesEnabled', smartQuotesEnabled);
  }

  getMainParentParent(element) {
    if (!element.parentElement) {
      return element;
    }
    const parentElementTags = ['P', 'TD', 'H1', 'H2', 'H3', 'H4', 'PRE'];
    const foundElementParent = parentElementTags.find(tag => tag === element.parentElement.tagName);
    if (!foundElementParent) {
      return this.getMainParentParent(element.parentElement);
    }
    // prevent removing a table cell
    if (foundElementParent === 'TD') {
      return element;
    }
    return element.parentElement;
  }
}
