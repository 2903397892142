import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SidenavService } from '../../sidenav/sidenav.service';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.model';
import { Subscription } from 'rxjs';
import { UserPreferencesService } from '../../../core/api/user-preferences/user-preferences.service';
import { debounceTime, filter, map, mergeMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'gd-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoritesComponent implements OnInit {

  sidenavItems: SidenavItem[] = [];
  currentFavorites: SidenavItem[] = [];
  currentFavoritesLocalStorage = [];


  _displayingItems: SidenavItem[] = [];
  get displayingItems() {
    if (this.searchValue) {
      return this.sidenavItems.filter((item) => {
        return item.name.toUpperCase().includes(this.searchValue.toUpperCase());
      });
    } else {
      return this.currentFavorites;
    }
  }

  searchValue: string;

  sortableOptions = {
    animation: 200
  };

  private _sidenavItemsSubscriptions: Subscription;

  constructor(
    private sidenavService: SidenavService,
    private userPreferenceService: UserPreferencesService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    let addedDemoShortcuts: boolean = false;
    const lsFavorites = this.userPreferenceService.getUserPreference('currentFavorites');
    this._sidenavItemsSubscriptions = this.sidenavService.items$
      .pipe(
        debounceTime(200),
        mergeMap(items => {
          return new Promise(resolve => resolve(items));
        }),
        take(1)
      )
      // subscribe to full list, prevent checking for specific length
      .subscribe((items: SidenavItem[]) => {
        this.sidenavItems = [];
        items.forEach((item) => {
          this.merge(this.sidenavItems, item);
        });

        addedDemoShortcuts = true;
        if (lsFavorites && this.sidenavItems.length) {
          lsFavorites.forEach(el => {
            this.toggleFavorite(this.sidenavItems[el]);
            this.cdr.detectChanges();
          });
        }
      });
  }

  merge(collector, item) {
    if (item.subItems && item.subItems.length > 0) {
      item.subItems.forEach((subItem) => {
        this.merge(collector, subItem);
      });
    }

    collector.push(item);
  }

  isFavorite(item: SidenavItem) {
    return (this.currentFavorites.indexOf(item) > -1);
  }

  toggleFavorite(item: SidenavItem) {
    const index = this.currentFavorites.indexOf(item);
    const sidenavItemsIndex = this.sidenavItems.findIndex(e => e.id === item.id);
    const lsIndex = this.currentFavoritesLocalStorage.indexOf(sidenavItemsIndex);

    if (index > -1) {
      this.currentFavorites.splice(index, 1);
      this.currentFavoritesLocalStorage.splice(lsIndex, 1);
    } else {
      this.currentFavorites.push(item);
      this.currentFavoritesLocalStorage.push(sidenavItemsIndex);
    }
    this.userPreferenceService.setUserPreference('currentFavorites', this.currentFavoritesLocalStorage);
  }

  clearSearchInput() {
    this.searchValue = '';
  }

  // enable accessibility keys
  handleKeyDown(event) {
    const allowedKeyCodes = [13, 27, 37, 38, 39, 40];
    if (allowedKeyCodes.includes(event.keyCode)) {
      return;
    }
    event.stopPropagation();
  }
}
