<div class="gd-checkbox-component">
  <div class="gd-checkbox-component__label">
    <label class="gd-configuration__label"
    [class.--error]="fieldControl.hasError('required') && fieldControl.touched">{{label}}{{isFormControlRequired ? ' *' : ''}}</label>

    <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
    class="question-icon"
    [matTooltip]="infoTooltip"
    matTooltipPosition="above" matSuffix fontSet="fal"
    fontIcon="fa-question-circle"></mat-icon>
  </div>
  <ul class="gd-checkbox-list gd-field-builder-element-spacing">
    <li class="gd-checkbox-item" *ngFor="let item of checkboxGroupOptions" [class.--read-only]="readOnlyMode">
      <mat-checkbox [checked]="selectedOptionsMap[item.value]"
                    (change)="onCheckboxChange($event, item)">
        {{item.label}}
      </mat-checkbox>
    </li>
  </ul>
  <div *ngIf="isFormControlRequired"
    class="gd-configuration__error-message"
    [class.--visible]="fieldControl.hasError('required') && fieldControl.touched">
    {{ fieldConfig.label }} is required.
  </div>
</div>
