import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, ActiveUserTokenState } from './auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private storageService: StorageService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // already logged in, continue
    if (this.authService.isLoggedIn) {
      return true;
    }

    // check the state of the loaded auth token, it can be valid, expired or it doesn't exist
    const authTokenState: ActiveUserTokenState = await this.authService
      .loadActiveIfValid()
      .catch((err) => {
        console.log('Failed to load active user token:\n' + err.toString());
        // save the attempted navigation url for later redirect from login if needed
        this.authService.redirectUrl = state.url;
        return ActiveUserTokenState.NoToken;
      });

    // A-OK, continue
    if (authTokenState === ActiveUserTokenState.Valid) {
      return true;
    }

    // going beyond this point, there is either no active user or their token isn't valid

    // TODO verify this message is no longer relevan and remove this bit of code
    // if the token has expired, let the user know
    if (authTokenState === ActiveUserTokenState.Expired) {
      console.log('User session has expired');
      // this.snackBar.open('Your session has expired.', $localize`Close`, { duration: 20000 });
    }

    // save the attempted navigation url for later redirect from login if needed
    this.authService.redirectUrl = state.url;

    // logout and block route activation
    this.authService.logout({ redirectToLogin: true, resetRedirectUrl: false });
    return false;
  }
}
