import { get } from 'lodash-es';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { getContentTags } from '../../core/store/content-tags/content-tags.reducer';
import { GetContentTagsAction } from '../../core/store/content-tags/content-tags.actions';
import { EmbeddedType } from '../../core/store/article/article-content.model';
import { generateShortId } from '../shared-functions';
import { GlideContentActionBusService } from '../../core/body-content/glide-content-actions-bus.service';
import { BidiService } from '../../core/i18n/bidi.service';
import { map, take } from 'rxjs/operators';

declare const $: any;
@Component({
  selector: 'gd-add-content-tag-dialog',
  templateUrl: './add-content-tag-dialog.component.html',
  styleUrls: ['./add-content-tag-dialog.component.scss']
})
export class AddContentTagDialogComponent implements OnInit {

  contentTagForm: UntypedFormGroup = this.formBuilder.group({
    id: [null],
    title: [''],
    identifier: ['', Validators.required],
    includeClosingMarker: [false],
    colour: [''],
  });

  contentTags$ = this.store.select(getContentTags);
  usage = 'template';
  editingMode = false;
  isInsideTable = false;
  hideContentTagName = false;

  glideContentType = 'ARTICLE';
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<AddContentTagDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private actionBus: GlideContentActionBusService,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.glideContentType = get(this.dialogInputData, 'glideContentType', 'ARTICLE');
    this.store.dispatch(new GetContentTagsAction({ pageSize: 100 }));
    this.contentTagForm.patchValue(this.dialogInputData);
    this.usage = this.dialogInputData.usage;
    this.editingMode = this.dialogInputData.editingMode;
    if (this.usage === 'template') {
      return;
    }
    this.isInsideTable = !!$(document.getElementById(this.dialogInputData.blockId)).closest('td').length;
    if (this.isInsideTable) {
      this.hideContentTagName = $(document.getElementById(this.dialogInputData.blockId)).closest('tr').children().length > 4;
    }
  }

  selectContentTag(contentTag) {
    this.contentTagForm.patchValue({ ...contentTag, title: contentTag.name });
  }

  save() {
    // on templates we basically just dump the form value back and let the caller handle the rest
    if (this.dialogInputData.usage === 'template') {
      return this.dialogRef.close(this.contentTagForm.value);
    }

    // handling the content tags in content body editor
    const openingMarkerBlockId = this.editingMode ? this.dialogInputData.blockId : generateShortId();
    const includeClosingMarker = !!this.contentTagForm.value.includeClosingMarker;
    const openingMarkerNode = this.createOpeningMarkerNode(openingMarkerBlockId, includeClosingMarker);

    if (this.editingMode) {
      // update closing marker if exist
      this.getClosingMarkerNode(openingMarkerBlockId).subscribe((closingMarkerContentNode: any) => {
        const markerNodes = !!closingMarkerContentNode
          ? [openingMarkerNode, this.createClosingMarkerNode(openingMarkerNode, closingMarkerContentNode.id)]
          : [openingMarkerNode];
        this.dialogRef.close({ payload: markerNodes });
      });
      return;
    }

    // determine payload, opening or opening and closing marker nodes, and close dialog
    const markerNodes = includeClosingMarker
      ? [openingMarkerNode, this.createClosingMarkerNode(openingMarkerNode)]
      : [openingMarkerNode];
    this.dialogRef.close({ payload: markerNodes });
  }

  createOpeningMarkerNode(openingMarkerBlockId, includeClosingMarker) {
    const colour = this.contentTagForm.value.colour;
    const focusedElementId = includeClosingMarker ? generateShortId() : openingMarkerBlockId;
    const closingMarkerId = includeClosingMarker ? generateShortId() : null;
    return {
      type: this.dialogInputData.embedType,
      blockId: openingMarkerBlockId,
      colour,
      focusedElementId,
      closingMarkerId,
      title: this.contentTagForm.value.title,
      hideContentTagName: this.hideContentTagName,
      updateData: this.contentTagForm.value
    };
  }

  createClosingMarkerNode(openingMarkerNode, closingMarkerId = null) {
    return {
      ...openingMarkerNode,
      blockId: closingMarkerId || openingMarkerNode.closingMarkerId,
      startingMarkerId: openingMarkerNode.blockId,
      updateData: {
        ...openingMarkerNode.updateData,
        startingMarkerId: openingMarkerNode.blockId
      }
    };
  }

  getClosingMarkerNode(openingMarkerBlockId) {
    return this.actionBus.getContentNodes(this.glideContentType)
    .pipe(
      take(1),
      map(contentNodes => {
        const closingMarkerContentNode = Object.values(contentNodes)
          .filter((item: any) => item.type === EmbeddedType.ContentTag)
          .find((item: any) => item.startingMarkerId === openingMarkerBlockId);
        return closingMarkerContentNode;
      })
    )
  }

  setFocus(editor, elementId) {
    const anchor = document.getElementById(elementId);
    editor.events.focus();
    editor.selection.setAtStart(anchor);
    editor.selection.restore();
  }
}
