import { fieldGroupsReducer, FieldGroupState, getFieldGroupsState } from './field-groups/field-groups.reducer';
import { ActionReducer, combineReducers, compose } from '@ngrx/store';
import { ActionReducerMap } from '@ngrx/store';

import { ActiveArticleState, articleReducer } from './article';
import { articleListReducer, ArticlesList } from './articles-list';
import { ACCOUNT_CHANGED, authReducer, AuthState, LOG_OUT } from './auth/';
import { TaxonomiesState, taxonomiesReducer } from './taxonomies';

import { environment } from '../../../environments/environment';
import { routeReducer } from './routes/route.reducers';
import { widgetTypesReducer } from './widget-types/widget-types.reducer';
import { imagesReducer, ImagesState, } from './images/images.reducer';
import { imagesConfigurationReducer, ImagesConfigurationState } from './images-configuration/images-configuration.reducer';
import { usersReducer, UsersState } from './users/users.reducer';
import { StatusWorkflowsState, workflowsReducer } from './workflows/workflow.reducer';
import { GalleriesState } from './galleries/galleries.model';
import { TemplatesState, templatesReducer } from './templates/templates.reducer';
import { galleriesReducer } from './galleries/galleries.reducer';
import { galleriesWorkflowReducer, GalleriesWorkflowState } from './galleries-workflow/galleries-workflow.reducer';
import { ImagesUploadState, imagesUploadReducer } from './images-upload/images-upload.reducer';
import { AdvertsState, advertsReducer } from './adverts/adverts.reducer';
import { advertsGroupReducer, AdvertGroupsState } from './adverts-group/adverts-group.reducer';
import { PagesState, pagesReducer } from './pages/pages.reducer';
import { UISectionTitleState, uiSectionTitleReducer } from './ui-section-title/ui-section-title.reducer';
import { MenusState, menusReducer } from './menus/menus.reducer';
import { AccountSettingsState, accountSettingsReducer } from './account-settings/account-settings.reducer';
import { HtmlSnippetsState, htmlSnippetsReducer } from './html-snippets/html-snippets.reducer';
import { lockReducer, LockState } from './lock/lock.reducer';
import { ArticleTypeState, articleTypesReducer } from './article-type/article-type.reducer';
import { AuthorsState, authorsReducer } from './authors/authors.reducer';
import { redirectsReducer, RedirectsState } from './redirects/redirects.reducer';
import { ContentPanelsState, contentPanelsReducer } from './content-panels/content-panels.reducer';
import { CollectionTypeState, collectionTypesReducer } from './collection-type/collection-type.reducer';
import { collectionReducer } from './collection/collection.reducer';
import { CollectionListState } from './collection-list/collection-list.model';
import { collectionListReducer } from './collection-list/collection-list.reducer';
import { ActiveCollectionState } from './collection/collection.model';
import { FilesState, filesReducer } from './files/files.reducer';
import { FilesUploadState, filesUploadReducer } from './files-upload/files-upload.reducer';
import { ContentTagsState, contentTypesReducer } from './content-tags/content-tags.reducer';
import { SSOState, ssoReducer } from './sso/sso.reducer';
import { TaxonomyConfigurationState, taxonomyConfigurationReducer } from './taxonomy-configuration/taxonomy-configuration.reducer';
import { AdvancedFiltersState } from './advanced-filtering-option/advanced-filtering-option.model';
import { advancedFilteringReducer } from './advanced-filtering-option/advanced-filtering-option.reducer';
import { accessBundlesReducer, AccessBundlesState } from './access-bundles/access-bundles.reducer';
import { verifySettingsReducer, VerifySettingsState } from './verify-settings/verify-settings.reducer';
import { ActiveLiveReportState } from './live-report/live-report.model';
import { activeLiveReportReducer } from './live-report/live-report.reducer';
import { liveReportsListReducer, LiveReportsListState } from './live-reports-list/live-reports-list.reducer';
import { postsReducer } from './posts/posts.reducer';
import { PostsState } from './posts/posts.model';
import { articleQuickViewReducer } from './article-quick-view/article-quick-view.reducers';
import { ArticleQuickViewState } from './article-quick-view/article-quick-view.model';
import { systemNotificationReducer, SystemNotificationState } from './system-notifications/system-notifications.reducer';
import { subscribersReducer, SubscribersState } from './subscribers/subscribers.reducer';
import { contentLocalesReducer, ContentLocalesState } from './content-locales/content-locales.reducer';
import { LiveReportTypeState, liveReportTypeReducer } from './live-report-types/live-report-types.reducer';
import { referencedContentRepositoryReducer } from './referenced-content-repository/referenced-content-repository.reducer';

export interface AppState {
  auth: AuthState;
  articlesList: ArticlesList;
  activeArticle: ActiveArticleState;
  articleTypes: ArticleTypeState;
  workflows: StatusWorkflowsState;
  taxonomies: TaxonomiesState;
  pages: PagesState;
  templates: TemplatesState;
  images: ImagesState;
  galleries: GalleriesState;
  files: FilesState;
  filesUpload: FilesUploadState;
  galleriesWorkflow: GalleriesWorkflowState;
  imagesConfiguration: ImagesConfigurationState;
  imagesUpload: ImagesUploadState;
  users: UsersState;
  adverts: AdvertsState;
  advertsGroup: AdvertGroupsState;
  uiSectionTitle: UISectionTitleState;
  menus: MenusState;
  accountSettings: AccountSettingsState;
  htmlSnippets: HtmlSnippetsState;
  lock: LockState;
  authors: AuthorsState;
  redirects: RedirectsState;
  contentPanels: ContentPanelsState;
  collectionTypes: CollectionTypeState;
  collections: ActiveCollectionState;
  collectionList: CollectionListState;
  contentTags: ContentTagsState;
  sso: SSOState;
  taxonomyConfiguration: TaxonomyConfigurationState;
  fieldGroups: FieldGroupState;
  advancedFilters: AdvancedFiltersState;
  accessBundles: AccessBundlesState;
  verifySettings: VerifySettingsState;
  activeLiveReport: ActiveLiveReportState;
  liveReportsList: LiveReportsListState;
  posts: PostsState;
  articleQuickView: ArticleQuickViewState;
  systemNotifications:  SystemNotificationState;
  subscribers: SubscribersState;
  contentLocales: ContentLocalesState;
  liveReportType: LiveReportTypeState;
}

const reducers = {
  auth: authReducer,
  articlesList: articleListReducer,
  activeArticle: articleReducer,
  articleTypes: articleTypesReducer,
  workflows: workflowsReducer,
  pages: pagesReducer,
  templates: templatesReducer,
  taxonomies: taxonomiesReducer,
  routes: routeReducer,
  widgetTypes: widgetTypesReducer,
  images: imagesReducer,
  galleries: galleriesReducer,
  files: filesReducer,
  filesUpload: filesUploadReducer,
  galleriesWorkflow: galleriesWorkflowReducer,
  imagesConfiguration: imagesConfigurationReducer,
  imagesUpload: imagesUploadReducer,
  users: usersReducer,
  adverts: advertsReducer,
  advertsGroup: advertsGroupReducer,
  uiSectionTitle: uiSectionTitleReducer,
  menus: menusReducer,
  accountSettings: accountSettingsReducer,
  htmlSnippets: htmlSnippetsReducer,
  lock: lockReducer,
  authors: authorsReducer,
  redirects: redirectsReducer,
  contentPanels: contentPanelsReducer, collectionTypes: collectionTypesReducer,
  collections: collectionReducer,
  collectionList: collectionListReducer,
  contentTags: contentTypesReducer,
  sso: ssoReducer,
  taxonomyConfiguration: taxonomyConfigurationReducer,
  advancedFilters: advancedFilteringReducer,
  accessBundles: accessBundlesReducer,
  verifySettings: verifySettingsReducer,
  fieldGroups: fieldGroupsReducer,
  activeLiveReport: activeLiveReportReducer,
  liveReportsList: liveReportsListReducer,
  posts: postsReducer,
  articleQuickView: articleQuickViewReducer,
  systemNotifications: systemNotificationReducer,
  subscribers: subscribersReducer,
  contentLocales: contentLocalesReducer,
  liveReportType: liveReportTypeReducer,
  referencedContentRepository: referencedContentRepositoryReducer,
};

const developmentReducer: ActionReducerMap<AppState> = reducers;
const productionReducer: ActionReducerMap<AppState> = reducers;

export const appReducer = environment.production
  ? productionReducer
  : developmentReducer;

export function clearState(reducer) {
  return function (state, action) {
    if ([LOG_OUT, ACCOUNT_CHANGED].includes(action.type)) {
      state = { lock: state.lock, auth: state.auth };
    }
    return reducer(state, action);
  };
}
