<div class="gd-dropzone"
     gdDragAndDrop
     (filesEmitter)="handleInput($event)"
     data-cy="dropzone-image-select-modal"
     [class.hide]="dzConfig.embedModal">
  <div class="gd-dropzone-input">
    <input type="file"
           #fileInput
           class="gd-dropzone-input-file"
           [id]="'dropzone-input-file-' + dialog"
           [multiple]="dzConfig.multiUpload"
           (change)="handleInput($event)">
    <label [for]="'dropzone-input-file-' + dialog"
           class="gd-dropzone-label"></label>
    <div class="gd-dropzone-message">
      <mat-icon svgIcon="glideCustomIcon_galleries"></mat-icon>
      <strong i18n>Drag and drop files here</strong>
      <span i18n>(or click to choose)</span>
    </div>
  </div>
</div>
