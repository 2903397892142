import {
  tap,
  filter,
  switchMap,
  takeUntil,
  delay,
  catchError,
  map,
  mergeMap,
  take,
} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, timer, Subject, of } from 'rxjs';
import { UnsafeAction } from '../unsafe-action.interface';
import { ImagesService } from '../../api/images/images.service';

import {
  GET_USER_CONFIGURATION,
  UPDATE_USER_CONFIGURATION,
  GetUserConfigurationCompleteAction,
  IMAGES_CONFIGURATION_ACTION_FAILED,
  ImagesConfigurationFailedAction,
  GET_IMAGE_GENERATION_SETTINGS,
  GetImageGenerationSettingsCompleteAction,
  UpdateImageGenerationSettingsCompleteAction,
  UPDATE_IMAGE_GENERATION_SETTINGS,
  UPDATE_IMAGE_GENERATION_SETTINGS_COMPLETE,
} from './images-configuration.actions';
import { AppState } from '../app-reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { REFRESH_ACCOUNT_SETTINGS } from '../account-settings/account-settings.actions';
import { AuthService } from '../../api';
import { getImageGenAiConfiguration } from './images-configuration.reducer';

@Injectable()
export class ImagesConfigurationEffects {

  getUserConfiguration$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(GET_USER_CONFIGURATION))
    .pipe(
      mergeMap((action: UnsafeAction) =>
        this.imagesService.getUserConfiguration().pipe(
          map((response) => new GetUserConfigurationCompleteAction(response)),
          catchError((e) => of(new ImagesConfigurationFailedAction({ error: e, action })))
        )
      )
    ));


  updateUserConfiguration$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(UPDATE_USER_CONFIGURATION))
    .pipe(
      mergeMap((action: UnsafeAction) =>
        this.imagesService.updateUserConfiguration(action.payload).pipe(
          mergeMap(() => {
            const configurationUpdated$ = new Subject();
            return timer(0, 3000).pipe(
              delay(3000),
              takeUntil(configurationUpdated$),
              switchMap(() => {
                return this.imagesService.getUserConfiguration();
              }),
              filter((response) => response.status === 'Available'),
              map((response) => {
                configurationUpdated$.next(true);
                this.snackBar.open($localize`Account settings updated`, $localize`Close`, { duration: 4000 });
                return new GetUserConfigurationCompleteAction(response);
              })
            );
          })
        )
      )
    ));

    getImageGenerationSettings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(GET_IMAGE_GENERATION_SETTINGS, REFRESH_ACCOUNT_SETTINGS))
    .pipe(
      mergeMap((action: UnsafeAction) => {
          const accountId = this.authService.getUserAccountId();
          return this.imagesService.getImageGenerationSettings().pipe(
            map((response) => {
              this.imagesService.refreshMediaSettingsInStore(accountId, response)
              return new GetImageGenerationSettingsCompleteAction(response)}),
            catchError((e) => of(new ImagesConfigurationFailedAction({ error: e, action })))
          )
        }
      )
    )
  );

  updateImageGenerationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(UPDATE_IMAGE_GENERATION_SETTINGS)).pipe(
      mergeMap((action: UnsafeAction) =>
        this.imagesService.updateImageGenerationSettings(action.payload).pipe(
          map((response) => new UpdateImageGenerationSettingsCompleteAction(response)),
          catchError((e) => of(new ImagesConfigurationFailedAction({ error: e, action })))
        )
      )
    )
  );

  handleSettingsChanged$: Observable<Action> = createEffect(() => this.actions$
  .pipe(ofType(UPDATE_IMAGE_GENERATION_SETTINGS_COMPLETE))
  .pipe(
    mergeMap(() => this.store.select(getImageGenAiConfiguration).pipe(take(1))),
    tap((settings) => {
      const accountId = this.authService.getUserAccountId();
      return this.imagesService.refreshMediaSettingsInStore(accountId, settings);
    })
  ), { dispatch: false });

  actionFailed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(IMAGES_CONFIGURATION_ACTION_FAILED)).pipe(
      tap((err: any) => {
        const actionType =
          (err && err.payload && err.payload.action && err.payload.action.type) ||
          $localize`Unknown`;
        this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, {
          duration: 4000,
        });
      }),
      // Note: stop effect propagaion
      filter((err) => false),
      map((err) => ({ type: 'NULL_ACTION' }))
    )
  );

  constructor(
    private actions$: Actions,
    private imagesService: ImagesService,
    private store: Store<AppState>,
    public snackBar: MatSnackBar,
    private authService: AuthService
  ) {}
}
