export const fontAwesome5Icons = [
  { name: 'far fa-address-book' },
  { name: 'far fa-address-card' },
  { name: 'far fa-adjust' },
  { name: 'far fa-alarm-clock' },
  { name: 'far fa-align-center' },
  { name: 'far fa-align-justify' },
  { name: 'far fa-align-left' },
  { name: 'far fa-align-right' },
  { name: 'far fa-allergies' },
  { name: 'far fa-ambulance' },
  { name: 'far fa-american-sign-language-interpreting' },
  { name: 'far fa-anchor' },
  { name: 'far fa-angle-double-down' },
  { name: 'far fa-angle-double-left' },
  { name: 'far fa-angle-double-right' },
  { name: 'far fa-angle-double-up' },
  { name: 'far fa-angle-down' },
  { name: 'far fa-angle-left' },
  { name: 'far fa-angle-right' },
  { name: 'far fa-angle-up' },
  { name: 'far fa-archive' },
  { name: 'far fa-arrow-alt-circle-down' },
  { name: 'far fa-arrow-alt-circle-left' },
  { name: 'far fa-arrow-alt-circle-right' },
  { name: 'far fa-arrow-alt-circle-up' },
  { name: 'far fa-arrow-alt-down' },
  { name: 'far fa-arrow-alt-from-bottom' },
  { name: 'far fa-arrow-alt-from-left' },
  { name: 'far fa-arrow-alt-from-right' },
  { name: 'far fa-arrow-alt-from-top' },
  { name: 'far fa-arrow-alt-left' },
  { name: 'far fa-arrow-alt-right' },
  { name: 'far fa-arrow-alt-square-down' },
  { name: 'far fa-arrow-alt-square-left' },
  { name: 'far fa-arrow-alt-square-right' },
  { name: 'far fa-arrow-alt-square-up' },
  { name: 'far fa-arrow-alt-to-bottom' },
  { name: 'far fa-arrow-alt-to-left' },
  { name: 'far fa-arrow-alt-to-right' },
  { name: 'far fa-arrow-alt-to-top' },
  { name: 'far fa-arrow-alt-up' },
  { name: 'far fa-arrow-circle-down' },
  { name: 'far fa-arrow-circle-left' },
  { name: 'far fa-arrow-circle-right' },
  { name: 'far fa-arrow-circle-up' },
  { name: 'far fa-arrow-down' },
  { name: 'far fa-arrow-from-bottom' },
  { name: 'far fa-arrow-from-left' },
  { name: 'far fa-arrow-from-right' },
  { name: 'far fa-arrow-from-top' },
  { name: 'far fa-arrow-left' },
  { name: 'far fa-arrow-right' },
  { name: 'far fa-arrow-square-down' },
  { name: 'far fa-arrow-square-left' },
  { name: 'far fa-arrow-square-right' },
  { name: 'far fa-arrow-square-up' },
  { name: 'far fa-arrow-to-bottom' },
  { name: 'far fa-arrow-to-left' },
  { name: 'far fa-arrow-to-right' },
  { name: 'far fa-arrow-to-top' },
  { name: 'far fa-arrow-up' },
  { name: 'far fa-arrows' },
  { name: 'far fa-arrows-alt' },
  { name: 'far fa-arrows-alt-h' },
  { name: 'far fa-arrows-alt-v' },
  { name: 'far fa-arrows-h' },
  { name: 'far fa-arrows-v' },
  { name: 'far fa-assistive-listening-systems' },
  { name: 'far fa-asterisk' },
  { name: 'far fa-at' },
  { name: 'far fa-audio-description' },
  { name: 'far fa-backward' },
  { name: 'far fa-badge' },
  { name: 'far fa-badge-check' },
  { name: 'far fa-balance-scale' },
  { name: 'far fa-ban' },
  { name: 'far fa-band-aid' },
  { name: 'far fa-barcode' },
  { name: 'far fa-barcode-alt' },
  { name: 'far fa-barcode-read' },
  { name: 'far fa-barcode-scan' },
  { name: 'far fa-bars' },
  { name: 'far fa-baseball' },
  { name: 'far fa-baseball-ball' },
  { name: 'far fa-basketball-ball' },
  { name: 'far fa-basketball-hoop' },
  { name: 'far fa-bath' },
  { name: 'far fa-battery-bolt' },
  { name: 'far fa-battery-empty' },
  { name: 'far fa-battery-full' },
  { name: 'far fa-battery-half' },
  { name: 'far fa-battery-quarter' },
  { name: 'far fa-battery-slash' },
  { name: 'far fa-battery-three-quarters' },
  { name: 'far fa-bed' },
  { name: 'far fa-beer' },
  { name: 'far fa-bell' },
  { name: 'far fa-bell-slash' },
  { name: 'far fa-bicycle' },
  { name: 'far fa-binoculars' },
  { name: 'far fa-birthday-cake' },
  { name: 'far fa-blanket' },
  { name: 'far fa-blind' },
  { name: 'far fa-bold' },
  { name: 'far fa-bolt' },
  { name: 'far fa-bomb' },
  { name: 'far fa-book' },
  { name: 'far fa-book-heart' },
  { name: 'far fa-bookmark' },
  { name: 'far fa-bowling-ball' },
  { name: 'far fa-bowling-pins' },
  { name: 'far fa-box' },
  { name: 'far fa-box-alt' },
  { name: 'far fa-box-check' },
  { name: 'far fa-box-fragile' },
  { name: 'far fa-box-full' },
  { name: 'far fa-box-heart' },
  { name: 'far fa-box-open' },
  { name: 'far fa-box-up' },
  { name: 'far fa-box-usd' },
  { name: 'far fa-boxes' },
  { name: 'far fa-boxes-alt' },
  { name: 'far fa-boxing-glove' },
  { name: 'far fa-braille' },
  { name: 'far fa-briefcase' },
  { name: 'far fa-briefcase-medical' },
  { name: 'far fa-browser' },
  { name: 'far fa-bug' },
  { name: 'far fa-building' },
  { name: 'far fa-bullhorn' },
  { name: 'far fa-bullseye' },
  { name: 'far fa-burn' },
  { name: 'far fa-bus' },
  { name: 'far fa-calculator' },
  { name: 'far fa-calendar' },
  { name: 'far fa-calendar-alt' },
  { name: 'far fa-calendar-check' },
  { name: 'far fa-calendar-edit' },
  { name: 'far fa-calendar-exclamation' },
  { name: 'far fa-calendar-minus' },
  { name: 'far fa-calendar-plus' },
  { name: 'far fa-calendar-times' },
  { name: 'far fa-camera' },
  { name: 'far fa-camera-alt' },
  { name: 'far fa-camera-retro' },
  { name: 'far fa-capsules' },
  { name: 'far fa-car' },
  { name: 'far fa-caret-circle-down' },
  { name: 'far fa-caret-circle-left' },
  { name: 'far fa-caret-circle-right' },
  { name: 'far fa-caret-circle-up' },
  { name: 'far fa-caret-down' },
  { name: 'far fa-caret-left' },
  { name: 'far fa-caret-right' },
  { name: 'far fa-caret-square-down' },
  { name: 'far fa-caret-square-left' },
  { name: 'far fa-caret-square-right' },
  { name: 'far fa-caret-square-up' },
  { name: 'far fa-caret-up' },
  { name: 'far fa-cart-arrow-down' },
  { name: 'far fa-cart-plus' },
  { name: 'far fa-certificate' },
  { name: 'far fa-chart-area' },
  { name: 'far fa-chart-bar' },
  { name: 'far fa-chart-line' },
  { name: 'far fa-chart-pie' },
  { name: 'far fa-check' },
  { name: 'far fa-check-circle' },
  { name: 'far fa-check-square' },
  { name: 'far fa-chess' },
  { name: 'far fa-chess-bishop' },
  { name: 'far fa-chess-bishop-alt' },
  { name: 'far fa-chess-board' },
  { name: 'far fa-chess-clock' },
  { name: 'far fa-chess-clock-alt' },
  { name: 'far fa-chess-king' },
  { name: 'far fa-chess-king-alt' },
  { name: 'far fa-chess-knight' },
  { name: 'far fa-chess-knight-alt' },
  { name: 'far fa-chess-pawn' },
  { name: 'far fa-chess-pawn-alt' },
  { name: 'far fa-chess-queen' },
  { name: 'far fa-chess-queen-alt' },
  { name: 'far fa-chess-rook' },
  { name: 'far fa-chess-rook-alt' },
  { name: 'far fa-chevron-circle-down' },
  { name: 'far fa-chevron-circle-left' },
  { name: 'far fa-chevron-circle-right' },
  { name: 'far fa-chevron-circle-up' },
  { name: 'far fa-chevron-double-down' },
  { name: 'far fa-chevron-double-left' },
  { name: 'far fa-chevron-double-right' },
  { name: 'far fa-chevron-double-up' },
  { name: 'far fa-chevron-down' },
  { name: 'far fa-chevron-left' },
  { name: 'far fa-chevron-right' },
  { name: 'far fa-chevron-square-down' },
  { name: 'far fa-chevron-square-left' },
  { name: 'far fa-chevron-square-right' },
  { name: 'far fa-chevron-square-up' },
  { name: 'far fa-chevron-up' },
  { name: 'far fa-child' },
  { name: 'far fa-circle' },
  { name: 'far fa-circle-notch' },
  { name: 'far fa-clipboard' },
  { name: 'far fa-clipboard-check' },
  { name: 'far fa-clipboard-list' },
  { name: 'far fa-clock' },
  { name: 'far fa-clone' },
  { name: 'far fa-closed-captioning' },
  { name: 'far fa-cloud' },
  { name: 'far fa-cloud-download' },
  { name: 'far fa-cloud-download-alt' },
  { name: 'far fa-cloud-upload' },
  { name: 'far fa-cloud-upload-alt' },
  { name: 'far fa-club' },
  { name: 'far fa-code' },
  { name: 'far fa-code-branch' },
  { name: 'far fa-code-commit' },
  { name: 'far fa-code-merge' },
  { name: 'far fa-coffee' },
  { name: 'far fa-cog' },
  { name: 'far fa-cogs' },
  { name: 'far fa-columns' },
  { name: 'far fa-comment' },
  { name: 'far fa-comment-alt' },
  { name: 'far fa-comment-alt-check' },
  { name: 'far fa-comment-alt-dots' },
  { name: 'far fa-comment-alt-edit' },
  { name: 'far fa-comment-alt-exclamation' },
  { name: 'far fa-comment-alt-lines' },
  { name: 'far fa-comment-alt-minus' },
  { name: 'far fa-comment-alt-plus' },
  { name: 'far fa-comment-alt-slash' },
  { name: 'far fa-comment-alt-smile' },
  { name: 'far fa-comment-alt-times' },
  { name: 'far fa-comment-check' },
  { name: 'far fa-comment-dots' },
  { name: 'far fa-comment-edit' },
  { name: 'far fa-comment-exclamation' },
  { name: 'far fa-comment-lines' },
  { name: 'far fa-comment-minus' },
  { name: 'far fa-comment-plus' },
  { name: 'far fa-comment-slash' },
  { name: 'far fa-comment-smile' },
  { name: 'far fa-comment-times' },
  { name: 'far fa-comments' },
  { name: 'far fa-comments-alt' },
  { name: 'far fa-compass' },
  { name: 'far fa-compress' },
  { name: 'far fa-compress-alt' },
  { name: 'far fa-compress-wide' },
  { name: 'far fa-container-storage' },
  { name: 'far fa-conveyor-belt' },
  { name: 'far fa-conveyor-belt-alt' },
  { name: 'far fa-copy' },
  { name: 'far fa-copyright' },
  { name: 'far fa-couch' },
  { name: 'far fa-credit-card' },
  { name: 'far fa-credit-card-blank' },
  { name: 'far fa-credit-card-front' },
  { name: 'far fa-cricket' },
  { name: 'far fa-crop' },
  { name: 'far fa-crosshairs' },
  { name: 'far fa-cube' },
  { name: 'far fa-cubes' },
  { name: 'far fa-curling' },
  { name: 'far fa-cut' },
  { name: 'far fa-database' },
  { name: 'far fa-deaf' },
  { name: 'far fa-desktop' },
  { name: 'far fa-desktop-alt' },
  { name: 'far fa-diagnoses' },
  { name: 'far fa-diamond' },
  { name: 'far fa-dna' },
  { name: 'far fa-dollar-sign' },
  { name: 'far fa-dolly' },
  { name: 'far fa-dolly-empty' },
  { name: 'far fa-dolly-flatbed' },
  { name: 'far fa-dolly-flatbed-alt' },
  { name: 'far fa-dolly-flatbed-empty' },
  { name: 'far fa-donate' },
  { name: 'far fa-dot-circle' },
  { name: 'far fa-dove' },
  { name: 'far fa-download' },
  { name: 'far fa-dumbbell' },
  { name: 'far fa-edit' },
  { name: 'far fa-eject' },
  { name: 'far fa-ellipsis-h' },
  { name: 'far fa-ellipsis-h-alt' },
  { name: 'far fa-ellipsis-v' },
  { name: 'far fa-ellipsis-v-alt' },
  { name: 'far fa-envelope' },
  { name: 'far fa-envelope-open' },
  { name: 'far fa-envelope-square' },
  { name: 'far fa-eraser' },
  { name: 'far fa-euro-sign' },
  { name: 'far fa-exchange' },
  { name: 'far fa-exchange-alt' },
  { name: 'far fa-exclamation' },
  { name: 'far fa-exclamation-circle' },
  { name: 'far fa-exclamation-square' },
  { name: 'far fa-exclamation-triangle' },
  { name: 'far fa-expand' },
  { name: 'far fa-expand-alt' },
  { name: 'far fa-expand-arrows' },
  { name: 'far fa-expand-arrows-alt' },
  { name: 'far fa-expand-wide' },
  { name: 'far fa-external-link' },
  { name: 'far fa-external-link-alt' },
  { name: 'far fa-external-link-square' },
  { name: 'far fa-external-link-square-alt' },
  { name: 'far fa-eye' },
  { name: 'far fa-eye-dropper' },
  { name: 'far fa-eye-slash' },
  { name: 'far fa-fast-backward' },
  { name: 'far fa-fast-forward' },
  { name: 'far fa-fax' },
  { name: 'far fa-female' },
  { name: 'far fa-field-hockey' },
  { name: 'far fa-fighter-jet' },
  { name: 'far fa-file' },
  { name: 'far fa-file-alt' },
  { name: 'far fa-file-archive' },
  { name: 'far fa-file-audio' },
  { name: 'far fa-file-check' },
  { name: 'far fa-file-code' },
  { name: 'far fa-file-edit' },
  { name: 'far fa-file-excel' },
  { name: 'far fa-file-exclamation' },
  { name: 'far fa-file-image' },
  { name: 'far fa-file-medical' },
  { name: 'far fa-file-medical-alt' },
  { name: 'far fa-file-minus' },
  { name: 'far fa-file-pdf' },
  { name: 'far fa-file-plus' },
  { name: 'far fa-file-powerpoint' },
  { name: 'far fa-file-times' },
  { name: 'far fa-file-video' },
  { name: 'far fa-file-word' },
  { name: 'far fa-film' },
  { name: 'far fa-film-alt' },
  { name: 'far fa-filter' },
  { name: 'far fa-fire' },
  { name: 'far fa-fire-extinguisher' },
  { name: 'far fa-first-aid' },
  { name: 'far fa-flag' },
  { name: 'far fa-flag-checkered' },
  { name: 'far fa-flask' },
  { name: 'far fa-folder' },
  { name: 'far fa-folder-open' },
  { name: 'far fa-font' },
  { name: 'far fa-football-ball' },
  { name: 'far fa-football-helmet' },
  { name: 'far fa-forklift' },
  { name: 'far fa-forward' },
  { name: 'far fa-fragile' },
  { name: 'far fa-frown' },
  { name: 'far fa-futbol' },
  { name: 'far fa-gamepad' },
  { name: 'far fa-gavel' },
  { name: 'far fa-gem' },
  { name: 'far fa-genderless' },
  { name: 'far fa-gift' },
  { name: 'far fa-glass-martini' },
  { name: 'far fa-globe' },
  { name: 'far fa-golf-ball' },
  { name: 'far fa-golf-club' },
  { name: 'far fa-graduation-cap' },
  { name: 'far fa-h-square' },
  { name: 'far fa-h1' },
  { name: 'far fa-h2' },
  { name: 'far fa-h3' },
  { name: 'far fa-hand-heart' },
  { name: 'far fa-hand-holding' },
  { name: 'far fa-hand-holding-box' },
  { name: 'far fa-hand-holding-heart' },
  { name: 'far fa-hand-holding-seedling' },
  { name: 'far fa-hand-holding-usd' },
  { name: 'far fa-hand-holding-water' },
  { name: 'far fa-hand-lizard' },
  { name: 'far fa-hand-paper' },
  { name: 'far fa-hand-peace' },
  { name: 'far fa-hand-point-down' },
  { name: 'far fa-hand-point-left' },
  { name: 'far fa-hand-point-right' },
  { name: 'far fa-hand-point-up' },
  { name: 'far fa-hand-pointer' },
  { name: 'far fa-hand-receiving' },
  { name: 'far fa-hand-rock' },
  { name: 'far fa-hand-scissors' },
  { name: 'far fa-hand-spock' },
  { name: 'far fa-hands' },
  { name: 'far fa-hands-heart' },
  { name: 'far fa-hands-helping' },
  { name: 'far fa-hands-usd' },
  { name: 'far fa-handshake' },
  { name: 'far fa-handshake-alt' },
  { name: 'far fa-hashtag' },
  { name: 'far fa-hdd' },
  { name: 'far fa-heading' },
  { name: 'far fa-headphones' },
  { name: 'far fa-heart' },
  { name: 'far fa-heart-circle' },
  { name: 'far fa-heart-square' },
  { name: 'far fa-heartbeat' },
  { name: 'far fa-hexagon' },
  { name: 'far fa-history' },
  { name: 'far fa-hockey-puck' },
  { name: 'far fa-hockey-sticks' },
  { name: 'far fa-horse' },
  { name: 'far fa-horse-saddle' },
  { name: 'far fa-horse-head' },
  { name: 'far fa-home' },
  { name: 'far fa-home-heart' },
  { name: 'far fa-hospital' },
  { name: 'far fa-hospital-alt' },
  { name: 'far fa-hospital-symbol' },
  { name: 'far fa-hourglass' },
  { name: 'far fa-hourglass-end' },
  { name: 'far fa-hourglass-half' },
  { name: 'far fa-hourglass-start' },
  { name: 'far fa-i-cursor' },
  { name: 'far fa-id-badge' },
  { name: 'far fa-id-card' },
  { name: 'far fa-id-card-alt' },
  { name: 'far fa-image' },
  { name: 'far fa-images' },
  { name: 'far fa-inbox' },
  { name: 'far fa-inbox-in' },
  { name: 'far fa-inbox-out' },
  { name: 'far fa-indent' },
  { name: 'far fa-industry' },
  { name: 'far fa-industry-alt' },
  { name: 'far fa-info' },
  { name: 'far fa-info-circle' },
  { name: 'far fa-info-square' },
  { name: 'far fa-inventory' },
  { name: 'far fa-italic' },
  { name: 'far fa-jack-o-lantern' },
  { name: 'far fa-key' },
  { name: 'far fa-keyboard' },
  { name: 'far fa-lamp' },
  { name: 'far fa-language' },
  { name: 'far fa-laptop' },
  { name: 'far fa-leaf' },
  { name: 'far fa-leaf-heart' },
  { name: 'far fa-lemon' },
  { name: 'far fa-level-down' },
  { name: 'far fa-level-down-alt' },
  { name: 'far fa-level-up' },
  { name: 'far fa-level-up-alt' },
  { name: 'far fa-life-ring' },
  { name: 'far fa-lightbulb' },
  { name: 'far fa-link' },
  { name: 'far fa-lira-sign' },
  { name: 'far fa-list' },
  { name: 'far fa-list-alt' },
  { name: 'far fa-list-ol' },
  { name: 'far fa-list-ul' },
  { name: 'far fa-location-arrow' },
  { name: 'far fa-lock' },
  { name: 'far fa-lock-alt' },
  { name: 'far fa-lock-open' },
  { name: 'far fa-lock-open-alt' },
  { name: 'far fa-long-arrow-alt-down' },
  { name: 'far fa-long-arrow-alt-left' },
  { name: 'far fa-long-arrow-alt-right' },
  { name: 'far fa-long-arrow-alt-up' },
  { name: 'far fa-long-arrow-down' },
  { name: 'far fa-long-arrow-left' },
  { name: 'far fa-long-arrow-right' },
  { name: 'far fa-long-arrow-up' },
  { name: 'far fa-loveseat' },
  { name: 'far fa-low-vision' },
  { name: 'far fa-luchador' },
  { name: 'far fa-magic' },
  { name: 'far fa-magnet' },
  { name: 'far fa-male' },
  { name: 'far fa-map' },
  { name: 'far fa-map-marker' },
  { name: 'far fa-map-marker-alt' },
  { name: 'far fa-map-pin' },
  { name: 'far fa-map-signs' },
  { name: 'far fa-mars' },
  { name: 'far fa-mars-double' },
  { name: 'far fa-mars-stroke' },
  { name: 'far fa-mars-stroke-h' },
  { name: 'far fa-mars-stroke-v' },
  { name: 'far fa-medkit' },
  { name: 'far fa-meh' },
  { name: 'far fa-mercury' },
  { name: 'far fa-microchip' },
  { name: 'far fa-microphone' },
  { name: 'far fa-microphone-alt' },
  { name: 'far fa-microphone-slash' },
  { name: 'far fa-minus' },
  { name: 'far fa-minus-circle' },
  { name: 'far fa-minus-hexagon' },
  { name: 'far fa-minus-octagon' },
  { name: 'far fa-minus-square' },
  { name: 'far fa-mobile' },
  { name: 'far fa-mobile-alt' },
  { name: 'far fa-mobile-android' },
  { name: 'far fa-mobile-android-alt' },
  { name: 'far fa-money-bill' },
  { name: 'far fa-money-bill-alt' },
  { name: 'far fa-moon' },
  { name: 'far fa-motorcycle' },
  { name: 'far fa-mouse-pointer' },
  { name: 'far fa-music' },
  { name: 'far fa-neuter' },
  { name: 'far fa-newspaper' },
  { name: 'far fa-notes-medical' },
  { name: 'far fa-object-group' },
  { name: 'far fa-object-ungroup' },
  { name: 'far fa-octagon' },
  { name: 'far fa-outdent' },
  { name: 'far fa-paint-brush' },
  { name: 'far fa-pallet' },
  { name: 'far fa-pallet-alt' },
  { name: 'far fa-paper-plane' },
  { name: 'far fa-paperclip' },
  { name: 'far fa-parachute-box' },
  { name: 'far fa-paragraph' },
  { name: 'far fa-paste' },
  { name: 'far fa-pause' },
  { name: 'far fa-pause-circle' },
  { name: 'far fa-paw' },
  { name: 'far fa-pen' },
  { name: 'far fa-pen-alt' },
  { name: 'far fa-pen-square' },
  { name: 'far fa-pencil' },
  { name: 'far fa-pencil-alt' },
  { name: 'far fa-pennant' },
  { name: 'far fa-people-carry' },
  { name: 'far fa-percent' },
  { name: 'far fa-person-carry' },
  { name: 'far fa-person-dolly' },
  { name: 'far fa-person-dolly-empty' },
  { name: 'far fa-phone' },
  { name: 'far fa-phone-plus' },
  { name: 'far fa-phone-slash' },
  { name: 'far fa-phone-square' },
  { name: 'far fa-phone-volume' },
  { name: 'far fa-piggy-bank' },
  { name: 'far fa-pills' },
  { name: 'far fa-plane' },
  { name: 'far fa-plane-alt' },
  { name: 'far fa-play' },
  { name: 'far fa-play-circle' },
  { name: 'far fa-plug' },
  { name: 'far fa-plus' },
  { name: 'far fa-plus-circle' },
  { name: 'far fa-plus-hexagon' },
  { name: 'far fa-plus-octagon' },
  { name: 'far fa-plus-square' },
  { name: 'far fa-podcast' },
  { name: 'far fa-poo' },
  { name: 'far fa-portrait' },
  { name: 'far fa-pound-sign' },
  { name: 'far fa-power-off' },
  { name: 'far fa-prescription-bottle' },
  { name: 'far fa-prescription-bottle-alt' },
  { name: 'far fa-print' },
  { name: 'far fa-procedures' },
  { name: 'far fa-puzzle-piece' },
  { name: 'far fa-qrcode' },
  { name: 'far fa-question' },
  { name: 'far fa-question-circle' },
  { name: 'far fa-question-square' },
  { name: 'far fa-quidditch' },
  { name: 'far fa-quote-left' },
  { name: 'far fa-quote-right' },
  { name: 'far fa-racquet' },
  { name: 'far fa-ramp-loading' },
  { name: 'far fa-random' },
  { name: 'far fa-rectangle-landscape' },
  { name: 'far fa-rectangle-portrait' },
  { name: 'far fa-rectangle-wide' },
  { name: 'far fa-recycle' },
  { name: 'far fa-redo' },
  { name: 'far fa-redo-alt' },
  { name: 'far fa-registered' },
  { name: 'far fa-repeat' },
  { name: 'far fa-repeat-1' },
  { name: 'far fa-repeat-1-alt' },
  { name: 'far fa-repeat-alt' },
  { name: 'far fa-reply' },
  { name: 'far fa-reply-all' },
  { name: 'far fa-retweet' },
  { name: 'far fa-retweet-alt' },
  { name: 'far fa-ribbon' },
  { name: 'far fa-road' },
  { name: 'far fa-rocket' },
  { name: 'far fa-route' },
  { name: 'far fa-rss' },
  { name: 'far fa-rss-square' },
  { name: 'far fa-ruble-sign' },
  { name: 'far fa-rupee-sign' },
  { name: 'far fa-save' },
  { name: 'far fa-scanner' },
  { name: 'far fa-scanner-keyboard' },
  { name: 'far fa-scanner-touchscreen' },
  { name: 'far fa-scrubber' },
  { name: 'far fa-search' },
  { name: 'far fa-search-minus' },
  { name: 'far fa-search-plus' },
  { name: 'far fa-seedling' },
  { name: 'far fa-server' },
  { name: 'far fa-share' },
  { name: 'far fa-share-all' },
  { name: 'far fa-share-alt' },
  { name: 'far fa-share-alt-square' },
  { name: 'far fa-share-square' },
  { name: 'far fa-shekel-sign' },
  { name: 'far fa-shield' },
  { name: 'far fa-shield-alt' },
  { name: 'far fa-shield-check' },
  { name: 'far fa-ship' },
  { name: 'far fa-shipping-fast' },
  { name: 'far fa-shipping-timed' },
  { name: 'far fa-shopping-bag' },
  { name: 'far fa-shopping-basket' },
  { name: 'far fa-shopping-cart' },
  { name: 'far fa-shower' },
  { name: 'far fa-shuttlecock' },
  { name: 'far fa-sign' },
  { name: 'far fa-sign-in' },
  { name: 'far fa-sign-in-alt' },
  { name: 'far fa-sign-language' },
  { name: 'far fa-sign-out' },
  { name: 'far fa-sign-out-alt' },
  { name: 'far fa-signal' },
  { name: 'far fa-sitemap' },
  { name: 'far fa-sliders-h' },
  { name: 'far fa-sliders-h-square' },
  { name: 'far fa-sliders-v' },
  { name: 'far fa-sliders-v-square' },
  { name: 'far fa-smile' },
  { name: 'far fa-smile-plus' },
  { name: 'far fa-smoking' },
  { name: 'far fa-snowflake' },
  { name: 'far fa-sort' },
  { name: 'far fa-sort-alpha-down' },
  { name: 'far fa-sort-alpha-up' },
  { name: 'far fa-sort-amount-down' },
  { name: 'far fa-sort-amount-up' },
  { name: 'far fa-sort-down' },
  { name: 'far fa-sort-numeric-down' },
  { name: 'far fa-sort-numeric-up' },
  { name: 'far fa-sort-up' },
  { name: 'far fa-space-shuttle' },
  { name: 'far fa-spade' },
  { name: 'far fa-spinner' },
  { name: 'far fa-spinner-third' },
  { name: 'far fa-square' },
  { name: 'far fa-square-full' },
  { name: 'far fa-star' },
  { name: 'far fa-star-exclamation' },
  { name: 'far fa-star-half' },
  { name: 'far fa-step-backward' },
  { name: 'far fa-step-forward' },
  { name: 'far fa-stethoscope' },
  { name: 'far fa-sticky-note' },
  { name: 'far fa-stop' },
  { name: 'far fa-stop-circle' },
  { name: 'far fa-stopwatch' },
  { name: 'far fa-street-view' },
  { name: 'far fa-strikethrough' },
  { name: 'far fa-subscript' },
  { name: 'far fa-subway' },
  { name: 'far fa-suitcase' },
  { name: 'far fa-sun' },
  { name: 'far fa-superscript' },
  { name: 'far fa-sync' },
  { name: 'far fa-sync-alt' },
  { name: 'far fa-syringe' },
  { name: 'far fa-table' },
  { name: 'far fa-table-tennis' },
  { name: 'far fa-tablet' },
  { name: 'far fa-tablet-alt' },
  { name: 'far fa-tablet-android' },
  { name: 'far fa-tablet-android-alt' },
  { name: 'far fa-tablet-rugged' },
  { name: 'far fa-tablets' },
  { name: 'far fa-tachometer' },
  { name: 'far fa-tachometer-alt' },
  { name: 'far fa-tag' },
  { name: 'far fa-tags' },
  { name: 'far fa-tape' },
  { name: 'far fa-tasks' },
  { name: 'far fa-taxi' },
  { name: 'far fa-tennis-ball' },
  { name: 'far fa-terminal' },
  { name: 'far fa-text-height' },
  { name: 'far fa-text-width' },
  { name: 'far fa-th' },
  { name: 'far fa-th-large' },
  { name: 'far fa-th-list' },
  { name: 'far fa-thermometer' },
  { name: 'far fa-thermometer-empty' },
  { name: 'far fa-thermometer-full' },
  { name: 'far fa-thermometer-half' },
  { name: 'far fa-thermometer-quarter' },
  { name: 'far fa-thermometer-three-quarters' },
  { name: 'far fa-thumbs-down' },
  { name: 'far fa-thumbs-up' },
  { name: 'far fa-thumbtack' },
  { name: 'far fa-ticket' },
  { name: 'far fa-ticket-alt' },
  { name: 'far fa-times' },
  { name: 'far fa-times-circle' },
  { name: 'far fa-times-hexagon' },
  { name: 'far fa-times-octagon' },
  { name: 'far fa-times-square' },
  { name: 'far fa-tint' },
  { name: 'far fa-toggle-off' },
  { name: 'far fa-toggle-on' },
  { name: 'far fa-trademark' },
  { name: 'far fa-train' },
  { name: 'far fa-transgender' },
  { name: 'far fa-transgender-alt' },
  { name: 'far fa-trash' },
  { name: 'far fa-trash-alt' },
  { name: 'far fa-tree' },
  { name: 'far fa-tree-alt' },
  { name: 'far fa-triangle' },
  { name: 'far fa-trophy' },
  { name: 'far fa-trophy-alt' },
  { name: 'far fa-truck' },
  { name: 'far fa-truck-container' },
  { name: 'far fa-truck-couch' },
  { name: 'far fa-truck-loading' },
  { name: 'far fa-truck-moving' },
  { name: 'far fa-truck-ramp' },
  { name: 'far fa-tty' },
  { name: 'far fa-tv' },
  { name: 'far fa-tv-retro' },
  { name: 'far fa-umbrella' },
  { name: 'far fa-underline' },
  { name: 'far fa-undo' },
  { name: 'far fa-undo-alt' },
  { name: 'far fa-universal-access' },
  { name: 'far fa-university' },
  { name: 'far fa-unlink' },
  { name: 'far fa-unlock' },
  { name: 'far fa-unlock-alt' },
  { name: 'far fa-upload' },
  { name: 'far fa-usd-circle' },
  { name: 'far fa-usd-square' },
  { name: 'far fa-user' },
  { name: 'far fa-user-alt' },
  { name: 'far fa-user-circle' },
  { name: 'far fa-user-md' },
  { name: 'far fa-user-plus' },
  { name: 'far fa-user-secret' },
  { name: 'far fa-user-times' },
  { name: 'far fa-users' },
  { name: 'far fa-utensil-fork' },
  { name: 'far fa-utensil-knife' },
  { name: 'far fa-utensil-spoon' },
  { name: 'far fa-utensils' },
  { name: 'far fa-utensils-alt' },
  { name: 'far fa-venus' },
  { name: 'far fa-venus-double' },
  { name: 'far fa-venus-mars' },
  { name: 'far fa-vial' },
  { name: 'far fa-vials' },
  { name: 'far fa-video' },
  { name: 'far fa-video-plus' },
  { name: 'far fa-video-slash' },
  { name: 'far fa-volleyball-ball' },
  { name: 'far fa-volume-down' },
  { name: 'far fa-volume-mute' },
  { name: 'far fa-volume-off' },
  { name: 'far fa-volume-up' },
  { name: 'far fa-warehouse' },
  { name: 'far fa-warehouse-alt' },
  { name: 'far fa-watch' },
  { name: 'far fa-weight' },
  { name: 'far fa-wheelchair' },
  { name: 'far fa-whistle' },
  { name: 'far fa-wifi' },
  { name: 'far fa-window' },
  { name: 'far fa-window-alt' },
  { name: 'far fa-window-close' },
  { name: 'far fa-window-maximize' },
  { name: 'far fa-window-minimize' },
  { name: 'far fa-window-restore' },
  { name: 'far fa-wine-glass' },
  { name: 'far fa-won-sign' },
  { name: 'far fa-wrench' },
  { name: 'far fa-x-ray' },
  { name: 'far fa-yen-sign' },
  { name: 'fas fa-address-book' },
  { name: 'fas fa-address-card' },
  { name: 'fas fa-adjust' },
  { name: 'fas fa-alarm-clock' },
  { name: 'fas fa-align-center' },
  { name: 'fas fa-align-justify' },
  { name: 'fas fa-align-left' },
  { name: 'fas fa-align-right' },
  { name: 'fas fa-allergies' },
  { name: 'fas fa-ambulance' },
  { name: 'fas fa-american-sign-language-interpreting' },
  { name: 'fas fa-anchor' },
  { name: 'fas fa-angle-double-down' },
  { name: 'fas fa-angle-double-left' },
  { name: 'fas fa-angle-double-right' },
  { name: 'fas fa-angle-double-up' },
  { name: 'fas fa-angle-down' },
  { name: 'fas fa-angle-left' },
  { name: 'fas fa-angle-right' },
  { name: 'fas fa-angle-up' },
  { name: 'fas fa-archive' },
  { name: 'fas fa-arrow-alt-circle-down' },
  { name: 'fas fa-arrow-alt-circle-left' },
  { name: 'fas fa-arrow-alt-circle-right' },
  { name: 'fas fa-arrow-alt-circle-up' },
  { name: 'fas fa-arrow-alt-down' },
  { name: 'fas fa-arrow-alt-from-bottom' },
  { name: 'fas fa-arrow-alt-from-left' },
  { name: 'fas fa-arrow-alt-from-right' },
  { name: 'fas fa-arrow-alt-from-top' },
  { name: 'fas fa-arrow-alt-left' },
  { name: 'fas fa-arrow-alt-right' },
  { name: 'fas fa-arrow-alt-square-down' },
  { name: 'fas fa-arrow-alt-square-left' },
  { name: 'fas fa-arrow-alt-square-right' },
  { name: 'fas fa-arrow-alt-square-up' },
  { name: 'fas fa-arrow-alt-to-bottom' },
  { name: 'fas fa-arrow-alt-to-left' },
  { name: 'fas fa-arrow-alt-to-right' },
  { name: 'fas fa-arrow-alt-to-top' },
  { name: 'fas fa-arrow-alt-up' },
  { name: 'fas fa-arrow-circle-down' },
  { name: 'fas fa-arrow-circle-left' },
  { name: 'fas fa-arrow-circle-right' },
  { name: 'fas fa-arrow-circle-up' },
  { name: 'fas fa-arrow-down' },
  { name: 'fas fa-arrow-from-bottom' },
  { name: 'fas fa-arrow-from-left' },
  { name: 'fas fa-arrow-from-right' },
  { name: 'fas fa-arrow-from-top' },
  { name: 'fas fa-arrow-left' },
  { name: 'fas fa-arrow-right' },
  { name: 'fas fa-arrow-square-down' },
  { name: 'fas fa-arrow-square-left' },
  { name: 'fas fa-arrow-square-right' },
  { name: 'fas fa-arrow-square-up' },
  { name: 'fas fa-arrow-to-bottom' },
  { name: 'fas fa-arrow-to-left' },
  { name: 'fas fa-arrow-to-right' },
  { name: 'fas fa-arrow-to-top' },
  { name: 'fas fa-arrow-up' },
  { name: 'fas fa-arrows' },
  { name: 'fas fa-arrows-alt' },
  { name: 'fas fa-arrows-alt-h' },
  { name: 'fas fa-arrows-alt-v' },
  { name: 'fas fa-arrows-h' },
  { name: 'fas fa-arrows-v' },
  { name: 'fas fa-assistive-listening-systems' },
  { name: 'fas fa-asterisk' },
  { name: 'fas fa-at' },
  { name: 'fas fa-audio-description' },
  { name: 'fas fa-backward' },
  { name: 'fas fa-badge' },
  { name: 'fas fa-badge-check' },
  { name: 'fas fa-balance-scale' },
  { name: 'fas fa-ban' },
  { name: 'fas fa-band-aid' },
  { name: 'fas fa-barcode' },
  { name: 'fas fa-barcode-alt' },
  { name: 'fas fa-barcode-read' },
  { name: 'fas fa-barcode-scan' },
  { name: 'fas fa-bars' },
  { name: 'fas fa-baseball' },
  { name: 'fas fa-baseball-ball' },
  { name: 'fas fa-basketball-ball' },
  { name: 'fas fa-basketball-hoop' },
  { name: 'fas fa-bath' },
  { name: 'fas fa-battery-bolt' },
  { name: 'fas fa-battery-empty' },
  { name: 'fas fa-battery-full' },
  { name: 'fas fa-battery-half' },
  { name: 'fas fa-battery-quarter' },
  { name: 'fas fa-battery-slash' },
  { name: 'fas fa-battery-three-quarters' },
  { name: 'fas fa-bed' },
  { name: 'fas fa-beer' },
  { name: 'fas fa-bell' },
  { name: 'fas fa-bell-slash' },
  { name: 'fas fa-bicycle' },
  { name: 'fas fa-binoculars' },
  { name: 'fas fa-birthday-cake' },
  { name: 'fas fa-blanket' },
  { name: 'fas fa-blind' },
  { name: 'fas fa-bold' },
  { name: 'fas fa-bolt' },
  { name: 'fas fa-bomb' },
  { name: 'fas fa-book' },
  { name: 'fas fa-book-heart' },
  { name: 'fas fa-bookmark' },
  { name: 'fas fa-bowling-ball' },
  { name: 'fas fa-bowling-pins' },
  { name: 'fas fa-box' },
  { name: 'fas fa-box-alt' },
  { name: 'fas fa-box-check' },
  { name: 'fas fa-box-fragile' },
  { name: 'fas fa-box-full' },
  { name: 'fas fa-box-heart' },
  { name: 'fas fa-box-open' },
  { name: 'fas fa-box-up' },
  { name: 'fas fa-box-usd' },
  { name: 'fas fa-boxes' },
  { name: 'fas fa-boxes-alt' },
  { name: 'fas fa-boxing-glove' },
  { name: 'fas fa-braille' },
  { name: 'fas fa-briefcase' },
  { name: 'fas fa-briefcase-medical' },
  { name: 'fas fa-browser' },
  { name: 'fas fa-bug' },
  { name: 'fas fa-building' },
  { name: 'fas fa-bullhorn' },
  { name: 'fas fa-bullseye' },
  { name: 'fas fa-burn' },
  { name: 'fas fa-bus' },
  { name: 'fas fa-calculator' },
  { name: 'fas fa-calendar' },
  { name: 'fas fa-calendar-alt' },
  { name: 'fas fa-calendar-check' },
  { name: 'fas fa-calendar-edit' },
  { name: 'fas fa-calendar-exclamation' },
  { name: 'fas fa-calendar-minus' },
  { name: 'fas fa-calendar-plus' },
  { name: 'fas fa-calendar-times' },
  { name: 'fas fa-camera' },
  { name: 'fas fa-camera-alt' },
  { name: 'fas fa-camera-retro' },
  { name: 'fas fa-capsules' },
  { name: 'fas fa-car' },
  { name: 'fas fa-caret-circle-down' },
  { name: 'fas fa-caret-circle-left' },
  { name: 'fas fa-caret-circle-right' },
  { name: 'fas fa-caret-circle-up' },
  { name: 'fas fa-caret-down' },
  { name: 'fas fa-caret-left' },
  { name: 'fas fa-caret-right' },
  { name: 'fas fa-caret-square-down' },
  { name: 'fas fa-caret-square-left' },
  { name: 'fas fa-caret-square-right' },
  { name: 'fas fa-caret-square-up' },
  { name: 'fas fa-caret-up' },
  { name: 'fas fa-cart-arrow-down' },
  { name: 'fas fa-cart-plus' },
  { name: 'fas fa-certificate' },
  { name: 'fas fa-chart-area' },
  { name: 'fas fa-chart-bar' },
  { name: 'fas fa-chart-line' },
  { name: 'fas fa-chart-pie' },
  { name: 'fas fa-check' },
  { name: 'fas fa-check-circle' },
  { name: 'fas fa-check-square' },
  { name: 'fas fa-chess' },
  { name: 'fas fa-chess-bishop' },
  { name: 'fas fa-chess-bishop-alt' },
  { name: 'fas fa-chess-board' },
  { name: 'fas fa-chess-clock' },
  { name: 'fas fa-chess-clock-alt' },
  { name: 'fas fa-chess-king' },
  { name: 'fas fa-chess-king-alt' },
  { name: 'fas fa-chess-knight' },
  { name: 'fas fa-chess-knight-alt' },
  { name: 'fas fa-chess-pawn' },
  { name: 'fas fa-chess-pawn-alt' },
  { name: 'fas fa-chess-queen' },
  { name: 'fas fa-chess-queen-alt' },
  { name: 'fas fa-chess-rook' },
  { name: 'fas fa-chess-rook-alt' },
  { name: 'fas fa-chevron-circle-down' },
  { name: 'fas fa-chevron-circle-left' },
  { name: 'fas fa-chevron-circle-right' },
  { name: 'fas fa-chevron-circle-up' },
  { name: 'fas fa-chevron-double-down' },
  { name: 'fas fa-chevron-double-left' },
  { name: 'fas fa-chevron-double-right' },
  { name: 'fas fa-chevron-double-up' },
  { name: 'fas fa-chevron-down' },
  { name: 'fas fa-chevron-left' },
  { name: 'fas fa-chevron-right' },
  { name: 'fas fa-chevron-square-down' },
  { name: 'fas fa-chevron-square-left' },
  { name: 'fas fa-chevron-square-right' },
  { name: 'fas fa-chevron-square-up' },
  { name: 'fas fa-chevron-up' },
  { name: 'fas fa-child' },
  { name: 'fas fa-circle' },
  { name: 'fas fa-circle-notch' },
  { name: 'fas fa-clipboard' },
  { name: 'fas fa-clipboard-check' },
  { name: 'fas fa-clipboard-list' },
  { name: 'fas fa-clock' },
  { name: 'fas fa-clone' },
  { name: 'fas fa-closed-captioning' },
  { name: 'fas fa-cloud' },
  { name: 'fas fa-cloud-download' },
  { name: 'fas fa-cloud-download-alt' },
  { name: 'fas fa-cloud-upload' },
  { name: 'fas fa-cloud-upload-alt' },
  { name: 'fas fa-club' },
  { name: 'fas fa-code' },
  { name: 'fas fa-code-branch' },
  { name: 'fas fa-code-commit' },
  { name: 'fas fa-code-merge' },
  { name: 'fas fa-coffee' },
  { name: 'fas fa-cog' },
  { name: 'fas fa-cogs' },
  { name: 'fas fa-columns' },
  { name: 'fas fa-comment' },
  { name: 'fas fa-comment-alt' },
  { name: 'fas fa-comment-alt-check' },
  { name: 'fas fa-comment-alt-dots' },
  { name: 'fas fa-comment-alt-edit' },
  { name: 'fas fa-comment-alt-exclamation' },
  { name: 'fas fa-comment-alt-lines' },
  { name: 'fas fa-comment-alt-minus' },
  { name: 'fas fa-comment-alt-plus' },
  { name: 'fas fa-comment-alt-slash' },
  { name: 'fas fa-comment-alt-smile' },
  { name: 'fas fa-comment-alt-times' },
  { name: 'fas fa-comment-check' },
  { name: 'fas fa-comment-dots' },
  { name: 'fas fa-comment-edit' },
  { name: 'fas fa-comment-exclamation' },
  { name: 'fas fa-comment-lines' },
  { name: 'fas fa-comment-minus' },
  { name: 'fas fa-comment-plus' },
  { name: 'fas fa-comment-slash' },
  { name: 'fas fa-comment-smile' },
  { name: 'fas fa-comment-times' },
  { name: 'fas fa-comments' },
  { name: 'fas fa-comments-alt' },
  { name: 'fas fa-compass' },
  { name: 'fas fa-compress' },
  { name: 'fas fa-compress-alt' },
  { name: 'fas fa-compress-wide' },
  { name: 'fas fa-container-storage' },
  { name: 'fas fa-conveyor-belt' },
  { name: 'fas fa-conveyor-belt-alt' },
  { name: 'fas fa-copy' },
  { name: 'fas fa-copyright' },
  { name: 'fas fa-couch' },
  { name: 'fas fa-credit-card' },
  { name: 'fas fa-credit-card-blank' },
  { name: 'fas fa-credit-card-front' },
  { name: 'fas fa-cricket' },
  { name: 'fas fa-crop' },
  { name: 'fas fa-crosshairs' },
  { name: 'fas fa-cube' },
  { name: 'fas fa-cubes' },
  { name: 'fas fa-curling' },
  { name: 'fas fa-cut' },
  { name: 'fas fa-database' },
  { name: 'fas fa-deaf' },
  { name: 'fas fa-desktop' },
  { name: 'fas fa-desktop-alt' },
  { name: 'fas fa-diagnoses' },
  { name: 'fas fa-diamond' },
  { name: 'fas fa-dna' },
  { name: 'fas fa-dollar-sign' },
  { name: 'fas fa-dolly' },
  { name: 'fas fa-dolly-empty' },
  { name: 'fas fa-dolly-flatbed' },
  { name: 'fas fa-dolly-flatbed-alt' },
  { name: 'fas fa-dolly-flatbed-empty' },
  { name: 'fas fa-donate' },
  { name: 'fas fa-dot-circle' },
  { name: 'fas fa-dove' },
  { name: 'fas fa-download' },
  { name: 'fas fa-dumbbell' },
  { name: 'fas fa-edit' },
  { name: 'fas fa-eject' },
  { name: 'fas fa-ellipsis-h' },
  { name: 'fas fa-ellipsis-h-alt' },
  { name: 'fas fa-ellipsis-v' },
  { name: 'fas fa-ellipsis-v-alt' },
  { name: 'fas fa-envelope' },
  { name: 'fas fa-envelope-open' },
  { name: 'fas fa-envelope-square' },
  { name: 'fas fa-eraser' },
  { name: 'fas fa-euro-sign' },
  { name: 'fas fa-exchange' },
  { name: 'fas fa-exchange-alt' },
  { name: 'fas fa-exclamation' },
  { name: 'fas fa-exclamation-circle' },
  { name: 'fas fa-exclamation-square' },
  { name: 'fas fa-exclamation-triangle' },
  { name: 'fas fa-expand' },
  { name: 'fas fa-expand-alt' },
  { name: 'fas fa-expand-arrows' },
  { name: 'fas fa-expand-arrows-alt' },
  { name: 'fas fa-expand-wide' },
  { name: 'fas fa-external-link' },
  { name: 'fas fa-external-link-alt' },
  { name: 'fas fa-external-link-square' },
  { name: 'fas fa-external-link-square-alt' },
  { name: 'fas fa-eye' },
  { name: 'fas fa-eye-dropper' },
  { name: 'fas fa-eye-slash' },
  { name: 'fas fa-fast-backward' },
  { name: 'fas fa-fast-forward' },
  { name: 'fas fa-fax' },
  { name: 'fas fa-female' },
  { name: 'fas fa-field-hockey' },
  { name: 'fas fa-fighter-jet' },
  { name: 'fas fa-file' },
  { name: 'fas fa-file-alt' },
  { name: 'fas fa-file-archive' },
  { name: 'fas fa-file-audio' },
  { name: 'fas fa-file-check' },
  { name: 'fas fa-file-code' },
  { name: 'fas fa-file-edit' },
  { name: 'fas fa-file-excel' },
  { name: 'fas fa-file-exclamation' },
  { name: 'fas fa-file-image' },
  { name: 'fas fa-file-medical' },
  { name: 'fas fa-file-medical-alt' },
  { name: 'fas fa-file-minus' },
  { name: 'fas fa-file-pdf' },
  { name: 'fas fa-file-plus' },
  { name: 'fas fa-file-powerpoint' },
  { name: 'fas fa-file-times' },
  { name: 'fas fa-file-video' },
  { name: 'fas fa-file-word' },
  { name: 'fas fa-film' },
  { name: 'fas fa-film-alt' },
  { name: 'fas fa-filter' },
  { name: 'fas fa-fire' },
  { name: 'fas fa-fire-extinguisher' },
  { name: 'fas fa-first-aid' },
  { name: 'fas fa-flag' },
  { name: 'fas fa-flag-checkered' },
  { name: 'fas fa-flask' },
  { name: 'fas fa-folder' },
  { name: 'fas fa-folder-open' },
  { name: 'fas fa-font' },
  { name: 'fas fa-football-ball' },
  { name: 'fas fa-football-helmet' },
  { name: 'fas fa-forklift' },
  { name: 'fas fa-forward' },
  { name: 'fas fa-fragile' },
  { name: 'fas fa-frown' },
  { name: 'fas fa-futbol' },
  { name: 'fas fa-gamepad' },
  { name: 'fas fa-gavel' },
  { name: 'fas fa-gem' },
  { name: 'fas fa-genderless' },
  { name: 'fas fa-gift' },
  { name: 'fas fa-glass-martini' },
  { name: 'fas fa-globe' },
  { name: 'fas fa-golf-ball' },
  { name: 'fas fa-golf-club' },
  { name: 'fas fa-graduation-cap' },
  { name: 'fas fa-h-square' },
  { name: 'fas fa-h1' },
  { name: 'fas fa-h2' },
  { name: 'fas fa-h3' },
  { name: 'fas fa-hand-heart' },
  { name: 'fas fa-hand-holding' },
  { name: 'fas fa-hand-holding-box' },
  { name: 'fas fa-hand-holding-heart' },
  { name: 'fas fa-hand-holding-seedling' },
  { name: 'fas fa-hand-holding-usd' },
  { name: 'fas fa-hand-holding-water' },
  { name: 'fas fa-hand-lizard' },
  { name: 'fas fa-hand-paper' },
  { name: 'fas fa-hand-peace' },
  { name: 'fas fa-hand-point-down' },
  { name: 'fas fa-hand-point-left' },
  { name: 'fas fa-hand-point-right' },
  { name: 'fas fa-hand-point-up' },
  { name: 'fas fa-hand-pointer' },
  { name: 'fas fa-hand-receiving' },
  { name: 'fas fa-hand-rock' },
  { name: 'fas fa-hand-scissors' },
  { name: 'fas fa-hand-spock' },
  { name: 'fas fa-hands' },
  { name: 'fas fa-hands-heart' },
  { name: 'fas fa-hands-helping' },
  { name: 'fas fa-hands-usd' },
  { name: 'fas fa-handshake' },
  { name: 'fas fa-handshake-alt' },
  { name: 'fas fa-hashtag' },
  { name: 'fas fa-hdd' },
  { name: 'fas fa-heading' },
  { name: 'fas fa-headphones' },
  { name: 'fas fa-heart' },
  { name: 'fas fa-heart-circle' },
  { name: 'fas fa-heart-square' },
  { name: 'fas fa-heartbeat' },
  { name: 'fas fa-hexagon' },
  { name: 'fas fa-history' },
  { name: 'fas fa-hockey-puck' },
  { name: 'fas fa-hockey-sticks' },
  { name: 'fas fa-home' },
  { name: 'fas fa-home-heart' },
  { name: 'fas fa-hospital' },
  { name: 'fas fa-hospital-alt' },
  { name: 'fas fa-hospital-symbol' },
  { name: 'fas fa-hourglass' },
  { name: 'fas fa-hourglass-end' },
  { name: 'fas fa-hourglass-half' },
  { name: 'fas fa-hourglass-start' },
  { name: 'fas fa-i-cursor' },
  { name: 'fas fa-id-badge' },
  { name: 'fas fa-id-card' },
  { name: 'fas fa-id-card-alt' },
  { name: 'fas fa-image' },
  { name: 'fas fa-images' },
  { name: 'fas fa-inbox' },
  { name: 'fas fa-inbox-in' },
  { name: 'fas fa-inbox-out' },
  { name: 'fas fa-indent' },
  { name: 'fas fa-industry' },
  { name: 'fas fa-industry-alt' },
  { name: 'fas fa-info' },
  { name: 'fas fa-info-circle' },
  { name: 'fas fa-info-square' },
  { name: 'fas fa-inventory' },
  { name: 'fas fa-italic' },
  { name: 'fas fa-jack-o-lantern' },
  { name: 'fas fa-key' },
  { name: 'fas fa-keyboard' },
  { name: 'fas fa-lamp' },
  { name: 'fas fa-language' },
  { name: 'fas fa-laptop' },
  { name: 'fas fa-leaf' },
  { name: 'fas fa-leaf-heart' },
  { name: 'fas fa-lemon' },
  { name: 'fas fa-level-down' },
  { name: 'fas fa-level-down-alt' },
  { name: 'fas fa-level-up' },
  { name: 'fas fa-level-up-alt' },
  { name: 'fas fa-life-ring' },
  { name: 'fas fa-lightbulb' },
  { name: 'fas fa-link' },
  { name: 'fas fa-lira-sign' },
  { name: 'fas fa-list' },
  { name: 'fas fa-list-alt' },
  { name: 'fas fa-list-ol' },
  { name: 'fas fa-list-ul' },
  { name: 'fas fa-location-arrow' },
  { name: 'fas fa-lock' },
  { name: 'fas fa-lock-alt' },
  { name: 'fas fa-lock-open' },
  { name: 'fas fa-lock-open-alt' },
  { name: 'fas fa-long-arrow-alt-down' },
  { name: 'fas fa-long-arrow-alt-left' },
  { name: 'fas fa-long-arrow-alt-right' },
  { name: 'fas fa-long-arrow-alt-up' },
  { name: 'fas fa-long-arrow-down' },
  { name: 'fas fa-long-arrow-left' },
  { name: 'fas fa-long-arrow-right' },
  { name: 'fas fa-long-arrow-up' },
  { name: 'fas fa-loveseat' },
  { name: 'fas fa-low-vision' },
  { name: 'fas fa-luchador' },
  { name: 'fas fa-magic' },
  { name: 'fas fa-magnet' },
  { name: 'fas fa-male' },
  { name: 'fas fa-map' },
  { name: 'fas fa-map-marker' },
  { name: 'fas fa-map-marker-alt' },
  { name: 'fas fa-map-pin' },
  { name: 'fas fa-map-signs' },
  { name: 'fas fa-mars' },
  { name: 'fas fa-mars-double' },
  { name: 'fas fa-mars-stroke' },
  { name: 'fas fa-mars-stroke-h' },
  { name: 'fas fa-mars-stroke-v' },
  { name: 'fas fa-medkit' },
  { name: 'fas fa-meh' },
  { name: 'fas fa-mercury' },
  { name: 'fas fa-microchip' },
  { name: 'fas fa-microphone' },
  { name: 'fas fa-microphone-alt' },
  { name: 'fas fa-microphone-slash' },
  { name: 'fas fa-minus' },
  { name: 'fas fa-minus-circle' },
  { name: 'fas fa-minus-hexagon' },
  { name: 'fas fa-minus-octagon' },
  { name: 'fas fa-minus-square' },
  { name: 'fas fa-mobile' },
  { name: 'fas fa-mobile-alt' },
  { name: 'fas fa-mobile-android' },
  { name: 'fas fa-mobile-android-alt' },
  { name: 'fas fa-money-bill' },
  { name: 'fas fa-money-bill-alt' },
  { name: 'fas fa-moon' },
  { name: 'fas fa-motorcycle' },
  { name: 'fas fa-mouse-pointer' },
  { name: 'fas fa-music' },
  { name: 'fas fa-neuter' },
  { name: 'fas fa-newspaper' },
  { name: 'fas fa-notes-medical' },
  { name: 'fas fa-object-group' },
  { name: 'fas fa-object-ungroup' },
  { name: 'fas fa-octagon' },
  { name: 'fas fa-outdent' },
  { name: 'fas fa-paint-brush' },
  { name: 'fas fa-pallet' },
  { name: 'fas fa-pallet-alt' },
  { name: 'fas fa-paper-plane' },
  { name: 'fas fa-paperclip' },
  { name: 'fas fa-parachute-box' },
  { name: 'fas fa-paragraph' },
  { name: 'fas fa-paste' },
  { name: 'fas fa-pause' },
  { name: 'fas fa-pause-circle' },
  { name: 'fas fa-paw' },
  { name: 'fas fa-pen' },
  { name: 'fas fa-pen-alt' },
  { name: 'fas fa-pen-square' },
  { name: 'fas fa-pencil' },
  { name: 'fas fa-pencil-alt' },
  { name: 'fas fa-pennant' },
  { name: 'fas fa-people-carry' },
  { name: 'fas fa-percent' },
  { name: 'fas fa-person-carry' },
  { name: 'fas fa-person-dolly' },
  { name: 'fas fa-person-dolly-empty' },
  { name: 'fas fa-phone' },
  { name: 'fas fa-phone-plus' },
  { name: 'fas fa-phone-slash' },
  { name: 'fas fa-phone-square' },
  { name: 'fas fa-phone-volume' },
  { name: 'fas fa-piggy-bank' },
  { name: 'fas fa-pills' },
  { name: 'fas fa-plane' },
  { name: 'fas fa-plane-alt' },
  { name: 'fas fa-play' },
  { name: 'fas fa-play-circle' },
  { name: 'fas fa-plug' },
  { name: 'fas fa-plus' },
  { name: 'fas fa-plus-circle' },
  { name: 'fas fa-plus-hexagon' },
  { name: 'fas fa-plus-octagon' },
  { name: 'fas fa-plus-square' },
  { name: 'fas fa-podcast' },
  { name: 'fas fa-poo' },
  { name: 'fas fa-portrait' },
  { name: 'fas fa-pound-sign' },
  { name: 'fas fa-power-off' },
  { name: 'fas fa-prescription-bottle' },
  { name: 'fas fa-prescription-bottle-alt' },
  { name: 'fas fa-print' },
  { name: 'fas fa-procedures' },
  { name: 'fas fa-puzzle-piece' },
  { name: 'fas fa-qrcode' },
  { name: 'fas fa-question' },
  { name: 'fas fa-question-circle' },
  { name: 'fas fa-question-square' },
  { name: 'fas fa-quidditch' },
  { name: 'fas fa-quote-left' },
  { name: 'fas fa-quote-right' },
  { name: 'fas fa-racquet' },
  { name: 'fas fa-ramp-loading' },
  { name: 'fas fa-random' },
  { name: 'fas fa-rectangle-landscape' },
  { name: 'fas fa-rectangle-portrait' },
  { name: 'fas fa-rectangle-wide' },
  { name: 'fas fa-recycle' },
  { name: 'fas fa-redo' },
  { name: 'fas fa-redo-alt' },
  { name: 'fas fa-registered' },
  { name: 'fas fa-repeat' },
  { name: 'fas fa-repeat-1' },
  { name: 'fas fa-repeat-1-alt' },
  { name: 'fas fa-repeat-alt' },
  { name: 'fas fa-reply' },
  { name: 'fas fa-reply-all' },
  { name: 'fas fa-retweet' },
  { name: 'fas fa-retweet-alt' },
  { name: 'fas fa-ribbon' },
  { name: 'fas fa-road' },
  { name: 'fas fa-rocket' },
  { name: 'fas fa-route' },
  { name: 'fas fa-rss' },
  { name: 'fas fa-rss-square' },
  { name: 'fas fa-ruble-sign' },
  { name: 'fas fa-rupee-sign' },
  { name: 'fas fa-save' },
  { name: 'fas fa-scanner' },
  { name: 'fas fa-scanner-keyboard' },
  { name: 'fas fa-scanner-touchscreen' },
  { name: 'fas fa-scrubber' },
  { name: 'fas fa-search' },
  { name: 'fas fa-search-minus' },
  { name: 'fas fa-search-plus' },
  { name: 'fas fa-seedling' },
  { name: 'fas fa-server' },
  { name: 'fas fa-share' },
  { name: 'fas fa-share-all' },
  { name: 'fas fa-share-alt' },
  { name: 'fas fa-share-alt-square' },
  { name: 'fas fa-share-square' },
  { name: 'fas fa-shekel-sign' },
  { name: 'fas fa-shield' },
  { name: 'fas fa-shield-alt' },
  { name: 'fas fa-shield-check' },
  { name: 'fas fa-ship' },
  { name: 'fas fa-shipping-fast' },
  { name: 'fas fa-shipping-timed' },
  { name: 'fas fa-shopping-bag' },
  { name: 'fas fa-shopping-basket' },
  { name: 'fas fa-shopping-cart' },
  { name: 'fas fa-shower' },
  { name: 'fas fa-shuttlecock' },
  { name: 'fas fa-sign' },
  { name: 'fas fa-sign-in' },
  { name: 'fas fa-sign-in-alt' },
  { name: 'fas fa-sign-language' },
  { name: 'fas fa-sign-out' },
  { name: 'fas fa-sign-out-alt' },
  { name: 'fas fa-signal' },
  { name: 'fas fa-sitemap' },
  { name: 'fas fa-sliders-h' },
  { name: 'fas fa-sliders-h-square' },
  { name: 'fas fa-sliders-v' },
  { name: 'fas fa-sliders-v-square' },
  { name: 'fas fa-smile' },
  { name: 'fas fa-smile-plus' },
  { name: 'fas fa-smoking' },
  { name: 'fas fa-snowflake' },
  { name: 'fas fa-sort' },
  { name: 'fas fa-sort-alpha-down' },
  { name: 'fas fa-sort-alpha-up' },
  { name: 'fas fa-sort-amount-down' },
  { name: 'fas fa-sort-amount-up' },
  { name: 'fas fa-sort-down' },
  { name: 'fas fa-sort-numeric-down' },
  { name: 'fas fa-sort-numeric-up' },
  { name: 'fas fa-sort-up' },
  { name: 'fas fa-space-shuttle' },
  { name: 'fas fa-spade' },
  { name: 'fas fa-spinner' },
  { name: 'fas fa-spinner-third' },
  { name: 'fas fa-square' },
  { name: 'fas fa-square-full' },
  { name: 'fas fa-star' },
  { name: 'fas fa-star-exclamation' },
  { name: 'fas fa-star-half' },
  { name: 'fas fa-step-backward' },
  { name: 'fas fa-step-forward' },
  { name: 'fas fa-stethoscope' },
  { name: 'fas fa-sticky-note' },
  { name: 'fas fa-stop' },
  { name: 'fas fa-stop-circle' },
  { name: 'fas fa-stopwatch' },
  { name: 'fas fa-street-view' },
  { name: 'fas fa-strikethrough' },
  { name: 'fas fa-subscript' },
  { name: 'fas fa-subway' },
  { name: 'fas fa-suitcase' },
  { name: 'fas fa-sun' },
  { name: 'fas fa-superscript' },
  { name: 'fas fa-sync' },
  { name: 'fas fa-sync-alt' },
  { name: 'fas fa-syringe' },
  { name: 'fas fa-table' },
  { name: 'fas fa-table-tennis' },
  { name: 'fas fa-tablet' },
  { name: 'fas fa-tablet-alt' },
  { name: 'fas fa-tablet-android' },
  { name: 'fas fa-tablet-android-alt' },
  { name: 'fas fa-tablet-rugged' },
  { name: 'fas fa-tablets' },
  { name: 'fas fa-tachometer' },
  { name: 'fas fa-tachometer-alt' },
  { name: 'fas fa-tag' },
  { name: 'fas fa-tags' },
  { name: 'fas fa-tape' },
  { name: 'fas fa-tasks' },
  { name: 'fas fa-taxi' },
  { name: 'fas fa-tennis-ball' },
  { name: 'fas fa-terminal' },
  { name: 'fas fa-text-height' },
  { name: 'fas fa-text-width' },
  { name: 'fas fa-th' },
  { name: 'fas fa-th-large' },
  { name: 'fas fa-th-list' },
  { name: 'fas fa-thermometer' },
  { name: 'fas fa-thermometer-empty' },
  { name: 'fas fa-thermometer-full' },
  { name: 'fas fa-thermometer-half' },
  { name: 'fas fa-thermometer-quarter' },
  { name: 'fas fa-thermometer-three-quarters' },
  { name: 'fas fa-thumbs-down' },
  { name: 'fas fa-thumbs-up' },
  { name: 'fas fa-thumbtack' },
  { name: 'fas fa-ticket' },
  { name: 'fas fa-ticket-alt' },
  { name: 'fas fa-times' },
  { name: 'fas fa-times-circle' },
  { name: 'fas fa-times-hexagon' },
  { name: 'fas fa-times-octagon' },
  { name: 'fas fa-times-square' },
  { name: 'fas fa-tint' },
  { name: 'fas fa-toggle-off' },
  { name: 'fas fa-toggle-on' },
  { name: 'fas fa-trademark' },
  { name: 'fas fa-train' },
  { name: 'fas fa-transgender' },
  { name: 'fas fa-transgender-alt' },
  { name: 'fas fa-trash' },
  { name: 'fas fa-trash-alt' },
  { name: 'fas fa-tree' },
  { name: 'fas fa-tree-alt' },
  { name: 'fas fa-triangle' },
  { name: 'fas fa-trophy' },
  { name: 'fas fa-trophy-alt' },
  { name: 'fas fa-truck' },
  { name: 'fas fa-truck-container' },
  { name: 'fas fa-truck-couch' },
  { name: 'fas fa-truck-loading' },
  { name: 'fas fa-truck-moving' },
  { name: 'fas fa-truck-ramp' },
  { name: 'fas fa-tty' },
  { name: 'fas fa-tv' },
  { name: 'fas fa-tv-retro' },
  { name: 'fas fa-umbrella' },
  { name: 'fas fa-underline' },
  { name: 'fas fa-undo' },
  { name: 'fas fa-undo-alt' },
  { name: 'fas fa-universal-access' },
  { name: 'fas fa-university' },
  { name: 'fas fa-unlink' },
  { name: 'fas fa-unlock' },
  { name: 'fas fa-unlock-alt' },
  { name: 'fas fa-upload' },
  { name: 'fas fa-usd-circle' },
  { name: 'fas fa-usd-square' },
  { name: 'fas fa-user' },
  { name: 'fas fa-user-alt' },
  { name: 'fas fa-user-circle' },
  { name: 'fas fa-user-md' },
  { name: 'fas fa-user-plus' },
  { name: 'fas fa-user-secret' },
  { name: 'fas fa-user-times' },
  { name: 'fas fa-users' },
  { name: 'fas fa-utensil-fork' },
  { name: 'fas fa-utensil-knife' },
  { name: 'fas fa-utensil-spoon' },
  { name: 'fas fa-utensils' },
  { name: 'fas fa-utensils-alt' },
  { name: 'fas fa-venus' },
  { name: 'fas fa-venus-double' },
  { name: 'fas fa-venus-mars' },
  { name: 'fas fa-vial' },
  { name: 'fas fa-vials' },
  { name: 'fas fa-video' },
  { name: 'fas fa-video-plus' },
  { name: 'fas fa-video-slash' },
  { name: 'fas fa-volleyball-ball' },
  { name: 'fas fa-volume-down' },
  { name: 'fas fa-volume-mute' },
  { name: 'fas fa-volume-off' },
  { name: 'fas fa-volume-up' },
  { name: 'fas fa-warehouse' },
  { name: 'fas fa-warehouse-alt' },
  { name: 'fas fa-watch' },
  { name: 'fas fa-weight' },
  { name: 'fas fa-wheelchair' },
  { name: 'fas fa-whistle' },
  { name: 'fas fa-wifi' },
  { name: 'fas fa-window' },
  { name: 'fas fa-window-alt' },
  { name: 'fas fa-window-close' },
  { name: 'fas fa-window-maximize' },
  { name: 'fas fa-window-minimize' },
  { name: 'fas fa-window-restore' },
  { name: 'fas fa-wine-glass' },
  { name: 'fas fa-won-sign' },
  { name: 'fas fa-wrench' },
  { name: 'fas fa-x-ray' },
  { name: 'fas fa-yen-sign' },
  { name: 'fad fa-address-book' },
  { name: 'fad fa-address-card' },
  { name: 'fad fa-adjust' },
  { name: 'fad fa-alarm-clock' },
  { name: 'fad fa-align-center' },
  { name: 'fad fa-align-justify' },
  { name: 'fad fa-align-left' },
  { name: 'fad fa-align-right' },
  { name: 'fad fa-allergies' },
  { name: 'fad fa-ambulance' },
  { name: 'fad fa-american-sign-language-interpreting' },
  { name: 'fad fa-anchor' },
  { name: 'fad fa-angle-double-down' },
  { name: 'fad fa-angle-double-left' },
  { name: 'fad fa-angle-double-right' },
  { name: 'fad fa-angle-double-up' },
  { name: 'fad fa-angle-down' },
  { name: 'fad fa-angle-left' },
  { name: 'fad fa-angle-right' },
  { name: 'fad fa-angle-up' },
  { name: 'fad fa-archive' },
  { name: 'fad fa-arrow-alt-circle-down' },
  { name: 'fad fa-arrow-alt-circle-left' },
  { name: 'fad fa-arrow-alt-circle-right' },
  { name: 'fad fa-arrow-alt-circle-up' },
  { name: 'fad fa-arrow-alt-down' },
  { name: 'fad fa-arrow-alt-from-bottom' },
  { name: 'fad fa-arrow-alt-from-left' },
  { name: 'fad fa-arrow-alt-from-right' },
  { name: 'fad fa-arrow-alt-from-top' },
  { name: 'fad fa-arrow-alt-left' },
  { name: 'fad fa-arrow-alt-right' },
  { name: 'fad fa-arrow-alt-square-down' },
  { name: 'fad fa-arrow-alt-square-left' },
  { name: 'fad fa-arrow-alt-square-right' },
  { name: 'fad fa-arrow-alt-square-up' },
  { name: 'fad fa-arrow-alt-to-bottom' },
  { name: 'fad fa-arrow-alt-to-left' },
  { name: 'fad fa-arrow-alt-to-right' },
  { name: 'fad fa-arrow-alt-to-top' },
  { name: 'fad fa-arrow-alt-up' },
  { name: 'fad fa-arrow-circle-down' },
  { name: 'fad fa-arrow-circle-left' },
  { name: 'fad fa-arrow-circle-right' },
  { name: 'fad fa-arrow-circle-up' },
  { name: 'fad fa-arrow-down' },
  { name: 'fad fa-arrow-from-bottom' },
  { name: 'fad fa-arrow-from-left' },
  { name: 'fad fa-arrow-from-right' },
  { name: 'fad fa-arrow-from-top' },
  { name: 'fad fa-arrow-left' },
  { name: 'fad fa-arrow-right' },
  { name: 'fad fa-arrow-square-down' },
  { name: 'fad fa-arrow-square-left' },
  { name: 'fad fa-arrow-square-right' },
  { name: 'fad fa-arrow-square-up' },
  { name: 'fad fa-arrow-to-bottom' },
  { name: 'fad fa-arrow-to-left' },
  { name: 'fad fa-arrow-to-right' },
  { name: 'fad fa-arrow-to-top' },
  { name: 'fad fa-arrow-up' },
  { name: 'fad fa-arrows' },
  { name: 'fad fa-arrows-alt' },
  { name: 'fad fa-arrows-alt-h' },
  { name: 'fad fa-arrows-alt-v' },
  { name: 'fad fa-arrows-h' },
  { name: 'fad fa-arrows-v' },
  { name: 'fad fa-assistive-listening-systems' },
  { name: 'fad fa-asterisk' },
  { name: 'fad fa-at' },
  { name: 'fad fa-audio-description' },
  { name: 'fad fa-backward' },
  { name: 'fad fa-badge' },
  { name: 'fad fa-badge-check' },
  { name: 'fad fa-balance-scale' },
  { name: 'fad fa-ban' },
  { name: 'fad fa-band-aid' },
  { name: 'fad fa-barcode' },
  { name: 'fad fa-barcode-alt' },
  { name: 'fad fa-barcode-read' },
  { name: 'fad fa-barcode-scan' },
  { name: 'fad fa-bars' },
  { name: 'fad fa-baseball' },
  { name: 'fad fa-baseball-ball' },
  { name: 'fad fa-basketball-ball' },
  { name: 'fad fa-basketball-hoop' },
  { name: 'fad fa-bath' },
  { name: 'fad fa-battery-bolt' },
  { name: 'fad fa-battery-empty' },
  { name: 'fad fa-battery-full' },
  { name: 'fad fa-battery-half' },
  { name: 'fad fa-battery-quarter' },
  { name: 'fad fa-battery-slash' },
  { name: 'fad fa-battery-three-quarters' },
  { name: 'fad fa-bed' },
  { name: 'fad fa-beer' },
  { name: 'fad fa-bell' },
  { name: 'fad fa-bell-slash' },
  { name: 'fad fa-bicycle' },
  { name: 'fad fa-binoculars' },
  { name: 'fad fa-birthday-cake' },
  { name: 'fad fa-blanket' },
  { name: 'fad fa-blind' },
  { name: 'fad fa-bold' },
  { name: 'fad fa-bolt' },
  { name: 'fad fa-bomb' },
  { name: 'fad fa-book' },
  { name: 'fad fa-book-heart' },
  { name: 'fad fa-bookmark' },
  { name: 'fad fa-bowling-ball' },
  { name: 'fad fa-bowling-pins' },
  { name: 'fad fa-box' },
  { name: 'fad fa-box-alt' },
  { name: 'fad fa-box-check' },
  { name: 'fad fa-box-fragile' },
  { name: 'fad fa-box-full' },
  { name: 'fad fa-box-heart' },
  { name: 'fad fa-box-open' },
  { name: 'fad fa-box-up' },
  { name: 'fad fa-box-usd' },
  { name: 'fad fa-boxes' },
  { name: 'fad fa-boxes-alt' },
  { name: 'fad fa-boxing-glove' },
  { name: 'fad fa-braille' },
  { name: 'fad fa-briefcase' },
  { name: 'fad fa-briefcase-medical' },
  { name: 'fad fa-browser' },
  { name: 'fad fa-bug' },
  { name: 'fad fa-building' },
  { name: 'fad fa-bullhorn' },
  { name: 'fad fa-bullseye' },
  { name: 'fad fa-burn' },
  { name: 'fad fa-bus' },
  { name: 'fad fa-calculator' },
  { name: 'fad fa-calendar' },
  { name: 'fad fa-calendar-alt' },
  { name: 'fad fa-calendar-check' },
  { name: 'fad fa-calendar-edit' },
  { name: 'fad fa-calendar-exclamation' },
  { name: 'fad fa-calendar-minus' },
  { name: 'fad fa-calendar-plus' },
  { name: 'fad fa-calendar-times' },
  { name: 'fad fa-camera' },
  { name: 'fad fa-camera-alt' },
  { name: 'fad fa-camera-retro' },
  { name: 'fad fa-capsules' },
  { name: 'fad fa-car' },
  { name: 'fad fa-caret-circle-down' },
  { name: 'fad fa-caret-circle-left' },
  { name: 'fad fa-caret-circle-right' },
  { name: 'fad fa-caret-circle-up' },
  { name: 'fad fa-caret-down' },
  { name: 'fad fa-caret-left' },
  { name: 'fad fa-caret-right' },
  { name: 'fad fa-caret-square-down' },
  { name: 'fad fa-caret-square-left' },
  { name: 'fad fa-caret-square-right' },
  { name: 'fad fa-caret-square-up' },
  { name: 'fad fa-caret-up' },
  { name: 'fad fa-cart-arrow-down' },
  { name: 'fad fa-cart-plus' },
  { name: 'fad fa-certificate' },
  { name: 'fad fa-chart-area' },
  { name: 'fad fa-chart-bar' },
  { name: 'fad fa-chart-line' },
  { name: 'fad fa-chart-pie' },
  { name: 'fad fa-check' },
  { name: 'fad fa-check-circle' },
  { name: 'fad fa-check-square' },
  { name: 'fad fa-chess' },
  { name: 'fad fa-chess-bishop' },
  { name: 'fad fa-chess-bishop-alt' },
  { name: 'fad fa-chess-board' },
  { name: 'fad fa-chess-clock' },
  { name: 'fad fa-chess-clock-alt' },
  { name: 'fad fa-chess-king' },
  { name: 'fad fa-chess-king-alt' },
  { name: 'fad fa-chess-knight' },
  { name: 'fad fa-chess-knight-alt' },
  { name: 'fad fa-chess-pawn' },
  { name: 'fad fa-chess-pawn-alt' },
  { name: 'fad fa-chess-queen' },
  { name: 'fad fa-chess-queen-alt' },
  { name: 'fad fa-chess-rook' },
  { name: 'fad fa-chess-rook-alt' },
  { name: 'fad fa-chevron-circle-down' },
  { name: 'fad fa-chevron-circle-left' },
  { name: 'fad fa-chevron-circle-right' },
  { name: 'fad fa-chevron-circle-up' },
  { name: 'fad fa-chevron-double-down' },
  { name: 'fad fa-chevron-double-left' },
  { name: 'fad fa-chevron-double-right' },
  { name: 'fad fa-chevron-double-up' },
  { name: 'fad fa-chevron-down' },
  { name: 'fad fa-chevron-left' },
  { name: 'fad fa-chevron-right' },
  { name: 'fad fa-chevron-square-down' },
  { name: 'fad fa-chevron-square-left' },
  { name: 'fad fa-chevron-square-right' },
  { name: 'fad fa-chevron-square-up' },
  { name: 'fad fa-chevron-up' },
  { name: 'fad fa-child' },
  { name: 'fad fa-circle' },
  { name: 'fad fa-circle-notch' },
  { name: 'fad fa-clipboard' },
  { name: 'fad fa-clipboard-check' },
  { name: 'fad fa-clipboard-list' },
  { name: 'fad fa-clock' },
  { name: 'fad fa-clone' },
  { name: 'fad fa-closed-captioning' },
  { name: 'fad fa-cloud' },
  { name: 'fad fa-cloud-download' },
  { name: 'fad fa-cloud-download-alt' },
  { name: 'fad fa-cloud-upload' },
  { name: 'fad fa-cloud-upload-alt' },
  { name: 'fad fa-club' },
  { name: 'fad fa-code' },
  { name: 'fad fa-code-branch' },
  { name: 'fad fa-code-commit' },
  { name: 'fad fa-code-merge' },
  { name: 'fad fa-coffee' },
  { name: 'fad fa-cog' },
  { name: 'fad fa-cogs' },
  { name: 'fad fa-columns' },
  { name: 'fad fa-comment' },
  { name: 'fad fa-comment-alt' },
  { name: 'fad fa-comment-alt-check' },
  { name: 'fad fa-comment-alt-dots' },
  { name: 'fad fa-comment-alt-edit' },
  { name: 'fad fa-comment-alt-exclamation' },
  { name: 'fad fa-comment-alt-lines' },
  { name: 'fad fa-comment-alt-minus' },
  { name: 'fad fa-comment-alt-plus' },
  { name: 'fad fa-comment-alt-slash' },
  { name: 'fad fa-comment-alt-smile' },
  { name: 'fad fa-comment-alt-times' },
  { name: 'fad fa-comment-check' },
  { name: 'fad fa-comment-dots' },
  { name: 'fad fa-comment-edit' },
  { name: 'fad fa-comment-exclamation' },
  { name: 'fad fa-comment-lines' },
  { name: 'fad fa-comment-minus' },
  { name: 'fad fa-comment-plus' },
  { name: 'fad fa-comment-slash' },
  { name: 'fad fa-comment-smile' },
  { name: 'fad fa-comment-times' },
  { name: 'fad fa-comments' },
  { name: 'fad fa-comments-alt' },
  { name: 'fad fa-compass' },
  { name: 'fad fa-compress' },
  { name: 'fad fa-compress-alt' },
  { name: 'fad fa-compress-wide' },
  { name: 'fad fa-container-storage' },
  { name: 'fad fa-conveyor-belt' },
  { name: 'fad fa-conveyor-belt-alt' },
  { name: 'fad fa-copy' },
  { name: 'fad fa-copyright' },
  { name: 'fad fa-couch' },
  { name: 'fad fa-credit-card' },
  { name: 'fad fa-credit-card-blank' },
  { name: 'fad fa-credit-card-front' },
  { name: 'fad fa-cricket' },
  { name: 'fad fa-crop' },
  { name: 'fad fa-crosshairs' },
  { name: 'fad fa-cube' },
  { name: 'fad fa-cubes' },
  { name: 'fad fa-curling' },
  { name: 'fad fa-cut' },
  { name: 'fad fa-database' },
  { name: 'fad fa-deaf' },
  { name: 'fad fa-desktop' },
  { name: 'fad fa-desktop-alt' },
  { name: 'fad fa-diagnoses' },
  { name: 'fad fa-diamond' },
  { name: 'fad fa-dna' },
  { name: 'fad fa-dollar-sign' },
  { name: 'fad fa-dolly' },
  { name: 'fad fa-dolly-empty' },
  { name: 'fad fa-dolly-flatbed' },
  { name: 'fad fa-dolly-flatbed-alt' },
  { name: 'fad fa-dolly-flatbed-empty' },
  { name: 'fad fa-donate' },
  { name: 'fad fa-dot-circle' },
  { name: 'fad fa-dove' },
  { name: 'fad fa-download' },
  { name: 'fad fa-dumbbell' },
  { name: 'fad fa-edit' },
  { name: 'fad fa-eject' },
  { name: 'fad fa-ellipsis-h' },
  { name: 'fad fa-ellipsis-h-alt' },
  { name: 'fad fa-ellipsis-v' },
  { name: 'fad fa-ellipsis-v-alt' },
  { name: 'fad fa-envelope' },
  { name: 'fad fa-envelope-open' },
  { name: 'fad fa-envelope-square' },
  { name: 'fad fa-eraser' },
  { name: 'fad fa-euro-sign' },
  { name: 'fad fa-exchange' },
  { name: 'fad fa-exchange-alt' },
  { name: 'fad fa-exclamation' },
  { name: 'fad fa-exclamation-circle' },
  { name: 'fad fa-exclamation-square' },
  { name: 'fad fa-exclamation-triangle' },
  { name: 'fad fa-expand' },
  { name: 'fad fa-expand-alt' },
  { name: 'fad fa-expand-arrows' },
  { name: 'fad fa-expand-arrows-alt' },
  { name: 'fad fa-expand-wide' },
  { name: 'fad fa-external-link' },
  { name: 'fad fa-external-link-alt' },
  { name: 'fad fa-external-link-square' },
  { name: 'fad fa-external-link-square-alt' },
  { name: 'fad fa-eye' },
  { name: 'fad fa-eye-dropper' },
  { name: 'fad fa-eye-slash' },
  { name: 'fad fa-fast-backward' },
  { name: 'fad fa-fast-forward' },
  { name: 'fad fa-fax' },
  { name: 'fad fa-female' },
  { name: 'fad fa-field-hockey' },
  { name: 'fad fa-fighter-jet' },
  { name: 'fad fa-file' },
  { name: 'fad fa-file-alt' },
  { name: 'fad fa-file-archive' },
  { name: 'fad fa-file-audio' },
  { name: 'fad fa-file-check' },
  { name: 'fad fa-file-code' },
  { name: 'fad fa-file-edit' },
  { name: 'fad fa-file-excel' },
  { name: 'fad fa-file-exclamation' },
  { name: 'fad fa-file-image' },
  { name: 'fad fa-file-medical' },
  { name: 'fad fa-file-medical-alt' },
  { name: 'fad fa-file-minus' },
  { name: 'fad fa-file-pdf' },
  { name: 'fad fa-file-plus' },
  { name: 'fad fa-file-powerpoint' },
  { name: 'fad fa-file-times' },
  { name: 'fad fa-file-video' },
  { name: 'fad fa-file-word' },
  { name: 'fad fa-film' },
  { name: 'fad fa-film-alt' },
  { name: 'fad fa-filter' },
  { name: 'fad fa-fire' },
  { name: 'fad fa-fire-extinguisher' },
  { name: 'fad fa-first-aid' },
  { name: 'fad fa-flag' },
  { name: 'fad fa-flag-checkered' },
  { name: 'fad fa-flask' },
  { name: 'fad fa-folder' },
  { name: 'fad fa-folder-open' },
  { name: 'fad fa-font' },
  { name: 'fad fa-football-ball' },
  { name: 'fad fa-football-helmet' },
  { name: 'fad fa-forklift' },
  { name: 'fad fa-forward' },
  { name: 'fad fa-fragile' },
  { name: 'fad fa-frown' },
  { name: 'fad fa-futbol' },
  { name: 'fad fa-gamepad' },
  { name: 'fad fa-gavel' },
  { name: 'fad fa-gem' },
  { name: 'fad fa-genderless' },
  { name: 'fad fa-gift' },
  { name: 'fad fa-glass-martini' },
  { name: 'fad fa-globe' },
  { name: 'fad fa-golf-ball' },
  { name: 'fad fa-golf-club' },
  { name: 'fad fa-graduation-cap' },
  { name: 'fad fa-h-square' },
  { name: 'fad fa-h1' },
  { name: 'fad fa-h2' },
  { name: 'fad fa-h3' },
  { name: 'fad fa-hand-heart' },
  { name: 'fad fa-hand-holding' },
  { name: 'fad fa-hand-holding-box' },
  { name: 'fad fa-hand-holding-heart' },
  { name: 'fad fa-hand-holding-seedling' },
  { name: 'fad fa-hand-holding-usd' },
  { name: 'fad fa-hand-holding-water' },
  { name: 'fad fa-hand-lizard' },
  { name: 'fad fa-hand-paper' },
  { name: 'fad fa-hand-peace' },
  { name: 'fad fa-hand-point-down' },
  { name: 'fad fa-hand-point-left' },
  { name: 'fad fa-hand-point-right' },
  { name: 'fad fa-hand-point-up' },
  { name: 'fad fa-hand-pointer' },
  { name: 'fad fa-hand-receiving' },
  { name: 'fad fa-hand-rock' },
  { name: 'fad fa-hand-scissors' },
  { name: 'fad fa-hand-spock' },
  { name: 'fad fa-hands' },
  { name: 'fad fa-hands-heart' },
  { name: 'fad fa-hands-helping' },
  { name: 'fad fa-hands-usd' },
  { name: 'fad fa-handshake' },
  { name: 'fad fa-handshake-alt' },
  { name: 'fad fa-hashtag' },
  { name: 'fad fa-hdd' },
  { name: 'fad fa-heading' },
  { name: 'fad fa-headphones' },
  { name: 'fad fa-heart' },
  { name: 'fad fa-heart-circle' },
  { name: 'fad fa-heart-square' },
  { name: 'fad fa-heartbeat' },
  { name: 'fad fa-hexagon' },
  { name: 'fad fa-history' },
  { name: 'fad fa-hockey-puck' },
  { name: 'fad fa-hockey-sticks' },
  { name: 'fad fa-home' },
  { name: 'fad fa-home-heart' },
  { name: 'fad fa-hospital' },
  { name: 'fad fa-hospital-alt' },
  { name: 'fad fa-hospital-symbol' },
  { name: 'fad fa-hourglass' },
  { name: 'fad fa-hourglass-end' },
  { name: 'fad fa-hourglass-half' },
  { name: 'fad fa-hourglass-start' },
  { name: 'fad fa-i-cursor' },
  { name: 'fad fa-id-badge' },
  { name: 'fad fa-id-card' },
  { name: 'fad fa-id-card-alt' },
  { name: 'fad fa-image' },
  { name: 'fad fa-images' },
  { name: 'fad fa-inbox' },
  { name: 'fad fa-inbox-in' },
  { name: 'fad fa-inbox-out' },
  { name: 'fad fa-indent' },
  { name: 'fad fa-industry' },
  { name: 'fad fa-industry-alt' },
  { name: 'fad fa-info' },
  { name: 'fad fa-info-circle' },
  { name: 'fad fa-info-square' },
  { name: 'fad fa-inventory' },
  { name: 'fad fa-italic' },
  { name: 'fad fa-jack-o-lantern' },
  { name: 'fad fa-key' },
  { name: 'fad fa-keyboard' },
  { name: 'fad fa-lamp' },
  { name: 'fad fa-language' },
  { name: 'fad fa-laptop' },
  { name: 'fad fa-leaf' },
  { name: 'fad fa-leaf-heart' },
  { name: 'fad fa-lemon' },
  { name: 'fad fa-level-down' },
  { name: 'fad fa-level-down-alt' },
  { name: 'fad fa-level-up' },
  { name: 'fad fa-level-up-alt' },
  { name: 'fad fa-life-ring' },
  { name: 'fad fa-lightbulb' },
  { name: 'fad fa-link' },
  { name: 'fad fa-lira-sign' },
  { name: 'fad fa-list' },
  { name: 'fad fa-list-alt' },
  { name: 'fad fa-list-ol' },
  { name: 'fad fa-list-ul' },
  { name: 'fad fa-location-arrow' },
  { name: 'fad fa-lock' },
  { name: 'fad fa-lock-alt' },
  { name: 'fad fa-lock-open' },
  { name: 'fad fa-lock-open-alt' },
  { name: 'fad fa-long-arrow-alt-down' },
  { name: 'fad fa-long-arrow-alt-left' },
  { name: 'fad fa-long-arrow-alt-right' },
  { name: 'fad fa-long-arrow-alt-up' },
  { name: 'fad fa-long-arrow-down' },
  { name: 'fad fa-long-arrow-left' },
  { name: 'fad fa-long-arrow-right' },
  { name: 'fad fa-long-arrow-up' },
  { name: 'fad fa-loveseat' },
  { name: 'fad fa-low-vision' },
  { name: 'fad fa-luchador' },
  { name: 'fad fa-magic' },
  { name: 'fad fa-magnet' },
  { name: 'fad fa-male' },
  { name: 'fad fa-map' },
  { name: 'fad fa-map-marker' },
  { name: 'fad fa-map-marker-alt' },
  { name: 'fad fa-map-pin' },
  { name: 'fad fa-map-signs' },
  { name: 'fad fa-mars' },
  { name: 'fad fa-mars-double' },
  { name: 'fad fa-mars-stroke' },
  { name: 'fad fa-mars-stroke-h' },
  { name: 'fad fa-mars-stroke-v' },
  { name: 'fad fa-medkit' },
  { name: 'fad fa-meh' },
  { name: 'fad fa-mercury' },
  { name: 'fad fa-microchip' },
  { name: 'fad fa-microphone' },
  { name: 'fad fa-microphone-alt' },
  { name: 'fad fa-microphone-slash' },
  { name: 'fad fa-minus' },
  { name: 'fad fa-minus-circle' },
  { name: 'fad fa-minus-hexagon' },
  { name: 'fad fa-minus-octagon' },
  { name: 'fad fa-minus-square' },
  { name: 'fad fa-mobile' },
  { name: 'fad fa-mobile-alt' },
  { name: 'fad fa-mobile-android' },
  { name: 'fad fa-mobile-android-alt' },
  { name: 'fad fa-money-bill' },
  { name: 'fad fa-money-bill-alt' },
  { name: 'fad fa-moon' },
  { name: 'fad fa-motorcycle' },
  { name: 'fad fa-mouse-pointer' },
  { name: 'fad fa-music' },
  { name: 'fad fa-neuter' },
  { name: 'fad fa-newspaper' },
  { name: 'fad fa-notes-medical' },
  { name: 'fad fa-object-group' },
  { name: 'fad fa-object-ungroup' },
  { name: 'fad fa-octagon' },
  { name: 'fad fa-outdent' },
  { name: 'fad fa-paint-brush' },
  { name: 'fad fa-pallet' },
  { name: 'fad fa-pallet-alt' },
  { name: 'fad fa-paper-plane' },
  { name: 'fad fa-paperclip' },
  { name: 'fad fa-parachute-box' },
  { name: 'fad fa-paragraph' },
  { name: 'fad fa-paste' },
  { name: 'fad fa-pause' },
  { name: 'fad fa-pause-circle' },
  { name: 'fad fa-paw' },
  { name: 'fad fa-pen' },
  { name: 'fad fa-pen-alt' },
  { name: 'fad fa-pen-square' },
  { name: 'fad fa-pencil' },
  { name: 'fad fa-pencil-alt' },
  { name: 'fad fa-pennant' },
  { name: 'fad fa-people-carry' },
  { name: 'fad fa-percent' },
  { name: 'fad fa-person-carry' },
  { name: 'fad fa-person-dolly' },
  { name: 'fad fa-person-dolly-empty' },
  { name: 'fad fa-phone' },
  { name: 'fad fa-phone-plus' },
  { name: 'fad fa-phone-slash' },
  { name: 'fad fa-phone-square' },
  { name: 'fad fa-phone-volume' },
  { name: 'fad fa-piggy-bank' },
  { name: 'fad fa-pills' },
  { name: 'fad fa-plane' },
  { name: 'fad fa-plane-alt' },
  { name: 'fad fa-play' },
  { name: 'fad fa-play-circle' },
  { name: 'fad fa-plug' },
  { name: 'fad fa-plus' },
  { name: 'fad fa-plus-circle' },
  { name: 'fad fa-plus-hexagon' },
  { name: 'fad fa-plus-octagon' },
  { name: 'fad fa-plus-square' },
  { name: 'fad fa-podcast' },
  { name: 'fad fa-poo' },
  { name: 'fad fa-portrait' },
  { name: 'fad fa-pound-sign' },
  { name: 'fad fa-power-off' },
  { name: 'fad fa-prescription-bottle' },
  { name: 'fad fa-prescription-bottle-alt' },
  { name: 'fad fa-print' },
  { name: 'fad fa-procedures' },
  { name: 'fad fa-puzzle-piece' },
  { name: 'fad fa-qrcode' },
  { name: 'fad fa-question' },
  { name: 'fad fa-question-circle' },
  { name: 'fad fa-question-square' },
  { name: 'fad fa-quidditch' },
  { name: 'fad fa-quote-left' },
  { name: 'fad fa-quote-right' },
  { name: 'fad fa-racquet' },
  { name: 'fad fa-ramp-loading' },
  { name: 'fad fa-random' },
  { name: 'fad fa-rectangle-landscape' },
  { name: 'fad fa-rectangle-portrait' },
  { name: 'fad fa-rectangle-wide' },
  { name: 'fad fa-recycle' },
  { name: 'fad fa-redo' },
  { name: 'fad fa-redo-alt' },
  { name: 'fad fa-registered' },
  { name: 'fad fa-repeat' },
  { name: 'fad fa-repeat-1' },
  { name: 'fad fa-repeat-1-alt' },
  { name: 'fad fa-repeat-alt' },
  { name: 'fad fa-reply' },
  { name: 'fad fa-reply-all' },
  { name: 'fad fa-retweet' },
  { name: 'fad fa-retweet-alt' },
  { name: 'fad fa-ribbon' },
  { name: 'fad fa-road' },
  { name: 'fad fa-rocket' },
  { name: 'fad fa-route' },
  { name: 'fad fa-rss' },
  { name: 'fad fa-rss-square' },
  { name: 'fad fa-ruble-sign' },
  { name: 'fad fa-rupee-sign' },
  { name: 'fad fa-save' },
  { name: 'fad fa-scanner' },
  { name: 'fad fa-scanner-keyboard' },
  { name: 'fad fa-scanner-touchscreen' },
  { name: 'fad fa-scrubber' },
  { name: 'fad fa-search' },
  { name: 'fad fa-search-minus' },
  { name: 'fad fa-search-plus' },
  { name: 'fad fa-seedling' },
  { name: 'fad fa-server' },
  { name: 'fad fa-share' },
  { name: 'fad fa-share-all' },
  { name: 'fad fa-share-alt' },
  { name: 'fad fa-share-alt-square' },
  { name: 'fad fa-share-square' },
  { name: 'fad fa-shekel-sign' },
  { name: 'fad fa-shield' },
  { name: 'fad fa-shield-alt' },
  { name: 'fad fa-shield-check' },
  { name: 'fad fa-ship' },
  { name: 'fad fa-shipping-fast' },
  { name: 'fad fa-shipping-timed' },
  { name: 'fad fa-shopping-bag' },
  { name: 'fad fa-shopping-basket' },
  { name: 'fad fa-shopping-cart' },
  { name: 'fad fa-shower' },
  { name: 'fad fa-shuttlecock' },
  { name: 'fad fa-sign' },
  { name: 'fad fa-sign-in' },
  { name: 'fad fa-sign-in-alt' },
  { name: 'fad fa-sign-language' },
  { name: 'fad fa-sign-out' },
  { name: 'fad fa-sign-out-alt' },
  { name: 'fad fa-signal' },
  { name: 'fad fa-sitemap' },
  { name: 'fad fa-sliders-h' },
  { name: 'fad fa-sliders-h-square' },
  { name: 'fad fa-sliders-v' },
  { name: 'fad fa-sliders-v-square' },
  { name: 'fad fa-smile' },
  { name: 'fad fa-smile-plus' },
  { name: 'fad fa-smoking' },
  { name: 'fad fa-snowflake' },
  { name: 'fad fa-sort' },
  { name: 'fad fa-sort-alpha-down' },
  { name: 'fad fa-sort-alpha-up' },
  { name: 'fad fa-sort-amount-down' },
  { name: 'fad fa-sort-amount-up' },
  { name: 'fad fa-sort-down' },
  { name: 'fad fa-sort-numeric-down' },
  { name: 'fad fa-sort-numeric-up' },
  { name: 'fad fa-sort-up' },
  { name: 'fad fa-space-shuttle' },
  { name: 'fad fa-spade' },
  { name: 'fad fa-spinner' },
  { name: 'fad fa-spinner-third' },
  { name: 'fad fa-square' },
  { name: 'fad fa-square-full' },
  { name: 'fad fa-star' },
  { name: 'fad fa-star-exclamation' },
  { name: 'fad fa-star-half' },
  { name: 'fad fa-step-backward' },
  { name: 'fad fa-step-forward' },
  { name: 'fad fa-stethoscope' },
  { name: 'fad fa-sticky-note' },
  { name: 'fad fa-stop' },
  { name: 'fad fa-stop-circle' },
  { name: 'fad fa-stopwatch' },
  { name: 'fad fa-street-view' },
  { name: 'fad fa-strikethrough' },
  { name: 'fad fa-subscript' },
  { name: 'fad fa-subway' },
  { name: 'fad fa-suitcase' },
  { name: 'fad fa-sun' },
  { name: 'fad fa-superscript' },
  { name: 'fad fa-sync' },
  { name: 'fad fa-sync-alt' },
  { name: 'fad fa-syringe' },
  { name: 'fad fa-table' },
  { name: 'fad fa-table-tennis' },
  { name: 'fad fa-tablet' },
  { name: 'fad fa-tablet-alt' },
  { name: 'fad fa-tablet-android' },
  { name: 'fad fa-tablet-android-alt' },
  { name: 'fad fa-tablet-rugged' },
  { name: 'fad fa-tablets' },
  { name: 'fad fa-tachometer' },
  { name: 'fad fa-tachometer-alt' },
  { name: 'fad fa-tag' },
  { name: 'fad fa-tags' },
  { name: 'fad fa-tape' },
  { name: 'fad fa-tasks' },
  { name: 'fad fa-taxi' },
  { name: 'fad fa-tennis-ball' },
  { name: 'fad fa-terminal' },
  { name: 'fad fa-text-height' },
  { name: 'fad fa-text-width' },
  { name: 'fad fa-th' },
  { name: 'fad fa-th-large' },
  { name: 'fad fa-th-list' },
  { name: 'fad fa-thermometer' },
  { name: 'fad fa-thermometer-empty' },
  { name: 'fad fa-thermometer-full' },
  { name: 'fad fa-thermometer-half' },
  { name: 'fad fa-thermometer-quarter' },
  { name: 'fad fa-thermometer-three-quarters' },
  { name: 'fad fa-thumbs-down' },
  { name: 'fad fa-thumbs-up' },
  { name: 'fad fa-thumbtack' },
  { name: 'fad fa-ticket' },
  { name: 'fad fa-ticket-alt' },
  { name: 'fad fa-times' },
  { name: 'fad fa-times-circle' },
  { name: 'fad fa-times-hexagon' },
  { name: 'fad fa-times-octagon' },
  { name: 'fad fa-times-square' },
  { name: 'fad fa-tint' },
  { name: 'fad fa-toggle-off' },
  { name: 'fad fa-toggle-on' },
  { name: 'fad fa-trademark' },
  { name: 'fad fa-train' },
  { name: 'fad fa-transgender' },
  { name: 'fad fa-transgender-alt' },
  { name: 'fad fa-trash' },
  { name: 'fad fa-trash-alt' },
  { name: 'fad fa-tree' },
  { name: 'fad fa-tree-alt' },
  { name: 'fad fa-triangle' },
  { name: 'fad fa-trophy' },
  { name: 'fad fa-trophy-alt' },
  { name: 'fad fa-truck' },
  { name: 'fad fa-truck-container' },
  { name: 'fad fa-truck-couch' },
  { name: 'fad fa-truck-loading' },
  { name: 'fad fa-truck-moving' },
  { name: 'fad fa-truck-ramp' },
  { name: 'fad fa-tty' },
  { name: 'fad fa-tv' },
  { name: 'fad fa-tv-retro' },
  { name: 'fad fa-umbrella' },
  { name: 'fad fa-underline' },
  { name: 'fad fa-undo' },
  { name: 'fad fa-undo-alt' },
  { name: 'fad fa-universal-access' },
  { name: 'fad fa-university' },
  { name: 'fad fa-unlink' },
  { name: 'fad fa-unlock' },
  { name: 'fad fa-unlock-alt' },
  { name: 'fad fa-upload' },
  { name: 'fad fa-usd-circle' },
  { name: 'fad fa-usd-square' },
  { name: 'fad fa-user' },
  { name: 'fad fa-user-alt' },
  { name: 'fad fa-user-circle' },
  { name: 'fad fa-user-md' },
  { name: 'fad fa-user-plus' },
  { name: 'fad fa-user-secret' },
  { name: 'fad fa-user-times' },
  { name: 'fad fa-users' },
  { name: 'fad fa-utensil-fork' },
  { name: 'fad fa-utensil-knife' },
  { name: 'fad fa-utensil-spoon' },
  { name: 'fad fa-utensils' },
  { name: 'fad fa-utensils-alt' },
  { name: 'fad fa-venus' },
  { name: 'fad fa-venus-double' },
  { name: 'fad fa-venus-mars' },
  { name: 'fad fa-vial' },
  { name: 'fad fa-vials' },
  { name: 'fad fa-video' },
  { name: 'fad fa-video-plus' },
  { name: 'fad fa-video-slash' },
  { name: 'fad fa-volleyball-ball' },
  { name: 'fad fa-volume-down' },
  { name: 'fad fa-volume-mute' },
  { name: 'fad fa-volume-off' },
  { name: 'fad fa-volume-up' },
  { name: 'fad fa-warehouse' },
  { name: 'fad fa-warehouse-alt' },
  { name: 'fad fa-watch' },
  { name: 'fad fa-weight' },
  { name: 'fad fa-wheelchair' },
  { name: 'fad fa-whistle' },
  { name: 'fad fa-wifi' },
  { name: 'fad fa-window' },
  { name: 'fad fa-window-alt' },
  { name: 'fad fa-window-close' },
  { name: 'fad fa-window-maximize' },
  { name: 'fad fa-window-minimize' },
  { name: 'fad fa-window-restore' },
  { name: 'fad fa-wine-glass' },
  { name: 'fad fa-won-sign' },
  { name: 'fad fa-wrench' },
  { name: 'fad fa-x-ray' },
  { name: 'fad fa-yen-sign' },
  { name: 'fal fa-address-book' },
  { name: 'fal fa-address-card' },
  { name: 'fal fa-adjust' },
  { name: 'fal fa-alarm-clock' },
  { name: 'fal fa-align-center' },
  { name: 'fal fa-align-justify' },
  { name: 'fal fa-align-left' },
  { name: 'fal fa-align-right' },
  { name: 'fal fa-allergies' },
  { name: 'fal fa-ambulance' },
  { name: 'fal fa-american-sign-language-interpreting' },
  { name: 'fal fa-anchor' },
  { name: 'fal fa-angle-double-down' },
  { name: 'fal fa-angle-double-left' },
  { name: 'fal fa-angle-double-right' },
  { name: 'fal fa-angle-double-up' },
  { name: 'fal fa-angle-down' },
  { name: 'fal fa-angle-left' },
  { name: 'fal fa-angle-right' },
  { name: 'fal fa-angle-up' },
  { name: 'fal fa-archive' },
  { name: 'fal fa-arrow-alt-circle-down' },
  { name: 'fal fa-arrow-alt-circle-left' },
  { name: 'fal fa-arrow-alt-circle-right' },
  { name: 'fal fa-arrow-alt-circle-up' },
  { name: 'fal fa-arrow-alt-down' },
  { name: 'fal fa-arrow-alt-from-bottom' },
  { name: 'fal fa-arrow-alt-from-left' },
  { name: 'fal fa-arrow-alt-from-right' },
  { name: 'fal fa-arrow-alt-from-top' },
  { name: 'fal fa-arrow-alt-left' },
  { name: 'fal fa-arrow-alt-right' },
  { name: 'fal fa-arrow-alt-square-down' },
  { name: 'fal fa-arrow-alt-square-left' },
  { name: 'fal fa-arrow-alt-square-right' },
  { name: 'fal fa-arrow-alt-square-up' },
  { name: 'fal fa-arrow-alt-to-bottom' },
  { name: 'fal fa-arrow-alt-to-left' },
  { name: 'fal fa-arrow-alt-to-right' },
  { name: 'fal fa-arrow-alt-to-top' },
  { name: 'fal fa-arrow-alt-up' },
  { name: 'fal fa-arrow-circle-down' },
  { name: 'fal fa-arrow-circle-left' },
  { name: 'fal fa-arrow-circle-right' },
  { name: 'fal fa-arrow-circle-up' },
  { name: 'fal fa-arrow-down' },
  { name: 'fal fa-arrow-from-bottom' },
  { name: 'fal fa-arrow-from-left' },
  { name: 'fal fa-arrow-from-right' },
  { name: 'fal fa-arrow-from-top' },
  { name: 'fal fa-arrow-left' },
  { name: 'fal fa-arrow-right' },
  { name: 'fal fa-arrow-square-down' },
  { name: 'fal fa-arrow-square-left' },
  { name: 'fal fa-arrow-square-right' },
  { name: 'fal fa-arrow-square-up' },
  { name: 'fal fa-arrow-to-bottom' },
  { name: 'fal fa-arrow-to-left' },
  { name: 'fal fa-arrow-to-right' },
  { name: 'fal fa-arrow-to-top' },
  { name: 'fal fa-arrow-up' },
  { name: 'fal fa-arrows' },
  { name: 'fal fa-arrows-alt' },
  { name: 'fal fa-arrows-alt-h' },
  { name: 'fal fa-arrows-alt-v' },
  { name: 'fal fa-arrows-h' },
  { name: 'fal fa-arrows-v' },
  { name: 'fal fa-assistive-listening-systems' },
  { name: 'fal fa-asterisk' },
  { name: 'fal fa-at' },
  { name: 'fal fa-audio-description' },
  { name: 'fal fa-backward' },
  { name: 'fal fa-badge' },
  { name: 'fal fa-badge-check' },
  { name: 'fal fa-balance-scale' },
  { name: 'fal fa-ban' },
  { name: 'fal fa-band-aid' },
  { name: 'fal fa-barcode' },
  { name: 'fal fa-barcode-alt' },
  { name: 'fal fa-barcode-read' },
  { name: 'fal fa-barcode-scan' },
  { name: 'fal fa-bars' },
  { name: 'fal fa-baseball' },
  { name: 'fal fa-baseball-ball' },
  { name: 'fal fa-basketball-ball' },
  { name: 'fal fa-basketball-hoop' },
  { name: 'fal fa-bath' },
  { name: 'fal fa-battery-bolt' },
  { name: 'fal fa-battery-empty' },
  { name: 'fal fa-battery-full' },
  { name: 'fal fa-battery-half' },
  { name: 'fal fa-battery-quarter' },
  { name: 'fal fa-battery-slash' },
  { name: 'fal fa-battery-three-quarters' },
  { name: 'fal fa-bed' },
  { name: 'fal fa-beer' },
  { name: 'fal fa-bell' },
  { name: 'fal fa-bell-slash' },
  { name: 'fal fa-bicycle' },
  { name: 'fal fa-binoculars' },
  { name: 'fal fa-birthday-cake' },
  { name: 'fal fa-blanket' },
  { name: 'fal fa-blind' },
  { name: 'fal fa-bold' },
  { name: 'fal fa-bolt' },
  { name: 'fal fa-bomb' },
  { name: 'fal fa-book' },
  { name: 'fal fa-book-heart' },
  { name: 'fal fa-bookmark' },
  { name: 'fal fa-bowling-ball' },
  { name: 'fal fa-bowling-pins' },
  { name: 'fal fa-box' },
  { name: 'fal fa-box-alt' },
  { name: 'fal fa-box-check' },
  { name: 'fal fa-box-fragile' },
  { name: 'fal fa-box-full' },
  { name: 'fal fa-box-heart' },
  { name: 'fal fa-box-open' },
  { name: 'fal fa-box-up' },
  { name: 'fal fa-box-usd' },
  { name: 'fal fa-boxes' },
  { name: 'fal fa-boxes-alt' },
  { name: 'fal fa-boxing-glove' },
  { name: 'fal fa-braille' },
  { name: 'fal fa-briefcase' },
  { name: 'fal fa-briefcase-medical' },
  { name: 'fal fa-browser' },
  { name: 'fal fa-bug' },
  { name: 'fal fa-building' },
  { name: 'fal fa-bullhorn' },
  { name: 'fal fa-bullseye' },
  { name: 'fal fa-burn' },
  { name: 'fal fa-bus' },
  { name: 'fal fa-calculator' },
  { name: 'fal fa-calendar' },
  { name: 'fal fa-calendar-alt' },
  { name: 'fal fa-calendar-check' },
  { name: 'fal fa-calendar-edit' },
  { name: 'fal fa-calendar-exclamation' },
  { name: 'fal fa-calendar-minus' },
  { name: 'fal fa-calendar-plus' },
  { name: 'fal fa-calendar-times' },
  { name: 'fal fa-camera' },
  { name: 'fal fa-camera-alt' },
  { name: 'fal fa-camera-retro' },
  { name: 'fal fa-capsules' },
  { name: 'fal fa-car' },
  { name: 'fal fa-caret-circle-down' },
  { name: 'fal fa-caret-circle-left' },
  { name: 'fal fa-caret-circle-right' },
  { name: 'fal fa-caret-circle-up' },
  { name: 'fal fa-caret-down' },
  { name: 'fal fa-caret-left' },
  { name: 'fal fa-caret-right' },
  { name: 'fal fa-caret-square-down' },
  { name: 'fal fa-caret-square-left' },
  { name: 'fal fa-caret-square-right' },
  { name: 'fal fa-caret-square-up' },
  { name: 'fal fa-caret-up' },
  { name: 'fal fa-cart-arrow-down' },
  { name: 'fal fa-cart-plus' },
  { name: 'fal fa-certificate' },
  { name: 'fal fa-chart-area' },
  { name: 'fal fa-chart-bar' },
  { name: 'fal fa-chart-line' },
  { name: 'fal fa-chart-pie' },
  { name: 'fal fa-check' },
  { name: 'fal fa-check-circle' },
  { name: 'fal fa-check-square' },
  { name: 'fal fa-chess' },
  { name: 'fal fa-chess-bishop' },
  { name: 'fal fa-chess-bishop-alt' },
  { name: 'fal fa-chess-board' },
  { name: 'fal fa-chess-clock' },
  { name: 'fal fa-chess-clock-alt' },
  { name: 'fal fa-chess-king' },
  { name: 'fal fa-chess-king-alt' },
  { name: 'fal fa-chess-knight' },
  { name: 'fal fa-chess-knight-alt' },
  { name: 'fal fa-chess-pawn' },
  { name: 'fal fa-chess-pawn-alt' },
  { name: 'fal fa-chess-queen' },
  { name: 'fal fa-chess-queen-alt' },
  { name: 'fal fa-chess-rook' },
  { name: 'fal fa-chess-rook-alt' },
  { name: 'fal fa-chevron-circle-down' },
  { name: 'fal fa-chevron-circle-left' },
  { name: 'fal fa-chevron-circle-right' },
  { name: 'fal fa-chevron-circle-up' },
  { name: 'fal fa-chevron-double-down' },
  { name: 'fal fa-chevron-double-left' },
  { name: 'fal fa-chevron-double-right' },
  { name: 'fal fa-chevron-double-up' },
  { name: 'fal fa-chevron-down' },
  { name: 'fal fa-chevron-left' },
  { name: 'fal fa-chevron-right' },
  { name: 'fal fa-chevron-square-down' },
  { name: 'fal fa-chevron-square-left' },
  { name: 'fal fa-chevron-square-right' },
  { name: 'fal fa-chevron-square-up' },
  { name: 'fal fa-chevron-up' },
  { name: 'fal fa-child' },
  { name: 'fal fa-circle' },
  { name: 'fal fa-circle-notch' },
  { name: 'fal fa-clipboard' },
  { name: 'fal fa-clipboard-check' },
  { name: 'fal fa-clipboard-list' },
  { name: 'fal fa-clock' },
  { name: 'fal fa-clone' },
  { name: 'fal fa-closed-captioning' },
  { name: 'fal fa-cloud' },
  { name: 'fal fa-cloud-download' },
  { name: 'fal fa-cloud-download-alt' },
  { name: 'fal fa-cloud-upload' },
  { name: 'fal fa-cloud-upload-alt' },
  { name: 'fal fa-club' },
  { name: 'fal fa-code' },
  { name: 'fal fa-code-branch' },
  { name: 'fal fa-code-commit' },
  { name: 'fal fa-code-merge' },
  { name: 'fal fa-coffee' },
  { name: 'fal fa-cog' },
  { name: 'fal fa-cogs' },
  { name: 'fal fa-columns' },
  { name: 'fal fa-comment' },
  { name: 'fal fa-comment-alt' },
  { name: 'fal fa-comment-alt-check' },
  { name: 'fal fa-comment-alt-dots' },
  { name: 'fal fa-comment-alt-edit' },
  { name: 'fal fa-comment-alt-exclamation' },
  { name: 'fal fa-comment-alt-lines' },
  { name: 'fal fa-comment-alt-minus' },
  { name: 'fal fa-comment-alt-plus' },
  { name: 'fal fa-comment-alt-slash' },
  { name: 'fal fa-comment-alt-smile' },
  { name: 'fal fa-comment-alt-times' },
  { name: 'fal fa-comment-check' },
  { name: 'fal fa-comment-dots' },
  { name: 'fal fa-comment-edit' },
  { name: 'fal fa-comment-exclamation' },
  { name: 'fal fa-comment-lines' },
  { name: 'fal fa-comment-minus' },
  { name: 'fal fa-comment-plus' },
  { name: 'fal fa-comment-slash' },
  { name: 'fal fa-comment-smile' },
  { name: 'fal fa-comment-times' },
  { name: 'fal fa-comments' },
  { name: 'fal fa-comments-alt' },
  { name: 'fal fa-compass' },
  { name: 'fal fa-compress' },
  { name: 'fal fa-compress-alt' },
  { name: 'fal fa-compress-wide' },
  { name: 'fal fa-container-storage' },
  { name: 'fal fa-conveyor-belt' },
  { name: 'fal fa-conveyor-belt-alt' },
  { name: 'fal fa-copy' },
  { name: 'fal fa-copyright' },
  { name: 'fal fa-couch' },
  { name: 'fal fa-credit-card' },
  { name: 'fal fa-credit-card-blank' },
  { name: 'fal fa-credit-card-front' },
  { name: 'fal fa-cricket' },
  { name: 'fal fa-crop' },
  { name: 'fal fa-crosshairs' },
  { name: 'fal fa-cube' },
  { name: 'fal fa-cubes' },
  { name: 'fal fa-curling' },
  { name: 'fal fa-cut' },
  { name: 'fal fa-database' },
  { name: 'fal fa-deaf' },
  { name: 'fal fa-desktop' },
  { name: 'fal fa-desktop-alt' },
  { name: 'fal fa-diagnoses' },
  { name: 'fal fa-diamond' },
  { name: 'fal fa-dna' },
  { name: 'fal fa-dollar-sign' },
  { name: 'fal fa-dolly' },
  { name: 'fal fa-dolly-empty' },
  { name: 'fal fa-dolly-flatbed' },
  { name: 'fal fa-dolly-flatbed-alt' },
  { name: 'fal fa-dolly-flatbed-empty' },
  { name: 'fal fa-donate' },
  { name: 'fal fa-dot-circle' },
  { name: 'fal fa-dove' },
  { name: 'fal fa-download' },
  { name: 'fal fa-dumbbell' },
  { name: 'fal fa-edit' },
  { name: 'fal fa-eject' },
  { name: 'fal fa-ellipsis-h' },
  { name: 'fal fa-ellipsis-h-alt' },
  { name: 'fal fa-ellipsis-v' },
  { name: 'fal fa-ellipsis-v-alt' },
  { name: 'fal fa-envelope' },
  { name: 'fal fa-envelope-open' },
  { name: 'fal fa-envelope-square' },
  { name: 'fal fa-eraser' },
  { name: 'fal fa-euro-sign' },
  { name: 'fal fa-exchange' },
  { name: 'fal fa-exchange-alt' },
  { name: 'fal fa-exclamation' },
  { name: 'fal fa-exclamation-circle' },
  { name: 'fal fa-exclamation-square' },
  { name: 'fal fa-exclamation-triangle' },
  { name: 'fal fa-expand' },
  { name: 'fal fa-expand-alt' },
  { name: 'fal fa-expand-arrows' },
  { name: 'fal fa-expand-arrows-alt' },
  { name: 'fal fa-expand-wide' },
  { name: 'fal fa-external-link' },
  { name: 'fal fa-external-link-alt' },
  { name: 'fal fa-external-link-square' },
  { name: 'fal fa-external-link-square-alt' },
  { name: 'fal fa-eye' },
  { name: 'fal fa-eye-dropper' },
  { name: 'fal fa-eye-slash' },
  { name: 'fal fa-fast-backward' },
  { name: 'fal fa-fast-forward' },
  { name: 'fal fa-fax' },
  { name: 'fal fa-female' },
  { name: 'fal fa-field-hockey' },
  { name: 'fal fa-fighter-jet' },
  { name: 'fal fa-file' },
  { name: 'fal fa-file-alt' },
  { name: 'fal fa-file-archive' },
  { name: 'fal fa-file-audio' },
  { name: 'fal fa-file-check' },
  { name: 'fal fa-file-code' },
  { name: 'fal fa-file-edit' },
  { name: 'fal fa-file-excel' },
  { name: 'fal fa-file-exclamation' },
  { name: 'fal fa-file-image' },
  { name: 'fal fa-file-medical' },
  { name: 'fal fa-file-medical-alt' },
  { name: 'fal fa-file-minus' },
  { name: 'fal fa-file-pdf' },
  { name: 'fal fa-file-plus' },
  { name: 'fal fa-file-powerpoint' },
  { name: 'fal fa-file-times' },
  { name: 'fal fa-file-video' },
  { name: 'fal fa-file-word' },
  { name: 'fal fa-film' },
  { name: 'fal fa-film-alt' },
  { name: 'fal fa-filter' },
  { name: 'fal fa-fire' },
  { name: 'fal fa-fire-extinguisher' },
  { name: 'fal fa-first-aid' },
  { name: 'fal fa-flag' },
  { name: 'fal fa-flag-checkered' },
  { name: 'fal fa-flask' },
  { name: 'fal fa-folder' },
  { name: 'fal fa-folder-open' },
  { name: 'fal fa-font' },
  { name: 'fal fa-football-ball' },
  { name: 'fal fa-football-helmet' },
  { name: 'fal fa-forklift' },
  { name: 'fal fa-forward' },
  { name: 'fal fa-fragile' },
  { name: 'fal fa-frown' },
  { name: 'fal fa-futbol' },
  { name: 'fal fa-gamepad' },
  { name: 'fal fa-gavel' },
  { name: 'fal fa-gem' },
  { name: 'fal fa-genderless' },
  { name: 'fal fa-gift' },
  { name: 'fal fa-glass-martini' },
  { name: 'fal fa-globe' },
  { name: 'fal fa-golf-ball' },
  { name: 'fal fa-golf-club' },
  { name: 'fal fa-graduation-cap' },
  { name: 'fal fa-h-square' },
  { name: 'fal fa-h1' },
  { name: 'fal fa-h2' },
  { name: 'fal fa-h3' },
  { name: 'fal fa-hand-heart' },
  { name: 'fal fa-hand-holding' },
  { name: 'fal fa-hand-holding-box' },
  { name: 'fal fa-hand-holding-heart' },
  { name: 'fal fa-hand-holding-seedling' },
  { name: 'fal fa-hand-holding-usd' },
  { name: 'fal fa-hand-holding-water' },
  { name: 'fal fa-hand-lizard' },
  { name: 'fal fa-hand-paper' },
  { name: 'fal fa-hand-peace' },
  { name: 'fal fa-hand-point-down' },
  { name: 'fal fa-hand-point-left' },
  { name: 'fal fa-hand-point-right' },
  { name: 'fal fa-hand-point-up' },
  { name: 'fal fa-hand-pointer' },
  { name: 'fal fa-hand-receiving' },
  { name: 'fal fa-hand-rock' },
  { name: 'fal fa-hand-scissors' },
  { name: 'fal fa-hand-spock' },
  { name: 'fal fa-hands' },
  { name: 'fal fa-hands-heart' },
  { name: 'fal fa-hands-helping' },
  { name: 'fal fa-hands-usd' },
  { name: 'fal fa-handshake' },
  { name: 'fal fa-handshake-alt' },
  { name: 'fal fa-hashtag' },
  { name: 'fal fa-hdd' },
  { name: 'fal fa-heading' },
  { name: 'fal fa-headphones' },
  { name: 'fal fa-heart' },
  { name: 'fal fa-heart-circle' },
  { name: 'fal fa-heart-square' },
  { name: 'fal fa-heartbeat' },
  { name: 'fal fa-hexagon' },
  { name: 'fal fa-history' },
  { name: 'fal fa-hockey-puck' },
  { name: 'fal fa-hockey-sticks' },
  { name: 'fal fa-home' },
  { name: 'fal fa-home-heart' },
  { name: 'fal fa-hospital' },
  { name: 'fal fa-hospital-alt' },
  { name: 'fal fa-hospital-symbol' },
  { name: 'fal fa-hourglass' },
  { name: 'fal fa-hourglass-end' },
  { name: 'fal fa-hourglass-half' },
  { name: 'fal fa-hourglass-start' },
  { name: 'fal fa-i-cursor' },
  { name: 'fal fa-id-badge' },
  { name: 'fal fa-id-card' },
  { name: 'fal fa-id-card-alt' },
  { name: 'fal fa-image' },
  { name: 'fal fa-images' },
  { name: 'fal fa-inbox' },
  { name: 'fal fa-inbox-in' },
  { name: 'fal fa-inbox-out' },
  { name: 'fal fa-indent' },
  { name: 'fal fa-industry' },
  { name: 'fal fa-industry-alt' },
  { name: 'fal fa-info' },
  { name: 'fal fa-info-circle' },
  { name: 'fal fa-info-square' },
  { name: 'fal fa-inventory' },
  { name: 'fal fa-italic' },
  { name: 'fal fa-jack-o-lantern' },
  { name: 'fal fa-key' },
  { name: 'fal fa-keyboard' },
  { name: 'fal fa-lamp' },
  { name: 'fal fa-language' },
  { name: 'fal fa-laptop' },
  { name: 'fal fa-leaf' },
  { name: 'fal fa-leaf-heart' },
  { name: 'fal fa-lemon' },
  { name: 'fal fa-level-down' },
  { name: 'fal fa-level-down-alt' },
  { name: 'fal fa-level-up' },
  { name: 'fal fa-level-up-alt' },
  { name: 'fal fa-life-ring' },
  { name: 'fal fa-lightbulb' },
  { name: 'fal fa-link' },
  { name: 'fal fa-lira-sign' },
  { name: 'fal fa-list' },
  { name: 'fal fa-list-alt' },
  { name: 'fal fa-list-ol' },
  { name: 'fal fa-list-ul' },
  { name: 'fal fa-location-arrow' },
  { name: 'fal fa-lock' },
  { name: 'fal fa-lock-alt' },
  { name: 'fal fa-lock-open' },
  { name: 'fal fa-lock-open-alt' },
  { name: 'fal fa-long-arrow-alt-down' },
  { name: 'fal fa-long-arrow-alt-left' },
  { name: 'fal fa-long-arrow-alt-right' },
  { name: 'fal fa-long-arrow-alt-up' },
  { name: 'fal fa-long-arrow-down' },
  { name: 'fal fa-long-arrow-left' },
  { name: 'fal fa-long-arrow-right' },
  { name: 'fal fa-long-arrow-up' },
  { name: 'fal fa-loveseat' },
  { name: 'fal fa-low-vision' },
  { name: 'fal fa-luchador' },
  { name: 'fal fa-magic' },
  { name: 'fal fa-magnet' },
  { name: 'fal fa-male' },
  { name: 'fal fa-map' },
  { name: 'fal fa-map-marker' },
  { name: 'fal fa-map-marker-alt' },
  { name: 'fal fa-map-pin' },
  { name: 'fal fa-map-signs' },
  { name: 'fal fa-mars' },
  { name: 'fal fa-mars-double' },
  { name: 'fal fa-mars-stroke' },
  { name: 'fal fa-mars-stroke-h' },
  { name: 'fal fa-mars-stroke-v' },
  { name: 'fal fa-medkit' },
  { name: 'fal fa-meh' },
  { name: 'fal fa-mercury' },
  { name: 'fal fa-microchip' },
  { name: 'fal fa-microphone' },
  { name: 'fal fa-microphone-alt' },
  { name: 'fal fa-microphone-slash' },
  { name: 'fal fa-minus' },
  { name: 'fal fa-minus-circle' },
  { name: 'fal fa-minus-hexagon' },
  { name: 'fal fa-minus-octagon' },
  { name: 'fal fa-minus-square' },
  { name: 'fal fa-mobile' },
  { name: 'fal fa-mobile-alt' },
  { name: 'fal fa-mobile-android' },
  { name: 'fal fa-mobile-android-alt' },
  { name: 'fal fa-money-bill' },
  { name: 'fal fa-money-bill-alt' },
  { name: 'fal fa-moon' },
  { name: 'fal fa-motorcycle' },
  { name: 'fal fa-mouse-pointer' },
  { name: 'fal fa-music' },
  { name: 'fal fa-neuter' },
  { name: 'fal fa-newspaper' },
  { name: 'fal fa-notes-medical' },
  { name: 'fal fa-object-group' },
  { name: 'fal fa-object-ungroup' },
  { name: 'fal fa-octagon' },
  { name: 'fal fa-outdent' },
  { name: 'fal fa-paint-brush' },
  { name: 'fal fa-pallet' },
  { name: 'fal fa-pallet-alt' },
  { name: 'fal fa-paper-plane' },
  { name: 'fal fa-paperclip' },
  { name: 'fal fa-parachute-box' },
  { name: 'fal fa-paragraph' },
  { name: 'fal fa-paste' },
  { name: 'fal fa-pause' },
  { name: 'fal fa-pause-circle' },
  { name: 'fal fa-paw' },
  { name: 'fal fa-pen' },
  { name: 'fal fa-pen-alt' },
  { name: 'fal fa-pen-square' },
  { name: 'fal fa-pencil' },
  { name: 'fal fa-pencil-alt' },
  { name: 'fal fa-pennant' },
  { name: 'fal fa-people-carry' },
  { name: 'fal fa-percent' },
  { name: 'fal fa-person-carry' },
  { name: 'fal fa-person-dolly' },
  { name: 'fal fa-person-dolly-empty' },
  { name: 'fal fa-phone' },
  { name: 'fal fa-phone-plus' },
  { name: 'fal fa-phone-slash' },
  { name: 'fal fa-phone-square' },
  { name: 'fal fa-phone-volume' },
  { name: 'fal fa-piggy-bank' },
  { name: 'fal fa-pills' },
  { name: 'fal fa-plane' },
  { name: 'fal fa-plane-alt' },
  { name: 'fal fa-play' },
  { name: 'fal fa-play-circle' },
  { name: 'fal fa-plug' },
  { name: 'fal fa-plus' },
  { name: 'fal fa-plus-circle' },
  { name: 'fal fa-plus-hexagon' },
  { name: 'fal fa-plus-octagon' },
  { name: 'fal fa-plus-square' },
  { name: 'fal fa-podcast' },
  { name: 'fal fa-poo' },
  { name: 'fal fa-portrait' },
  { name: 'fal fa-pound-sign' },
  { name: 'fal fa-power-off' },
  { name: 'fal fa-prescription-bottle' },
  { name: 'fal fa-prescription-bottle-alt' },
  { name: 'fal fa-print' },
  { name: 'fal fa-procedures' },
  { name: 'fal fa-puzzle-piece' },
  { name: 'fal fa-qrcode' },
  { name: 'fal fa-question' },
  { name: 'fal fa-question-circle' },
  { name: 'fal fa-question-square' },
  { name: 'fal fa-quidditch' },
  { name: 'fal fa-quote-left' },
  { name: 'fal fa-quote-right' },
  { name: 'fal fa-racquet' },
  { name: 'fal fa-ramp-loading' },
  { name: 'fal fa-random' },
  { name: 'fal fa-rectangle-landscape' },
  { name: 'fal fa-rectangle-portrait' },
  { name: 'fal fa-rectangle-wide' },
  { name: 'fal fa-recycle' },
  { name: 'fal fa-redo' },
  { name: 'fal fa-redo-alt' },
  { name: 'fal fa-registered' },
  { name: 'fal fa-repeat' },
  { name: 'fal fa-repeat-1' },
  { name: 'fal fa-repeat-1-alt' },
  { name: 'fal fa-repeat-alt' },
  { name: 'fal fa-reply' },
  { name: 'fal fa-reply-all' },
  { name: 'fal fa-retweet' },
  { name: 'fal fa-retweet-alt' },
  { name: 'fal fa-ribbon' },
  { name: 'fal fa-road' },
  { name: 'fal fa-rocket' },
  { name: 'fal fa-route' },
  { name: 'fal fa-rss' },
  { name: 'fal fa-rss-square' },
  { name: 'fal fa-ruble-sign' },
  { name: 'fal fa-rupee-sign' },
  { name: 'fal fa-save' },
  { name: 'fal fa-scanner' },
  { name: 'fal fa-scanner-keyboard' },
  { name: 'fal fa-scanner-touchscreen' },
  { name: 'fal fa-scrubber' },
  { name: 'fal fa-search' },
  { name: 'fal fa-search-minus' },
  { name: 'fal fa-search-plus' },
  { name: 'fal fa-seedling' },
  { name: 'fal fa-server' },
  { name: 'fal fa-share' },
  { name: 'fal fa-share-all' },
  { name: 'fal fa-share-alt' },
  { name: 'fal fa-share-alt-square' },
  { name: 'fal fa-share-square' },
  { name: 'fal fa-shekel-sign' },
  { name: 'fal fa-shield' },
  { name: 'fal fa-shield-alt' },
  { name: 'fal fa-shield-check' },
  { name: 'fal fa-ship' },
  { name: 'fal fa-shipping-fast' },
  { name: 'fal fa-shipping-timed' },
  { name: 'fal fa-shopping-bag' },
  { name: 'fal fa-shopping-basket' },
  { name: 'fal fa-shopping-cart' },
  { name: 'fal fa-shower' },
  { name: 'fal fa-shuttlecock' },
  { name: 'fal fa-sign' },
  { name: 'fal fa-sign-in' },
  { name: 'fal fa-sign-in-alt' },
  { name: 'fal fa-sign-language' },
  { name: 'fal fa-sign-out' },
  { name: 'fal fa-sign-out-alt' },
  { name: 'fal fa-signal' },
  { name: 'fal fa-sitemap' },
  { name: 'fal fa-sliders-h' },
  { name: 'fal fa-sliders-h-square' },
  { name: 'fal fa-sliders-v' },
  { name: 'fal fa-sliders-v-square' },
  { name: 'fal fa-smile' },
  { name: 'fal fa-smile-plus' },
  { name: 'fal fa-smoking' },
  { name: 'fal fa-snowflake' },
  { name: 'fal fa-sort' },
  { name: 'fal fa-sort-alpha-down' },
  { name: 'fal fa-sort-alpha-up' },
  { name: 'fal fa-sort-amount-down' },
  { name: 'fal fa-sort-amount-up' },
  { name: 'fal fa-sort-down' },
  { name: 'fal fa-sort-numeric-down' },
  { name: 'fal fa-sort-numeric-up' },
  { name: 'fal fa-sort-up' },
  { name: 'fal fa-space-shuttle' },
  { name: 'fal fa-spade' },
  { name: 'fal fa-spinner' },
  { name: 'fal fa-spinner-third' },
  { name: 'fal fa-square' },
  { name: 'fal fa-square-full' },
  { name: 'fal fa-star' },
  { name: 'fal fa-star-exclamation' },
  { name: 'fal fa-star-half' },
  { name: 'fal fa-step-backward' },
  { name: 'fal fa-step-forward' },
  { name: 'fal fa-stethoscope' },
  { name: 'fal fa-sticky-note' },
  { name: 'fal fa-stop' },
  { name: 'fal fa-stop-circle' },
  { name: 'fal fa-stopwatch' },
  { name: 'fal fa-street-view' },
  { name: 'fal fa-strikethrough' },
  { name: 'fal fa-subscript' },
  { name: 'fal fa-subway' },
  { name: 'fal fa-suitcase' },
  { name: 'fal fa-sun' },
  { name: 'fal fa-superscript' },
  { name: 'fal fa-sync' },
  { name: 'fal fa-sync-alt' },
  { name: 'fal fa-syringe' },
  { name: 'fal fa-table' },
  { name: 'fal fa-table-tennis' },
  { name: 'fal fa-tablet' },
  { name: 'fal fa-tablet-alt' },
  { name: 'fal fa-tablet-android' },
  { name: 'fal fa-tablet-android-alt' },
  { name: 'fal fa-tablet-rugged' },
  { name: 'fal fa-tablets' },
  { name: 'fal fa-tachometer' },
  { name: 'fal fa-tachometer-alt' },
  { name: 'fal fa-tag' },
  { name: 'fal fa-tags' },
  { name: 'fal fa-tape' },
  { name: 'fal fa-tasks' },
  { name: 'fal fa-taxi' },
  { name: 'fal fa-tennis-ball' },
  { name: 'fal fa-terminal' },
  { name: 'fal fa-text-height' },
  { name: 'fal fa-text-width' },
  { name: 'fal fa-th' },
  { name: 'fal fa-th-large' },
  { name: 'fal fa-th-list' },
  { name: 'fal fa-thermometer' },
  { name: 'fal fa-thermometer-empty' },
  { name: 'fal fa-thermometer-full' },
  { name: 'fal fa-thermometer-half' },
  { name: 'fal fa-thermometer-quarter' },
  { name: 'fal fa-thermometer-three-quarters' },
  { name: 'fal fa-thumbs-down' },
  { name: 'fal fa-thumbs-up' },
  { name: 'fal fa-thumbtack' },
  { name: 'fal fa-ticket' },
  { name: 'fal fa-ticket-alt' },
  { name: 'fal fa-times' },
  { name: 'fal fa-times-circle' },
  { name: 'fal fa-times-hexagon' },
  { name: 'fal fa-times-octagon' },
  { name: 'fal fa-times-square' },
  { name: 'fal fa-tint' },
  { name: 'fal fa-toggle-off' },
  { name: 'fal fa-toggle-on' },
  { name: 'fal fa-trademark' },
  { name: 'fal fa-train' },
  { name: 'fal fa-transgender' },
  { name: 'fal fa-transgender-alt' },
  { name: 'fal fa-trash' },
  { name: 'fal fa-trash-alt' },
  { name: 'fal fa-tree' },
  { name: 'fal fa-tree-alt' },
  { name: 'fal fa-triangle' },
  { name: 'fal fa-trophy' },
  { name: 'fal fa-trophy-alt' },
  { name: 'fal fa-truck' },
  { name: 'fal fa-truck-container' },
  { name: 'fal fa-truck-couch' },
  { name: 'fal fa-truck-loading' },
  { name: 'fal fa-truck-moving' },
  { name: 'fal fa-truck-ramp' },
  { name: 'fal fa-tty' },
  { name: 'fal fa-tv' },
  { name: 'fal fa-tv-retro' },
  { name: 'fal fa-umbrella' },
  { name: 'fal fa-underline' },
  { name: 'fal fa-undo' },
  { name: 'fal fa-undo-alt' },
  { name: 'fal fa-universal-access' },
  { name: 'fal fa-university' },
  { name: 'fal fa-unlink' },
  { name: 'fal fa-unlock' },
  { name: 'fal fa-unlock-alt' },
  { name: 'fal fa-upload' },
  { name: 'fal fa-usd-circle' },
  { name: 'fal fa-usd-square' },
  { name: 'fal fa-user' },
  { name: 'fal fa-user-alt' },
  { name: 'fal fa-user-circle' },
  { name: 'fal fa-user-md' },
  { name: 'fal fa-user-plus' },
  { name: 'fal fa-user-secret' },
  { name: 'fal fa-user-times' },
  { name: 'fal fa-users' },
  { name: 'fal fa-utensil-fork' },
  { name: 'fal fa-utensil-knife' },
  { name: 'fal fa-utensil-spoon' },
  { name: 'fal fa-utensils' },
  { name: 'fal fa-utensils-alt' },
  { name: 'fal fa-venus' },
  { name: 'fal fa-venus-double' },
  { name: 'fal fa-venus-mars' },
  { name: 'fal fa-vial' },
  { name: 'fal fa-vials' },
  { name: 'fal fa-video' },
  { name: 'fal fa-video-plus' },
  { name: 'fal fa-video-slash' },
  { name: 'fal fa-volleyball-ball' },
  { name: 'fal fa-volume-down' },
  { name: 'fal fa-volume-mute' },
  { name: 'fal fa-volume-off' },
  { name: 'fal fa-volume-up' },
  { name: 'fal fa-warehouse' },
  { name: 'fal fa-warehouse-alt' },
  { name: 'fal fa-watch' },
  { name: 'fal fa-weight' },
  { name: 'fal fa-wheelchair' },
  { name: 'fal fa-whistle' },
  { name: 'fal fa-wifi' },
  { name: 'fal fa-window' },
  { name: 'fal fa-window-alt' },
  { name: 'fal fa-window-close' },
  { name: 'fal fa-window-maximize' },
  { name: 'fal fa-window-minimize' },
  { name: 'fal fa-window-restore' },
  { name: 'fal fa-wine-glass' },
  { name: 'fal fa-won-sign' },
  { name: 'fal fa-wrench' },
  { name: 'fal fa-x-ray' },
  { name: 'fal fa-yen-sign' },
  { name: 'fab fa-500px' },
  { name: 'fab fa-accessible-icon' },
  { name: 'fab fa-accusoft' },
  { name: 'fab fa-adn' },
  { name: 'fab fa-adversal' },
  { name: 'fab fa-affiliatetheme' },
  { name: 'fab fa-algolia' },
  { name: 'fab fa-amazon' },
  { name: 'fab fa-amazon-pay' },
  { name: 'fab fa-amilia' },
  { name: 'fab fa-android' },
  { name: 'fab fa-angellist' },
  { name: 'fab fa-angrycreative' },
  { name: 'fab fa-angular' },
  { name: 'fab fa-app-store' },
  { name: 'fab fa-app-store-ios' },
  { name: 'fab fa-apper' },
  { name: 'fab fa-apple' },
  { name: 'fab fa-apple-pay' },
  { name: 'fab fa-asymmetrik' },
  { name: 'fab fa-audible' },
  { name: 'fab fa-autoprefixer' },
  { name: 'fab fa-avianex' },
  { name: 'fab fa-aviato' },
  { name: 'fab fa-aws' },
  { name: 'fab fa-bandcamp' },
  { name: 'fab fa-behance' },
  { name: 'fab fa-behance-square' },
  { name: 'fab fa-bimobject' },
  { name: 'fab fa-bitbucket' },
  { name: 'fab fa-bitcoin' },
  { name: 'fab fa-bity' },
  { name: 'fab fa-black-tie' },
  { name: 'fab fa-blackberry' },
  { name: 'fab fa-blogger' },
  { name: 'fab fa-blogger-b' },
  { name: 'fab fa-bluetooth' },
  { name: 'fab fa-bluetooth-b' },
  { name: 'fab fa-btc' },
  { name: 'fab fa-buromobelexperte' },
  { name: 'fab fa-buysellads' },
  { name: 'fab fa-cc-amazon-pay' },
  { name: 'fab fa-cc-amex' },
  { name: 'fab fa-cc-apple-pay' },
  { name: 'fab fa-cc-diners-club' },
  { name: 'fab fa-cc-discover' },
  { name: 'fab fa-cc-jcb' },
  { name: 'fab fa-cc-mastercard' },
  { name: 'fab fa-cc-paypal' },
  { name: 'fab fa-cc-stripe' },
  { name: 'fab fa-cc-visa' },
  { name: 'fab fa-centercode' },
  { name: 'fab fa-chrome' },
  { name: 'fab fa-cloudscale' },
  { name: 'fab fa-cloudsmith' },
  { name: 'fab fa-cloudversify' },
  { name: 'fab fa-codepen' },
  { name: 'fab fa-codiepie' },
  { name: 'fab fa-connectdevelop' },
  { name: 'fab fa-contao' },
  { name: 'fab fa-cpanel' },
  { name: 'fab fa-creative-commons' },
  { name: 'fab fa-css3' },
  { name: 'fab fa-css3-alt' },
  { name: 'fab fa-cuttlefish' },
  { name: 'fab fa-d-and-d' },
  { name: 'fab fa-dashcube' },
  { name: 'fab fa-delicious' },
  { name: 'fab fa-deploydog' },
  { name: 'fab fa-deskpro' },
  { name: 'fab fa-deviantart' },
  { name: 'fab fa-digg' },
  { name: 'fab fa-digital-ocean' },
  { name: 'fab fa-discord' },
  { name: 'fab fa-discourse' },
  { name: 'fab fa-dochub' },
  { name: 'fab fa-docker' },
  { name: 'fab fa-draft2digital' },
  { name: 'fab fa-dribbble' },
  { name: 'fab fa-dribbble-square' },
  { name: 'fab fa-dropbox' },
  { name: 'fab fa-drupal' },
  { name: 'fab fa-dyalog' },
  { name: 'fab fa-earlybirds' },
  { name: 'fab fa-edge' },
  { name: 'fab fa-elementor' },
  { name: 'fab fa-ember' },
  { name: 'fab fa-empire' },
  { name: 'fab fa-envira' },
  { name: 'fab fa-erlang' },
  { name: 'fab fa-ethereum' },
  { name: 'fab fa-etsy' },
  { name: 'fab fa-expeditedssl' },
  { name: 'fab fa-facebook' },
  { name: 'fab fa-facebook-f' },
  { name: 'fab fa-facebook-messenger' },
  { name: 'fab fa-facebook-square' },
  { name: 'fab fa-firefox' },
  { name: 'fab fa-first-order' },
  { name: 'fab fa-firstdraft' },
  { name: 'fab fa-flickr' },
  { name: 'fab fa-flipboard' },
  { name: 'fab fa-fly' },
  { name: 'fab fa-font-awesome' },
  { name: 'fab fa-font-awesome-alt' },
  { name: 'fab fa-font-awesome-flag' },
  { name: 'fab fa-fonticons' },
  { name: 'fab fa-fonticons-fi' },
  { name: 'fab fa-fort-awesome' },
  { name: 'fab fa-fort-awesome-alt' },
  { name: 'fab fa-forumbee' },
  { name: 'fab fa-foursquare' },
  { name: 'fab fa-free-code-camp' },
  { name: 'fab fa-freebsd' },
  { name: 'fab fa-get-pocket' },
  { name: 'fab fa-gg' },
  { name: 'fab fa-gg-circle' },
  { name: 'fab fa-git' },
  { name: 'fab fa-git-square' },
  { name: 'fab fa-github' },
  { name: 'fab fa-github-alt' },
  { name: 'fab fa-github-square' },
  { name: 'fab fa-gitkraken' },
  { name: 'fab fa-gitlab' },
  { name: 'fab fa-gitter' },
  { name: 'fab fa-glide' },
  { name: 'fab fa-glide-g' },
  { name: 'fab fa-gofore' },
  { name: 'fab fa-goodreads' },
  { name: 'fab fa-goodreads-g' },
  { name: 'fab fa-google' },
  { name: 'fab fa-google-drive' },
  { name: 'fab fa-google-play' },
  { name: 'fab fa-google-plus' },
  { name: 'fab fa-google-plus-g' },
  { name: 'fab fa-google-plus-square' },
  { name: 'fab fa-google-wallet' },
  { name: 'fab fa-gratipay' },
  { name: 'fab fa-grav' },
  { name: 'fab fa-gripfire' },
  { name: 'fab fa-grunt' },
  { name: 'fab fa-gulp' },
  { name: 'fab fa-hacker-news' },
  { name: 'fab fa-hacker-news-square' },
  { name: 'fab fa-hips' },
  { name: 'fab fa-hire-a-helper' },
  { name: 'fab fa-hooli' },
  { name: 'fab fa-hotjar' },
  { name: 'fab fa-houzz' },
  { name: 'fab fa-html5' },
  { name: 'fab fa-hubspot' },
  { name: 'fab fa-imdb' },
  { name: 'fab fa-instagram' },
  { name: 'fab fa-internet-explorer' },
  { name: 'fab fa-ioxhost' },
  { name: 'fab fa-itunes' },
  { name: 'fab fa-itunes-note' },
  { name: 'fab fa-jenkins' },
  { name: 'fab fa-joget' },
  { name: 'fab fa-joomla' },
  { name: 'fab fa-js' },
  { name: 'fab fa-js-square' },
  { name: 'fab fa-jsfiddle' },
  { name: 'fab fa-keycdn' },
  { name: 'fab fa-kickstarter' },
  { name: 'fab fa-kickstarter-k' },
  { name: 'fab fa-korvue' },
  { name: 'fab fa-laravel' },
  { name: 'fab fa-lastfm' },
  { name: 'fab fa-lastfm-square' },
  { name: 'fab fa-leanpub' },
  { name: 'fab fa-less' },
  { name: 'fab fa-line' },
  { name: 'fab fa-linkedin' },
  { name: 'fab fa-linkedin-in' },
  { name: 'fab fa-linode' },
  { name: 'fab fa-linux' },
  { name: 'fab fa-lyft' },
  { name: 'fab fa-magento' },
  { name: 'fab fa-maxcdn' },
  { name: 'fab fa-medapps' },
  { name: 'fab fa-medium' },
  { name: 'fab fa-medium-m' },
  { name: 'fab fa-medrt' },
  { name: 'fab fa-meetup' },
  { name: 'fab fa-microsoft' },
  { name: 'fab fa-mix' },
  { name: 'fab fa-mixcloud' },
  { name: 'fab fa-mizuni' },
  { name: 'fab fa-modx' },
  { name: 'fab fa-monero' },
  { name: 'fab fa-napster' },
  { name: 'fab fa-nintendo-switch' },
  { name: 'fab fa-node' },
  { name: 'fab fa-node-js' },
  { name: 'fab fa-npm' },
  { name: 'fab fa-ns8' },
  { name: 'fab fa-nutritionix' },
  { name: 'fab fa-odnoklassniki' },
  { name: 'fab fa-odnoklassniki-square' },
  { name: 'fab fa-opencart' },
  { name: 'fab fa-openid' },
  { name: 'fab fa-opera' },
  { name: 'fab fa-optin-monster' },
  { name: 'fab fa-osi' },
  { name: 'fab fa-page4' },
  { name: 'fab fa-pagelines' },
  { name: 'fab fa-palfed' },
  { name: 'fab fa-patreon' },
  { name: 'fab fa-paypal' },
  { name: 'fab fa-periscope' },
  { name: 'fab fa-phabricator' },
  { name: 'fab fa-phoenix-framework' },
  { name: 'fab fa-php' },
  { name: 'fab fa-pied-piper' },
  { name: 'fab fa-pied-piper-alt' },
  { name: 'fab fa-pied-piper-pp' },
  { name: 'fab fa-pinterest' },
  { name: 'fab fa-pinterest-p' },
  { name: 'fab fa-pinterest-square' },
  { name: 'fab fa-playstation' },
  { name: 'fab fa-product-hunt' },
  { name: 'fab fa-pushed' },
  { name: 'fab fa-python' },
  { name: 'fab fa-qq' },
  { name: 'fab fa-quinscape' },
  { name: 'fab fa-quora' },
  { name: 'fab fa-ravelry' },
  { name: 'fab fa-react' },
  { name: 'fab fa-readme' },
  { name: 'fab fa-rebel' },
  { name: 'fab fa-red-river' },
  { name: 'fab fa-reddit' },
  { name: 'fab fa-reddit-alien' },
  { name: 'fab fa-reddit-square' },
  { name: 'fab fa-rendact' },
  { name: 'fab fa-renren' },
  { name: 'fab fa-replyd' },
  { name: 'fab fa-resolving' },
  { name: 'fab fa-rocketchat' },
  { name: 'fab fa-rockrms' },
  { name: 'fab fa-safari' },
  { name: 'fab fa-sass' },
  { name: 'fab fa-schlix' },
  { name: 'fab fa-scribd' },
  { name: 'fab fa-searchengin' },
  { name: 'fab fa-sellcast' },
  { name: 'fab fa-sellsy' },
  { name: 'fab fa-servicestack' },
  { name: 'fab fa-shirtsinbulk' },
  { name: 'fab fa-simplybuilt' },
  { name: 'fab fa-sistrix' },
  { name: 'fab fa-skyatlas' },
  { name: 'fab fa-skype' },
  { name: 'fab fa-slack' },
  { name: 'fab fa-slack-hash' },
  { name: 'fab fa-slideshare' },
  { name: 'fab fa-snapchat' },
  { name: 'fab fa-snapchat-ghost' },
  { name: 'fab fa-snapchat-square' },
  { name: 'fab fa-soundcloud' },
  { name: 'fab fa-speakap' },
  { name: 'fab fa-spotify' },
  { name: 'fab fa-stack-exchange' },
  { name: 'fab fa-stack-overflow' },
  { name: 'fab fa-staylinked' },
  { name: 'fab fa-steam' },
  { name: 'fab fa-steam-square' },
  { name: 'fab fa-steam-symbol' },
  { name: 'fab fa-sticker-mule' },
  { name: 'fab fa-strava' },
  { name: 'fab fa-stripe' },
  { name: 'fab fa-stripe-s' },
  { name: 'fab fa-studiovinari' },
  { name: 'fab fa-stumbleupon' },
  { name: 'fab fa-stumbleupon-circle' },
  { name: 'fab fa-superpowers' },
  { name: 'fab fa-supple' },
  { name: 'fab fa-telegram' },
  { name: 'fab fa-telegram-plane' },
  { name: 'fab fa-tencent-weibo' },
  { name: 'fab fa-themeisle' },
  { name: 'fab fa-trello' },
  { name: 'fab fa-tripadvisor' },
  { name: 'fab fa-tumblr' },
  { name: 'fab fa-tumblr-square' },
  { name: 'fab fa-twitch' },
  { name: 'fab fa-twitter' },
  { name: 'fab fa-twitter-square' },
  { name: 'fab fa-typo3' },
  { name: 'fab fa-uber' },
  { name: 'fab fa-uikit' },
  { name: 'fab fa-uniregistry' },
  { name: 'fab fa-untappd' },
  { name: 'fab fa-usb' },
  { name: 'fab fa-ussunnah' },
  { name: 'fab fa-vaadin' },
  { name: 'fab fa-viacoin' },
  { name: 'fab fa-viadeo' },
  { name: 'fab fa-viadeo-square' },
  { name: 'fab fa-viber' },
  { name: 'fab fa-vimeo' },
  { name: 'fab fa-vimeo-square' },
  { name: 'fab fa-vimeo-v' },
  { name: 'fab fa-vine' },
  { name: 'fab fa-vk' },
  { name: 'fab fa-vnv' },
  { name: 'fab fa-vuejs' },
  { name: 'fab fa-weibo' },
  { name: 'fab fa-weixin' },
  { name: 'fab fa-whatsapp' },
  { name: 'fab fa-whatsapp-square' },
  { name: 'fab fa-whmcs' },
  { name: 'fab fa-wikipedia-w' },
  { name: 'fab fa-windows' },
  { name: 'fab fa-wordpress' },
  { name: 'fab fa-wordpress-simple' },
  { name: 'fab fa-wpbeginner' },
  { name: 'fab fa-wpexplorer' },
  { name: 'fab fa-wpforms' },
  { name: 'fab fa-xbox' },
  { name: 'fab fa-xing' },
  { name: 'fab fa-xing-square' },
  { name: 'fab fa-y-combinator' },
  { name: 'fab fa-yahoo' },
  { name: 'fab fa-yandex' },
  { name: 'fab fa-yandex-international' },
  { name: 'fab fa-yelp' },
  { name: 'fab fa-yoast' },
  { name: 'fab fa-youtube' },
  { name: 'fab fa-youtube-square' },
];
