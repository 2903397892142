import { filter, map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';
import { MenusService } from '../../api/menus/menus.service';
import {
  GET_MENUS,
  GetMenusSuccessAction,
  MenuFailedAction,
  CREATE_MENU,
  CreateMenuSuccessAction,
  DELETE_MENU,
  DeleteMenuSuccessAction,
  MENU_ACTION_FAILED,
  GET_ACTIVE_MENU,
  GetActiveMenuSuccessAction,
  UPDATE_ACTIVE_MENU,
  UpdateActiveMenuSuccessAction,
  DeleteMenuAction,
} from './menus.actions';
import { Menu } from './menus.model';
import { SetSectionTitleAction } from '../ui-section-title/ui-section-title.actions';

@Injectable()
export class MenusEffects {

  loadMenus$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_MENUS),
    mergeMap((action) => {
      return this.menusService.getMenus().pipe(
        map((menus) =>
          menus.map((menu) => ({ ...menu, menuItems: getNormalizedMenuItems(menu.menuItems) }))
        ),
        map((menus) => new GetMenusSuccessAction(menus)),
        catchError((e) => of(new MenuFailedAction(e)))
      );
    })
  ));


  createMenu$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_MENU)).pipe(
    mergeMap((action: UnsafeAction) => {
      const menu = {
        ...action.payload,
        menuItems: getDenormalizedMenuItems(action.payload.menuItems),
      };
      return this.menusService.createMenu(menu).pipe(
        tap((data) => {
          this.snackBar.open($localize`Menu created successfully.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/widgets/menus/']);
          setTimeout(() => this.store.dispatch(new SetSectionTitleAction(data.name)), 0);
          return data;
        }),
        map((data: Menu) => ({ ...data, menuItems: getNormalizedMenuItems(data.menuItems) })),
        map((data: any) => new CreateMenuSuccessAction(data)),
        catchError((e) => of(new MenuFailedAction(e)))
      );
    })
  ));


  deleteMenu$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_MENU),
    mergeMap((action: DeleteMenuAction) => {
      const id = action.payload;
      return this.menusService.deleteMenu(id).pipe(
        map(() => new DeleteMenuSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Menu deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Failed to delete menu!`, $localize`Close`);
          return of(new MenuFailedAction(e));
        })
      );
    })
  ));


  getActiveMenu$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACTIVE_MENU),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload.id;
      return this.menusService.getMenu(id).pipe(
        filter((menu) => {
          if (menu) {
            return true;
          }
          this.router.navigate(['/widgets/menus/']);
          setTimeout(() => this.snackBar.open($localize`Menu not found`, $localize`Close`, { duration: 4000 }), 100);
          return false;
        }),
        map((menu) => {
          return ({ ...menu, menuItems: getNormalizedMenuItems(menu.menuItems) })}),
        map((menu) => new GetActiveMenuSuccessAction(menu)),
        catchError((e) => {
          setTimeout(() => this.snackBar.open($localize`Menu not found`, $localize`Close`, { duration: 4000 }), 100);
          this.router.navigate(['/widgets/menus']);
          return of(new MenuFailedAction(e))})
      );
    })
  ));


  updateActiveMenu$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_ACTIVE_MENU),
    mergeMap((action: UnsafeAction) => {
      const menu = {
        ...action.payload,
        menuItems: getDenormalizedMenuItems(action.payload.menuItems),
      };
      return this.menusService.updateMenu(menu).pipe(
        map((data: Menu) => ({ ...data, menuItems: getNormalizedMenuItems(data.menuItems) })),
        map((data: any) => new UpdateActiveMenuSuccessAction(data)),
        tap(() => {
          this.snackBar.open($localize`Menu successfully updated.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/widgets/menus/']);
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update Menu!`, $localize`Close`);
          return of(new MenuFailedAction(e));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MENU_ACTION_FAILED),
    tap((err: any) => {
      if (err.payload.message === 'Menu cannot be deleted! Cannot delete a menu that contains menu items!') {
        this.snackBar.open($localize`Menu delete failed! Menu contains Menu items.`, $localize`Close`, { duration: 4000 });
        return;
      }
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private menusService: MenusService
  ) {}
}

function getNormalizedMenuItems(items) {
  if (!items || items.length === 0) {
    return {};
  }
  const normalizedItems = items.reduce((acc, item) => {
    item.children = [];
    item.parentMenuItemId = item.parentMenuItemId || null; // ensure root level items have parent id of null
    acc[item.menuItemId] = item;
    return acc;
  }, {});
  items
    .filter((item) => item.parentMenuItemId)
    .forEach((item) => {
      const parentMenuItem = normalizedItems[item.parentMenuItemId];
      if (!parentMenuItem) {
        return (item.parentMenuItemId = null);
      }
      parentMenuItem.children.push(item.menuItemId);
    });
  return normalizedItems;
}

function getDenormalizedMenuItems(items) {
  if (!items) {
    return [];
  }
  const menuItems: any = Object.values(items);

  // delete temporary ids
  menuItems.forEach((item) => {
    if (item.id === item.menuItemId) {
      delete item.id;
    }
  });

  return menuItems;
}
