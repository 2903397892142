<mat-card class="gd-files-upload" [ngClass]="config.viewAs" [dir]="dir$ | async">
  <div
    fxLayout="row"
    fxLayoutGap="18px"
    fxLayoutAlign="end center"
    class="gd-files-upload__header"
    [style.padding-bottom]="config.viewAs !== 'standalone' ? '0px' : '16px'"
  >
    <div
      class="gd-files-upload__header-actions"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxFlex="100%"
      fxLayoutGap="8px"
      style="display: flex; justify-content: flex-end;"
    >
      <div></div>
      <div style="display: flex; justify-content: flex-end;" fxLayout="row" fxLayoutGap="8px">
        <button
          *ngIf="hasEnqueuedFiles && queuedFiles.length !== 0 && config.viewAs !=='contained'"
          mat-button
          mat-raised-button
          [disabled]="queueInProcessing || uploadDisabled"
          (click)="openFileSelectorDialog()"
        >
          <mat-icon svgIcon="glideCustomIcon_image-search"></mat-icon>
          <span i18n>Browse</span>
        </button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          [disabled]="!hasEnqueuedFiles || queueInProcessing || uploadDisabled"
          (click)="startUpload()"
          *ngIf="config.viewAs !== 'contained'"
        >
          <mat-icon fontSet="fal" fontIcon="fa-cloud-upload"></mat-icon>
          <span class="gd-files-upload__btn-label" i18n>Upload</span>
        </button>
      </div>
    </div>
  </div>

  <!-- Dropzone block -->
  <gd-dropzone
    #dropzone
    [dialog]="'native'"
    [dzConfig]="dzConfig"
    (queuedFilesEvent)="onFilesUpdate($event)"
    [class.reduce-dropzone]="queuedFiles.length !== 0"
    [class.hide-dropzone]="queuedFiles.length !== 0 && dzConfig.embedModal"
    [class.small-screen]="queuedFiles.length === 0 && dzConfig.embedModal"
  >
  </gd-dropzone>

  <!-- Queued Files Grid Block for Standalone page -->
  <div  *ngIf="queuedFiles.length !== 0 && config.multiUpload" style="height: 100%">
    <gd-scroller>
      <gd-images-view-grid
        [collection]="queuedFiles"
        (triggerRemoveImageFromList)="removeFileFromQueue($event)"
        (triggerEditFile)="handleEditFileForm($event)"
        (setIsDropzoneEnabled)="isDropzoneEnabled = $event"
        [options]="gridOptions"
        [hasDeletePermission]="hasUploadPermission"
      >
      </gd-images-view-grid>
    </gd-scroller>
  </div>
  <div
    *ngIf="config.viewAs === 'standalone'"
    fxLayout="row"
    fxLayoutAlign="space-between"
    class="gd-files-upload__footer-actions"
  >
    <button mat-button mat-raised-button [disabled]="queueInProcessing" (click)="goBack()" i18n>Back</button>
  </div>
</mat-card>
