import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { Observable, Subscription, debounceTime, of } from 'rxjs';
import { BidiService } from '../../../core/i18n/bidi.service';

import { fontAwesome5Icons } from './font-awesome-5-icon-list';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'gd-icon-picker-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
  ],
  templateUrl: './icon-picker-modal.component.html',
  styleUrls: ['./icon-picker-modal.component.scss'],
})
export class IconPickerModalComponent implements OnInit, OnDestroy {
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  searchFormControl: UntypedFormControl = new UntypedFormControl('');

  selectedIndex: number = 0;
  selectedIcon = null;

  iconList$: Observable<any>;
  componentSub: Subscription = new Subscription();

  fontAwesomeIcons = fontAwesome5Icons.sort((a, b) =>
    a.name.split(' ')[1] < b.name.split(' ')[1] ? -1 : 1
  );
  showedIconsList;

  paginator = {
    currentPage: 0,
    total: 0,
    pageSize: 50,
    pageIndex: 0,
    pageSizeOptions: [50, 100, 150, 200],
  };

  constructor(
    private dialogRef: MatDialogRef<IconPickerModalComponent>,
    private bidiService: BidiService
  ) {}

  ngOnInit(): void {
    this.clearSelectedChoice();
    this.showedIconsList = this.fontAwesomeIcons.slice(0, this.paginator.pageSize);
    this.paginator.total = fontAwesome5Icons.length;
    let filteredIcons;
    this.componentSub.add(
      this.searchFormControl.valueChanges.pipe(debounceTime(200)).subscribe((searchValue) => {
        if (searchValue.length >= 3 || searchValue.length == 0) {
          filteredIcons = this.fontAwesomeIcons.filter((icon) => icon.name.includes(searchValue));
          this.paginator.total = filteredIcons.length;
          this.showedIconsList = filteredIcons.slice(0, this.paginator.pageSize);
          this.paginator.pageIndex = 0;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.componentSub.unsubscribe();
  }

  handleTabChanged($event: MatTabChangeEvent) {
    throw new Error('Method not implemented.');
  }

  selectIcon(icon) {
    if (icon.isSelected) {
      icon.isSelected = false;
      this.selectedIcon = null;
      return;
    }

    this.fontAwesomeIcons.forEach((icon: any) =>
      icon?.isSelected ? (icon.isSelected = false) : null
    );
    icon.isSelected = true;
    this.selectedIcon = icon;
  }

  confirmSelection() {
    const icon = this.selectedIcon;
    icon.type = 'font_awesome';
    delete icon.isSelected;
    return this.dialogRef.close({ icon });
  }

  clearSelectedChoice() {
    this.selectedIcon = null;
    this.fontAwesomeIcons.forEach((icon: any) =>
      icon?.isSelected ? (icon.isSelected = false) : null
    );
  }

  async pageChanged($event) {
    const pageIndex = $event.pageIndex;
    const pageSize = $event.pageSize;
    const pageStartItemNumber = pageIndex * pageSize;
    const pageEndItemNumber = pageIndex * pageSize + pageSize;

    this.paginator.pageIndex = $event.pageIndex;
    this.paginator.pageSize = $event.pageSize;
    this.showedIconsList = this.fontAwesomeIcons
      .filter((icon) => icon.name.includes(this.searchFormControl.value))
      .slice(pageStartItemNumber, pageEndItemNumber);
  }
}
