import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

export function getPaginatorIntl(): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = $localize`Items per page`;
  paginatorIntl.nextPageLabel = $localize`Next page`;
  paginatorIntl.previousPageLabel = $localize`Previous page`;
  paginatorIntl.firstPageLabel = $localize`First page`;
  paginatorIntl.lastPageLabel = $localize`Last page`;
  paginatorIntl.getRangeLabel = getRangeLabel;
  return paginatorIntl;
}

function getRangeLabel(page: number, pageSize: number, length: number): string {
  if (length == 0 || pageSize == 0) {
    return $localize`0 of ${length}`;
  }

  length = Math.max(length, 0);
  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return $localize`${startIndex + 1} - ${endIndex} of ${length}`;
}
