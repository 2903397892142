import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GalleriesService } from '../../api/galleries/galleries.service';
import {
  GET_GALLERIES_WORKFLOWS,
  GetGalleriesWorkflowsSuccessAction,
  GetGalleriesWorkflowsErrorAction,
} from './galleries-workflow.actions';
import { GET_WORKFLOWS_ERROR } from '../workflows/workflow.actions';

@Injectable()
export class GalleriesWorkflowEffects {
  // This will be implemented additionally as an improvement (Ticket: GC-852)
  /* @Effect()
  accountChanged$: Observable<Action> = this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .withLatestFrom(this.store.select(getGalleriesWorkflowsState))
    .filter(([action, workflowsState]: [UnsafeAction, any]) => !!workflowsState.loaded)
    .map(([action, workflowsState]: [UnsafeAction, any]) => new GetGalleriesWorkflowsAction())
    .catch((err) => {
      console.log(err);
      return of();
    }); */


  loadWorkflows$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_GALLERIES_WORKFLOWS),
    mergeMap((action) => {
      return this.galleriesService.getWorkflows().pipe(
        map((workflows) => new GetGalleriesWorkflowsSuccessAction(workflows)),
        catchError((e) => of(new GetGalleriesWorkflowsErrorAction(e)))
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_WORKFLOWS_ERROR),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    public snackBar: MatSnackBar,
    private galleriesService: GalleriesService
  ) {}
}
