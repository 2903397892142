import { UnsafeAction } from '../unsafe-action.interface';

export const ADD_FILES_TO_QUEUE = '[Files Upload] Add files to queue';
export class AddFilesToQueueAction implements UnsafeAction {
  readonly type = ADD_FILES_TO_QUEUE;
  constructor(public payload: any) {}
}

export const ADD_FILES_TO_QUEUE_DONE = '[Files Upload] Add files to queue done';
export class AddFilesToQueueDoneAction implements UnsafeAction {
  readonly type = ADD_FILES_TO_QUEUE_DONE;
  constructor(public payload: any) {}
}

export const UPDATE_QUEUED_FILES = '[Files Upload] Update queued files';
export class UpdateQueuedFilesAction implements UnsafeAction {
  readonly type = UPDATE_QUEUED_FILES;
  constructor(public payload: any) {}
}

export const REMOVE_FILES_FROM_QUEUE = '[Files Upload] Remove files from queue';
export class RemoveFileFromQueueAction implements UnsafeAction {
  readonly type = REMOVE_FILES_FROM_QUEUE;
  constructor(public payload: any) {}
}

export const UPLOAD_FILE_STARTED = '[Files Upload] File upload started';
export class UploadFilesStartedAction implements UnsafeAction {
  readonly type = UPLOAD_FILE_STARTED;
  constructor(public payload: any) {}
}

export const FILE_UPLOADED = '[Files Upload] File uploaded';
export class FileUploadedAction implements UnsafeAction {
  readonly type = FILE_UPLOADED;
  constructor(public payload: any) {}
}

export const UPDATE_UPLOADED_FILE = '[Files Upload] Update uploaded file';
export class UpdateUploadedFilesAction implements UnsafeAction {
  readonly type = UPDATE_UPLOADED_FILE;
  constructor(public payload: any) {}
}

export const ALL_FILES_UPLOADED = '[Files Upload] All Files uploaded';
export class AllFilesUploadedAction implements UnsafeAction {
  readonly type = ALL_FILES_UPLOADED;
  constructor(public payload: any) {}
}

export const UPDATE_PROCESSED_FILE = '[Files Upload] Update processed file';
export class UpdateProcessedFileAction implements UnsafeAction {
  readonly type = UPDATE_PROCESSED_FILE;
  constructor(public payload: any) {}
}

export const FILES_PROCESSED = '[Files Upload] Files processed';
export class FilesProcessedAction implements UnsafeAction {
  readonly type = FILES_PROCESSED;
  constructor(public payload: any) {}
}

export const CLEAR_QUEUED_FILES = '[Files Upload] Clear queued files';
export class ClearQueuedFilesAction implements UnsafeAction {
  readonly type = CLEAR_QUEUED_FILES;
  constructor() {}
}

export const RESET_FILES_UPLOAD_STATE = '[Files Upload] Reset files upload state';
export class ResetFilesUploadStateAction implements UnsafeAction {
  readonly type = RESET_FILES_UPLOAD_STATE;
  constructor() {}
}

export const UPDATE_QUEUED_FILE = '[Files Upload] Update queued file data';
export class UpdateQueuedFileAction implements UnsafeAction {
  readonly type = UPDATE_QUEUED_FILE;
  constructor(public payload: any) {}
}
