import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements AfterViewInit, OnDestroy {
  @Input() sidenav: any;
  isFullscreen = false;

  @Input() sectionHasFloatingTabHeader = false;

  showBreadcrumbs = true;
  showFavorites = true;

  loadingBarActive = true;
  activeSection = '';
  componentSub: Subscription = new Subscription();
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private bidiService: BidiService,
  ) {
    const routerSub = this.router.events
      .pipe(filter(navigationEnd => navigationEnd instanceof NavigationEnd))
      .subscribe((navigationEnd: NavigationEnd) => {
        this.activeSection = navigationEnd.url.startsWith('/media/files') ? 'files' : '';
      });
    this.componentSub.add(routerSub);
  }

  ngAfterViewInit() {
    this.store.select(state => state).subscribe(appReducers => {
      setTimeout(() => {
        this.loadingBarActive = Object.values(appReducers)
          .some(reducer => reducer.loading);
      }, 200);
    });
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }

}
