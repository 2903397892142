import { AbstractControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { WidgetTypeFieldConfiguration } from '../../../core/store/widget-types/widget-types.model';

interface FormControlFactoryOptions {
  initialValue: any;
  editMode: boolean;
  contentLocaleId?: number;
}

// these defaults are based on what values won't disrupt existing users of
// formControlFactory which don't pass in the options param
const defaultFormControlFactoryOptions = {
  initialValue: '',
  editMode: true,
  contentLocaleId: null,
};

/**
 * This function creates an instance of FormControl or FormGroup based
 * on the custom field configuration provided, and using the provided
 * form builder for instantiation.
 *
 * Note on initial value: the options allows the initialValue to be passed in,
 * but also flag indicating if the control is used in edit or create context.
 * In create context, default value from field config will be loaded if available.
 *
 * TODO should be used in custom-form-constructor mainly, investigate other places
 * it is used, as it seems there is some code duplication that could be refactored away
 *
 * @param controlConfig GPP field configuration object
 * @param formBuilder Angular form builder, used for instantiating fields
 * @param options options object mostly containing settings relevant for initial value
 * @returns the created form control, form array or form group
 */
export function formControlFactory(
  controlConfig: WidgetTypeFieldConfiguration,
  formBuilder: UntypedFormBuilder,
  options: FormControlFactoryOptions = defaultFormControlFactoryOptions
): AbstractControl {
  const validators = [];
  controlConfig.validators.forEach(({ validator, parameter }) => {
    if (validator === 'required') {
      return validators.push(Validators.required);
    }
    if (validator === 'maxLength') {
      return validators.push(Validators.maxLength(parameter));
    }
    if (validator === 'minLength') {
      return validators.push(Validators.minLength(parameter));
    }
    if (validator === 'minValue') {
      return validators.push(Validators.min(parameter));
    }
    if (validator === 'maxValue') {
      return validators.push(Validators.max(parameter));
    }
    if (validator === 'email') {
      return validators.push(Validators.email);
    }
    if (validator === 'pattern') {
      return validators.push(Validators.pattern(parameter));
    }
  });

  // for custom field groups we'll return an empty FormGroup or FormArray, and it will be
  // populated once it reaches the field group builder component
  if (controlConfig.fieldType === 'group') {
    // note: comparing with 'single' intentionally avoided (fallback - use setup for SINGLE in case of any other stored value than MULTIPLE)
    return controlConfig.inputType !== 'multiple'
      ? formBuilder.group({}, validators)
      : formBuilder.array([], validators);
  }

  // for edit mode, use the initial value provided
  if(options.editMode) {
    return formBuilder.control(options.initialValue, validators);
  }
  // if the control is in the create mode, load the default value from config
  let resolvedValue = controlConfig.defaultValue || options.initialValue;

  // if there is a content locale id and localized default values, use localized value
  if (options.contentLocaleId && controlConfig.localizedDefaultValues && controlConfig.localizedDefaultValues.length) {
    const localizedValuesMap = controlConfig.localizedDefaultValues.reduce((acc,item) => {
      acc[item.contentLocaleId] = item.value
      return acc;
    }, {})
    resolvedValue = localizedValuesMap[options.contentLocaleId] || resolvedValue;
  }

  return formBuilder.control(resolvedValue, validators);
}
