<div class="gm-upload-file-meta-card" [class.__article-files]="isStandalone" [dir]="dir$ | async">
  <div class="gm-upload-file-meta-card__file-details">
    <h1 *ngIf="!isStandalone" i18n>File Details</h1>
      <div>
      <div class="gm-upload-file-met-card__file-size-type mat-body-2"><span i18n>Type</span>: .{{ extension }}</div>
      <div class="gm-upload-file-met-card__file-size-type mat-body-2">
        <span i18n>Size</span>: {{ returnFileSize(fileData?.fileData.size || file?.fileData.size) }}
      </div>
    </div>
    <form class="gm-upload-file-meta-card__file-details-form" [formGroup]="editFileForm">
      <mat-form-field fxFlex="100%" appearance="outline" style="padding-top: 21.5px">
        <mat-label i18n>Name</mat-label>
        <input matInput formControlName="name" autocomplete="off" (blur)="checkNameField()" required/>
        <mat-error *ngIf="editFileForm.controls.name.errors?.required" i18n>Name cannot be blank.</mat-error>
        <mat-error *ngIf="editFileForm.controls.name.errors?.pattern" i18n>Following characters are not allowed: < > , # & + : “ / \ | ? *</mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" appearance="outline">
        <mat-label i18n>Filename</mat-label>
        <input matInput formControlName="filename" autocomplete="off" required />
        <span matSuffix>.{{ extension }}</span>
        <mat-error *ngIf="editFileForm.controls.filename.errors?.required" i18n>Filename cannot be blank.</mat-error>
        <mat-error *ngIf="editFileForm.controls.filename.errors?.pattern" i18n>Following characters are not allowed: < > , # & + : “ / \ | ? *</mat-error>
      </mat-form-field>

      <mat-label class="gm-upload-file-meta-card__description-header" i18n>Description</mat-label>
      <section class="gm-upload-file-meta-card__editor-wrapper">
        <gd-rich-text-editor
          [froalaConfigOptions]="froalaConfigOptions"
          [hasActionPermission]="true"
          (editorChange)="handleEditorContentChange($event)"
          [editorData]="taxonomyEditorData"
        ></gd-rich-text-editor>
      </section>
    </form>
    <div *ngIf="!isStandalone" class="gm-upload-file-meta-card__buttons">
      <button mat-button mat-raised-button [mat-dialog-close] class="close-btn" i18n>
        Close
      </button>
      <button mat-button
              mat-raised-button
              color="primary"
              (click)="saveFileDetails()"
              [disabled]="editFileForm.invalid"
              i18n>
        Save
      </button>
    </div>
  </div>
</div>
