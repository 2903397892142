import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from '../rest.service';

@Injectable()
export class RedirectsService {
  constructor(private rest: RestService) {}

  getRedirects({ pageNumber = 0, pageSize = 20, original = '', destination = '' }) {
    let requestPath = `path-redirects?page=${pageNumber || 0}&size=${pageSize || 20}`;
    requestPath += original ? `&original=${encodeURIComponent(original)}` : '';
    requestPath += destination ? `&destination=${encodeURIComponent(destination)}` : '';
    return this.rest.get(requestPath);
  }

  getRedirect(id) {
    return this.rest.get(`path-redirects/${id}`).pipe(map((response) => response.data));
  }

  createRedirect(redirect) {
    return this.rest.post('path-redirects', redirect).pipe(map((response) => response.data));
  }

  updateRedirect(redirect) {
    return this.rest
      .put(`path-redirects/${redirect.id}`, redirect)
      .pipe(map((response: any) => response.data));
  }

  deleteRedirect(id) {
    return this.rest.delete(`path-redirects/${id}`);
  }
}
