import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { GET_GALLERIES_WORKFLOWS, GET_GALLERIES_WORKFLOWS_SUCCESS } from './galleries-workflow.actions';
import { AppState } from '../app-reducer';

export interface GalleriesWorkflowState {
  loaded: boolean;
  loading: boolean;
  error: string;
  workflows: Array<{}>;
  activeWorkflowId: number;
  statuses: Array<{}>;
}

const initialState: GalleriesWorkflowState = {
  loaded: false,
  loading: false,
  error: null,
  workflows: [],
  activeWorkflowId: null,
  statuses: []
};

export function galleriesWorkflowReducer(state: GalleriesWorkflowState = initialState, action: UnsafeAction) {

  switch (action.type) {


    case GET_GALLERIES_WORKFLOWS: {
      return { ...state, loading: true };
    }

    case GET_GALLERIES_WORKFLOWS_SUCCESS: {
      let statuses = [];
      if (action.payload && action.payload.length) {
        statuses = action.payload[0].statuses;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        workflows: action.payload,
        statuses
      };
    }

    default: {
      return state;
    }
  }

}

export const getGalleriesWorkflowsState = (state: AppState) => state.galleriesWorkflow;
export const getGalleriesWorkflowsArray = createSelector(getGalleriesWorkflowsState, (state) => state.workflows);
export const getGalleriesWorkflowStatuses = createSelector(getGalleriesWorkflowsState, (state) => state.statuses);
export const getPublishedGalleriesWorkflow = createSelector(
  getGalleriesWorkflowsArray,
  workflows => workflows.find((workflow: any) => workflow.published));

export const getActiveWorkflowForGalleries = createSelector(
  getPublishedGalleriesWorkflow,
  (activeWorkflow: any) => {
    if (!activeWorkflow) {
      return null;
    }

    // TODO correct this on the api side, gallery workflows should be the same as general workflows
    const adjustedStatuses = activeWorkflow.statuses
      .map(status => ({...status, backgroundColor: status.bg_color}));
    const adjustedStatesGraph = adjustedStatuses.reduce((acc, status) => {
      acc[status.id] = status;
      return acc;
    }, {});

    return {
      ...activeWorkflow,
      statuses: adjustedStatuses,
      statesGraph: adjustedStatesGraph,
      startingState: Object.values(activeWorkflow.statesGraph).find((s: any) => s.isInitial),
      publishedState: Object.values(activeWorkflow.statesGraph).find((s: any) => s.isPublished),
      publishableState: Object.values(activeWorkflow.statesGraph).find((s: any) => s.isPublishable)
    };
  }
);

