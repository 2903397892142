import { getTaxonomiesLoaded } from './../../../core/store/taxonomies/taxonomies.reducer';
import { GetTaxonomiesAction } from './../../../core/store/taxonomies/taxonomies.actions';
import { ArticlesService } from '../../../core/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError, Subscription, combineLatest } from 'rxjs';
import { map, catchError, distinctUntilChanged, startWith, debounceTime, take, retry, tap, mergeMap, filter } from 'rxjs/operators';
import { FormControl, AbstractControl } from '@angular/forms';
import { TaxonomiesService, processTaxonomies, isTaxonomyAllowed } from '../../../core/api/taxonomies/taxonomies.service';
import { MenusService } from '../../../core/api/menus/menus.service';
import { HtmlSnippetsService } from '../../../core/api/html-snippets/html-snippets.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CollectionsService } from '../../../core/api/collections/collections.service';
import { ContentTagsService } from '../../../core/api/content-tags/content-tags.service';
import { AuthorsService } from '../../../core/api/authors/authors.service';
import { ArticleTypesService } from '../../../core/api/article-types/article-types.service';
import { FieldGroupsService } from './../../../core/api/field-groups/field-groups.service';
import { CollectionTypeService } from '../../../core/api/collection-types/collection-types.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { getTaxonomies } from '../../../core/store/taxonomies/taxonomies.reducer';
import { AccountSettingsService } from '../../../core/api/account-settings/accounts-settings.service';
import * as Handlebars from 'handlebars/dist/handlebars';
import { isEmpty, get, uniq } from 'lodash-es';
import { ProxyService } from '../../../core/api/proxy/proxy.service';
import { LiveReportsService } from '../../../core/api/live-reports/live-reports.service';
import { isArray } from 'lodash-es';
import { getActiveWorkflow, getWorkflowsState } from '../../../core/store/workflows/workflow.reducer';
import { GetActiveWorkflowAction } from '../../../core/store/workflows/workflow.actions';
import { WorkflowStatus } from '../../../core/store/workflows/workflow.model';
import { getContentLocalesState, getFilteredActiveContentLocalesList, multipleLocalesExist } from '../../../core/store/content-locales/content-locales.reducer';
import { GetContentLocalesAction } from '../../../core/store/content-locales/content-locales.actions';
import { ContentLocalesService } from '../../../core/api/content-locales/content-locales.service';
import { ContentLocale } from '../../../core/store/content-locales/content-locales.model';


@Injectable()
export class DataSourceFactoryService {

  constructor(
    private articlesService: ArticlesService,
    private taxonomyService: TaxonomiesService,
    private menusService: MenusService,
    private htmlSnippetService: HtmlSnippetsService,
    private http: HttpClient,
    private collectionService: CollectionsService,
    private contentTagsService: ContentTagsService,
    private authorsService: AuthorsService,
    private articleTypesService: ArticleTypesService,
    private collectionTypesService: CollectionTypeService,
    private fieldGroupsService: FieldGroupsService,
    private store: Store<AppState>,
    private proxyService: ProxyService,
    private accountSettingsService: AccountSettingsService,
    private liveReportsService: LiveReportsService,
    private contentLocalesService: ContentLocalesService,
  ) {
    this.store.select(getContentLocalesState).pipe(
      take(1),
      filter(({ contentLocales, loading }) => !loading && !contentLocales?.length),
    ).subscribe(() => this.store.dispatch(new GetContentLocalesAction()));
  }

  create(config, itemId, formControl, isContentPublished = true, contentLocaleId = null) {
    if (config.dataSource.type === 'Custom Data') {
      return this.getCustomDataSource(config.dataSource.value);
    }

    if (config.dataSource.type === 'External Data') {
      return this.getExternalDataSource(config.dataSource.value, formControl);
    }

    if (config.dataSource.type !== 'CMS Data' || config.fieldType !== 'autocomplete') {
      return null;
    }

    const dataSourceConfig = {
      controlConfig: config,
      controlId: itemId,
      formControl,
      isContentPublished,
      contentLocaleId
    };

    return this.prepareDataSource(dataSourceConfig);
  }

  prepareDataSource(dataSourceConfig) {
    const dataSource = get(dataSourceConfig, 'controlConfig.dataSource.value', null);

    if (dataSource === 'customFieldGroups') {
      return this.getCustomFieldGroupsFactory(dataSourceConfig);
    }

    if (dataSource === 'articles') {
      return this.getArticleFactory(dataSourceConfig);
    }

    if (dataSource === 'taxonomies') {
      return this.getTaxonomiesFactory(dataSourceConfig);
    }

    if (dataSource === 'htmlSnippets') {
      return this.getHtmlSnippetFactory(dataSourceConfig);
    }
    if (dataSource === 'menus') {
      return this.getMenusFactory(dataSourceConfig);
    }
    if (dataSource === 'collections') {
      return this.getCollectionsFactory(dataSourceConfig);
    }
    if (dataSource === 'contentTags') {
      return this.getContentTagsFactory(dataSourceConfig);
    }
    if (dataSource === 'authors') {
      return this.getAuthorsFactory(dataSourceConfig);
    }
    if (dataSource === 'articleTypes') {
      return this.getArticleTypesFactory(dataSourceConfig);
    }
    if (dataSource === 'collectionTypes') {
      return this.getCollectionTypesFactory(dataSourceConfig);
    }
    if (dataSource === 'liveReports') {
      return this.getLiveReportsFactory(dataSourceConfig);
    }
    if (dataSource === 'contentLocales') {
      return this.getContentLocalesFactory(dataSourceConfig);
    }

  }

  getMenusFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.menusService.getMenu(filter)
            .subscribe((menu: any) => data.next([{ id: menu.id, name: menu.name }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }
        this.menusService.getFilteredMenus(filter)
          .pipe(map((response: any) => response.map(menu => ({ id: menu.id, name: menu.name }))))
          .subscribe(menus => data.next(menus));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const menuId = controlId;
          this.menusService.getMenu(controlId).pipe(
            map(menu => ([{ id: menu.id, name: menu.name, source: 'Menu', deleted: false }])),
            catchError(() => of([{ id: menuId, source: 'Menu', name: `[Menu - ${menuId}]`, deleted: true }]))
            )
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.menusService.getMenusByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getHtmlSnippetFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.htmlSnippetService.getHtmlSnippet(filter)
            .subscribe((snippet: any) => data.next([{ id: snippet.id, name: snippet.label }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }
        this.htmlSnippetService.getFilteredHtmlSnippets(filter)
          .pipe(map((response: any) => response.map(snippet => ({ id: snippet.id, name: snippet.label }))))
          .subscribe(snippets => data.next(snippets));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const htmlSnippetId = controlId;
          this.htmlSnippetService.getHtmlSnippet(controlId).pipe(
            map(snippet => ([{ id: snippet.id, name: snippet.label, source: 'HTML Snippet', deleted: false }])),
            catchError(() => of([{ id: htmlSnippetId, source: 'HTML Snippet', name: `[HTML Snippet - ${htmlSnippetId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.htmlSnippetService.getHtmlSnippetByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getTaxonomiesFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl, contentLocaleId } = dataSourceConfig;
    const showTaxonomyPath = this.accountSettingsService.getShowTaxonomyPathFlag();

    const getAllTaxonomies = () => {
      return combineLatest([
        this.store.select(getTaxonomies),
        this.store.select(getTaxonomiesLoaded)
      ])
        .pipe(
          map(([allTaxonomies, taxonomiesLoaded]: any[]) =>
            ([allTaxonomies, Object.keys(allTaxonomies).length, taxonomiesLoaded ])
          ),
          // get the taxonomies for reference if they haven't already been loaded
          tap(([, allTaxonomiesCount, taxonomiesLoaded]) => {
            if (allTaxonomiesCount === 0 && !taxonomiesLoaded) {
              // TODO do debounce to avoid api bashing
              this.store.dispatch(new GetTaxonomiesAction());
            }
          }),
          // only notify the subscriber if all taxonomies are fetched/exist in store
          filter(([, allTaxonomiesCount]) => allTaxonomiesCount > 0),
          take(1),
          map(([allTaxonomies]) => allTaxonomies));
    };

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      // Analyze what is going on here
      setFilter: (filterFieldValue = '', allowedTaxonomies = [], allowedTaxonomySubtrees = []) => {
        // case: get one taxonomy by ID (filter === id)
        if (typeof filterFieldValue === 'number') {
          getAllTaxonomies().pipe(map(taxonomies => {
            const selectedTaxonomy = taxonomies[filterFieldValue];
            if (!selectedTaxonomy) {
              return [];
            }
            return processTaxonomies([selectedTaxonomy], taxonomies, showTaxonomyPath, null, false, contentLocaleId)
              .map(tax => ({ ...tax, name: tax.localizedDisplayData[contentLocaleId]?.name || tax.name }));
          })).subscribe(taxonomies => data.next(taxonomies));
          return;
        }

        // case: we have the actual value or array of values which we'll use
        if (typeof filterFieldValue === 'object') {
          return data.next(filterFieldValue ? [filterFieldValue] : []);
        }

        // case: filter taxonomies by name
        getAllTaxonomies().pipe(map(taxonomies => {
          const [name, ids, limit] = [filterFieldValue, [], 50];
          return filterTaxonomies(taxonomies, name, ids, limit, showTaxonomyPath, allowedTaxonomies || [], allowedTaxonomySubtrees || [], true, contentLocaleId);
        })).subscribe(taxonomies => data.next(taxonomies));
      },

      getData: () => {
        // TODO take a look at the comment below
        // look into this control ID - should be local var? and check how get data works
        const taxonomyId = controlId;
        if (!taxonomyId) {
          data.next([]);
        }

        if (typeof taxonomyId !== 'number') {
          return;
        }

        getAllTaxonomies().pipe(map(taxonomies => {
          const deletedTaxObj = { id: taxonomyId, name: `[Taxonomy - ${taxonomyId}]` };
          const selectedTaxonomy = { ...(taxonomies[taxonomyId] || deletedTaxObj), source: 'Taxonomy', deleted: !taxonomies[taxonomyId] };
          if (selectedTaxonomy.deleted) {
            return [selectedTaxonomy];
          }
          return processTaxonomies([selectedTaxonomy], taxonomies, showTaxonomyPath, null, false, contentLocaleId)
            .map(tax => ({ ...tax, name: tax.localizedDisplayData[contentLocaleId]?.name || tax.name }));
        })).subscribe(taxonomies => data.next(taxonomies));
      },

      // getData for multiple type fields
      getMultiSelectCMSData: (ids) => {
        return getAllTaxonomies().pipe(map(taxonomies => filterTaxonomies(taxonomies, '', ids, 50, showTaxonomyPath, [], [], false, contentLocaleId)));
      },

      destroy: () => { data.complete(); }
    };
  }

  getArticleFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const dataLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const { controlId, formControl, isContentPublished, contentLocaleId } = dataSourceConfig;

    let currentRequestSub: Subscription = null;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filterData = '', allowedTypes = [], allowedContentLocales = [], options: any) => {
        if(currentRequestSub) {
          currentRequestSub.unsubscribe();
        }

        if (typeof filterData === 'number') {
          // note that we are not displaying the loader here on purpose, it causes it to blink after the user selects a suggestion
          this.articlesService.getReferencedArticleById(filterData)
            .subscribe((article: any) => {
              const activeRevision = article.publishedRevision || article.scheduledRevision || article.latestRevision || {};
              return data.next([{
                id: article.id,
                name: activeRevision.headline,
                catchline: activeRevision.catchline || '',
                headline: activeRevision.headline,
                type: activeRevision.typeId || null,
                scheduled: !!article.scheduledRevision,
                publishDateFrom: activeRevision.publishDateFrom,
                publishDateTo: activeRevision.publishDateTo,
                unpublishScheduled: (!!article.publishedRevisionId || !!article.publishedRevision) && Date.now() < activeRevision.publishDateTo,
                source: 'Article',
                contentLocaleId: article.contentLocaleId
              }]);
            });
          return;
        }

      if (typeof filterData === 'object') {
        dataLoading.next(false);
        data.next(filterData ? [filterData] : []);
        return;
      }

      // TODO revisit this, this is a workaround fo always fetching data on field focus. we want to do that only when field has no value
      if(!options?.suppressLoader) {
        dataLoading.next(true);
      }
      // TODO check if the must confirm that the content locales are loaded - most likely we don't
      // any place we would have locale scoped article search would have locales loaded
      currentRequestSub = this.store.select(multipleLocalesExist)
        .pipe(
          // resolve query parameters
          map(multipleLocalesExist => this.prepareArticleQueryParams({
            headlineOrCatchline: filterData,
            allowedTypes,
            allowedContentLocales,
            isContentPublished,
            contentLocaleId,
            multipleLocalesExist
          })),
          // get article data
          mergeMap(queryParams => {
            // we will cache the results for the initial data fetch for one minute
            // any search for a specific string will not go trough cache
            const useCache = typeof filterData === 'string' && filterData === '';
            return this.articlesService.getArticlesAdvancedFiltering(queryParams, {
              useCache,
              noPagination: true,
            });
          }),
          tap(() => dataLoading.next(false)),
          map(response => {
            return response.articles.map(article => {
              const { id , headline, catchline, typeId, publishedRevisionId, scheduledRevision, contentLocaleId } = article;
              return {
                id,
                name: headline,
                catchline,
                headline,
                type: typeId,
                publishedRevisionId,
                scheduled: !!scheduledRevision,
                publishDateFrom: !!scheduledRevision && scheduledRevision.publishDateFrom,
                publishDateTo: article.unpublishedOn,
                unpublishScheduled: publishedRevisionId && !!article.unpublishedOn,
                contentLocaleId,
                source: 'Article',
              };
            });
          })
        ).subscribe(articles => {
          currentRequestSub = null;
          data.next(articles)
        });
      },

    getData: () => {
     if (!controlId) {
      data.next([]);
     }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const articleId = controlId;
          this.articlesService.getReferencedArticleById(controlId).pipe(
            map((article: any) => {
              const activeRevision = article.publishedRevision || article.scheduledRevision || article.latestRevision || {};
              return [{
                id: article.id,
                name: activeRevision.headline,
                catchline: activeRevision.catchline || '',
                headline: activeRevision.headline,
                type: activeRevision.typeId || null,
                publishedRevisionId: !!article.publishedRevisionId || !!article.publishedRevision || null,
                scheduled: !!article.scheduledRevision,
                publishDateFrom: activeRevision.publishDateFrom,
                publishDateTo: activeRevision.publishDateTo,
                unpublishScheduled: (!!article.publishedRevisionId || !!article.publishedRevision) && Date.now() < activeRevision.publishDateTo,
                deleted: false,
                source: 'Article',
                contentLocaleId: article.contentLocaleId
              }];
            }),
            catchError(() => of([{ id: articleId, source: 'Article', name: `[Article - ${articleId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.articlesService.getReferencedArticlesByIds(ids);
      },
      dataLoading$: dataLoading.asObservable(),
      // used to reset the panel after closing
      clearData: () => {
        if(currentRequestSub) {
          currentRequestSub.unsubscribe();
          dataLoading.next(false);
        }
        data.next([]);
      },
      destroy: () => { data.complete(); }
    };
  }

  getCustomDataSource(sourceValue) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);

    return {
      data: new BehaviorSubject([]),
      init: () => { return data.asObservable(); },
      getData: () => {
        return data.next(this.createObjectData(sourceValue));
      },
      destroy: () => { data.complete(); }
    };
  }

  /**
   * This is the factory function for the External Data data source option.
   * It is specific in that URL parameter can be a template which can use values from
   * parent form context - i.e. sibling form control values can be used as template variables.
   * Also, it makes use of messages stream to provide hints and error messages to
   * custom field instance in which it is used.
   */
  getExternalDataSource(sourceValue, formControl: AbstractControl) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const messages: BehaviorSubject<any> = new BehaviorSubject(null);

    const httpHeaders = sourceValue.httpHeaders || [];

    // the function body which will transform the response data if enabled
    const functionData = get(sourceValue, 'functionData', {}) ;
    const doResponseDataTransform: boolean = functionData.slideToggle;
    const transformDataFn = doResponseDataTransform && new Function('data', 'contextValues', functionData.transformFunction);

    // this is the script which will be ran on every parent form value change and before
    // request is made and processed. The script will either modify or return new contextValues,
    // i.e. effectively allows modification of formControl.parent.value
    const preRequestScriptData = get(sourceValue, 'preRequestScript', {});
    const doPreRequestScript: boolean = preRequestScriptData.enabled;
    const preRequestScriptFn = doPreRequestScript && new Function('contextValues', preRequestScriptData.functionBody);

    // resolve the url template to be used for fetching the data
    const urlTemplateRaw = get(sourceValue, 'value', '');
    const urlTemplate =  safeCompileHandlebarsTemplate(urlTemplateRaw);

    // resolve a template to be used for fetching initial data via item id or ids
    const dynamicSearchEnabled = get(sourceValue, 'dynamicSearch.enabled', false);
    const dataFetchTemplateRaw = get(sourceValue, 'dynamicSearch.dataInitializationUrl', null);
    // TODO: divorce data fetch template from the dynamic filtering option
    // there are cases where static query might still require separate data fetch url,
    // like fixed relative time filtering, filtering by constant flags, pagination etc
    // and in all cases, things selected may not be there in the data fetch later on
    const dataFetchTemplate = safeCompileHandlebarsTemplate(dataFetchTemplateRaw);

    const useComposerAsProxy = get(sourceValue, 'builtInProxy.enabled', false);

    // extract context variables that factor in the URL handlebars template
    const watchedValues = extractValuesToWatch(sourceValue);
    const watchedValuesExist = !isEmpty(watchedValues);

    // used for filtering in dynamic search variant
    let latestContextValues = null;

    // interpolate URL to which request will be made if there are no variables in it,
    // otherwise leave the url as null and wait for contextValues stream to initialize
    let url = watchedValuesExist ? null : urlTemplate();

    // use a debounce timeout for data fetch to avoid API chatter
    let dataFetchTimeout = null;

    // define a function used to execute data fetch from external API URL
    const getDataWithTransform = (searchTerms = null, initializing = false) => {
      // when initializing the field, populate the __id and __ids to allow for data init invocation
      if(initializing) {
        const initialValue = formControl.value;
        searchTerms = { __id: [initialValue], __ids: initialValue };
      }

      // if a string search param is provided, interpolate it in the URL template
      // this is used for dynamic filtering
      // so it should be skipped on init
      if (!initializing && searchTerms && searchTerms.__search) {
        latestContextValues = latestContextValues || formControl.parent.value;
        url = urlTemplate({...latestContextValues, ...searchTerms});
      }

      // if we get id or ids in search terms, use the data fetch template
      // this is used to fetch the initial list of items in dynamic search scenario
      if (searchTerms && (searchTerms.__ids || searchTerms.__id)) {
        latestContextValues = latestContextValues || formControl.parent.value;
        url = dataFetchTemplate({...latestContextValues, ...searchTerms});
      }

      // no point in making a request if we don't have an url, or we have dynamic
      // search and no search query, either an ID/IDs for data fetch, or string for search
      if (!url || (dynamicSearchEnabled && !searchTerms)) {
        return null;
      }

      clearTimeout(dataFetchTimeout);
      dataFetchTimeout = setTimeout(() => {
        // use proxy fetch via Composer or directly form external api depending on field config
        let serverData = useComposerAsProxy
          ? this.createProxyRequest(url, httpHeaders)
          : this.getDataFromServer(url, httpHeaders);

        // attach data transform function to server data stream if the function is available
        if (transformDataFn) {
          serverData = serverData.pipe(
            map((extData) => transformDataFn(extData, latestContextValues))
          );
        }
        serverData.subscribe(
          res => {
            messages.next(null);
            const items = this.createObjectData(res);
            data.next(items);
            let selection = formControl.value;
            if (!selection) {
              return;
            }
            const clearField = !Array.isArray(selection) && !(items || []).find(item => item?.id === selection);
            if (clearField) {
              formControl.setValue(null);
            }
          },
          err => {
            const message = err && err.status
              ? 'Failed to fetch external data. HTTP Status: ' + err.status
              : (err.message || err.toString());
            messages.next({ type: 'error', message });
          }
        );
      }, 200);
    };

    // if the URL template uses sibling form control values, then subscribe to parent form context
    // value changes, and refetch data if relevant values have changed
    const contextSubscription: Subscription = new Subscription();
      contextSubscription.add(
        formControl.parent.valueChanges
         // filter the values so that we recalculate only if relevant values have changed
         .pipe(
           debounceTime(100),
           startWith(formControl.parent.value),
           // TODO figure out if we can have default value handling for EDS fields depending on context
           // currently we don't support proper context for default values on the custom field config form
           // we could do something like hold default values in a dedicated form, and switch to that context
           // when we detect we're on the custom field configuration form
           filter(contextValues => {
            const parentIsCustomFieldConfigForm = !!(contextValues.fieldType && contextValues.dataSource);
            return !parentIsCustomFieldConfigForm;
           }),
           // execute pre request script if available
           map(contextValues => {
             if (!preRequestScriptFn) {
               return contextValues;
             }

             // if the pre-request script is defined, execute it with the current context values
             // it is expected that the script will either return new context values, or modify the current ones
             try {
              const newContextValues = preRequestScriptFn(contextValues);
              return newContextValues || contextValues;
            } catch (error) {
              console.error('Failed to execute pre-request script for external data field!');
              console.error(error);
              // emit hint and stop stream propagation
              messages.next({ type: 'error', message: 'Error in pre-request script!' });
              return null;
            }
           }),
           filter(cv => !!cv),
           distinctUntilChanged((previousValues, currentValues) => {
             // check if any relevant variables changed in the context values
            for (const relevantVarKey of watchedValues) {
               if (previousValues[relevantVarKey] !== currentValues[relevantVarKey]) {
                 return false;
               }
             }
             return true;
           }),
           filter(contextValues => {
            latestContextValues = contextValues;
             // don't make the request if all relevant context values are not set, and emit a hint in that case
            const relevantVarsNotSet = this.getRequiredValuesNotSet(contextValues, watchedValues, dynamicSearchEnabled);
             if (!!relevantVarsNotSet) {
               messages.next({ type: 'hint', message: 'Prerequisite fields are not set: ' + relevantVarsNotSet });
               formControl.setValue(null);
             }
             return !relevantVarsNotSet;
           })
         )
         .subscribe(contextValues => {
           // if everything goes ok upstream, regenerate the URL and get the data
           url = urlTemplate(contextValues);
           getDataWithTransform();
         })
      );

    return {
      data,
      messages,
      setFilter: (filterFieldValue) => {
        if (typeof filterFieldValue === 'string') {
          getDataWithTransform({__search: filterFieldValue });
          return;
        }

        // TODO revisit this, use case may be obolete by adding initilizing flag to getData
        // case: get one item by ID (filter === id)
        if (typeof filterFieldValue === 'number') {
          getDataWithTransform({ __ids: [filterFieldValue], __id: filterFieldValue });
          // get single/multiple items by using alternate URL
          return;
        }

        // case: we have the actual value or array of values which we'll use
        if (typeof filterFieldValue === 'object') {
          data.next(filterFieldValue ? [filterFieldValue] : []);
        }
      },
      init: () => { return data.asObservable(); },
      getData: getDataWithTransform,
      getMultiSelectCMSData: (selectedIds) => {
        getDataWithTransform({__ids: selectedIds });
        return data.asObservable().pipe(take(2));
      },
      getMessages: () => messages.asObservable(),
      destroy: () => {
        data.complete();
        contextSubscription.unsubscribe();
      }
    };
  }

  /**
   * A simple utility function which takes an object and a list of object keys, and then
   * returns all listed required values which are not truthy in the `values` object.
   * Note: if dynamic search is enabled `__search` var will be an empty string if not set.
   * Returns `null` if all required values are set
   */
  private getRequiredValuesNotSet(values: { [key: string]: any }, listOfRequiredValues: string[], dynamicSearchEnabled) {
    if (!listOfRequiredValues || listOfRequiredValues.length === 0) {
      return null;
    }

    let variablesWithoutValues = [];
    for (const relVar of listOfRequiredValues) {
      if (!values[relVar]) {
        variablesWithoutValues.push(relVar);
      }
    }

    // if dynamic search is enabled, ignore missing `__search` param, it will be set to an empty string
    if (dynamicSearchEnabled) {
      variablesWithoutValues = variablesWithoutValues.filter(v => v !== '__search');
  }

    return variablesWithoutValues.length > 0 ? variablesWithoutValues : null;
  }

  /**
   * This method is used for external data source fields that have
   * built in proxy via composer enabled. Idea is: to avoid CORS
   * limitations, instead of Publisher making requests directly to
   * external APIs, proxy them via Composer instead.
   *
   * @param url the full url that will be proxy target
   * @param httpHeaders headers to be sent with proxy request
   * @returns an angular http request made via proxy service
   */
  createProxyRequest(url, httpHeaders) {
    // split the url and recombine it after encoding the query values
    const [urlFragment, query] = url.split('?');
    let encodedQuery = '';
    if (query) {
      encodedQuery = query
        .split('&')
        .map(queryFragments => queryFragments.split('='))
        .map(([key, value]) => key + '=' + encodeURIComponent(value))
        .join('&');
      encodedQuery = '?' + encodedQuery;
    }
    const preparedUrl = urlFragment + encodedQuery;

    const proxyRequest = {
      url: preparedUrl,
      headers: null,
      cache: false
    };

    // attach headers to the request if they are available
    if (httpHeaders && httpHeaders.length > 0) {
      proxyRequest.headers = httpHeaders.reduce((acc, header) => {
        acc.push(header.label);
        acc.push(header.value);
        return acc;
      }, []);
    }

    // do a single retry if the request fails on the first go
    return this.proxyService.get(proxyRequest).pipe(retry(1));
  }

  createObjectData(data): Array<{id: any, name: string}> {
    // safe exit if we get no data
    if (!data || (isArray(data) && isEmpty(data))) {
      return [];
    }

    // if we get a single object with id and name properties, wrap it up into array
    // this covers the single item data fetch scenario at field init time
    const dataList = isArray(data) ? data : [data];

    // if we have an array of objects, ensure they are in correct format, and return
    const testData = dataList[0];
    if (testData && typeof (testData) === 'object') {
      return dataList.map(item => this.convertSelectOptionObject(item));
    }

    // if we get a list of strings or numbers, convert those into id-name objects as well
    const transformedData = data.map(d => ({ id: d, name: d }));
    return transformedData;
  }

  convertSelectOptionObject(option) {
    const optionCopy: any = {...option};
    if(optionCopy.value && !optionCopy.id) {
      optionCopy.id = optionCopy.value;
      delete optionCopy.value;
    }
    return optionCopy;
  }

  getDataFromServer(url: string, httpHeaders = []): Observable<any> {
    const requestOptions: any = {};
    if (httpHeaders && httpHeaders.length > 0) {
      const headersRaw = httpHeaders.reduce((acc, {label, value}) => {
        acc[label] = value;
        return acc;
      }, {});
      requestOptions.headers = new HttpHeaders(headersRaw);
    }

    return this.http.get<any>(url, requestOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleAngularJsonBug(error);
        })
      );
  }

  private handleAngularJsonBug(error: HttpErrorResponse) {
    const JsonParseError = 'Http failure during parsing for';
    const matches = error.message.match(new RegExp(JsonParseError, 'ig'));
    if (error.status === 200 && matches.length === 1) {
      console.log('error', error);
      return of();
    }
    if (error.status === 403 || error.status === 401) {
      console.log('Error40x', error);
      return of();
    }
    return throwError(error);
  }

  getCustomFieldGroupsFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.fieldGroupsService.getFieldGroup(filter)
          .subscribe((contentTag: any) => data.next([{ id: contentTag.id, name: contentTag.name }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        this.fieldGroupsService.getFieldGroups({ name: filter || '', pageSize: 20 })
          .pipe(map(response =>
            response.fieldGroups.map(fieldGroups => ({ id: fieldGroups.id, name: fieldGroups.name })))
          )
          .subscribe(fieldGroups => data.next(fieldGroups));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const customFieldGroupId = controlId;
          this.fieldGroupsService.getFieldGroup(controlId).pipe(
            map((contentTag: any) => ([{ id: contentTag.id, name: contentTag.name, source: 'Custom Field Group', deleted: false }])),
            catchError(() => of([{ id: customFieldGroupId, source: 'Custom Field Group', name: `[Custom Field Group - ${customFieldGroupId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.fieldGroupsService.getReferencedCustomFieldGroupsByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getCollectionsFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl, isContentPublished } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '', typeIds = []) => {
        if (typeof filter === 'number') {
          this.collectionService.getReferencedCollectionById(filter)
            .subscribe((collection: any) => {
              const activeRevision = collection.publishedRevision || collection.scheduledRevision || collection.latestRevision || {};

              return data.next([{
              id: collection.id,
              name: collection.publishedRevision?.name || collection.latestRevision.name,
              type: collection.publishedRevision?.typeId || collection.latestRevision.typeId || null,
              catchline: collection.catchline,
              source: 'Collection',
              scheduled: !!collection.scheduledRevision,
              publishDateFrom: activeRevision.publishDateFrom,
              publishDateTo: activeRevision.publishDateTo,
              unpublishScheduled: (!!collection.publishedRevisionId || !!collection.publishedRevision) && Date.now() < collection.publishDateTo,
            }])});
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }
        this.collectionService.getCollections({ name : filter || '', published: isContentPublished, typeIds, pageSize: 20 })
          .pipe(map(response => response.data.map(collection => {
            const activeRevision = collection.publishedRevision || collection.scheduledRevision || collection.latestRevision || {};

            return {
            id: collection.id,
            name: collection.name,
            type: collection.collectionType.id || null,
            publishedRevisionId: !!collection.publishedRevisionId,
            catchline: collection.catchline,
            source: 'Collection',
            scheduled: !!collection.scheduledRevision,
            publishDateFrom: activeRevision.publishDateFrom,
            publishDateTo: activeRevision.publishDateTo,
            unpublishScheduled: (!!collection.publishedRevisionId || !!collection.publishedRevision) && Date.now() < activeRevision.publishDateTo,
            };
          })))
          .subscribe(collections => data.next(collections));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const collectionId = controlId;
          this.collectionService.getReferencedCollectionById(controlId).pipe(
            map((collection: any) => {
              const activeRevision = collection.publishedRevision || collection.scheduledRevision || collection.latestRevision || {};

              return ([{
              id: collection.id,
              name: collection.publishedRevision?.name || collection.latestRevision.name,
              type: collection.publishedRevision?.typeId || collection.latestRevision.typeId || null,
              publishedRevisionId: !!collection.publishedRevisionId || !!collection.publishedRevision || null,
              catchline: collection.catchline,
              deleted: false,
              source: 'Collection',
              scheduled: !!collection.scheduledRevision,
              publishDateFrom: activeRevision.publishDateFrom,
              publishDateTo: activeRevision.publishDateTo,
              unpublishScheduled: (!!collection.publishedRevisionId || !!collection.publishedRevision) && Date.now() < activeRevision.publishDateTo,
            }])}),
            catchError(() => of([{ id: collectionId, source: 'Collection', name: `[Collection - ${collectionId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.collectionService.getReferencedCollectionsByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getContentTagsFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.contentTagsService.getContentTag(filter)
            .subscribe((contentTag: any) => data.next([{ id: contentTag.id, name: contentTag.name }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        this.contentTagsService.getContentTags({ name: filter || '' })
          .pipe(map(response => response.map(contentTag => ({ id: contentTag.id, name: contentTag.name }))))
          .subscribe(contentTags => data.next(contentTags));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const contentTagId = controlId;
          this.contentTagsService.getContentTag(controlId).pipe(
            map((contentTag: any) => ([{ id: contentTag.id, name: contentTag.name, source: 'Content Tag', deleted: false }])),
            catchError(() => of([{ id: contentTagId, source: 'Content Tag', name: `[Content Tag - ${contentTagId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.contentTagsService.getContentTagsById(ids);
      },

      destroy: () => { data.complete(); }
    };
  }
  getAuthorsFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.authorsService.getAuthor(filter)
            .subscribe((author: any) => data.next([{ id: author.id, name: author.name }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        this.authorsService.getAuthors({ name: filter || '', pageSize: 20 })
          .pipe(map(response => response.data.map(author => ({ id: author.id, name: author.name }))))
          .subscribe(authors => data.next(authors));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const authorId = controlId;
          this.authorsService.getAuthor(controlId).pipe(
            map((author: any) => ([{ id: author.id, name: author.name, source: 'Author', deleted: false }])),
            catchError(() => of([{ id: authorId, source: 'Author', name: `[Author - ${authorId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.authorsService.getAuthorsByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getArticleTypesFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.articleTypesService.getOne(filter)
            .subscribe((articleType: any) => data.next([{ id: articleType.id, name: articleType.name }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        this.articleTypesService.getAll({ name: filter || '', pageSize: 20 })
          .pipe(map(response => response.map(articleType => ({ id: articleType.id, name: articleType.name }))))
          .subscribe(articleTypes => data.next(articleTypes));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const typeId = controlId;
          this.articleTypesService.getOne(controlId).pipe(
            map((articleType: any) => ([{ id: articleType.id, name: articleType.name, source: 'Article Type', deleted: false }])),
            catchError(() => of([{ id: typeId, source: 'Article Type', name: `[Article Type - ${typeId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.articleTypesService.getArticleTypesByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getCollectionTypesFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.collectionTypesService.getOne(filter)
            .subscribe((collectionType: any) => data.next([{ id: collectionType.id, name: collectionType.name }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        this.collectionTypesService.getAll({ name: filter || '', pageSize: 20 })
          .pipe(map(response => response.map(collectionType => ({ id: collectionType.id, name: collectionType.name }))))
          .subscribe(collectionTypes => data.next(collectionTypes));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const typeId = controlId;
          this.collectionTypesService.getOne(controlId).pipe(
            map((collectionType: any) => ([{ id: collectionType.id, name: collectionType.name, source: 'Collection Type', deleted: false }])),
            catchError(() => of([{ id: typeId, source: 'Collection Type', name: `[Collection Type - ${typeId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.collectionTypesService.getCollectionTypesByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  getLiveReportsFactory(dataSourceConfig) {
    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.liveReportsService.getLiveReport(filter)
            .subscribe((liveReport: any) => data.next([{
              id: liveReport.id,
              name: liveReport.publishedRevision?.headline || liveReport.workingRevision.headline,
              catchline: liveReport.publishedRevision?.catchline || liveReport.workingRevision.catchline,
              source: 'Live Report',
            }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        const filterSrc = {
          include: {
            headlineOrCatchline: {
              like: filter
            }
          }
        };

        this.liveReportsService.getLiveReportsList({ filter: filterSrc, size: 20 })
          .pipe(map((response: any) => response.liveReports.map(liveReport =>
            ({ id: liveReport.id, name: liveReport.publishedRevision?.headline || liveReport.workingRevision.headline, catchline: liveReport.publishedRevision?.catchline || liveReport.workingRevision.catchline, publishedRevisionId: liveReport.publishedRevision?.id, source: 'Live Report' }))))
          .subscribe(liveReports => data.next(liveReports));
      },

      getData: () => {
        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const liveReportId = controlId;
          this.liveReportsService.getLiveReport(liveReportId).pipe(
            map((liveReport: any) => ([{
              id: liveReport.id,
              name: liveReport.publishedRevision?.headline || liveReport.workingRevision.headline,
              catchline: liveReport.publishedRevision?.catchline || liveReport.workingRevision.catchline,
              publishedRevisionId: liveReport.publishedRevision?.id || null,
              deleted: false,
              source: 'Live Report'
            }])),
            catchError(() => of([{ id: liveReportId, source: 'Live Report', name: `[Live Report - ${liveReportId}]`, deleted: true}])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.liveReportsService.getReferencedLiveReportsByIds(ids);
      },

      destroy: () => { data.complete(); }
    };
  }

  prepareArticleQueryParams({
    headlineOrCatchline,
    allowedTypes,
    allowedContentLocales,
    isContentPublished,
    contentLocaleId,
    multipleLocalesExist
  }) {
    const queryParams: any  = {
      filter: {
        include: {
          headlineOrCatchline: {like: headlineOrCatchline || ''},
        }
      },
      limit: 20
    };

    if (allowedTypes && allowedTypes.length > 0) {
      queryParams.filter.include.articleType = allowedTypes;
    }

    if(isContentPublished) {
      queryParams.filter.include.publicationState = 'ScheduledOrPublished';
    }

    // if we don't have multiple locales, exit here
    if(!multipleLocalesExist) {
      return queryParams;
    }

    // figure out which locales should be included
    const allowedContentLocalesExist = !isEmpty(allowedContentLocales) && isArray(allowedContentLocales);
    const allowedContentLocaleIds = allowedContentLocalesExist ? allowedContentLocales : [];
    if (contentLocaleId || allowedContentLocalesExist) {
      queryParams.filter.include.contentLocaleIds = uniq([
        ...allowedContentLocaleIds,
        contentLocaleId || null,
      ]).filter((cId) => !!cId);
    }

    return queryParams;
  }

  getContentLocalesFactory(dataSourceConfig) {

    const data: BehaviorSubject<any> = new BehaviorSubject([]);
    const { controlId, formControl } = dataSourceConfig;

    return {
      data: new BehaviorSubject([]),

      init: () => { return data.asObservable(); },

      setFilter: (filter = '') => {
        if (typeof filter === 'number') {
          this.contentLocalesService.getContentLocaleById(filter)
            .subscribe((locale: ContentLocale) => data.next([{ id: locale.id, name: locale.label, iconUrl: locale.iconUrl }]));
          return;
        }

        if (typeof filter === 'object') {
          return data.next(filter ? [filter] : []);
        }

        this.store.select(getFilteredActiveContentLocalesList(filter))
          .pipe(map((response: any) => response.map(locale => ({ id: locale.id, name: locale.label, iconUrl: locale.iconUrl }))))
          .subscribe(locales => data.next(locales));
      },

      getData: () => {

        if (!controlId) {
          data.next([]);
        }

        const typeOfControl = typeof controlId;
        if (controlId && typeOfControl === 'number') {
          const contentLocaleId = controlId;
          this.contentLocalesService.getContentLocaleById(controlId).pipe(
            map(locale => ([{ id: locale.id, name: locale.label, source: 'Content Locale', deleted: false }])),
            catchError(() => of([{ id: contentLocaleId, source: 'Content Locale', name: `[Content Locale - ${contentLocaleId}]`, deleted: true }])))
            .subscribe(el => {
              if (el.length < 1) { formControl.setValue(''); }
              data.next(el);
            });
        }
      },

      getMultiSelectCMSData: (ids) => {
        return this.contentLocalesService.getAllContentLocales().pipe(map(data => data.filter(locale => ids.includes(locale.id))));
      },

      destroy: () => { data.complete(); }
    };
  }

  }


// TODO extract the functions under here into a separate file

/**
 * This function does safe compile of Hbs template string, returning
 * a fallback value which is just a function returning the raw template
 *
 * @param rawTemplate raw Handlebars string template
 * @returns compiled Handlebars template function
 */
function safeCompileHandlebarsTemplate(rawTemplate) {
  let urlTemplate = null;
  try {
    urlTemplate = Handlebars.compile(rawTemplate);
  } catch (error) {
    console.warn('Failed to compile the external data URL template. Falling back to raw value.');
    urlTemplate = () => rawTemplate;
}
  return urlTemplate;
}

/**
 * This function extracts a list of context values changes on which will trigger
 * data refetch and processing. Since not all form value changes should affect
 * changes to specific external data fields.
 *
 * @param sourceValue data source object, as a part of custom field configuration
 * @returns list of values to be watched
 */
function extractValuesToWatch(sourceValue) {

  const urlTemplateRaw = get(sourceValue, 'value', '');
  const urlTemplateVariables = extractVariablesFromHbsTemplate(urlTemplateRaw);

  const transformFunction =  get(sourceValue, 'functionData.transformFunction', '');
  const transformFunctionContextVariables = extractContextValuesUsedInScript(transformFunction);

  return uniq([
    ...urlTemplateVariables,
    ...transformFunctionContextVariables
   ]);
}

/**
 * This function extracts list of variables to be interpolated into Hbs string
 *
 * @param rawTemplate raw Handlebars string template
 * @returns list of variables which will be interpolated
 */
function extractVariablesFromHbsTemplate(rawTemplate) {
  const templateVariables = [];
  const templateVarRegexp = /\{\{\{?([^\}]+)\}?\}\}/g;
  let match = templateVarRegexp.exec(rawTemplate);
  while (match != null) {
    templateVariables.push(match[1].trim());
    match = templateVarRegexp.exec(rawTemplate);
  }
  return templateVariables;
}

/**
 * This function extracts each occupance of `contextValues.[variable]` where
 * variable is a name conforming to regex /[a-zA-Z0-9-_]+/, for example `contextValues.myValue`.
 * Each occurrence is a value from context to watch, since values used in data transform function
 * may on change need to trigger data refetch
 *
 * @param scriptAsAString the js script in string format
 * @returns list of values which will be watched in the contextValues
 */
function extractContextValuesUsedInScript(scriptAsAString) {
  const scriptContextValues = [];
  const contextValueRegexp = /contextValues\.(?<variableToWatch>[a-zA-Z0-9-_]+)/g;
  let match = contextValueRegexp.exec(scriptAsAString);
  while (match != null) {
    scriptContextValues.push(match[1].trim());
    match = contextValueRegexp.exec(scriptAsAString);
  }
  return scriptContextValues;
}

function filterTaxonomies(taxonomies, name, ids, limit, showTaxonomyPath, allowedTaxonomies = [], allowedTaxonomySubtrees = [], showOnlyPermitted = true, contentLocaleId = null) {
  let counter = 0;
  name = (name || '').toLowerCase().trim();

  const filteredTaxonomies = Object.values(taxonomies).filter((tax: any) => {
    if (counter === limit) {
      return;
    }
    const taxName = (showOnlyPermitted && contentLocaleId)	? (tax.localizedDisplayData[contentLocaleId]?.name || '') : tax.name;
    const allowed = isTaxonomyAllowed(tax, taxonomies, allowedTaxonomies, allowedTaxonomySubtrees);
    const hasPermission = !showOnlyPermitted || tax.userHasPermission;
    let includedInResults = allowed && taxName && taxName.toLowerCase().includes(name) && hasPermission;
    if (ids?.length) {
      includedInResults = includedInResults && ids.includes(tax.id);
    }

    counter = includedInResults ? ++counter : counter;
    return includedInResults;
  });

  return processTaxonomies(filteredTaxonomies, taxonomies, showTaxonomyPath, null, showOnlyPermitted, contentLocaleId)
    .map(tax => ({ ...tax, name: tax.localizedDisplayData[contentLocaleId]?.name || tax.name }));
}
