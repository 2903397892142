import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export interface BroadcastMessage {
  type: string;
  payload: any;
}

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  broadcastChannel: any;
  onMessage = new Subject<any>();
  message$: Observable<any>;
  key = 'SSO_REFRESH_SESSION';
  usingStorage = false;

  constructor() {
    if ('BroadcastChannel' in self) {
      // this.initializeChannel();
      this.initializeStorage();
      this.usingStorage = true;
    } else {
      this.initializeStorage();
      this.usingStorage = true;
    }
  }

  // remove storage option once all major browser support BroadcastChannel
  initializeStorage() {
    this.message$ = fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event) => event.storageArea === localStorage),
      filter((event) => event.key === this.key),
      map((event) => event.newValue)
    );
    this.broadcastChannel = {};
    this.broadcastChannel.postMessage = (message) => {
      window['localStorage'].setItem(this.key, JSON.stringify(message));
    };
  }

  initializeChannel() {
    this.broadcastChannel = new BroadcastChannel(this.key);
    this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
  }

  publish(message: BroadcastMessage): void {
    this.broadcastChannel.postMessage(message);
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    if (this.usingStorage) {
      return this.message$.pipe(map((val) => JSON.parse(val)));
    }
    return this.onMessage.pipe(filter((message) => message.type === type));
  }
}
