import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AccessBundlesService {
  constructor(private rest: RestService) { }

  getAccessBundlesList({ pageNumber = 0, pageSize = 100, published = null }: any = {}) {
    let requestQuery = `page=${pageNumber}&size=${pageSize}`;
    requestQuery += published ? `&published=${published}` : '';
    let requestPath = `verify/access-bundles?${requestQuery}`;
    return this.rest.get(requestPath).pipe(map((response) => {
      return response.data}));
  }

  getAccessBundle(id) {
    return this.rest.get(`verify/access-bundles/${id}`).pipe(map(response => response.data));
  }

  createAccessBundle(payload) {
    return this.rest.post('verify/access-bundles', payload).pipe(map(response => response.data));
  }

  updateAccessBundle(payload) {
    const id = payload.id;
    delete payload.id;
    return this.rest.put(`verify/access-bundles/${id}`, payload).pipe(map(response => response.data));
  }

  deleteAccessBundle(id) {
    return this.rest.delete(`verify/access-bundles/${id}`);
  }

  getAccessBundleRevision(revisionId) {
    return this.rest.get(`verify/access-bundles/versions/${revisionId}`).pipe(map(response => response.data));
  }

  publishAccessBundle({ id, revisionId }) {
    return this.rest.post(`verify/access-bundles/${id}/publish/${revisionId}`).pipe(map(response => response.data));
  }

  unpublishAccessBundle(id) {
    return this.rest.post(`verify/access-bundles/${id}/unpublish`).pipe(map(response => response.data));
  }
}
