import { createSelector } from 'reselect';
import { UnsafeAction } from '../unsafe-action.interface';
import { GET_VERIFY_SETTINGS, GET_VERIFY_SETTINGS_COMPLETE } from './verify-settings.actions';

export interface VerifySettingsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  active: boolean;
  unauthorizedAccess: {};
  enabledOnArticle: boolean,
  enabledOnLiveReport: boolean,
}

const initialState: VerifySettingsState = {
  loaded: false,
  loading: false,
  error: null,
  active: false,
  unauthorizedAccess: null,
  enabledOnArticle: false,
  enabledOnLiveReport: false,
};

export function verifySettingsReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_VERIFY_SETTINGS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_VERIFY_SETTINGS_COMPLETE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        active: action.payload.active,
        unauthorizedAccess: action.payload.unauthorizedAccess,
        enabledOnArticle: action.payload.enabledOnArticle,
        enabledOnLiveReport: action.payload.enabledOnLiveReport,
      };
    }
    default: {
      return state;
    }
  }
}


export const getVerifySettingsState = (state) => state.verifySettings;
export const getVerifySettingsStateLoading = createSelector(getVerifySettingsState, state => state.loading);
export const getVerifySettingsActive = createSelector(getVerifySettingsState, state => state.active);
export const getDefaultAccessBundleId = createSelector(getVerifySettingsState, state => {
  if (!state.unauthorizedAccess || !state.unauthorizedAccess.settings) {
    return null;
  }
  return state.unauthorizedAccess.settings.id;
});
export const getVerifySettingsArticleEnabled = createSelector(getVerifySettingsState, (state) => {
  return state.enabledOnArticle;
});
export const getVerifySettingsLiveReportEnabled = createSelector(
  getVerifySettingsState,
  (state) => {
    return state.enabledOnLiveReport;
  }
);
