import { mergeMap, map, catchError, withLatestFrom, tap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app-reducer';
import { CollectionsService } from '../../api/collections/collections.service';
import { Observable, of } from 'rxjs';
import {
  GET_ACTIVE_COLLECTION,
  CREATE_COLLECTION,
  UPDATE_COLLECTION,
  PUBLISH_COLLECTION,
  UNPUBLISH_COLLECTION,
  CHANGE_COLLECTION_REVISION,
  COLLECTION_ACTION_FAILED,
  CollectionFailedAction,
  GetActiveCollectionSuccessAction,
  CreateCollectionSuccessAction,
  UpdateCollectionSuccessAction,
  PublishCollectionSuccessAction,
  UnpublishCollectionSuccessAction,
  ChangeCollectionRevisionSuccessAction,
  COPY_COLLECTION_BY_ID,
  SCHEDULE_COLLECTION,
  ScheduleCollectionSuccessAction,
  UNSCHEDULE_COLLECTION,
  UnscheduleCollectionSuccessAction,
  PublishCollectionAction,
} from './collection.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { RefreshLockAction } from '../lock/lock.actions';
import { getActiveWorkflowForCollections } from '../workflows/workflow.reducer';
import { ACCOUNT_CHANGED } from '..';
import { GetActiveWorkflowAction } from '../workflows/workflow.actions';

@Injectable()
export class CollectionEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private collectionService: CollectionsService,
    private store: Store<AppState>
  ) {}


  loadActiveCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACTIVE_COLLECTION),
    mergeMap((action: UnsafeAction) => {
      return this.collectionService.getCollection(action.payload).pipe(
        map((collection) => new GetActiveCollectionSuccessAction(collection)),
        catchError((e) => {
          const message = (e && e.payload && e.payload.message) || (e && e.message) || $localize`Unknown`;
          if (message !== 'Forbidden') {
            this.router.navigate(['/collections/']);
            setTimeout(
              () => this.snackBar.open($localize`Invalid Collection Id`, $localize`Close`, { duration: 4000 }),
              0
            );
          }
          return of(new CollectionFailedAction(e));
        })
      );
    })
  ));


  createCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_COLLECTION),
    mergeMap((action: UnsafeAction) => {
      return this.collectionService.createCollection({ ...action.payload }).pipe(
        tap((collection) => {
          this.router.navigate(['/collections/', collection.id]);
          this.snackBar.open($localize`Collection created`, $localize`Close`, { duration: 4000 });
        }),
        map((collection) => new CreateCollectionSuccessAction(collection)),
        catchError((e) => of(new CollectionFailedAction(e)))
      );
    })
  ));


  updateCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_COLLECTION),
    mergeMap((action: UnsafeAction) => {
      const data = { ...action.payload };
      return this.collectionService.updateCollection({ id: data.editionId, data }).pipe(
        map((edition) => new UpdateCollectionSuccessAction(edition)),
        tap(() => this.snackBar.open($localize`Collection updated`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.store.dispatch(new RefreshLockAction());
          return of(new CollectionFailedAction(e));
        })
      );
    })
  ));

  publishCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(PUBLISH_COLLECTION)).pipe(
    mergeMap((action: PublishCollectionAction) => {

      const collectionPayload = action.payload;
      return this.collectionService
        .publishCollection(collectionPayload.id, collectionPayload.publishedRevisionId)
        .pipe(
          mergeMap((res) => {
            return this.store.select(getActiveWorkflowForCollections).pipe(
              take(1),
              map((activeWorkflow) => {
                const { data, message } = res;
                data.publishedState = activeWorkflow.publishedState;
                data.unpublishedState = activeWorkflow.unpublishedState;
                return { data, message };
              })
            );
          }),
          tap((res) => {
            const message = $localize`Collection published`
            this.snackBar.open(message, $localize`Close`, { duration: 4000 })
          }),
          map((res) => new PublishCollectionSuccessAction(res.data)),
          catchError((e) => of(new CollectionFailedAction({ error: e, action })))
        );
      }
    )
  ));

  unpublishCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UNPUBLISH_COLLECTION)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.collectionService.unpublishCollection(action.payload).pipe(
        mergeMap((message) => {
          return this.store.select(getActiveWorkflowForCollections).pipe(
            take(1),
            map((activeWorkflow) => {
              const data = activeWorkflow.unpublishedState;
              return { data, message };
            })
          );
        }),
        tap((res) => {
          const message = $localize`Collection unpublished`;
          this.snackBar.open(message, $localize`Close`, { duration: 4000 })
        }),
        map((res) => new UnpublishCollectionSuccessAction(res.data)),
        catchError((e) => of(new CollectionFailedAction({ error: e, action })))
      );
    })
  ));


  changeRevision$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_COLLECTION_REVISION),
    withLatestFrom(this.store.select((state) => state.collections.collectionRevisionMap)),
    mergeMap(([action, revisionsMap]: [UnsafeAction, any]) => {
      const id = action.payload;
      const observer = revisionsMap[id]
        ? of({ ...revisionsMap[id] })
        : this.collectionService.getCollectionRevisionById(id);
      return observer.pipe(
        map((edition) => new ChangeCollectionRevisionSuccessAction(edition)),
        tap(() => this.snackBar.open($localize`Revision Changed`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new CollectionFailedAction(e)))
      );
    })
  ));

  scheduleCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(SCHEDULE_COLLECTION)).pipe(
    mergeMap((action: UnsafeAction) => {
      const collectionPayload = action.payload;
      return this.collectionService
        .scheduleCollection(collectionPayload.id, collectionPayload.publishedRevisionId)
        .pipe(
          mergeMap((res) => {
            return this.store.select(getActiveWorkflowForCollections).pipe(
              take(1),
              map((activeWorkflow) => {
                const { data, message } = res;
                data.scheduledState = activeWorkflow.scheduledState;
                return { data, message };
              })
            );
          }),
          tap((res) => {
            const message = $localize`Collection scheduled successfully.`
            this.snackBar.open(message, $localize`Close`, { duration: 4000 })
          }),
          map((res) => new ScheduleCollectionSuccessAction(res.data)),
          catchError((e) => of(new CollectionFailedAction({ error: e, action })))
        );
    })
  ));


  unscheduleCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UNSCHEDULE_COLLECTION)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.collectionService.unscheduleCollection(action.payload).pipe(
        mergeMap((message) => {
          return this.store.select(getActiveWorkflowForCollections).pipe(
            take(1),
            map((activeWorkflow) => {
              const data = activeWorkflow.publishableState;
              return { data, message };
            })
          );
        }),
        tap((res) => {
          const message = $localize`Successfully unscheduled.`
          this.snackBar.open(message, $localize`Close`, { duration: 4000 })
        }),
        map((res) => new UnscheduleCollectionSuccessAction(res.data)),
        catchError((e) => of(new CollectionFailedAction({ error: e, action })))
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(COLLECTION_ACTION_FAILED),
    tap((err: any) => {
      const message =
        (err && err.payload && err.payload.message) || (err && err.message) || $localize`Unknown`;
      // Handling Taxonomy permissions
      if (message === 'Forbidden') {
        this.router.navigate(['collections/']);
        const errorMessage =
        $localize`Access denied. You may not have the appropriate Taxonomy permissions to access this collection.`;
        return this.snackBar.open(errorMessage, $localize`Close`, { duration: 5000 });
      }
      this.snackBar.open($localize`Action failed: ` + message, `Close`, { duration: 4000 });
    })
  ), { dispatch: false });


  copyCollectionById$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(COPY_COLLECTION_BY_ID),
    mergeMap((action: UnsafeAction) =>
      this.collectionService.getCollection(+action.payload).pipe(
        map((collection: any) => {
          const latestRevision = { ...collection.latestRevision };
          latestRevision.name = $localize `:This is the headline for new content created by copy:Copy of ${latestRevision.name}`;

          const revisionFieldsToReset = ['id', 'createdAt', 'publishFrom', 'publishTo'];
          revisionFieldsToReset.forEach((key) => (latestRevision[key] = null));

          const newCollection = { ...collection, latestRevision };
          const collectionFieldsToReset = [
            'id',
            'glideId',
            'accountId',
            'createdAt',
            'updatedAt',
            'firstPublishedAt',
            'lastPublishedAt',
            'publishedRevision',
            'revisions',
          ];
          collectionFieldsToReset.forEach(
            (key) => (newCollection[key] = key === 'revisions' ? [] : null)
          );
          return new GetActiveCollectionSuccessAction(newCollection);
        }),
        tap(() => {
          this.router.navigate(['collections', 'create'], { state: { copyMode: true } });
        })
      )
    )
  ));


  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .pipe(map(() => new GetActiveWorkflowAction())));
}
