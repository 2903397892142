<mat-toolbar class="main-toolbar mat-elevation-z2">
  <div fxHide="false"
       fxHide.gt-sm>
    <button (click)="sidenav.open()"
            mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <gd-favorites *ngIf="showFavorites"
                fxHide="true"
                fxHide.gt-sm="false"
                [style]="(dir$ | async) === 'rtl' ? 'border-right: unset;' : ''"></gd-favorites>

  <!-- spacer -->
  <span fxFlex
        *ngIf="(dir$ | async) === 'rtl'"></span>

  <!-- breadcrumbs -->
  <ng-container [ngSwitch]="activeSection"
                *ngIf="showBreadcrumbs">
    <gd-file-manager-breadcrumb *ngSwitchCase="'files'"
                                fxHide="true"
                                fxHide.gt-sm="false"></gd-file-manager-breadcrumb>
    <gd-breadcrumbs *ngSwitchDefault
                    fxHide="true"
                    fxHide.gt-sm="false"></gd-breadcrumbs>
  </ng-container>

  <!-- spacer -->
  <span fxFlex
        *ngIf="(dir$ | async) !== 'rtl'"></span>

  <gd-user-menu></gd-user-menu>

  <!-- spinner of doom, indicates something is loading from the api, or that something broke -->
  <div style="position: absolute; bottom: -1px; left: 0; right: 0;">
    <mat-progress-bar mode="indeterminate"
                      [style.opacity]="loadingBarActive ? 1 : 0"></mat-progress-bar>
  </div>

</mat-toolbar>

<div *ngIf="sectionHasFloatingTabHeader"
     class="gd-toolbar__floating-tab-header-placeholder">
  <!-- This space is reserved so that it can be occupied by floating tab headers or toolbars -->
  <!-- The floating section header or tab select is placed over this using `position: fixed` -->
</div>
