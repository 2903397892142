import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class StorageService {

  constructor() { }

  saveUserInStore(userData): any {
    this.clearUserFromStore();
    const rememberMe = localStorage.getItem('rememberMe') && JSON.parse(localStorage.getItem('rememberMe'));
    if (rememberMe) {
      return localStorage.setItem('activeUser', JSON.stringify(userData));
    }
    return this.setSessionCookie('activeUser', JSON.stringify(userData));
  }

  loadUserFromStore(): any {
    let userData;
    const rememberMe = localStorage.getItem('rememberMe') && JSON.parse(localStorage.getItem('rememberMe'));
    if (rememberMe) {
      userData = localStorage.getItem('activeUser');
    } else {
      userData = this.getCookie('activeUser');
    }

    return userData ? JSON.parse(userData) : null;
  }

  clearUserFromStore(): void {
    localStorage.removeItem('activeUser');
    this.setSessionCookie('activeUser', '');
  }

  setSessionCookie(cname, cvalue): void {
    const cookieData = cname + '=' + cvalue + ';path=/';
    document.cookie = cookieData;
  }

  getCookie(cname): any {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const value = c.substring(name.length, c.length);
        return value !== '' ? value : null;
      }
    }
    return null;
  }

  loadAccountSettingsFromStore(accountId) {
    const key = btoa('accountSettings' + accountId);
    const settings = localStorage.getItem(key);
    return settings ? JSON.parse(settings) : {};
  }

  saveAccountSettingsInStore(accountId, settings) {
    const key = btoa('accountSettings' + accountId);
    return localStorage.setItem(key, JSON.stringify(settings));
  }

  saveMediaSettingsInStore(accountId, settings) {
    const key = btoa('mediaSettings' + accountId);
    return localStorage.setItem(key, JSON.stringify(settings));
  }

  loadMediaSettingsInStore(accountId) {
    const key = btoa('mediaSettings' + accountId);
    const settings = localStorage.getItem(key);
    return settings ? JSON.parse(settings) : {};
  }


  setI18nActiveUserData(userData) {
    if(!userData) {
      return;
    }

    localStorage.setItem('i18nActiveUser', JSON.stringify(userData));
  }

  getI18nActiveUserData() {
    const userData = localStorage.getItem('i18nActiveUser');
    this.clearI18nActiveUserData();
    return userData ? JSON.parse(userData) : null;
  }

  clearI18nActiveUserData() {
    localStorage.removeItem('i18nActiveUser');
  }
}
