import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest.service';

@Injectable()
export class SSOService {
  restParams = {
    service: 'glideUsers',
  };

  constructor(private rest: RestService) {}

  getConfig() {
    return this.rest.get(`sso`, this.restParams).pipe(map((response) => response.data));
  }

  updateSamlConfig({ id, data }) {
    return this.rest
      .put(`sso/${id}`, data, this.restParams)
      .pipe(map((response) => response.data));
  }

  createSamlConfig(payload) {
    return this.rest
      .post(`sso`, payload, this.restParams)
      .pipe(map((response) => response.data));
  }

  getProviders() {
    return this.rest.get('sso/providers', this.restParams).pipe(map((response) => response.data));
  }

  getProvider(id) {
    return this.rest
      .get(`sso/providers/${id}`, this.restParams)
      .pipe(map((response) => response.data));
  }

  updateProvider({ id, data }) {
    return this.rest
      .put(`sso/providers/${id}`, data, this.restParams)
      .pipe(map((response) => response.data));
  }

  getEnabledProvider() {
    return this.rest.get('sso/enabled', this.restParams).pipe(map((response) => response.data));
  }
}
