import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ImagesService } from './../images/images.service';
import { WorkflowsService } from '../workflows/workflows.service';
import { of, Observable, forkJoin } from 'rxjs';
import { articleReducer } from '../../store';

@Injectable()
export class GalleriesService {
  defaultParams = {
    service: 'glideMedia',
  };

  constructor(
    private rest: RestService,
    private workflowsService: WorkflowsService,
    private imagesService: ImagesService
  ) {}

  getGalleries(payload) {
    const { title, status, taxonomies, pageView, contentLocaleId } = payload;
    let queryUrl = `galleries?size=${pageView.pageSize}&page=${pageView.currentPage}`;
    if (title) {
      queryUrl += `&title=${encodeURIComponent(title)}`;
    }
    if (status) {
      queryUrl += `&status=${status}`;
    }
    if (taxonomies) {
      queryUrl += `&taxonomies=${taxonomies}`;
    }
    if (contentLocaleId) {
      queryUrl += `&contentLocaleId=${contentLocaleId}`;
    }
    return this.rest.get(queryUrl, this.defaultParams).pipe(
      mergeMap((data) => makeGalleriesArray(data, this.imagesService)),
      map(({ total, galleries }) => {
        const galleriesMap = galleries.reduce(
          (acc, gallery) => ({ ...acc, [gallery.id]: gallery }),
          {}
        );
        return { total, galleries: galleriesMap };
      })
    );
  }

  getGalleriesByTitle({ pageView, title, published = true, contentLocaleId = null, contentLocaleIds = [] }) {
    let requestPath = `galleries?size=${pageView.pageSize}&page=${0}`;
    if (published) {
      requestPath += `&published=${true}`;
    }
    if (title) {
      requestPath += `&title=${title}`;
    }

    if (contentLocaleId) {
      requestPath += `&contentLocaleId=${contentLocaleId}`;
    } else {
      requestPath += contentLocaleIds.length ? `&contentLocaleIds=${contentLocaleIds}` : '';
    }

    return this.rest.get(requestPath, this.defaultParams).pipe(
      mergeMap((data) =>
        makeEmbedGalleryDialogImagesArray(data.items || [], this.imagesService).pipe(
          map((items: []) => ({
            items,
            total: data.total,
          }))
        )
      )
    );
  }

  getEmbedGalleryDialogImages({ pageSize = 10, pageIndex = 0, title = '', published = true, contentLocaleId = null, contentLocaleIds = [] }) {
    let queryUrl = `galleries?size=${pageSize}&page=${pageIndex}`;
    if (published) {
      queryUrl += `&published=${true}`;
    }
    if (title) {
      queryUrl += `&title=${title}`;
    }

    if(contentLocaleId) {
      queryUrl += `&contentLocaleId=${contentLocaleId}`;
    } else {
      queryUrl += contentLocaleIds.length ? `&contentLocaleIds=${contentLocaleIds}` : '';
    }

    return this.rest
      .get(queryUrl, this.defaultParams)
      .pipe(
        mergeMap((data) =>
          makeEmbedGalleryDialogImagesArray(data.items || [], this.imagesService).pipe(
            map((items: []) => ({
              items,
              total: data.total,
            }))
          )
        ),
        map((response) => ({ total: response.total, items: response.items }))
      )
      .toPromise();
  }

  createGallery(payload) {
    // TODO change api so that we can use th whole payload here
    const newGalleryData = {
      localizationId: payload.localizationId,
      contentLocaleId: +payload.contentLocaleId,
      contentLocaleCode: payload.contentLocaleCode,
    };
    return this.rest.post('galleries', newGalleryData, this.defaultParams);
  }

  createGalleryRevision(galleryID, payload) {
    return this.rest.post(`galleries/${galleryID}`, payload, this.defaultParams);
  }

  getGalleryDetails(id, published = false) {
    return this.rest.get(`galleries/${id}?published=${published}`, this.defaultParams).pipe(
      mergeMap((gallery) =>
        gallery.error
          ? of(null)
          : getPromoImageFromGallery(
              published ? gallery.published : gallery.latest,
              this.imagesService
            ).pipe(map((promoImage) => ({ ...gallery, promoImage })))
      ),
      map((gallery: any) => {
        if (!gallery) {
          return {};
        }
        const activeRevision = published ? gallery.published : gallery.latest;
        activeRevision.promoImage = gallery.promoImage;
        const updatedGalleryRevisions = gallery.revisions.map((revision) => {
          if (revision.id === activeRevision.id) {
            revision = { ...revision, ...activeRevision };
            revision.images = activeRevision.images
              .map((img) => this.imagesService.processImage(img))
              .sort((a, b) =>
                a.gallery_revision_images.order < b.gallery_revision_images.order ? -1 : 1
              );

            revision.fetched = true;
          }

          revision.createdAt = revision.created_at;
          revision.updatedAt = revision.updated_at;
          revision.createdBy = +revision.created_by;

          return revision;
        });

        return {
          ...gallery,
          revisions: updatedGalleryRevisions,
        };
      })
    );
  }

  getGalleryRevision(galleryId, revisionId) {
    return this.rest.get(`galleries/${galleryId}/revisions/${revisionId}`, this.defaultParams).pipe(
      mergeMap((revision) =>
        getPromoImageFromGallery(revision, this.imagesService).pipe(
          map((promoImage) => ({
            ...revision,
            promoImage,
          }))
        )
      ),
      map((revision: any) => ({
        ...revision,
        id: revisionId,
        images: revision.images
          .map((img) => this.imagesService.processImage(img))
          .sort((a, b) => (a.order < b.order ? -1 : 1)),
      }))
    );
  }

  getLocalizedVersionsByGalleryId(id: number) {
    return this.rest.get(`galleries/${id}/localized-versions`, this.defaultParams);
  }

  getLocalizedVersionsForGalleries(ids: number[]) {
    if (ids.length < 1) {
      return of([]);
    }
    return this.rest.get(`galleries/localized-versions?ids=${ids}`, this.defaultParams);
  }

  deleteGallery(id) {
    return this.rest.delete(`galleries/${id}`, this.defaultParams);
  }

  publishGalleryRevision({ revision_id, gallery_id, status }) {
    return this.rest.put(`galleries/${gallery_id}`, { revision_id, status }, this.defaultParams);
  }

  getWorkflows() {
    return this.rest.get('workflows', this.defaultParams).pipe(
      map((workflows) =>
        workflows.map((workflow) => {
          const workflowTransitions = workflow.workflow_transitions.map((transition) => ({
            ...transition,
            startingState: workflow.statuses.find(
              (status) => status.name === transition.startingState.name
            ),
            endingState: workflow.statuses.find(
              (status) => status.name === transition.endingState.name
            ),
          }));

          const statesGraph = Object.entries(
            this.workflowsService.parseStateTrasnitionsGraph(workflowTransitions)
          ).reduce((acc, entry) => {
            const [id, status]: [string, any] = entry;
            return {
              ...acc,
              [id]: {
                ...status,
                isInitial: status.is_initial,
                isPublished: status.is_published,
                isPublishable: status.is_publishable,
              },
            };
          }, {});

          return {
            ...workflow,
            workflowTransitions,
            statesGraph,
            statuses: workflow.statuses,
          };
        })
      )
    );
  }
}

const makeGalleriesArray = ({ total, items }, imgService) => {
  if (!items || items.length === 0) {
    return of({ total, galleries: [] });
  }

  const galleriesObservables = items.map((gallery) =>
    of(gallery).pipe(
      mergeMap(() => getPromoImageFromGallery(gallery.latest, imgService)),
      map((image: any) => {
        if (gallery.latest && gallery.latest.images) {
          gallery.latest['images'] = gallery.latest.images.map((img) =>
            imgService.processImage(img)
          );
          gallery.latest.promoImage = image;
        }
        return gallery;
      })
    )
  );
  return forkJoin(galleriesObservables).pipe(map((galleries: []) => ({ total, galleries })));
};

const makeEmbedGalleryDialogImagesArray = (items, imgService) => {
  if (!items || items.length === 0) {
    return of([]);
  }
  const galleriesObservables = items.map((gallery) =>
    of(gallery).pipe(
      mergeMap(() => getPromoImageFromGallery(gallery.published || gallery.latest, imgService)),
      map((image: any) => {
        image = image || { thumbnail: null };
        const activeRevision = gallery.published || gallery.latest;
        return {
          ...image,
          id: gallery.id,
          title: activeRevision.title,
          publishedAt: gallery.published_at,
          updatedAt: gallery.updated_at,
          imageCount: activeRevision && activeRevision.images.length,
          isPublished: !!gallery.published_revision_id,
          status: activeRevision.status,
        };
      })
    )
  );
  return forkJoin(galleriesObservables);
};

// The function returns gallery promo image or first image if a promo image hasn't been set.
export const getPromoImageFromGallery = (gallery, imgService) => {
  return new Observable((observer) => {
    const promoImage = gallery.metaData.find((obj) => obj.key === 'promoImage');
    const galleryImages = gallery.images && gallery.images.sort((prev, curr) => prev.gallery_revision_images.order > curr.gallery_revision_images.order ? 1: -1);
    if (!promoImage) {
      const img = gallery.images.length === 0 ? null : imgService.processImage(galleryImages[0]);
      return emitValueAndComplete(observer, img);
    }
    const image: any = gallery.images.find((img) => img.id === promoImage.value);
    if (image) {
      return emitValueAndComplete(observer, imgService.processImage(image));
    }
    imgService
      .getImage(promoImage.value)
      .then((data) => {
        const img = data && data.id ? imgService.processImage(data) : null;
        return emitValueAndComplete(observer, img);
      })
      .catch(() => {
        const img = gallery.images.length === 0 ? null : imgService.processImage(galleryImages[0]);
        return emitValueAndComplete(observer, img);
      });
  });
};

const emitValueAndComplete = (observer, value) => {
  observer.next(value);
  observer.complete();
};
