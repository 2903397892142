import { UnsafeAction } from '../unsafe-action.interface';
import { Page } from './pages.model';


export const GET_PAGES = '[Pages] Get pages';
export class GetPagesAction implements UnsafeAction {
  readonly type = GET_PAGES;
  constructor() { }
}

export const GET_PAGES_SUCCESS = '[Pages] Get pages success';
export class GetPagesSucessAction implements UnsafeAction {
  readonly type = GET_PAGES_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_PAGE = '[Pages] Create page';
export class CreatePageAction implements UnsafeAction {
  readonly type = CREATE_PAGE;
  constructor(public payload: any) { }
}

export const CREATE_PAGE_SUCCESS = '[Pages] Create page success';
export class CreatePageSuccessAction implements UnsafeAction {
  readonly type = CREATE_PAGE_SUCCESS;
  constructor(public payload: Page) { }
}

export const UPDATE_PAGE = '[Pages] Update page';
export class UpdatePageAction implements UnsafeAction {
  readonly type = UPDATE_PAGE;
  constructor(public payload: Page) { }
}

export const UPDATE_PAGE_SUCCESS = '[Pages] Update page success';
export class UpdatePageSuccessAction implements UnsafeAction {
  readonly type = UPDATE_PAGE_SUCCESS;
  constructor(public payload: any) { }
}

export const DELETE_PAGE = '[Pages] Delete page';
export class DeletePageAction implements UnsafeAction {
  readonly type = DELETE_PAGE;
  constructor(public payload: any) { }
}

export const DELETE_PAGE_SUCCESS = '[Pages] Delete page completed';
export class DeletePageCompletedAction implements UnsafeAction {
  readonly type = DELETE_PAGE_SUCCESS;
  constructor(public payload?: any) { }
}

export const PUBLISH_PAGE = '[Pages] Publish page';
export class PublishPageAction implements UnsafeAction {
  readonly type = PUBLISH_PAGE;
  constructor(public payload: any) { }
}

export const UNPUBLISH_PAGE = '[Pages] Unublish page';
export class UnpublishPageAction implements UnsafeAction {
  readonly type = UNPUBLISH_PAGE;
  constructor(public payload: number) { }
}

export const SET_ACTIVE_PAGE_ID = '[Pages] Set active page id';
export class SetActivePageIdAction implements UnsafeAction {
  readonly type = SET_ACTIVE_PAGE_ID;
  constructor(public payload: number) { }
}

export const SET_PAGE_FILTER = '[Pages] Set page filter';
export class SetPageFilterAction implements UnsafeAction {
  readonly type = SET_PAGE_FILTER;
  constructor(public payload: String) { }
}

export const SET_ACTIVE_PAGE = '[Pages] Set active page';
export class SetActivePageAction implements UnsafeAction {
  readonly type = SET_ACTIVE_PAGE;
  constructor(public payload: any) { }
}

export const CLEAR_PAGES_STATE_ACTION = '[Pages] Clear Pages State';
export class ClearPagesStateAction implements UnsafeAction {
  readonly type = CLEAR_PAGES_STATE_ACTION;
  constructor() { }
}

export const PAGE_FAILED_ACTION = '[Pages] Action Failed';
export class PagesFailureAction implements UnsafeAction {
  readonly type = PAGE_FAILED_ACTION;
  constructor(public payload: any) { }
}
