import { RestService } from '../rest.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LiveReportTypesService {
  constructor(
    private rest: RestService,
  ) { }

  get() {
    return this.rest.get('live-report-types').pipe(
      map((response: any) => response.data.find(element => element.defaultType === true))
    );
  }

  update(liveReportType: any) {
    const id = liveReportType.id;
    return this.rest
      .put(`live-report-types/${id}`, liveReportType)
      .pipe(map((response: any) => response.data));
  }

}
