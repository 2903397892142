import { get } from 'lodash-es';
import { GlideContentActionBusService } from './../../core/body-content/glide-content-actions-bus.service';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Store } from '@ngrx/store';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription , BehaviorSubject, Observable } from 'rxjs';

import { UploadImageMetaFormComponent } from '../upload-image-meta-form/upload-image-meta-form.component';

import { AppState } from './../../core/store/app-reducer';
import { getImages, initialState, getImagesView, getImagesTags } from '../../core/store/images/images.reducer';
import {
  GetImagesAction,
  SetPageViewOptions,
  ClearImagesStateAction,
  ResetImagesStateAction,
  GetTagsAction,
} from '../../core/store/images/images.actions';
import {
  ResetImagesUploadStateAction,
  UpdateQueuedImageMetaAction,
} from '../../core/store/images-upload/images-upload.actions';

import { queueBeingProcessed } from '../../core/store/images-upload/images-upload.reducer';
import { GalleriesService } from '../../core/api/galleries/galleries.service';
import { GridOptions } from '../images-view-grid/grid-options.model';
import { ImagesService } from '../../core/api/images/images.service';
import { startWith, debounceTime, map, filter } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { Permissions } from '../../core/store/auth/permissions';
import { defaultPageSize, defaultPageSizeOptions } from '../../core/store/constants/default-pagination.constants';
import { getUsersList } from '../../core/store/users/users.reducer';
import { GetUsersAction } from '../../core/store/users/users.actions';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { DateFormatService } from '../../core/miscellaneous/date-format.service';
import { ExpandableChipTypes } from '../expandable-chip-list/expandable-chip-list.component';
import { isEmpty } from 'lodash';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ModalsService } from '../modals/modals.service';
import { ExternalImageLibraryWidgetComponent } from '../external-image-library-widget/external-image-library-widget.component';
import { multipleLocalesExist } from '../../core/store/content-locales/content-locales.reducer';
import { BidiService } from '../../core/i18n/bidi.service';
import { AccountSettingsService } from '../../core/api/account-settings/accounts-settings.service';
import { MixPanelService } from '../../core/api/mixpanel/mixpanel.service';

interface ModalOptions {
  showTabs: boolean;
  dialogContent: string;
  showSearch: boolean;
  loadSingleImage: boolean;
  showLibrary: boolean;
  showUnpublishedContent?: boolean;
  contentLocaleId?: any
}

const CUSTOM_FORMAT = {
  parse: { dateInput: 'LL' },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'gd-embed-media-dialog',
  templateUrl: './embed-media-dialog.component.html',
  styleUrls: ['./embed-media-dialog.component.scss'],
  animations: [
    trigger('showMetaCard', [
      state('preview', style({ transform: 'translateX(100%)' })),
      state('details', style({ transform: 'translateX(0%)' })),
      state('*', style({ transform: 'translateX(0%)' })),
      state('void', style({ transform: 'translateX(100%)' })),
      transition('preview => details', [animate(300)]),
      transition('details => preview', [animate(300)]),
      transition('void => *', [animate(300)]),
      transition('* => void', [animate(300)]),
    ]),
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMAT }],
})

export class EmbedMediaDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  loading$;
  activeTab = 'Library';
  modalLayout = { contentHeight: null, extraHeight: 0 };

  selectedIndex: number = 0;

  // Default Dialog Options
  dialogOptions: ModalOptions = {
    showTabs: true,
    dialogContent: 'images',
    showSearch: false,
    loadSingleImage: false,
    showLibrary: true,
    showUnpublishedContent: false,
    contentLocaleId: null
  };

  // Default Grid Config
  gridOptions: GridOptions = {
    viewAs: 'contained',
    enableSelection: true,
    showActionControls: false,
    columns: 5,
    fixedGrid: true,
    thumbnailDraggable: false,
    sortable: false,
    embedImageModal: false,

  };

  // Default Upload Config
  uploadConfig = {
    viewAs: 'contained',
    autoImport: true,
    multipleSelect: false,
    multiUpload: false,
    embedModal: true,
  };

  // Default pager config
  initialPaginatorConfig = {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageIndex: 0,
    pageSizeOptions: defaultPageSizeOptions,
  };

  paginator = <any>{};

  items = [];
  itemsFiltered = false;

  selectedItems = [];

  galleries = { items: [], total: 0, filtered: false };
  enqueuedImage = null;
  hasEnqueuedImages = false;
  enqueuedImagesInvalid = false;
  queueBeingProcessed$;
  activeToggleMenuItem = 'preview';
  uploadImageMetaFormActive = false;

  dataSubscriptions = new Subscription();

  filteredTags$: Observable<any>;
  filteredUsers$: Observable<any>;

  searchFormControl: UntypedFormControl = new UntypedFormControl('');
  tagsFormControl: UntypedFormControl = new UntypedFormControl([]);
  usersFormControl: UntypedFormControl = new UntypedFormControl([]);

  tagsFilterControl: UntypedFormControl = new UntypedFormControl('');
  usersFilterControl: UntypedFormControl = new UntypedFormControl('');

  uploadedAtControl = new UntypedFormGroup({
    gte: new UntypedFormControl(),
    lte: new UntypedFormControl()
  });

  @ViewChild('photosGrid') photosGrid;
  @ViewChild('imageUpload') imageUpload;
  @ViewChild('imageMetaForm') imageMetaForm: UploadImageMetaFormComponent;
  @ViewChild('scrollbar') scrollbar;

  hasReadTagPermission = this.permissionService.hasPermission(Permissions.GM_TAG_READ);
  hasUploadImagePermission = this.permissionService.hasPermission(Permissions.GM_IMAGE_WRITE);
  hasImageReadPermission = this.permissionService.hasPermission(Permissions.GM_IMAGE_READ);
  ExpandableChipTypes = ExpandableChipTypes;
  blueLineThreshold: number = 50;

  contentLocaleId = null;
  multipleLocalesExist = false;
  contentLocaleIds = [];

  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();
  gaiaEnabled = this.accountSettingsService.isGAIAEnabled() && this.imagesService.isMediaAIGenEnabled();
  queuedImagesSub;
  imageGenerated = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedMediaDialogComponent>,
    private store: Store<AppState>,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private galleriesService: GalleriesService,
    private imagesService: ImagesService,
    private permissionService: PermissionService,
    private actionBus: GlideContentActionBusService,
    private bidiService: BidiService,
    private dateFormatService: DateFormatService,
    private modalsService: ModalsService,
    private accountSettingsService: AccountSettingsService,
    private mixPanelService: MixPanelService,
  ) { }

  ngOnInit() {
    this.items = this.createImagesHelperArray(initialState.imagesView, true);
    this.paginator = this.initialPaginatorConfig;

    this.contentLocaleId =
    get(this.dialogInputData, 'data.contentLocaleId', null) ||
    get(this.dialogInputData, 'dialogOptions.contentLocaleId', null);

    this.contentLocaleIds = this.dialogInputData.allowedContentLocales || [];

    this.store.dispatch(new ResetImagesStateAction());
    this.store.dispatch(new ResetImagesUploadStateAction());
    this.store.dispatch(new ClearImagesStateAction());
    // Resolve configurations needed for dialog, grid, and upload
    this.resolveConfigurations();
    // Resolve dialog content based on passed content key
    this.resolveDialogContent();

    this.initializeLoaders();
    // Check if there's input of selectedImages (used for image selection within Library tab)
    if (this.dialogInputData.selectedImages) {
      this.selectedItems = [...this.dialogInputData.selectedImages];
    }

    this.filteredTags$ = this.store.select(getImagesTags).pipe(map(tags => {
      const tag = this.tagsFilterControl.value;
      const showResults = typeof tag === 'string' && !!tag.trim();
      return showResults ? tags : [];
    }));

    this.dataSubscriptions.add(
      this.tagsFilterControl.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        filter(keystroke => typeof keystroke === 'string'),
        map(keystroke => (keystroke || '').trim())
      ).subscribe(name => this.store.dispatch(new GetTagsAction({ name })))
    );

    this.filteredUsers$ = this.store.select(getUsersList).pipe(
      map(users => {
      const user = this.usersFilterControl.value;
      const showResults = typeof user === 'string' && !!user.trim();
      return showResults ? users : [];
    }));

    this.dataSubscriptions.add(
      this.usersFilterControl.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        filter(keystroke => typeof keystroke === 'string'),
        map(keystroke => (keystroke || '').trim()),
      ).subscribe(name => this.store.dispatch(new GetUsersAction({ search: name })))
    );

    this.selectedIndex = this.activeTab === 'Upload' ? 1 : 0;

    // this just lets us know if there is more than one content locale defined
    // if there is just one, we don't need to show the locale control
    this.dataSubscriptions.add(
      this.store.select(multipleLocalesExist)
        .subscribe(mle => this.multipleLocalesExist = mle)
    )
  }

  ngAfterViewInit() {
    // const queuedImagesSubscription = this.imageUpload.queuedImagesObs.subscribe(queuedImages => {
    //   this.hasEnqueuedImages = queuedImages.length !== 0;

    //   if (!this.uploadConfig.multiUpload && this.hasEnqueuedImages) {
    //     this.enqueuedImage = queuedImages[0];
    //   }

    //   if (this.uploadConfig.multiUpload && this.hasEnqueuedImages) {
    //     this.enqueuedImagesInvalid = queuedImages.every(img => img.invalid);
    //   }
    // });
    // this.dataSubscriptions.add(queuedImagesSubscription);

    // Resolve needed modal elements based on cofnig
    this.resolveModalElements();
    // Apply styles for resolved modal elements
    setTimeout(() => this.setModalContentStyles(), 0);
  }

  resolveConfigurations() {
    // Resolve dialog config
    if (this.dialogInputData.dialogOptions) {
      this.dialogOptions = { ...this.dialogOptions, ...this.dialogInputData.dialogOptions };
      if (this.dialogOptions.dialogContent === 'galleries') {
        this.gridOptions.showGalleryIcon = true;
        this.gridOptions.showUnpublishedContent = this.dialogOptions.showUnpublishedContent;
      }
      if (this.dialogOptions.dialogContent === 'images') {
        this.gridOptions.embedImageModal = true;
      }
    }

    // Resolve grid config
    if (this.dialogInputData.gridOptions) {
      this.gridOptions = { ...this.gridOptions, ...this.dialogInputData.gridOptions };
    }

    // Resolve upload config
    if (this.dialogInputData.uploadConfig) {
      this.uploadConfig = { ...this.uploadConfig, ...this.dialogInputData.uploadConfig };
    }

    this.activeTab = this.dialogOptions.showLibrary ? 'Library' : 'Upload';
  }

  async resolveDialogContent() {
    const { dialogContent } = this.dialogOptions;
    // Dialog content => IMAGES
    if (dialogContent === 'images') {
      this.store.dispatch(new GetImagesAction({ page: this.paginator.pageIndex, size: this.paginator.pageSize}));
      this.store
        .select(getImagesView)
        .subscribe(imagesPageView => (this.paginator = imagesPageView));

      const imagesSubscription = this.store.select(getImages).subscribe(imagesState => {
        if (imagesState.length) {
          this.items = imagesState;
        }

        if (!imagesState.length && this.paginator.total && !this.itemsFiltered) {
          this.items = this.createImagesHelperArray(this.paginator, true);
        }

        if (!imagesState.length && this.itemsFiltered) {
          this.items = imagesState;
        }


        if (!imagesState.length && this.paginator.total) {
          this.items = this.createImagesHelperArray(this.paginator, false);
        }
      });
      // Add to dataSubscriptions group
      this.dataSubscriptions.add(imagesSubscription);
    }

    // Dialog content => GALLERIES
    if (dialogContent === 'galleries') {
      await this.fetchGalleries();
      this.paginator.total = this.galleries.total;
    }
  }

  resolveModalElements() {
    // Show tabs based on config
    if (!this.dialogOptions.showTabs) {
      const tabsHeader = this.elRef.nativeElement.querySelector('mat-tab-header');
      this.renderer.setStyle(tabsHeader, 'display', 'none');
    }

    // Show search block based on config
    if (this.dialogOptions.showLibrary && !this.dialogOptions.showSearch) {
      const searchBlock = this.elRef.nativeElement.querySelector('.gd-embed-media__search');
      this.renderer.setStyle(searchBlock, 'display', 'none');
    }
  }

  setModalContentStyles() {
    let paginatorHeight = 0;
    let modalHeaderHeight = 0;
    let modalSearchHeight = 0;

    const modalHeight = +window.getComputedStyle(this.elRef.nativeElement).height.replace('px', '');
    const modalActionsHeight = +window
      .getComputedStyle(this.elRef.nativeElement.querySelector('mat-dialog-actions'))
      .height.replace('px', '');

    paginatorHeight = 0;
    const contentWrapper = this.elRef.nativeElement.querySelector(
      '.gd-embed-media-tabs__content-wrapper'
    );

    let contentWrapperMarginTop = 0;
    if (this.activeTab === 'Library') {
      contentWrapperMarginTop = parseInt(get(contentWrapper, 'style.marginTop', 0), 10);
    }

    // Check if modal has search block enabled or tabs
    if (this.dialogOptions.showTabs) {
      const modalHeaderTabs = this.elRef.nativeElement.querySelector('mat-tab-header');
      const tabsHeight = +window.getComputedStyle(modalHeaderTabs).height.replace('px', '');
      modalHeaderHeight = tabsHeight;
    }

    if (this.dialogOptions.showSearch && this.activeTab === 'Library') {
      const modalHeaderSearch = this.elRef.nativeElement.querySelector('.gd-embed-media__search');
      modalSearchHeight = +window.getComputedStyle(modalHeaderSearch).height.replace('px', '') + 30;
    }

    // contentWrapperMarginTop is used to calculate top margin added on Library tab for gd-embed-media-tabs__content-wrapper
    // and that margin is introduced with adding of filtering on image modal
    const availableHeight =
      modalHeight - modalHeaderHeight - modalActionsHeight - modalSearchHeight - contentWrapperMarginTop;

    if (contentWrapper) {
      this.renderer.setStyle(contentWrapper, 'height', `${availableHeight}px`);
    }
    this.modalLayout.contentHeight = availableHeight;
  }

  async fetchGalleries(pageChanged = false) {
    const title = this.searchFormControl.value;
    if (!title && !this.contentLocaleId && !pageChanged) {
      this.paginator.currentPage = 0;
      this.paginator.pageIndex = 0;
    }
    const galleries = await this.galleriesService
      .getEmbedGalleryDialogImages({ ...this.paginator, title, published: !this.dialogOptions.showUnpublishedContent, contentLocaleId: this.contentLocaleId, contentLocaleIds: this.contentLocaleIds });
    this.galleries.items = galleries.items;
    this.galleries.total = galleries.total;
    this.items = this.galleries.items;
    this.paginator.total = this.galleries.total;
  }

  saveImageMeta(hideForm = true) {
    if (!this.imageMetaForm) {
      return;
    }
    const metadata = this.imageMetaForm.getImageMetaResponse();
    const filterTags = (metadata.tags || []).filter(tag => tag.group !== 'system');
    this.store.dispatch(
      new UpdateQueuedImageMetaAction({
        queueID: this.enqueuedImage.queueID,
        meta: metadata.meta,
        tags: filterTags,
      })
    );
    if (hideForm) {
      this.handleToggleMenuEvent({ value: 'preview' });
      this.enqueuedImage = null;
    }
  }

  async searchResource() {
    const title = this.searchFormControl.value;
    if (this.dialogOptions.dialogContent === 'images') {
      this.paginator.pageIndex = 0;
      const payload = this.preparePayloadObject();
      this.store.dispatch(new GetImagesAction(payload));
      this.itemsFiltered = title !== '' || !!this.tagsFormControl.value.length || !!this.usersFormControl.value.length || !!(this.uploadedAtControl.get('gte').value && this.uploadedAtControl.get('lte'));
    }

    if (this.dialogOptions.dialogContent === 'galleries') {
      if (title || this.contentLocaleId) {
        this.loading$.next(true);
        const galleriesResponse = await this.galleriesService
          .getGalleriesByTitle({ pageView: this.paginator, title, published: !this.dialogOptions.showUnpublishedContent, contentLocaleId: this.contentLocaleId, contentLocaleIds: this.contentLocaleIds })
          .toPromise();
        this.items = galleriesResponse.items;
        this.galleries.filtered = true;
        this.paginator.total = galleriesResponse.total;
        this.paginator.pageIndex = 0;
        this.paginator.currentPage = 0;
        this.loading$.next(false);
      } else {
        this.galleries.filtered = false;
        this.fetchGalleries();
      }
    }
  }

  clearResourceFilter() {
    this.searchFormControl.setValue('');
    this.tagsFormControl.setValue([]);
    this.usersFormControl.setValue([]);
    this.uploadedAtControl.get('gte').setValue(null);
    this.uploadedAtControl.get('lte').setValue(null);
    this.contentLocaleId = null;

    this.paginator.pageIndex = 0;
    if (this.dialogOptions.dialogContent === 'images') {
      const payload = this.preparePayloadObject();
      this.store.dispatch(new GetImagesAction(payload));
      this.itemsFiltered = false;
    }

    if (this.dialogOptions.dialogContent === 'galleries') {
      this.galleries.filtered = false;
      this.fetchGalleries();
    }
  }

  initializeLoaders() {
    if (this.dialogOptions.dialogContent === 'images') {
      this.loading$ = this.store.select(store => store.images.loading);
      this.queueBeingProcessed$ = this.store.select(queueBeingProcessed);
    }

    if (this.dialogOptions.dialogContent === 'galleries') {
      this.loading$ = new BehaviorSubject(false);
    }
  }

  handleImagesSelectedEvent(images) {
    this.selectedItems = images;
  }

  async pageChanged($event) {
    this.scrollbar.scrollTo(0, 0);

    this.paginator.pageIndex = $event.pageIndex;
    this.paginator.pageSize = $event.pageSize;
    if (this.dialogOptions.dialogContent === 'images') {
      this.store.dispatch(new ClearImagesStateAction());
      this.store.dispatch(new GetImagesAction(this.preparePayloadObject()));
    }

    if (this.dialogOptions.dialogContent === 'galleries') {
      this.loading$.next(true);
      this.paginator.currentPage = $event.pageIndex;
      this.items = this.createImagesHelperArray(this.paginator, false);
      await this.fetchGalleries(true);
      this.loading$.next(false);
    }
  }

  handleTabChanged(event: MatTabChangeEvent) {
    if (event.index === 2) {
      this.mixPanelService.trackEvent('GenerateImageTab', {});
    }
    this.resetImagesUploadQueue();
    this.activeTab = event.index === 0 ? 'Library' : (event.index === 1 ? 'Upload' : 'Generate');
    this.setModalContentStyles();
    if (this.dialogInputData.data?.usage === 'article') {
      this.selectedItems = [];
    }
    if (['Upload', 'Generate'].includes(this.activeTab) && !this.queuedImagesSub) {
      this.setImageUploadSub();
    }
  }

  handleToggleMenuEvent(event) {
    this.activeToggleMenuItem = event.value;
  }

  handleImagesProcessed(images) {
    this.insertImages([...this.selectedItems, ...images]);
  }

  async insertImages(images) {
    if (!images || images.length === 0) {
      return;
    }
    if (!this.dialogOptions.loadSingleImage && this.dialogInputData.data?.usage !== 'article') {
      return this.dialogRef.close({ images });
    }
    // embedding image in article or post body
    const glideContentType = get(this.dialogInputData, 'glideContentType', 'ARTICLE');
    this.actionBus.setContentLoading(true, glideContentType);
    let selectedImages = images.image || images;
    let data;
    selectedImages = await Promise.all(
      selectedImages.map(async (image) => {
        const isUploadMode = !!image.queueID;
        const processedImage = await this.imagesService.getImage(image.id).then((img) => this.imagesService.processImage(img));
        data = { ...processedImage, isUploadMode };
        if (isUploadMode) {
          const rawMeta = image.meta.find((item) => item.key === 'image_details') || { value: {}, };
          const metaData = [];
          Object.keys(rawMeta.value).forEach((key) => metaData.push({ key, value: rawMeta.value[key] }));
          data = { ...data, metaData };
        }
        return data;
      })
    );
    this.actionBus.setContentLoading(false, glideContentType);
    this.dialogRef.close({ images: selectedImages });
  }

  createImagesHelperArray(pV, initialPage = false): any[] {
    const imageCounter = (pV.pageIndex + 1) * pV.pageSize;
    if (initialPage) {
      return Array(pV.pageSize).fill({ hideLoader: true });
    }
    const numberOfImagesOnCurrentPage =
      imageCounter - pV.total <= 0 ? pV.pageSize : pV.pageSize - (imageCounter - pV.total);
    return Array(numberOfImagesOnCurrentPage).fill({ hideLoader: true });
  }

  openImageSelector() {
    this.imageUpload.openImageSelectorDialog();
  }

  triggerUploadImage() {
    if (this.uploadConfig.multiUpload && this.activeTab !== 'Generate') {
      this.imageUpload.startUpload();
    } else {
      const hideForm = false;
      this.saveImageMeta(hideForm);
      setTimeout(() => this.imageUpload.startUpload(), 300);
    }
  }

  removeEnqueuedImage() {
    this.imageUpload.removeImageFromQueue(this.enqueuedImage);
    this.enqueuedImage = null;
  }

  handleOpenImageMetaForm(event) {
    this.enqueuedImage = event;
    this.hasEnqueuedImages = true;
    this.handleToggleMenuEvent({ value: 'details' });
  }

  preparePayloadObject() {
    this.store.dispatch(new SetPageViewOptions(this.paginator));

    const tags = this.tagsFormControl.value.map(tag => tag.id);
    const uploadedBy = this.usersFormControl.value.map(user => user.id);

    const uploadedAt: any = this.uploadedAtControl.value;

    if(uploadedAt.lte && typeof uploadedAt.lte === 'object') {
      uploadedAt.lte.add('23:59:59');
    }

    if (uploadedAt.lte) {
      uploadedAt.gte = this.generateTimestamp(uploadedAt.gte);
      uploadedAt.lte = this.generateTimestamp(uploadedAt.lte);
    }

    const search = this.searchFormControl.value.length > 2 ? this.searchFormControl.value : '';
    return { tags, uploadedBy, search, uploadedAt, page: this.paginator.pageIndex, size: this.paginator.pageSize  };
  }

  // made as a generic function to support tags and users
  selectItem(filterControl: UntypedFormControl, control: UntypedFormControl, item) {
    let newEntries = [item];
    filterControl.setValue('');
    const items = control.value;

    // tags need to be handled separately
    if (item.versions) {
      newEntries = item.versions.filter(val => !items.find(({ id }) => id === val.id));
    } else if (items.find(i => i.id === item.id)) {
      newEntries = [];
    }
    // items already added
    if (!newEntries.length) {
      return;
    }
    control.setValue([...items, ...newEntries]);
    this.searchResource();
  }

  // made as a generic function to support tags and users
  removeItem(formControl: UntypedFormControl, item) {
    const items = formControl.value;
    const updatedItems = items.filter(i => i.id !== item.id);
    formControl.setValue(updatedItems);
    this.searchResource();
  }

  generateTimestamp(date) {
    if(!date) {
      return '';
    }
    const datetime = moment(date).format('YYYY-MM-DD HH:mm:ss');
    const timestamp = this.dateFormatService.getLocalizedTimestamp(new Date(datetime).valueOf()).valueOf();
    return timestamp;
  }

  applyDateRange() {
    const { gte, lte } = this.uploadedAtControl.value;
    if(!gte || !lte) {
      this.uploadedAtControl.get('gte').setValue(null);
      this.uploadedAtControl.get('lte').setValue(null);
    }

    this.searchResource();
  }

  clearDateRange() {
    this.uploadedAtControl.get('gte').setValue(null);
    this.uploadedAtControl.get('lte').setValue(null);
    this.searchResource();
  }

  loadGettyWidget() {
    this.modalsService
      .custom('Getty Library', ExternalImageLibraryWidgetComponent, {
        data: {},
        width: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        panelClass: 'gd-external-image-library-dialog',
        disableClose: true
      })
      .subscribe(() => {});
  }

  croppingInProgress() {
    return this.imageUpload?.imageToCrop;
  }

  triggerImageCropping(image) {
    this.imageUpload.toggleCropImage(image);
  }

  handleLocaleChange(locale) {
    this.contentLocaleId = locale.id || null;
    this.searchResource();
  }

  removeGeneratedImageFromQueue() {
    this.imageUpload.removeImageFromQueue(this.enqueuedImage);
    this.enqueuedImage = null;
    this.hasEnqueuedImages = false;
    this.handleToggleMenuEvent({ value: 'preview'});
  }

  setImageUploadSub() {
    setTimeout(() => {
      this.queuedImagesSub = this.imageUpload?.queuedImagesObs.subscribe(queuedImages => {
        this.hasEnqueuedImages = queuedImages.length !== 0;

        if (!this.uploadConfig.multiUpload && this.hasEnqueuedImages) {
          this.enqueuedImage = queuedImages[0];
        }

        if (this.uploadConfig.multiUpload && this.hasEnqueuedImages) {
          this.enqueuedImagesInvalid = queuedImages.every(img => img.invalid);
        }
      });
    }, 100);
  }

  addToQueue() {
    this.mixPanelService.trackEvent('GenerateImageAccept', {});
    this.imageUpload.invokeAddToQueue();
  }

  imageGeneratedChanged(val) {
    this.imageGenerated = val;
  }

  resetImagesUploadQueue() {
    this.store.dispatch(new ResetImagesUploadStateAction());
    // this.selectedItems = [];
    this.enqueuedImage = null;
    this.hasEnqueuedImages = false;
    this.handleToggleMenuEvent({ value: 'preview'});
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetImagesStateAction());
    this.store.dispatch(new ResetImagesUploadStateAction());
    this.dataSubscriptions.unsubscribe();
    if (this.queuedImagesSub) {
      this.queuedImagesSub.unsubscribe();
    }
  }
}
