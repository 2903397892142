import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { colors } from '../../../../glide-users/+accounts/edit-account/presetColors';
import { get } from 'lodash-es';
import { hasRequiredValidation } from '../has-required-validation/has-required-validation';
import { generateHelpTooltip } from '../info-tooltip.text';

@Component({
  selector: 'gd-color-picker-configuration',
  templateUrl: './color-picker-configuration.component.html',
  styleUrls: ['./color-picker-configuration.component.scss']
})
export class ColorPickerConfigurationComponent implements OnInit, OnChanges {
  currentColor = null;
  selectedColor = null;
  presetColors = colors;
  colorPickerForm: UntypedFormGroup;
  @Input() label;
  @Input() fieldControl;
  @Input() fieldConfig;
  @Input() direction;
  @Input() readOnlyMode = false;
  isFormControlRequired = false;
  infoTooltip = '';

  readOnlyTooltip = $localize`Read-only`;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    this.isFormControlRequired ? this.fieldControl.setValidators([Validators.required, Validators.pattern(/^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6}|[0-9a-fA-f]{8})$/)]): this.fieldControl.setValidators(Validators.pattern(/^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6}|[0-9a-fA-f]{8})$/));
    this.fieldControl.updateValueAndValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
  }

  cancelColorPickerModal() {
    this.currentColor = null;
  }

  updateSelectedColor(color) {
    this.currentColor = color;
  }

  closeColorPickerModal() {
    if (!this.currentColor) {
      return;
    }
    this.fieldControl.setValue(this.currentColor);
    this.fieldControl.markAsDirty();
  }

  updateColor(color) {
    this.fieldControl.setValue(color);
    this.fieldControl.markAsDirty();
  }
}
