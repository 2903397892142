import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ContentModel } from '../../store/article/article-content.model';
import { Observable, of } from 'rxjs';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { ArticlePathPreview } from './article-path-preview.model';
import { prepareTextGenerationConfig } from '../article-types/article-types.service';
import { get } from 'lodash';
import { PassthroughCacheFactoryService } from '../cache/api-cache-factory.service';

interface AdvancedFilteringOptions {
  noPagination?: boolean;
  useCache?: boolean;
}

@Injectable()
export class ArticlesService {

  /**
   * TODO improve the caching logic, we'd really want some adaptive cache for any more complex use case
   *
   * This cache is used instead of simple get request, it will first try to
   * get the requested URL from ongoing requests buffer, then from cache,
   * and finally from the API by using the passed apiClient
   *
   * This cache is intended for article autocomplete fields
   */
  passthroughCache = this.passthroughCacheFactory.createCache({
    domain: 'articles-01',
    apiClient: this.rest,
    ttl: 60 * 1000  // 60 seconds
  });

  constructor(
    private rest: RestService,
    private passthroughCacheFactory: PassthroughCacheFactoryService,
  ) { }

  // this takes revisionId and returns html preview for that revision of the article
  getHtmlArticleVersionPreview(versionId) {
    return this.rest
      .get(`articles/preview/${versionId}`).pipe(
      map((articlePreviewData: any) => articlePreviewData.data));
  }

  // This one takes the bodyModel of article content an posts it to the GC api,
  // receivng back the content rendered as HTML
  makeHtmlPreviewForCurrentContent(bodyModel: ContentModel) {
    return this.rest
      .post(`articles/preview`, JSON.stringify(bodyModel)).pipe(
      map((articlePreviewData: any) => articlePreviewData.data));
  }

  getArticlesAdvancedFiltering(queryParams: any, options: AdvancedFilteringOptions = { noPagination: false, useCache: false }) {
    let { page } = queryParams;
    page = typeof page === 'object' ? page.number : page;
    let requestPath = `articles?page=${page || 0}&size=${queryParams.limit || defaultPageSize}`;
    if(options?.noPagination) {
      requestPath += `&noPagination=true`;
    }
    requestPath += queryParams.orderBy && queryParams.orderBy.length > 0 ? `&orderBy=${queryParams.orderBy}` : '';
    requestPath += queryParams.published ? `&published=${queryParams.published}` : '';
    requestPath += queryParams.ids ? `&ids=${queryParams.ids}` : '';
    requestPath += queryParams.generateUrl  ? `&generateUrl=${true}` : '';
    requestPath += queryParams.contentLocaleId ? `&contentLocaleId=${queryParams.contentLocaleId}` : '';

    const queryParamsExist = queryParams.filter && Object.keys(queryParams.filter).length > 0;
    const headlineOrCatchlineQueryParam = get(
      queryParams,
      'filter.include.headlineOrCatchline.like',
      null
    );

    if (queryParamsExist) {
      const filter = queryParams.filter;
      if (headlineOrCatchlineQueryParam === '') {
        delete filter.include.headlineOrCatchline;
      }
      requestPath += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    // primitive cache logic, for use in article autocomplete and simple cases
    if (options?.useCache) {
      return this.passthroughCache.getData(requestPath).pipe(
        map((articlesData: any) => {
          const articles = articlesData.data;
          const query = { page: articlesData.meta.page };
          return { articles, query };
        })
      );
    }

    return this.rest
      // TODO handle defaults
      .get(requestPath).pipe(
      map((articlesData: any) => {
        const articles = articlesData.data;
        const query = { page: articlesData.meta.page };
        return { articles, query };
      }));
  }

  getArticlesDefaultFilter() {
    const requestPath = `articles?contentFilter=default`;
    return this.rest
      // TODO handle defaults
      .get(requestPath).pipe(
      map((articlesData: any) => {
        const articles = articlesData.data;
        const query = { page: articlesData.meta.page };
        return { articles, query };
      }));
  }

  // TODO deprecate this and use merge with getArticlesAdvancedFiltering
  getArticles({
    page = 0, limit = defaultPageSize, headline = '', taxonomies = '', statusId = '', orderBy = '', generateUrl  = null,
    updatesAvailable = false, published = null, catchline = '', authorIds = null, lastUpdateFrom = null, lastUpdateTo = null,
    filter = null
  }) {
    let requestPath = `articles?page=${page || 0}&size=${limit || defaultPageSize}`;
    requestPath += headline ? `&headline=${encodeURIComponent(headline)}` : '';
    requestPath += catchline ? `&catchline=${encodeURIComponent(catchline)}` : '';
    requestPath += taxonomies.length > 0 ? `&taxonomyIds=${taxonomies}` : '';
    requestPath += statusId ? `&statusId=${statusId}` : '';
    requestPath += orderBy.length > 0 ? `&orderBy=${orderBy}` : '';
    requestPath += updatesAvailable ? `&updatesAvailable=${updatesAvailable}` : '';
    requestPath += published !== null ? `&published=${published}` : '';
    requestPath += authorIds ? `&authorIds=${authorIds}` : '';
    requestPath += lastUpdateFrom ? `&lastUpdateFrom=${lastUpdateFrom}` : '';
    requestPath += lastUpdateTo ? `&lastUpdateTo=${lastUpdateTo}` : '';
    requestPath += generateUrl  ? `&generateUrl=${true}` : '';
    if (filter && Object.keys(filter).length > 0) {
      const filterAsString = JSON.stringify(filter);
      requestPath += `&filter=${encodeURIComponent(filterAsString)}`;
    }
    return this.rest
      // TODO handle defaults
      .get(requestPath).pipe(
      map((articlesData: any) => {
        const articles = articlesData.data;
        const query = { page: articlesData.meta.page };
        return { articles, query };
      }));
  }

  getArticleById(id) {
    return this.rest
      .get('articles/' + id).pipe(
      map((articleData: any) => {
        return articleData.data ? deserializeBody(articleData.data) : null;
      }));
  }

  getArticleByGlideId(glideId){
    return this.rest
    .get('glide-entities/' + glideId).pipe(
    map((articleData: any) => {
      return articleData.data ? deserializeBody(articleData.data) : null;
    }));
  }

  getLocalizedVersionsByArticleId(articleId: number) {
    return this.rest
      .get(`articles/${articleId}/localized-versions`).pipe(
      map((articleData: any) => {
        return articleData.data;
      }));
  }

  getLocalizedVersionsByLocalizationIds(localizationIds: string[]) {
    const localizationsIdsString = localizationIds.join(',');
    return this.rest
      .get(`articles/localized-versions?localizationIds=${localizationsIdsString}`)
      .pipe(
        map((articleData: any) => {
          return articleData.data;
        })
      );
  }

  getLocalizedVersionsForArticles(articleIds: number[]) {
    if (articleIds.length < 1) {
      return of([]);
    }
    return this.rest
      .get(`articles/localized-versions?ids=${articleIds}`).pipe(
      map((articleData: any) => {
        return articleData.data;
      }));
  }

  getArticleRevisionById(revisionId) {
    return this.rest
      .get('articles/versions/' + revisionId).pipe(
      map((revisionData: any) => {
        return deserializeRevisionBody(revisionData.data);
      }));
  }

  createArticle(payload) {
    const bodyModel = processArticleBody(payload.body);
    const parsedPayload = { ...payload, body: JSON.stringify(bodyModel) };
    return this.rest
      .post('articles', parsedPayload).pipe(
      map((articleData: any) => {
        return deserializeBody(articleData.data);
      }));
  }

  updateArticle(payload) {
    const bodyModel = processArticleBody(payload.body);
    const parsedPayload = { ...payload, body: JSON.stringify(bodyModel) };
    return this.rest
      .put('articles/' + payload.articleId, parsedPayload).pipe(
      map((articleData: any) => {
        return deserializeBody(articleData.data);
      }));
  }

  makeAutosave(payload) {
    const bodyModel = processArticleBody(payload.body);
    const parsedPayload = { ...payload, body: JSON.stringify(bodyModel) };
    delete parsedPayload.id;
    return this.rest.put(`articles/${payload.articleId}/autosave`, parsedPayload).pipe(
      map((articleData: any) => {
        return deserializeBody(articleData.data);
      })
    );
  }

  /**
   * Discard autosave revision for the article if it exists, NOOP otherwise
   * @param articleId id of the article for which we discard the autosave
   * @returns either the id of the revision deleted, or null if there is no autosave
   */
  discardAutosave(articleId): Observable<number> {
    return this.rest.delete(`articles/${articleId}/autosave`)
      .pipe(map(res => res.data));
  }


  deleteArticle(payload) {
    return this.rest
      .delete('articles/' + payload).pipe(
      map((articleData: any) => {
        return articleData.data;
      }));
  }

  unpublishArticle(articleId) {
    return this.rest.post(`articles/${articleId}/unpublish`).pipe(
      map((response: any) => response.message));
  }

  publishArticle(articleId, revisionId) {
    return this.rest.post(`articles/${articleId}/publish/${revisionId}`).pipe(
      map((response: any) => response));
  }

  scheduleArticle(articleId, revisionId) {
    return this.rest.post(`articles/${articleId}/schedule/${revisionId}`).pipe(
      map((response: any) => response));
  }

  unscheduleArticle(articleId) {
    return this.rest.post(`articles/${articleId}/unschedule`).pipe(
      map((response: any) => response.message));
  }

  getRelatedArticles({ revisionId, size, useAutomaticRelatedArticles }) {
    const type = useAutomaticRelatedArticles ? 'automatic' : 'manual';
    let requestPath = `articles/versions/${revisionId}/related?type=${type}`;
    if (size) {
      requestPath += `&size=${size}`;
    }
    return this.rest
      .get(requestPath).pipe(
      map((response: any) => response.data));
  }

  // TODO deprecate this method
  getArticleUrlPreview(params) {
    return this.rest
      .post('articles/url-preview/', params).pipe(
      map(response => response.data),
      map(data => data.length > 0 ? data[0].url : ''),);
  }

  /**
   * This method returns all candidate paths for an article based on routes for the page
   * on which the article is expected to appear. The most specific path is the first one
   * and it is used by default in the system
   * @param params Object containing basic article properties needed to generate preview
   * @returns list of ArticlePathPreview objects
   */
  getArticlePathPreviewData(params): Observable<ArticlePathPreview[]> {
    return this.rest.post('articles/url-preview/', params).pipe(map(response => response.data));
  }

  getArticlesByIds(articlesId) {
    if (articlesId.length < 1) {
      return of([]);
    }
    return this.rest.get(`articles?ids=${articlesId.join(',')}`).pipe(
      map((articleData: any) => articleData.data));
  }

  getReferencedArticleById(id) {
    const requestPath = `articles/${id}/basic-projection`;
    return this.rest
      .get(requestPath).pipe(
      map((articleData: any) => articleData.data));
  }

  getReferencedArticlesByIds(articlesId) {
    if (articlesId.length < 1) {
      return of([]);
    }

    const size = articlesId.length <= 200 ? articlesId.length : 200;

    return this.rest.get(`articles/basic-projection?ids=${articlesId.join(',')}&size=${size}`).pipe(
      map((articleData: any) => {
        return articleData.data;
      }));
  }

  getArticleRevisionUrlPreview(revisionId) {
    return this.rest.post(`articles/url-preview/${revisionId}`).pipe(map(({ data }) => data.length > 0 ? data[0].url : ''));
  }

  generateArticleSummary(payload) {
    return this.rest.post(`gaia/summarize`, payload).pipe(map((response) => response.data));
  }

  generateArticlePreflight(payload) {
    return this.rest.post(`gaia/preflight`, payload).pipe(map((response) => response.data.preflight));
  }

  generateArticleDraft(payload) {
    return this.rest.post(`gaia/generate`, payload).pipe(map((response) => response.data));
  }

  translateArticle(id, localeId) {
    const payload = {
      entityType: 'article',
      entityId: id,
      localeId,
    };
    return this.rest
      .post('gaia/translate', payload).pipe(
      map((response: any) => deserializeRevisionBody(response.data)));
  }

}

function deserializeBody(articleData) {
  if (!articleData) {
    throw new Error('No article data');
  }
  const autosavedRevision = articleData.autosavedRevision && deserializeRevisionBody(articleData.autosavedRevision) || null;
  return {
    ...articleData,
    autosavedRevision,
    latestRevision: deserializeRevisionBody(articleData.latestRevision),
  };
}

function deserializeRevisionBody(revision) {
  try {
    return { ...revision, body: JSON.parse(revision.body) };
  } catch (error) {
    console.error('Failed to deserialize revision body!', { error, revision });
    return revision;
  }
}

// This function is checking if article body is empty. If it is, it returns content structure as an empty array.
function processArticleBody(bodyPayload) {
  const isArticleBodyEmpty: boolean =
    bodyPayload?.contentStructure?.length === 1 &&
    bodyPayload?.contentStructure[0]?.innerText === '' &&
    bodyPayload?.contentStructure[0]?.childNodes?.length === 0;

  const contentStructure = isArticleBodyEmpty ? [] : bodyPayload.contentStructure;
  const bodyModel = { ...bodyPayload, contentStructure };
  delete bodyModel.bodyHtml;
  delete bodyModel.referencedContent;
  return bodyModel;
}
