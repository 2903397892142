export function convertStraightToCurlyQuotes(text) {
  // open singles
  text = text.replace(
    /(^&#39;)|((([\s{\[\(>]&#39;)|([\s>]"&#39;))(?!([^<])*?>)(?!<script[^>]*?>)(?![^<]*?<\/script>|$))/gi,
    function ($1$2) {
      return $1$2.replace('&#39;', '&lsquo;');
    }
  );

  // closing singles + apostrophes
  text = text.replace(/&#39;(?!([^<])*?>)(?!<script[^>]*?>)(?![^<]*?<\/script>|$)/gi, '&rsquo;');

  // open doubles
  text = text.replace(
    /(^&quot;)|((([\s{\[\(>]&quot;)|([\s>]'"))(?!([^<])*?>)(?!<script[^>]*?>)(?![^<]*?<\/script>|$))/gi,
    function ($1$2) {
      return $1$2.replace('&quot;', '&ldquo;');
    }
  );

  // closing doubles
  text = text.replace(/&quot;(?!([^<])*?>)(?!<script[^>]*?>)(?![^<]*?<\/script>|$)/g, '&rdquo;');

  return text;
}
