import { Component, OnInit, Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ModalsService } from '../modals/modals.service';
import { GridOptions } from '../images-view-grid/grid-options.model';
import { ImagesService } from '../../core/api/images/images.service';
import { AccountSettingsService } from '../../core/api/account-settings/accounts-settings.service';
import { addUILocale } from '../../gpp-shared/i18n-utilities/i18n-utilities';
import { DomSanitizer } from '@angular/platform-browser';

interface PreviewImageSource {
  thumbnail?: string;
  url?: string;
  previewImage?: string;
  id?: string;
}
@Component({
  selector: 'gd-promo-image',
  templateUrl: './promo-image.component.html',
  styleUrls: ['./promo-image.component.scss'],
})
export class PromoImageComponent implements OnInit {
  selectedImage = null;
  selectedIcon = false;
  isImageSmallDimensions = false;
  previewImageSrc: PreviewImageSource = {};

  @Input() formControl;
  @Input() promoType;
  // TODO fix the case for icon usage - it does not get disabled
  @Input() hasActionPermission = true;
  @Input() sectionType = '';
  @Input() customLabel: 'Icon' | 'Image' = 'Icon';
  // TODO improve this so that any rendition can be chosen
  @Input() preferredRendition: 'default' | 'gm_preview' = null;

  gridOptions: GridOptions = {
    viewAs: 'embedded',
    enableSelection: true,
    multipleSelection: false,
    showActionControls: false,
    showUploadActionControls: false,
    showProgressOverlay: true,
    showProcessedIcon: false,
    thumbnailDraggable: false,
  };
  mediaBaseUrl = this.accountSettingsService.getMediaBaseUrl();

  constructor(
    private modalsService: ModalsService,
    private imagesService: ImagesService,
    private accountSettingsService: AccountSettingsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.formControl && this.formControl.value) {
      this.validateImageData().then(() => {
        this.selectedImage = this.formControl.value;
        this.selectedIcon = this.promoType === 'icon' ? true : false;
        this.previewImageSrc = this.selectedImage;
      }).catch(() => this.selectedIcon = null);
    }

    this.formControl.valueChanges.subscribe(imageValue => {
      if (imageValue) {
        this.validateImageData().then(() => {
          this.selectedImage = { ...this.formControl.value };
          this.selectedIcon = this.promoType === 'icon' ? true : false;

        if (!this.previewImageSrc['thumbnail']) {
          this.previewImageSrc = this.selectedImage;
        }

        if (this.promoType === 'image' && this.previewImageSrc['id'] && this.previewImageSrc['id'] !== this.selectedImage.id) {
          this.previewImageSrc = this.selectedImage;
        }
        }).catch(() => this.selectedIcon = null);;
      }

      // provide a way for resetting the preview and selected image on form control value change
      if(!imageValue?.id) {
        this.selectedImage = null;
        this.previewImageSrc = {};
      }
    });
  }

  selectImage(type) {
    if (!this.hasActionPermission) {
      return;
    }
    const sub =  this.openImageSelectionModal({ multipleSelection: false })
    .pipe(
      filter(event => event && event['images'])
    )
    .subscribe((event: any) => {
     const imageData = { ...event.images[0] };
     const image = imageData.image || imageData;
     this.isImageSmallDimensions = image.isImageSmallDimensions;
        if (type === 'icon') {
          let iconURL = imageData.formats && imageData.formats['150x100'];
          if (!iconURL) {
            const imageKey = imageData.formats.find(
              ({ width, height }) => height === '100' && width === '150'
            ).key;
            iconURL = this.imagesService.generateImageUrl(imageKey);
          }

          this.previewImageSrc =  {
            thumbnail: imageData.listThumbnail,
            id: image.id,
            url: iconURL
          };

          this.formControl.patchValue({ id: image.id, url: iconURL, path: iconURL.replace(this.mediaBaseUrl, '') });
          this.formControl.markAsDirty();

          this.selectedIcon = true;

          return sub.unsubscribe();
        }

        let path = null;
        let imageURL = imageData.formats && imageData.formats['400x260'];
        if (!imageURL) {
          path = imageData.formats.find(({ width, height }) => {
            if(this.preferredRendition === 'gm_preview') {
              return height === '633' && width === '950';
            }
            return height === '260' && width === '400';
          }).key;
          imageURL = this.imagesService.generateImageUrl(path);
        }

        const sanitizedUrl = imageData.dataURL ? this.sanitizer.bypassSecurityTrustUrl(imageData.dataURL) : imageData.dataURL;
        this.previewImageSrc =  {
          thumbnail: imageData.thumbnail,
          id: image.id,
          url: imageURL,
          previewImage: imageData.previewImage || sanitizedUrl
        };

        this.selectedImage = imageData.image || imageData;
        this.formControl.setValue({ id: image.id, url: imageURL, path: path || imageURL.replace(this.mediaBaseUrl, '') });
        this.formControl.markAsDirty();
        return sub.unsubscribe();
      });
  }

  openImageSelectionModal(extraGridOptions?, selectedImages?) {
    return this.modalsService.embedImage({
      dialogOptions: { showSearch: true },
      gridOptions: { ...this.gridOptions, ...extraGridOptions },
      uploadConfig: { multiUpload: false },
      selectedImages: selectedImages || [],
      width: '960px',
      height: 'auto',
    });
  }

  handleEditorContentChange(change) {
    this.formControl.patchValue(change || '');
    this.formControl.markAsDirty();
  }

  removeImage(type) {
    this.formControl.markAsDirty();
    this.previewImageSrc = null;
    if (type === 'icon') {
      this.formControl.setValue(null);
      this.selectedIcon = false;
      return;
    }
    this.formControl.setValue(null);
    this.selectedImage = null;
  }

  async validateImageData() {
    return new Promise<void>((resolve, reject) => {
      this.imagesService.getImage(this.formControl.value.id).then((image) => {
      image = this.imagesService.processImage(image);
      this.isImageSmallDimensions = image.isImageSmallDimensions;
      const imageFormat = this.resolveImageFormat();
        if (image.formats && image.formats[imageFormat]) {
          this.formControl.value.url = image.formats[imageFormat];
          return resolve();
        }
        const imageKey = image.formats.find(({ width, height }) => imageFormat === width + 'x' + height).key;
        this.formControl.value.url = this.imagesService.generateImageUrl(imageKey);
        return resolve();
      }).catch(() => {
        if (this.formControl.value.url) {
          return resolve();
        }
        reject();
      });
    });
  }

  resolveImageFormat() {
    if (this.preferredRendition === 'gm_preview') {
      return "950x633";
    }

    // default
    return this.promoType === 'icon' ? '150x100' : '400x260';
  }

  getRemoveBtnLabel() {
    if(this.customLabel === 'Icon') {
      return $localize`Remove Icon`;
    }
    return $localize`Remove Image`;
  }

  getChangeBtnLabel() {
    if(this.selectedIcon && this.customLabel === 'Icon') {
      return $localize`Change Icon`;
    }
    if(!this.selectedIcon && this.customLabel === 'Icon') {
      return $localize`Select Icon`;
    }
    if(this.selectedIcon && this.customLabel === 'Image') {
      return $localize`Change Image`;
    }
    if(!this.selectedIcon && this.customLabel === 'Image') {
      return $localize`Select Image`;
    }
  }


  navigateToItem() {
    const url = '/media/images/' + this.selectedImage.id;
    window.open(addUILocale(url), '_blank');
  }
}
