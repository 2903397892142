import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'gd-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss']
})

export class SystemNotificationsComponent {
  public message: string;
  public label: string;

  constructor(public dialogRef: MatDialogRef<SystemNotificationsComponent>) { }

  public close() {
    this.dialogRef.close();
  }
}
