<mat-sidenav-container class="{{themeManager.getTheme() | async}}">

  <mat-sidenav class="sidenav"
               [opened]="sidenavOpen"
               [mode]="sidenavMode"
               [disableClose]="true"
               gdIconSidenav
               (collapsedChange)="sidenavIsCollapsed = $event"
               #sidenav>
    <gd-sidenav [smallDisplay]="isSmallDisplay"></gd-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <gd-toolbar [sidenav]="sidenav"
                [sectionHasFloatingTabHeader]="sectionHasFloatingTabHeader">
    </gd-toolbar>
    <div class="main-container"
         id="main-container"
         [class.--sidenav-inactive]="sidenavOpen === false"
         [class.--sidenav-collapsed]="sidenavIsCollapsed === true"
         [class.--sidenav-opened]="sidenavIsCollapsed === false"
         [class.--has-floating-header]="sectionHasFloatingTabHeader"
         #scrollContainer>
      <gd-ui-section-title></gd-ui-section-title>
      <router-outlet (activate)="onActivate($event, scrollContainer)">
      </router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
