
import {debounceTime} from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { Subscription, combineLatest } from 'rxjs';
import { getBrightcoveSettings } from '../../../core/store/account-settings/account-settings.reducer';
import { ProxyService } from '../../../core/api/proxy/proxy.service';
import { GlideContentActionBusService } from '../../../core/body-content/glide-content-actions-bus.service';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-embed-brightcove-dialog',
  templateUrl: './embed-brightcove-dialog.component.html',
  styleUrls: ['./embed-brightcove-dialog.component.scss']
})
export class EmbedBrightcoveDialogComponent implements OnInit, OnDestroy {

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: [''],
    src: [''],
    description: [''],
    autoplay: [false],
    height: [''],
    width: ['']
  });

  dialogInputFormData = {
    id : '',
    name: '',
    description: '',
    src: '',
    height: '',
    width: ''
  };


  blockId;
  itemDetails = null;
  componentSub: Subscription = new Subscription();
  patchedValue;
  validYouTubeId = null;
  setEditData = false;
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  get idControl() {
    return this.embeddableForm.get('id');
  }

  get srcControl() {
    return this.embeddableForm.get('src');
  }

  get nameControl() {
    return this.embeddableForm.get('name');
  }

  get descriptionControl() {
    return this.embeddableForm.get('description');
  }

  get brightcoveAutoplayControl() {
    return this.embeddableForm.get('autoplay');
  }

  get brightcoveHeightControl() {
    return this.embeddableForm.get('height');
  }

  get brightcoveWidthControl() {
    return this.embeddableForm.get('width');
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedBrightcoveDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private actionBus: GlideContentActionBusService,
    private proxyService: ProxyService,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    const videoIframe: any = document.getElementById('video-iframe');
    this.componentSub.add(
      combineLatest([
        this.idControl.valueChanges.pipe(debounceTime(200)),
        this.store.select(getBrightcoveSettings)
      ]).subscribe(([id, settings]) => {
        if (!settings) {
          return this.idControl.setErrors({ 'invalidBrightcoveSettings': true });
        }

        const validPatchedUrl = (this.validYouTubeId === this.idControl.value) && this.patchedValue;
        const emptyFormFlag = !this.idControl.value && this.idControl.hasError('required') && !this.itemDetails;
        if (validPatchedUrl || emptyFormFlag || this.idControl.hasError('invalidVideoId')) {
          return;
        }

        videoIframe.src = '';
        id = (id || '').trim();
        if (!id) {
          this.itemDetails = null;
          this.patchValue();
          return this.idControl.setErrors({ 'required': true });
        }

        const glideContentType = this.dialogInputData.glideContentType;
        this.actionBus.setContentLoading(glideContentType, true);
        this.getBrightcoveVideoData(id, settings).then(data => {
          this.actionBus.setContentLoading(glideContentType, false);
          if (!data || !data.id) {
            this.itemDetails = null;
            this.patchValue();
            return this.idControl.setErrors({ 'invalidVideoId': true });
          }

          this.itemDetails = data;
          const { accountId, previewPlayerId } = settings;
          videoIframe.src = '//players.brightcove.net/' + accountId + '/'
            + previewPlayerId + '_default/index.html?videoId=' + data.id;
          this.idControl.setErrors(null);
          this.validYouTubeId = data.id;
          this.patchValue();
        });
      })
    );

    this.embeddableForm.patchValue(this.dialogInputData);
    this.blockId = this.dialogInputData.blockId;
  }

  getBrightcoveVideoData(videoId, settings) {
    const { accountId, policyKey } = settings;
    const url = 'https://edge.api.brightcove.com/playback/v1/accounts/' + accountId + '/videos/' + videoId;
    const headers = ['Authorization', 'BCOV-Policy ' + policyKey];

    return this.proxyService.get({ url, headers }).toPromise();
  }

  save() {
    const formModel = {
      id: this.idControl.value,
      name: this.nameControl.value ? this.nameControl.value : this.dialogInputFormData['name'],
      description: this.descriptionControl.value ? this.descriptionControl.value : this.dialogInputFormData['description'],
      src: this.srcControl.value,
      autoplay: this.brightcoveAutoplayControl.value,
      height: this.brightcoveHeightControl.value,
      width: this.brightcoveWidthControl.value
    };

    const contentNodeData = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel
    };
    this.dialogRef.close(contentNodeData);
  }

  ngOnDestroy(): void {
    this.componentSub.unsubscribe();
  }

  patchValue() {
    if (!this.setEditData && this.dialogInputData.id) {
      this.embeddableForm.patchValue(this.dialogInputData);
      this.dialogInputFormData = {...this.itemDetails};
      this.setEditData = true;
      this.patchedValue = true;
      return;
    }
    if (this.itemDetails) {
      const rendition = this.itemDetails.sources.find(el => el.height && el.width)
      this.dialogInputFormData = {
        id: this.itemDetails.id,
        name: this.itemDetails.name,
        description: this.itemDetails.description,
        src: this.itemDetails.thumbnail,
        height: rendition.height,
        width: rendition.width
      };
      this.embeddableForm.patchValue(this.dialogInputFormData);
      this.patchedValue = true;
      return;
    }

    this.embeddableForm.patchValue({
      id: this.idControl.value ? this.idControl.value : '',
      name: null,
      description: null,
      src: null,
      autoplay: false
    });
    this.dialogInputFormData = {
      ...this.dialogInputData.id,
      name: '',
      description: ''
    };
    this.patchedValue = false;
  }


}
