
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { PageTemplate } from '../../store/templates/templates.model';

@Injectable({
  providedIn: 'root'
})
export class PageTemplatesService {

  constructor(private rest: RestService) { }

  getTemplates() {
    return this.rest
      .get('page-templates').pipe(
      map((templatesData: any) => templatesData.data));
  }

  createTemplate(newTemplate) {
    const createPayload = { ...newTemplate };
    delete createPayload.publishedRevision;
    return this.rest
      .post('page-templates', createPayload).pipe(
      map((response: any) => response.data));
  }

  updateTemplate(template: PageTemplate) {
    const updatePayload = { ...template };
    delete updatePayload.publishedRevision;
    return this.rest
      .put('page-templates/' + template.id, template).pipe(
      map((response: any) => response.data));
  }

  publishTemplate(id, revisionId) {
    return this.rest.post(`page-templates/${id}/publish/${revisionId}`).pipe(
      map((response: any) => response.data));
  }

  unpublishTemplate(id) {
    return this.rest.post(`page-templates/${id}/unpublish`).pipe(
      map((response: any) => response.data));
  }

  deleteTemplate(id) {
    return this.rest
      .delete('page-templates/' + id);
  }

}
