import { Route } from './route.model';
import {
  GET_ROUTES, GET_ROUTES_SUCCESS,
  CREATE_ROUTE_SUCCESS,
  UPDATE_ROUTE_SUCCESS, UPDATE_ROUTE, SET_ROUTE_FILTER, CREATE_ROUTE
} from './route.actions';
import { createSelector } from 'reselect';
import { DELETE_ROUTE, DELETE_ROUTE_SUCCESS, UPDATE_ROUTE_POSITION } from './route.actions';
import { UnsafeAction } from '../unsafe-action.interface';
export interface RoutesState {
  loaded: boolean;
  loading: boolean;
  routes: { [id: number]: Route };
  filter?: string;
}

const initialState: RoutesState = {
  loaded: false,
  loading: false,
  routes: {},
  filter: ''
};

export function routeReducer(state: RoutesState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_ROUTES:
      return { ...state, loading: true };

    case GET_ROUTES_SUCCESS: {
      const routesData = action.payload;
      const normalizedRoutes = routesData.reduce((acc, r) => {
        delete r.parentId;
        delete r.pageId;
        delete r.active;
        acc[r.id] = r;
        return acc;
      }, {});
      return { ...state, loaded: true, loading: false, routes: normalizedRoutes };
    }

    case CREATE_ROUTE_SUCCESS: {
      const newRoute: Route = action.payload;
      const updatedRouteSiblings = Object.values(state.routes)
        .filter(r => r.position >= newRoute.position)
        .map(r => ({ ...r, position: r.position + 1 }))
        .reduce((acc, r) => ({ ...acc, [r.id]: r }), {});
      return {
        ...state, routes: {
          ...state.routes,
          [newRoute.id]: newRoute,
          ...updatedRouteSiblings
        }
      };
    }

    case UPDATE_ROUTE_SUCCESS: {
      return { ...state, loaded: true, loading: false, routes: getUpdatedRoutes(action.payload, state) };
    }

    case UPDATE_ROUTE_POSITION: {
      const { routeId, currentIndex, previousIndex } = action.payload;
      const updatedRoute = {
        ...state.routes[routeId],
        position: currentIndex
      };
      const smallestPosition = currentIndex < previousIndex ? currentIndex : previousIndex;
      const largestPosition = currentIndex > previousIndex ? currentIndex : previousIndex;
      const shiftSiblingsLeft = currentIndex > previousIndex;

      const updatedSiblings = Object.values(state.routes)
        .filter(route => route.id !== routeId && route.position >= smallestPosition
          && route.position <= largestPosition)
        .map(route => {
          const newPosition = shiftSiblingsLeft ? route.position - 1 : route.position + 1;
          return { ...route, position: newPosition };
        })
        .reduce((acc, route) => {
          acc[route.id] = route;
          return acc;
        }, {});

      const updatedRoutes = { ...state.routes, ...updatedSiblings, [updatedRoute.id]: updatedRoute };

      return {
        ...state,
        routes: updatedRoutes
      };
    }

    case DELETE_ROUTE: {
      const routeToDelete = action.payload;
      const updatedRouteSiblings = Object.values(state.routes)
        .filter(r => r.position > routeToDelete.position)
        .map(r => ({ ...r, position: r.position - 1 }))
        .reduce((acc, r) => ({ ...acc, [r.id]: r }), {});
      const newRoutes = {
        ...state.routes,
        ...updatedRouteSiblings
      };
      delete newRoutes[routeToDelete.id];
      return {
        ...state,
        loading: true,
        routes: newRoutes
      };
    }

    case DELETE_ROUTE_SUCCESS: {
      return { ...state, loading: false };
    }

    case SET_ROUTE_FILTER: {
      const filter: string = action.payload;
      return { ...state, filter };
    }

    case UPDATE_ROUTE:
    case CREATE_ROUTE:
      return { ...state, loading: true };

    default:
      return state;
  }

}

function getUpdatedRoutes(data, state) {
  const route = {};
  route[data.id] = data;
  let routes = state.routes;
  routes = Object.assign({}, routes, route);
  const routeAfter = routes[data.id];
  return routes;
}

export function getURLPattern(route, routes): String {
  let pattern = route.pathPattern;
  while (route && route.parentId) {
    route = routes.find(_route => _route.id === route.parentId);
    if (route && route.pathPattern) {
      pattern = route.pathPattern + '/' + pattern;
    }
  }
  return pattern;
}

export const getRoutesState = (state) => state.routes;
export const getRoutesListIsLoading = createSelector((state: any) => state.routes.loading, isLoading => isLoading);


export const getRoutes = createSelector(getRoutesState, (routesState) => {
  return routesState.routes || {};
});

export const getRoutesList = createSelector(getRoutes, routesMap => {
  const routesList = Object.keys(routesMap)
    .map(rId => routesMap[rId])
    .map(r => ({ id: r.id, name: r.name, position: r.position, pathPattern: r.pathPattern }));
  routesList.sort((r1, r2) => r1.position - r2.position);
  return routesList;
});

export const getFullRoutes = createSelector(getRoutes, (routesData) => {
  return Object.keys(routesData)
    .map(rId => routesData[rId])
    .map(route => getURLPattern(route, routesData));
});

export const getFilteredRoutesList =  createSelector(getRoutesState, getRoutesList , (state, routesList) => {
  const filter = (state.filter || '').toLowerCase();
  if (!filter) {
   return routesList;
  }
 return routesList.filter(item => item.name.toLowerCase().includes(filter));
});

export const getRoutesByIds = ids => createSelector(getRoutes, routesMap => ids.map(id => routesMap[id]));
