import { get } from 'lodash-es';
import { UnsafeAction } from '../store/unsafe-action.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import {
  getActiveRevisionContentBlocks,
  getReferencedContent,
  UPDATE_ARTICLE_CONTENT_NODE,
  GET_ARTICLE_COMPLETE,
  CREATE_ARTICLE_COMPLETE,
  SWITCH_ACTIVE_REVISION_COMPLETE,
  UPDATE_REFERENCED_CONTENT,
  UNPUBLISH_ARTICLE_COMPLETE,
  SET_ARTICLE_TYPE,
  CREATE_NEW_EMPTY_ARTICLE,
  ARTICLE_PAGE_TAB_CHANGE,
  ChangeArticlePageTabAction,
  UpdateArticleBodyAction,
  COPY_ARTICLE_CONTENT_NODES,
  getContentNodesArray,
  CopyArticleContentNodesAction,
  RemoveArticleContentNodeAction,
  UpdateArticleContentNodeAction,
  getContentNode,
  CreateArticleContentNodeAction,
  getWorkingRevision,
  LOCALIZE_ARTICLE_SUCCESS,
  getActiveArticleContentLocale,
} from '../store';
import { AppState } from '../store/app-reducer';
import { map } from 'rxjs/operators';
import { ContentModel } from '../store/article/article-content.model';
import {
  BodyContentUpdateModel,
  EditorBodyContentService,
} from './editor-body-content.service.interface';
import { getArticleQuickViewContentNodes } from '../store/article-quick-view/article-quick-view.reducers';

/**
 * ArticleContentService fetches and processes article body content
 * based on the current state of active article.
 *
 * It is primarily used by article body editor components as a means of
 * getting content model and structure
 */
@Injectable({
  providedIn: 'root',
})
export class ArticleContentService implements EditorBodyContentService {
  constructor(private store: Store<AppState>, private actions$: Actions) {}

  updateContent(contentModel: ContentModel) {
    this.store.dispatch(new UpdateArticleBodyAction(contentModel));
  }

  getReferencedContent() {
    return this.store.select(getReferencedContent);
  }

  // TODO try and form this as ngrx effect, starting from the action, then joining in the content
  getContent(): Observable<BodyContentUpdateModel> {
    return combineLatest([this.store.select(getActiveRevisionContentBlocks), this.actions$]).pipe(
      // NOTE: DO NOT add CREATE_ARTICLE_CONTENT_NODE action here, it will cause issues
      filter(([, action]) => {
        // These are all the actions which should result in article content updates
        const propagateOnAction = [
          CREATE_NEW_EMPTY_ARTICLE,
          UPDATE_ARTICLE_CONTENT_NODE,
          GET_ARTICLE_COMPLETE,
          LOCALIZE_ARTICLE_SUCCESS,
          CREATE_ARTICLE_COMPLETE,
          SWITCH_ACTIVE_REVISION_COMPLETE,
          COPY_ARTICLE_CONTENT_NODES,
          UPDATE_REFERENCED_CONTENT,
          UNPUBLISH_ARTICLE_COMPLETE,
          SET_ARTICLE_TYPE,
          // we need this action for widget preview to load in DOM after tab change
          ARTICLE_PAGE_TAB_CHANGE,
        ];
        return propagateOnAction.includes(action.type);
      }),
      // NOTE this causes issues with referenced content, maybe extract that in a separate stream
      // never fire two events for the exact same action
      distinctUntilChanged(([, a1], [, a2]) => a1 === a2),
      map(([activeRevisionArticleContent, action]) => {
        // Merge article body content and referenced content data
        return {
          content: activeRevisionArticleContent,
          reinitializeContent: this.isContentInitAction(action),
          skipEditorUpdate: false,
          resetEditorHasBeenInteractedWith: this.isInteractionResetAction(action),
        };
      }),
      filter((contentUpdate) => !contentUpdate.skipEditorUpdate)
    );
  }

  isInteractionResetAction(action: UnsafeAction) {
    const editorInteractionResetActions = [
      GET_ARTICLE_COMPLETE,
      CREATE_NEW_EMPTY_ARTICLE,
      LOCALIZE_ARTICLE_SUCCESS,
      SWITCH_ACTIVE_REVISION_COMPLETE,
      ARTICLE_PAGE_TAB_CHANGE
    ];
    return editorInteractionResetActions.includes(action.type);
  }

  /**
   * These actions basically mean that the article content
   * is going to be reinitialized - so, a new content model is
   * loaded and the fieldControl gets set to pristine
   */
  private isContentInitAction(action: UnsafeAction) {
    const contentInitActions = [
      CREATE_NEW_EMPTY_ARTICLE,
      GET_ARTICLE_COMPLETE,
      LOCALIZE_ARTICLE_SUCCESS,
      CREATE_ARTICLE_COMPLETE,
    ];
    return contentInitActions.includes(action.type);
  }

  getBodyContent() {
    return this.store
      .select(getWorkingRevision)
      .pipe(map((workingRevision) => get(workingRevision, 'body', {})));
  }

  getContentNodesArray() {
    return this.store.select(getContentNodesArray);
  }

  getContentNode(nodeId) {
    return this.store.select(getContentNode(nodeId));
  }

  copyContentNodes(data: { contentNodes: any[]; referencedContent: any }) {
    this.store.dispatch(new CopyArticleContentNodesAction(data));
  }

  removeContentNode(nodeId) {
    this.store.dispatch(new RemoveArticleContentNodeAction(nodeId));
  }

  updateContentNode(nodeData) {
    this.store.dispatch(new UpdateArticleContentNodeAction(nodeData));
  }

  createContentNode(nodeId, nodeData) {
    this.store.dispatch(new CreateArticleContentNodeAction({ nodeId, nodeData }));
  }

  getValidateLoadedImagePreviews(): Observable<boolean> {
    return this.actions$.pipe(
      ofType(ARTICLE_PAGE_TAB_CHANGE),
      map((action: ChangeArticlePageTabAction) => {
        return action.activeTab === 'CREATE';
      }),
      filter((doValidate) => doValidate)
    );
  }

  getContentLocale() {
    return this.store.select(getActiveArticleContentLocale);
  }

  getQuickViewContentNode(nodeId) {
    return this.store.select(getArticleQuickViewContentNodes(nodeId));
  }

}
