import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

declare const FB: any;

@Injectable()
export class FacebookService {

  constructor() { }

  isFacebookPostUrlValid(url: string): Observable<any> {
    const access_token = environment.facebookAppId + '|' + environment.facebookAppSecret;
    return new Observable(observer => {
      if (!url) {
        observer.next(null);
        return observer.complete();
      }
      const params = { 'id': url, 'access_token': access_token };
      FB.api('/', params, response => {
          if (!response || response.error) {
            return observer.next(null);
          }
          let fbPostId = null;
          const matchItems = url.match(/\/(\d+)?[\/?]/g);
            if (!matchItems || !matchItems.length) {
              return observer.next({ fbPostId: null });
            }
            fbPostId = matchItems[matchItems.length - 1];
            fbPostId = fbPostId.substring(1, fbPostId.length - 1);
            const apiUrl = '/' + fbPostId + '?access_token=' + access_token + '&fields=picture';
            FB.api(apiUrl, r => {
                let hasThumbnail = false;
                if (r && !r.error) {
                  hasThumbnail = true;
                }
                fbPostId = hasThumbnail ? fbPostId : null;
                observer.next({ fbPostId });
                return observer.complete();
              }
          );
        }
    );
    });
}
}
