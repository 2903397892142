import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { RestService } from '../rest.service';
import { PassthroughCacheFactoryService } from '../cache/api-cache-factory.service';

@Injectable()
export class VerifySettingsService {

  /**
   * This cache is used instead of simple get request, it will first try to
   * get the requested URL from ongoing requests buffer, then from cache,
   * and finally from the API by using the passed apiClient
   */
    passthroughCache = this.passthroughCacheFactory.createCache({
      domain: 'verify-settings',
      apiClient: this.rest,
      // cache verify settings for 5 minutes
      ttl: 5 * 60 * 1000
    });

  constructor(
    private rest: RestService,
    private passthroughCacheFactory: PassthroughCacheFactoryService,
  ) {}

  getVerifySettings() {
    return this.passthroughCache.getData('verify/settings')
      .pipe(map((response) => response.data));
  }

  updateVerifySettings(payload) {
    return this.rest.put('verify/settings', payload)
      .pipe(
        tap(() => this.passthroughCache.clear()),
        map(response => response.data)
      );
  }
}
