import { ContentModel, ContentNode, EmbeddedType } from './../article/article-content.model';
import { UnsafeAction } from '../unsafe-action.interface';
import { resolveArticleBodyImage } from '../../api/images/images.service'
import {
  COPY_POST_CONTENT_NODES,
  CREATE_POST_CONTENT_NODE,
  LOAD_POST_BODY_INTO_EDITOR,
  MANAGE_POST_EDITOR_EMBEDDED_IMAGE_VISIBILITY,
  REMOVE_POST_CONTENT_NODE,
  UPDATE_POST_BODY,
  UPDATE_POST_BODY_REFERENCED_CONTENT,
  UPDATE_POST_CONTENT_NODE
} from './posts.actions';
import { prepareReferencedContent } from '../utility-functions/prepare-referenced-content';

export function postContentReducer(state: ContentModel = null, action: UnsafeAction): ContentModel {
  // this updates active latest revision article body each time the change in body happens
  switch (action.type) {
    case LOAD_POST_BODY_INTO_EDITOR:
    case UPDATE_POST_BODY: {
      const postBody: ContentModel = action.payload;
      if (!postBody) {
        return state;
      }

      const contentStructure = postBody.contentStructure;
      const oldContentNodes = state.contentNodes || {};

      const contentNodes = createContentNodesObject(oldContentNodes, postBody.contentNodes);

      return {
        ...state,
        bodyHtml: postBody.bodyHtml || state.bodyHtml,
        contentStructure,
        contentNodes,
        referencedContent: state.referencedContent,
      };
    }

    case UPDATE_POST_CONTENT_NODE: {
      const targetBlockId = action.payload.blockId;
      const targetBlock: ContentNode = state.contentNodes[targetBlockId];

      if (!targetBlock) {
        return state;
      }

      const referencedContent = prepareReferencedContent(state.referencedContent, targetBlock);

      const updatedBlock = updateContentBlock(targetBlock, action.payload.updateData);
      return {
        ...state,
        contentNodes: {
          ...state.contentNodes,
          [targetBlockId]: updatedBlock
        },
        referencedContent
      };
    }

    case CREATE_POST_CONTENT_NODE: {
      const targetBlockId = action.payload.blockId || action.payload.nodeId;
      const nodeData = action.payload.updateData || action.payload.nodeData;

      const newContentNode = updateContentBlock({ id: targetBlockId }, nodeData);
      const referencedContent = prepareReferencedContent(state.referencedContent, newContentNode);

      return {
        ...state,
        contentNodes: {
          ...state.contentNodes,
          [targetBlockId]: newContentNode
        },
        referencedContent
      };
    }

    case COPY_POST_CONTENT_NODES: {
      // expect an array of content block nodes
      const targetBlocks = action.payload.contentNodes
        .reduce((acc, block) => {
          acc[block.id] = block;
          return acc;
        }, {});

      return {
        ...state,
        contentNodes: {
          ...state.contentNodes,
          ...targetBlocks
        },
      };
    }

    case REMOVE_POST_CONTENT_NODE: {
      const newState = {
        ...state,
        contentNodes: { ...state.contentNodes }
      };
      delete newState.contentNodes[action.payload];
      return newState;
    }

    case UPDATE_POST_BODY_REFERENCED_CONTENT: {
      const newState = {
        ...state,
        referencedContent: {
          ...state.referencedContent,
          ...action.payload
        }
      };
      return newState;
    }

    case MANAGE_POST_EDITOR_EMBEDDED_IMAGE_VISIBILITY: {
      const data = action.payload;
      const affectedNode = state.referencedContent[data.id];
      if (!affectedNode || affectedNode.dataId !== data.dataId) {
        // stop executing image checker
        clearTimeout(data.timeout);
        return state;
      }
      return {
        ...state,
        referencedContent: {
          ...state.referencedContent,
          [data.id]: { ...affectedNode, src: data.src || affectedNode.src, isNotLoaded: data.isNotLoaded }
        }
      };
    }

    default: {
      return state;
    }
  }

}

// TODO extract into util functions for content reducers
function updateContentBlock(targetBlock: any, data) {
  const src = getSrcByEmbeddedType(targetBlock.type, data);
  data = { ...data, dataId: data.id, dataUrl: data.url, src };
  // if present, assign the document block id as well
  if (data.elementId) {
    data.id = data.elementId;
  }
  // possible attributes of content node, set only if exist
  const attributes = ['dataId', 'dataUrl', 'src', 'name', 'title', 'caption', 'alt', 'credit',
    'sourceMeta', 'alignment', 'imageFormat', 'imageStyle', 'description', 'configuration', 'autoplay', 'colour', 'identifier', 'startingMarkerId', 'duration', 'durationMillis', 'thumbnails', 'height', 'width'];

  const contentNode: any = { ...targetBlock, description: data.description };
  attributes.filter(attr => ['caption', 'alt', 'credit'].includes(attr) || !!data[attr]).forEach(attr => {
    // note: value can be empty string but not undefined
    // fixes image credits on images uploaded from editor
    if (data[attr] !== undefined) {
      contentNode[attr] = data[attr];
    }
  });

  if (data.hasOwnProperty('autoplay')) {
    contentNode['autoplay'] = data.autoplay;
  }

  // publishedAt is treated separately to avoid saving this info accidentally for some other resources
  if (targetBlock.type === EmbeddedType.YouTube && data.publishedAt) {
    contentNode.publishedAt = data.publishedAt;
  }
  return contentNode;
}

// TODO extract into util functions for content reducers
function getSrcByEmbeddedType(type, data) {
  switch (type) {
    case EmbeddedType.Image:
      return resolveArticleBodyImage(data.image || data);
    case EmbeddedType.Gallery:
      return data.thumbnail || data.src || './assets/img/embeded-gallery-placeholder.png';
    case EmbeddedType.YouTube:
      return `https://img.youtube.com/vi/${data.id}/0.jpg`;
    case EmbeddedType.FacebookPost:
      return data.id ? `https://graph.facebook.com/${data.id}/picture` : './assets/img/facebook-logo-blue.png';
    case EmbeddedType.TwitterPost:
      return './assets/img/x-twitter-logo-black.png';
    case EmbeddedType.InstagramPost:
      return './assets/img/instagram-logo.svg';
    case EmbeddedType.Vimeo:
      return data.src || './assets/img/vimeo-logo-blue.png';
    case EmbeddedType.Brightcove:
      return data.src || './assets/img/brightcove-logo-color.png';
    case EmbeddedType.BrightcovePlaylist:
      return data.src || './assets/img/brightcove-logo-color.png';
    case EmbeddedType.SystemWidget:
      return data.id ? './assets/img/custom-icons/glideCustomIcon_system-widgets.svg' : './assets/img/custom-icons/glideCustomIcon_system-widgets.svg';
    case EmbeddedType.ThirdPartyWidget:
      return data.id ? './assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg' : './assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg';
    case EmbeddedType.File:
      return data.src || './assets/img/files/default.svg';
    case EmbeddedType.ImageURL:
      return data.src || data.url || './assets/img/embed-image-url-dark.svg';
    case EmbeddedType.TikTokPost:
      return './assets/img/tiktok-logo-dark.svg';
    case EmbeddedType.JWPlayerVideo:
    case EmbeddedType.JWPlayerPlaylist:
      return data.src || './assets/img/jw-logo-placeholder.svg';
    case EmbeddedType.DailymotionVideo:
    case EmbeddedType.DailymotionPlaylist:
      return data.src || './assets/dailymotion-logo.svg';
    case EmbeddedType.TwitchVideo:
    case EmbeddedType.TwitchStream:
      return data.src || './assets/img/twitch-icon.svg';
    default:
      return '';
  }
}

// TODO extract into util functions for content reducers
function createContentNodesObject(oldContentNodes, newContentNodes) {
  Object.keys(newContentNodes).forEach(key => {
    const overrideOldValues = newContentNodes[key].type !== EmbeddedType.Url
      || (newContentNodes[key].type === EmbeddedType.Url && newContentNodes[key].src);
    newContentNodes[key] = overrideOldValues ? { ...oldContentNodes[key], ...newContentNodes[key] } : { ...oldContentNodes[key] };
  });
  return newContentNodes;
}

