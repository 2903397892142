import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  GET_WORKFLOWS,
  GetWorkflowsSuccessAction,
  GetWorkflowsErrorAction,
  GET_ACTIVE_WORKFLOW,
  GetActiveWorkflowSuccessAction,
  WORKFLOW_ACTION_FAILED,
  GetActiveWorkflowErrorAction,
  SET_ACTIVE_WORKFLOW,
  SetActiveWorkflowSuccessAction,
  SetActiveWorkflowErrorAction,
} from './workflow.actions';
import { WorkflowsService } from '../../api/workflows/workflows.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UnsafeAction } from '../unsafe-action.interface';

@Injectable()
export class WorkflowsEffects {

  loadWorkflows$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_WORKFLOWS),
    mergeMap((action) => {
      return this.workflowsService.getWorkflows().pipe(
        map((workflows) => new GetWorkflowsSuccessAction(workflows)),
        catchError((e) => of(new GetWorkflowsErrorAction(e)))
      );
    })
  ));


  setActiveWorkflow$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SET_ACTIVE_WORKFLOW),
    mergeMap((action: UnsafeAction) => {
      return this.workflowsService.setActiveWorkflow(action.payload).pipe(
        map(() => new SetActiveWorkflowSuccessAction(action.payload)),
        tap(() => this.snackBar.open($localize`Active account changed.`, $localize`Close`)),
        catchError((e) => of(new SetActiveWorkflowErrorAction(e)))
      );
    })
  ));


  loadActiveWorkflow$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACTIVE_WORKFLOW),
    mergeMap((action) => {
      return this.workflowsService.getActiveWorkflow().pipe(
        map((activeWorkflow) => new GetActiveWorkflowSuccessAction(activeWorkflow)),
        catchError((errorResponse) => {
          // const rsponseBody = errorResponse.json();
          const responseBody = errorResponse;
          this.snackBar.open(responseBody.message, $localize`Close`);
          return of(new GetActiveWorkflowErrorAction(responseBody));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    public snackBar: MatSnackBar,
    private workflowsService: WorkflowsService
  ) {}
}
