import { UnsafeAction } from '../unsafe-action.interface';

export const GET_WORKFLOWS = '[Workflow] Get Workflows';
export class GetWorkflowsAction implements UnsafeAction {
  readonly type = GET_WORKFLOWS;
  constructor() { }
}

export const GET_WORKFLOWS_SUCCESS = '[Workflow] Get Workflows done';
export class GetWorkflowsSuccessAction implements UnsafeAction {
  readonly type = GET_WORKFLOWS_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_WORKFLOWS_ERROR = '[Workflow] Get Workflows error';
export class GetWorkflowsErrorAction implements UnsafeAction {
  readonly type = GET_WORKFLOWS_ERROR;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_WORKFLOW = '[Workflow] Get Active Workflows';
export class GetActiveWorkflowAction implements UnsafeAction {
  readonly type = GET_ACTIVE_WORKFLOW;
  constructor() { }
}

export const GET_ACTIVE_WORKFLOWS_SUCCES = '[Workflow] Get Active Workflows done';
export class GetActiveWorkflowSuccessAction implements UnsafeAction {
  readonly type = GET_ACTIVE_WORKFLOWS_SUCCES;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_WORKFLOWS_ERROR = '[Workflow] Get Active Workflows error';
export class GetActiveWorkflowErrorAction implements UnsafeAction {
  readonly type = GET_ACTIVE_WORKFLOWS_ERROR;
  constructor(public payload: any) { }
}

export const SET_ACTIVE_WORKFLOW = '[Workflow] Set Active Workflows';
export class SetActiveWorkflowAction implements UnsafeAction {
  readonly type = SET_ACTIVE_WORKFLOW;
  constructor(public payload: any) { }
}

export const SET_ACTIVE_WORKFLOWS_SUCCES = '[Workflow] Set Active Workflows done';
export class SetActiveWorkflowSuccessAction implements UnsafeAction {
  readonly type = SET_ACTIVE_WORKFLOWS_SUCCES;
  constructor(public payload: any) { }
}

export const SET_ACTIVE_WORKFLOWS_ERROR = '[Workflow] Set Active Workflows error';
export class SetActiveWorkflowErrorAction implements UnsafeAction {
  readonly type = SET_ACTIVE_WORKFLOWS_ERROR;
  constructor(public payload: any) { }
}

export const WORKFLOW_ACTION_FAILED = '[Workflow] Generic failure action';
export class WorkflowFailedAction implements UnsafeAction {
  readonly type = WORKFLOW_ACTION_FAILED;
  constructor(public payload: any) { }
}
