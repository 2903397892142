import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Inject,
  Optional,
  ViewChild,
  Injector,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AppState } from '../../core/store/app-reducer';
import { ImagesUploadService } from '../../core/api';
import {
  RemoveImageFromQueueAction,
  UploadStartedAction,
  UpdateQueuedImageMetaAction,
  UpdateQueuedImagesAction,
  ALL_IMAGES_UPLOADED,
  DiscardImageCropsChangesAction,
  SetImagesProcessingStateAction,
} from '../../core/store/images-upload/images-upload.actions';

import { getQueuedImagesState, queueBeingProcessed } from '../../core/store/images-upload/images-upload.reducer';
import { DropzoneComponent } from '../dropzone/dropzone.component';
import { ModalsService } from '../modals/modals.service';
import { ImageCropEditComponent } from '../image-crop/image-crop-edit/image-crop-edit.component';
import { Subscription, BehaviorSubject, filter, take, firstValueFrom } from 'rxjs';
import { GetUserConfigurationAction } from '../../core/store/images-configuration';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { Permissions } from '../../core/store/auth/permissions';
import { SetImagesLoadingFlagAction } from '../../core/store/images/images.actions';
import { MixPanelService } from '../../core/api/mixpanel/mixpanel.service';
import { ImageCropService } from '../image-crop/image-crop.service';
import { ExternalImageLibraryWidgetComponent } from '../external-image-library-widget/external-image-library-widget.component';
import { HttpClient } from '@angular/common/http';
import { pauseExecution } from '../shared-functions';
import { BidiService } from '../../core/i18n/bidi.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GettyIntegrationService } from '../../core/api/image-integrations/getty-integration.service';
import { ImageIntegrationService } from '../../core/api/image-integrations/image-integration.service.interface';
import { GenerateImageFormComponent } from '../generate-image-form/generate-image-form.component';

@Component({
  selector: 'gd-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [{ provide: MatDialogRef, useValue: ImageUploadComponent }],
})
export class ImageUploadComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() uploadConfig = <any>{};
  @Output() imageGeneratedUploadEmitter = new EventEmitter<boolean>();
  config = {
    viewAs: 'standalone',
    autoImport: false,
    multiUpload: true,
    embedModal: false
  };

  integrationService: ImageIntegrationService;
  queuedImages = [];
  firstQueuedImage = null;
  queuedImagesStateSub: Subscription;
  uploadStateSubscriptions: Subscription = new Subscription();
  queuedImages$ = new BehaviorSubject(<any>[]);

  hasEnqueuedImages = false;
  queueInProcessing = false;
  imagesProcessedSub: Subscription;

  imageToCrop = null;
  cropperActive = false;
  queueBeingProcessed$ = this.store.select(queueBeingProcessed);
  imageProcessFlag;
  imageData = null;

  gridConfig = {
    viewAs: 'standalone',
    showActionControls: false,
    showUploadActionControls: true,
    canRemoveFromList: true,
    showProgressOverlay: true,
    showProcessedIcon: true,
    thumbnailDraggable: false,
    useCropPreviewImage: true,
  };

  cropOptions = {
    autoCrop: true,
  };

  dzConfig = {
    multiUpload: true,
    embedModal: false,
  };

  uploadDisabled = false;
  hasGettyError: boolean = true;
  errorMessage: string;
  isGettyLoaded: boolean = false;

  hasUploadPermission = this.permissionService.hasPermission(Permissions.GM_IMAGE_WRITE);
  // keep old crops state if a user wants to discard changes
  cropsPreviousState;

  @Output() imagesProcessed = new EventEmitter();
  @Output() openImageUploadForm = new EventEmitter();
  @Input() generateImageTab = false;
  @Input() generateImagePage = false;
  @Input() usage;

  @ViewChild('dropzone') dropzone: DropzoneComponent;
  @ViewChild('imageCropper') imageCropper: ImageCropEditComponent;
  @ViewChild('generateImageForm') generateImageForm: GenerateImageFormComponent
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private store: Store<AppState>,
    public imagesUploadService: ImagesUploadService,
    private elRef: ElementRef,
    private actions$: Actions,
    private router: Router,
    private modalsService: ModalsService,
    private permissionService: PermissionService,
    private mixPanelService: MixPanelService,
    private cropService: ImageCropService,
    private bidiService: BidiService,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private gettyIntegrationService: GettyIntegrationService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogInputData: any
  ) {
    window.addEventListener('beforeunload', this.preventBrowserClose);
  }

  preventBrowserClose = e => {
    if (this.imageProcessFlag) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  get queuedImagesObs() {
    return this.queuedImages$.asObservable();
  }

  ngOnInit() {
    this.uploadStateSubscriptions.add(
      this.gettyIntegrationService
        .auth()
        .pipe(take(1))
        .subscribe((GettyIntegrationService) => {
          this.handleGettyButton(GettyIntegrationService)
        })
    );

    if (!this.hasUploadPermission) {
      return;
      // return this.router.navigate(['/media/images']);
    }
    this.config = { ...this.config, ...this.uploadConfig };
    if (this.config.viewAs === 'contained') {
      this.store.dispatch(new GetUserConfigurationAction());
    }

    if (this.dialogInputData) {
      this.config = { ...this.uploadConfig, ...this.dialogInputData.uploadConfig };
      this.gridConfig = {
        ...this.dialogInputData.gridOptions,
        ...this.gridConfig,
        showUploadActionControls: true,
        viewAs: this.config.viewAs,
        enableSelection: false,
      };

      this.dzConfig = { ...this.dzConfig, multiUpload: this.config.multiUpload, embedModal: this.config.embedModal };
    }

    this.queuedImagesStateSub = this.store
      .select(getQueuedImagesState)
      .subscribe(async queuedImages => {
        // REVERTED: when adding images for upload in articles/galleries, open metadata form for first image
        if (!this.queuedImages.length && queuedImages.length && this.config.viewAs === 'contained' && !!this.config.multiUpload) {
          this.triggerAddImageMeta(queuedImages[0]);
        }
        this.queuedImages = queuedImages;
        if (this.queuedImages.length) {
          // Store tmpUploadQueue in case some of images in list doesnt have thumbnail img
          let tempUploadQueue: any = this.queuedImages.map(async img => {
            return {
              id: img.id,
              thumbnail: await this.imagesUploadService.createImageThumbnail(img),
            };
          });

          tempUploadQueue = await Promise.all(tempUploadQueue);
          tempUploadQueue = tempUploadQueue.reduce(
            (acc, img: any) => ({
              ...acc,
              [img.id]: img.thumbnail,
            }),
            {}
          );

          this.imagesUploadService.setTempUploadQueue(tempUploadQueue);
        }

        if (queuedImages.length && !this.config.multiUpload) {
          this.firstQueuedImage = this.queuedImages[0];
        }

        this.handleUploadQueueUpdate();
      });
    this.uploadStateSubscriptions.add(this.queuedImagesStateSub);

    this.listenForImagesProcessedEvent();
    this.trackUploadProgress();
    this.uploadStateSubscriptions.add(
      this.queueBeingProcessed$.subscribe((imgProcessing) => (this.imageProcessFlag = imgProcessing))
    );

    this.mixPanelService.trackEvent('PageLoaded', {
      page_type: 'ImageUploadPage',
    });
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.classList.add(this.config.viewAs);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.config = { ...this.config, ...changes.uploadConfig.currentValue };
    }
  }

  removeImageFromQueue(image) {
    this.imageData = image;
    this.store.dispatch(new RemoveImageFromQueueAction(image.queueID));
  }

  async startUpload() {
    this.queueInProcessing = true;
    this.store.dispatch(new UploadStartedAction());
    this.cropService.resetCropParams();
  }

  listenForImagesProcessedEvent() {
    this.imagesProcessedSub = this.actions$
      .pipe(ofType(ALL_IMAGES_UPLOADED))
      .subscribe((action: any) => {
        // => If standalone page just redirect to images section
        // => Images View will properly reset queue state
        if (this.router.url.endsWith('upload') && this.config.viewAs === 'standalone') {
          setTimeout(() => {
            this.queueInProcessing = false;
            this.router.navigate(['/media/images']);
          }, 2000);
        }

        if (this.config.viewAs === 'contained' && this.config.autoImport) {
          setTimeout(() => {
            this.queueInProcessing = false;
            this.imagesProcessed.emit(action.payload);
          }, 3000);
        }
      });

    // Add subscription to group for easier disposal of subscriptions
    this.uploadStateSubscriptions.add(this.imagesProcessedSub);
  }

  trackUploadProgress() {
    // Get upload progress and queuedImages combines
    this.imagesUploadService.getUploadProgress().subscribe((uploadProgress: any) => {
      // Update upload progress
      const imageToUpdate = this.queuedImages.find(
        (img: any) => img.queueID === uploadProgress.image
      );
      if (imageToUpdate) {
        imageToUpdate['uploadProgress'] = uploadProgress.progress;
      }
    });
  }

  async handleUploadQueueUpdate() {
    // If all queued images are invalid then upload is disabled
    this.uploadDisabled = this.queuedImages.every(img => img.invalid);
    this.hasEnqueuedImages = this.queuedImages.length !== 0;
    this.queuedImages$.next(this.queuedImages);

    const imagesWithoutThumbnail = this.queuedImages.filter(
      (img: any) => !img.thumbnail && !img.invalid
    );
    await asyncForEach(imagesWithoutThumbnail, async img => {
      const blobData = await this.imagesUploadService.getImageBlobURL(img);
      const imgThumbnail = await this.imagesUploadService.createImageThumbnail(img);
      const imgListThumbnail = await this.imagesUploadService.createImageThumbnail(img, { width: 100, height: 100});
      const naturalDimensions = await this.imagesUploadService.getNaturalDimensions(blobData);

      img.naturalDimensions = naturalDimensions;
      img.thumbnail = this.imagesUploadService.sanitazeBlobURL(imgThumbnail);
      img.listThumbnail = this.imagesUploadService.sanitazeBlobURL(imgListThumbnail);

      img.iptc = await this.imagesUploadService.extractImageMetadata(img);

      img.dataURL = img.dataURL || blobData;
      img.thumbnailLoaded = true;

      await delay(250);
    });
  }

  async toggleCropImage(imageToCrop = null) {

    if (imageToCrop) {
      this.imageToCrop = this.queuedImages.find(img => img.queueID === imageToCrop.queueID);
      this.cropsPreviousState = this.imageToCrop.crops.map(item => ({ ...item }));
      if (this.imageToCrop.original && !this.imageToCrop.originalLoaded) {
        await this.prepareImageForCropping();
      }
      this.imageToCrop.cropsMap = this.imageToCrop.crops.reduce((acc, item) => ({ ...acc, [item.renditionId]: item }), {});
    }
    await this.manageCropWindowDimensions();

    if (this.imageToCrop.cropped) {
      this.imageToCrop.cropped = false;
    }

    this.cropperActive = true;
  }

  async handleCropImage() {
    this.imageToCrop.cropped = true;
    // a helper variable for detecting if a user maybe started cropping another image while "final previews" are being generated for the previous one
    const cropperRef = this.imageCropper;
    const queueId = this.imageToCrop.queueID;

    // That previews are generated before croppedImage is accessed
    this.imageCropper.showCropPreviews = true;
    this.imageCropper.generateFinalPreviews().then(({ cropPreviews }: any) => {
      const croppedImage = this.queuedImages.find(img => img.queueID === queueId);
      const thumbnailData = cropPreviews.find(cropPreview => {
        return cropPreview.cropperData.width === 400 && cropPreview.cropperData.height === 260;
      });
      const thumbnail = thumbnailData && this.imagesUploadService.sanitazeBlobURL(thumbnailData.croppedImage);
      // cropping changes already saved
      if (!this.imageCropper || this.imageCropper !== cropperRef) {
        if (croppedImage && thumbnail) {
          this.store.dispatch(new UpdateQueuedImagesAction([{ ...croppedImage, thumbnail }]));
        }
        return;
      }
      if (this.config.viewAs === 'contained' && !this.generateImagePage) {
        document.querySelector('mat-dialog-content.gd-embed-media-dialog__content').scroll({ top: 0, behavior: 'smooth' });
      } else {
        document.getElementById('main-container').scroll({ top: 0, behavior: 'smooth' });
      }
      this.imageToCrop.cropped = true;
      this.imageCropper.cropPreviewsLayoutCreated = true;

      if (thumbnail) {
        this.imageToCrop.thumbnail = thumbnail;
      }
      this.imageToCrop.crops = [...croppedImage.crops];
      this.imageCropper.resetCropperOffset();
    });
  }

  handleSaveImageCrop() {
    const croppedImage = this.queuedImages.find(img => img.queueID === this.imageToCrop.queueID);
    this.store.dispatch(
      new UpdateQueuedImagesAction([
        { ...croppedImage, thumbnail: this.imageToCrop.thumbnail, cropped: true },
      ])
    );
    // Reset cropper visibility/state
    this.cropperActive = false;
    this.imageToCrop = null;
    this.imageCropper.showCropPreviews = false;
    this.manageCropWindowDimensions();
  }

  openImageSelectorDialog() {
    let payload = {};
    if (!this.config.multiUpload && this.queuedImages.length) {
      payload = { changeImage: true, currentImage: this.queuedImages[0].queueID };
    }
    this.dropzone.openNativeDialog(payload);
  }

  triggerAddImageMeta(image) {
    if (this.config.viewAs === 'contained') {
      this.openImageUploadForm.emit(image);
    } else {
      this.modalsService.uploadImageMetaForm({ image }).subscribe(event => {
        if (event && event.meta) {
          this.store.dispatch(
            new UpdateQueuedImageMetaAction({
              queueID: image.queueID,
              meta: event.meta,
              tags: event.tags,
            })
          );
        }
      });
    }
  }

  handleDiscardCropEvent() {
    this.discardImageCrop();
  }

  discardImageCrop() {
    this.imageCropper.resetCropbox();
    this.cropperActive = false;
    this.store.dispatch(new DiscardImageCropsChangesAction({ id: this.imageToCrop.queueID, crops: this.cropsPreviousState}));
    this.imageToCrop = false;
    this.imageCropper.resetCropParams();
    this.manageCropWindowDimensions();
  }

  async manageCropWindowDimensions() {
    const dialogEl = document.querySelector('.gd-embed-upload-panel');
    if (this.config.viewAs !== 'contained' || !dialogEl) {
      return;
    }
    if (dialogEl.classList.contains('gd-embed-upload-panel--crop-active')) {
      dialogEl.classList.remove('gd-embed-upload-panel--crop-active');
      return;
    }
    dialogEl.classList.add('gd-embed-upload-panel--crop-active');
    await pauseExecution(400);
  }

  handleQueuedFilesEvent(images) {
    if (images?.length && !this.config.multiUpload) {
      this.queuedImagesObs
        .pipe(filter(data => !!(data || []).length), take(1))
        .subscribe(data => {
          const image = data.find(img => img.queueID === images[0].queueID);
          this.triggerAddImageMeta(image);
        });
    }
  }

  ngOnDestroy(): void {
    // if (!this.queueInProcessing) {
    //   this.store.dispatch(new ResetImagesUploadStateAction());
    // }
    // Tear down upload state subscriptions
    this.uploadStateSubscriptions.unsubscribe();
    window.removeEventListener('beforeunload', this.preventBrowserClose);
  }

  loadGettyWidget() {

    this.modalsService
      .custom('Getty Library', ExternalImageLibraryWidgetComponent, {
        data: {
          multiUpload: this.config.multiUpload,
        },
        width: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        panelClass: 'gd-external-image-library-dialog',
        disableClose: true
      })
      .subscribe((images) => {
        if(!this.config.multiUpload){
          this.queuedImages.filter(image => image.queueID !== images[0].queueID)
          .forEach(image => this.store.dispatch(new RemoveImageFromQueueAction(image.queueID)))
        }
        this.handleQueuedFilesEvent(images);
      });
  }

  async prepareImageForCropping() {
    this.snackbar.open($localize`The image is being prepared for cropping..`, $localize`Close`);
    // download original needed for crop functionality
    // reuse the existing "processing" flag for displaying spinner while the original image is being downloaded
    this.imageToCrop.processing = true;
    this.store.dispatch(new SetImagesProcessingStateAction(true));

    const blob = await firstValueFrom(this.http.get(this.imageToCrop.original, { responseType: 'blob' }));
    const fileData = new File([blob], this.imageToCrop.fileData.name, { type: blob.type });
    const imgURL = URL.createObjectURL(fileData);
    const thumbnail = this.imagesUploadService.sanitazeBlobURL(imgURL);
    const dataURL = await this.imagesUploadService.getImageBlobURL({ fileData });

    this.imageToCrop.processing = false;
    this.store.dispatch(new UpdateQueuedImagesAction([{ ...this.imageToCrop, naturalDimensions: { ...this.imageToCrop.dimensions }, imgURL, thumbnail, listThumbnail: thumbnail, dataURL, fileData, originalLoaded: true }]));
    this.store.dispatch(new SetImagesProcessingStateAction(false));

    await pauseExecution(50);
    this.imageToCrop = this.queuedImages.find(img => img.queueID === this.imageToCrop.queueID);
    this.snackbar.open($localize`The image is ready for cropping`, $localize`Close`, { duration: 3000 });
  }

  handleGettyButton(responseData) {
    if (responseData.status === 'success') {
      this.errorMessage = '';
      this.hasGettyError = false;
      this.isGettyLoaded = true;
      return;
    }
    this.errorMessage = responseData.errorMessage;
    this.hasGettyError = true;
    this.isGettyLoaded = true;
  }

  imageGeneratedChange(val) {
    this.imageGeneratedUploadEmitter.emit(val);
  }

  invokeAddToQueue() {
    this.generateImageForm.addToQueue();
  }
}

function delay(duration) {
  return new Promise(resolve => setTimeout(() => resolve({}), duration));
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
