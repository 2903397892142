<div id="image-slider"
     class="gd-image-slider__container">
  <button mat-icon-button
          *ngIf="images && images.length > 4"
          class="arrow-buttons"
          (click)="slideLeft()"
          type="button"
          [style.left]="'4px'">
    <mat-icon fontSet="fal"
              fontIcon="fa-angle-left"></mat-icon>
  </button>

  <ng-container *ngFor="let img of images; let i = index">
    <img *ngIf="itemType === 'IMAGE'"
         [src]="img.thumbnail"
         class="gd-image-slider__img"
         [class.--first]="i===0"
         [class.--last]="images.length > 4 && i===images.length-1"
         [class.--selected]="selectedImageIndex === i"
         (click)="changeIndex.emit(i)" />

    <img class="gd-image-slider__img"
         *ngIf="itemType === 'FILE' && img.previewImageUrl && !img.isIconPreview"
         [src]="img.previewImageUrl"
         alt=""
         [class.--first]="i===0"
         [class.--last]="images.length > 4 && i===images.length-1"
         [class.--selected]="selectedImageIndex === i"
         (click)="changeIndex.emit(i)"
         [matTooltip]="img.filename">
    <mat-icon class="gd-image-slider__img icon"
              *ngIf="itemType === 'FILE' && img.isIconPreview && img.isCustomSvgIcon"
              svgIcon="{{ img.previewImageUrl }}"
              [class.--first]="i===0"
              [class.--last]="images.length > 4 && i===images.length-1"
              [class.--selected]="selectedImageIndex === i"
              (click)="changeIndex.emit(i)"
              [matTooltip]="img.filename"></mat-icon>
    <mat-icon class="gd-image-slider__img icon"
              *ngIf="itemType === 'FILE' && img.isIconPreview && !img.isCustomSvgIcon"
              fontSet="fal"
              fontIcon="{{ img.previewImageUrl }}"
              [class.--first]="i===0"
              [class.--last]="images.length > 4 && i===images.length-1"
              [class.--selected]="selectedImageIndex === i"
              (click)="changeIndex.emit(i)"
              [matTooltip]="img.filename"></mat-icon>
  </ng-container>
  <button mat-icon-button
          *ngIf="images && images.length > 4"
          class="arrow-buttons"
          (click)="slideRight()"
          type="button"
          [style.right]="'4px'">
    <mat-icon fontSet="fal"
              fontIcon="fa-angle-right"></mat-icon>
  </button>

</div>