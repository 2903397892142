import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared/material-module.module';
import { WidgetCardBuilderComponent } from './widget-card-builder/widget-card-builder.component';
import { CustomFieldPreviewComponent } from './widget-card-builder/custom-field-preview/custom-field-preview.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [WidgetCardBuilderComponent],
  declarations: [WidgetCardBuilderComponent, CustomFieldPreviewComponent],
  providers: [],
})
export class WidgetConfigPreviewModule {}
