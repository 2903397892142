
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { of } from 'rxjs';

@Injectable()
export class AuthorsService {

  constructor(private rest: RestService) { }

  getAuthors({ name = '', jobTitle = '', currentPage = 0, pageSize = defaultPageSize, slug = '', orderBy = ''}: any = {}) {
    let requestPath = `authors?page=${currentPage || 0}&size=${pageSize || defaultPageSize}`;
    requestPath += name ? `&name=${encodeURIComponent(name)}` : '';
    requestPath += jobTitle ? `&jobTitle=${encodeURIComponent(jobTitle)}` : '';
    requestPath += slug ? `&slug=${slug}` : '';
    requestPath += orderBy.length > 0 ? `&orderBy=${orderBy}` : '';

    return this.rest.get(requestPath);
  }

  getAuthor(id) {
    return this.rest.get(`authors/${id}`).pipe(map(response => response.data));
  }

  createAuthor(author) {
    return this.rest.post('authors', author).pipe(map(response => response.data));
  }

  updateAuthor(author) {
    return this.rest.put(`authors/${author.id}`, author).pipe(map((response: any) => response.data));
  }

  deleteAuthor(id) {
    return this.rest.delete(`authors/${id}`);
  }

  getAuthorsByIds(authorIds) {

    if (authorIds.length < 1) {
      return of([]);
    }

    const size = authorIds.length <= 200 ? authorIds.length : 200;

    return this.rest.get(`authors?ids=${authorIds.join(',')}&size=${size}`).pipe(map(response => response.data));
  }

  getAuthorByUserId(id) {
    return this.rest.get(`authors/user/${id}`).pipe(map(response => response.data));
  }

  checkIfAuthorSlugExists({ slug }) {
    return this.getAuthors({ slug });
  }
}
