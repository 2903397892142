import { UnsafeAction } from '../unsafe-action.interface';
import { Menu } from './menus.model';

export const GET_MENUS = '[MENUS] Get Menus';
export class GetMenusAction implements UnsafeAction {
  readonly type = GET_MENUS;
  constructor() { }
}

export const GET_MENUS_SUCCESS = '[MENUS] Get Menus success';
export class GetMenusSuccessAction implements UnsafeAction {
  readonly type = GET_MENUS_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_MENU = '[MENU] Create Menu';
export class CreateMenuAction implements UnsafeAction {
  readonly type = CREATE_MENU;
  constructor(public payload: Menu) { }
}

export const CREATE_MENU_SUCCESS = '[MENU] Create Menu success';
export class CreateMenuSuccessAction implements UnsafeAction {
  readonly type = CREATE_MENU_SUCCESS;
  constructor(public payload: Menu) { }
}

export const DELETE_MENU = '[MENU] Delete Menu';
export class DeleteMenuAction implements UnsafeAction {
  readonly type = DELETE_MENU;
  constructor(public payload: number) { }
}

export const DELETE_MENU_SUCCESS = '[MENU] Delete Menu success';
export class DeleteMenuSuccessAction implements UnsafeAction {
  readonly type = DELETE_MENU_SUCCESS;
  constructor(public payload: number) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[MENU] Set page view options';
export class SetPageViewOptions implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_MENU = '[MENU] Get active Menu';
export class GetActiveMenuAction implements UnsafeAction {
  readonly type = GET_ACTIVE_MENU;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_MENU_SUCCESS = '[MENU] Get active Menu success';
export class GetActiveMenuSuccessAction implements UnsafeAction {
  readonly type = GET_ACTIVE_MENU_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_ACTIVE_MENU = '[MENU] Update active Menu';
export class UpdateActiveMenuAction implements UnsafeAction {
  readonly type = UPDATE_ACTIVE_MENU;
  constructor(public payload: Menu) { }
}

export const UPDATE_ACTIVE_MENU_SUCCESS = '[MENU] Update active Menu success';
export class UpdateActiveMenuSuccessAction implements UnsafeAction {
  readonly type = UPDATE_ACTIVE_MENU_SUCCESS;
  constructor(public payload: Menu) { }
}

export const ADD_MENU_ITEM = '[MENU] Add Menu Item';
export class AddMenuItemAction implements UnsafeAction {
  readonly type = ADD_MENU_ITEM;
  constructor(public payload: any) { }
}

export const UPDATE_MENU_ITEM = '[MENU] Update Menu Item';
export class UpdateMenuItemAction implements UnsafeAction {
  readonly type = UPDATE_MENU_ITEM;
  constructor(public payload: any) { }
}

export const DELETE_MENU_ITEM = '[MENU] Delete Menu Item';
export class DeleteMenuItemAction implements UnsafeAction {
  readonly type = DELETE_MENU_ITEM;
  constructor(public payload: any) { }
}

export const CHANGE_MENU_ITEM_PUBLISHED_STATE = '[MENU] Change Menu Item published state';
export class ChangeMenuItemPublishedStateAction implements UnsafeAction {
  readonly type = CHANGE_MENU_ITEM_PUBLISHED_STATE;
  constructor(public payload: any) { }
}

export const REORDER_MENU_ITEMS = '[MENU] Reorder Menu Items';
export class ReorderMenuItemsAction implements UnsafeAction {
  readonly type = REORDER_MENU_ITEMS;
  constructor(public payload: any) { }
}

export const SET_MENUS_FILTER = '[MENUS] Set Menus filter';
export class SetMenusFilterAction implements UnsafeAction {
  readonly type = SET_MENUS_FILTER;
  constructor(public payload: any) { }
}

export const MENU_ACTION_FAILED = '[MENU] Generic failure action';
export class MenuFailedAction implements UnsafeAction {
  readonly type = MENU_ACTION_FAILED;
  constructor(public payload: any) { }
}
