<ul class="breadcrumbs"
    fxLayout="row"
    [dir]="dir$ | async">
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first;"
      fxLayout="row"
      fxLayoutAlign="center center">
    <!-- disable link of last item -->
    <mat-icon class="ms-breadcrumbs__navigate-divider"
              *ngIf="!first"
              fontSet="fal"
              [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"></mat-icon>

    <a role="button"
       mat-button
       style='min-width: 0px;'
       class="ms-breadcrumbs__navigate-button"
       *ngIf="!last && breadcrumb.url"
       [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
    <span class="ms-breadcrumbs__navigate-label-last"
          *ngIf="last || !breadcrumb.url"
          style="padding: 0px 4px">{{ localizeLabel(breadcrumb.label) }}</span>
  </li>
</ul>
