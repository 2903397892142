import {debounceTime} from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ScriptInjectorService } from '../../../core/miscellaneous/script-injector.service';
import { BidiService } from '../../../core/i18n/bidi.service';

declare const twttr: any;

@Component({
  selector: 'gd-embed-twitter-dialog',
  templateUrl: './embed-twitter-dialog.component.html',
  styleUrls: ['./embed-twitter-dialog.component.scss']
})
export class EmbedTwitterDialogComponent implements OnInit, OnDestroy {

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: [null],
    url: ['', Validators.required],
  });

  blockId;
  loading = false;
  postLoaded = false;
  urlSubscription: Subscription;
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  get urlControl() {
    return this.embeddableForm.get('url');
  }

  get idControl() {
    return this.embeddableForm.get('id');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedTwitterDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private scriptInjector: ScriptInjectorService,
    private bidiService: BidiService,
  ) { }

  ngOnInit(): void {
    this.loadTwitterScript();
    this.urlSubscription = this.urlControl.valueChanges.pipe(debounceTime(200)).subscribe(
      url => {
        const postContainer = document.getElementById('twitter-container');
        postContainer.innerHTML = '';
        this.loadTwitterScript();
        this.postLoaded = false;
        if (!url) {
          this.urlControl.setErrors({ 'required': true });
          return;
        }

        const postId = this.getTwitterPostIdFromUrl(url);
        if (!postId || postId.replace(/[0-9]/g, '').length) {
          return this.urlControl.setErrors({'invalidUrl': true});
        }

        this.loading = true;
        return twttr.widgets.createTweet(postId, postContainer)
        .then(response => {
          this.loading = false;
          if (!response) {
            return this.urlControl.setErrors({'invalidUrl': true});
          }
          this.postLoaded = true;
          return this.urlControl.setErrors(null);
        });
      }
    );

    this.embeddableForm.patchValue(this.dialogInputData);
    this.blockId = this.dialogInputData.blockId;
  }

  getTwitterPostIdFromUrl(postUrl: string): string {
    const regex = /(twitter|x)\.com\/.*\/status(?:es)?\/(\d+)/;
    const parts = postUrl.match(regex);
    return parts && parts[2];
  }

  save() {
    const formModel = this.embeddableForm.value;
    this.dialogRef.close({
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel
    });
  }

  //run twitter SDK script to load post
  loadTwitterScript() {
    if (document.getElementById('twitter-sdk')) {
      return;
    }
    this.scriptInjector
      .injectScript('https://platform.twitter.com/widgets.js', { id: 'twitter-sdk' })
      .then(() => {})
      .catch(() => console.log('Error: Twitter software development kit failed to load'));
  }


  ngOnDestroy(): void {
    this.urlSubscription.unsubscribe();
  }

}
