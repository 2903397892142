import { UnsafeAction } from '../unsafe-action.interface';

export const GET_ARTICLE_QUICK_VIEW = '[Article Quick View] Get Article Quick View';
export class GetArticleQuickViewAction implements UnsafeAction {
  readonly type = GET_ARTICLE_QUICK_VIEW;
  constructor(public payload: any) {}
}

export const GET_ARTICLE_QUICK_VIEW_SUCCESS = '[Article Quick View] Get Article Quick View success';
export class GetArticleQuickViewSuccessAction implements UnsafeAction {
  readonly type = GET_ARTICLE_QUICK_VIEW_SUCCESS;
  constructor(public payload: any) {}
}

export const CLEAR_ARTICLE_QUICK_VIEW = '[Article Quick View] Clear Article Quick View';
export class ClearArticleQuickViewAction implements UnsafeAction {
  readonly type = CLEAR_ARTICLE_QUICK_VIEW;
  constructor() {}
}

export const MANAGE_EMBEDDED_IMAGE_VISIBILITY = '[Article Quick View] Manage visibility of embedded image';
export class ManageEmbeddedImageVisibilityAction implements UnsafeAction {
  readonly type = MANAGE_EMBEDDED_IMAGE_VISIBILITY;
  constructor(public payload: any) { }
}

export const UPDATE_REFERENCED_CONTENT = '[Article Quick View] Update referenced content';
export class UpdateReferencedContentAction implements UnsafeAction {
  readonly type = UPDATE_REFERENCED_CONTENT;
  constructor(public payload: any) { }
}

