import { catchError, debounceTime, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomFormConstructorService } from '../../../gpp-shared/widget-configuration/form-constructor/custom-form-constructor.service';
import { EmbeddablesDataService } from '../../editors/embeddables-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { generateShortId } from '../../shared-functions';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-embed-jw-media-dialog',
  templateUrl: './embed-jw-media-dialog.component.html',
  styleUrls: ['./embed-jw-media-dialog.component.scss'],
})
export class EmbedJWMediaDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  mediaForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: [''],
    src: [''],
    description: [''],
    autoplay: [false],
    height: [''],
    width: ['']
  });


  previewSrc = null;
  // used mostly for displaying list of videos added in playlist
  videos = [];
  metaDataConfig;
  componentSubs: Subscription = new Subscription();

  editingMode = false;
  allowMeta = false;
  content = 'video'; // 'video' | 'playlist'
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  tabIndex: number = 0;
  initialValues = null;


  @ViewChild('focusInput') focusInput: ElementRef;
  @ViewChild('previewEl') previewEl: ElementRef;

  get idControl() {
    return this.mediaForm.get('id');
  }

  get metaData(): UntypedFormControl {
    return this.mediaForm.get('itemMetaData') as UntypedFormControl;
  }

  get videoUrl() {
    return this.previewEl ? this.previewEl.nativeElement.src : null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedJWMediaDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private fc: CustomFormConstructorService,
    private embeddablesDataService: EmbeddablesDataService,
    public domSanitizer: DomSanitizer,
    private bidiService: BidiService,
  ) {}

  ngOnInit(): void {
    this.tabIndex = this.dialogInputData.tabIndex;
    this.editingMode = !!this.dialogInputData.editingMode;
    this.componentSubs.add(this.createIdInputSub());
    this.allowMeta = !!this.dialogInputData.allowMeta;
    // embed type options: "video/jw" or "video/jw-playlist"
    this.content = this.dialogInputData.embedType.endsWith('playlist') ? 'playlist' : 'video';

    if (this.editingMode && this.dialogInputData.id) {
      // triggers form initialization
      this.idControl.setValue(this.dialogInputData.id);
    }
    this.prepareItemMetaData();
  }

  ngAfterViewInit() {
    setTimeout(() => this.focusInput.nativeElement.focus(), 200);
  }

  createIdInputSub() {
    let firstChange = !this.editingMode || this.dialogInputData.id;
    return this.idControl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => this.clearPreviousData()),
        filter((id) => !!id),
        map((id) => (id || '').trim()),
        mergeMap((id) => id ? this.getMediaData(id) : of(false))
      )
      .subscribe((data) => {
        this.idControl.markAsTouched();
        const error = !data || data.error ? { invalidId: true } : null;
        this.idControl.setErrors(error);
        if (error) {
          return;
        }
        // used mostly for displaying list of videos added in playlist
        this.videos = this.prepareVideosArray(data.playlist);
        const videoData = data.playlist[0];
        this.previewSrc = this.videos.length && this.domSanitizer.bypassSecurityTrustResourceUrl(this.videos[0].src) || this.domSanitizer.bypassSecurityTrustResourceUrl(videoData.image);
        const showSavedData = firstChange && this.editingMode;
        firstChange = false;
        if (showSavedData) {
          const { src, name, description, autoplay } = this.dialogInputData;
          const rendition = videoData.sources?.find(el => el.height && el.width)

          return this.mediaForm.patchValue({
            src: src || videoData.image,
            name: name || videoData.title,
            description: description || videoData.description,
            autoplay,
            height: rendition.height,
            width: rendition.width
          });
        }

        const { title: name, description, image: src } = videoData;

        const rendition = videoData.sources.find(el => el.height && el.width)
        this.mediaForm.patchValue({ name, description, src, height: rendition.height, width: rendition.width });
      });
  }

  getMediaData(id) {
    if (this.content === 'video') {
      return this.embeddablesDataService.getJWVideoData(id).pipe(catchError((err) => of(err)));
    }
    return this.embeddablesDataService.getJWPlaylistData(id).pipe(catchError((err) => of(err)));
  }

  prepareVideosArray(playlist) {
    if (!playlist.length) {
      return [];
    }
    return playlist.map(video => {
      const sortedSources = video.sources
      .filter(item => item.width && item.type.startsWith('video'))
      .sort((a, b) => a.width < b.width ? 1 : -1);
      return { name: video.title, src: sortedSources[0].file };
    });
  }

  save() {
    this.dialogRef.close(this.prepareOutputObj());
  }

  clearPreviousData() {
    this.videos	= [];
    this.previewSrc = null;
    ['name', 'description', 'src'].forEach(key => {
      this.mediaForm.get(key).setValue('');
    });
  }

  prepareOutputObj() {
    const data = this.mediaForm.value;
    data.id = data.id ? data.id.trim() : data.id;
    data.src = data.src || './assets/img/jw-logo-placeholder.svg';

    if (this.content === 'playlist') {
      delete data.autoplay;
    }
    if (!this.allowMeta) {
      delete data.itemMetaData;
    }

    if (this.allowMeta) {
      return data;
    }

    const payload = {
      type: this.dialogInputData.embedType,
      blockId: this.dialogInputData.blockId || generateShortId(),
      updateData: data,
    };

    return { payload };
  }

  ngOnDestroy(): void {
    this.componentSubs.unsubscribe();
  }

  prepareItemMetaData() {
    this.mediaForm.removeControl('itemMetaData');

    const passedConfig = this.dialogInputData.itemMetaDataConfig;
    this.metaDataConfig = passedConfig && passedConfig.length > 0 ? passedConfig : null;
    const initialValues = this.editingMode ? this.dialogInputData.itemMetaData || {} : null;
    // TODO refactor - move responsibility to DynamicWidgetFormComponent
    this.initialValues = initialValues;
    const itemFormGroup = this.fc.createFormGroup(this.metaDataConfig, initialValues, {
      editMode: !!initialValues,
    });
    this.mediaForm.addControl('itemMetaData', itemFormGroup);
  }

  previewVideo(src) {
    if (src === this.videoUrl) {
      return;
    }
    this.previewSrc = null;
    setTimeout(() => this.previewSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(src), 0);
  }

  getSaveButtonTooltipMessage() {
    if (this.mediaForm.valid) {
      return '';
    }
    if (this.metaData.invalid) {
      if (this.isCustomDataTaxonomyAllowed()) {
        return $localize`Some of the used Taxonomies on custom fields are invalid.`;
      }
      return $localize`Metadata form is invalid`
    }
    return $localize`Form is invalid`
  }

  isCustomDataTaxonomyAllowed() {
    const configuration = this.metaData as UntypedFormControl;
    if (!configuration) {
      return false;
    }
    return Object.values(configuration['controls'])
      .some((control: UntypedFormControl) => control.hasError("taxonomyErr"))
  }

}
