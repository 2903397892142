import { DataSourceOptions } from './../../../custom-form-builder/data-source-options.enum';
import { filter, map, startWith, take, tap } from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { WidgetTypeFieldConfiguration } from '../../../../core/store/widget-types/widget-types.model';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { DataSourceFactoryService } from '../field-builder-data-factory.service';
import { hasRequiredValidation } from '../has-required-validation/has-required-validation';
import { isEqual, get } from 'lodash-es';
import { generateHelpTooltip } from '../info-tooltip.text';

@Component({
  selector: 'gd-select-field-configuration',
  templateUrl: './select-field-configuration.component.html',
  styleUrls: ['./select-field-configuration.component.scss']
})
export class SelectFieldConfigurationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fieldControl: UntypedFormControl;
  @Input() fieldConfig:  WidgetTypeFieldConfiguration;
  @Input() width = 70;
  @Input() hasActionPermission = true;
  @Input() readOnlyMode = false;

  public selectedItem = null;

  isFormControlRequired;
  dataSource$: Observable<any>;
  validators: any = {};
  sourceVal = null;
  cmsDataSourceType;
  dataSource;
  ready = true;

  // these values are used for message provided by options data source
  dataSourceMessage = null;
  dataSourceMessageType = null;

  componentSubscriptions = new Subscription();

  // determine if the labels for this field will be saved for CFG EDS fields
  saveLabels = false;

  infoTooltip = '';

  readOnlyTooltip = $localize`Read-only`;


  get sysLabelsControl() {
    return this.fieldControl.parent.get('__sysLabels');
  }

  get sysSelectionValuesControl() {
    return this.fieldControl.parent.get('__sysEdsOptionsData');
  }

  get isExternalDataSourceField() {
    return get(this.fieldConfig, 'dataSource.type', null) === DataSourceOptions.ExternalData;
  }

  constructor(
    private dataSourceFactory: DataSourceFactoryService
  ) { }

  ngOnInit() {
    this.initializeDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {

    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);

    const configChange = changes['fieldConfig'];
    // TODO make a more sophisticated check here - we don't need to update on label or description changes
    const configurationChanged = !configChange.firstChange && !isEqual(configChange.currentValue, configChange.previousValue);
    // only reinitialize
    if (!configurationChanged) {
      return;
    }
    this.ready = false;
    this.cleanUpDataSource();
    this.initializeDataSource();
    setTimeout(() => {
      this.ready = true;
    }, 0);
  }

  ngOnDestroy() {
    this.cleanUpDataSource();
    this.componentSubscriptions.unsubscribe();
  }

  initializeDataSource() {
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    this.sourceVal = this.fieldConfig && this.fieldConfig['dataSource'] || null;
    this.cmsDataSourceType = this.sourceVal && this.sourceVal.value;
    this.saveLabels = get(this.fieldConfig, 'dataSource.value.saveLabels', false);

    if (!this.hasActionPermission) {
      this.fieldControl.disable();
    }


    this.dataSource = this.dataSourceFactory.create(this.fieldConfig, this.fieldControl.value, this.fieldControl);
    this.dataSource$ = this.dataSource.init();
    this.dataSource.getData();

    this.initializeOptionsMessageListener();
    this.handleSaveSelectedOptionsData();
  }

  initializeOptionsMessageListener() {
    // if the options data source provides a stream of messages (hints or errors)
    // then wire up the source with local variables used to display those messages
    if (this.dataSource.getMessages) {
      this.componentSubscriptions.add(
        this.dataSource.getMessages().subscribe(messageData => {
          this.dataSourceMessageType = get(messageData, 'type', null);
          this.dataSourceMessage = get(messageData, 'message', null);
        })
      );
    }
  }

  // only for EDS select fields within CFGs
  handleSaveSelectedOptionsData() {
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    if (this.fieldConfig.key !== 'defaultValue' && this.fieldConfig.dataSource.type === 'External Data') {
      this.fieldControl.addValidators(externalDataLoadingValidator);
      this.fieldControl.updateValueAndValidity();
    }
    const selectionDataStream$ = combineLatest([
      this.dataSource$,
      this.fieldControl.valueChanges.pipe(startWith(this.fieldControl.value)),
    ]).pipe(tap(([data, selected]) => {
      if(data.length === 0 && !selected && !this.isFormControlRequired){
        this.fieldControl.removeValidators(externalDataLoadingValidator);
        this.fieldControl.updateValueAndValidity();
      }
    })).pipe(filter(([data, selected]) => data && selected));

    // single select form control
    // this is only executed to initiate the value of the saved label for this field
    if (this.fieldConfig.inputType === 'single') {
      this.componentSubscriptions.add(
        selectionDataStream$
          .pipe(
            map(([data, selectedId]) => data.find((value) => selectedId === value.id)),
            filter((dd) => !!dd),
            take(1)
          )
          .subscribe((selectedValue) => {
            this.saveSelectedOptionLabels(selectedValue.name || selectedValue.label);
            this.saveSelectedOptionData(selectedValue);
            this.fieldControl.removeValidators(externalDataLoadingValidator);
            this.fieldControl.updateValueAndValidity();
          })
      );
      return;
    }

    // multi select field control
    this.componentSubscriptions.add(
      selectionDataStream$.subscribe(([data, selectedIds]) => {
        const selectedOptions = data.filter((value) => selectedIds.includes(value.id));

        // create and id-label map and save it
        const selectedOptionLabelsMap = selectedOptions.reduce((acc, v) => {
          acc[v.id] = v.name;
          return acc;
        }, {});
        this.saveSelectedOptionLabels(selectedOptionLabelsMap);

        // create an id-option map and save it
        const selectedOptionsMap = selectedOptions.reduce((acc, v) => {
          acc[v.id] = v;
          return acc;
        }, {});
        this.saveSelectedOptionData(selectedOptionsMap);
        this.fieldControl.removeValidators(externalDataLoadingValidator);
        this.fieldControl.updateValueAndValidity();
      })
    );
  }

  /**
   * Noop if the save labels EDS field option is not enabled
   */
  saveSelectedOptionLabels(selectedOptionLabel) {
    if(!this.saveLabels || !this.sysLabelsControl) {
      return;
    }
    this.sysLabelsControl.setValue({
      ...this.sysLabelsControl.value,
      [this.fieldConfig.key]: selectedOptionLabel
    });
  }

  saveSelectedOptionData(selectedOption) {
    this.selectedItem = selectedOption;
    if(!this.sysSelectionValuesControl) {
      return;
    }
    this.sysSelectionValuesControl.setValue({
      ...this.sysSelectionValuesControl.value,
      [this.fieldConfig.key]: selectedOption
    });
  }

  cleanUpDataSource() {
    if (this.dataSource && this.dataSource.destroy) {
      this.dataSource.destroy();
    }
  }

  // only for EDS select fields within CFGs with input type 'single'
  handleSelectionChange(event) {
    if(!this.isExternalDataSourceField || this.fieldConfig.inputType !== 'single') {
      return;
    }

    const selectedValue = event.value;
    this.dataSource$.pipe(take(1)).subscribe(options => {
      const selectedOption = options.find(option => option.id === selectedValue);
      this.saveSelectedOptionData(selectedOption);
    })

    const selectedLabel = event.source.triggerValue;
    this.saveSelectedOptionLabels(selectedLabel);
  }

}

function externalDataLoadingValidator() {
  return { externalDataLoading: true };
}
