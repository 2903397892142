<mat-card class="gd-edit-user__my-profile" [dir]="dir$ | async">
  <h4 class="gd-edit-user__step-heading"
      i18n>My profile</h4>
  <gd-user-details-form [userForm]="userForm"
                        [userEdited]="userEdited">
  </gd-user-details-form>

  <div class="gd-edit-user__action-buttons"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <button mat-raised-button
            i18n
            (click)="openChangePasswordDialog()">Change Password</button>

    <div fxLayout="row"
         fxLayoutGap="8px">
      <button mat-raised-button
              [disabled]="!userForm.valid"
              [color]="userForm.dirty ? 'primary' : ''"
              (click)="submitMyProfile()">
        <ng-container *ngIf="userForm.dirty"
                      i18n>Save</ng-container>
        <ng-container *ngIf="userForm.pristine"
                      i18n>Cancel</ng-container>
      </button>
    </div>
  </div>
</mat-card>
