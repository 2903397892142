import { UsersQueryParameters } from '../../api/users/users.service';
import { UnsafeAction } from '../unsafe-action.interface';

// Accounts actions
export const GET_ACCOUNTS = '[Users] Get accounts';
export class GetAccountsAction implements UnsafeAction {
  readonly type = GET_ACCOUNTS;
  constructor() { }
}

export const GET_ACCOUNTS_COMPLETE = '[Users] Get accounts complete';
export class GetAccountsCompleteAction implements UnsafeAction {
  readonly type = GET_ACCOUNTS_COMPLETE;
  constructor(public payload: any) { }
}

export const CREATE_ACCOUNT = '[Users] Create account';
export class CreateAccountAction implements UnsafeAction {
  readonly type = CREATE_ACCOUNT;
  constructor(public payload: any) { }
}

export const CREATE_ACCOUNT_COMPLETE = '[Users] Create account complete';
export class CreateAccountCompleteAction implements UnsafeAction {
  readonly type = CREATE_ACCOUNT_COMPLETE;
  constructor(public payload: any) { }
}

export const UPDATE_ACCOUNT = '[Users] Update account';
export class UpdateAccountAction implements UnsafeAction {
  readonly type = UPDATE_ACCOUNT;
  constructor(public payload: any) { }
}

export const UPDATE_ACCOUNT_COMPLETE = '[Users] Update account complete';
export class UpdateAccountCompleteAction implements UnsafeAction {
  readonly type = UPDATE_ACCOUNT_COMPLETE;
  constructor() { }
}

export const DELETE_ACCOUNT = '[Users] Delete account';
export class DeleteAccountAction implements UnsafeAction {
  readonly type = DELETE_ACCOUNT;
  constructor(public payload: any) { }
}

export const DELETE_ACCOUNT_COMPLETE = '[Users] Delete account complete';
export class DeleteAccountCompleteAction implements UnsafeAction {
  readonly type = DELETE_ACCOUNT_COMPLETE;
  constructor(public payload: any) { }
}

// Roles actions
export const GET_ROLES = '[Users] Get roles';
export class GetRolesAction implements UnsafeAction {
  readonly type = GET_ROLES;
  constructor() { }
}

export const GET_ROLES_COMPLETE = '[Users] Get roles completed';
export class GetRolesCompleteAction implements UnsafeAction {
  readonly type = GET_ROLES_COMPLETE;
  constructor(public payload: any) { }
}

export const CREATE_ROLE = '[Users] Create role';
export class CreateRoleAction implements UnsafeAction {
  readonly type = CREATE_ROLE;
  constructor(public payload: any) { }
}

export const CREATE_ROLE_COMPLETE = '[Users] Create role completed';
export class CreateRoleCompleteAction implements UnsafeAction {
  readonly type = CREATE_ROLE_COMPLETE;
  constructor() { }
}

export const UPDATE_ROLE = '[Users] Update role';
export class UpdateRoleAction implements UnsafeAction {
  readonly type = UPDATE_ROLE;
  constructor(public payload: any) { }
}

export const UPDATE_ROLE_COMPLETE = '[Users] Update role completed';
export class UpdateRoleCompleteAction implements UnsafeAction {
  readonly type = UPDATE_ROLE_COMPLETE;
  constructor() { }
}

// Permissions
export const GET_PERMISSIONS = '[Users] Get permissions';
export class GetPermissionsAction implements UnsafeAction {
  readonly type = GET_PERMISSIONS;
  constructor() { }
}

export const GET_PERMISSIONS_COMPLETE = '[Users] Get permissions completed';
export class GetPermissionsCompleteAction implements UnsafeAction {
  readonly type = GET_PERMISSIONS_COMPLETE;
  constructor(public payload: any) { }
}

export const DELETE_ROLE = '[Users] Delete role';
export class DeleteRoleAction implements UnsafeAction {
  readonly type = DELETE_ROLE;
  constructor(public payload: any) { }
}

export const DELETE_ROLE_COMPLETE = '[Users] Delete role completed';
export class DeleteRoleCompleteAction implements UnsafeAction {
  readonly type = DELETE_ROLE_COMPLETE;
  constructor(public payload: any) { }
}

// Users actions
export const GET_USERS = '[Users] Get users';
export class GetUsersAction implements UnsafeAction {
  readonly type = GET_USERS;
  constructor(public payload: UsersQueryParameters) { }
}

export const GET_USERS_COMPLETE = '[Users] Get users completed';
export class GetUsersCompleteAction implements UnsafeAction {
  readonly type = GET_USERS_COMPLETE;
  constructor(public payload: { users: any, deactivatedUsers?: any }) { }
}

export const CREATE_USER = '[Users] Create user';
export class CreateUserAction implements UnsafeAction {
  readonly type = CREATE_USER;
  constructor(public payload: any) { }
}

export const CREATE_USER_COMPLETE = '[Users] Create user completed';
export class CreateUserCompleteAction implements UnsafeAction {
  readonly type = CREATE_USER_COMPLETE;
  constructor(public payload: any) { }
}

export const UPDATE_USER = '[Users] Update user';
export class UpdateUserAction implements UnsafeAction {
  readonly type = UPDATE_USER;
  constructor(public payload: any) { }
}

export const UPDATE_USER_COMPLETE = '[Users] Update user completed';
export class UpdateUserCompleteAction implements UnsafeAction {
  readonly type = UPDATE_USER_COMPLETE;
  constructor(public payload: any) { }
}

export const SET_USERS_LOADING_FLAG = '[Users] Set loading flag';
export class SetUsersLoadingFlagAction implements UnsafeAction {
  readonly type = SET_USERS_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const USERS_ACTION_FAILED = '[Users] Generic failure action';
export class UsersFailedAction implements UnsafeAction {
  readonly type = USERS_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const DEACTIVATE_USER = '[Users] Deactivate user';
export class DeactivateUserAction implements UnsafeAction {
  readonly type = DEACTIVATE_USER;
  constructor(public payload: any) { }
}

export const DEACTIVATE_USER_COMPLETE = '[Users] Deactivate user completed';
export class DeactivateUserCompleteAction implements UnsafeAction {
  readonly type = DEACTIVATE_USER_COMPLETE;
  constructor(public payload: any) { }
}


export const SET_PAGE_VIEW_OPTIONS = '[Users] Set page view options';
export class SetPageViewOptions implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: { pageIndex, pageSize }) { }
}

export const UPDATE_USER_PROFILE = '[Users] Update user profile';
export class UpdateUserProfileAction implements UnsafeAction {
  readonly type = UPDATE_USER_PROFILE;
  constructor(public payload: any) { }
}

export const UPDATE_USER_PROFILE_COMPLETE = '[Users] Update user profile complete';
export class UpdateUserProfileCompleteAction implements UnsafeAction {
  readonly type = UPDATE_USER_PROFILE_COMPLETE;
  constructor(public payload: any) { }
}

// Role Types actions
export const GET_ROLE_TYPES = '[Users] Get role types';
export class GetRoleTypesAction implements UnsafeAction {
  readonly type = GET_ROLE_TYPES;
  constructor() { }
}

export const GET_ROLE_TYPES_COMPLETE = '[Users] Get role types completed';
export class GetRoleTypesCompleteAction implements UnsafeAction {
  readonly type = GET_ROLE_TYPES_COMPLETE;
  constructor(public payload: any) { }
}

export const RESET_EDITED_USER = '[Users] Reset edited user';
export class ResetEditedUserAction implements UnsafeAction {
  readonly type = RESET_EDITED_USER;
  constructor() { }
}

export const GET_USER = '[Users] Get user';
export class GetUserAction implements UnsafeAction {
  readonly type = GET_USER;
  constructor(public payload: any) { }
}

export const GET_USER_COMPLETE = '[Users] Get user completed';
export class GetUserCompleteAction implements UnsafeAction {
  readonly type = GET_USER_COMPLETE;
  constructor(public payload: any) { }
}
export const RESET_USERS_STATE = '[Users] Reset User State';
export class ResetUsersState implements UnsafeAction {
  readonly type = RESET_USERS_STATE;
  constructor() { }
}

export const CLEAR_USERS_LIST = '[Users] Clear Users List';
export class ClearUsersListAction implements UnsafeAction {
  readonly type = CLEAR_USERS_LIST;
  constructor() { }
}
