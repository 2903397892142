import { UnsafeAction } from '../unsafe-action.interface';
import { Advert } from './adverts.model';

export const GET_ADVERTS = '[ADVERTS] Get Adverts';
export class GetAdvertsAction implements UnsafeAction {
  readonly type = GET_ADVERTS;
  constructor(public payload: { page?: number, limit?: number, name?: string, type?: string }) { }
}

export const GET_ADVERTS_SUCCESS = '[ADVERTS] Get Adverts success';
export class GetAdvertsSuccessAction implements UnsafeAction {
  readonly type = GET_ADVERTS_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_ADVERTS_ERROR = '[ADVERTS] Get Adverts error';
export class GetAdvertsErrorAction implements UnsafeAction {
  readonly type = GET_ADVERTS_ERROR;
  constructor(public payload: any) { }
}

export const CREATE_ADVERTS = '[ADVERTS] Create Adverts';
export class CreateAdvertsAction implements UnsafeAction {
  readonly type = CREATE_ADVERTS;
  constructor(public payload: Advert) { }
}

export const CREATE_ADVERTS_SUCCESS = '[ADVERTS] Create Adverts success';
export class CreateAdvertsSuccessAction implements UnsafeAction {
  readonly type = CREATE_ADVERTS_SUCCESS;
  constructor(public payload: Advert) { }
}

export const UPDATE_ADVERTS = '[ADVERTS] Update Adverts';
export class UpdateAdvertsAction implements UnsafeAction {
  readonly type = UPDATE_ADVERTS;
  constructor(public payload: Advert) { }
}

export const UPDATE_ADVERTS_SUCCESS = '[ADVERTS] Update Adverts success';
export class UpdateAdvertsSuccessAction implements UnsafeAction {
  readonly type = UPDATE_ADVERTS_SUCCESS;
  constructor(public payload: Advert) { }
}

export const DELETE_ADVERTS = '[ADVERTS] Delete Adverts';
export class DeleteAdvertsAction implements UnsafeAction {
  readonly type = DELETE_ADVERTS;
  constructor(public payload: number) { }
}

export const DELETE_ADVERTS_SUCCESS = '[ADVERTS] Delete Adverts success';
export class DeleteAdvertsSuccessAction implements UnsafeAction {
  readonly type = DELETE_ADVERTS_SUCCESS;
  constructor(public payload: number) { }
}

export const ADVERTS_ACTION_FAILED = '[ADVERTS] Generic failure action';
export class AdvertsFailedAction implements UnsafeAction {
  readonly type = ADVERTS_ACTION_FAILED;
  constructor(public payload: any) { }
}
