import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UserPreferencesService } from '../api/user-preferences/user-preferences.service';
import { isEqual } from 'lodash';


// this service should be changed with shared column manager service
@Injectable({providedIn: 'root'})
export class ArticleListColumnManager {
  private defaultColumnList = [
    'headline',
    'lastUpdate',
    'byline',
    'type',
    'taxonomies',
    'localizedVersions',
    'status',
    'actions'
  ];

  public columnMapping = {
    headline: $localize`Headline/Catchline`,
    lastUpdate: $localize`Edit Status`,
    byline: $localize`Byline`,
    type: $localize`Type`,
    localizedVersions: $localize`Localisations`,
    taxonomies: $localize`Taxonomies`,
    status: $localize`Article Status`
  };

  public defaultActiveColumns = {
    headline: true,
    lastUpdate: true,
    byline: false,
    type: false,
    taxonomies: true,
    status: true
  };

  private activeColumns = null;
  private columnList = null;

  private columnListState = new BehaviorSubject(this.columnList);
  private activeColumnsState = new BehaviorSubject(this.activeColumns);

  constructor(private userPreferencesService: UserPreferencesService) { }

  getColumnList(): Observable<string[]> {
    this.columnList = this.userPreferencesService.getUserPreference('articleList.firstRowColumns') || this.defaultColumnList;
    this.columnListState.next(this.columnList);
    return this.columnListState.asObservable().pipe(distinctUntilChanged(isEqual),filter(c=>!!c));
  }

  getActiveColumns(): Observable<any> {
    this.activeColumns = this.userPreferencesService.getUserPreference('articleList.activeColumns') || this.defaultActiveColumns;
    this.activeColumnsState.next(this.activeColumns);
    return this.activeColumnsState.asObservable().pipe(distinctUntilChanged(isEqual), filter(c => !!c));
  }

  getMappedColumnList(): Observable<string[]> {
    return this.columnListState.asObservable().pipe(
      // note actions shouldn't be available on advanced filters column tab
      map(columns => columns.filter(column => column !== 'actions')),
      map(columns => columns.map(column => ({name: column, viewValue: this.columnMapping[column]})))
    )
  }

  setColumnList(columns) {
    if (!columns.includes('actions')) {
      columns.push('actions');
    }
    this.userPreferencesService.setUserPreference('articleList.firstRowColumns', columns);
    this.columnListState.next(columns);
  }

  setActiveColumns(columns) {
    this.userPreferencesService.setUserPreference('articleList.activeColumns', columns);
    this.activeColumnsState.next(columns);
  }

  setDefaultList(): void {
    this.setColumnList(this.defaultColumnList);
    this.setActiveColumns(this.defaultActiveColumns);
  }
}
