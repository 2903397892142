interface SortableByLabel {
  label: string;
}

export function sortByLabelAsc(a: SortableByLabel, b: SortableByLabel) {
  const aLabel: string = a.label.toLocaleLowerCase();
  const bLabel: string = b.label.toLocaleLowerCase();
  if (aLabel < bLabel) { return -1; }
  if (aLabel > bLabel) { return 1; }
  return 0;
}
