import { take, mergeMap, tap, map, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  GET_TEMPLATES,
  GetTemplatesActionSuccess,
  CREATE_TEMPLATE,
  CreateTemplateSuccessAction,
  DELETE_TEMPLATE,
  DeleteTemplateCompletedAction,
  TemplatesFailedAction,
  UPDATE_TEMPLATE,
  UpdateTemplateSuccessAction,
  PUBLISH_TEMPLATE,
  PublishTemplateSucessAction,
  UNPUBLISH_TEMPLATE,
  UnpublishTemplateSucessAction,
} from './templates.actions';
import { PageTemplatesService } from '../../api/page-templates/page-templates.service';
import { AppState } from '../app-reducer';
import { getActiveTemplate } from './templates.selectors';
import { PageTemplate } from './templates.model';
import { ClearEmptyNewTemplateAction } from './templates.actions';

@Injectable()
export class TemplatesEffects {
  // TODO handle account change

  
  createNewTemplate$: Observable<UnsafeAction> = createEffect(() => this.actions$.pipe(ofType(CREATE_TEMPLATE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.store.select(getActiveTemplate).pipe(
        take(1),
        mergeMap((activeTemplate) => {
          const templateToCreate = { ...activeTemplate, ...action.payload };
          delete templateToCreate.id;
          return this.pageTemplatesService.createTemplate(templateToCreate).pipe(
            tap((newTemplate: PageTemplate) => {
              this.snackBar.open($localize`Template saved`, $localize`Close`, { duration: 4000 });
              this.router.navigate(['site-builder/templates/', newTemplate && newTemplate.id]);
              setTimeout(() => this.store.dispatch(new ClearEmptyNewTemplateAction()), 20);
            }),
            map((newTemplate: PageTemplate) => new CreateTemplateSuccessAction(newTemplate))
          );
        })
      )
    )
  ));

  
  updateTemplate$: Observable<UnsafeAction> = createEffect(() => this.actions$.pipe(ofType(UPDATE_TEMPLATE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.store.select(getActiveTemplate).pipe(
        take(1),
        mergeMap((activeTemplate) => {
          const templateToCreate = { ...activeTemplate, ...action.payload };
          // don't send update information to API
          delete templateToCreate.updatedAt;
          delete templateToCreate.updatedBy;
          return this.pageTemplatesService.updateTemplate(templateToCreate).pipe(
            tap((newTemplate: PageTemplate) => {
              this.snackBar.open($localize`Template saved`, $localize`Close`, { duration: 4000 });
            }),
            map((updatedTemplate: PageTemplate) => new UpdateTemplateSuccessAction(updatedTemplate))
          );
        })
      )
    )
  ));

  
  loadTemplates$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_TEMPLATES),
    mergeMap((action: UnsafeAction) => {
      return this.pageTemplatesService.getTemplates().pipe(
        map((templates) => new GetTemplatesActionSuccess(templates)),
        catchError((e) => of(new TemplatesFailedAction({ error: e, action })))
      );
    })
  ));

  
  deleteTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_TEMPLATE),
    mergeMap((action: UnsafeAction) => {
      return this.pageTemplatesService.deleteTemplate(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Template deleted`, $localize`Close`, { duration: 4000 })),
        map(() => new DeleteTemplateCompletedAction(action.payload)),
        catchError((e) => of(new TemplatesFailedAction({ error: e, action })))
      );
    })
  ));

  
  publishTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(PUBLISH_TEMPLATE)).pipe(
    mergeMap((action: UnsafeAction) => {
      const templateId = action.payload.templateId;
      const revisionId = action.payload.revisionId;
      return this.pageTemplatesService.publishTemplate(templateId, revisionId).pipe(
        tap(() => this.snackBar.open($localize`Template published`, $localize`Close`, { duration: 4000 })),
        map((updatedTemplate) => new PublishTemplateSucessAction(updatedTemplate)),
        catchError((e) => of(new TemplatesFailedAction({ error: e, action })))
      );
    })
  ));

  
  unpublishTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UNPUBLISH_TEMPLATE),
    mergeMap((action: UnsafeAction) => {
      const templateId = action.payload.templateId;
      return this.pageTemplatesService.unpublishTemplate(templateId).pipe(
        tap(() => this.snackBar.open($localize`Template unpublished`, $localize`Close`, { duration: 4000 })),
        map((updatedTemplate) => new UnpublishTemplateSucessAction(updatedTemplate)),
        catchError((e) => of(new TemplatesFailedAction({ error: e, action })))
      );
    })
  ));

  // TODO impletent templates failed action

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    public snackBar: MatSnackBar,
    private pageTemplatesService: PageTemplatesService
  ) { }
}
