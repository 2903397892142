import { TemplateNode, PageTemplateRevision, PageWidget, LayoutBlock, TemplateNodeType } from './templates.model';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  CREATE_NODE, CREATE_NODE_COLLECTION,
  UPDATE_NODE_DATA, UPDATE_NODE_POSITION, DELETE_NODE,
} from './templates.actions';

interface NodePositionUpdateModel {
  nodeType: string;
  sourceContainerId: string;
  targetContainerId: string;
  sourceContainerNewNodePositions: any[];
  targetContainerNewNodePositions: any[];
}


export function activeTemplateRevisionReducer(state: PageTemplateRevision = null, action: UnsafeAction): PageTemplateRevision {

  if (state === null) {
    return null;
  }

  switch (action.type) {

    case UPDATE_NODE_POSITION: {
      const updateModel: NodePositionUpdateModel = action.payload;
      const nodes: TemplateNode[] = updateModel.nodeType === 'WIDGET' ? state.widgets : state.blocks;
      const parentChanged = updateModel.sourceContainerId !== updateModel.targetContainerId;
      const nodesToUpdate = parentChanged
        ? [...updateModel.sourceContainerNewNodePositions, ...updateModel.targetContainerNewNodePositions]
        : updateModel.targetContainerNewNodePositions;
      const updatedNodes = updateAllNodes(nodes, nodesToUpdate);
      return updateRevisionNodesOfType(state, updatedNodes, updateModel.nodeType);
    }

    case CREATE_NODE: {
      const node: TemplateNode = action.payload;
      const nodes: TemplateNode[] = node.type === 'WIDGET' ? state.widgets : state.blocks;
      const updatedNodes = addNewTemplateNodes(nodes, [node]);
      return updateRevisionNodesOfType(state, updatedNodes, node.type);
    }

    case CREATE_NODE_COLLECTION: {
      const newNodes: TemplateNode[] = action.payload;
      const nodes: TemplateNode[] = newNodes[0].type === 'WIDGET' ? state.widgets : state.blocks;
      const updatedNodes = addNewTemplateNodes(nodes, newNodes);
      return updateRevisionNodesOfType(state, updatedNodes, newNodes[0].type);
    }

    case UPDATE_NODE_DATA: {
      const node: TemplateNode = action.payload;
      const nodes: TemplateNode[] = node.type === 'WIDGET' ? state.widgets : state.blocks;
      const updatedNodes = findAndUpdateTemplateNode(nodes, node);
      return updateRevisionNodesOfType(state, updatedNodes, node.type);
    }

    case DELETE_NODE: {
      const node: TemplateNode = action.payload;
      const nodes: TemplateNode[] = node.type === 'WIDGET' ? state.widgets : state.blocks;
      const updatedNodes = findAndRemoveTemplateNode(nodes, node);
      return updateRevisionNodesOfType(state, updatedNodes, node.type);
    }

    default:
      return state;
  }

}

function updateRevisionNodesOfType(revision: PageTemplateRevision, nodes, type) {
  const updatedRevision: PageTemplateRevision = { ...revision };
  if (type === 'WIDGET') {
    updatedRevision.widgets = nodes as PageWidget[];
  }
  if (type === 'CONTAINER') {
    updatedRevision.blocks = nodes as LayoutBlock[];
  }
  return updatedRevision;
}

function addNewTemplateNodes(nodes: TemplateNode[], newNodes: TemplateNode[]) {
  // TODO process new nodes, update positions of siblings
  const newNodesCount = newNodes.length;
  const newNodesParentBlock = newNodes[0].parentBlockId;
  const newNodesFirstIndex = newNodes[0].position;
  const updatedNodes = nodes
    // update node positions for all nodes in the same container that come after the deleted node
    .map((n: TemplateNode) => {
      const sameParent = n.parentBlockId === newNodesParentBlock
        || (!n.parentBlockId && !newNodesParentBlock);
      const isFollowingCreatedNodes = sameParent && n.position >= newNodesFirstIndex;
      return isFollowingCreatedNodes ? { ...n, position: n.position + newNodesCount } : n;
    });

  return [
    ...updatedNodes,
    ...newNodes.map(processNewNodes)
  ];
}

function processNewNodes(node: TemplateNode, index: number) {
  const processedNode: any = { ...node };
  if (node.type === TemplateNodeType.Widget) {
    processedNode.additionalItems = {
      ...node.additionalItems,
      width: 100,
      widthUnits: '%'
    };
  }
  return processedNode;
}

function findAndUpdateTemplateNode(nodes: TemplateNode[], modifiedNode: TemplateNode) {
  const indexToModify = nodes.findIndex((n: TemplateNode) => n.id === modifiedNode.id);
  const updatedNode = { ...nodes[indexToModify], ...modifiedNode };
  return [
    ...nodes.slice(0, indexToModify),
    updatedNode,
    ...nodes.slice(indexToModify + 1)
  ];
}

function updateAllNodes(nodes: TemplateNode[], updatedNodes: TemplateNode[]) {
  const updatedNodesMap = updatedNodes.reduce((acc, n) => {
    acc[n.id] = n;
    return acc;
  }, {});
  return nodes.map((n: TemplateNode) => {
    if (!updatedNodesMap[n.id]) {
      return n;
    }
    return { ...n, ...updatedNodesMap[n.id] };
  });
}

function findAndRemoveTemplateNode(nodes: TemplateNode[], node: TemplateNode) {
  const validNodes = nodes.filter(function(item) {
    return item.id !== node.id && item.parentBlockId !== node.id;
  });

  // TODO reposition siblings belonign to the same container
  return [
    ...validNodes
  ]
    // update node positions for all nodes in the same container that come after the deleted node
    .map((n: TemplateNode) => {
      const isFollowingDeletedNode = n.parentBlockId === node.parentBlockId
        && n.position > node.position;
      return isFollowingDeletedNode ? { ...n, position: n.position - 1 } : n;
    });
}
