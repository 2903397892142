import { SamlConfig } from './sso.model';
import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from '@ngrx/store';
import { GET_SAML_CONFIG, GET_SAML_CONFIG_COMPLETE, RESET_SAML_CONFIG, SSO_PROVIDERS_FAILED, UPDATE_SAML_CONFIG, UPDATE_SAML_CONFIG_COMPLETE } from './sso.actions';

export interface SSOState {
  loaded: boolean;
  loading: boolean;
  error: string;
  samlConfig?: SamlConfig;
}

const initialState: SSOState = {
  loaded: false,
  loading: false,
  error: null,
  samlConfig: {
    issuer: null,
    type: 'SAML',
    certificate: null,
    enabled: false,
    url: null
  }
};

export function ssoReducer(state: SSOState = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_SAML_CONFIG:
    case UPDATE_SAML_CONFIG: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_SAML_CONFIG_COMPLETE: {
      const samlConfig = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        samlConfig
      };
    }

    case UPDATE_SAML_CONFIG_COMPLETE: {
      const updatedConfig = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        samlConfig: {
          ...state.samlConfig,
          ...updatedConfig
        }
      };
    }

    case SSO_PROVIDERS_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    }

    case RESET_SAML_CONFIG: {
      return initialState;
    }

    default:
      return state;
  }
}
export const getSSOState = (state) => state.sso;
export const getSsoProvidersLoading = createSelector(getSSOState, state => state.loading);
export const getSsoProvidersLoaded = createSelector(getSSOState, state => state.loaded);
export const getSamlConfig = createSelector(getSSOState, sso => sso.samlConfig);
