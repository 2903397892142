import { ModalsService } from './shared/modals/modals.service';
import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '../environments/environment';
import { debounceTime, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {
  initialIntervalRef;

  constructor(
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    private modalService: ModalsService
  ) {}

  runUpdateChecks() {
    // service workers are not enabled when using dev server, i.e. you have to make a build
    // and serve it on `localhost` via regular http server to test SW code
    if (!environment.production) {
      console.log('Skipping service worker update checks in non prod mode.');
      return;
    }

    // even if the browser supports ServiceWorker feature it requires HTTPS connection
    //  the API will be disabled unless using HTTPS or `localhost` to serve
    if (!this.swUpdate.isEnabled) {
      console.warn(
        'Cannot initialize updates available check as service worker API is not enabled!'
      );
      return;
    }

    // TODO take a look at GPP-2871 and GPP-2872 issues, we need a better way of handling this
    // this.checkIfUpdateIsPossible();

    // trigger initial check if there are any updates, and setup a polling for long term checks
    this.doInitialCheck();

    // when the new version becomes available and can be activated, notify the user
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      // can be used for debugging
      // map((evt) => ({
      //   type: 'UPDATE_AVAILABLE',
      //   current: evt.currentVersion,
      //   available: evt.latestVersion,
      // }))
      debounceTime(5000)
    );
    updatesAvailable.subscribe({
      next: () => {
        const snack = this.snackbar.open($localize`Update Available`, $localize`Reload`);
        snack.onAction().subscribe(() => {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        });
      },
      error: (err) => {
        console.error('App update error, failed to activate new version!', err);
        // this.displayAppUpdateError();
      },
    });

    // if we detect that the app update ended up in an unrecoverable state, notify the user
    this.swUpdate.unrecoverable.subscribe((err) => {
      console.error('App update in a unrecoverable state!', err);
      // this.displayAppUpdateError();
    });
  }

  /**
   * @deprecated This function checks if we can fetch the ngsw worker script file without any issues.
   * The goal is to confirm that Cognito isn't blocking the access to these files,
   * and if it does, we display a notification to the user suggesting they do SHIFT+F5 refresh
   */
  public checkIfUpdateIsPossible() {
    var cacheBuster = Math.random();
    const ngswScriptUrl = `/ngsw-worker.js?ngsw-bypass=true&ngsw-cache-bust=${cacheBuster}`;
    fetch(ngswScriptUrl, { method: 'HEAD', cache: 'no-cache' })
      .then((res) => {
        if (res.status >= 300) {
          throw new Error('SW_WORKER_SCRIPT_ERROR');
        }
      })
      .catch((err) => {
        console.error('Publisher update check error! Failed to fetch ngsw worker script!');
        // this.displayAppUpdateError();
      });
  }

  doInitialCheck() {
    let checksCount = 0;
    let initial = true;
    this.swUpdate.checkForUpdate().then((res) => {
      // do 3 checks in 45[s] interval, and check every half an hour thereafter
      this.initialIntervalRef = setInterval(() => {
        if (initial) {
          initial = false;
          return;
        }
        this.swUpdate.checkForUpdate();
        checksCount++;
        if (checksCount === 3) {
          clearInterval(this.initialIntervalRef);
          this.doLongTermChecks();
        }
      }, 45 * 1000);
    });
  }

  doLongTermChecks() {
    setInterval(() => {
      this.swUpdate.checkForUpdate();
    }, 30 * 60 * 1000);
  }

  displayAppUpdateError() {
    this.modalService.info(
      'Clear cache and refresh page',
      'App update error. To ensure latest GPP Publisher version is loaded please do a hard page refresh'
    );
  }
}
