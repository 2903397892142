import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UnsafeAction } from '../unsafe-action.interface';
import { VerifySettingsService } from '../../api/verify-settings/verify-settings.service';
import {
  GetVerifySettingsCompleteAction,
  VerifySettingsErrorAction,
  GET_VERIFY_SETTINGS,
  UpdateVerifySettingsCompletedAction,
  UPDATE_VERIFY_SETTINGS,
} from './verify-settings.actions';
import { mergeMap, catchError, map, withLatestFrom, tap, switchMap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class VerifySettingsEffects {
  constructor(
    private actions$: Actions,
    private verifySettingsService: VerifySettingsService,
    private snackBar: MatSnackBar
  ) {}

   getVerifySettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_VERIFY_SETTINGS),
    mergeMap((action: any) => {
      return this.verifySettingsService.getVerifySettings().pipe(
        map((response) => new GetVerifySettingsCompleteAction(response)),
        catchError((e) => of(new VerifySettingsErrorAction(e)))
      );
    })
  ));


  updateVerifySettings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(UPDATE_VERIFY_SETTINGS))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        return this.verifySettingsService.updateVerifySettings(action.payload).pipe(
          map(() => {
            this.snackBar.open($localize`Verify settings updated`, $localize`Close`, { duration: 2000 })
            return new UpdateVerifySettingsCompletedAction(action.payload)}),
          catchError((e) => {
            this.snackBar.open($localize`Verify settings update failed`, $localize`Close`, { duration: 2000 });
            return of(new VerifySettingsErrorAction({ error: e, action }));
          })
        );
      })
    ));
}
