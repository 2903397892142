// do custom mapping when locale codes for Froala and in genera don't match exactly
export function getLocaleCode() {
  const customLocaleMap = {
    'zh-CN': 'zh_cn',
    'fi-FI': 'fi',
    'ja-JP': 'ja_jp', // TODO verify
    'fi-fi': 'fi-fi', // TODO verify
  }
  return customLocaleMap[window.locale] || window.locale || 'en';
}
