import { Component, Input } from '@angular/core';

@Component({
  selector: 'gd-table-no-results',
  templateUrl: './table-no-results.component.html',
  styleUrls: ['./table-no-results.component.scss'],
})
export class TableNoResultsComponent {
  @Input() isTable;
  @Input() isMedia;
  @Input() message = $localize `Your search did not match any results.`;
  constructor() {}
}
