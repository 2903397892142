import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisLabelTooltipComponent } from './ellipsis-label-tooltip.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';



@NgModule({
  declarations: [
    EllipsisLabelTooltipComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [
    EllipsisLabelTooltipComponent
  ]
})
export class EllipsisLabelTooltipModule { }
