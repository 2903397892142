import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { UPDATE_LOCK_STATE, RELEASE_LOCK_SUCCESS, CLEAR_REFRESH_LOCK_INTERVAL, CHANGE_DETECTED, CLEAR_CHECK_LOCK_INTERVAL, CLEAR_INACTIVE_PERIOD_TIMEOUT } from './lock.actions';

export interface LockState {
  lockServicePayload: any;
  isLockedByAnotherUser: boolean;
  checkLockInterval: any;
  refreshLockInterval: any;
  inactivePeriodTimeout: any;
}

const initialState: LockState = {
  lockServicePayload: {},
  isLockedByAnotherUser: false,
  checkLockInterval: null,
  refreshLockInterval: null,
  inactivePeriodTimeout: null,
};

export function lockReducer(state: LockState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case UPDATE_LOCK_STATE: {
      const updateData = action.payload;
      if (updateData.isLockedByAnotherUser) {
        clearInterval(state.checkLockInterval);
        updateData.checkLockInterval = null;
      }
      return {
        ...state,
        ...updateData,
      };
    }

    case CLEAR_REFRESH_LOCK_INTERVAL: {
      clearInterval(state.refreshLockInterval);
      return {
        ...state,
        refreshLockInterval: null
      };
    }

    case CLEAR_CHECK_LOCK_INTERVAL: {
      clearInterval(state.checkLockInterval);
      return {
        ...state,
        checkLockInterval: null
      };
    }

    case CLEAR_INACTIVE_PERIOD_TIMEOUT: {
      clearTimeout(state.inactivePeriodTimeout);
      return {
        ...state,
        inactivePeriodTimeout: null
      };
    }

    case CHANGE_DETECTED: {
      clearInterval(state.checkLockInterval);
      clearTimeout(state.inactivePeriodTimeout);
      return state;
    }

    case RELEASE_LOCK_SUCCESS: {
      clearInterval(state.checkLockInterval);
      clearInterval(state.refreshLockInterval);
      clearTimeout(state.inactivePeriodTimeout);
      return initialState;
    }

    default: {
      return state;
    }
  }

}

export const getLockState = (state) => state.lock;

export const getLockedItemDetails = createSelector(getLockState, state => state.lockServicePayload);

export const isItemLocked = createSelector(getLockState, state => !state.isLockedByAnotherUser && !!state.refreshLockInterval);

export const getIsLockedFlag = createSelector(getLockState, state => state.isLockedByAnotherUser);
