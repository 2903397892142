import { UnsafeAction } from '../unsafe-action.interface';

export const GET_ACCOUNT_SETTINGS = '[ACCOUNT SETTINGS] Get Account settings';
export class GetAccountSettingsAction implements UnsafeAction {
  readonly type = GET_ACCOUNT_SETTINGS;
  constructor() { }
}

export const GET_ACCOUNT_SETTINGS_SUCCESS = '[ACCOUNT SETTINGS] Get Account settings success';
export class GetAccountSettingsSuccessAction implements UnsafeAction {
  readonly type = GET_ACCOUNT_SETTINGS_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_ACCOUNT_SETTINGS = '[ACCOUNT SETTINGS] Update Account settings';
export class UpdateAccountSettingsAction implements UnsafeAction {
  readonly type = UPDATE_ACCOUNT_SETTINGS;
  constructor(public payload: any) { }
}

export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = '[ACCOUNT SETTINGS] Update Account settings success';
export class UpdateAccountSettingsSuccessAction implements UnsafeAction {
  readonly type = UPDATE_ACCOUNT_SETTINGS_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_INTEGRATION_CONFIG = '[ACCOUNT SETTINGS] Update Integration Config';
export class UpdateIntegrationConfigAction implements UnsafeAction {
  readonly type = UPDATE_INTEGRATION_CONFIG;
  constructor(public payload: any) { }
}

export const UPDATE_INTEGRATION_CONFIG_SUCCESS = '[ACCOUNT SETTINGS] Update Integration Config success';
export class UpdateIntegrationConfigSuccessAction implements UnsafeAction {
  readonly type = UPDATE_INTEGRATION_CONFIG_SUCCESS;
  constructor() { }
}

export const UPDATE_SETTINGS_REFERENCED_CONTENT = '[ACCOUNT SETTINGS] Update settings referenced content';
export class UpdateSettingsReferencedContentAction implements UnsafeAction {
  readonly type = UPDATE_SETTINGS_REFERENCED_CONTENT;
  constructor(public payload: any) { }
}

export const REFRESH_ACCOUNT_SETTINGS = '[ACCOUNT SETTINGS] Refresh Account settings';
export class RefreshAccountSettingsAction implements UnsafeAction {
  readonly type = REFRESH_ACCOUNT_SETTINGS;
  constructor() { }
}

export const REFRESH_ACCOUNT_SETTINGS_SUCCESS = '[ACCOUNT SETTINGS] Refresh Account settings success';
export class RefreshAccountSettingsSuccessAction implements UnsafeAction {
  readonly type = REFRESH_ACCOUNT_SETTINGS_SUCCESS;
  constructor(public payload: any) { }
}

export const SET_ACCOUNT_SETTINGS_LOADING_FLAG = '[ACCOUNT SETTINGS] Set account settings loading flag';
export class SetAccountSettingsLoadingFlagAction implements UnsafeAction {
  readonly type = SET_ACCOUNT_SETTINGS_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const ACCOUNT_SETTINGS_ACTION_FAILED = '[ACCOUNT SETTINGS] Generic failure action';
export class AccountSettingsFailedAction implements UnsafeAction {
  readonly type = ACCOUNT_SETTINGS_ACTION_FAILED;
  constructor(public payload: any) { }
}
