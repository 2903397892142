import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AccountSettingsService } from '../../core/api/account-settings/accounts-settings.service';
import { BidiService } from '../../core/i18n/bidi.service';

export enum ExpandableChipTypes {
  TAG = 'tag',
  TAXONOMY = 'taxonomy',
  USER = 'user',
  DEFAULT = 'default',
}

@Component({
  selector: 'gd-expandable-chip-list',
  templateUrl: './expandable-chip-list.component.html',
  styleUrls: ['./expandable-chip-list.component.scss'],
})
// TODO add this component to post list page and replace author list and taxonomy list with this component
export class ExpandableListComponent implements OnInit, OnDestroy {
  @Input() chipFormControl: UntypedFormControl = new UntypedFormControl([]);
  @Input() type: ExpandableChipTypes = ExpandableChipTypes.DEFAULT;
  @Input() blueLineThreshold: number = 0;
  @Output() removeItem: EventEmitter<any> = new EventEmitter();

  @ViewChild('expandableWrapper', { static: false }) expandableView: ElementRef;

  expandableChipTypes = ExpandableChipTypes;
  componentSub: Subscription = new Subscription();
  showTaxonomyPath: boolean = this.accountSettingsService.getShowTaxonomyPathFlag();
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private accountSettingsService: AccountSettingsService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.componentSub.add(this.chipFormControl.valueChanges.subscribe((value) => {
      this.changeDetectorRef.detectChanges();
      this.addBlueLineToExpandable();
    }));
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }

  removeSelectedItem(item) {
    this.removeItem.emit(item);
  }

  addBlueLineToExpandable() {
    if (!this.expandableView) {
      return;
    }

    const nativeElement = this.expandableView.nativeElement;
    nativeElement.offsetHeight >= this.blueLineThreshold
      ? this.renderer.addClass(nativeElement.children[0].children[0], 'expandable')
      : this.renderer.removeClass(nativeElement.children[0].children[0], 'expandable');
  }

  chipColorMap = {
    'system' : 'rgb(190, 190, 190)',
    'rekognition' : '#ff4081',
    'user' : '#3f51b5'
  }
}
