import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { UnsafeAction } from '../unsafe-action.interface';
import { FilesService } from '../../api/files/files.service';
import {
  GET_FILES,
  GetFilesCompleteAction,
  DELETE_FOLDER,
  DELETE_FILE,
  CREATE_FOLDER,
  CreateFolderSuccessAction,
  CreateFolderFailedAction,
  GetFilesFailed,
  UPDATE_FILE_DETAILS,
  UpdateFileDetailsCompletedAction,
  FilesFailedAction,
  UPDATE_FOLDER_DETAILS,
  UpdateFolderDetailsCompletedAction,
} from './files.actions';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { mergeMap, catchError, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class FilesEffects {
  constructor(
    private actions$: Actions,
    private filesService: FilesService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}


  getFiles$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(GET_FILES),
      mergeMap((action: UnsafeAction) => {
      return this.filesService
        .getFiles(action.payload)
        .pipe(
          tap(res => {
            if (res.error) {
              this.router.navigate(['media/files/list/']);
              this.snackBar.open($localize`Invalid url`, $localize`Close`, { duration: 2000 });
            }
          }),
          map(files => new GetFilesCompleteAction(files.error ? { content: [] } : files)),
          catchError(e => of(new GetFilesFailed({ error: e })))
        );
      })
    ));


  createFolder$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(CREATE_FOLDER)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.filesService
        .createFolder(action.payload)
        .pipe(
          tap(response => {
            this.router.navigate([`media/files/list/${response.path}`]);
            return this.snackBar.open($localize`New Folder Created`, $localize`Close`, { duration: 2000 });
          }),
          map(response => new CreateFolderSuccessAction(response)),
          catchError(err => of(new CreateFolderFailedAction(err)))
        );
    })));


  deleteFolder$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(DELETE_FOLDER),
      mergeMap((action: UnsafeAction) => {
        return this.filesService.deleteFolder(action.payload.folderId).pipe(
          mergeMap(response => {
            return this.filesService
              .getFiles(action.payload)
              .pipe(
                map(files => {
                  this.router.navigate([`media/files/list/${action.payload.folderPath}`]);
                  return new GetFilesCompleteAction(files);
                }),
                catchError(e => of(new GetFilesFailed({ error: e, action })))
              );
          })
        );
      })
    ));


  deleteFile$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(DELETE_FILE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.filesService.deleteFile(action.payload.fileId).pipe(
        mergeMap(response => {
          return this.filesService
            .getFiles(action.payload)
            .pipe(
              map(files => new GetFilesCompleteAction(files)),
              catchError(err => of(new GetFilesFailed(err))),
            );
        })
      );
    })));


  updateFile$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(UPDATE_FILE_DETAILS)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.filesService
        .updateFileDetails(action.payload)
        .pipe(
          tap(() => this.snackBar.open($localize`File details saved`, $localize`Close`, { duration: 2000 })),
          map(() => new UpdateFileDetailsCompletedAction(action.payload)),
          tap(() => this.router.navigate(['/media/files/list/' + action.payload.fileData.path])),
          catchError(e => of(new FilesFailedAction({ error: e, action })))

        )
    })));


  updateFolder$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(UPDATE_FOLDER_DETAILS),
      mergeMap((action: UnsafeAction) => {
        return this.filesService
          .updateFolderDetails(action.payload)
          .pipe(
            tap(() => {
              const { folderData = {} } = action.payload;
              this.router.navigate(['/media/files/list/' + folderData.path]);
              this.snackBar.open($localize`Folder details saved`, $localize`Close`, { duration: 2000 });
            }),
            map(() => new UpdateFolderDetailsCompletedAction(action.payload)),
            catchError(e => of(new FilesFailedAction({ error: e, action })))

          );
      })
    ));
}
