import { ContentModel } from './../article/article-content.model';
import { find, get, has } from 'lodash-es';
import { createSelector } from "@ngrx/store";
import { AppState } from "../app-reducer";
import { UnsafeAction } from "../unsafe-action.interface";
import {
  CREATE_POST,
  CREATE_POST_SUCCESS,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  GET_ACTIVE_POST,
  GET_ACTIVE_POST_SUCCESS,
  GET_EMPTY_POST_LIST,
  GET_POST_LIST,
  GET_POST_LIST_SUCCESS,
  POST_LIST_FAILED,
  PUBLISH_POST,
  PUBLISH_POST_SUCCESS,
  UNPUBLISH_POST,
  UNPUBLISH_POST_SUCCESS,
  UPDATE_POST,
  UPDATE_POST_SUCCESS,
  UPDATE_POSTS_PAGE_VIEW,
  CLEAR_POST_BODY_EDITOR,
  COPY_POST_CONTENT_NODES,
  CREATE_POST_CONTENT_NODE,
  REMOVE_POST_CONTENT_NODE,
  UPDATE_POST_BODY,
  UPDATE_POST_CONTENT_NODE,
  UPDATE_POST_BODY_REFERENCED_CONTENT,
  LOAD_POST_BODY_INTO_EDITOR,
  UPDATE_POST_LIST_REFERENCED_CONTENT,
  MANAGE_POST_LIST_EMBEDDED_IMAGE_VISIBILITY,
  MANAGE_POST_EDITOR_EMBEDDED_IMAGE_VISIBILITY,
  LIVE_REPORT_TAB_CHANGE,
} from "./posts.actions";
import { PostsState, getPostsInitialState, Post } from "./posts.model";
import { postContentReducer } from './post-content.reducer';

const initialState: PostsState = getPostsInitialState();

export function postsReducer(state: PostsState = initialState, action: UnsafeAction) {
  switch (action.type) {

    case GET_POST_LIST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_POST_LIST_SUCCESS: {
      const posts = action.payload.data;

      const { number, size, total } = action.payload.meta.page;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        posts: posts,
        pageView: {
          pageIndex: number,
          pageSize: size,
          total
        }
      };
    }

    case DELETE_POST_SUCCESS: {
      const deletedId = action.payload;
      const activePostId = state.activePost && state.activePost.id;

      return {
        ...state,
        loading: false,
        posts: state.posts.filter(post => post.id !== deletedId),
        activePost: activePostId !== deletedId ? state.activePost : null
      };
    }

    case GET_ACTIVE_POST:
    case CREATE_POST:
    case UPDATE_POST:
    case DELETE_POST:
    case PUBLISH_POST:
    case UNPUBLISH_POST: {
      return { ...state, loading: true };
    }

    case GET_ACTIVE_POST_SUCCESS:
    case CREATE_POST_SUCCESS:
    case UPDATE_POST_SUCCESS:
    case UNPUBLISH_POST_SUCCESS: {
      const activePost = action.payload;
      return { ...state, activePost, loading: false };
    }

    // we clear the active post on publish to prepare for the next post
    case PUBLISH_POST_SUCCESS: {
      const activePost = null;
      return { ...state, activePost, loading: false };
    }

    case POST_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case GET_EMPTY_POST_LIST: {
      return getPostsInitialState();
    }

    case UPDATE_POSTS_PAGE_VIEW: {
      return {
        ...state,
        pageView: { ...state.pageView, ...action.payload }
      }
    }

    // post body editor reducer traps into here
    case UPDATE_POST_BODY:
    case LOAD_POST_BODY_INTO_EDITOR:
    case COPY_POST_CONTENT_NODES:
    case REMOVE_POST_CONTENT_NODE:
    case CREATE_POST_CONTENT_NODE:
    case UPDATE_POST_CONTENT_NODE:
    case CLEAR_POST_BODY_EDITOR:
    case MANAGE_POST_EDITOR_EMBEDDED_IMAGE_VISIBILITY:
    case UPDATE_POST_BODY_REFERENCED_CONTENT: {
      const newEditorModelState = postContentReducer(state.editorContentModel, action);
      return {
        ...state,
        editorContentModel: newEditorModelState
      };
    }

    case UPDATE_POST_LIST_REFERENCED_CONTENT: {
      const referencedContent = action.payload;
      return {
        ...state,
        postListReferencedContent: {
          ...state.postListReferencedContent,
          ...referencedContent
        }
      };
    }

    case MANAGE_POST_LIST_EMBEDDED_IMAGE_VISIBILITY: {
      const data = action.payload;
      const affectedNode = state.postListReferencedContent[data.id];
      if (!affectedNode || affectedNode.dataId !== data.dataId) {
        // stop executing image checker
        clearTimeout(data.timeout);
        return state;
      }
      return {
        ...state,
        postListReferencedContent: {
          ...state.postListReferencedContent,
          [data.id]: { ...affectedNode, src: data.src || affectedNode.src, isNotLoaded: data.isNotLoaded }
        }
      };
    }

    case LIVE_REPORT_TAB_CHANGE: {
      let activePost = null;
      if (state.activePost) {
        activePost = {
          ...state.activePost,
          workingRevision: {
            ...state.activePost?.workingRevision,
            body: {
              ...state.activePost.workingRevision.body,
              contentNodes: {...state.activePost.workingRevision.body.contentNodes }
            }
          }
        }
      }
      return {
        ...state,
        activePost
      };
    }
    
    default: {
      return state;
    }
  }
}

export const getPostsState = (state: AppState): PostsState => state.posts;
export const getListOfPosts = createSelector(getPostsState, (state) => state.posts);
export const getActivePost = createSelector(getPostsState, (state) => state.activePost);
export const getPostsPageView = createSelector(getPostsState, (state) => state.pageView);
export const getPostListReferencedContent = createSelector(getPostsState, (state) => state.postListReferencedContent);
export const getPostLoadingFlag = createSelector(getPostsState, state => state.loading)

export const getMapOfPosts = createSelector(getListOfPosts, listOfPosts => {
  return createMapOfList(listOfPosts);
});

function createMapOfList(list) {
  return list.reduce((map, post) => {
    return { ...map, [post.id]: post };
  }, {});
}

export const getPostWorkingRevision = createSelector(
  getPostsState,
  (state) => get(state, 'activePost.workingRevision', null)
);

export const getPostEditorBodyModel = createSelector(getPostsState, (state) => state.editorContentModel);

export const getPostContentNodesArray = createSelector(
  getPostEditorBodyModel,
  (contentModel: ContentModel) => {
    return Object.values(contentModel.contentNodes || {});
  }
);

export const getPostReferencedContent = createSelector(
  getPostEditorBodyModel,
  (contentModel: ContentModel) => {
    return contentModel.referencedContent;
  });

export const getPostReferencedContentNode = (id: string) => createSelector(
  getPostEditorBodyModel,
  (contentModel: ContentModel) => {
    return contentModel.referencedContent && contentModel.referencedContent[id];
  });

export const getPostContentNode = (id: string) => createSelector(
  getPostEditorBodyModel,
  (contentModel: ContentModel) => {
    if (!id) { return null; }
    const contentNodes = contentModel.contentNodes;
    const contentNode = { ...(Object.values(contentNodes).find((node: any) => node.id === id) as any) };

    const referencedContent = contentModel.referencedContent;
    const refEl = referencedContent ? Object.values(referencedContent).find((node: any) => node.id === id) : null;
    if (!refEl) {
      return contentNode;
    }

    Object.keys(refEl).filter(key => !contentNode.hasOwnProperty(key)).forEach(key => contentNode[key] = refEl[key]);
    return contentNode;
  }
);

export const getPostQuickViewContentNode = (id: string) =>
  createSelector(getListOfPosts, (posts) => {
    const post = find(posts, (post) => has(post, `workingRevision.body.contentNodes.${id}`));
    return get(post, `workingRevision.body.contentNodes.${id}`, null);
  });
