import { createSelector } from 'reselect';
import { UnsafeAction } from '../unsafe-action.interface';

import {
  GET_FILES,
  GET_FILES_COMPLETE,
  GET_FILES_FAILED,
  DELETE_FOLDER,
  DELETE_FILE,
  CREATE_FOLDER,
  UPDATE_FILE_DETAILS_COMPLETE,
  UPDATE_FOLDER_DETAILS_COMPLETE,
  RESET_FILES_LIST,
  SET_ACTIVE_FOLDER,
  SET_FILES_PAGE_VIEW_OPTIONS,
} from './files.actions';
import { defaultPageSizeOptions } from '../constants/default-pagination.constants';

export interface FilesState {
  loading: boolean;
  loaded: boolean;
  requestError: any;
  activeFolder: {};
  pageView: {
    pageIndex: number;
    total: number;
    pageSize: number;
    pageSizeOptions: number[];
  }
}

export const initialState = {
  loading: false,
  loaded: false,
  requestError: null,
  activeFolder: {
    path: '',
    id: null,
    parent: null,
    content: [],
  },
  pageView: {
    pageIndex: 0,
    total: 0,
    pageSize: 50,
    pageSizeOptions: defaultPageSizeOptions,
  },
};

export function filesReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_FILES:
    case DELETE_FOLDER:
    case DELETE_FILE:
    case CREATE_FOLDER: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_FILES_COMPLETE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        activeFolder: action.payload,
        pageView: { ...state.pageView, total: action.payload.itemCount }
      };
    }

    case GET_FILES_FAILED: {
      return {
        ...state,
        loaded: true,
        loading: false,
        files: {},
        activeFolder: {},
        requestError: action.payload,
      };
    }

    case UPDATE_FILE_DETAILS_COMPLETE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case UPDATE_FOLDER_DETAILS_COMPLETE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case RESET_FILES_LIST: {
      return {
        ...state,
        activeFolder: { ...initialState.activeFolder, path: '' }
      };
    }

    case SET_ACTIVE_FOLDER: {
      return {
        ...state,
        activeFolder: action.payload
      }
    }

    case SET_FILES_PAGE_VIEW_OPTIONS: {
      const pW = action.payload;
      const pageIndex = pW.hasOwnProperty('pageIndex') ? pW.pageIndex : state.pageView.pageIndex;
      const pageSize = pW.hasOwnProperty('pageSize') ? pW.pageSize : state.pageView.pageSize;
      return {
        ...state,
        pageView: {  ...state.pageView,  pageIndex, pageSize }
      };
    }

    default: {
      return state;
    }
  }
}

export const getFilesState = (state) => state.files;
export const getActiveFolder = createSelector(getFilesState, state => state.activeFolder);
export const getFiles = createSelector(getActiveFolder, state => state.content)
export const getFilesPageView = createSelector(getFilesState, state => state.pageView)
