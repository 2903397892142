import { UnsafeAction } from '../unsafe-action.interface';

export const SET_LOCK = '[LOCK] Set Lock';
export class SetLockAction implements UnsafeAction {
  readonly type = SET_LOCK;
  constructor(public payload: any) { }
}

export const UPDATE_LOCK_STATE = '[LOCK] Update Lock state';
export class UpdateLockStateAction implements UnsafeAction {
  readonly type = UPDATE_LOCK_STATE;
  constructor(public payload: any) { }
}

export const REFRESH_LOCK = '[LOCK] Refresh Lock';
export class RefreshLockAction implements UnsafeAction {
  readonly type = REFRESH_LOCK;
  constructor() { }
}

export const CHANGE_DETECTED = '[LOCK] Change detected';
export class ChangeDetectedAction implements UnsafeAction {
  readonly type = CHANGE_DETECTED;
  constructor() { }
}

export const RELEASE_LOCK = '[LOCK] Release Lock';
export class ReleaseLockAction implements UnsafeAction {
  readonly type = RELEASE_LOCK;
  constructor() { }
}

export const RELEASE_LOCK_SUCCESS = '[LOCK] Release Lock Success';
export class ReleaseLockSuccessAction implements UnsafeAction {
  readonly type = RELEASE_LOCK_SUCCESS;
  constructor() { }
}

export const CLEAR_REFRESH_LOCK_INTERVAL = '[LOCK] Clear Refresh Lock Interval';
export class ClearRefreshLockIntervalAction implements UnsafeAction {
  readonly type = CLEAR_REFRESH_LOCK_INTERVAL;
  constructor() { }
}

export const CLEAR_CHECK_LOCK_INTERVAL = '[LOCK] Clear Check Lock Interval';
export class ClearCheckLockIntervalAction implements UnsafeAction {
  readonly type = CLEAR_CHECK_LOCK_INTERVAL;
  constructor() { }
}

export const CLEAR_INACTIVE_PERIOD_TIMEOUT = '[LOCK] Clear Inactive Period Timeout';
export class ClearInactivePeriodTimeoutAction implements UnsafeAction {
  readonly type = CLEAR_INACTIVE_PERIOD_TIMEOUT;
  constructor() { }
}

export const LOCK_ACTION_FAILED = '[LOCK] Generic failure action';
export class LockFailedAction implements UnsafeAction {
  readonly type = LOCK_ACTION_FAILED;
  constructor(public payload: any) { }
}
