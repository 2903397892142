import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { UsersService } from '../../core/api/users/users.service';
import { AuthService } from '../../core/api';
import { AppState } from '../../core/store/app-reducer';
import { SetUsersLoadingFlagAction } from '../../core/store/users';
import { UserDetailsFormComponent } from '../../shared/user-details-form/user-details-form.component';
import { BidiService } from '../../core/i18n/bidi.service';


@Component({
  selector: 'gd-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  changePasswordForm: UntypedFormGroup;

  get passwordControl() {
    return <UntypedFormControl>this.changePasswordForm.get('password');
  }

  get confirmPasswordControl() {
    return <UntypedFormControl>this.changePasswordForm.get('confirmPassword');
  }
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();
  constructor(
    @Inject(MAT_DIALOG_DATA) public userDetails: any,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private store: Store<AppState>,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(.+)$/;
    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(12), Validators.pattern(passwordPattern)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: changePasswordFormValidator });
  }

  save() {
    const user = {
      ...this.userDetails.userData,
      password: this.passwordControl.value,
      accountId: this.authService.getUserAccountId()
    };

    this.store.dispatch(new SetUsersLoadingFlagAction(true));
    this.usersService
      .changeUserPassword({ userId: user.id, user, isProfilePage: this.userDetails.isProfilePage ? true : false}).toPromise()
      .then(() => {
        this.store.dispatch(new SetUsersLoadingFlagAction(false));
        this.snackBar.open($localize`Password changed successfully.`, $localize`Close`, { duration: 3000 });
      })
      .catch(() => {
        this.store.dispatch(new SetUsersLoadingFlagAction(false));
        this.snackBar.open($localize`Password change action failed.`, $localize`Close`, { duration: 3000 });
      });

    this.dialogRef.close();
  }

}

function changePasswordFormValidator(form: AbstractControl): { [key: string]: any | null } {
  const passwordControl = form.get('password');
  const confirmPasswordControl = form.get('confirmPassword');

  if (passwordControl.value !== confirmPasswordControl.value) {
    confirmPasswordControl.setErrors({ match: true });
    return { match: true };
  }
  confirmPasswordControl.setErrors(null);
  return null;
}
