import { mergeMap, map, tap, catchError } from 'rxjs/operators';
import { ArticleTypesService } from '../../api/article-types/article-types.service';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  DeleteArticleTypeAction,
  DeleteArticleTypeSuccessAction,
  CreateArticleTypeAction,
  CreateArticleTypeSuccessAction,
  UpdateArticleTypeAction,
  UpdateArticleTypeSuccessAction,
  LoadOneArticleTypeSuccessAction,
  LoadBasicArticleTypesSuccessAction,
} from './article-type.actions';
import { ArticleType } from './article-type.model';
import { LoadOneArticleTypeAction } from './article-type.actions';
import {
  ArticleTypeActionTypes,
  FailureArticleTypeAction,
  LoadArticleTypesSuccessAction,
} from './article-type.actions';

@Injectable()
export class ArticleTypesEffects {

  loadArticleTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ArticleTypeActionTypes.LOAD),
    mergeMap(() =>
      this.articleTypesService.getAll().pipe(
        map((types) => new LoadArticleTypesSuccessAction(types)),
        catchError((e) => of(new FailureArticleTypeAction({ error: e })))
      )
    )
  ));

  loadBasicArticleTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ArticleTypeActionTypes.LOAD_BASIC),
    mergeMap(() =>
      this.articleTypesService.getBasicProjection().pipe(
        map((types) => new LoadBasicArticleTypesSuccessAction(types)),
        catchError((e) => of(new FailureArticleTypeAction({ error: e })))
      )
    )
  ));

  loadOneArticleType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ArticleTypeActionTypes.LOAD_ONE),
    mergeMap((action: LoadOneArticleTypeAction) =>
      this.articleTypesService.getOne(action.payload).pipe(
        map((types) => new LoadOneArticleTypeSuccessAction(types)),
        catchError((e) => {
          this.snackBar.open($localize`Article type not found.`, $localize`Close`, { duration: 3000 });
          this.router.navigate(['site-builder/article-types']);
          return of(new FailureArticleTypeAction({ error: e }));
        })
      )
    )
  ));


  createArticleType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ArticleTypeActionTypes.CREATE),
    mergeMap((action: CreateArticleTypeAction) => {
      return this.articleTypesService.create(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Article type saved`, $localize`Close`, { duration: 2000 });
          this.router.navigate(['site-builder/article-types']);
        }),
        map((newArticleType: ArticleType) => new CreateArticleTypeSuccessAction(newArticleType)),
        catchError((e) => of(new FailureArticleTypeAction({ error: e })))
      );
    })
  ));


  updateArticleType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ArticleTypeActionTypes.UPDATE),
    mergeMap((action: UpdateArticleTypeAction) => {
      return this.articleTypesService.update(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Article type saved`, $localize`Close`, { duration: 2000 });
          this.router.navigate(['site-builder/article-types']);
        }),
        map((newArticleType: ArticleType) => new UpdateArticleTypeSuccessAction(newArticleType)),
        catchError((e) => of(new FailureArticleTypeAction({ error: e })))
      );
    })
  ));


  deleteArticleType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ArticleTypeActionTypes.DELETE),
    mergeMap((action: DeleteArticleTypeAction) => {
      const idToDelete = action.payload;
      return this.articleTypesService.delete(idToDelete).pipe(
        tap(() => this.snackBar.open($localize`Article type deleted`, $localize`Close`, { duration: 2000 })),
        map(() => new DeleteArticleTypeSuccessAction(idToDelete)),
        catchError(() => {
          this.snackBar.open($localize`Cannot delete Article Type assigned to an Article.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new DeleteArticleTypeSuccessAction(null));
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private articleTypesService: ArticleTypesService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}
}
