import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FilesUploadService {
  uploadParams = {
    service: 'glideMedia',
    headers: {},
    isPlainText: true,
    omitDefaultHeaders: false,
  };

  uploadProgress = new BehaviorSubject({});
  tempUploadQueue = {};

  constructor(private rest: RestService, private httpClient: HttpClient) {}

  createFileRequest(files, activeFolder) {
    const payload = files.map(file => {
      return {
        filename: file.filename || file.fileData.name,
        contentType: file.fileData.type,
        metaData: file.metaData || {},
        folder: activeFolder.id,
      };
    });
    return this.rest.post('files', payload, this.uploadParams);
  }

  uploadFile(file: File, url: string) {
    const uploadRequest = new HttpRequest('PUT', url, file, {
      reportProgress: true,
      headers: new HttpHeaders({
        'ngsw-bypass': 'true',
      }),
    });

    return this.httpClient.request(uploadRequest);
  }

  fileSizeLimitExceeds(sizeInBytes) {
    return sizeInBytes > 106 * 1000000;
  }

  dispatchUploadProgress(fileProgress) {
    this.uploadProgress.next(fileProgress);
  }

  getUploadProgress() {
    return this.uploadProgress.asObservable();
  }

  generateName(name, usedNames, counter = 0, rawName = null) {
    rawName = rawName || name;
    if (!usedNames.includes(name)) {
      return name;
    }
    name = rawName;
    counter++;
    const extension = name.slice(name.lastIndexOf('.'), name.length);
    name = name.slice(0, name.lastIndexOf('.'));
    name += ` (${counter})${extension}`;
    return this.generateName(name, usedNames, counter, rawName);
  }

}
// file extension and their MIME types that doesn't get readed by browser
// mimeTypes = {
//   docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
//   ppt: 'application/vnd.ms-powerpoint',
//   rar: 'application/x-rar-compressed',
//   xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   xls: 'application/vnd.ms-excel',
//   odt: 'application/vnd.oasis.opendocument.text',
//   ods: 'application/vnd.oasis.opendocument.spreadsheet',
//   odp: 'application/vnd.oasis.opendocument.presentation',
//   csv: 'text/csv',
// };
