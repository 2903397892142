<div class="gd-image-cropper"
     [class.main]="mainCropper"
     fxLayout="row"
     fxLayoutAlign="space-between">

  <!-- Main cropper initialization loading overlay -->
  <div class="gd-image-cropper__loading-spinner"
       fxLayout="row"
       fxLayoutAlign="center center"
       *ngIf="mainCropper && !cropperInitialized">
    <mat-spinner diameter="70"
                 color="accent">
    </mat-spinner>
  </div>

  <div class="gd-image-cropper__canvas-wrapper"
       [class.main-cropper]="mainCropper"
       [class.mat-elevation-z2]="!mainCropper">
    <div class="gd-image-cropper__canvas"
         [class.compact]="!mainCropper">
      <div class="gd-image-cropper__focal-point-wrapper"
           *ngIf="canvasData && mainCropper"
           [style.top.px]="canvasData.top"
           [style.left.px]="canvasData.left"
           [style.width.px]="canvasData.width"
           [style.height.px]="canvasData.height">
        <i *ngIf="(mainCropper && focalPointPosition$ | async) as fp"
           class="gd-image-cropper__focal-point far fa-dot-circle"
           matTooltip="Drag to move or click to remove"
           matTooltipPosition="right"
           [matTooltipDisabled]="focalPointMoving"
           i18n-matTooltip
           [style.opacity]="focalPointInsideOfCroparea ? '1' : '0.2'"
           [style.z-index]="200"
           [style.cursor]="focalPointMoving ? 'grabbing' : 'grab'"
           (click)="removeFocalPoint($event)"
           cdkDragBoundary=".gd-image-cropper__focal-point-wrapper"
           cdkDrag
           [cdkDragFreeDragPosition]="{ x: fp.x - 11, y: fp.y - 11 }"
           (cdkDragStarted)="focalPointMoving = true"
           (cdkDragEnded)="handleFocalPointMoveEvent($event)"></i>
      </div>
      <img class="gd-image-cropper__canvas-image">
    </div>
  </div>

  <div fxLayout="column"
       fxLayoutAlign="space-between"
       class="gd-image-cropper__canvas-actions"
       *ngIf="!mainCropper">
    <h4> {{ imageSizeData?.label }} </h4>
    <div>
      <p>
        <span style="margin-right: 12px" i18n>Width: {{ imageSizeData?.width }}</span>
        <span i18n>Height: {{ imageSizeData?.height }}</span>
      </p>
      <p *ngIf="imageSizeData?.crop_position_label !== ''" i18n>Crop position: {{ imageSizeData?.crop_position_label }}</p>
      <p fxLayout="row"
         fxLayoutAlign="center"
         *ngIf="getUserFriendlyRatio(imageSizeData.width, imageSizeData.height) as formattedRatio">
         <span i18n>Aspect ratio: {{ formattedRatio }}</span>
        <mat-icon class="gd-image-cropper__info-icon"
                  matTooltip="Please note that this rendition is not a perfect ratio'"
                  i18n-matTooltip
                  *ngIf="formattedRatio.includes('*')">
          info_outline
        </mat-icon>
      </p>
    </div>
    <p>
      <button mat-button
              mat-flat-button
              class="gd-image-cropper__btn-lock">
        <mat-icon (click)="handleCropLockEvent($event)">
          {{ imageSizeData?.modified ? 'lock' : 'lock_open' }}
        </mat-icon>
      </button>
    </p>
  </div>

  <div *ngIf="imageSizeData"
       class="gd-image-cropper__preview-wrapper">
    <div class="gd-image-cropper__preview--outline-wrapper mat-elevation-z2">
      <div [id]="'crop-preview-' + imageSizeData?.key"
           class="gd-image-cropper__preview {{ imageSizeData?.key }}">
      </div>
    </div>
  </div>

</div>
