<ul class="breadcrumbs"
    fxLayout="row" [dir]="dir$ | async">
  <li *ngFor="let url of urls; let last = last; let first = first;"
      [ngClass]="{'active': last}"
      fxLayout="row"
      fxLayoutAlign="center center">
    <!-- disable link of last item -->
    <mat-icon class="ms-breadcrumbs__navigate-divider"
              *ngIf="!first"
              fontSet="fal"
              [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"></mat-icon>

    <a role="button"
       *ngIf="!last && url === prefix">{{url}}</a>
    <a role="button"
       *ngIf="!last && url !== prefix && !(first && noIndexPage.includes(url))"
       mat-button
       style='min-width: 0px;'
       class="ms-breadcrumbs__navigate-button"
       [routerLink]="url">{{friendlyName(url)}}</a>

    <span class="ms-breadcrumbs__navigate-label-last"
          *ngIf="last || (first && noIndexPage.includes(url))"
          style="padding: 0px 4px">{{friendlyName(url)}}</span>
    <span class="ms-breadcrumbs__navigate-label-last"
          *ngIf="last && url === prefix">{{friendlyName('/')}}</span>
  </li>
</ul>


