import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  GET_CONTENT_FILTERS,
  GetContentFiltersSuccessAction,
  ContentFiltersFailedAction,
  CREATE_CONTENT_FILTER,
  CreateContentFilterSuccessAction,
  UPDATE_CONTENT_FILTER,
  UpdateContentFilterSuccessAction,
  DELETE_CONTENT_FILTER,
  DeleteContentFilterSuccessAction,
  SET_DEFAULT_CONTENT_FILTER,
  SetDefaultContentFilterSuccessAction,
  ClearDefaultContentFilterSuccessAction,
  CLEAR_DEFAULT_CONTENT_FILTER,
} from './advanced-filtering-option.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { ContentFiltersService } from '../../api/content-filters/content-filters.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class AdvancedFilteringOptionEffects {

  loadContentFilters$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_CONTENT_FILTERS),
    mergeMap((action: UnsafeAction) => {
      return this.contentFiltersService.getContentFilters(action.payload).pipe(
        map((response) => new GetContentFiltersSuccessAction(response)),
        catchError((e) => {
          this.snackBar.open($localize`Failed to load content filters!`, $localize`Close`);
          return of(new ContentFiltersFailedAction(e));
        })
      ) as Observable<UnsafeAction>;
    })
  ));


  createContentFilter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_CONTENT_FILTER),
    mergeMap((action: UnsafeAction) => {
      const contentFilter = action.payload;
      delete contentFilter.id;
      return this.contentFiltersService.createContentFilter(contentFilter).pipe(
        map((data) => new CreateContentFilterSuccessAction(data)),
        tap(() =>
          this.snackBar.open($localize`Content filter created successfully.`, $localize`Close`, { duration: 4000 })
        ),
        catchError((e) => {
          this.snackBar.open($localize`Failed to create content filter!`, $localize`Close`);
          return of(new ContentFiltersFailedAction(e));
        })
      ) as Observable<UnsafeAction>;
    })
  ));


  updateContentFilter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_CONTENT_FILTER),
    mergeMap((action: UnsafeAction) => {
      return this.contentFiltersService.updateContentFilter(action.payload).pipe(
        map((data) => new UpdateContentFilterSuccessAction(data)),
        tap(() =>
          this.snackBar.open($localize`Content filter successfully updated.`, $localize`Close`, { duration: 4000 })
        ),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update content filter!`, $localize`Close`);
          return of(new ContentFiltersFailedAction(e));
        })
      ) as Observable<UnsafeAction>;
    })
  ));


  deleteContentFilter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_CONTENT_FILTER),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.contentFiltersService.deleteContentFilter(id).pipe(
        map(() => new DeleteContentFilterSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Content filter deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Failed to delete content filter!`, $localize`Close`);
          return of(new ContentFiltersFailedAction(e));
        })
      );
    })
  ));


  setDefaultContentFilter$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(SET_DEFAULT_CONTENT_FILTER))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        const id = action.payload;
        return this.contentFiltersService.setDefaultFilter(id).pipe(
          map(() => new SetDefaultContentFilterSuccessAction(action.payload)),
          tap(() =>
            this.snackBar.open($localize`Default Content filter is set.`, $localize`Close`, { duration: 4000 })
          ),
          catchError((e) => {
            this.snackBar.open($localize`Failed to set default content filter!`, $localize`Close`);
            return of(new ContentFiltersFailedAction(e));
          })
        ) as Observable<UnsafeAction>;
      })
    ));


  clearDefaultContentFilter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CLEAR_DEFAULT_CONTENT_FILTER),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.contentFiltersService.clearDefaultFilter(id).pipe(
        map(() => new ClearDefaultContentFilterSuccessAction(action.payload)),
        tap(() =>
          this.snackBar.open($localize`Default Content filter is removed.`, $localize`Close`, { duration: 4000 })
        ),
        catchError((e) => {
          this.snackBar.open($localize`Failed to remove default content filter!`, $localize`Close`);
          return of(new ContentFiltersFailedAction(e));
        })
      ) as Observable<UnsafeAction>;
    })
  ));

  constructor(
    private actions$: Actions,
    private contentFiltersService: ContentFiltersService,
    private snackBar: MatSnackBar
  ) {}
}
