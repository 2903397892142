import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from '../rest.service';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(private rest: RestService) {}

  getPages() {
    return this.rest.get('pages').pipe(map((pagesData: any) => pagesData.data));
  }

  getPageById(id) {
    return this.rest.get('pages/' + id).pipe(map((pageData: any) => pageData.data));
  }

  createPage(newPage) {
    const createPayload = { ...newPage };
    return this.rest.post('pages', createPayload).pipe(map((response: any) => response.data));
  }

  updatePage(page) {
    return this.rest.put('pages/' + page.id, page).pipe(map((response: any) => response.data));
  }

  deletePage(id) {
    return this.rest.delete('pages/' + id);
  }

  publishPage(id) {
    return this.rest.post(`pages/${id}/publish`).pipe(map((response: any) => response.data));
  }

  unpublishPage(id) {
    return this.rest.post(`pages/${id}/unpublish`).pipe(map((response: any) => response.data));
  }
}
