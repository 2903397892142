import { Directive, HostBinding, HostListener, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SidenavService } from './sidenav.service';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { Subscription } from 'rxjs';
import { SidenavScreenSizeService } from './sidenav-screen-size.service';

@Directive({
  selector: '[gdIconSidenav]',
})
export class IconSidenavDirective implements OnInit, OnDestroy {

  @Output() collapsedChange = new EventEmitter();

  private screenSizeSubscription: Subscription;

  isMobile = false;
  isSmallDisplay = false;

  @HostBinding('class.icon-sidenav')
  get isIconSidenav(): boolean {
    return this.sidenavService.isIconSidenav;
  }

  @HostBinding('class.collapsed')
  collapsed: boolean = false;

  currentlyOpen: SidenavItem[];

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.isIconSidenav && this.isSmallDisplay) {
      this.collapsed = false;
      this.collapsedChange.next(this.collapsed);
      return;
    }

    if ((this.isIconSidenav && !this.isMobile) || this.isSmallDisplay) {
      this.collapsed = false;
      this.collapsedChange.next(this.collapsed);
      this.sidenavService.nextCurrentlyOpen(this.currentlyOpen);
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (!this.isIconSidenav && this.isSmallDisplay) {
      this.collapsed = false;
      this.collapsedChange.next(this.collapsed);
      return;
    }

    if ((this.isIconSidenav && !this.isMobile) || this.isSmallDisplay) {
      this.collapsed = true;
      this.collapsedChange.next(this.collapsed);
      this.currentlyOpen = this.sidenavService.currentlyOpen;
      this.sidenavService.nextCurrentlyOpen([]);
    }
  }

  constructor(
    private sidenavService: SidenavService,
    private sidenavScreenSizeService: SidenavScreenSizeService
  ) {}

  ngOnInit() {
    this.currentlyOpen = this.sidenavService.currentlyOpen;
    this.sidenavService.nextCurrentlyOpen([]);

    this.screenSizeSubscription = this.sidenavScreenSizeService
      .getSizeState()
      .subscribe((screenState) => {
        const { isMobile, isSmallScreen, isLarge } = screenState;
        this.isMobile = isMobile;
        this.isSmallDisplay = isSmallScreen;

        if (isLarge && this.collapsed) {
          this.sidenavService.isIconSidenav = true;
        }

        // small screen: the side nav is pinned over the content
        if (!this.isIconSidenav && this.isSmallDisplay) {
          return;
        }

        if (this.isSmallDisplay) {
          this.collapsed = true;
          this.collapsedChange.next(this.collapsed);
          this.sidenavService.nextCurrentlyOpen([]);
          return;
        }

        this.sidenavService.nextCurrentlyOpen(this.currentlyOpen || []);
      });
  }

  ngOnDestroy() {
    this.screenSizeSubscription.unsubscribe();
  }
}
