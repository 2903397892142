<div class="gm-move-to-view__list-card" [dir]="dir$ | async">
  <div class="gd-move-to-view__breadcrumbs">
    <mat-icon matTooltip="Back"
              i18n-matTooltip
              [class.--disabled]="!loaded || currentFolder.path === '/'"
              (click)="goBack()"
              fontSet="fal"
              fontIcon="fa-arrow-left"></mat-icon>

    <span *ngIf="currentFolder.name" class="gd-move-to-view__folder-name-span">{{ currentFolder.name }}</span>
    <span *ngIf="!currentFolder.name" class="gd-move-to-view__files-span" i18n>Files</span>
  </div>
  <div class="gm-move-to-view__content">
    <mat-table #table [dataSource]="folderData$ | async">
      <ng-container matColumnDef="filename">
        <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
        <mat-cell *matCellDef="let element"
                  (click)="selectFolder(element)"
                  (dblclick)="openFolder($event, element)"
                  class="gm-move-to__name-cell"
                  [class.--selected]="selectedFolder.folderId === element.id"
                  [class.--disabled]="element.type !== 'folder'">
          <div fxFlex="100%"
               fxLayout="row"
               fxLayoutAlign="start center">
            <div class="gd-move-to-view__list-thumbnail">
              <mat-icon *ngIf="element.isCustomSvgIcon" class="gd-folder-thumbnail" svgIcon="{{ element.thumbnail }}"></mat-icon>
              <mat-icon *ngIf="!element.isCustomSvgIcon" class="gd-folder-thumbnail" fontSet="fal" fontIcon="{{ element.thumbnail }}"></mat-icon>
            </div>
            <div class="gd-folder-name">{{ element.filename }}</div>
          </div>
          <mat-icon class="gm-move-to__folder-open"
                    matTooltip="Open Folder"
                    i18n-matTooltip
                    (click)="openFolder($event, element)"
                    fontSet="fal"
                    fontIcon="fa-angle-right"></mat-icon>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <!-- If there is no content/files to view -->
    <div class="gm-move-to__table-no-results" *ngIf="(folderData$ | async)?.length === 0 && loaded">
      <gd-table-no-results [isTable]="true" [isMedia]="false" i18n-message message="Folder is empty.">
      </gd-table-no-results>
    </div>
  </div>
  <div class="gm-move-to-view__list-buttons">
    <!-- Hidden input added to avoid initial focus on close button -->
    <input type="text" style="opacity: 0">
    <button mat-button mat-raised-button mat-dialog-close class="gm-move-to-view__close-btn" i18n>Close</button>
    <button mat-button mat-raised-button color="primary" (click)="moveFolder()"> <span i18n>Move</span> {{generateMoveMessage()}}</button>
  </div>
</div>
