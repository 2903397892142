import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RestService } from '../rest.service';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
import shortid from 'shortid';
import * as EXIF from '../../../../assets/exif';

@Injectable({
  providedIn: 'root',
})
export class ImagesUploadService {
  uploadParams = {
    service: 'glideMedia',
    headers: {},
    isPlainText: true,
    omitDefaultHeaders: false,
  };

  uploadProgress = new BehaviorSubject({});

  allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/avif'];

  tempUploadQueue = {};

  constructor(
    private rest: RestService,
    private httpClient: HttpClient,
    private sanitazer: DomSanitizer
  ) {}

  createImageRequest(imageFiles) {
    const payload = imageFiles.map(file => {
      const imageMeta = file.meta.map(metaData => ({
        ...metaData,
        value: JSON.stringify(metaData.value),
      }));

      let imageUploadDetails = <any>{};

      if (file.cropped) {
        imageUploadDetails = Object.values(file.crops).reduce((acc: any, crop: any) => {
          const cropPayload = <any>{};

          cropPayload.label = crop.label;
          cropPayload.key = crop.key;

          if (crop.cropbox) {
            cropPayload.cropbox = crop.cropbox;
          }

          return [...acc, cropPayload];
        }, []);

        imageMeta.push({ key: 'upload_details', value: JSON.stringify(imageUploadDetails) });
      }

      const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
      const data: any = {
        name: file.fileData.name.replace(emojiRegex, ''),
        type: file.fileData.type,
        width: file.dimensions.width,
        height: file.dimensions.height,
        meta: imageMeta,
        tags: file.tags,
        premiumTag: file.premiumTag,
        provider: file.provider,
        systemTags: file.systemTags,
      };

      if (file.provider && file.original) {
        data.import_image_url = file.original;
      }

      return data;
    });

    return this.rest.post('images', payload, this.uploadParams)
      .pipe(map(data => data.map((item, i) => ({ ...item, queueID: imageFiles[i]?.queueID }))));
  }

  uploadImage(image: File, url: string) {
    const uploadRequest = new HttpRequest('PUT', url, image, {
      reportProgress: true,
      headers: new HttpHeaders({
        'ngsw-bypass': 'true',
      }),
    });
    return this.httpClient.request(uploadRequest);
  }

  getImageBlobURL(image) {
    return new Promise(resolve => {
      const worker = new Worker(new URL('./image.worker', import.meta.url), { type: 'module' });
      worker.postMessage({ type: 'READ_BLOB', payload: image.fileData });

      worker.onmessage = e => {
        const { payload } = e.data;
        resolve(payload);
      };
    });
  }

  readFileDataURL(image) {
    return new Promise(resolve => {
      const worker = new Worker(new URL('./image.worker', import.meta.url), { type: 'module' });
      worker.postMessage({ type: 'READ_DATAURL', payload: image.fileData });

      worker.onmessage = e => {
        const { payload } = e.data;
        resolve({ image, dataURL: payload });
      };
    });
  }

  prepareImageForUpload(image, queueID) {
    return {
      queueID: queueID ? queueID : shortid.generate(10),
      fileData: image,
    };
  }

  createImageThumbnail(entry, args = {}) {
    return new Promise(async resolve => {
      const imageBlobURL: any =
        typeof entry === 'object' ? entry.imgURL : entry;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const image = new Image();

      image.src = imageBlobURL;
      image.onload = () => {
        this.resizeImage(canvas, context, image, args);
        canvas.toBlob(imgBlob => {
          const blobUrl = URL.createObjectURL(imgBlob);
          resolve(blobUrl);
        });
      };
      image.onerror = () => resolve({});
    });
  }

  resizeImage(canvas, context, imageObj, { width = 400, height = 260 }) {
    canvas.width = width;
    canvas.height = height;

    const imageAspectRatio = imageObj.width / imageObj.height;
    const canvasAspectRatio = canvas.width / canvas.height;
    let renderableWidth;
    let renderableHeight;
    let xStart = 0;
    let yStart = 0;

    const orientation = imageObj.width < imageObj.height ? 'portrait' : 'landscape';

    if (orientation === 'portrait') {
      renderableWidth = canvas.width;
      renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
      yStart = (canvas.height - renderableHeight) / 2;
    }

    if (orientation === 'landscape') {
      if (imageAspectRatio < canvasAspectRatio) {
        renderableWidth = canvas.width;
        renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
      } else if (imageAspectRatio > canvasAspectRatio) {
        renderableHeight = canvas.height;
        renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
        xStart = (canvas.width - renderableWidth) / 2;
      } else {
        renderableWidth = canvas.width;
        renderableHeight = canvas.height;
      }
    }

    context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
  }

  isAllowedType(fileType) {
    return this.allowedTypes.includes(fileType);
  }

  fileSizeLimitExceeds(sizeInBytes) {
    return sizeInBytes > 32000000;
  }

  getUploadProgress() {
    return this.uploadProgress.asObservable();
  }

  dispatchUploadProgress(imgProgress) {
    this.uploadProgress.next(imgProgress);
  }

  sanitazeBlobURL(url) {
    return this.sanitazer.bypassSecurityTrustUrl(url);
  }

  getNaturalDimensions(imageBlob) {
    return new Promise(resolve => {
      const img = new Image();
      img.src = imageBlob;
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = () => resolve({});
    });
  }

  formatBytes(bytes) {
    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(1) + ' KB';
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(1) + ' MB';
    } else {
      return (bytes / 1073741824).toFixed(1) + ' GB';
    }
  }

  extractImageMetadata(image) {
    return new Promise(resolve => {
      if (image.fileData) {
        EXIF.getData(image.fileData, () => {
          if (Object.keys(image.fileData.iptcdata).length === 0) {
            resolve(null);
          } else {
            const iptc = Object.entries(image.fileData.iptcdata)
              .map(([key, value]: any) => {
                if (key === 'keywords' && Array.isArray(value)) {
                  value = value.join(', ');
                }
                key = key.replace(/([^a-z])/g, char => ` ${char.toLocaleLowerCase()}`);
                return { key: key, value: value };
              })
              .reverse();

            resolve(iptc);
          }
        });
      }
    });
  }

  findInTempQueue(imageID) {
    return this.tempUploadQueue[imageID];
  }

  removeFromTempQueue(imageID) {
    delete this.tempUploadQueue[imageID];
  }

  setTempUploadQueue(uploadQueue: any) {
    this.tempUploadQueue = uploadQueue;
  }

  generateImage(payload) {
    return this.rest.post('bedrock/generate-image', payload, { service: 'glideMedia' })
    .pipe(map(data => data.imageBase64)).toPromise();
  }

}
