import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ImageDownloadComponent } from '../image-download/image-download.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { environment } from '../../../environments/environment';
import { ImagesService } from '../../core/api/images/images.service';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
})
export class FileDownloadComponent implements OnInit {
  file;

  @ViewChildren('inputField') inputField: QueryList<HTMLInputElement>;
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    public dialogRef: MatDialogRef<ImageDownloadComponent>,
    private store: Store<AppState>,
    private imagesService: ImagesService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.file = {
      ...this.dialogInputData.data,
      url: this.imagesService.generateImageUrl(this.dialogInputData.data.key),
    };
  }

  handleCopyFileLink(url) {
    const linkToCopy: any = this.inputField.find((field: any) => field.nativeElement.value === url);
    linkToCopy.nativeElement.select();
    document.execCommand('copy');
  }

  handleFileDownload(link) {
    const linkChunks = link.split('/');
    const fileName = linkChunks[linkChunks.length - 1];
    const linkToVisit = document.createElement('a');
    linkToVisit.target = '_blank';
    linkToVisit.href = link;
    linkToVisit.download = fileName;
    linkToVisit.style.display = 'none';
    document.body.appendChild(linkToVisit);
    linkToVisit.click();
    document.body.removeChild(linkToVisit);
  }
}
