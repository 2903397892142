import { createSelector } from 'reselect';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import { UnsafeAction } from '../unsafe-action.interface';
import { COPY_LIVE_REPORT_URL, COPY_LIVE_REPORT_URL_SUCCESS, DELETE_LIVE_REPORT, DELETE_LIVE_REPORT_SUCCESS, GET_LIVE_REPORTS_LIST, GET_LIVE_REPORTS_LIST_SUCCESS, SET_LIVE_REPORTS_LIST_PAGE_VIEW } from './live-reports-list.action';

export interface LiveReport {
  id: number;
  workingRevision: any;
  publishedRevision?: any;
  postCount: number;
  createdAt: number,
  updatedAt: number,
  firstPublishedAt: number,
  lastPublishedAt: number,
  createdBy: number,
  updatedBy: number,
  firstPublishedBy: number,
  lastPublishedBy: number
}

export interface LiveReportsListState {
  loaded: boolean;
  loading: boolean;
  error: string;
  liveReports: LiveReport[];
  pageView: any;
}

const initialState: LiveReportsListState = {
  loaded: false,
  loading: false,
  error: null,
  liveReports: [],
  pageView: {
    pageIndex: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions,
    total: 0
  }
};

export function liveReportsListReducer(state: LiveReportsListState = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_LIVE_REPORTS_LIST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        pageView: {
          ...state.pageView,
          pageIndex: action.payload.page
        }
      };
    }
    case DELETE_LIVE_REPORT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_LIVE_REPORTS_LIST_SUCCESS: {
      const { meta, liveReports } = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        pageView: {
          ...state.pageView,
          total: meta.page.total
        },
        liveReports
      }
    }

    case SET_LIVE_REPORTS_LIST_PAGE_VIEW: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        pageView: {
          ...state.pageView,
          pageIndex: pageIndex || 0,
          pageSize: pageSize || state.pageView.pageSize
        }
      };
    }


    case DELETE_LIVE_REPORT_SUCCESS: {
      const deletedId = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        liveReports: state.liveReports.filter(liveReport => liveReport.id !== deletedId),
        pageView: {
          ...state.pageView,
          total: state.pageView.total - 1
        }
      };
    }
    case COPY_LIVE_REPORT_URL: {
      return {
        ...state,
        loading: true,
      }
    }

    case COPY_LIVE_REPORT_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    default: {
      return state;
    }
  }
}
export const getLiveReportsListState = (state) => state.liveReportsList;
export const getLiveReportsList = createSelector(getLiveReportsListState, state => state.liveReports);
export const getLiveReportsListPageView = createSelector(getLiveReportsListState, state => state.pageView);
