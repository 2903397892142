import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { map, take, tap } from 'rxjs/operators';
import { ReferencedContentRepositoryService } from '../../../core/api/referenced-content-repository/referenced-content-repository.service';
import { ResourceType } from '../../../core/store/referenced-content-repository/referenced-content-repository.model';
import { getWorkflowsState } from '../../../core/store/workflows/workflow.reducer';
import { GetActiveWorkflowAction } from '../../../core/store/workflows/workflow.actions';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';

@Component({
  selector: 'gd-widget-card-builder',
  templateUrl: './widget-card-builder.component.html',
  styleUrls: ['./widget-card-builder.component.scss'],
})
export class WidgetCardBuilderComponent implements OnInit, OnChanges {
  @Input() widgetId;
  @Input() localeId;
  @Input() usage: 'article' | 'template' | 'collection';
  @Input() config = {};
  @Input() contextShadowRoot = null;

  @Output() editItem = new EventEmitter();

  @ViewChild('cardBuilder', { static: false }) cardBuilder: ElementRef;

  previewFields = [];
  widgetData;
  loading;

  constructor(
    private referencedContentService: ReferencedContentRepositoryService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadActiveWorkflow();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configChanged =
      changes.config &&
      !changes.config.firstChange &&
      !isEqual(changes.config.previousValue, changes.config.currentValue);
    if (configChanged) {
      this.init();
    }
  }

  init() {
    if (!this.widgetId) {
      return;
    }
    this.getWidget().subscribe((widget) => {
      this.widgetData = widget;
      if (!this.widgetData) {
        return;
      }
      this.previewFields = [widget.primaryPreviewField, widget.secondaryPreviewField].reduce(
        (acc, fieldKey) => {
          const value = (this.config || {})[fieldKey];
          const configuration = widget.configuration.find((field) => field.key === fieldKey);
          // ignore preview field if it doesn't exist in the widget configuration
          return configuration ? [...acc, { value, configuration }] : acc;
        },
        []
      );

      // prepare preview data
      this.previewFields.forEach((field) => {
        const { value, configuration } = field;
        this.referencedContentService
          .getCustomFieldPreviewData(value, configuration, this.localeId)
          .subscribe((pd) => (field.previewData = pd));
      });
    });
  }

  getWidget() {
    this.loading = true;
    return this.referencedContentService.getData(this.widgetId, ResourceType.Widget).pipe(
      tap(() => (this.loading = false)),
      map(({ data }) => (data?.id ? data : null))
    );
  }

  loadActiveWorkflow() {
    this.store
      .select(getWorkflowsState)
      .pipe(
        tap(({ workflows, loading, loaded }) => {
          if (!Object.keys(workflows).length && !loaded && !loading) {
            this.store.dispatch(new GetActiveWorkflowAction());
          }
        }),
        take(1)
      )
      .subscribe(() => {});
  }
  switchCards() {
    if (!this.cardBuilder.nativeElement.classList.contains('moveToBottom')) {
      this.cardBuilder.nativeElement.classList.add('moveToBottom');
      this.cardBuilder.nativeElement.classList.remove('moveToTop');
      return;
    }

    this.cardBuilder.nativeElement.classList.add('moveToTop');
    this.cardBuilder.nativeElement.classList.remove('moveToBottom');
  }

  navigateToWidget() {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/site-builder/system-widgets/${this.widgetId}`], {}));
    window.open(url, '_blank');
  }

  handleEditItem() {
    this.editItem.emit();
  }
}
