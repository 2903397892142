<div class="sidenav-container" fxLayout="column">

  <mat-toolbar color="primary" class="sidenav-toolbar mat-elevation-z2">
    <div fxLayout="row" style="width: 100%;">
      <img src="assets/img/glide-logo.svg" fxFlexAlign="center" class="gd-sidenav-logo">
      <span fxFlex><!-- fill space --></span>
      <div fxHide="true" fxHide.gt-sm="false" fxFlexAlign="center">
        <button mat-icon-button (click)="toggleIconSidenav()">
          <mat-icon class="icon-sidenav-toggle fade-in-on-icon-sidenav" [class.rotate]="isIconSidenav()" fontSet="fal" fontIcon="fa-angle-left"></mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div class="sidenav-scrollbar-container" fxLayout="column">
      <!-- <mat-nav-list [perfect-scrollbar] fxLayout="column" class="sidenav-list sidenav-toplevel"> -->
      <mat-nav-list fxLayout="column" class="sidenav-list sidenav-toplevel">
        <gd-sidenav-item *ngFor="let item of items"
                         [item]="item"
                         [currentlyActiveSidenavItem]="activeItemsMap" (click)="sendMixPanelEvent($event, item)"></gd-sidenav-item>
      </mat-nav-list>
  </div>

</div>
