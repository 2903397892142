import { Injectable } from '@angular/core';
import { ManagePostEditorEmbeddedImageVisibilityAction, UpdatePostBodyReferencedContentAction } from '../../store/posts/posts.actions';
import { AbstractReferencedContentService } from './abstract-referenced-content.service';


@Injectable({
  providedIn: 'root',
})
export class PostBodyReferencedContentService extends AbstractReferencedContentService {
  protected EmbeddedImageVisibilityAction = ManagePostEditorEmbeddedImageVisibilityAction;
  protected UpdateReferencedContentAction = UpdatePostBodyReferencedContentAction;
}
