export const customCommandsTranslations = {
  "Mark as notes (CTRL+E)": $localize `Mark as notes (CTRL+E)`,
  "Embed Facebook Post": $localize `Embed Facebook Post`,
  "Embed Twitter / X Post": $localize `Embed Twitter / X Post`,
  "Embed Vimeo Video": $localize `Embed Vimeo Video`,
  "Embed Brightcove Video": $localize `Embed Brightcove Video`,
  "Embed Brightcove Playlist": $localize `Embed Brightcove Playlist`,
  "Embed YouTube Video (CTRL+Y)": $localize `Embed YouTube Video (CTRL+Y)`,
  "Embed YouTube Video": $localize `Embed YouTube Video`,
  "Embed Image": $localize `Embed Image`,
  "Embed Gallery": $localize `Embed Gallery`,
  "Embed File": $localize `Embed File`,
  "Embed Instagram Post": $localize `Embed Instagram Post`,
  "Embed System Widget": $localize `Embed System Widget`,
  "Embed Third Party Widget": $localize `Embed Third Party Widget`,
  "Insert Content Tag": $localize `Insert Content Tag`,
  "Cell Header": $localize `Cell Header`,
  "Close Content Tag": $localize `Close Content Tag`,
  "Embed Article Link": $localize `Embed Article Link`,
  "Embed Image Via URL": $localize `Embed Image Via URL`,
  "Strip Tags": $localize `Strip Tags`,
  "Edit embed": $localize `Edit embed`,
  "Embed Article Anchor Link": $localize `Embed Article Anchor Link`,
  "Add relationship attribute": $localize `Add relationship attribute`,
  "Add target attribute": $localize `Add target attribute`,
  "Embed TikTok Post": $localize `Embed TikTok Post`,
  "Align": $localize`Align`,
  "Default": $localize`Default`,
  "OK": $localize`OK`,
  "Right to left writing direction (RTL)": $localize`Right to left writing direction (RTL)`,
  "Left to right writing direction (LTR)": $localize`Left to right writing direction (LTR)`,
  "Clear All": $localize`Clear All`,
  "Block Quote": $localize`Block Quote`,
  "Convert Quotes To Curly": $localize`Convert Quotes To Curly`,
  "Embed JW Player Video": $localize`Embed JW Player Video`,
  "Embed JW Player Playlist": $localize`Embed JW Player Playlist`,
}
