import { Article, ArticleRevision } from '../article/article.model';
import { UnsafeAction } from '../unsafe-action.interface';
import { ContentModel } from './article-content.model';

export const GET_ARTICLE = '[Article] Get single article';
export class GetArticleAction implements UnsafeAction {
  readonly type = GET_ARTICLE;
  // payload is article ID
  constructor(public payload: number) { }
}

export const GET_ARTICLE_COMPLETE = '[Article] Single article fetched';
export class GetArticleCompleteAction implements UnsafeAction {
  readonly type = GET_ARTICLE_COMPLETE;
  // payload is fetched article
  constructor(public payload: Article) { }
}

export const CREATE_ARTICLE = '[Article] Create article';
export class CreateArticleAction implements UnsafeAction {
  readonly type = CREATE_ARTICLE;
  // payload is article ID
  constructor(public payload: Article) { }
}

export const CREATE_ARTICLE_COMPLETE = '[Article] Create article completed';
export class CreateArticleCompleteAction implements UnsafeAction {
  readonly type = CREATE_ARTICLE_COMPLETE;
  // payload is article ID
  constructor(public payload: Article) { }
}

export const UPDATE_ARTICLE = '[Article] Update article';
export class UpdateArticleAction implements UnsafeAction {
  readonly type = UPDATE_ARTICLE;
  // payload is fetched article
  constructor(public payload: Article) { }
}

export const UPDATE_ARTICLE_COMPLETE = '[Article] Update article completed';
export class UpdateArticleCompleteAction implements UnsafeAction {
  readonly type = UPDATE_ARTICLE_COMPLETE;
  // payload is fetched article
  constructor(public payload: Article) { }
}

export const ARTICLE_AUTOSAVE = '[Article] Autosave';
export class ArticleAutosaveAction implements UnsafeAction {
  readonly type = ARTICLE_AUTOSAVE;
  // payload is fetched article
  constructor(public payload: ArticleRevision) { }
}

export const ARTICLE_AUTOSAVE_SUCCESS = '[Article] Autosave successful';
export class ArticleAutosaveSuccessAction implements UnsafeAction {
  readonly type = ARTICLE_AUTOSAVE_SUCCESS;
  // payload is the autosaved article
  constructor(public payload: Article) { }
}

// TODO handle error state
export const ARTICLE_AUTOSAVE_ERROR = '[Article] Autosave failed';
export class ArticleAutosaveErrorAction implements UnsafeAction {
  readonly type = ARTICLE_AUTOSAVE_ERROR;
  constructor(public payload: any) { }
}

export const DISCARD_ARTICLE_AUTOSAVE = '[Article] Discard Autosave';
export class DiscardArticleAutosaveAction implements UnsafeAction {
  readonly type = DISCARD_ARTICLE_AUTOSAVE;
  // payload is the article id
  constructor(public payload: number, public fireAndForget = false) { }
}

export const DISCARD_ARTICLE_AUTOSAVE_SUCCESS = '[Article] Discard Autosave successful';
export class DiscardArticleAutosaveSuccessAction implements UnsafeAction {
  readonly type = DISCARD_ARTICLE_AUTOSAVE_SUCCESS;
  // payload is the article id
  constructor(public payload: number, public fireAndForget = false) { }
}


export const SWITCH_ACTIVE_REVISION = '[Article] Change active revision';
export class SwitchActiveArticleRevisionAction implements UnsafeAction {
  readonly type = SWITCH_ACTIVE_REVISION;
  // payload is revision ID
  constructor(public payload: number) { }
}

export const SWITCH_ACTIVE_REVISION_COMPLETE = '[Article] Changing active revision completed';
export class SwitchActiveArticleRevisionCompleteAction implements UnsafeAction {
  readonly type = SWITCH_ACTIVE_REVISION_COMPLETE;
  // payload is revision body to be loaded as working revision
  constructor(public payload: ArticleRevision) { }
}

export const UPDATE_ARTICLE_BODY = '[Article] Update article body';
export class UpdateArticleBodyAction implements UnsafeAction {
  readonly type = UPDATE_ARTICLE_BODY;
  constructor(public payload: ContentModel) { }
}

// used for copy functionality in article body
export const COPY_ARTICLE_CONTENT_NODES = '[Article] Copy content nodes';
export class CopyArticleContentNodesAction implements UnsafeAction {
  readonly type = COPY_ARTICLE_CONTENT_NODES;
  constructor(public payload: { contentNodes: any[], referencedContent: any }) { }
}

export const CREATE_ARTICLE_CONTENT_NODE = '[Article] Create content node';
export class CreateArticleContentNodeAction implements UnsafeAction {
  readonly type = CREATE_ARTICLE_CONTENT_NODE;
  constructor(public payload) { }
}

export const UPDATE_ARTICLE_CONTENT_NODE = '[Article] Update content node';
export class UpdateArticleContentNodeAction implements UnsafeAction {
  readonly type = UPDATE_ARTICLE_CONTENT_NODE;
  constructor(public payload) { }
}

export const CREATE_NEW_EMPTY_ARTICLE = '[Article] Create new empty article';
export class CreateNewEmptyArticleAction implements UnsafeAction {
  readonly type = CREATE_NEW_EMPTY_ARTICLE;
  public payload: any = { typeId: null, taxonomies: [] };
  constructor(payload: {
    typeId?: number,
    taxonomies?: number[],
    contentLocale?: any,
  }) {
    if (payload.typeId) {
      this.payload.typeId = payload.typeId;
    }
    if (payload.taxonomies) {
      this.payload.taxonomies = payload.taxonomies;
    }
    const contentLocale = payload.contentLocale;
    if (contentLocale) {
      this.payload.contentLocale = contentLocale;
    }
  }
}

export const COPY_ARTICLE_BY_ID = '[Article] Copy article by id';
export class CopyArticleByIdAction implements UnsafeAction {
  readonly type = COPY_ARTICLE_BY_ID;
  constructor(public payload: number) { }
}

export const LOCALIZE_ARTICLE = '[Article] Localize article by id';
export class LocalizeArticleAction implements UnsafeAction {
  readonly type = LOCALIZE_ARTICLE;
  constructor(public payload: {
    mode: string,
    id: number,
    typeId: number,
    contentLocaleId: number,
    localizationId: string
  }) { }
}

export const LOCALIZE_ARTICLE_SUCCESS = '[Article] Localize article by id done';
export class LocalizeArticleSuccessAction implements UnsafeAction {
  readonly type = LOCALIZE_ARTICLE_SUCCESS;
  constructor(public payload: Article) { }
}

export const RESET_ACTIVE_ARTICLE = '[Article] Reset Active Article';
export class ResetActiveArticleAction implements UnsafeAction {
  readonly type = RESET_ACTIVE_ARTICLE;
  constructor() { }
}

export const UNPUBLISH_ARTICLE = '[Article] Unpublish Article';
export class UnpublishArticleAction implements UnsafeAction {
  readonly type = UNPUBLISH_ARTICLE;
  constructor(public payload) { }
}

export const UNPUBLISH_ARTICLE_COMPLETE = '[Article] Unpublish Article Complete';
export class UnpublishArticleCompleteAction implements UnsafeAction {
  readonly type = UNPUBLISH_ARTICLE_COMPLETE;
  constructor(public payload) { }
}

export const SET_ACTIVE_ARTICLE_TAXONOMIES = '[Article] Set active article taxonomie taxonomies';
export class SetActiveArticleTaxonomiesAction implements UnsafeAction {
  readonly type = SET_ACTIVE_ARTICLE_TAXONOMIES;
  constructor(public payload: any) { }
}

export const SET_ARTICLE_LOADING_FLAG = '[Article] Set article loading flag';
export class SetArticleLoadingFlagAction implements UnsafeAction {
  readonly type = SET_ARTICLE_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const UPDATE_REFERENCED_CONTENT = '[Article] Update referenced content';
export class UpdateReferencedContentAction implements UnsafeAction {
  readonly type = UPDATE_REFERENCED_CONTENT;
  constructor(public payload: any) { }
}

export const ARTICLE_ACTION_FAILED = '[Article] Generic failure action';
export class ArticleFailedAction implements UnsafeAction {
  readonly type = ARTICLE_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const UPDATE_ARTICLE_STATUS = '[Article] Update article status';
export class UpdateArticleStatus implements UnsafeAction {
  readonly type = UPDATE_ARTICLE_STATUS;
  constructor(public payload: any) { }
}

export const UPDATE_RELATED_ARTICLES = '[Article] Update related articles';
export class UpdateRelatedArticlesAction implements UnsafeAction {
  readonly type = UPDATE_RELATED_ARTICLES;
  constructor(public payload: any) { }
}

export const SET_PRIMARY_TAXONOMY = '[Article] Set primary taxonomy';
export class SetPrimaryTaxonomyAction implements UnsafeAction {
  readonly type = SET_PRIMARY_TAXONOMY;
  constructor(public payload: any) { }
}

export const SET_IS_USING_AUTOMATIC_RELATED_ARTICLES = '[Article] Set Is Using Automatic Related Articles Flag';
export class SetIsUsingAutomaticRelatedArticlesAction implements UnsafeAction {
  readonly type = SET_IS_USING_AUTOMATIC_RELATED_ARTICLES;
  constructor(public payload: any) { }
}

export const ADD_RELATED_ARTICLE = '[Article] Add related article';
export class AddRelatedArticleAction implements UnsafeAction {
  readonly type = ADD_RELATED_ARTICLE;
  constructor(public payload: any) { }
}

export const REMOVE_RELATED_ARTICLE = '[Article] Remove related article';
export class RemoveRelatedArticleAction implements UnsafeAction {
  readonly type = REMOVE_RELATED_ARTICLE;
  constructor(public payload: any) { }
}

export const PUBLISH_ARTICLE = '[Article] Publish Article';
export class PublishArticleAction implements UnsafeAction {
  readonly type = PUBLISH_ARTICLE;
  constructor(public payload, public mockPublish = false) { }
}

export const PUBLISH_ARTICLE_COMPLETE = '[Article] Publish Article Complete';
export class PublishArticleCompleteAction implements UnsafeAction {
  readonly type = PUBLISH_ARTICLE_COMPLETE;
  constructor(public payload) { }
}

export const REMOVE_ARTICLE_CONTENT_NODE = '[Article] Remove article content node';
export class RemoveArticleContentNodeAction implements UnsafeAction {
  readonly type = REMOVE_ARTICLE_CONTENT_NODE;
  constructor(public payload: any) { }
}

// to be used in conjunction with `ensureImagePreviewsAreLoaded` in froala component
// and ensure that on tab change the image state is checked
export const ARTICLE_PAGE_TAB_CHANGE = '[Article] Change article page tab';
export class ChangeArticlePageTabAction implements UnsafeAction {
  readonly type = ARTICLE_PAGE_TAB_CHANGE;
  constructor(public activeTab: 'CREATE' | 'PRODUCE') { }
}

export const ADD_AUTHOR = '[Article] Add author';
export class AddAuthorAction implements UnsafeAction {
  readonly type = ADD_AUTHOR;
  constructor(public payload: { author: any, isDefault?: boolean }) { }
}

export const REMOVE_AUTHOR = '[Article] Remove author';
export class RemoveAuthorAction implements UnsafeAction {
  readonly type = REMOVE_AUTHOR;
  constructor(public payload: any) { }
}

export const SET_ARTICLE_TYPE = '[Article] Set article type';
export class SetArticleTypeAction implements UnsafeAction {
  readonly type = SET_ARTICLE_TYPE;
  constructor(public payload: any) { }
}

export const ARTICLE_ACTION_NOT_FOUND = '[Article] Not found failure action';
export class ArticleNotFoundAction implements UnsafeAction {
  readonly type = ARTICLE_ACTION_NOT_FOUND;
  constructor(public payload: any) { }
}

export const MANAGE_EMBEDDED_IMAGE_VISIBILITY = '[Article] Manage visibility of embedded image';
export class ManageEmbeddedImageVisibilityAction implements UnsafeAction {
  readonly type = MANAGE_EMBEDDED_IMAGE_VISIBILITY;
  constructor(public payload: any) { }
}

export const LOAD_LOCALIZED_VERSIONS = '[Article] Load LocalizedVersions';
export class LoadLocalizedVersionsAction implements UnsafeAction {
  readonly type = LOAD_LOCALIZED_VERSIONS;
  constructor(public payload: any) { }
}

export const CLEAR_REFERENCED_CONTENT = '[Article] Clear referenced content';
export class ClearReferencedContentAction implements UnsafeAction {
  readonly type = CLEAR_REFERENCED_CONTENT;
  constructor() { }
}

export const SCHEDULE_ARTICLE = '[Article] Schedule article';
export class ScheduleArticleAction implements UnsafeAction {
  readonly type = SCHEDULE_ARTICLE;
  constructor(public payload) { }
}

export const SCHEDULE_ARTICLE_SUCCESS = '[Article] Schedule article success';
export class ScheduleArticleSuccessAction implements UnsafeAction {
  readonly type = SCHEDULE_ARTICLE_SUCCESS;
  constructor(public payload) { }
}

export const UNSCHEDULE_ARTICLE = '[Article] Unschedule article';
export class UnscheduleArticleAction implements UnsafeAction {
  readonly type = UNSCHEDULE_ARTICLE;
  constructor(public payload) { }
}

export const UNSCHEDULE_ARTICLE_SUCCESS = '[Article] Unschedule article success';
export class UnscheduleArticleSuccessAction implements UnsafeAction {
  readonly type = UNSCHEDULE_ARTICLE_SUCCESS;
  constructor(public payload) { }
}

export const SET_RELATED_ARTICLES_LOADING_FLAG = '[Article] Set Related Articles loading flag';
export class SetRelatedArticlesLoadingFlagAction implements UnsafeAction {
  readonly type = SET_RELATED_ARTICLES_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const TRANSLATE_ARTICLE = '[Article] Translate article';
export class TranslateArticleAction implements UnsafeAction {
  readonly type = TRANSLATE_ARTICLE;
  constructor(public payload) { }
}
