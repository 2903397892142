import { mergeMap, map, tap, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EmbedYouTubeDialogComponent } from '../../glide-create/+articles/embed-dialog/embed-dialog.component';
import { ModalsService } from '../modals/modals.service';
import { EmbedFacebookDialogComponent } from '../../glide-create/+articles/embed-facebook-dialog/embed-facebook-dialog.component';
import { EmbedTwitterDialogComponent } from '../../glide-create/+articles/embed-twitter-dialog/embed-twitter-dialog.component';
import { BehaviorSubject , of } from 'rxjs';
import { EmbeddedType } from '../../core/store/article/article-content.model';
import { EmbedInstagramDialogComponent } from '../../glide-create/+articles/embed-instagram-dialog/embed-instagram-dialog.component';
import { EmbedVimeoDialogComponent } from '../../glide-create/+articles/embed-vimeo-dialog/embed-vimeo-dialog.component';
import { EmbedBrightcoveDialogComponent } from '../../glide-create/+articles/embed-brightcove-dialog/embed-brightcove-dialog.component';
import { ImagesService, resolveArticleBodyImage } from '../../core/api';
import {
  EmbedBrightcovePlaylistDialogComponent
} from '../../glide-create/+articles/embed-brightcove-playlist-dialog/embed-brightcove-playlist-dialog.component';
import {
  EmbedContentPanelDialogComponent
} from '../../glide-create/+articles/embed-content-panel-dialog/embed-content-panel-dialog.component';
import { EmbedAnchorLinkComponent } from '../../glide-create/+articles/embed-anchor-link/embed-anchor-link.component';
import { AddContentTagDialogComponent } from '../add-content-tag-dialog/add-content-tag-dialog.component';
import { EmbedImageViaURLComponent } from '../embed-dialogs/embed-image-url-dialog/embed-image-url-dialog.component';
import { EmbedTikTokDialogComponent } from '../embed-dialogs/embed-tiktok-dialog/embed-tiktok-dialog.component';
import { GlideContentActionBusService } from '../../core/body-content/glide-content-actions-bus.service';
import { FroalaEmbedWidgetComponent } from '../froala-embed-widget/froala-embed-widget.component';
import { generateShortId } from '../shared-functions';
import { FilesService } from '../../core/api/files/files.service';
import { EmbedJWMediaDialogComponent } from '../embed-dialogs/embed-jw-media-dialog/embed-jw-media-dialog.component';
import { EmbedDailymotionDialogComponent } from '../embed-dialogs/embed-dailymotion-dialog/embed-dailymotion-dialog.component';
import { EmbedTwitchDialogComponent } from '../embed-dialogs/embed-twitch-dialog/embed-twitch-dialog.component';

@Injectable()
export class EmbeddablesService {
  // TODO replace this with embed modals returning true or false on submit
  editorContentChanged = new BehaviorSubject(false);

  uploadConfig = {
    viewAs: 'contained',
    autoImport: true,
    dragAndDropMode: 'default',
    multiUpload: true,
  };

  constructor(
    private modalsService: ModalsService,
    private imagesService: ImagesService,
    private actionBus: GlideContentActionBusService,
    private filesService: FilesService,
  ) { }

  // need to pass content type somehow
  invokeDialog(dialogInputData) {

    switch (dialogInputData.embedType) {

      case EmbeddedType.Image: {
        return dialogInputData.id
          ? this.openImageMetaDialog(dialogInputData)
          : this.openImagesDialog(dialogInputData);
      }

      case EmbeddedType.FacebookPost: {
        return this.modalsService
          .custom($localize`Embed Facebook Post`, EmbedFacebookDialogComponent, {
            data: dialogInputData,
            width: '600px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.TwitterPost: {
        return this.modalsService
          .custom($localize`Embed Twitter / X Post`, EmbedTwitterDialogComponent, {
            data: dialogInputData,
            width: '600px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.YouTube: {
        return this.modalsService
          .custom($localize`Embed YouTube Video`, EmbedYouTubeDialogComponent, {
            data: dialogInputData,
            width: '800px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.Gallery: {
        return this.actionBus.getActiveContentLocaleId(dialogInputData.glideContentType).pipe(
          mergeMap((contentLocaleId) => {
            return this.modalsService.embedImage({
              data: { contentLocaleId },
              dialogOptions: {
                dialogContent: 'galleries',
                showTabs: false,
                showSearch: true,
              },
            });
          }),
          tap((event) => {
            if (event && event['images']) {
              this.editorContentChanged.next(true);
            }
          }),
          map((event) => {
            const hasImages = event && event['images'];
            if (hasImages) {
              return {
                type: dialogInputData.embedType,
                blockId: dialogInputData.blockId,
                updateData: event['images'][0],
              };
            }
            return null;
          })
        );
      }

      // TODO fix the issues with instagram API
      case EmbeddedType.InstagramPost: {
        return this.modalsService
          .custom($localize`Embed Instagram Post`, EmbedInstagramDialogComponent, {
            data: dialogInputData,
            width: '600px'
          })
          .pipe(tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.Vimeo: {
        return this.modalsService
          .custom($localize`Embed Vimeo Video`, EmbedVimeoDialogComponent, {
            data: dialogInputData,
            width: '800px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.Brightcove: {
        return this.modalsService
          .custom($localize`Embed Brightcove Video`, EmbedBrightcoveDialogComponent, {
            data: dialogInputData,
            width: '800px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.BrightcovePlaylist: {
        return this.modalsService
          .custom($localize`Embed Brightcove Playlist`, EmbedBrightcovePlaylistDialogComponent, {
            data: dialogInputData,
            width: '800px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.SystemWidget:
      case EmbeddedType.ThirdPartyWidget: {
        return this.modalsService
          .custom($localize`Embed Widget`, FroalaEmbedWidgetComponent, {
            data: dialogInputData,
            width: '850px',
            // in crete mode focus the widget select, in edit focus custom data panel
            autoFocus:  dialogInputData?.isEditingMode ? 'gd-dynamic-widget-form' : true,
            disableClose: true,
            maxHeight: '90vh',
          }).pipe(
            map((event) => {
              if (event) {
                const nodes = [
                  {
                    type: dialogInputData.embedType,
                    blockId: dialogInputData.editingMode
                      ? dialogInputData.blockId
                      : generateShortId(),
                    updateData: event.updateData,
                  },
                ];
                this.editorContentChanged.next(true);
                return { payload: nodes };
              }
              return null;
            })
          );
      }

      case EmbeddedType.ContentPanel: {
        return this.modalsService
          .custom($localize`Insert Content Panel`, EmbedContentPanelDialogComponent, {
            data: dialogInputData,
            width: '600px'
          });
      }

      case EmbeddedType.AnchorLink: {
        return this.modalsService
          .custom($localize`Embed Article Anchor Link`, EmbedAnchorLinkComponent, {
            data: dialogInputData,
            width: '800px',
          }).pipe(
            map((event) => {
              if (event) {
                const nodes = [
                  {
                    type: dialogInputData.embedType,
                    blockId: dialogInputData.editingMode
                      ? dialogInputData.blockId
                      : generateShortId(),
                    updateData: event.updateData,
                  },
                ];
                this.editorContentChanged.next(true);
                return { payload: nodes };
              }
              return null;
            })
          );
      }

      case EmbeddedType.File: {
        return this.openFileDialog(dialogInputData);
      }

      // WIP enable content tags for LR_POST
      case EmbeddedType.ContentTag: {
        return this.modalsService
          .custom($localize`Add Access Tag`, AddContentTagDialogComponent, {
            data: dialogInputData,
            width: '600px'
          }).pipe(
          // STEP 4
          tap(() => setTimeout(() => this.editorContentChanged.next(true), 400)));
      }

      case EmbeddedType.ImageURL: {
        return this.modalsService
          .custom($localize`Embed Image Via URL`, EmbedImageViaURLComponent, {
            data: dialogInputData,
            width: '800px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.TikTokPost: {
        return this.modalsService
          .custom($localize`Embed TikTok Post`, EmbedTikTokDialogComponent, {
            data: dialogInputData,
            width: '600px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.JWPlayerVideo:
      case EmbeddedType.JWPlayerPlaylist: {
        return this.modalsService
          .custom('Embed JW Player Media', EmbedJWMediaDialogComponent, {
            data: dialogInputData,
            width: '800px'
          }).pipe(
          tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.DailymotionVideo:
      case EmbeddedType.DailymotionPlaylist: {
          return this.modalsService
            .custom('Embed Dailymotion Media', EmbedDailymotionDialogComponent, {
              data: dialogInputData,
              width: '800px'
            }).pipe(
            tap(() => this.editorContentChanged.next(true)));
      }

      case EmbeddedType.TwitchVideo:
        case EmbeddedType.TwitchStream: {
            return this.modalsService
              .custom('Embed Twitch Media', EmbedTwitchDialogComponent, {
                data: dialogInputData,
                width: '800px'
              }).pipe(
              tap(() => this.editorContentChanged.next(true)));
        }
      default: return;
    }
  }

  openImagesDialog(dialogInputData) {
    const data = {
      dialogOptions: { loadSingleImage: false, showSearch: true, showLibrary: dialogInputData.dropEvent === true ? false : true },
      gridOptions: { enableSelection: true, multipleSelection: dialogInputData.editingMode ? false : true },
      uploadConfig: {...this.uploadConfig, multiUpload: dialogInputData.editingMode ? false : true },
      data: { usage: 'article' },
      selectedImages: dialogInputData.selectedImages || [],
    };
    return this.modalsService.embedImage(data).pipe(
        filter((event) => event && event.images),
        map((event) => {
          let selectedImages = event.images;
          selectedImages = selectedImages.map(image => {
            const tags = [...image.tags].sort((a, b) => a.group > b.group ? -1 : 1)
            .sort((a, b) => a.premium > b.premium ? -1 : 1)
            .map((tag) => ({ name: tag.name, premium: tag.premium, group: tag.group }));
          return { ...image, tags };
          })
          const isUploadMode = event.images.some(img => img.isUploadMode);
          return { images: selectedImages, isUploadMode };
        }),
        mergeMap(({ images, isUploadMode }) => this.openImageMetaDialog(dialogInputData, images, isUploadMode))
      );
  }

  openImageMetaDialog(data, selectedImages = null, isUploadMode = false) {
    const attributes = [
      'caption',
      'alt',
      'credit',
      'copyright',
      'description',
      'imageFormat',
      'imageStyle',
      'ai_service',
      'foundation_model',
      'ai_author',
      'seed',
      'prompt_strength',
      'generation_step',
      'ai_generated_image_prompt'
    ];
    const isEditingMode = !selectedImages;
    let sourceMeta: any = {};
    if (isEditingMode) {
      let sourceMeta: any = {};
      // prepare data for dialog in form of an image
      const image: any = { id: data.dataId, tags: data.tags, thumbnail: data.src };
      image.metaData = attributes.filter(attr => data[attr]).map(attr => ({ key: attr, value: data[attr] }));
      // get source meta from store
      this.actionBus.getReferencedContentForBlock(data.blockId, data.glideContentType)
        .subscribe(node => {
          [...attributes, 'isNotLoaded']
            .filter(attr => !!node[attr])
            .forEach(attr => sourceMeta[attr] = node[attr]);
          sourceMeta.tags = node.tags;
        });
      image.sourceMeta = sourceMeta;
      selectedImages = [image];
    } else {
      selectedImages.forEach(image => {
        let sourceMeta: any = {};
        if (!image.metaData.length) {
          image.metaData.push({ key: 'imageFormat', value: 'default' });
          image.metaData.push({ key: 'imageStyle', value: 'default' });
        }
        image.metaData.map(({ key, value }) => sourceMeta[key] = value);
        image.metaData
        .filter(({ key }) => key === 'imageFormat' || key === 'imageStyle')
        .map(({ key, value }) => (image[key] = value));
        sourceMeta.tags = image.tags;
        image.sourceMeta = sourceMeta;
      })
    }

    if (isUploadMode) {
      const nodes = selectedImages.map((image) => {
        image.sourceMeta.isNotLoaded = true;
        const contentNode: any = {
          type: data.embedType,
          blockId: data.blockId || generateShortId(),
          updateData: { ...image },
        };
        // TODO Add which action should be executing here
        this.imagesService.setArticleEmbeddedImageChecker({
          id: contentNode.blockId,
          dataId: image.id,
          src: resolveArticleBodyImage(image),
          attempt: 1,
        });
        return contentNode;
      });

      // move metaData within the node updateData property.
      nodes.forEach((node) => {
        const metaDetails = node.updateData?.metaData || [];
        metaDetails.forEach(({ key, value }) => (node.updateData[key] = value));
      });
      this.editorContentChanged.next(true);
      return of({ payload: nodes });
    }

    return this.modalsService
      .uploadImageMetaForm({ images: selectedImages, usage: 'article', isEditingMode })
      .pipe(
        filter((res) => !!res),
        mergeMap(({ images, changeImage }) => {
          if (changeImage) {
            return this.openImagesDialog({ ...data, selectedImages: images, isEditingMode });
          }

          const nodes = selectedImages.map((image, index) => {
            let newMeta: any = images[index].newMeta || {};
            const shouldUpdateSourceMeta = ['caption', 'alt', 'credit', 'copyright', 'description'].some(
              (attr) => !image.sourceMeta[attr] && newMeta[attr]
            );
            if (shouldUpdateSourceMeta) {
              image.sourceMeta = this.getNewSourceMeta(image.sourceMeta, newMeta);
              const payload = { imageId: image.id, imageData: [] };
              payload.imageData = attributes
                .filter((attr) => image.sourceMeta[attr])
                .map((attr) => ({ key: attr, value: image.sourceMeta[attr] }));
              this.imagesService.updateImageDetails(payload).subscribe(() => { });
            }

            const contentNode: any = {
              type: data.embedType,
              blockId: data.blockId || generateShortId(),
              updateData: { ...image, ...newMeta },
            };
            return contentNode;
          });

          this.editorContentChanged.next(true);
          return of({ payload: nodes });
        })
      );
  }

  getNewSourceMeta(sourceMeta, dialogMeta) {
    const attributes = ['caption', 'alt', 'credit', 'copyright', 'description'];
    const meta = { ...sourceMeta };
    Object.keys(dialogMeta)
      .filter(key => attributes.includes(key) && !meta[key] && dialogMeta[key])
      .forEach(key => meta[key] = dialogMeta[key]);

    return meta;
  }

  openFileDialog(dialogInputData) {
    return this.modalsService
      .embedFile({
        data: dialogInputData,
        usage: 'article',
        uploadConfig: {
          multiUpload: !dialogInputData.editingMode,
          multipleSelect: !dialogInputData.editingMode,
        },
      })
      .pipe(
        filter((event) => event && event.files),
        mergeMap((event) => {
          const fileNodes = event.files.map((file) => {
            const type = file.filename.match(/[^.]+$/g)[0];
            const fileThumbnail = this.filesService.resolveEmbedFileThumbnail(type);
            const fileNodeModel = {
              id: file.id,
              description: file.meta_data.description,
              src: fileThumbnail,
              url: this.imagesService.generateImageUrl(file.key),
              title: file.meta_data.name,
            };
            const contentNodeData = {
              type: dialogInputData.embedType,
              blockId: dialogInputData.editingMode ? dialogInputData.blockId : generateShortId(),
              updateData: fileNodeModel,
            };
            return contentNodeData;
          });
          return of({ payload: fileNodes });
        })
      );
  }

}
