import { getWorkingRevision } from './../store/article/article.reducers';
import { get } from 'lodash-es';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './../store/app-reducer';
import {
  CreateArticleContentNodeAction,
  getActiveArticleLocale,
  getReferencedContentNode,
  SetArticleLoadingFlagAction,
  UpdateArticleContentNodeAction,
} from '../store';
import {
  CreatePostContentNodeAction,
  UpdatePostContentNodeAction,
} from '../store/posts/posts.actions';
import { getPostEditorBodyModel, getPostReferencedContentNode } from '../store/posts/posts.reducer';
import { map, take } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * The purpose of this service is to fire actions specific to
 */
@Injectable({
  providedIn: 'root',
})
export class GlideContentActionBusService {
  constructor(private store: Store<AppState>) {}

  createContentNode(contentNodeData, glideContentType: string) {
    if (glideContentType === 'ARTICLE') {
      this.store.dispatch(new CreateArticleContentNodeAction(contentNodeData));
    }
    if (glideContentType === 'LR_POST') {
      this.store.dispatch(new CreatePostContentNodeAction(contentNodeData));
    }
  }

  updateContentNode(contentNodeData, glideContentType) {
    if (glideContentType === 'ARTICLE') {
      this.store.dispatch(new UpdateArticleContentNodeAction(contentNodeData));
    }
    if (glideContentType === 'LR_POST') {
      this.store.dispatch(new UpdatePostContentNodeAction(contentNodeData));
    }
  }

  setContentLoading(isLoading, glideContentType) {
    if (glideContentType === 'ARTICLE') {
      this.store.dispatch(new SetArticleLoadingFlagAction(isLoading));
    }
    if (glideContentType === 'LR_POST') {
      // TODO implement similar logic to what we have for articles
    }
  }

  getReferencedContentForBlock(blockId, glideContentType) {
    if (glideContentType === 'ARTICLE') {
      return this.store.select(getReferencedContentNode(blockId)).pipe(take(1));
    }

    if (glideContentType === 'LR_POST') {
      return this.store.select(getPostReferencedContentNode(blockId)).pipe(take(1));
    }
  }

  getContentNodes(glideContentType) {
    if (glideContentType === 'ARTICLE') {
      return this.store.select(getWorkingRevision)
        .pipe(
          take(1),
          map(workingRevision => get(workingRevision, 'body.contentNodes', {}))
        );
    }

    if (glideContentType === 'LR_POST') {
      return this.store.select(getPostEditorBodyModel)
        .pipe(
          take(1),
          map(contentModel => get(contentModel, 'contentNodes', {}))
        );
    }
  }

  getActiveContentLocaleId(glideContentType) {
      if (glideContentType === 'ARTICLE') {
        return this.store.select(getActiveArticleLocale).pipe(map(cl => (cl && cl.id || null)), take(1));
      }

      if (glideContentType === 'LR_POST') {
        return of(null);
      }
    }
}
