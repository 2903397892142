<div *ngIf="isDisplayed$ | async"
     class='gd-ui-section-header  mat-elevation-z2'
     [dir]="dir$ | async">
  <div class="gd-ui-section-header__title"
       fxLayout="row"
       fxLayoutGap="8px"
       fxLayoutAlign="space-between center">
    <h2 class="gd-ui-section-header__title-text mat-h2"
        [class.gd-ui-section-header__title-text--no-title]="(title$ | async) !== true">
        {{( title$ | async ) || noTitleLabel}}
        <span *ngIf="isLive$ | async as isLive; else finishedBlock"
              i18n
              [class.gd-ui-section-header__live]="isLive">Live now!</span>
        <ng-template #finishedBlock><span *ngIf="description$ | async"> | </span></ng-template>
        <span *ngIf="description$ | async as description">{{description}}</span>
    </h2>

    <div class="gd-ui-section-header__icons"
         fxLayout="row"
         fxLayoutGap="12px"
         fxLayoutAlign="space-between center">
      <mat-icon *ngIf="hasUpdates$ | async"
                matTooltip="Unpublished updates available"
                i18n-matTooltip
                class="gd-ui-section-header__updates-available-icon"
                fontSet="fal"
                fontIcon="fa-bell-on"></mat-icon>
      <mat-icon *ngIf="isPublished$ | async"
                matTooltip="Published revision available"
                i18n-matTooltip
                class="gd-ui-section-header__published-icon"
                fontSet="fal"
                fontIcon="fa-globe"></mat-icon>
      <gd-section-info *ngIf="sectionInfo$ | async"
                        [sectionInfo$]="sectionInfo$"></gd-section-info>
      </div>
  </div>
</div>
