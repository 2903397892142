import { UnsafeAction } from '../unsafe-action.interface';
import { Subscriber } from './subscribers.model';

export const GET_SUBSCRIBERS = '[Subscribers] Get Subscribers';
export class GetSubscribersAction implements UnsafeAction {
  readonly type = GET_SUBSCRIBERS;
  constructor(public payload: any = {}) { }
}

export const GET_SUBSCRIBERS_SUCCESS = '[Subscribers] Get Subscribers success';
export class GetSubscribersSuccessAction implements UnsafeAction {
  readonly type = GET_SUBSCRIBERS_SUCCESS;
  constructor(public payload: any = {}) { }
}

export const GET_ONE_SUBSCRIBER = '[Subscribers] Get One Subscriber';
export class GetOneSubscriberAction implements UnsafeAction {
  readonly type = GET_ONE_SUBSCRIBER;
  constructor(public payload: string) { }
}

export const GET_ONE_SUBSCRIBER_SUCCESS = '[Subscribers] Get One Subscriber success';
export class GetOneSubscriberSuccessAction implements UnsafeAction {
  readonly type = GET_ONE_SUBSCRIBER_SUCCESS;
  constructor(public payload: Subscriber) { }
}

export const GET_TRANSMIT_EVENTS = '[Events] Get Transmit Events';
export class GetTransmitEventsAction implements UnsafeAction {
  readonly type = GET_TRANSMIT_EVENTS;
  constructor() { }
}

export const GET_TRANSMIT_EVENTS_SUCCESS = '[Events] Get Transmit Events success';
export class GetTransmitEventsSuccessAction implements UnsafeAction {
  readonly type = GET_TRANSMIT_EVENTS_SUCCESS;
  constructor(public payload: string[]) { }
}

export const CREATE_SUBSCRIBER = '[Subscriber] Create Subscriber';
export class CreateSubscriberAction implements UnsafeAction {
  readonly type = CREATE_SUBSCRIBER;
  constructor(public payload: Subscriber) { }
}

export const CREATE_SUBSCRIBER_SUCCESS = '[Subscriber] Create Subscriber success';
export class CreateSubscriberSuccessAction implements UnsafeAction {
  readonly type = CREATE_SUBSCRIBER_SUCCESS;
  constructor(public payload: Subscriber) { }
}

export const UPDATE_SUBSCRIBER = '[Subscriber] Update Subscriber';
export class UpdateSubscriberAction implements UnsafeAction {
  readonly type = UPDATE_SUBSCRIBER;
  constructor(public payload: Subscriber) { }
}

export const UPDATE_SUBSCRIBER_SUCCESS = '[Subscriber] Update Subscriber success';
export class UpdateSubscriberSuccessAction implements UnsafeAction {
  readonly type = UPDATE_SUBSCRIBER_SUCCESS;
  constructor(public payload: Subscriber) { }
}

export const DELETE_SUBSCRIBER = '[Subscriber] Delete Subscriber';
export class DeleteSubscriberAction implements UnsafeAction {
  readonly type = DELETE_SUBSCRIBER;
  constructor(public payload: string) { }
}

export const DELETE_SUBSCRIBER_SUCCESS = '[Subscriber] Delete Subscriber success';
export class DeleteSubscriberSuccessAction implements UnsafeAction {
  readonly type = DELETE_SUBSCRIBER_SUCCESS;
  constructor(public payload: any) { }
}

export const SET_SUBSCRIBERS_PAGE_VIEW_OPTIONS = '[Subscriber] Set page view options';
export class SetSubscribersPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_SUBSCRIBERS_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const SUBSCRIBER_ACTION_FAILED = '[Subscriber] Generic failure action';
export class SubscriberFailedAction implements UnsafeAction {
  readonly type = SUBSCRIBER_ACTION_FAILED;
  constructor(public payload: any) { }
}
