import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '../../../core/store/app-reducer';
import { getListOfPosts } from '../../../core/store/posts/posts.reducer';

@Injectable()
export class RichTextEditorTransformService {

  posts = [];

  constructor(private store: Store<AppState>) {
    this.store.select(getListOfPosts).subscribe(posts => this.posts = posts);
  }

  transformPostLink(clipboardHtml: string): string {
    // create dummy html element to access innerText and outerHTML attributes
    const el = document.createElement('a');
    el.innerHTML = clipboardHtml;
    const params = el.innerText.match(/(?:live-reporting\/)([0-9]+)\/posts\/([0-9]+)/);
    const liveReportId = params[1];
    const postId = params[2];

    // attach post data
    const post = this.posts.find((data) => data.id === Number(postId));

    el.setAttribute('href', `#${post.glideId}`);
    el.setAttribute('data-id', postId);
    el.setAttribute('data-glide-id', post.glideId);
    el.setAttribute('data-live-report-id', liveReportId);
    el.innerText = (post.publishedRevision && post.publishedRevision.headline) || post.workingRevision.headline || 'No Post Headline';

    return el.outerHTML;
  }

  getPostFromLink(link: string) {
    const el = document.createElement('a');
    el.innerHTML = link;

    const params = el.innerText.match(/(?:live-reporting\/)([0-9]+)\/posts\/([0-9]+)/);
    const liveReportId = params[1];
    const postId = params[2];

    // attach post data
    const post = this.posts.find((data) => data.id === Number(postId));

    return { ...post, liveReportId };
  }

}
