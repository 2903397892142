import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-reducer';
import { Observable, catchError, map, of } from 'rxjs';
import { getTwitchSettings } from '../../store/account-settings/account-settings.reducer';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class TwitchIntegrationService {
  clientId;
  clientSecret;
  accessToken;
  usedAccountId;
  expiryDate;

  apiBaseUrl = 'https://id.twitch.tv/oauth2/token';

  get headers() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.accessToken,
      'client-id': this.clientId,
    };
    return { headers: new HttpHeaders(headers) };
  }

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private authService: AuthService
  ) {}

  auth(): Observable<any> {
    const activeAccountId = this.authService.getUserAccountId();
    const currentDate = new Date().valueOf();
    const accountChanged = this.usedAccountId !== activeAccountId;

    if (accountChanged) {
      this.clientId = '';
      this.clientSecret = '';
    }

    this.store.select(getTwitchSettings).subscribe((settings) => {
      if (!settings) {
        return;
      }
      this.clientId = settings.clientId;
      this.clientSecret = settings.clientSecret;
      this.usedAccountId = activeAccountId;
    });

    if (!this.clientId || !this.clientSecret) {
      return of({ status: 'missing-credentials' });
    }

    const body = {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      grant_type: 'client_credentials',
    };

    const newTokenRequired = !this.accessToken || this.expiryDate - currentDate < 30000;
    if (!newTokenRequired) {
      return of({ accessToken: this.accessToken, clientId: body.client_id });
    }

    return this.http.post(this.apiBaseUrl, body).pipe(
      map((data) => {
        this.accessToken = data['access_token'];
        this.expiryDate = new Date().valueOf() + data['expires_in'] * 1000;
        return { accessToken: this.accessToken, clientId: body.client_id };
      }),
      catchError(({ error }) => {
        switch (error.message) {
          case 'invalid client': {
            error = { status: 'invalid-client' };
            break;
          }
          case 'invalid client secret': {
            error = { status: 'invalid-secret' };
            break;
          }
          default:
            error = { status: 'invalid-credentials' };
            break;
        }
        return of(error);
      })
    );
  }
}
