import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { DataSourceOptions } from '../custom-form-builder/data-source-options.enum';
import { hasRequiredValidation } from '../widget-configuration/field-builder/has-required-validation/has-required-validation';
import { generateHelpTooltip } from '../widget-configuration/field-builder/info-tooltip.text';

@Component({
  selector: 'gd-text-value-list',
  templateUrl: './text-value-list.component.html',
  styleUrls: ['./text-value-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextValueListComponent implements OnInit, OnChanges {
  @Input() separatorKeysCodes;
  @Input() hasUpdatePermissionFlag;
  @Input() fieldControl: AbstractControl;
  @Input() fieldConfig;
  @Input() readOnlyMode = false;

  dataSourceValue;
  isFormControlRequired;
  options;
  textValueInputFormControl = new UntypedFormControl();
  infoTooltip = '';
  placeholderText = $localize`Value`;

  public DataSourceOptions = DataSourceOptions;

  constructor() {
    this.options = {
      onUpdate: (event: any) => {
        this.fieldControl.markAsDirty();
      }};
  }

  ngOnInit(): void {
    this.options = { ...this.options, disabled: this.readOnlyMode };
    this.textValueInputFormControl.setValue('');
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    const value = this.fieldControl.value;
    this.dataSourceValue = Array.isArray(value) ? value : [];
    if (this.isFormControlRequired) {
      this.fieldControl.addValidators(Validators.required);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
    this.placeholderText = this.fieldConfig.description || $localize`Value`;
  }

  addDataSource(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.dataSourceValue.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.updateTextList();
    this.textValueInputFormControl.setValue('');
  }

  addDataSourceUsingButton(value): void {
    if ((value || '').trim()) {
      this.dataSourceValue.push(value.trim());
    }
    this.updateTextList();
    this.textValueInputFormControl.setValue('');
  }

  removeDataSource(id: number): void {
    if (id >= 0) {
      this.dataSourceValue.splice(id, 1);
    }
    this.updateTextList();
  }

  updateTextList() {
    if (this.dataSourceValue.length === 0 && this.isFormControlRequired) {
      this.fieldControl.addValidators(Validators.required);
      this.fieldControl.updateValueAndValidity();
    }
    if (this.dataSourceValue.length > 0 && this.isFormControlRequired) {
      this.fieldControl.removeValidators(Validators.required);
      this.fieldControl.updateValueAndValidity();
    }
    if (this.fieldControl.valid) {
      this.fieldControl.setValue(this.dataSourceValue);
      this.fieldControl.markAsDirty();
    }
  }
}

