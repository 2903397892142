
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { of } from 'rxjs';

@Injectable()
export class MenusService {

  constructor(private rest: RestService) { }

  getMenus() {
    return this.rest.get('menus').pipe(
      map(response => response.data));
  }

  getMenu(id) {
    return this.rest.get(`menus/${id}`).pipe(
      map(response => response.data));
  }

  createMenu(payload) {
    return this.rest.post('menus', payload).pipe(
      map(response => response.data));
  }

  updateMenu(payload) {
    const menu = payload;
    return this.rest.put(`menus/${menu.id}`, menu).pipe(
      map((response: any) => response.data));
  }

  deleteMenu(id) {
    return this.rest.delete(`menus/${id}`);
  }

  getFilteredMenus(name) {
    let requestPath = `menus`;
    requestPath += name ? `?name=${name}` : '';
    return this.rest
      .get(requestPath).pipe(
      map((response: any) => response.data));
  }

  getMenusByIds(menusId) {
    if (menusId.length < 1) {
      return of([]);
    }
    return this.rest.get(`menus?ids=${menusId.join(',')}`).pipe(
      map((response: any) => response.data));
  }
}
