export interface Taxonomy {
  id: number;
  name: string;
}

export interface Author {
  id: number;
  name: string;
}

export interface Image {
  id: string;
  path: string;
}

export interface PromoDetails {
  image: Image;
  promoSubTitle: string;
  promoTitle: string;
}

export interface Seo {
  slug: string;
  title: string;
  author: string;
  description: string;
  canonicalUrl: string;
  keywords: string[];
  automaticSeo: boolean;
}

export interface SocialSharingData {
  facebook?: SocialSharingItem;
  twitter?: SocialSharingItem;
}

export interface SocialSharingItem {
  image: Image;
  title: string;
  description: string;
  message: string;
  enable: boolean;
}

export interface Video {
  videoPlatform: string;
  id: string;
  name: string;
  description: string;
  autoplay: boolean;
  imageUrl: string;
}

export interface LiveReportRevision {
  id: number;
  headline: string;
  createdAt: number;
  catchline: string;
  description: string;
  taxonomies: Taxonomy[];
  primaryTaxonomy?: Taxonomy;
  // primaryTaxonomyId: number;
  authors: Author[];
  promoDetails?: PromoDetails;
  seo: Seo;
  socialSharingData?: SocialSharingData;
  autoFinish: string;
  autoFinishPeriod: number;
  articleEmbed: boolean;
  chronologicalOrder: boolean;
  live: string;
  publishFrom?: string | number;
  publishTo?: string | number;
  useAutomaticRelatedArticles?: boolean;
  relatedVideos?: Video[];
  autofillAuthor: boolean;
  customTypeData?: any;
}

export interface ActiveLiveReportState {
  id: number;
  createdAt: string | number;
  updatedAt: string | number;
  firstPublishedAt: string | number;
  lastPublishedAt: string | number;
  latestPostPublishedAt: number;
  createdBy: number;
  updatedBy: number;
  firstPublishedBy: number;
  lastPublishedBy: number;
  workingRevision: LiveReportRevision;
  publishedRevision: LiveReportRevision;
  summary: Summary;
  loaded: boolean;
  loading: boolean;
  relatedArticles: any[];
  postCount: number;
}

export function getEmptyActiveLiveReportState({
  loaded = false,
  loading = false,
}): ActiveLiveReportState {
  return {
    id: null,
    createdAt: null,
    updatedAt: null,
    firstPublishedAt: null,
    lastPublishedAt: null,
    latestPostPublishedAt: null,
    createdBy: null,
    updatedBy: null,
    firstPublishedBy: null,
    lastPublishedBy: null,
    workingRevision: null,
    publishedRevision: null,
    summary: null,
    loaded,
    loading,
    relatedArticles: null,
    postCount: null,
  };
}

export interface Summary {
  id: number;
  createdAt: string | number;
  updatedAt: string | number;
  firstPublishedAt: string | number;
  lastPublishedAt: string | number;
  createdBy: number;
  updatedBy: number;
  firstPublishedBy: number;
  lastPublishedBy: number;
  workingRevision: SummaryRevision;
  publishedRevision: SummaryRevision;
}

export interface SummaryRevision {
  id: number;
  body: string;
}
