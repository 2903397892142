import { filter, tap, catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  GET_WIDGET_TYPES,
  GetWidgetTypesSuccessAction,
  GetWidgetTypesErrorAction,
  CREATE_WIDGET_TYPE,
  CreateWidgetTypeSuccessAction,
  DELETE_WIDGET_TYPE,
  DeleteWidgetTypeSuccessAction,
  UPDATE_WIDGET_TYPE,
  UpdateWidgetTypeSuccessAction,
  WIDGET_TYPES_ACTION_FAILED,
} from './widget-types.actions';
import { RestService } from '../../api/rest.service';
import { WidgetType } from './widget-types.model';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';

@Injectable()
export class WidgetTypesEffects {

  loadWidgetTypeList$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_WIDGET_TYPES)).pipe(
    mergeMap((action) => {
      const payload = action['payload'] ? action['payload'] : {};
      return this.getWidgetTypes(payload).pipe(
        map((pagesListData) => new GetWidgetTypesSuccessAction(pagesListData)),
        catchError((e) => of(new GetWidgetTypesErrorAction(e)))
      );
    })
  ));


  createWidgetType$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_WIDGET_TYPE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.createWidgetType(action.payload).pipe(
        map((pagesListData) => new CreateWidgetTypeSuccessAction(pagesListData)),
        tap((widget) => {
          const data = widget.payload['data'];
          this.snackBar.open($localize`Widget type created`, $localize`Close`, { duration: 4000 });
          const returnUrl = data['thirdParty'] ? 'third-party-widgets' : 'system-widgets';
          this.router.navigate(['/site-builder/' + returnUrl]);
        }),
        catchError((e) => of(new GetWidgetTypesErrorAction(e)))
      );
    })
  ));


  deleteWidgetType$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_WIDGET_TYPE)).pipe(
    mergeMap((action: UnsafeAction) => {
      const idToDelete = action.payload.id;
      return this.deleteWidgetType(action.payload).pipe(
        map(() => new DeleteWidgetTypeSuccessAction(idToDelete)),
        tap(() => this.snackBar.open($localize`Widget type deleted`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Failed to delete widget type!`, $localize`Close`);
          return of(new GetWidgetTypesErrorAction(e));
        })
      );
    })
  ));


  updateWidgetType$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_WIDGET_TYPE)).pipe(
    mergeMap((action: UnsafeAction) => {
      const widgetToUpdate = action.payload;
      return this.updateWidgetType(widgetToUpdate).pipe(
        map(() => new UpdateWidgetTypeSuccessAction(widgetToUpdate)),
        tap(() => {
          const returnUrl = widgetToUpdate.thirdParty ? 'third-party-widgets' : 'system-widgets';
          this.router.navigate(['/site-builder/' + returnUrl]);
          this.snackBar.open($localize`Widget type successfully updated`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update widget type!`, $localize`Close`);
          return of(new GetWidgetTypesErrorAction(e));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(WIDGET_TYPES_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  constructor(
    private actions$: Actions,
    private rest: RestService,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {}

  processWidgetTypes(typesArray: WidgetType[]) {
    if (!typesArray || typesArray.length === 0) {
      return [];
    }

    return typesArray.map((wt: WidgetType) => {
      // API currently servers widget type config as a plain text
      wt.configuration = JSON.parse(wt.configuration as string);
      return wt;
    });
  }

  createWidgetType(payload: WidgetType) {
    const newWidgetType = { ...payload };
    delete newWidgetType.id;
    newWidgetType.configuration = JSON.stringify(newWidgetType.configuration);
    const requestPath = payload.thirdParty ? 'third-party-widgets' : 'system-widgets';
    return this.rest.post(requestPath, newWidgetType);
  }

  updateWidgetType(payload: WidgetType) {
    const updatedWidgetType = { ...payload };
    updatedWidgetType.configuration = JSON.stringify(updatedWidgetType.configuration);
    const requestPath = payload.thirdParty ? 'third-party-widgets' : 'system-widgets';
    return this.rest.put(requestPath + `/${payload.id}`, updatedWidgetType);
  }

  deleteWidgetType({ id, thirdParty }) {
    const requestPath = thirdParty ? 'third-party-widgets' : 'system-widgets';
    return this.rest.delete(requestPath + `/${id}`);
  }

  getWidgetTypes(payload: any) {
    const has3rdPartyFlag = payload.hasOwnProperty('thirdParty');
    let requestPath = 'widget-types';
    if (has3rdPartyFlag) {
      requestPath = payload.thirdParty ? 'third-party-widgets' : 'system-widgets';
    }
    return this.rest.get(requestPath).pipe(map((reponse) => this.processWidgetTypes(reponse.data)));
  }
}
