import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImagesService, ImagesUploadService } from '../../core/api';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import shortid from 'shortid';
import { AddImagesToQueueAction } from '../../core/store/images-upload/images-upload.actions';
import { getActiveAccount, selectActiveUserDetails } from '../../core/store/auth/auth.reducer';
import { Subscription } from 'rxjs';
import { LoadActiveUserDetailsAction } from '../../core/store';
import {
  sdxlV0ImageDimensions,
  sdxlV1ImageDimensionsPortrait,
  titanImageDimensionsPortrait,
  titanImageDimensionsLandscape,
  sdxlV1ImageDimensionsLandscape,
  stylePresetList,
} from '../generate-image-form/select-input-options.js';
import { FoundationModels } from '../content-generation/content-generation-models';
import { MixPanelService } from '../../core/api/mixpanel/mixpanel.service';

@Component({
  selector: 'gd-generate-image-form',
  templateUrl: './generate-image-form.component.html',
  styleUrls: ['./generate-image-form.component.scss'],
})
export class GenerateImageFormComponent implements OnInit, OnDestroy {
  generateImageForm: UntypedFormGroup = this.formBuilder.group({
    text: ['', [Validators.required]],
    textWeight: [1, [Validators.min(0.1), Validators.max(2)]],
    negativeText: [''],
    negativeTextWeight: [0, [Validators.min(0), Validators.max(2)]],
    promptStrength: [0],
    generationStep: [50],
    seed: [0],
    stylePreset: ['none'],
    width: [512],
    height: [512],
  });
  imageUrl = '';
  base64 = '';
  loading = false;
  @Input() imageData = null;
  @Input() usage;
  @Output() queuedFilesEvent = new EventEmitter();
  @Output() imageGeneratedFormEmitter = new EventEmitter<boolean>();
  get textControl() {
    return <UntypedFormControl>this.generateImageForm.get('text');
  }

  get textWeightControl() {
    return <UntypedFormControl>this.generateImageForm.get('textWeight');
  }

  get negativeTextWeightControl() {
    return <UntypedFormControl>this.generateImageForm.get('negativeTextWeight');
  }

  get stylePresetControl() {
    return <UntypedFormControl>this.generateImageForm.get('stylePreset');
  }

  get negativeTextControl() {
    return <UntypedFormControl>this.generateImageForm.get('negativeText');
  }

  componentSub: Subscription = new Subscription();

  userInfo;
  accountName = '';
  imageDimensionForm: UntypedFormControl = new UntypedFormControl('512x512');
  imageOrientationForm: UntypedFormControl = new UntypedFormControl('Portrait');
  stylePresetList = [];
  imageDimensions = [];
  imageOrientation = [ { label: 'Portrait' }, { label: 'Landscape' } ];
  modelSettings = null;
  modelVersion = '';
  modelConfigParam = []
  constructor(
    private formBuilder: UntypedFormBuilder,
    private imagesUploadService: ImagesUploadService,
    private store: Store<AppState>,
    private imagesService: ImagesService,
    private mixPanelService: MixPanelService
  ) {}

  ngOnInit(): void {
    this.modelSettings = this.imagesService.mediaAIGenModelSettings();
    this.modelVersion = this.getModelVersion(this.modelSettings.modelId);
    this.modelConfigParam = FoundationModels.find(obj => obj.modelId === this.modelSettings.modelId).params;
    this.setFormValidation();
    if (this.imageData) {
      this.patchGeneratedImageData(this.imageData);
      this.setDimensionInputOptions();
    } else {
      this.setDimensionInputOptions();
      this.loadImageConfiguration();
    }
    this.componentSub.add(
      this.store.select(selectActiveUserDetails).subscribe((user) => {
        if (!user.profileData) {
          this.store.dispatch(new LoadActiveUserDetailsAction());
        }
        this.userInfo = user;
      })
    );
    this.componentSub.add(
      this.store.select(getActiveAccount).subscribe((acc) => (this.accountName = acc.name))
    );
  }

  generate() {
    this.loading = true;
    this.imageUrl = '';
    this.base64 = '';
    this.imageGeneratedFormEmitter.emit(false);
    this.imageData = null;
    this.textControl.setErrors(null);
    let payload: any = {
      cfgSteps: +this.generateImageForm.get('promptStrength').value,
      seed: +this.generateImageForm.get('seed').value,
      text: this.textControl.value,
      negativeText: this.negativeTextControl.value,
      width: this.generateImageForm.get('width').value,
      height: this.generateImageForm.get('height').value,
      modelId: this.modelSettings.modelId,
    };

    if (this.modelVersion !== 'TitanImageV1') {
      payload.stylePreset = this.stylePresetControl.value;
      payload.negativeTextWeight =
      this.negativeTextWeightControl.value - this.negativeTextWeightControl.value * 2;
      payload.textWeight= this.textWeightControl.value;
      payload.step = +this.generateImageForm.get('generationStep').value;
    }

    const event = this.usage === 'imagePage' ? 'ImageMediaGenerate' : 'ImageModalGenerate';
    this.mixPanelService.trackEvent(event, {});
    this.imagesUploadService
      .generateImage(payload)
      .then((imageBase64) => {
        this.imageUrl = 'data:image/jpeg;base64,' + imageBase64;
        this.base64 = imageBase64;
        this.loading = false;
        this.imageGeneratedFormEmitter.emit(true);
      })
      .catch((err) => {
        this.imageUrl = '';
        this.base64 = '';
        this.loading = false;
        this.textControl.setErrors({ invalidText: true });
      });
  }

  async addToQueue() {
    let blob = null;
    if (!this.imageData) {
      blob = await this.b64toBlob(this.base64, 'image/jpeg');
    }
    const file = !this.imageData
      ? await new File([blob], this.textControl.value.slice(0, 50) + '.png', {
          type: 'image/png',
        })
      : this.imageData.fileData;
    
    const imageDetails: any = {
      caption: this.textControl.value,
      ai_generated_image_prompt: this.textControl.value,
      ai_service: 'Amazon Bedrock',
      foundation_model: this.modelSettings.title,
      ai_author:
        +this.userInfo.userId === 1
          ? 'Superadmin'
          : this.userInfo?.profileData?.firstName + ' ' + this.userInfo?.profileData?.lastName,
      copyright: this.accountName,
      prompt_strength: +this.generateImageForm.get('promptStrength').value,
      seed: +this.generateImageForm.get('seed').value,
      negative_prompt: this.negativeTextControl.value,
    };
    if (this.modelVersion !== 'TitanImageV1') {
      imageDetails.generation_step = +this.generateImageForm.get('generationStep').value;
      imageDetails.style_preset = this.stylePresetControl.value;
    };

    const meta = [
      {
        key: 'image_details',
        value: imageDetails
      },
    ];
    const tagName = this.modelVersion !== 'TitanImageV1' ? 'Stable Diffusion XL' : 'Titan Image Generator G1'
    const imgURL = URL.createObjectURL(file);
    const dimensions = await this.imagesUploadService.getNaturalDimensions(imgURL);
    const item = {
      queueID: shortid.generate(10),
      fileData: file,
      dimensions,
      orientation: this.imageOrientationForm.value,
      imgURL,
      meta,
      tags: [
        { name: 'AI', premium: false, group: 'system' },
        { name: tagName, premium: false, group: 'system' },
      ],
      systemTags: [
        { name: 'AI', premium: false, group: 'system' },
        { name: tagName, premium: false, group: 'system' },
      ],
    };
    this.imageData = item;
    this.store.dispatch(new AddImagesToQueueAction([item]));
    this.queuedFilesEvent.emit([item]);
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  patchGeneratedImageData(data) {
    this.imageUrl = '';
    this.loading = true;
    const imageMeta = data.meta && data.meta.find((item) => item.key === 'image_details');

    const formData = {
      text: imageMeta.value?.caption,
      negativeText: imageMeta.value?.negative_prompt,
      promptStrength: +imageMeta.value?.prompt_strength,
      seed: +imageMeta.value?.seed,
      generationStep: +imageMeta.value?.generation_step,
      height: data.dimensions.height,
      width: data.dimensions.width,
      stylePreset: imageMeta?.value?.style_preset
    };
    const dimensionsLabel = formData.width.toString() + 'x' + formData.height.toString();    
    this.imageDimensionForm.setValue(dimensionsLabel);
    this.imageOrientationForm.patchValue(data.orientation);
    this.generateImageForm.patchValue(formData);
    setTimeout(() => {
      this.imageUrl = data.imgURL;
      this.loading = false;
    }, 20);
  }

  resetAdvanceConfig() {
    const formValues = {
      promptStrength: +(this.modelSettings?.promptStrength || 10),
      seed: +(this.modelSettings?.seed || 0),
      generationStep: +(this.modelSettings?.generationStep || 50),
      width: this.imageDimensions[0].width,
      height: this.imageDimensions[0].height,
      stylePreset: 'none',
    };
    this.imageDimensionForm.patchValue(this.imageDimensions[0].label);
    this.imageOrientationForm.patchValue("Portrait");
    this.generateImageForm.patchValue(formValues);
    this.sendMixpanelEvent(null, 'ImageAIReset');
  }

  loadImageConfiguration() {
    const formValues = {
      promptStrength: +this.modelSettings?.promptStrength,
      seed: +this.modelSettings?.seed,
      generationStep: +this.modelSettings?.generationStep,
      width: this.imageDimensions[0].width,
      height: this.imageDimensions[0].height,
    };
    this.imageDimensionForm.patchValue(this.imageDimensions[0].label);
    this.generateImageForm.patchValue(formValues);
  }

  changeImageDimension(width, height) {
    this.generateImageForm.get('width').setValue(width);
    this.generateImageForm.get('height').setValue(height);
    this.imageDimensionForm.patchValue(width.toString() + "x" + height.toString());
  }

  changeImageOrientation(orientation) {
    this.imageOrientationForm.patchValue(orientation);
    this.setDimensionInputOptions();
    this.imageDimensionForm.patchValue(this.imageDimensions[0].label);
  }

  setDimensionInputOptions() {
    if (this.modelVersion === 'SDXLV0.8') {
      this.stylePresetList = stylePresetList;
      this.imageDimensions = sdxlV0ImageDimensions;
    }

    if (this.imageOrientationForm.value === 'Portrait') {
      if (this.modelVersion === 'SDXLV1') {
        this.stylePresetList = stylePresetList;
        this.imageDimensions =  sdxlV1ImageDimensionsPortrait;
      }
      if (this.modelVersion === 'TitanImageV1') {
        this.imageDimensions = titanImageDimensionsPortrait;
      }
    }

    if (this.imageOrientationForm.value === 'Landscape') {
      if (this.modelVersion === 'SDXLV1') {
        this.stylePresetList = stylePresetList;
        this.imageDimensions =  sdxlV1ImageDimensionsLandscape;
      }
      if (this.modelVersion === 'TitanImageV1') {
        this.imageDimensions = titanImageDimensionsLandscape;
      }
    }
  }

  getModelVersion(model) {
    switch(model) {
      case 'stability.stable-diffusion-xl':
      case 'stability.stable-diffusion-xl-v0':
        return 'SDXLV0.8';
      case 'stability.stable-diffusion-xl-v1':
        return 'SDXLV1';
      case 'amazon.titan-image-generator-v1':
        return 'TitanImageV1';
      default:
        return 'Unknown'
    }
  }
  
  getFormControl(key) {
    return this.generateImageForm.get(key) as UntypedFormControl;
  }

  handleNumberInputChange(control, value, event) {
    control.setValue(value);
    control.markAsDirty();
    this.sendMixpanelEvent(value, event);
  }

  sendMixpanelEvent(value, event) {
    const formatEventName = 'Image' + event.replace(/(?:^\w|[A-Z]|\b\w)/g, match => match.toUpperCase()).replace(/\s+/g, '');
    const eventSuffix = this.usage === 'imagePage' ? 'Media' : '';
    const eventName = formatEventName + eventSuffix;
    const valObj = value ? { value: value } : {}
    this.mixPanelService.trackEvent(eventName, valObj);
  }

  setFormValidation() {
    this.modelConfigParam.forEach((param) => {
      let validators = [];
        validators = [Validators.min(param.min), Validators.max(param.max)];
        this.generateImageForm.get(param.key).addValidators(validators);
    });
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }
}