import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateFolderAction } from '../../core/store/files/files.actions';
import { getActiveFolder } from '../../core/store/files/files.reducer';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent implements OnInit, OnDestroy {
  createFolderForm: UntypedFormGroup;
  folderPath;
  parentFolder;
  richTextEditorData;
  breadcrumb;
  activeFolderSub;
  richTextEditorOptions = {
    heightMin: 100,
    quickInsertEnabled: false,
  };
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private dialogRef: MatDialogRef<CreateFolderComponent>,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private bidiService: BidiService,
  ) {}

  ngOnInit(): void {
    this.createFolderForm = this.initCreateFolderFormGroup();
    this.activeFolderSub = this.store.select(getActiveFolder).subscribe(activeFolder => {
      this.folderPath = activeFolder.path;
      this.parentFolder = activeFolder.id;
      this.breadcrumb = activeFolder.path.split('/');
      this.breadcrumb.shift();
    });
  }

  initCreateFolderFormGroup() {
    return this.fb.group({
      folderName: ['', [Validators.required, Validators.pattern('^[^<>,#&+:"|?*\/\\\\]*$'), Validators.maxLength(255)]],
      description: [''],
    });
  }

  handleEditorContentChange(change) {
    this.createFolderForm.get('description').patchValue(change || '');
    this.createFolderForm.markAsDirty();
  }

  createFolder() {
    if (this.createFolderForm.invalid) {
      return;
    }
    const payload = {
      name: this.createFolderForm.value.folderName,
      parent: this.parentFolder,
      folderPath: this.folderPath,
      description: this.createFolderForm.value.description,
    };
    this.store.dispatch(new CreateFolderAction(payload));
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.activeFolderSub.unsubscribe();
  }
}
