import { UnsafeAction } from '../unsafe-action.interface';
import { Menu, NormalizedMenuItems } from './menus.model';
import {
    UPDATE_ACTIVE_MENU_SUCCESS, GET_ACTIVE_MENU_SUCCESS,
    ADD_MENU_ITEM, UPDATE_MENU_ITEM, DELETE_MENU_ITEM, REORDER_MENU_ITEMS, CHANGE_MENU_ITEM_PUBLISHED_STATE
} from './menus.actions';

export function activeMenuReducer(state: Menu = null, action: UnsafeAction): Menu {
    switch (action.type) {
        case GET_ACTIVE_MENU_SUCCESS:
        case UPDATE_ACTIVE_MENU_SUCCESS: {
            const activeMenu = action.payload;
            return {
                ...state,
                ...activeMenu
            };
        }

        case ADD_MENU_ITEM: {
            const [menuItems, menuId] = state ? [{ ...state.menuItems }, state.id] : [{}, null];
            const menuItem = { ...action.payload, menuId };
            menuItems[menuItem.menuItemId] = menuItem;

            const parentId = menuItem.parentMenuItemId;
            if (parentId) {
                menuItems[parentId].children = [...menuItems[parentId].children, menuItem.menuItemId];
            }
            return { ...state, menuItems };
        }

        case UPDATE_MENU_ITEM: {
            const menuItem = { ...action.payload };
            const menuItems = { ...state.menuItems };

            // remove menu item id from old parent children
            const oldParentId = menuItems[menuItem.menuItemId].parentMenuItemId;
            if (oldParentId) {
                menuItems[oldParentId].children = [...menuItems[oldParentId].children].filter(id => id !== menuItem.menuItemId);
            }

            // add menu item id to new parent children
            const newParentId = menuItem.parentMenuItemId;
            if (newParentId) {
                menuItems[newParentId].children = [...menuItems[newParentId].children, menuItem.menuItemId];
            }

            menuItems[menuItem.menuItemId] = { ...menuItems[menuItem.menuItemId], ...menuItem };

            return { ...state, menuItems };
        }

        case DELETE_MENU_ITEM: {
            const menuItems = { ...state.menuItems };
            const menuItem = action.payload;
            delete menuItems[menuItem.menuItemId];

            const parentMenuItem = menuItem.parentMenuItemId ? menuItems[menuItem.parentMenuItemId] : null;
            if (parentMenuItem) {
                parentMenuItem.children = parentMenuItem.children.filter(id => id !== menuItem.menuItemId);
            }

            deleteAllChildren(menuItem, menuItems);

            return { ...state, menuItems };
        }

        case CHANGE_MENU_ITEM_PUBLISHED_STATE: {
            const menuItem = action.payload;
            const menuItems = {
                ...state.menuItems,
                [menuItem.menuItemId]: {
                    ...state.menuItems[menuItem.menuItemId],
                    ...menuItem
                }
            };

            return { ...state, menuItems };
        }

        case REORDER_MENU_ITEMS: {
            const menuItems = reorderMenuItems(state.menuItems, action.payload);
            return { ...state, menuItems };
        }

        default:
            return state;
    }

}

function reorderMenuItems(items: NormalizedMenuItems, payload: any) {
    const parentOldId = payload.parentOldId;
    const parentNewId = payload.parentNewId;
    const parentNewChildren = payload.parentNewChildren;
    const nodeId = payload.nodeId;

    const rootChildren = Object.values(items)
        .filter(item => item.parentMenuItemId === null)
        .sort((a, b) => a.position - b.position)
        .map(item => item.menuItemId);
    const rootNode = { children: rootChildren };

    const newMenuItems = { ...items };
    newMenuItems[nodeId].parentMenuItemId = parentNewId;

    const oldParent: any = parentOldId ? newMenuItems[parentOldId] : rootNode;
    oldParent.children = oldParent.children.filter(id => id !== nodeId);
    oldParent.children.forEach((id, i) => newMenuItems[id].position = i);

    const index = parentNewChildren.indexOf(nodeId);
    const newParent: any = parentNewId ? newMenuItems[parentNewId] : rootNode;
    newParent.children.splice(index, 0, nodeId);
    newParent.children.forEach((id, i) => newMenuItems[id].position = i);

    return newMenuItems;
}

function deleteAllChildren(menuItem, menuItems: NormalizedMenuItems) {

  const menuItemHasChildren = menuItem.children.length > 0;
  if (!menuItemHasChildren) {
    return;
  }
  menuItem.children.forEach(child => {
    deleteAllChildren(child, menuItems),
    delete menuItems[child.menuItemId];
  });
}
