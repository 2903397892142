import { UnsafeAction } from '../unsafe-action.interface';
import { PageTemplate, TemplateRevisionDesignation, TemplateNode, TemplateNodeType } from './templates.model';
import { generateShortId } from '../../../shared/shared-functions';


export const GET_TEMPLATES = '[Templates] Get templates';
export class GetTemplatesAction implements UnsafeAction {
  readonly type = GET_TEMPLATES;
  constructor() { }
}

export const LOAD_TEMPLATES = '[Templates] Templates fetched';
export class GetTemplatesActionSuccess implements UnsafeAction {
  readonly type = LOAD_TEMPLATES;
  constructor(public payload: PageTemplate[]) { }
}

export const SET_TEMPLATE_FILTER = '[Templates] Set taxonomy filter';
export class SetTemplateFilterAction implements UnsafeAction {
  readonly type = SET_TEMPLATE_FILTER;
  constructor(public payload: String) { }
}

export const SET_ACTIVE_TEMPLATE_ID = '[Templates] Set active template';
export class SetActiveTemplateIdAction implements UnsafeAction {
  readonly type = SET_ACTIVE_TEMPLATE_ID;
  constructor(public payload: number) { }
}

export const SET_ACTIVE_TEMPLATE_REVISION_DESIGNATION = '[Templates] Set active template revision designation';
export class SetActiveTemplateRevisionDesignationAction implements UnsafeAction {
  readonly type = SET_ACTIVE_TEMPLATE_REVISION_DESIGNATION;
  constructor(public payload: TemplateRevisionDesignation) { }
}

export const DELETE_TEMPLATE = '[Templates] Delete template';
export class DeleteTemplateAction implements UnsafeAction {
  readonly type = DELETE_TEMPLATE;
  constructor(public payload: any) { }
}

export const DELETE_TEMPLATE_COMPLETED = '[Templates] Delete template completed';
export class DeleteTemplateCompletedAction implements UnsafeAction {
  readonly type = DELETE_TEMPLATE_COMPLETED;
  constructor(public payload?: any) { }
}


// for creating new templates
export const LOAD_NEW_EMPTY_TEMPLATE = '[Templates] Load empty new template';
export class LoadEmptyNewTemplateAction implements UnsafeAction {
  readonly type = LOAD_NEW_EMPTY_TEMPLATE;
  constructor() { }
}

export const CLEAR_NEW_EMPTY_TEMPLATE = '[Templates] Clear new template placeholder';
export class ClearEmptyNewTemplateAction implements UnsafeAction {
  readonly type = CLEAR_NEW_EMPTY_TEMPLATE;
  constructor() { }
}

// New template actions
export const CREATE_TEMPLATE = '[Templates] Create template';
export class CreateTemplateAction implements UnsafeAction {
  readonly type = CREATE_TEMPLATE;
  constructor(public payload: any) { }
}

export const CREATE_TEMPLATE_SUCCESS = '[Templates] Create template success';
export class CreateTemplateSuccessAction implements UnsafeAction {
  readonly type = CREATE_TEMPLATE_SUCCESS;
  constructor(public payload: PageTemplate) { }
}

export const UPDATE_TEMPLATE = '[Templates] Update template';
export class UpdateTemplateAction implements UnsafeAction {
  readonly type = UPDATE_TEMPLATE;
  constructor(public payload: any) { }
}

export const UPDATE_TEMPLATE_SUCCESS = '[Templates] Update template success';
export class UpdateTemplateSuccessAction implements UnsafeAction {
  readonly type = UPDATE_TEMPLATE_SUCCESS;
  constructor(public payload: PageTemplate) { }
}

export const PUBLISH_TEMPLATE = '[Templates] Publish template';
export class PublishTemplateAction implements UnsafeAction {
  readonly type = PUBLISH_TEMPLATE;
  constructor(public payload: any) { }
}

export const PUBLISH_TEMPLATE_SUCCESS = '[Templates] Publish template sucess';
export class PublishTemplateSucessAction implements UnsafeAction {
  readonly type = PUBLISH_TEMPLATE_SUCCESS;
  constructor(public payload: any) { }
}

export const UNPUBLISH_TEMPLATE = '[Templates] Unpublish template';
export class UnpublishTemplateAction implements UnsafeAction {
  readonly type = UNPUBLISH_TEMPLATE;
  constructor(public payload: any) { }
}

export const UNPUBLISH_TEMPLATE_SUCCESS = '[Templates] Publish template sucess';
export class UnpublishTemplateSucessAction implements UnsafeAction {
  readonly type = UNPUBLISH_TEMPLATE_SUCCESS;
  constructor(public payload: any) { }
}

export const TEMPLATE_FAILED_ACTION = '[Templates] Action Failed';
export class TemplatesFailedAction implements UnsafeAction {
  readonly type = TEMPLATE_FAILED_ACTION;
  constructor(public payload: any) { }
}

// for working with tempate nodes: widgets and layout blocks
export const CREATE_NODE = '[Templates] Create node';
export class CreateTemplateNodeAction implements UnsafeAction {
  readonly type = CREATE_NODE;
  constructor(public payload: any) {
    payload.id = generateShortId();
    payload.cssClass = payload.cssClass || '';
    if (payload.type === TemplateNodeType.Widget) {
      payload.visible = true;
    }
  }
}

export const CREATE_NODE_COLLECTION = '[Templates] Create node collection';
export class CreateTemplateNodeCollectionAction implements UnsafeAction {
  readonly type = CREATE_NODE_COLLECTION;
  constructor(public payload: any) {
    payload = payload.map((n: TemplateNode, i) => {
      n.id = generateShortId();
      n.cssClass = n.cssClass || '';
      n.additionalItems = {
        ...(n.additionalItems || {}),
        // note: this is safe to do since there is no insert container element dialog
        // which could have different value which this would override, which is the case for widgets
        omitWrapperElement: false
      }
      return n;
    });
  }
}

export const UPDATE_NODE_DATA = '[Templates] Update node data';
export class UpdateNodeDataAction implements UnsafeAction {
  readonly type = UPDATE_NODE_DATA;
  constructor(public payload: any) { }
}

export const UPDATE_NODE_POSITION = '[Templates] Update node position';
export class UpdateNodePositionAction implements UnsafeAction {
  readonly type = UPDATE_NODE_POSITION;
  constructor(public payload: any) {

  }
}

export const UPDATE_ACTIVE_TEMPLATE_DATA = '[Templates] Update active template data';
export class UpdateActiveTemplateDataAction implements UnsafeAction {
  readonly type = UPDATE_ACTIVE_TEMPLATE_DATA;
  constructor(public payload: any) { }
}

export const DELETE_NODE = '[Templates] Delete node';
export class DeleteTemplateNodeAction implements UnsafeAction {
  readonly type = DELETE_NODE;
  constructor(public payload: TemplateNode) { }
}

export const SET_TEMPLATES_LOADING_FLAG = '[Templates] Set templates loading flag';
export class SetTemplatesLoadingFlagAction implements UnsafeAction {
  readonly type = SET_TEMPLATES_LOADING_FLAG;
  constructor(public payload: any) { }
}
