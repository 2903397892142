import {
  Component,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import {
  AddImagesToQueueAction,
  RemoveImageFromQueueAction,
} from '../../core/store/images-upload/images-upload.actions';
import shortid from 'shortid';
import { ImagesUploadService } from '../../core/api';
import { RemoveFileFromQueueAction, AddFilesToQueueAction } from '../../core/store/files-upload/files-upload.actions';

@Component({
  selector: 'gd-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnChanges, AfterViewInit {
  @Input() dialog = 'native';
  @Input() dzConfig = <any>{
    multiUpload: true,
  };
  @Output() queuedFilesEvent = new EventEmitter();
  @Output() dialogEvent = new EventEmitter();

  fileInputRef;

  constructor(
    private store: Store<AppState>,
    private elRef: ElementRef,
    private uploadService: ImagesUploadService
  ) {}

  ngAfterViewInit() {
    this.fileInputRef = this.elRef.nativeElement.querySelector(
      `#${'dropzone-input-file-' + this.dialog}`
    );
    this.fileInputRef.addEventListener('click', this.handleFileInputClickEvent.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dialog && changes.dialog.currentValue) {
      this.dialog = changes.dialog.currentValue;
    }

    if (changes.dzConfig && changes.dzConfig.currentValue) {
      this.dzConfig = { ...this.dzConfig, ...changes.dzConfig.currentValue };
    }
  }

  async handleImageInputChanges(event) {
    let files = event.target ? event.target.files[0] : event.files[0];
    let selectedImages = [];

    if (files) {
      const shouldChangeImage = !!this.fileInputRef.dataset['changeImage'];
      const currentImage = this.fileInputRef.dataset['currentImage'];

      if (shouldChangeImage) {
        this.store.dispatch(new RemoveImageFromQueueAction(currentImage));
      }

      files = event.files ? event.files : event.target.files;

      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];
        selectedImages.push(selectedFile);
      }

      // Assign queueID, and upload mode to selected images
      selectedImages = selectedImages.map(async img => {
        // Resolve image original dimensions
        // Dimensions are passed to API in order to create original image format upon request creation
        const imgURL = URL.createObjectURL(img);

        const dimensions = await this.uploadService.getNaturalDimensions(imgURL);

        return {
          queueID: shortid.generate(10),
          fileData: img,
          dimensions,
          imgURL,
        };
      });

      const resolvedSelectedImages = await Promise.all(selectedImages);

      this.store.dispatch(new AddImagesToQueueAction(resolvedSelectedImages));

      // Dispatch event of selected images so the consumer components can be aware of dispatched files
      // without watching for changes in queue from store
      this.queuedFilesEvent.emit(resolvedSelectedImages);

      // Clear input field
      if (event.target) {
        event.target.value = '';
      }
    }
  }


  async handleFileInputChanges(event) {
    let files = event.target ? event.target.files[0] : event.files[0];
    let selectedFiles = [];

    if (files) {
      const shouldChangeFile = !!this.fileInputRef.dataset['changeFile'];
      const currentFile = this.fileInputRef.dataset['currentFile'];

      if (shouldChangeFile) {
        this.store.dispatch(new RemoveFileFromQueueAction(currentFile));
      }

      files = event.files ? event.files : event.target.files;
      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];
        selectedFiles = [...selectedFiles, selectedFile];
      }

      // Assign queueID, and upload mode to selected files
      selectedFiles = selectedFiles.map(file => {
        return {
          queueID: shortid.generate(10),
          fileData: file,
        };
      });
      const resolvedSelectedFiles = await Promise.all(selectedFiles);

      this.store.dispatch(new AddFilesToQueueAction(resolvedSelectedFiles));

      // Dispatch event of selected files so the consumer components can be aware of dispatched files
      // without watching for changes in queue from store
      this.queuedFilesEvent.emit(event);

      // Clear input field
      if (event.target) {
        event.target.value = '';
      }
    }
  }

  handleInput(event) {
    if (this.dzConfig.view !== 'files') {
      return this.handleImageInputChanges(event);
    } else {
      return this.handleFileInputChanges(event);
    }
  }

  handleFileInputClickEvent(event) {
    if (this.dialog && this.dialog !== 'native') {
      event.preventDefault();
      event.stopPropagation();
      this.dialogEvent.emit('OPEN_SELECTOR');
    }
  }

  openNativeDialog(properties) {
    Object.entries(properties).forEach(entry => {
      this.fileInputRef.dataset[entry[0]] = entry[1];
    });

    this.fileInputRef.click();
  }
}
