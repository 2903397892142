import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared/material-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextValueListComponent } from './text-value-list.component';
import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  declarations: [
    TextValueListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SortablejsModule
  ],
  exports: [
    TextValueListComponent
  ]
})
export class TextValueListModule { }
