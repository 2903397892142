import { UnsafeAction } from '../unsafe-action.interface';

export const LOGIN = '[Auth] Attempt login';
export class LoginAction implements UnsafeAction {
    readonly type = LOGIN;
    // payload is username and password object
    constructor(public payload: any) { }
}

export const LOGGED_IN = '[Auth] Logged in';
export class LoggedInAction implements UnsafeAction {
    readonly type = LOGGED_IN;
    // payload is user data
    constructor(public payload: any) { }
}

export const LOGIN_FAILED = '[Auth] Login failed';
export class LoginFailedAction implements UnsafeAction {
    readonly type = LOGIN_FAILED;
    // payload is the error
    constructor(public payload: any) { }
}

export const LOG_OUT = '[Auth] Log out';
export class LogOutAction implements UnsafeAction {
    readonly type = LOG_OUT;
    constructor() { }
}

export const LOG_OUT_SUCCESS = '[Auth] Log out success';
export class LogOutSuccessAction implements UnsafeAction {
    readonly type = LOG_OUT_SUCCESS;
    constructor() { }
}

export const ACCOUNT_CHANGED = '[Auth] Account changed';
export class AccountChangeAction implements UnsafeAction {
    readonly type = ACCOUNT_CHANGED;
    constructor(public payload: any) { }
}

export const LOAD_ACTIVE_USER_DETAILS = '[Auth] Load active user details';
export class LoadActiveUserDetailsAction implements UnsafeAction {
    readonly type = LOAD_ACTIVE_USER_DETAILS;
    constructor() { }
}

export const LOAD_ACTIVE_USER_DETAILS_SUCCESS = '[Auth] Load active user details success';
export class LoadActiveUserDetailsSuccessAction implements UnsafeAction {
    readonly type = LOAD_ACTIVE_USER_DETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export const SET_ACTIVE_USER_AVATAR = '[Auth] Set active user avatar';
export class SetActiveUserAvatarAction implements UnsafeAction {
    readonly type = SET_ACTIVE_USER_AVATAR;
    constructor(public payload: any) { }
}

export const SEND_EMAIL = '[Auth] Send email';
export class SendEmailAction implements UnsafeAction {
    readonly type = SEND_EMAIL;
    constructor(public payload: any) { }
}

export const SEND_EMAIL_SUCCESS = '[Auth] Send email success';
export class SendEmailSuccessAction implements UnsafeAction {
    readonly type = SEND_EMAIL_SUCCESS;
    constructor(public payload: any) { }
}

export const RESET_PASSWORD = '[Auth] Reset password';
export class ResetPasswordAction implements UnsafeAction {
    readonly type = RESET_PASSWORD;
    constructor(public payload: any) { }
}

export const RESET_PASSWORD_SUCCESS = '[Auth] Reset password success';
export class ResetPasswordSuccessAction implements UnsafeAction {
    readonly type = RESET_PASSWORD_SUCCESS;
    constructor(public payload: any) { }
}

export const RESET_PASSWORD_ERROR = '[Auth] Reset password error';
export class ResetPasswordErrorAction implements UnsafeAction {
    readonly type = RESET_PASSWORD_ERROR;
    constructor(public payload: any) { }
}

export const BEFORE_ACCOUNT_CHANGED = '[Auth] Before Account changed';
export class BeforeAccountChangeAction implements UnsafeAction {
	readonly type = BEFORE_ACCOUNT_CHANGED;
	constructor(public payload: any) { }
}