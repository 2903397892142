import { CollectionType } from './collection-type.model';
import { CollectionTypeActions, CollectionTypeActionTypes } from './collection-types.actions';
import { AppState } from '../app-reducer';
import { createSelector } from '@ngrx/store';
import { orderBy } from 'lodash-es';

export interface CollectionTypeState {
  loaded: boolean;
  loading: boolean;
  error: string;
  activeCollectionTypeId: number;
  db: { [key: number]: CollectionType };
  embeddableResource: {};
}

const initialState: CollectionTypeState = {
  loaded: false,
  loading: false,
  activeCollectionTypeId: null,
  error: null,
  db : {},
  embeddableResource: {},
};

export function collectionTypesReducer(state: CollectionTypeState = initialState, action: CollectionTypeActions) {
  switch (action.type) {
    case CollectionTypeActionTypes.CREATE:
    case CollectionTypeActionTypes.UPDATE:
    case CollectionTypeActionTypes.DELETE:
    case CollectionTypeActionTypes.LOAD_ONE:
    case CollectionTypeActionTypes.LOAD: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case CollectionTypeActionTypes.SET_ACTIVE: {
      return {
        ...state,
        activeCollectionTypeId: action.payload,
      };
    }

    case CollectionTypeActionTypes.LOAD_SUCCESS: {
      const data: CollectionType[] = action.payload;
      const collectionTypesDb = data.reduce((acc, ct) => {
        acc[ct.id] = ct;
        return acc;
      }, {});

      return {
        ...state,
        db: collectionTypesDb,
        loaded: true,
        loading: false,
        error: null,
      };
    }

    case CollectionTypeActionTypes.LOAD_ONE_SUCCESS:
    case CollectionTypeActionTypes.UPDATE_SUCCESS:
    case CollectionTypeActionTypes.CREATE_SUCCESS: {
      const newCollectionType: CollectionType = action.payload;
      return {
        ...state,
        db: { ...state.db, [newCollectionType.id]: newCollectionType },
        loading: false,
        error: null,
      };
    }

    case CollectionTypeActionTypes.DELETE_SUCCESS: {
      if (!action.payload) {
        return { ...state, loading: false };
      }
      const newDb = { ...state.db };
      delete newDb[action.payload];
      return {
        ...state,
        db: newDb,
        loading: false,
      };
    }

    case CollectionTypeActionTypes.CLEAR: {
      return {
        ...state,
        db: {},
        loading: false
      }
    }

    case CollectionTypeActionTypes.SET_EMBEDDABLE: {
      return {
        ...state,
        loading: true,
      }
    }

    case CollectionTypeActionTypes.SET_EMBEDDABLE_SUCCESS: {
      return {
        ...state,
        embeddableResource: action.payload,
        loading: false
      }
    }

    default: {
      return state;
    }
  }
}

export const getCollectionTypesState = (state: AppState) => state.collectionTypes;
export const getCollectionTypes = (state: AppState) => state.collectionTypes.db;

export const getCollectionTypeLoading = createSelector(
  getCollectionTypesState,
  (collectionTypeState: CollectionTypeState) => collectionTypeState.loading
);

export const geCollectionTypeLoaded = createSelector(
  getCollectionTypesState,
  (collectionTypesState: CollectionTypeState) => collectionTypesState.loaded
);

export const getActiveCollectionType = createSelector(
  getCollectionTypesState,
  (collectionTypesState: CollectionTypeState) =>
    collectionTypesState.db[collectionTypesState.activeCollectionTypeId] || null
);

export const getCollectionTypesList = createSelector(
  getCollectionTypesState,
  (collectionTypesState: CollectionTypeState) => Object.values(collectionTypesState.db)
);

export const getDefaultFirstSortedCollectionTypesList = createSelector(
  getCollectionTypesList,
  (collectionTypes: CollectionType[]) => orderBy(collectionTypes, ['defaultType', 'name'], ['desc', 'asc'])
);

export const getCollectionTypeById = (id: number) => {
  return createSelector(
    getCollectionTypesState,
    (collectionTypeState: CollectionTypeState) => collectionTypeState.db[id] || null
  );
};

export const getDefaultCollectionType = createSelector(
  getCollectionTypesList,
  (collectionTypes: CollectionType[]) => collectionTypes.find(ct => ct.defaultType)
);

export const getNonDefaultCollectionTypes = createSelector(
  getCollectionTypesList,
  (collectionTypes: CollectionType[]) => collectionTypes.filter(ct => !ct.defaultType)
);

export const getEmbeddableResources = createSelector(
  getCollectionTypesState,
  (collectionTypeState: CollectionTypeState) => collectionTypeState.embeddableResource
)
