import { mergeMap, tap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CollectionTypeActionTypes,
  LoadCollectionTypesSuccessAction,
  FailureCollectionTypeAction,
  LoadOneCollectionTypeAction,
  LoadOneCollectionTypeSuccessAction,
  CreateCollectionTypeAction,
  CreateCollectionTypeSuccessAction,
  UpdateCollectionTypeAction,
  UpdateCollectionTypeSuccessAction,
  DeleteCollectionTypeAction,
  DeleteCollectionTypeSuccessAction,
  SetEmbeddableResourceSuccessAction,
} from './collection-types.actions';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { CollectionTypeService } from '../../api/collection-types/collection-types.service';
import { AppState } from '../app-reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { CollectionType } from './collection-type.model';

@Injectable()
export class CollectionTypesEffects {
  constructor(
    private actions$: Actions,
    private collectionTypesService: CollectionTypeService,
    private store: Store<AppState>,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}


  loadCollectionTypes$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(CollectionTypeActionTypes.LOAD))
    .pipe(
      mergeMap(() =>
        this.collectionTypesService.getAll().pipe(
          map((types) => new LoadCollectionTypesSuccessAction(types)),
          catchError((e) => of(new FailureCollectionTypeAction({ error: e })))
        )
      )
    ));


  loadOneCollectionType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CollectionTypeActionTypes.LOAD_ONE),
    mergeMap((action: LoadOneCollectionTypeAction) =>
      this.collectionTypesService.getOne(action.payload).pipe(
        map((types) => new LoadOneCollectionTypeSuccessAction(types)),
        catchError((e) => {
          this.router.navigate(['/site-builder/collection-types/']);
          this.snackBar.open($localize`Collection Type not found.`, $localize`Close`, { duration: 4000 });
          return of(new FailureCollectionTypeAction({ error: e }))})
      )
    )
  ));


  createCollectionType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CollectionTypeActionTypes.CREATE),
    mergeMap((action: CreateCollectionTypeAction) => {
      return this.collectionTypesService.create(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Collection type saved`, $localize`Close`, { duration: 2000 });
          this.router.navigate(['site-builder/collection-types']);
        }),
        map(
          (newCollectionType: CollectionType) =>
            new CreateCollectionTypeSuccessAction(newCollectionType)
        ),
        catchError((e) => of(new FailureCollectionTypeAction({ error: e })))
      );
    })
  ));


  updateCollectionType$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(CollectionTypeActionTypes.UPDATE))
    .pipe(
      mergeMap((action: UpdateCollectionTypeAction) => {
        return this.collectionTypesService.update(action.payload).pipe(
          tap(() => {
            this.snackBar.open($localize`Collection type saved`, $localize`Close`, { duration: 2000 });
            this.router.navigate(['site-builder/collection-types']);
          }),
          map(
            (newCollectionType: CollectionType) =>
              new UpdateCollectionTypeSuccessAction(newCollectionType)
          ),
          catchError((e) => of(new FailureCollectionTypeAction({ error: e })))
        );
      })
    ));


  deleteCollectionType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CollectionTypeActionTypes.DELETE),
    mergeMap((action: DeleteCollectionTypeAction) => {
      const idToDelete = action.payload;
      return this.collectionTypesService.delete(idToDelete).pipe(
        tap(() => this.snackBar.open($localize`Collection type deleted`, $localize`Close`, { duration: 2000 })),
        map(() => new DeleteCollectionTypeSuccessAction(idToDelete)),
        catchError(() => {
          this.snackBar.open($localize`Cannot delete Collection Type assigned to an Collection.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new DeleteCollectionTypeSuccessAction(null));
        })
      );
    })
  ));

  embeddableResources$: Observable<Action> = createEffect(() =>this.actions$.pipe(
    ofType(CollectionTypeActionTypes.SET_EMBEDDABLE),
    mergeMap(() => {
      return this.collectionTypesService.getEmbeddableResources().pipe(
        map((embeddableResources) => new SetEmbeddableResourceSuccessAction(embeddableResources)),
        catchError((e) => of(new FailureCollectionTypeAction({ error: e })))
      )
    })
  ));
}
