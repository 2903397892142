import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { ThemeManager } from '../../core/miscellaneous/theme-manager.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { AuthService, ImagesService } from '../../core/api';
import { AuthState, LogOutAction, getUserAvatarPath, SetActiveUserAvatarAction, BeforeAccountChangeAction } from '../../core/store';
import { Subscription } from 'rxjs';
import { AccountSettingsService } from '../../core/api/account-settings/accounts-settings.service';
import { UserPreferencesService } from '../../core/api/user-preferences/user-preferences.service';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { MixPanelService } from '../../core/api/mixpanel/mixpanel.service';
import { StorageService } from '../../core/api/storage/storage.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'gd-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {

  username: string;
  activeAccount: any;
  accounts: any;
  isSuperadmin: boolean;

  showBosnian = environment.client === 'GC1' && (environment.env === 'qa' || environment.env === 'dev');

  authState: AuthState;

  authStateSubscription: Subscription;

  userAvatarUrl$ = this.store.select(getUserAvatarPath).pipe(
    map(path => this.imagesService.generateImageUrl(path)),
    mergeMap(url => this.imagesService.validateImageUrl(url)),
    map(({ url, isValid }: any) => {
      if (url && !isValid) {
        this.store.dispatch(new SetActiveUserAvatarAction(null));
      }
      return isValid ? url : 'assets/img/avatars/noavatar.png';
    })
  );

  componentSub: Subscription = new Subscription();
  activeLanguage = 'en';

  nationalFlagsMap = {
    en: 'uk-flag',
    bs: 'bs-flag',
    ar: 'ar-flag',
    es: 'es-flag',
    it: 'it-flag',
    de: 'de-flag',
    fr: 'fr-flag',
    pt: 'pt-flag',
    ko: 'sk-flag',
    'ja-JP': 'ja-jp-flag',
    'fi-FI': 'fi-fi-flag',
    'en-US': 'en-us-flag',
    'zh-CN': 'zh-cn-flag',
  }

  constructor(
    private store: Store<AppState>,
    public authService: AuthService,
    public themeManager: ThemeManager,
    private userPreferenceService: UserPreferencesService,
    private route: ActivatedRoute,
    public accountSettingsService: AccountSettingsService,
    public imagesService: ImagesService,
    public mixPanelService: MixPanelService,
    @Inject(LOCALE_ID) locale: string,
    private router: Router,
    private storageService: StorageService,
  ) {
    this.activeLanguage = locale;
  }

  ngOnInit() {
    if (!this.userPreferenceService.getUserPreference('theme')) {
      this.userPreferenceService.setUserPreference('theme', 'default');
    }
    this.authStateSubscription = this.store
      .select('auth')
      .subscribe((authState: AuthState) => {
        this.isSuperadmin = authState.userData && Number(authState.userData.userId) === 1;
        this.authState = authState;
        this.username = authState.userData && authState.userData.username ? authState.userData.username : '';
        this.accounts = authState && authState.accounts || [];
        this.activeAccount = authState.activeAccount;
      });
    this.themeManager.setTheme(this.userPreferenceService.getUserPreference('theme'));
  }

  ngOnDestroy() {
    this.authStateSubscription.unsubscribe();
    this.componentSub.unsubscribe();
  }

  cycleTheme() {
    const availableThemes = ['default', 'gd-test-theme'];
    const newThemeIndex = (availableThemes.indexOf(this.themeManager.getActiveTheme()) + 1) % availableThemes.length;
    const newTheme = availableThemes[newThemeIndex];
    this.userPreferenceService.setUserPreference('theme', newTheme);
    this.themeManager.setTheme(newTheme);

    this.mixPanelService.trackEvent('UserMenuItemClicked', {
      action: 'Switch Theme',
      active_theme: newTheme === 'default' ? 'Default Theme' : 'Dark Theme',
    });
  }

  // TODO refactor this to work by firing an action
  changeAccount(account) {
    const accountId = account?.id;
    if (accountId !== this.activeAccount.id) {
      this.store.dispatch(new BeforeAccountChangeAction({ accountId }))
      }
    this.mixPanelService.trackEvent('UserMenuItemClicked', {
      action: 'Switch Account',
      new_account: account.name,
      old_account: this.activeAccount.name,
    });
  }

  saveCurrentUrlPath() {
    const currentUrlPathValue = this.route.snapshot['_routerState'].url;
    this.userPreferenceService.setUserPreference('currentUrlPathValue', currentUrlPathValue);

    this.mixPanelService.trackEvent('UserMenuItemClicked', {
      action: 'My Profile',
    });
  }

  logout() {
    this.store.dispatch(new LogOutAction());
    this.userPreferenceService.setUserPreference('collections.copiedItem', '');
    this.mixPanelService.trackEvent('UserMenuItemClicked', {
      action: 'Logout',
    });
  }


  menuOpened() {
    this.mixPanelService.trackEvent('UserMenuItemClicked', {
      action: 'User Menu Expand',
    });
  }

  changeLanguage(language:string = '') {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    if(!rememberMe) {
      const userData = {
        token: this.authService.authToken,
        accounts: this.authService.accounts,
        refreshToken: this.authService.refreshToken,
        refreshTokenCreatedAt: this.authService.refreshTokenCreatedAt
      }

      this.storageService.setI18nActiveUserData(userData);
    }

    if(!language) {
      window.location.href = `${this.router.url}`;
      this.userPreferenceService.setUserPreference('i18n.defaultLocale', 'en');
      return;
    }
    window.location.href = `/${language}${this.router.url}`;
    this.userPreferenceService.setUserPreference('i18n.defaultLocale', language);
  }
}
