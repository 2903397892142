import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[gdDragAndDrop]'
})
export class DragAndDropDirective {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('gdDragAndDrop') dragAndDropMode;
  @Output() filesEmitter = new EventEmitter();

  @HostListener('dragenter', ['$event']) public onDragEnter(event) {
    this.elRef.nativeElement.classList.add('--dragging-over');
    return false;
  }

  @HostListener('dragover', ['$event']) public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    this.elRef.nativeElement.classList.remove('--dragging-over');
    return false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    this.elRef.nativeElement.classList.remove('--dragging-over');

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.emitDroppedFiles(files);
    }

    return false;
  }

  constructor(
    private elRef: ElementRef
  ) { }

  emitDroppedFiles(files) {
    this.filesEmitter.next({ files });
  }
}
