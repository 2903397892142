import { UnsafeAction } from '../unsafe-action.interface';

export const GET_SYSTEM_NOTIFICATIONS = '[System Notification] Get System Notifications';
export class GetSystemNotificationsAction implements UnsafeAction {
  readonly type = GET_SYSTEM_NOTIFICATIONS;
  constructor(public payload: any = {}, public loadingFlag: boolean = true) { }
}

export const GET_SYSTEM_NOTIFICATIONS_SUCCESS = '[System Notification] Get System Notifications success';
export class GetSystemNotificationsSuccessAction implements UnsafeAction {
  readonly type = GET_SYSTEM_NOTIFICATIONS_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_SYSTEM_NOTIFICATION = '[System Notification] Create System Notification';
export class CreateSystemNotificationAction implements UnsafeAction {
  readonly type = CREATE_SYSTEM_NOTIFICATION;
  constructor(public payload: any) { }
}

export const CREATE_SYSTEM_NOTIFICATION_SUCCESS = '[System Notification] Create System Notification success';
export class CreateSystemNotificationSuccessAction implements UnsafeAction {
  readonly type = CREATE_SYSTEM_NOTIFICATION_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_SYSTEM_NOTIFICATION = '[System Notification] Update System Notification';
export class UpdateSystemNotificationAction implements UnsafeAction {
  readonly type = UPDATE_SYSTEM_NOTIFICATION;
  constructor(public payload: any) { }
}

export const UPDATE_SYSTEM_NOTIFICATION_SUCCESS = '[System Notification] Update System Notification success';
export class UpdateSystemNotificationSuccessAction implements UnsafeAction {
  readonly type = UPDATE_SYSTEM_NOTIFICATION_SUCCESS;
  constructor(public payload: any) { }
}

export const CANCEL_SYSTEM_NOTIFICATION = '[System Notification] Cancel System Notification';
export class CancelSystemNotificationAction implements UnsafeAction {
  readonly type = CANCEL_SYSTEM_NOTIFICATION;
  constructor(public payload: number) { }
}

export const CANCEL_SYSTEM_NOTIFICATION_SUCCESS = '[System Notification] Cancel System Notification success';
export class CancelSystemNotificationSuccessAction implements UnsafeAction {
  readonly type = CANCEL_SYSTEM_NOTIFICATION_SUCCESS;
  constructor(public payload: number) { }
}

export const DELETE_SYSTEM_NOTIFICATION = '[System Notification] Delete System Notification';
export class DeleteSystemNotificationAction implements UnsafeAction {
  readonly type = DELETE_SYSTEM_NOTIFICATION;
  constructor(public payload: number) { }
}

export const DELETE_SYSTEM_NOTIFICATION_SUCCESS = '[System Notification] Delete System Notification success';
export class DeleteSystemNotificationSuccessAction implements UnsafeAction {
  readonly type = DELETE_SYSTEM_NOTIFICATION_SUCCESS;
  constructor(public payload: number) { }
}

export const SET_SYSTEM_NOTIFICATION_PAGE_VIEW_OPTIONS = '[System Notification] Set page view options';
export class SetSystemNotificationsPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_SYSTEM_NOTIFICATION_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const SET_SYSTEM_NOTIFICATION_LOADING_FLAG = '[System Notification] Set System Notification loading flag';
export class SetSystemNotificationLoadingFlagAction implements UnsafeAction {
  readonly type = SET_SYSTEM_NOTIFICATION_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const SYSTEM_NOTIFICATION_ACTION_FAILED = '[System Notification] Generic failure action';
export class SystemNotificationFailedAction implements UnsafeAction {
  readonly type = SYSTEM_NOTIFICATION_ACTION_FAILED;
  constructor(public payload: any) { }
}
