import { Component, OnInit, Inject, OnDestroy, Input, ViewChild, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GettyIntegrationService } from '../../core/api/image-integrations/getty-integration.service';
import { ImageIntegrationService } from '../../core/api/image-integrations/image-integration.service.interface';
import { combineLatest, Subscription, startWith, debounceTime, filter, mergeMap, tap } from 'rxjs';
import { ImagesUploadService } from '../../core/api/images/images-upload.service';
import { HttpClient } from '@angular/common/http';
import { ModalsService } from '../modals/modals.service';
import { MixPanelService } from '../../core/api/mixpanel/mixpanel.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';

declare const $: any;

enum Provider {
  Getty = 'getty',
}
interface Config {
  title: string;
  filters: any[];
  importBtnLabel: string;
  importConfirmationMessageSingle: string;
  importConfirmationMessageMultiple: string;
}

@Component({
  selector: 'gd-external-image-library-widget',
  templateUrl: './external-image-library-widget.component.html',
  styleUrls: ['./external-image-library-widget.component.scss'],
})
export class ExternalImageLibraryWidgetComponent implements OnInit, OnDestroy {
  @Input() provider: string = Provider.Getty;
  integrationService: ImageIntegrationService;

  filteredImages = [];
  selectedImages = [];
  previewImage = null;
  multiUpload = this.dialogInputData.multiUpload;
  config: Config = { title: '', filters: [], importBtnLabel: '', importConfirmationMessageMultiple: '', importConfirmationMessageSingle: '' };

  filtersForm = this.fb.group({});
  componentSubs: Subscription = new Subscription();

  paginationOptions = { pageNumber: 0, pageSize: 30, total: 0 };
  importing = false;

  @ViewChild('images_grid', { static: true }) imagesGridEl;
  @ViewChild('filters', { static: true }) filtersBlockEl;

  get primaryFilters() {
    return this.config.filters.filter((item) => item.primary);
  }

  get secondaryFilters() {
    return this.config.filters.filter((item) => !item.primary);
  }

  get loading() {
    return this.integrationService.loading;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private store: Store<AppState>,
    private injector: Injector,
    private dialogRef: MatDialogRef<ExternalImageLibraryWidgetComponent>,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private imageUploadService: ImagesUploadService,
    private http: HttpClient,
    private modalsService: ModalsService,
    private mixPanelService: MixPanelService
  ) {}

  ngOnInit(): void {
    this.mixPanelService.trackEvent('ExternalImageLibraryWidgetOpened', {
      provider: this.provider,
    });
    this.integrationService = this.getIntegrationService();
    if (!this.integrationService) {
      this.snackBar.open($localize`Invalid service provider!`, $localize`Close`, { duration: 3000 });
      return;
    }
    this.initializeWidget();
  }
  setMetaDataMaxHeight() {
    const previewBlockHeight = $('.gd-image-library__col-preview-block-wrapper')[0].offsetHeight;
    const wrapperHeight = $('.gd-image-library__col-preview')[0].offsetHeight;
    $('.gd-image-library__meta-wrapper').css( "height", `${wrapperHeight - previewBlockHeight - 50}px`);
  }

  setImagesGridMaxHeight(previousHeight = null) {
    const height = this.filtersBlockEl.nativeElement.offsetHeight;
    if (height === previousHeight) {
      return;
    }
    if (previousHeight) {
      const transitionTime = previousHeight < height ? 0.05 : 0.3;
      this.imagesGridEl.nativeElement.style.transition = `max-height ${transitionTime}s`;
    }
    // 120px is height of widget title together with some margins
    this.imagesGridEl.nativeElement.style.maxHeight = `calc(100vh - ${height}px - 120px)`;
    setTimeout(() => this.setImagesGridMaxHeight(height), 70);
  }

  getIntegrationService() {
    switch (this.provider) {
      case Provider.Getty:
        return this.injector.get(GettyIntegrationService);
      default:
        return null;
    }
  }

  initializeWidget() {
    this.config = this.integrationService.getConfig();
    this.config.filters.forEach((item) => this.filtersForm.addControl(item.key, new UntypedFormControl()));

    const observers = this.config.filters.map((item) => {
      const delay = item.type === 'text' ? 200 : 0;
      return this.getFilterControl(item.key).valueChanges.pipe(
        startWith(null),
        debounceTime(delay)
      );
    });

    this.componentSubs.add(
      combineLatest(observers).subscribe(() => {
        this.filteredImages = [];
        this.paginationOptions.pageNumber = 0;
        this.getImages();
      })
    );

    this.componentSubs.add(
      this.integrationService
        .getIsConfiguredObservable()
        .pipe(filter((val) => !val))
        .subscribe(() => this.dialogRef.close())
    );
    setTimeout(() => this.setImagesGridMaxHeight(), 300);
  }

  getImages() {
    const params = { ...this.filtersForm.value, ...this.paginationOptions };
    this.integrationService.filter(params).subscribe((data) => {
      this.filteredImages = [...this.filteredImages, ...data.images];
      this.paginationOptions.total = data.total;
    });
  }

  isImageSelected(id) {
    return !!this.selectedImages.find((img) => img.id === id);
  }

  preview(image) {
    this.extractMetaDetails(image);
    this.previewImage = image;
  }

  addImage(image) {
    this.extractMetaDetails(image);
    if (this.multiUpload) {
      this.selectedImages.push(image);
    } else {
      this.selectedImages = [image];
    }
  }

  removeImage(image) {
    this.selectedImages = this.selectedImages.filter((img) => img.id !== image.id);
  }

  getFilterControl(key) {
    return this.filtersForm.get(key) as UntypedFormControl;
  }

  loadMore() {
    this.paginationOptions.pageNumber++;
    this.getImages();
  }

  canLoadMore() {
    const { pageNumber, pageSize, total } = this.paginationOptions;
    const allImagesNotLoaded = total - pageNumber * pageSize > pageSize;
    return !this.loading && allImagesNotLoaded;
  }

  close() {
    if (this.selectedImages.length === 0) {
      this.dialogRef.close();
      return;
    }
    const message = $localize`Are you sure you want to navigate away`;

    this.modalsService
      .confirm($localize`Leave Getty Image Importer`, message)
      .pipe(filter((r) => !!r))
      .subscribe(() => this.dialogRef.close());
  }

  import() {
    this.modalsService
      .confirm(this.config.importBtnLabel, this.multiUpload ? this.config.importConfirmationMessageMultiple : this.config.importConfirmationMessageSingle)
      .pipe(
        filter((r) => !!r),
        tap(() => (this.importing = true)),
        mergeMap(() => this.integrationService.import(this.selectedImages))
      )
      .subscribe((images) => {
        this.dialogRef.close(images);
      });
  }

  clear() {
    let message = $localize`Are you sure you want to discard ${this.selectedImages.length} selected images?`;

    if(this.selectedImages.length === 0){
      message = $localize`Are you sure you want to discard the selected image?`;
    }

    this.modalsService
      .confirm($localize`Discard selected images`, message)
      .pipe(filter((r) => !!r))
      .subscribe(() => (this.selectedImages = []));
  }

  extractMetaDetails(image) {
    if (image.meta) {
      return;
    }
    this.http.get(image.thumbnail, { responseType: 'blob' }).subscribe(async (blob) => {
      const file = new File([blob], 'thumbnail', { type: blob.type });
      const iptc: any = await this.imageUploadService.extractImageMetadata({ fileData: file });

      // this part is implemented in a way to match the existing process of extraction metadata from images
      // position property is added only for the purpose of sorting
      let meta = iptc.reduce((acc, { key, value }) => {
        if (['byline', 'byline title'].includes(key)) {
          acc.push({ key: 'credit', value: [value], position: 4 });
        }
        if (['caption', 'caption writer', 'description', 'caption abstract'].includes(key)) {
          acc.push({ key: 'caption', value, position: 1 });
        }
        if (key === 'headline') {
          acc.push({ key: 'headline', value, position: 2 });
        }
        if (['description', 'caption writer', 'caption', 'caption abstract'].includes(key)) {
          acc.push({ key: 'description', value, position: 3 });
        }
        return acc;
      }, []);

      if (image.title && !meta.find((item) => item.key === 'headline')) {
        meta.push({ key: 'headline', value: image.title, position: 2 });
      }

      image.meta = meta
        .sort((a, b) => a.position - b.position)
        .map(({ key, value }) => ({ key, value }));
    });
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
    this.integrationService.configured$.next(true);
  }
}
