import { UnsafeAction } from '../unsafe-action.interface';
import { HtmlSnippet } from './html-snippets.model';

export const GET_HTML_SNIPPETS = '[HTML Snippets] Get HTML Snippets';
export class GetHtmlSnippetsAction implements UnsafeAction {
  readonly type = GET_HTML_SNIPPETS;
  constructor() { }
}

export const GET_HTML_SNIPPETS_SUCCESS = '[HTML Snippets] Get HTML Snippets success';
export class GetHtmlSnippetsSuccessAction implements UnsafeAction {
  readonly type = GET_HTML_SNIPPETS_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_HTML_SNIPPET = '[HTML Snippet] Get HTML Snippet';
export class GetHtmlSnippetAction implements UnsafeAction {
  readonly type = GET_HTML_SNIPPET;
  constructor(public payload: any) { }
}

export const GET_HTML_SNIPPET_SUCCESS = '[HTML Snippet] Get HTML Snippet success';
export class GetHtmlSnippetSuccessAction implements UnsafeAction {
  readonly type = GET_HTML_SNIPPET_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_HTML_SNIPPET = '[HTML Snippet] Create HTML Snippet';
export class CreateHtmlSnippetAction implements UnsafeAction {
  readonly type = CREATE_HTML_SNIPPET;
  constructor(public payload: HtmlSnippet) { }
}

export const CREATE_HTML_SNIPPET_SUCCESS = '[HTML Snippet] Create HTML Snippet success';
export class CreateHtmlSnippetSuccessAction implements UnsafeAction {
  readonly type = CREATE_HTML_SNIPPET_SUCCESS;
  constructor(public payload: HtmlSnippet) { }
}

export const UPDATE_HTML_SNIPPET = '[HTML Snippet] Update HTML Snippet';
export class UpdateHtmlSnippetAction implements UnsafeAction {
  readonly type = UPDATE_HTML_SNIPPET;
  constructor(public payload: HtmlSnippet) { }
}

export const UPDATE_HTML_SNIPPET_SUCCESS = '[HTML Snippet] Update HTML Snippet success';
export class UpdateHtmlSnippetSuccessAction implements UnsafeAction {
  readonly type = UPDATE_HTML_SNIPPET_SUCCESS;
  constructor(public payload: HtmlSnippet) { }
}

export const DELETE_HTML_SNIPPET = '[HTML Snippet] Delete HTML Snippet';
export class DeleteHtmlSnippetAction implements UnsafeAction {
  readonly type = DELETE_HTML_SNIPPET;
  constructor(public payload: number) { }
}

export const DELETE_HTML_SNIPPET_SUCCESS = '[HTML Snippet] Delete HTML Snippet success';
export class DeleteHtmlSnippetSuccessAction implements UnsafeAction {
  readonly type = DELETE_HTML_SNIPPET_SUCCESS;
  constructor(public payload: number) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[HTML Snippet] Set page view options';
export class SetPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const SET_HTML_SNIPPETS_FILTER = '[HTML Snippet] Set HTML Snippets filter';
export class SetHtmlSnippetsFilterAction implements UnsafeAction {
  readonly type = SET_HTML_SNIPPETS_FILTER;
  constructor(public payload: any) { }
}

// action will set active html snippet if exist in store
export const SET_ACTIVE_HTML_SNIPPET = '[HTML Snippet] Set active HTML Snippet';
export class SetActiveHtmlSnippetAction implements UnsafeAction {
  readonly type = SET_ACTIVE_HTML_SNIPPET;
  constructor(public payload: any) { }
}

export const HTML_SNIPPET_ACTION_FAILED = '[HTML Snippet] Generic failure action';
export class HtmlSnippetFailedAction implements UnsafeAction {
  readonly type = HTML_SNIPPET_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const HTML_SNIPPET_NOT_FOUND = '[HTML Snippet] Generic failure action';
export class HtmlSnippetNotFoundAction implements UnsafeAction {
  readonly type = HTML_SNIPPET_NOT_FOUND;
  constructor(public payload: any) { }
}
