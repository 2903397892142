<!-- Dialog Content -->
<mat-dialog-content class="gd-embed-media-dialog__content"
                    [dir]="dir$ | async">
  <mat-progress-bar class="gd-embed-media-dialog__progress-bar"
                    *ngIf="dialogOptions.dialogContent === 'galleries'"
                    [style.opacity]="(loading$ | async) ? '1' : '0'"
                    mode="indeterminate"
                    value="40"></mat-progress-bar>

  <!-- Tabbed Layout -->
  <mat-tab-group class="gd-embed-media-tabs"
                 [(selectedIndex)]="selectedIndex"
                 (selectedTabChange)="handleTabChanged($event)"
                 [class.gd-embed-media-tabs--navigation-hidden]="croppingInProgress()">
    <!-- Library Tab -->
    <mat-tab label="Library" i18n-label="Image library tab label in embed media dialog">
      <!-- Search Block -->
      <div fxLayout="row"
           [fxLayoutAlign]="dialogOptions.dialogContent === 'galleries' ? 'space-between center' : 'space-between end'"
           fxLayoutGap="15px"
           class="gd-embed-media__search">
        <div class="gd-embed-media__title-filter-wrapper"
             [class.--content-images]="dialogOptions.dialogContent === 'images'"
             [class.--content-galleries]="dialogOptions.dialogContent === 'galleries'">
            <mat-form-field class="gd-embed-media-filter-field">
              <mat-label *ngIf="dialogOptions.dialogContent === 'images'" i18n="Image embed search parameters">Filename, caption, headline or credit</mat-label>
              <mat-label *ngIf="dialogOptions.dialogContent === 'galleries'" i18n>Search galleries by title...</mat-label>
              <input matInput
                autocomplete="off"
                [formControl]="searchFormControl"
                [value]="searchFormControl.value"
                (keyup.enter)="(dialogOptions.dialogContent !== 'images' || !searchFormControl.value.trim() || searchFormControl.value.trim().length > 2) && searchResource()">
              <mat-hint *ngIf="dialogOptions.dialogContent === 'images'" class="gd-embed-media-filter-field-hint" i18n>Search image (min. 3 characters)</mat-hint>
            </mat-form-field>
            <gd-localizations-list appearance="standard"
                                   usage="ARTICLE_LIST"
                                   [allowedContentLocales]="contentLocaleIds"
                                   [activeLocaleId]="contentLocaleId"
                                   (localeChange)="handleLocaleChange($event)"
                                   style="margin-top: -1.5px"
                                   *ngIf="dialogOptions.dialogContent === 'galleries' && multipleLocalesExist">
            </gd-localizations-list>
        </div>
        <div fxFlex="25" *ngIf="dialogOptions.dialogContent === 'images' && hasReadTagPermission">
            <div fxLayout="column" style="position: relative;">
              <mat-form-field class="gd-embed-media-filter-field">
                <mat-label i18n>Tags</mat-label>
                <input
                  matInput
                  placeholder="Tags"
                  i18n-placeholder
                  [formControl]="tagsFilterControl"
                  (blur)="tagsFilterControl.setValue('')"
                  [matAutocomplete]="tagsFilter"
                  #tagsFilterId>

                <mat-autocomplete autoActiveFirstOption class="gd-embed-media-filter-field__autocomplete"
                      #tagsFilter="matAutocomplete"
                      (optionSelected)="selectItem(tagsFilterControl, tagsFormControl, $event.option.value)">
                  <mat-option class="gd-embed-media-filter-field__autocomplete-options" *ngFor="let tag of filteredTags$ | async"
                      [value]="tag">
                      <span>{{ tag.name }}</span>
                      <div class="gd-embed-media-filter-tag-indicators">
                        <span class="gd-embed-media-filter-tag-indicator__circle"
                              *ngFor="let tagVersion of tag.versions || [tag]"
                              [ngClass]="'--' + tagVersion.group"></span>
                      </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <gd-expandable-chip-list #expandableListComponent
                [chipFormControl]="tagsFormControl"
                [type]="ExpandableChipTypes.TAG"
                [blueLineThreshold]="blueLineThreshold"
                (removeItem)="removeItem(tagsFormControl, $event)"></gd-expandable-chip-list>
            </div>
        </div>
        <div fxFlex="25" *ngIf="dialogOptions.dialogContent === 'images'" style="position: relative;">
          <div *ngIf="uploadedAtControl.get('lte').value" class="gd-embed-media-filter-field__clear-upload-date" (click)="clearDateRange()">
            <mat-icon fontSet="fal" fontIcon="fa-times-circle" class="clear" matTooltip="Clear dates" i18n-matTooltip matTooltipPosition="above"></mat-icon>
          </div>
          <mat-form-field appearance="fill" class="gd-embed-media-filter-field gd-embed-media-filter-field__upload-date" (click)="picker.open()">
            <mat-label i18n>Upload date</mat-label>
            <mat-date-range-input [formGroup]="uploadedAtControl" [rangePicker]="picker">
              <input matStartDate formControlName="gte" placeholder="Start date" i18n-placeholder readonly >
              <input matEndDate (dateChange)="searchResource()" formControlName="lte" placeholder="End date" i18n-placeholder readonly >
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel (click)="clearDateRange()" i18n>Clear dates</button>
                <button mat-raised-button color="primary" (click)="applyDateRange()"matDateRangePickerApply i18n>Apply</button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>

            <mat-error *ngIf="uploadedAtControl.controls.gte.hasError('matStartDateInvalid')" i18n>Invalid start date</mat-error>
            <mat-error *ngIf="uploadedAtControl.controls.lte.hasError('matEndDateInvalid')" i18n>Invalid end date</mat-error>
            <mat-hint class="gd-embed-media-filter-field-hint" i18n>Enter a date range</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="25" *ngIf="dialogOptions.dialogContent === 'images'">
          <div fxLayout="column" style="position: relative;">
              <mat-form-field class="gd-embed-media-filter-field">
                <mat-label i18n>Uploaded by</mat-label>
                <input
                  placeholder="Uploaded by"
                  i18n-placeholder
                  matInput
                  [formControl]="usersFilterControl"
                  (blur)="usersFilterControl.setValue('')"
                  [matAutocomplete]="usersFilter"
                  #usersFilterId>

                <mat-autocomplete autoActiveFirstOption class="gd-embed-media-filter-field__autocomplete"
                      #usersFilter="matAutocomplete"
                      (optionSelected)="selectItem(usersFilterControl, usersFormControl, $event.option.value)">
                  <mat-option class="gd-embed-media-filter-field__autocomplete-options" *ngFor="let user of filteredUsers$ | async"
                      [value]="user">
                      {{ user.firstName + ' ' + user.lastName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <gd-expandable-chip-list #expandableListComponent
                [chipFormControl]="usersFormControl"
                [type]="ExpandableChipTypes.USER"
                [blueLineThreshold]="blueLineThreshold"
                (removeItem)="removeItem(usersFormControl, $event)"></gd-expandable-chip-list>
          </div>
        </div>

        <button mat-button
                mat-icon-button
                [disabled]="!itemsFiltered && !galleries.filtered"
                matTooltip="Clear filters"
                matTooltipPosition="below"
                class="gd-embed-media__search--clear"
                (click)="clearResourceFilter()">
            <mat-icon fontSet="fal" fontIcon="fa-stream"></mat-icon>
        </button>
      </div>

      <div [style.margin-top]="tagsFormControl.value.length || usersFormControl.value.length ? '45px' : '0px'"> </div>

      <!-- Tab Label -->
      <ng-template mat-tab-label>
        <mat-icon svgIcon="glideCustomIcon_galleries"></mat-icon>
        <span class="gd-embed-media-tabs__tab-label" i18n="Image library tab label in embed media dialog">Library</span>
      </ng-template>

      <div class="gd-embed-media-tabs__content-wrapper" style="margin-top: 50px;">
        <gd-scroller #scrollbar>
          <gd-images-view-grid #photosGrid
                               [collection]="items"
                               [selectedImages]="selectedItems"
                               [loading]="loading$"
                               [options]="gridOptions"
                               (selectedImagesDispatcher)="handleImagesSelectedEvent($event)">
          </gd-images-view-grid>
          <gd-table-no-results [isTable]="true"
                               *ngIf="items?.length===0 && (loading$ | async) !== true && (itemsFiltered || galleries.filtered)">
           </gd-table-no-results>
        </gd-scroller>
      </div>
    </mat-tab>

    <!-- Upload Tab -->
    <mat-tab label="Upload"
             [disabled]="!hasUploadImagePermission">
      <!-- Tab Label -->
      <ng-template mat-tab-label>
        <mat-icon fontSet="fal" fontIcon="fa-cloud-upload"></mat-icon>
        <span class="gd-embed-media-tabs__tab-label" i18n>Upload</span>
      </ng-template>

      <div class="gd-embed-media-tabs__upload-tab-content"
           [style.padding]="croppingInProgress() ? '16px' : '0 16px'"
           [style.height]="croppingInProgress() ? 'auto' : (modalLayout.contentHeight + 'px')"
           *ngIf="activeTab === 'Upload'">
        <button mat-button
                mat-raised-button
                i18n
                *ngIf="uploadConfig.viewAs === 'contained' && uploadConfig.multiUpload && activeToggleMenuItem === 'details' && !croppingInProgress()"
                (click)="handleToggleMenuEvent({ value: 'preview'})"
                class="gd-embed-media-dialog__btn-back">
          Back
        </button>

        <!-- Show Preview for queued image -->
        <div class="gd-embed-media__image-preview">
          <gd-image-upload #imageUpload
                           [uploadConfig]="uploadConfig"
                           (imagesProcessed)="handleImagesProcessed($event)"
                           (openImageUploadForm)="handleOpenImageMetaForm($event)">
          </gd-image-upload>
        </div>

        <!-- Show details form and IPTC metadata for selected image -->
        <div [@showMetaCard]="activeToggleMenuItem"
             [@.disabled]="!uploadConfig.multiUpload"
             *ngIf="hasEnqueuedImages && enqueuedImage && enqueuedImage.dataURL && activeToggleMenuItem === 'details'"
             class="gd-embed-media__image-details"
             [style.display]="croppingInProgress() ? 'none' : 'flex'">
          <gd-upload-image-meta-form #imageMetaForm
              [imageData]="enqueuedImage"
              [usage]="dialogOptions.loadSingleImage ? 'article' : 'image'"
              [enableTagEditing]="true"
              (cropImage)="triggerImageCropping($event)"
              style="width: 100%">
          </gd-upload-image-meta-form>
        </div>

      </div>
    </mat-tab>

    <mat-tab label="Generate Image"
             [disabled]="!hasUploadImagePermission"
             *ngIf="gaiaEnabled">
      <ng-template mat-tab-label>
        <mat-icon class="gd-images-view__icon"
                  svgIcon="glideCustomIcon_gaia_image_generation"></mat-icon>
        <span class="gd-embed-media-tabs__tab-label"
              i18n>Generate Image</span>
      </ng-template>

      <div class="gd-embed-media-tabs__upload-tab-content --generate-image"
           [style.padding]="croppingInProgress() ? '16px' : '0 16px'"
           [style.height]="croppingInProgress() ? 'auto' : (modalLayout.contentHeight + 'px')"
           *ngIf="activeTab === 'Generate'">

        <div class="gd-embed-media__image-preview">
          <gd-image-upload #imageUpload
                           [uploadConfig]="uploadConfig"
                           (imagesProcessed)="handleImagesProcessed($event)"
                           (openImageUploadForm)="handleOpenImageMetaForm($event)"
                           [generateImageTab]="true"
                           (imageGeneratedUploadEmitter)="imageGeneratedChanged($event)"
                           [usage]="'embedModal'">
          </gd-image-upload>
        </div>

        <div [@showMetaCard]="activeToggleMenuItem"
             [@.disabled]="!uploadConfig.multiUpload"
             *ngIf="hasEnqueuedImages && enqueuedImage && enqueuedImage.dataURL && activeToggleMenuItem === 'details'"
             class="gd-embed-media__image-details"
             [style.display]="croppingInProgress() ? 'none' : 'flex'"
             [style.top]="'6px'">
          <gd-upload-image-meta-form #imageMetaForm
                                     [imageData]="enqueuedImage"
                                     [usage]="dialogOptions.loadSingleImage ? 'article' : 'image'"
                                     [enableTagEditing]="true"
                                     (cropImage)="triggerImageCropping($event)"
                                     style="width: 100%">
          </gd-upload-image-meta-form>
        </div>

      </div>
    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>

<!-- Dialog Actions -->
<mat-dialog-actions fxLayout="row"
                    [dir]="dir$ | async"
                    fxLayoutAlign="end center"
                    fxLayoutGap="12px"
                    class="gd-embed-media-dialog__actions"
                    *ngIf="!croppingInProgress()">
  <div class="gd-embed-media-dialog__list-pagination">
    <mat-paginator  [pageIndex]="paginator.pageIndex"
                     [pageSize]="paginator.pageSize"
                     [length]="paginator.total"
                     [pageSizeOptions]="paginator.pageSizeOptions"
                     (page)="pageChanged($event)"
                     *ngIf="activeTab === 'Library'">
      </mat-paginator>
      <button mat-button
              mat-raised-button
              i18n
              *ngIf="activeTab ==='Generate' && activeToggleMenuItem === 'details' && !croppingInProgress()"
              (click)="removeGeneratedImageFromQueue()"
              class="gd-embed-media-dialog__btn-back">
        Back
      </button>
  </div>
  <div class="gd-embed-media-dialog__action-buttons">

  <button [disabled]="queueBeingProcessed$ | async"
          mat-flat-button
          i18n
          [mat-dialog-close]="'UPLOAD_DONE'" data-cy="gd-embed-media-close-btn">
    Cancel
  </button>

  <button [disabled]="selectedItems.length === 0"
          *ngIf="activeTab === 'Library'"
          mat-raised-button
          color="primary"
          i18n
          (click)="insertImages(selectedItems)" data-cy="gd-embed-media-select-btn"
          class="gd-embed-media-dialog__action-save-btn">
    Select
  </button>

  <button *ngIf="(activeTab === 'Upload' && activeToggleMenuItem !== 'details') ||
    !uploadConfig.multiUpload && activeToggleMenuItem === 'details' || (activeTab === 'Generate' && hasEnqueuedImages)"
          [disabled]="(!uploadConfig.multiUpload && (!hasEnqueuedImages || enqueuedImage?.invalid)) ||
          (uploadConfig.multiUpload && (!hasEnqueuedImages || enqueuedImagesInvalid)) || (queueBeingProcessed$ | async)"
          mat-raised-button
          color="primary"
          i18n
          (click)="triggerUploadImage()" data-cy="gd-embed-media-upload-btn"
          class="gd-embed-media-dialog__action-save-btn">
    {{activeTab === 'Generate' && hasEnqueuedImages ? 'Save' : 'Upload'}}
  </button>

  <button mat-raised-button
          *ngIf="activeTab === 'Generate' && !hasEnqueuedImages"
          [disabled]="!imageGenerated"
          color="accent"
          i18n
          data-cy="gd-embed-media-select-btn"
          style="margin: 0px 14px 0px 12px;"
          (click)="addToQueue()">
      Accept Image
    </button>

  <button *ngIf="uploadConfig.multiUpload && hasEnqueuedImages && activeToggleMenuItem === 'details' && activeTab !== 'Generate'"
          mat-raised-button
          color="primary"
          i18n
          (click)="saveImageMeta()" data-cy="gd-embed-media-save-btn"
          class="gd-embed-media-dialog__action-save-btn">
    Save
  </button>
  </div>
</mat-dialog-actions>
