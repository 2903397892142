import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DynamicWidgetFormComponent } from './dynamic-widget-form/dynamic-widget-form.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { FieldArrayBuilderComponent } from './field-array-builder/field-array-builder.component';
import { MediaWidgetConfigurationComponent } from './field-builder/media-widget-configuration/media-widget-configuration.component';
import { WidgetConfigurationDataService } from './widget-configuration.service';
import { DataSourceFactoryService } from './field-builder/field-builder-data-factory.service';
import { AutocompleteFieldConfigurationComponent } from './field-builder/autocomplete-field-configuration/autocomplete-field-configuration.component';
import { SelectFieldConfigurationComponent } from './field-builder/select-field-configuration/select-field-configuration.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from '../../shared/material-module.module';
import { LabelValueListModule } from '../../gpp-shared/label-value-list/label-value-list.module';
import { RadioButtonConfigurationComponent } from './field-builder/radio-button-configuration/radio-button-configuration.component';
import { CheckboxConfigurationComponent } from './field-builder/checkbox-configuration/checkbox-configuration.component';
import { ColorPickerConfigurationComponent } from './field-builder/color-picker-configuration/color-picker-configuration.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TextValueListModule } from '../text-value-list/text-value-list.module';
import { RichTextEditorModule } from '../rich-text-editor/rich-text-editor.module';
import { FieldGroupBuilderComponent } from './field-group-builder/field-group-builder.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EllipsisLabelTooltipModule } from '../utility-components/ellipsis-label-tooltip/ellipsis-label-tooltip.module';
import { CustomDataPanelComponent } from './custom-data-panel/custom-data-panel.component';

@NgModule({
  declarations: [
    DynamicWidgetFormComponent,
    FieldBuilderComponent,
    FieldArrayBuilderComponent,
    MediaWidgetConfigurationComponent,
    AutocompleteFieldConfigurationComponent,
    SelectFieldConfigurationComponent,
    RadioButtonConfigurationComponent,
    CheckboxConfigurationComponent,
    ColorPickerConfigurationComponent,
    CustomDataPanelComponent,
    FieldGroupBuilderComponent,
  ],
  exports: [
    DynamicWidgetFormComponent,
    FieldBuilderComponent,
    FieldGroupBuilderComponent,
    FieldArrayBuilderComponent,
    CustomDataPanelComponent,
    MediaWidgetConfigurationComponent,
    AutocompleteFieldConfigurationComponent,
  ],
  providers: [WidgetConfigurationDataService, DataSourceFactoryService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    // TODO extract exact requirements from shared material module
    MaterialModule,
    DragDropModule,
    LabelValueListModule,
    ColorPickerModule,
    TextValueListModule,
    RichTextEditorModule,
    EllipsisLabelTooltipModule,
  ],
})
export class WidgetConfigurationModule {}
