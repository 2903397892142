import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  ViewChildren,
  QueryList
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { SetSelectedImageAction } from '../../core/store/images/images.actions';

declare const $;

@Component({
  selector: 'gd-scrolling-list',
  templateUrl: './scrolling-list.component.html',
  styleUrls: ['./scrolling-list.component.scss']
})
export class ScrollingListComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() collection = [];
  @Input() previewedImage;

  containerWidth;
  contentWrapper: any = {};
  showNavigationArrrows = true;

  scrollInterval;

  @ViewChild('scroller', { static: true }) scroller;
  @ViewChildren('collectionItems') collectionItems: QueryList<any>;

  thumbnailsConfig = {
    width: 150,
    height: 105,
    gap: 9
  };

  constructor(
    private store: Store<AppState>,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    const extraWidth = ((this.collection.length - 1) * 4) * 1.85;
    this.contentWrapper = {
      height: this.thumbnailsConfig.height + 24,
      width: (this.collection.length * (this.thumbnailsConfig.width)) + extraWidth
    };
  }

  ngAfterViewInit() {
    const parentContainer = this.elRef.nativeElement.getBoundingClientRect();
    this.contentWrapper['maxItemsInViewport'] = Math.floor(parentContainer.width / (this.thumbnailsConfig.width + 8));

    this.scroller.updateScrollingAreaWidth(this.contentWrapper.width);
  }

  ngOnChanges(changes: SimpleChanges) {
    clearInterval(this.scrollInterval);

    this.scrollInterval = setTimeout(() => {
      if (changes.previewedImage && this.collectionItems) {
        const elementToFocus = this.collectionItems.find(item => {
          const itemID = item.nativeElement.getAttribute('id');
          return itemID === this.previewedImage.id;
        });

        const imgInViewport = this.isInViewport(elementToFocus.nativeElement).inViewport;
        if (!imgInViewport) {
          this.scroller.scrollToElement(elementToFocus.nativeElement);
        }
      }
    }, 100);
  }

  previewGalleryImage(image) {
    this.store.dispatch(new SetSelectedImageAction(image));
  }

  handleArrowsVisibility(direction = 'false', visibility = 'none') {
    const arrow = this.elRef.nativeElement.querySelector(`.gd-scrolling-list__navigation-arrow.${direction}`);
    arrow.style.display = visibility;
  }

  isInViewport = function (elem) {
    const bounding = elem.getBoundingClientRect();
    const left = bounding.left >= 0;
    const right = bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
    return {
      left,
      right,
      inViewport: left && right
    };
  };
}
