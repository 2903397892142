import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppState } from '../../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { generateShortId } from '../../../shared/shared-functions';
import { getContentPanels } from '../../../core/store/content-panels/content-panels.reducer';
import { GetContentPanelsAction } from '../../../core/store/content-panels/content-panels.actions';
import { EmbeddedType } from '../../../core/store/article/article-content.model';
import { ContentPanelsService } from '../../../core/api/content-panels/content-panels.service';
import { CustomFormConstructorService } from '../../../gpp-shared/widget-configuration/form-constructor/custom-form-constructor.service';
import { GlideContentActionBusService } from '../../../core/body-content/glide-content-actions-bus.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { BidiService } from '../../../core/i18n/bidi.service';
import { addUILocale } from '../../../gpp-shared/i18n-utilities/i18n-utilities';

@Component({
  selector: 'gd-embed-content-panel-dialog',
  templateUrl: './embed-content-panel-dialog.component.html',
  styleUrls: ['./embed-content-panel-dialog.component.scss']
})
export class EmbedContentPanelDialogComponent implements OnInit {

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    title: [''],
    colour: [''],
    identifier: [''],
  });

  get configurationControl() {
    return <UntypedFormControl>this.embeddableForm.get('configuration');
  }

  contentPanels$ = this.store.select(getContentPanels);
  customFieldsConfig;
  loadDefaultValues = false;
  initialValues = null;

  get colourControl() {
    return <UntypedFormControl>this.embeddableForm.get('colour');
  }

  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedContentPanelDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private actionBus: GlideContentActionBusService,
    private contentPanelService: ContentPanelsService,
    private fc: CustomFormConstructorService,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.store.dispatch(new GetContentPanelsAction());
    this.embeddableForm.patchValue(this.dialogInputData);
    if (!this.dialogInputData.id) {
      return this.loadDefaultValues = true;
    }
    this.contentPanelService.getContentPanelById(this.dialogInputData.id)
      .subscribe(contentPanel => this.prepareConfigurationField(contentPanel));
  }

  selectContentPanel(contentPanel) {
    this.loadDefaultValues = true;
    this.embeddableForm.patchValue({ ...contentPanel, title: contentPanel.name });
    this.prepareConfigurationField(contentPanel);
  }

  prepareContentPanelHTML(columns, id) {
    const { title, colour, configuration } = this.embeddableForm.value;
    let html = `<section id="${id}" data-type="${EmbeddedType.ContentPanel}" data-columns="${columns}" class="gc-row gd-cp-section">`;
    // this is the id of the paragraph in the first content panel column
    // focus will be set to it after inserting the panel
    let firstPanelContentElId = null;
    // header (actions)
    html += `
      <div class="gc-col-12 gd-cp-header" contenteditable="false" style="background: ${colour}">
        <span style="display: flex; width: calc(100% - 120px)">
          <span class="gd-cp-section__title-label-span">${title || ' '}</span>
          <span class="gd-cp-section__title-label-span">${configuration.label ? ' - ' + configuration.label : ' '}</span>
        </span>
        <span>
          <i class="fa fa-cut" aria-hidden="true"></i>
          <i class="fa fa-copy" aria-hidden="true"></i>
          <i class="fa fa-cog" aria-hidden="true"></i>
          <i class="fa fa-trash" aria-hidden="true"></i>
        </span>
      </div>`;
    // columns
    for (let i = 1; i <= columns; i++) {
      const pId = generateShortId();
      if (i === 1) {
        firstPanelContentElId = pId;
      }
      // eslint-disable-next-line max-len
      html += `<div class="gc-col-${12 / columns} gd-cp-column" id="${generateShortId()}"><div contenteditable="false" class="gd-helper-element"></div><p id="${pId}"></p><div contenteditable="false" class="gd-helper-element"></div></div>`;
    }
    html += `</section>`;
    return { html, firstPanelContentElId };
  }

  save() {
    const blockId = this.dialogInputData.editingMode ? this.dialogInputData.blockId : generateShortId();
    const contentNodeData = {
      type: this.dialogInputData.embedType,
      blockId,
      updateData: this.embeddableForm.value,
      html: '',
      focusedElementId: null
    };
    if (this.dialogInputData.editingMode) {
      return this.dialogRef.close({ payload: contentNodeData });
    }
    const { html, firstPanelContentElId } = this.prepareContentPanelHTML(this.dialogInputData.columns, blockId);
    contentNodeData.html = html;
    contentNodeData.focusedElementId = firstPanelContentElId;
    this.dialogRef.close({ payload: contentNodeData });
  }

  setFocus(editor, elementId) {
    const anchor = document.getElementById(elementId);
    editor.events.focus();
    editor.selection.setAtStart(anchor);
    editor.selection.restore();
  }

  prepareConfigurationField(contentPanel) {
    if (!contentPanel) {
      return;
    }
    this.customFieldsConfig = contentPanel.customDataConfiguration && contentPanel.customDataConfiguration.metaData || [];
    this.customFieldsConfig = this.customFieldsConfig.length ? this.customFieldsConfig : null;
    const initialValues = this.loadDefaultValues ? null : (this.dialogInputData.configuration || {});
    // TODO refactor - move responsibility to DynamicWidgetFormComponent
    this.initialValues = initialValues;
    const customDataForm = this.fc.createFormGroup(this.customFieldsConfig, initialValues, { editMode: !!initialValues });
    this.embeddableForm.removeControl('configuration');
    this.embeddableForm.addControl('configuration', customDataForm);
  }

  clipboardCopyId(id) {
    this.clipboard.copy(id);
    this.snackbar.open($localize`Content Panel block ID copied to clipboard.`, $localize`Close`, { duration: 2000 });
  }

  openInNewTab(id) {
    const url = '/site-builder/content-panels/' + id;
    window.open(addUILocale(url), '_blank');
  }

  getSaveButtonTooltipMessage() {
    if (!this.embeddableForm.get('id').value) {
      return '';
    }
    if (this.isCustomDataTaxonomyAllowed()) {
      return $localize`Some of the used Taxonomies on custom fields are invalid.`;
    }
    return $localize`Content panel custom data form is invalid`
  }

  isCustomDataTaxonomyAllowed() {
    if (!this.configurationControl) {
      return false;
    }
    return Object.values(this.configurationControl['controls'])
      .some((control: UntypedFormControl) => control.hasError("taxonomyErr"))
  }

}
