<div class="gd-color-picker-form-group">
  <div fxLayout="row"
       class="gd-color-picker-wrapper"
       [class.--read-only-simple-input]="readOnlyMode">
    <mat-form-field appearance="outline"
                    [floatLabel]="readOnlyMode ? 'always' : 'auto'"
                    class="gd-color-picker-form">
      <mat-label>{{ fieldConfig?.label }}</mat-label>
      <input matInput
             [formControl]="fieldControl"
             (change)="updateColor(this.fieldControl.value)"
             [value]="fieldControl.value"
             [readonly]="readOnlyMode">
      <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                style="color: #8e8e8e; font-size: 24px"
                class="question-icon"
                [matTooltip]="infoTooltip"
                matTooltipPosition="above"
                matSuffix
                fontSet="fal"
                fontIcon="fa-question-circle">
      </mat-icon>
      <mat-error>

        <span *ngIf="fieldControl.hasError('required')">{{ fieldConfig?.label }} is required. </span>
        <span *ngIf="fieldControl.hasError('pattern')">Invalid hex color code</span>
      </mat-error>
    </mat-form-field>

    <div class="gd-color-picker mat-elevation-z4"
         [style.background]="fieldControl.value"
         [class.--disabled]="readOnlyMode"
         [cpOutputFormat]="'hex'"
         [cpAlphaChannel]="'always'"
         [colorPicker]="fieldControl.value"
         [cpPresetColors]="presetColors"
         (colorPickerCancel)="cancelColorPickerModal()"
         (colorPickerChange)="updateSelectedColor($event)"
         (colorPickerClose)="closeColorPickerModal()"
         [cpOKButton]="false"
         [cpSaveClickOutside]="true"
         [cpCancelButton]="true"
         [cpCancelButtonClass]="'gd-color-picker-button mat-raised-button mat-elevation-z2'"
         [cpPositionOffset]="'-70%'"
         [cpPosition]="direction === 'rtl' ? 'left' : 'auto'">
    </div>

  </div>
</div>
