import { Injectable } from '@angular/core';
import { RestService } from '..';

import { AccountSettingsService } from '../account-settings/accounts-settings.service';
import { mergeMap, map, retry } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { PreviewConfiguration } from './preview.model';
import { environment } from '../../../../environments/environment';

const DELIVER_PREVIEW_PATH = 'api/preview';

@Injectable({
  providedIn: 'root',
})
export class PreviewV1Service {
  constructor(private rest: RestService, private accountSettings: AccountSettingsService) {}

  generatePreview(articleDto, articleUrl = '') {
    const previewSessionId = articleDto.additionalItems.previewSessionId;
    articleDto.additionalItems.url = articleUrl;
    const parsedPayload = { ...articleDto, body: JSON.stringify(articleDto.body) };
    // making a request to composer here, as preview V1 stages the data trough composer
    return this.rest
      .put('articles/preview/' + previewSessionId, parsedPayload)
      .pipe(map((response: any) => response.data));
  }

  previewArticle(previewConfig$: Observable<PreviewConfiguration>, articleUrl = '') {
    const deliverDomain = this.getDeliverDomain();
    return previewConfig$.pipe(
      mergeMap((configData: PreviewConfiguration) => this.generatePreview(configData, articleUrl)),
      map(({ previewUrl }) => {
        const fullPreviewUrl = (deliverDomain + previewUrl).replace(
          '//api/preview',
          '/api/preview'
        );
        return { previewUrl, fullPreviewUrl };
      })
    );
  }

  checkPreviewAvailability() {
    const apiUrl = this.getApiUrl();
    if (!apiUrl) {
      return throwError($localize`Frontend app domain is not defined, preview is not available`);
    }
    return this.rest
      .get(`${this.getApiUrl()}/status`, { service: 'glidePreview' })
      .pipe(map((response: any) => response.data));
  }

  poolPreviewSessionStatus(sessionId) {
    return this.rest
      .poll(`${this.getApiUrl()}/${sessionId}/info`, 10000, {
        service: 'glidePreview',
        withCredentials: true,
      })
      .pipe(map((response: any) => response.data));
  }

  getPreviewTokenForPreviewSession(sessionId) {
    return this.rest
      .get(`${this.getApiUrl()}/${sessionId}/token`, { service: 'glidePreview' })
      .pipe(
        retry(3),
        map((response: any) => response.data.token)
      );
  }

  setPreviewTokenCookie(sessionId, tokenValue) {
    return this.rest
      .post(
        `${this.getApiUrl()}/${sessionId}/token`,
        { token: tokenValue },
        {
          service: 'glidePreview',
          withCredentials: true,
        }
      )
      .pipe(map((response: any) => response.data));
  }

  // api path returned wil not have terminal '/' - it should be added if needed
  getApiUrl() {
    const previewSettingsApiUrl = this.accountSettings.getPreviewServiceSettings().apiUrl;
    if (!previewSettingsApiUrl) {
      const deliverDomain = this.getDeliverDomain();
      if (!deliverDomain) {
        return null;
      }
      return deliverDomain + DELIVER_PREVIEW_PATH;
    }

    if (previewSettingsApiUrl.endsWith('/')) {
      return previewSettingsApiUrl.replace(/\/$/, '');
    }

    return previewSettingsApiUrl;
  }

  getDeliverDomain() {
    const previewUrlOverride = environment && (environment as any).previewUrlOverride;

    const deliverDomain: string = previewUrlOverride
      || this.accountSettings.getPreviewServiceSettings().apiUrl
      || this.accountSettings.getDeliverDomain();

    if (!deliverDomain) {
      return null;
    }

    if (deliverDomain.endsWith('/')) {
      return deliverDomain;
    }
    return deliverDomain + '/';
  }
}
