<div class="gd-radio-button-component">
  <div style="display: flex; flex-direction: row; align-items: center;">
    <label class="gd-configuration__label"
           [class.--error]="fieldControl.hasError('required') && fieldControl.touched">{{label}}{{isFormControlRequired ? ' *' : ''}}</label>

    <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
              class="gd-configuration__label-icon question-icon"
              [matTooltip]="infoTooltip"
              matTooltipPosition="above"
              matSuffix
              fontSet="fal"
              fontIcon="fa-question-circle"></mat-icon>
  </div>
  <mat-radio-group class="gd-radio-button-list gd-field-builder-element-spacing"
                   [formControl]="fieldControl">
    <mat-radio-button class="gd-radio-button-item"
                      *ngFor="let item of radioButtonOptions"
                      [value]="item.value"
                      [class.--read-only]="readOnlyMode">
      {{item.label}}
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="isFormControlRequired"
       class="gd-configuration__error-message"
       [class.--visible]="fieldControl.hasError('required') && fieldControl.touched">
    {{ fieldConfig.label }} is required.
  </div>
  <button *ngIf="fieldConfig.isOnFieldConfigurationPage" mat-raised-button
          (click)="clearRadioButtons()"
          color="accent">
          Clear</button>
</div>
