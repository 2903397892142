import { UnsafeAction } from '../unsafe-action.interface';
import * as advertsAction from './adverts.actions';
import { createSelector } from 'reselect';
import { Advert } from './adverts.model';

export interface AdvertsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  items: Advert[];
  query: { page: any };
}

const initialState: AdvertsState = {
  loaded: false,
  loading: false,
  error: null,
  query: {
    page: {
      number: 0,
      size: 10,
      total: 0
    }
  },
  items: []
};

export function advertsReducer(state: AdvertsState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case advertsAction.GET_ADVERTS: {
      return { ...state, loading: true, query: action.payload };
    }

    case advertsAction.GET_ADVERTS_SUCCESS: {
      const newQuery = Object.assign({}, state.query, action.payload.query);
      const adverts = action.payload.adverts;
      return {
        loaded: true,
        loading: false,
        error: null,
        items: adverts,
        query: newQuery
      };
    }
    case advertsAction.DELETE_ADVERTS:
    case advertsAction.UPDATE_ADVERTS:
    case advertsAction.CREATE_ADVERTS: {
      return { ...state, loading: true };
    }

    case advertsAction.CREATE_ADVERTS_SUCCESS: {
      const advert = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, advert]
      };
    }

    case advertsAction.DELETE_ADVERTS_SUCCESS: {
      const deleteId = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        items: state.items.filter(advert => advert.id !== deleteId)
      };
    }

    case advertsAction.UPDATE_ADVERTS_SUCCESS: {
      const updatedAdvert: Advert = action.payload;
      const updatedIndex = state.items.findIndex(wt => wt.id === updatedAdvert.id);
      const newItemsArray = [
        ...state.items.slice(0, updatedIndex),
        updatedAdvert,
        ...state.items.slice(updatedIndex + 1)
      ];

      return {
        ...state,
        loading: false,
        error: null,
        items: newItemsArray
      };
    }

    case advertsAction.GET_ADVERTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getAdvertsState = (state) => state.adverts;

export const getAdvertsPage = createSelector(getAdvertsState, adverts => adverts.query.page);
export const getAdvertsList = createSelector(getAdvertsState, adverts => adverts.items);
export const getAdvertsLoading = createSelector(getAdvertsState, adverts => adverts.loading);
export const getAdvertsLoaded = createSelector(getAdvertsState, adverts => adverts.loaded);

