import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'sanitazeURL' })
export class SanitazeURL implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(url: string): any {
    if (!url) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
