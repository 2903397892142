<form fxLayout="column"
      fxLayoutAlign="space-around stretch"
      [formGroup]="mediaForm"
      (ngSubmit)="save()"
      novalidate>
  <div class="gd-embed-twitch-dialog"
       fxLayout="column"
       fxLayoutAlign="space-between stretch"
       [dir]="dir$ | async">
    <h2 *ngIf="content === 'video'"
        mat-dialog-title
        i18n>Embed Twitch Video</h2>
    <h2 *ngIf="content === 'stream'"
        mat-dialog-title
        i18n>Embed Twitch Stream</h2>
    <mat-tab-group class="gd-embed-twitch-dialog__tab-group"
                   [class.gd-embed-twitch-dialog__tab-group--header-hidden]="!allowMeta"
                   dynamicHeight
                   [selectedIndex]="tabIndex">
      <mat-tab label="Configuration">
        <ng-template mat-tab-label
                     i18n="configurationTab|Configuration tab is used for adding new video">Configuration</ng-template>

        <div class="gd-embed-twitch-dialog__scrollable-content">
        <div class="gd-embed-twitch-dialog__data-wrapper">
          <div class="gd-embed-twitch-dialog__preview">
            <div id="twitch-embed"></div>
          </div>
          <div fxLayout="column"
               fxFlex="270px">
            <div>
              <section>
                <mat-form-field class="gd-embed-twitch-dialog__id-wrapper"
                                fxFlex="100%"
                                appearance="outline">
                  <mat-label>Id</mat-label>
                  <input matInput
                         type="text"
                         required
                         formControlName="id"
                         autocomplete="off"
                         #focusInput>
                  <mat-error *ngIf="idControl.hasError('invalidId')"
                             i18n="Validation message">Invalid ID</mat-error>
                  <mat-error *ngIf="idControl.hasError('required')"
                             i18n="Validation message">ID is required</mat-error>
                  <mat-error *ngIf="idControl.hasError('missingTwitchSettings')"
                             i18n="Validation message">Twitch settings are invalid</mat-error>
                  <mat-error *ngIf="idControl.hasError('invalidClientTwitchSettings')"
                             i18n="Validation message">Twitch Client ID is invalid</mat-error>
                  <mat-error *ngIf="idControl.hasError('invalidSecretTwitchSettings')"
                             i18n="Validation message">Twitch Secret Key is invalid</mat-error>
                  <mat-error *ngIf="idControl.hasError('invalidCredentialsTwitchSettings')"
                             i18n="Validation message">Twitch settings are invalid</mat-error>
                </mat-form-field>
              </section>

              <section>
                <mat-form-field class="gd-embed-twitch-dialog__name-wrapper"
                                fxFlex="100%"
                                appearance="outline">
                  <mat-label i18n>Name</mat-label>
                  <textarea matInput
                            cdkTextareaAutosize
                            type="text"
                            formControlName="name"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="2"
                            class="gd-custom-scroller"></textarea>
                  <mat-error *ngIf="mediaForm.get('name').hasError('required')"
                             i18n="Validation message">Name is required</mat-error>
                </mat-form-field>
              </section>

              <section *ngIf="content === 'video'">
                <div class="gd-article-form__input">
                  <mat-slide-toggle formControlName="autoplay"
                                    i18n>Autoplay</mat-slide-toggle>
                </div>
              </section>
            </div>
          </div>
        </div>

        <section class="gd-embed-twitch-dialog__description-wrapper">
          <mat-form-field class="gd-embed-twitch-dialog__description"
                          fxFlex="100%"
                          appearance="outline">
            <mat-label i18n>Description</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      type="text"
                      formControlName="description"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="7"
                      class="gd-custom-scroller"></textarea>
          </mat-form-field>
        </section>
        </div>
      </mat-tab>
      <mat-tab label="Metadata">
        <ng-template mat-tab-label
                     i18n="metadataTab|Metadata tab is used for adding video metadata">Metadata</ng-template>
        <div class="gd-embed-twitch-dialog__scrollable-content">
        <section>
          <ng-container *ngIf="metaDataConfig; else noItemConfigRequired">
            <gd-dynamic-widget-form [formConfig]="metaDataConfig"
                                    [widgetFormGroup]="metaData"
                                    [initialValues]="initialValues"
                                    [isEditingMode]="editingMode"
                                    [usage]="'COLLECTION'">
            </gd-dynamic-widget-form>
          </ng-container>
          <ng-template #noItemConfigRequired>
            <div [style.margin-bottom]="'10px'"
                 i18n>No configuration needed.</div>
          </ng-template>
        </section>
        </div>
      </mat-tab>
    </mat-tab-group>
    <section class="gd-embed-twitch-dialog-buttons">
      <button mat-raised-button
              color="primary"
              [disabled]="!mediaForm.valid || mediaForm.pending"
              type="submit"
              class="gd-embed-twitch-dialog-buttons--save">
        <ng-container *ngIf="allowMeta">
          <span [matTooltip]="getSaveButtonTooltipMessage()"
                [matTooltipDisabled]="!mediaForm || mediaForm.valid"
                i18n>
            Apply
          </span></ng-container>
        <ng-container *ngIf="!allowMeta"
                      i18n>Save</ng-container>
      </button>
      <button mat-raised-button
              color="danger"
              mat-dialog-close
              i18n>Cancel</button>
    </section>

  </div>
</form>
