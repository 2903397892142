import {filter, mergeMap, take} from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer2,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { Observable, of ,  Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { UpdateQueuedImageCropsAction } from '../../../core/store/images-upload/images-upload.actions';
import smartcrop from 'smartcrop';
import JustifiedLayout from 'justified-layout';
import { getUserFriendlyRatio } from '../../../core/api/images/images.service';
import { ImageCropService } from '../image-crop.service';
import { getRenditionsGroupedByRatio } from '../../../core/store/images-configuration';
import { get } from 'lodash';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'gd-image-crop-edit',
  templateUrl: './image-crop-edit.component.html',
  styleUrls: ['./image-crop-edit.component.scss']
})
export class ImageCropEditComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() image;
  @Input() crops;
  @Input() cropOptions;
  @Output() discardCropEvent = new EventEmitter();
  @Output() saveCropEvent = new EventEmitter();

  showPreviews = false;
  showCropPreviews = false;
  cropPreviews = [];
  cropPreviewsLayoutCreated = false;

  imageSmartCropped = false;
  mainCropperReadySub: Subscription;
  mainCropperReady = false;
  croppersReadySub: Subscription;
  croppersMovingSub: Subscription;
  cropperUpdateSub: Subscription;
  croppersActivationSub: Subscription;

  cropperSubscriptionGroup: Subscription = new Subscription();
  zoomStep = 0.2;
  hasUnsavedChanges = false;
  renditionGroups$: Observable<any> = this.store.select(getRenditionsGroupedByRatio)
  .pipe(filter((data: any) => data && !!data.length), take(1));

  @ViewChild('mainCropper', { static: true }) mainCropperInstance: ImageCropperComponent;
  @ViewChildren('imageCroppers') imageCroppersInstances: QueryList<ImageCropperComponent>;
  @ViewChildren('rendition_group') groupPanelElements;

  get cropPreviewsMap() {
    return (this.cropPreviews || [])
      .filter(cp => get(cp, 'cropperData.renditionId', null))
      .reduce((acc, cp) => ({ ...acc, [cp.cropperData.renditionId]: cp }), {});
  }

  cropByRatioControl: UntypedFormControl = new UntypedFormControl(true);

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private store: Store<AppState>,
    private cropService: ImageCropService,
  ) { }

  ngOnInit() {
    this.resetCropParams();
    setTimeout(() => this.handleCropByRatioToggleChange(true), 200);
  }

  ngAfterViewInit() {
    this.createCropSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image && changes.image.currentValue) {
      this.image = changes.image.currentValue;
      this.imageSmartCropped = false;

      if (this.mainCropperInstance && this.mainCropperInstance.cropper) {
        this.resetCropbox();
      }
    }

    if (changes.crops && changes.crops.currentValue) {
      this.crops = changes.crops.currentValue;
    }
  }

  updateQueuedImageCrops() {
    const croppersData = this.imageCroppersInstances.reduce((acc: any, cropper: any) => ({
      queueID: cropper.image.queueID,
      croppersData: [...acc.croppersData || [], cropper.imageSizeData]
    }), {});
    this.store.dispatch(new UpdateQueuedImageCropsAction(croppersData));
  }

  listenCroppersReadyEvents() {
    return of(this.imageCroppersInstances).pipe(
      mergeMap(croppers => {
        return croppers.map(cropper => cropper.cropperInitialized$.asObservable());
      }), mergeMap(v => v));
  }

  checkIfCroppersNotReady() {
    return this.imageCroppersInstances.some(cropper => !cropper.cropperInitialized);
  }

  listenForCroppersMoveEvents() {
    return this.mainCropperInstance.cropping$.asObservable();
  }

  listenForCropperUpdateEvent() {
    return of(this.imageCroppersInstances).pipe(
      mergeMap(croppers => croppers.map(cropper => cropper.cropperUpdate$.asObservable())),
      mergeMap(v => v));
  }

  subscribeToMainCropperInit() {
    return this.mainCropperInstance.cropperInitialized$
      .subscribe(event => {
        this.mainCropperReady = event;
        this.handleShowPreviews({ checked: true });
      });
  }

  subscribeToCroppersReady() {
    return this.listenCroppersReadyEvents()
      .subscribe(events => {
        const notAllCroppersReady = this.checkIfCroppersNotReady();
        if (!notAllCroppersReady) {
          this.updateQueuedImageCrops();
        }
      });
  }

  subscribeToCroppersMoving() {
    return this.listenForCroppersMoveEvents()
      .subscribe(cropping => {
        if (!cropping) {
          this.updateQueuedImageCrops();
          this.hasUnsavedChanges = true;
        }
      });
  }

  subscribeToCropperUpdate() {
    return this.listenForCropperUpdateEvent()
      .subscribe(updateEvent => {
        if (updateEvent) {
          this.updateQueuedImageCrops();
          this.hasUnsavedChanges = true;
        }
      });
  }

  handleShowPreviews(event) {
    this.showPreviews = event.checked;
  }

  generateFinalPreviews() {
    return new Promise(async (resolve) => {
      let imageCroppers = await Promise.all(this.imageCroppersInstances.map(async (imageCropper) => ({
        imageSizeData: imageCropper.imageSizeData,
        croppedImage: await imageCropper.getCroppedCanvasAsImg({
          width: +imageCropper.imageSizeData.width,
          height: +imageCropper.imageSizeData.height
        })
      })));

      imageCroppers = imageCroppers.sort((prev, curr) =>
        (+prev.imageSizeData.aspectRatio < +curr.imageSizeData.aspectRatio) ? -1 : 1);

      const imageSizes = imageCroppers.map(imageCropper => ({
        width: +imageCropper.imageSizeData.width,
        height: +imageCropper.imageSizeData.height,
      }));

      const { width: previewWrapperWidth } = this.elRef.nativeElement.getBoundingClientRect();

      const layout = JustifiedLayout(imageSizes, {
        containerWidth: Math.round(previewWrapperWidth),
        targetRowHeight: 180,
        targetRowHeightTolerance: 0.3
      });

      this.cropPreviews = layout.boxes.map((box, i) => {
        const sizeData = imageCroppers[i].imageSizeData;
        const boxPreview = imageCroppers[i].croppedImage;
        const { width, height } = this.getNewImageDimensions(sizeData);
        const formattedRatio = getUserFriendlyRatio(sizeData.width, sizeData.height);
        const displayData = { width, height, ratio: sizeData.aspectRatio, method: sizeData.resize_method_label, formattedRatio };
        return { ...box, croppedImage: boxPreview, label: sizeData.label, cropperData: sizeData, displayData };
      })
      .sort((a, b) => parseInt(a.displayData.height, 10) > parseInt(b.displayData.height, 10) ? -1 : 1)
      .sort((a, b) => parseInt(a.displayData.width, 10) > parseInt(b.displayData.width, 10) ? -1 : 1);

      resolve({ cropPreviews: this.cropPreviews });
    });
  }

  getNewImageDimensions(rendition) {
    const resizeMethod = rendition.resize_method;
    if (resizeMethod === 'scale_to_fill') {
      return { width: rendition.width, height: rendition.height };
    }
    const { width: cropWidth, height: cropHeight } = rendition.cropbox || rendition.initialCropbox;
    const maxWidth = Math.round(cropWidth <= rendition.width ? cropWidth : rendition.width);
    const maxHeight = Math.round(cropHeight <= rendition.height ? cropHeight : rendition.height);
    if (resizeMethod === 'crop_from_position') {
      return calculateNewImageDimensions(maxWidth, rendition.width / rendition.height, maxHeight);
    }
    // scale to fit
    const original = { ...this.image.naturalDimensions };
    return calculateNewImageDimensions(maxWidth, original.width / original.height, maxHeight);
  }

  handleCropReInit(imageSizeKey) {
    // refresh cropsMap
    this.image.cropsMap = this.image.crops.reduce((acc, item) => ({ ...acc, [item.renditionId]: item }), {});
    document.getElementById('main-container').scroll({ top: 0 });
    const cropperCanvas = this.elRef.nativeElement.querySelector('.gd-image-crop-edit__cropper-canvas');
    this.renderer.setStyle(cropperCanvas, 'display', 'block');
    this.showCropPreviews = false;
    this.cropPreviewsLayoutCreated = false;
    this.image.cropped = false;
    this.handleShowPreviews({ checked: true });
    setTimeout(() => this.handleCropByRatioToggleChange(false), 0);

    setTimeout(() => {
      const cropperToFocus = this.imageCroppersInstances.find((cropper: any) =>
        cropper.imageSizeData.key === imageSizeKey);
      cropperToFocus.cropper.container.scrollIntoView({ behavior: 'smooth', block: 'center' });

      const compactCropperWrapper = this.elRef.nativeElement.querySelector(`.gd-image-crop-edit__crop-item.${imageSizeKey}`);
      this.renderer.addClass(compactCropperWrapper, '--focused');
      this.createCropSubscriptions();
    }, 500);

  }

  async getSmartcropArea() {
    const scaleRatio = 4;
    const imgToProcess: any = await this.getShrinkedImage(this.image, scaleRatio);
    const shrinkedImg = {
      width: imgToProcess.shrinkedImage.width,
      height: imgToProcess.shrinkedImage.height
    };

    // NOTE: "as any" for CropOptions param added just to avoid compile issues
    // investigate why crop options doesn't have required width and height properties
    const smartAreaCrop = await smartcrop.crop(imgToProcess.shrinkedImage, { ruleOfThirds: true } as any);
    const smartArea = smartAreaCrop.topCrop;

    const xP = (smartArea.x / shrinkedImg.width) * 100;
    const yP = (smartArea.y / shrinkedImg.height) * 100;

    const wP = (smartArea.width / shrinkedImg.width) * 100;
    const hP = (smartArea.height / shrinkedImg.height) * 100;

    this.mainCropperInstance.setCropboxPositionFromPercentage({ x: xP, y: yP, width: wP, height: hP });
    this.imageCroppersInstances.forEach(cropper => {
      cropper.setCropboxPositionFromPercentage({ x: xP, y: yP, width: wP, height: hP });
    });
  }

  resetCropbox() {
    this.imageSmartCropped = false;
    this.cropService.removeRelativeFocalPoint();
    this.mainCropperInstance.cropper.enable();
    this.mainCropperInstance.cropper.reset();
    this.mainCropperInstance.cropper.crop();
    this.imageCroppersInstances.forEach(cropper => {
      cropper.cropper.setData(cropper.imageSizeData.initialCropbox);
      cropper.imageSizeData['modified'] = false;
      if (cropper.imageSizeData.cropMethod === 'preDefined') {
        cropper.imageSizeData.usePredefined = true;
      }
    });
    this.hasUnsavedChanges = false;
  }

  discardCrop() {
    this.discardCropEvent.emit(true);
  }

  saveCropChanges() {
    this.saveCropEvent.emit(true);
  }

  handleZoomEvent(zoomValue) {
    this.mainCropperInstance.cropper.zoom(zoomValue);
    this.imageCroppersInstances.forEach(cropper => {
      cropper.cropper.zoom(zoomValue);
    });
  }

  handleRotateEvent(direction) {
    const rotateDegree = 45;
    const dir = direction === 'LEFT' ? rotateDegree : -rotateDegree;
    this.mainCropperInstance.cropper.rotate(dir);
    this.imageCroppersInstances.forEach(cropper => {
      cropper.cropper.rotate(dir);
    });
  }

  ngOnDestroy() {
    this.cropperSubscriptionGroup.unsubscribe();
  }

  getShrinkedImage(image, scaleRatio) {
    return new Promise((resolve) => {
      const newImage = new Image();
      newImage.src = image.dataURL;
      const canvas = document.createElement('canvas');
      const canvasCtx = canvas.getContext('2d');
      canvasCtx.imageSmoothingEnabled = true;

      // TODO: Resolve natural image dimensions and check if they exceed some predefined values
      // if image is already small enough to be quickly processed by smartcrop then
      // there's no need to shrink image
      newImage.onload = async () => {
        const imageAspectRatio = newImage.width / newImage.height;
        canvas.width = Math.round(newImage.width / scaleRatio);
        canvas.height = canvas.width / imageAspectRatio;
        canvasCtx.drawImage(newImage, 0, 0);

        const shrinkedImage = new Image();
        shrinkedImage.src = canvas.toDataURL('image/jpeg', 1);
        shrinkedImage.onload = async () => {
          resolve({ shrinkedImage, naturalDimensions: { width: newImage.width, height: newImage.height } });
        };
      };
    });
  }

  createCropSubscriptions() {
    this.cropperSubscriptionGroup.unsubscribe();
    this.cropperSubscriptionGroup = new Subscription();
    if (this.mainCropperInstance) {
      this.cropService.getCropboxState().pipe(take(1), filter(data => data && !!Object.keys(data).length))
        .subscribe(data => this.mainCropperInstance.cropper.setData(data));
      this.cropperSubscriptionGroup.add(this.subscribeToMainCropperInit());
    }

    this.cropperSubscriptionGroup.add(this.subscribeToCroppersReady());
    this.cropperSubscriptionGroup.add(this.subscribeToCroppersMoving());
    this.cropperSubscriptionGroup.add(this.subscribeToCroppersMoving());
    this.cropperSubscriptionGroup.add(this.subscribeToCropperUpdate());
  }

  resetCropperOffset() {
    this.cropService.resetOffset();
  }

  resetCropParams() {
    this.cropService.resetCropParams();
  }

  handleRenditionGroupExpansion(index) {
    if (!this.groupPanelElements?.length) {
      return;
    }
    const groupEl = this.groupPanelElements.get(index).nativeElement;
    const collapsed = groupEl.classList.contains('--collapsed');
    if (collapsed) {
      groupEl.classList.remove('--collapsed');
    } else {
      groupEl.classList.add('--collapsed');
    }
  }

  getCropPreview(renditionId) {
    return (this.cropPreviews || []).find(data => get(data, 'cropperData.renditionId', null) === renditionId);
  }

  handleCropByRatioToggleChange(checked) {
    this.cropByRatioControl.setValue(checked);
    this.cropService.setCropByRatioFlag(checked);
    Array.from(this.groupPanelElements).forEach((panel: any) => {
      const el = panel.nativeElement;
      if (checked) {
        el.classList.add('--collapsed');
      } else {
        el.classList.remove('--collapsed');
      }
    })
  }

  getRenditionGroupTitle(group) {
    return group.replace(/_/g, ' ').replace('-', ' - ');
  }
}

function calculateNewImageDimensions(width, ratio, maxHeight) {
  const height = Math.floor(width / ratio);
  if (height <= maxHeight) {
    return { width, height };
  }
  return calculateNewImageDimensions(--width, ratio, maxHeight);
}

