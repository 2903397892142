import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of, Subject, tap } from 'rxjs';
import { ImageIntegrationService } from './image-integration.service.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { AppState } from '../../store/app-reducer';
import { Store } from '@ngrx/store';
import { AddImagesToQueueAction } from '../../store/images-upload/images-upload.actions';
import shortid from 'shortid';
import { RestService } from '../rest.service';

@Injectable()
export class GettyIntegrationService implements ImageIntegrationService {
  accessToken;
  expiryDate;
  apiKey;
  usedAccountId;
  hasError: boolean = false;

  loading: boolean;
  configured$: Subject<any> = new Subject();

  apiBaseUrl = 'https://api.gettyimages.com/v3/';

  get headers() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.accessToken,
      'Api-Key': this.apiKey,
    };

    return { headers: new HttpHeaders(headers)};
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>,
    private rest: RestService,
  ) {
    this.dispatchIsConfiguredInfo(true);
  }

  auth(): Observable<any> {
    const activeAccountId = this.authService.getUserAccountId();
    const currentDate = new Date().valueOf();
    const accountChanged = this.usedAccountId !== activeAccountId;

    // get a token if not fetched already, or it's fetched but will expire in 30 seconds max or active account has been changed
    const newTokenRequired = !this.accessToken || accountChanged || (this.expiryDate - currentDate) < 30000;
    if (!newTokenRequired && !this.hasError) {
      return of({ accessToken: this.accessToken, apiKey: this.apiKey, status: 'success' });
    }
    this.dispatchIsConfiguredInfo(true);

    return this.rest.get('integrations/getty/auth', { service: 'glideMedia' }).pipe(
      mergeMap((authData) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authData.accessToken,
          'Api-Key': authData.apiKey,
        };
        return this.http.get(`${this.apiBaseUrl}products`, { headers }).pipe(
          map((products) => {
            const productsOk = products['products'].length !== 0;
            return [authData, productsOk];
          })
        );
      }),
      map(([authData, productsOk]) => {
        if (!productsOk) {
          this.hasError = true;
          return {
            status: 'error',
            errorType: 'expired-credentials',
            errorMessage: $localize`The current Getty account credentials have expired. Please go to Settings to update your Getty account details`,
          };
        }
        this.usedAccountId = activeAccountId;
        this.accessToken = authData.accessToken;
        this.apiKey = authData.apiKey;
        this.expiryDate = new Date().valueOf() + authData.expiresIn * 1000;

        this.hasError = false;
        return { status: 'success', accessToken: this.accessToken, apiKey: this.apiKey };
      }),
      catchError(({ error }) => {
        // if credentials are not set, the API error message would be: "Missing Getty credentials"
        const configured = !error.toLowerCase().includes('missing');
        this.dispatchIsConfiguredInfo(false);
        let errorMessage = error;
        let errorType = '';

        if (!configured) {
          errorType = 'not-configured-credentials';
          errorMessage = $localize`Getty is not linked with this Glide account. Please go to Settings in order to get started.`;
        }
        if (error.toLowerCase().includes('incorrect')) {
          errorType = 'incorrect-credentials';
          errorMessage = $localize`The Getty credentials linked with this Glide account are no longer valid. Please go to Settings to check the details.`;
        }
        this.hasError = true;
        return of({ status: 'error', errorMessage, errorType });
      })
    );
  }

  filter({ phrase, imageType, orientation, sortBy, pageNumber, pageSize }): Observable<any> {
    this.loading = true;
    // +1 is added because getty pagination starts from number 1, not 0
    pageNumber = typeof pageNumber === 'number' ? (pageNumber + 1) : 1;

    phrase = (phrase || '').trim();
    let requestUrl = this.apiBaseUrl + 'search/images';
    requestUrl += imageType && imageType !== 'none' ? `/${imageType}` : '';
    requestUrl += `?file_types=jpg&fields=summary_set,download_sizes,display_set,largest_downloads&page=${pageNumber}&page_size=${pageSize || 30}`;
    requestUrl += phrase ? `&phrase=${phrase}` : '';
    requestUrl += orientation && orientation !== 'none' ? `&orientations=${orientation}` : '';
    requestUrl += sortBy && sortBy !== 'none' ? `&sort_order=${sortBy}` : '';

    return this.auth().pipe(
      filter(data => data?.accessToken),
      mergeMap(() => this.http.get(requestUrl, this.headers)),
      map(({ images, result_count }: any) => {
        const formattedImages = images.map(img => {
          const thumbnail = img['display_sizes'].find(({ name }) => name === 'thumb').uri;
          const preview = img['display_sizes'].find(({ name }) => name === 'comp').uri;
          return { ...img, thumbnail, preview };
        });
        return { images: formattedImages, total: result_count };
      }),
      tap(() => { this.loading = false; })
    );
  }

  getConfig() {
    const imageTypeOptions = [
      { label: $localize`All`, value: 'none' },
      { label: $localize`Creative`, value: 'creative' },
      { label: $localize`Editorial`, value: 'editorial' },
    ];
    const orientationOptions = [
      { label: $localize`All`, value: 'none' },
      { label: $localize`Vertical`, value: 'vertical' },
      { label: $localize`Horizontal`, value: 'horizontal' },
      { label: $localize`Squared`, value: 'squared' },
    ];
    const sortByOptions = [
      { label: $localize`Best Match`, value: 'best_match' },
      { label: $localize`Most Popular`, value: 'most_popular' },
      { label: $localize`Newest`, value: 'newest' },
    ];

    const filters = [
      { key: 'phrase', label: $localize`Phrase`, type: 'text', primary: true },
      { key: 'imageType', label: $localize`Image Type`, type: 'select', data: imageTypeOptions, primary: false },
      { key: 'orientation', label: $localize`Orientation`, type: 'select', data: orientationOptions, primary: false },
      { key: 'sortBy', label: $localize`Sort By`, type: 'select', data: sortByOptions, primary: false },
    ];

    return {
      title: $localize`Getty Library`,
      filters,
      importBtnLabel: $localize`Licence & Import`,
      importConfirmationMessageMultiple: $localize`There may be charges associated with Getty images.
                                           Please check your organisation's Getty account status for details.
                                           ${'<br>'} Are you sure you want to license and import the selected images?`,
      importConfirmationMessageSingle: $localize`There may be charges associated with Getty images.
                                           Please check your organisation's Getty account status for details.
                                           ${'<br>'} Are you sure you want to license and import the selected image?`,
    };
  }

  async import(data) {
    const images: any = await Promise.all(data.map(async (img) => {
      // licencing
      const licenceUrl = `https://api.gettyimages.com/v3/downloads/${img.id}?auto_download=false&file_type=jpg`;
      const original = await firstValueFrom(this.http.post(licenceUrl, {}, this.headers)).then(({ uri }: any) => uri);

      const blob = await firstValueFrom(this.http.get(img.thumbnail, { responseType: 'blob' }));
      const filename = `${extractImageNameFromUrl(img.preview)}.${blob.type.split('/')[1]}`;
      const file = new File([blob], filename, { type: blob.type });

      const imgURL = URL.createObjectURL(file);
      const meta = { key: 'image_details', value: {} };
      img.meta.forEach(({ key, value }) => {
        meta.value[key] = key === 'credit' ? JSON.stringify(value) : value;
      });
      return {
        queueID: shortid.generate(10),
        fileData: file,
        meta: [meta],
        dimensions: { ...img.max_dimensions },
        // dimensions: { width: 7200, height: 5400 },
        imgURL,
        original,
        // original: 'https://s3-eu-west-1.amazonaws.com/nonprod-media-glidecloud1/qa/images/original/582e32296c39-large-sample-png-image-download-for-testing.png',
        originalLoaded: false,
        provider: 'Getty'
      };
    }));

    this.store.dispatch(new AddImagesToQueueAction(images));
    return images;
  }

  getIsConfiguredObservable(): Observable<boolean> {
    return this.configured$.asObservable();
  }

  dispatchIsConfiguredInfo(value) {
    this.configured$.next(value);
  }

  getProducts() {
    return this.auth().pipe(
      mergeMap(() => this.http.get(`${this.apiBaseUrl}products`, this.headers))
    );
  }

  reset() {
    this.accessToken = null;
    this.apiKey = null;
    this.expiryDate = null;
  }

  validateConfiguration({ apiKey, apiSecret }) {
    return this.rest.post(`integrations/getty/token`, { apiKey, apiSecret }, { service: 'glideMedia' });
  }
}

function extractImageNameFromUrl(url: string) {
  let name = url.slice(url.lastIndexOf('/') + 1);
  if (url.includes('?')) {
    name = name.slice(0, name.lastIndexOf('?'));
  }
  const extension = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif'].find(ext => name.endsWith(`.${ext}`));
  if (extension) {
    name = name.slice(0, name.lastIndexOf(extension) - 1);
  }
  return name;
}
