import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ContentLocale } from '../../store/content-locales/content-locales.model';

@Injectable()
export class ContentLocalesService {
  constructor(private rest: RestService) {}

  getAllContentLocales() {
    return this.rest.get('content-locales').pipe(map((response) => response.data));
  }

  getContentLocaleById(id: number) {
    return this.rest.get('content-locales/' + id).pipe(map((response) => response.data));
  }

  createContentLocale(payload: ContentLocale) {
    return this.rest.post('content-locales', payload).pipe(map((response) => response.data));
  }

  updateContentLocale(contentLocale: ContentLocale) {
    return this.rest
      .put('content-locales/' + contentLocale.id, contentLocale)
      .pipe(map((response) => response.data));
  }

  deleteContentLocale(id: number) {
    return this.rest.delete('content-locales/' + id);
  }
}
