import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ProxyService } from '../../core/api/proxy/proxy.service';

declare const $: any;

@Injectable()
export class EmbeddablesDataService {

  constructor(
    private http: HttpClient,
    private proxyService: ProxyService
  ) { }

  getYouTubeVideoData(queryParams: any = { part: 'id,snippet' }): Observable<any> {
    const queryString = Object.entries(queryParams)
      .map(([paramName, value]) => `${paramName}=${value}`)
      .join('&');
    const apiKey = environment.youTubeApiKey;
    const url = `https://www.googleapis.com/youtube/v3/videos?key=${apiKey}&${queryString}`;
    return this.http.get(url);
  }

  getVimeoData(id: string, autoplayFlag: boolean) {
    const requestUrl = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + id + '&autoplay=' + autoplayFlag;
    return this.http.get(requestUrl).toPromise();
  }

  getBrightcoveVideoData(videoId, settings) {
    const { accountId, policyKey } = settings;
    const url = 'https://edge.api.brightcove.com/playback/v1/accounts/' + accountId + '/videos/' + videoId;
    const headers = ['Authorization', 'BCOV-Policy ' + policyKey];
    return this.proxyService.get({ url, headers }).toPromise();
  }

  getJWVideoData(videoId) {
    const url = `https://cdn.jwplayer.com/v2/media/${videoId}`;
    return this.http.get(url);
  }

  getJWPlaylistData(playlistId) {
    const url = `https://cdn.jwplayer.com/v2/playlists/${playlistId}`;
    return this.http.get(url);
  }

  getDailymotionVideoData(videoId) {
    const url = `https://api.dailymotion.com/video/${videoId}?fields=private_id%2Cprivate%2Cembed_url%2Cthumbnail_480_url%2Cdescription%2Ctitle%2Cid%2Cheight%2Cwidth`;
    return this.http.get(url);
  }

  getDailymotionPlaylistData(playlistId) {
    const url = `https://api.dailymotion.com/playlist/${playlistId}?fields=videos_total%2Cid%2Cthumbnail_480_url%2Cname%2Cdescription`;
    return this.http.get(url);
  }

  getDailymotionPlaylistVideos(id) {
    const url = `https://api.dailymotion.com/playlist/${id}/videos?fields=id%2Cthumbnail_480_url%2Ctitle&page=1&limit=10`;
    return this.http.get(url);
  }

  getTwitchVideoData(videoId, auth) {
    const url = `https://api.twitch.tv/helix/videos?id=${videoId}`;
    const headers = {
      'Client-ID': auth.clientId,
      'Authorization': `Bearer ${auth.accessToken}`
    }
    return this.http.get(url, {headers})
  }

  getTwitchStreamData(channel, auth) {
    const url = `https://api.twitch.tv/helix/streams?user_login=${channel}`;
    const headers = {
      'Client-ID': auth.clientId,
      'Authorization': `Bearer ${auth.accessToken}`
    }
    return this.http.get(url, {headers})
  }

  emojiStrip(text) {
    const regex = new RegExp(['([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?',
                             '|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?',
                             '|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?',
                             '|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?',
                             '|[\u20E3]|[\u26A0-\u3000]|\uD83E[\udd00-\uddff]|[\u00A0-\u269F]'].join(''), 'g');
    return text.replace(regex, '');
  }
}
