<div fxLayout="column">

  <h2 mat-dialog-title>{{ refreshTokenFailed ? 'Session Expired' : 'Token refresh failed' }}</h2>
  <section *ngIf="!refreshTokenFailed">
    <p>Please try refreshing it manually</p>
  </section>
    <section *ngIf="refreshTokenFailed">
      <p>Please enter your password to continue working</p>

      <mat-form-field class="gd-refresh-token-dialog__input">
        <mat-label>Password</mat-label>
        <input matInput
              type="password"
              [formControl]="passwordForm">
        <mat-error *ngIf="passwordForm.hasError('required')">
          Required
        </mat-error>
        <mat-error
                  *ngIf="passwordForm.hasError('invalidPassword')">
          Invalid password
        </mat-error>
      </mat-form-field>
    </section>

  <section fxFlex="100%"
           fxLayout="row"
           fxLayoutAlign="flex-end flex-end"
           fxLayoutGap="12px">
    <div class="gd-refresh-token-dialog__loader "
         *ngIf="makingRequest">
      <mat-spinner mode="indeterminate"
                   diameter="30">
      </mat-spinner>
    </div>
    <button mat-raised-button
            color="primary"
            (click)="submit()"
            [disabled]="makingRequest">
      {{ refreshTokenFailed ? 'Continue working' : 'Refresh' }}
    </button>
    <button mat-button
            color="danger"
            (click)="exit()"
            mat-dialog-close>
      Cancel
    </button>
  </section>

</div>
