import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';

@Injectable()
export class ProxyService {

  constructor(private rest: RestService) { }

  get(payload) {
    return this.rest.post('proxy-request', payload);
  }

}
