import { UnsafeAction } from '../unsafe-action.interface';

import {
  UPDATE_QUEUED_IMAGE_CROPS,
  REMOVE_IMAGE_FROM_QUEUE,
  UPDATE_UPLOADED_IMAGE,
  UPLOAD_STARTED,
  ALL_IMAGES_UPLOADED,
  START_POLLING_IMAGE_STATUS,
  UPDATE_PROCESSED_IMAGE,
  UPDATE_QUEUED_IMAGES,
  ADD_IMAGES_TO_QUEUE_DONE,
  UPDATE_QUEUED_IMAGE_META,
  RESET_IMAGES_UPLOAD_STATE,
  DISCARD_IMAGE_CROPS_CHANGES,
  SET_IMAGES_PROCESSING_STATE,
} from './images-upload.actions';
import { createSelector } from '@ngrx/store';
import { ACCOUNT_CHANGED } from '../auth/auth.actions';

export interface ImagesUploadState {
  uploading: boolean;
  processing: boolean;
  checkingImagesStatus: boolean;
  queuedImages: {};
}

const initialState: ImagesUploadState = {
  uploading: false,
  processing: false,
  checkingImagesStatus: false,
  queuedImages: {},
};

export function imagesUploadReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {
    case ADD_IMAGES_TO_QUEUE_DONE: {
      const imagesToEnqueue = action.payload
        .map(image => {
          return {
            ...image,
            meta: image.meta || [],
            tags: image.tags || [],
            uploaded: false,
            processing: false,
            processed: false,
            uploadProgress: 0,
          };
        })
        .reduce((acc, curr) => ({ ...acc, [curr.queueID]: curr }), {});

      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          ...imagesToEnqueue,
        },
      };
    }

    case UPDATE_QUEUED_IMAGES: {
      const updatedQueuedImages = action.payload.reduce((acc, image) => {
        acc[image.queueID] = image;
        return acc;
      }, {});

      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          ...updatedQueuedImages,
        },
      };
    }

    case REMOVE_IMAGE_FROM_QUEUE: {
      const imageID = action.payload;
      const newQueuedImagesState = { ...state.queuedImages };
      delete newQueuedImagesState[imageID];
      return {
        ...state,
        queuedImages: newQueuedImagesState,
      };
    }

    case UPLOAD_STARTED: {
      return {
        ...state,
        uploading: true,
      };
    }

    case ALL_IMAGES_UPLOADED: {
      return {
        ...state,
        uploading: false,
        processing: true,
      };
    }

    case START_POLLING_IMAGE_STATUS: {
      return {
        ...state,
        checkingImagesStatus: true,
      };
    }

    case UPDATE_UPLOADED_IMAGE: {
      const uploadedImage = action.payload;
      uploadedImage.processing = true;
      uploadedImage.processed = false;
      uploadedImage.uploaded = true;
      uploadedImage.uploading = false;
      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          [uploadedImage.queueID]: uploadedImage,
        },
      };
    }

    case UPDATE_PROCESSED_IMAGE: {
      const processedImage = action.payload;
      processedImage.processed = true;
      processedImage.processing = false;
      processedImage.uploaded = true;
      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          [processedImage.queueID]: processedImage,
        },
      };
    }

    case UPDATE_QUEUED_IMAGE_META: {
      const { queueID, meta, tags } = action.payload;
      let imageToUpdate = state.queuedImages[queueID];
      if (!imageToUpdate) {
        return state;
      }
      const imageDetailsMeta = imageToUpdate.meta.find(item => item.key === 'image_details');

      if (!imageDetailsMeta) {
        imageToUpdate = {
          ...imageToUpdate,
          meta: [{ key: 'image_details', value: meta }],
        };
      } else {
        imageDetailsMeta.value = {
          ...imageDetailsMeta.value,
          ...meta,
        };
      }

      // Assign tags
      imageToUpdate = {
        ...imageToUpdate,
        tags,
      };

      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          [queueID]: imageToUpdate,
        },
      };
    }

    case UPDATE_QUEUED_IMAGE_CROPS: {
      const { queueID, croppersData } = action.payload;
      const queuedImage = { ...state.queuedImages[queueID] };
      queuedImage.crops = [...croppersData];

      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          [queueID]: queuedImage,
        },
      };
    }

    case DISCARD_IMAGE_CROPS_CHANGES: {
      const { id, crops } = action.payload;
      const queuedImage = { ...state.queuedImages[id], crops };

      return {
        ...state,
        queuedImages: {
          ...state.queuedImages,
          [id]: queuedImage,
        },
      };
    }

    case RESET_IMAGES_UPLOAD_STATE:
    case ACCOUNT_CHANGED: {
      return { ...initialState };
    }

    case SET_IMAGES_PROCESSING_STATE: {
      return {
        ...state,
        processing: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getQueuedImages = state => state.imagesUpload.queuedImages;
export const getQueuedImagesArray = state => Object.values(state.imagesUpload.queuedImages);

export const getQueuedImagesState = createSelector(
  getQueuedImagesArray,
  images => images
);

export const queueBeingProcessed = state => {
  return state.imagesUpload.uploading || state.imagesUpload.processing;
};
