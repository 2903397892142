import { createSelector } from 'reselect';
import { LiveReportType } from './live-report-types.model';
import { LiveReportTypeActionTypes, LiveReportTypeActions } from './live-report-types.actions';
import { AppState } from '../app-reducer';

export interface LiveReportTypeState {
  loaded: boolean;
  loading: boolean;
  error: string;
  configuration: LiveReportType;
}

const initialState: LiveReportTypeState = {
  loaded: false,
  loading: false,
  error: null,
  configuration: null,
};

export function liveReportTypeReducer(state = initialState, action: LiveReportTypeActions) {
  switch (action.type) {
    case LiveReportTypeActionTypes.UPDATE:
    case LiveReportTypeActionTypes.LOAD: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case LiveReportTypeActionTypes.LOAD_SUCCESS: {
      const configuration: LiveReportType = action.payload;
      return {
        ...state,
        configuration,
        loaded: true,
        loading: false,
        error: null,
      };
    }

    case LiveReportTypeActionTypes.UPDATE_SUCCESS: {
      const configuration: LiveReportType = action.payload;
      return {
        ...state,
        configuration,
        loading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLiveReportTypesState = (state: AppState) => state.liveReportType;
export const getLiveReportTypes = (state: AppState) => state.liveReportType.configuration;

export const getLiveReportTypeLoading = createSelector(
  getLiveReportTypesState,
  (liveReportTypeState: LiveReportTypeState) => liveReportTypeState.loading
);

export const getLiveReportTypeLoaded = createSelector(
  getLiveReportTypesState,
  (liveReportTypeState: LiveReportTypeState) => liveReportTypeState.loaded
);
