<div #container
     class="gd-widget-card-builder-wrapper">
  <div #cardBuilder
       class="gd-widget-card-builder"
       [class.--one-preview-field]="previewFields.length === 1">
    <ng-container *ngIf="!loading">
      <ng-container *ngFor="let field of previewFields; let i = index">
        <div class="gd-widget-card-builder__field"
             [class.--onePreviewField]="previewFields.length === 1"
             [style.display]="field.previewData ? 'block' : 'flex'"
             [style.width]="previewFields.length > 1 ? 'calc(50% - 10px)' : '100%'">
          <gd-custom-field-preview [selectedValue]="field.value"
                                   [configuration]="field.configuration"
                                   [previewData]="field.previewData"
                                   [widgetColor]="widgetData?.colour"
                                   [usage]="usage"
                                   *ngIf="field.previewData"
                                   [contextShadowRoot]="contextShadowRoot"
                                   (editItem)="handleEditItem()"
                                   ></gd-custom-field-preview>
          <mat-spinner color="primary"
                       diameter="30"
                       *ngIf="!field.previewData">
          </mat-spinner>
        </div>
      </ng-container>
      <div *ngIf="!widgetData"
           class="gd-widget-card-builder__no-result-field">
        <div class="gd-widget-card-builder__no-result-field-header"
             i18n>
          Widget not found
        </div>
      </div>
      <div *ngIf="widgetData && !previewFields.length"
           class="gd-widget-card-builder__no-preview-fields">
        <div class="gd-widget-card-builder__no-preview"
             (click)="navigateToWidget()">
          <span i18n>
            <strong>Widget Preview</strong> can be configured in <strong>System Widgets</strong></span>
        </div>
      </div>
    </ng-container>
    <mat-spinner color="primary"
                 diameter="35"
                 *ngIf="loading">
    </mat-spinner>
  </div>
  <div *ngIf="!loading && previewFields.length > 1"
       class="gd-widget-card-builder__switch-button-wrapper">
    <button mat-mini-fab
            class="gd-widget-card-builder__switch-button"
            (click)="switchCards()">
      <mat-icon fontSet="fas"
                fontIcon="fa-sort-alt"></mat-icon>
    </button>
  </div>
</div>
