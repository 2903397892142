import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import Tribute from 'tributejs';
import { Key } from 'ts-key-enum';
import { map } from 'rxjs/operators';
import { DataToken } from './data-token.model';

declare const $;
@Injectable()
export class DataTokensService {
  dataTokens: any = [];
  tributeConfig = {
    trigger: '<',
    lookup: 'name',
    fillAttr: 'name',
    autocompleteMode: true,
    values: () => { }
  };

  isFilteringEnabled = true;

  filterDataTokens = (text, callback, usage) => {
    $('.tribute-container').addClass('custom --data-token');
    const disableFiltering = !!text.match(/[:>]/) || this.dataTokens.length === 0;
    if (!this.isFilteringEnabled || disableFiltering) {
      $('.tribute-container').addClass('--hidden');
      return false;
    }
    $('.tribute-container').removeClass('--hidden');
    text = text.toLowerCase();

    const filteredTokens = this.dataTokens.filter(({name, type}) => {
      // URL and CUSTOM_URL_TOKENS are viable candidates for usage in route path pattern
      if (usage == 'route') {
        const isRouteUsableType = type === 'URL' || type === 'CUSTOM_URL_TOKEN';
        return isRouteUsableType && name.toLowerCase().includes(text);
      }

      // for other cases return all tokens
      return name.toLowerCase().includes(text);
    }).slice(0, 5);
    callback(filteredTokens);
  }

  constructor(private rest: RestService) { }

  createDataToken(token: DataToken) {
    return this.rest.post('data-tokens', token)
      .pipe(map(res => res.data));
  }

  updateDataToken(token: DataToken) {
    return this.rest.put('data-tokens/' + token.id, token)
      .pipe(map(res => res.data));
  }

  deleteDataToken(token) {
    return this.rest.delete('data-tokens/' + token.id);
  }

  getDataTokens() {
    return this.rest.get('data-tokens')
      .pipe(map(response => response.data.sort((a, b) => a.name < b.name ? -1 : 1)));
  }

  initDataTokensAutocomplete(inputEl, formControl, usage = 'route') {
    if (!inputEl) {
      return;
    }
    this.getDataTokens().toPromise().then(tokens => this.dataTokens = tokens);
    const config = {
      ...this.tributeConfig,
      values: (text, callback) => this.filterDataTokens(text, data => callback(data), usage),
      selectTemplate: item => {
        setTimeout(() => formControl.setValue(inputEl.value.trim()), 0);
        return item && item.original ? getOutputByUsage(usage, item.original.name) : '';
      }
    };
    const tribute = new Tribute({ collection: [config], noMatchTemplate: () => '<li>No Match</li>' });
    tribute.attach(inputEl);
    this.bindEvents(inputEl);
  }

  bindEvents(element) {
    $(element).on('keydown', event => {
      // key '<'
      if (event.key === '<') {
        return this.isFilteringEnabled = true;
      }
      // key 'esc'
      if (event.key === Key.Escape) {
        return this.isFilteringEnabled = false;
      }
    });
  }

}

// usage affects to text that will be displayed after selecting token from autocomplete
function getOutputByUsage(usage, token) {
  switch (usage) {
    case 'template':
      return `{{ dataTokens.${token} }}`;
    default:
      return `<${token}>`;
  }
}
