import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-translation-loader-dialog',
  templateUrl: './translation-loader-dialog.component.html',
  styleUrls: ['./translation-loader-dialog.component.scss'],
})
export class TranslationLoaderDialogComponent {
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<TranslationLoaderDialogComponent>,
    private bidiService: BidiService,
  ) {}
}
