<div class="gd-change-password-dialog" [dir]="dir$ | async">
  <form fxLayout="column"
        fxLayoutAlign="space-around strech"
        [formGroup]="changePasswordForm"
        (ngSubmit)="save()"
        novalidate>
    <h2 mat-dialog-title i18n>Change Password</h2>

    <section>
      <mat-form-field class="gd-change-password-dialog__input"
      appearance="outline"
      [style.margin-bottom]="passwordControl.hasError('pattern') ? '30px' : null">
      <mat-label i18n>New Password</mat-label>
        <input matInput
               type="password"
               autocomplete="new-password"
               formControlName="password">
        <mat-icon matSuffix
                  class="question-icon"
                  i18n-matTooltip
                  matTooltip="Password must be at least 10 characters long and it must contain at least one uppercase letter, one lowercase letter, one numeric digit and one special character."
                  matTooltipPosition="left"
                  fontSet="fal"
                  fontIcon="fa-question-circle"></mat-icon>
        <mat-error *ngIf="passwordControl.hasError('required')" i18n>Password is required.</mat-error>
        <mat-error *ngIf="passwordControl.hasError('minlength')" i18n>Password must be at least 12 characters long.</mat-error>
        <mat-error *ngIf="passwordControl.hasError('pattern')" i18n>Password must contain at least one uppercase letter, one lowercase letter, one numeric digit and one special character.</mat-error>
      </mat-form-field>
    </section>

    <section>
      <mat-form-field class="gd-change-password-dialog__input"
        [style.margin-bottom]="confirmPasswordControl.invalid ? '20px' : null"
        appearance="outline">
        <mat-label i18n>Confirm Password</mat-label>
        <input matInput
               type="password"
               autocomplete="new-password"
               formControlName="confirmPassword">
        <mat-error i18n>Password doesn't match.</mat-error>
      </mat-form-field>
    </section>

    <section class="gd-change-password-dialog__section-buttons">
      <button mat-raised-button
              color="primary"
              type="submit"
              i18n
              class="gd-change-password-dialog-buttons--save"
              [disabled]="!passwordControl.valid || !confirmPasswordControl.valid">Save</button>
      <button mat-raised-button
              color="danger"
              i18n
              mat-dialog-close>Cancel</button>
    </section>
  </form>
</div>
