<form fxLayout="column"
      fxLayoutAlign="space-around stretch"
      [formGroup]="embeddableForm"
      (ngSubmit)="save()"
      novalidate>
  <div class="gd-embed-embed-image-url-dialog"
       fxLayout="column"
       fxLayoutAlign="space-between stretch"
       [dir]="dir$ | async">
    <h2 mat-dialog-title
        i18n="Modal headline">Embed Image Via URL</h2>

    <div class="gd-embed-image-url-dialog__data-wrapper">
      <div>
        <img class="gd-embed-image-url-dialog__preview"
             id="embed-preview"
             [src]="displayImage" />
      </div>

      <div fxLayout="column"
           fxFlex="270px">
        <div>
          <section>
            <mat-form-field class="gd-embed-image-dialog__url-wrapper"
                            fxFlex="100%"
                            appearance="outline">
              <mat-label i18n>URL</mat-label>
              <input matInput
                     type="text"
                     required
                     formControlName="url"
                     autocomplete="off" />
              <mat-error *ngIf="urlControl.hasError('invalidImageUrl')"
                         i18n="Validation message">Invalid image URL</mat-error>
              <mat-error *ngIf="urlControl.hasError('required')"
                         i18n="Validation Message">Image URL is required</mat-error>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field class="gd-embed-image-dialog__caption-wrapper"
                            fxFlex="100%"
                            appearance="outline">
              <mat-label i18n
                         i18n="imageCaption|">Caption</mat-label>
              <input matInput
                     type="text"
                     formControlName="caption"
                     autocomplete="off" />
            </mat-form-field>
          </section>

          <section>
            <mat-form-field class="gd-embed-image-dialog__credit-wrapper"
                            fxFlex="100%"
                            appearance="outline">
              <mat-label i18n="imageCredit|">Credit</mat-label>
              <input matInput
                     type="text"
                     formControlName="credit"
                     autocomplete="off" />
            </mat-form-field>
          </section>

          <section>
            <mat-form-field class="gd-embed-image-dialog__alt-wrapper"
                            fxFlex="100%"
                            appearance="outline">
              <mat-label i18n="imageAltAttribute|">Alt</mat-label>
              <input matInput
                     type="text"
                     formControlName="alt"
                     autocomplete="off" />
            </mat-form-field>
          </section>

          <section>
            <mat-form-field class="gd-embed-image-dialog__styles-wrapper"
                            fxFlex="100%"
                            appearance="outline">
              <mat-label i18n="imageStyle|">Image Style</mat-label>
              <mat-select formControlName="imageStyle">
                <mat-option *ngFor="let style of articleImageStyles"
                            [value]="style.value">
                  {{ style.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>
        </div>
      </div>
    </div>

    <section class="gd-embed-image-dialog-buttons">
      <button mat-raised-button
              color="primary"
              [disabled]="embeddableForm.invalid || embeddableForm.pending"
              type="submit"
              class="gd-embed-image-dialog-buttons--save"
              i18n>Save</button>
      <button mat-raised-button
              color="danger"
              mat-dialog-close
              i18n>Cancel</button>
    </section>
  </div>
</form>
