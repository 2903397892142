import { Component, ViewEncapsulation, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { Input } from '@angular/core';
import { SidenavItem } from './sidenav-item.model';
import { SidenavService } from '../sidenav.service';
import { MixPanelService } from '../../../core/api/mixpanel/mixpanel.service';
import { AppState } from '../../../core/store/app-reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gd-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidenavItemComponent implements OnChanges {
  @Input() item: SidenavItem;
  @Input() currentlyActiveSidenavItem: any;

  @HostBinding('class.open')
  get isOpen() {
    return this.sidenavService.isOpen(this.item);
  }

  @HostBinding('class.sidenav-item') sidenavItemClass = true;
  isItemActive = false;

  constructor(
    private sidenavService: SidenavService,
    private store: Store<AppState>,
    private mixPanelService: MixPanelService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentlyActiveSidenavItem) {
      this.isItemActive = !!this.currentlyActiveSidenavItem[this.item.id];
    }
  }

  toggleDropdown(): void {
    if (this.item.hasSubItems()) {
      this.sidenavService.toggleCurrentlyOpen(this.item);
    }
  }

  // Receives the count of Sub Items and multiplies it with 48 (height of one SidenavItem) to set the height for animation.
  getSubItemsHeight(): string {
    return (this.getOpenSubItemsCount(this.item) * 48) + 'px';
  }

  // Counts the amount of Sub Items there is and returns the count.
  getOpenSubItemsCount(item: SidenavItem): number {
    let count = 0;

    if (item.hasSubItems() && this.sidenavService.isOpen(item)) {
      count += item.subItems.length;

      item.subItems.forEach((subItem) => {
        count += this.getOpenSubItemsCount(subItem);
      });
    }

    return count;
  }

  updateCurrentlyOpenSection() {
    this.sidenavService.nextCurrentlyOpenByRoute(this.item.route);
  }

  sendMixPanelEvent(ev, subItem) {
    this.mixPanelService.trackEvent("MenuItemClicked", {
      menu_item_name: subItem.name,
    });
  }
}
