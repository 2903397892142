import {
  debounceTime,
  take,
  filter,
  map,
  mergeMap,
  catchError,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';
import {
  SET_LOCK,
  LockFailedAction,
  RefreshLockAction,
  CHANGE_DETECTED,
  UpdateLockStateAction,
  REFRESH_LOCK,
  RELEASE_LOCK,
  ReleaseLockSuccessAction,
  ClearRefreshLockIntervalAction,
  ClearCheckLockIntervalAction,
  ClearInactivePeriodTimeoutAction,
  ReleaseLockAction,
} from './lock.actions';
import { LockService } from '../../api/lock/lock.service';
import { getLockState, LockState } from './lock.reducer';
import { AuthService } from '../../api';
import { UsersService } from '../../api/users/users.service';
import { LOG_OUT } from '../auth';

@Injectable()
export class LockEffects {
  isLockRequestInProgress = false;


  setLock$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SET_LOCK),
    debounceTime(100),
    filter(() => {
      // don't assert lock while the session extension is pending
      return !this.authService.isAuthTokenExpired();
    }),
    mergeMap((action: UnsafeAction) => {
      this.isLockRequestInProgress = true;
      this.store.dispatch(new UpdateLockStateAction({ lockServicePayload: action.payload }));
      if(!action.payload.id || !action.payload.type) {
        return of();
      }
      return this.lockService.setLock(action.payload).pipe(
        map(() => {
          this.isLockRequestInProgress = false;
          const updateData = {
            isLockedByAnotherUser: false,
            inactivePeriodTimeout: this.setInactivePeriodTimeout(action.payload),
            refreshLockInterval: this.setRefreshLockInterval(),
          };
          this.setStorageEventsListener();
          setTimeout(() => this.lockService.setIsItemLockedFlag(true), 200);
          return new UpdateLockStateAction(updateData);
        }),
        catchError((error) => {
          this.isLockRequestInProgress = false;
          // TODO: Should be handled in a better way
          if (error.message !== 'Resource locked.') {
            return of(new UpdateLockStateAction({ isLockedByAnotherUser: false }));
          }
          this.lockFormAndDisplayMessage(null, action.payload);
          return of(new UpdateLockStateAction({ isLockedByAnotherUser: true }));
        })
      ) as Observable<UnsafeAction>;
    })
  ));


  refreshLock$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(REFRESH_LOCK),
    filter(() => {
      // don't assert lock while the session extension is pending
      return !this.authService.isAuthTokenExpired();
    }),
    mergeMap(() => {
      return this.store.select(getLockState).pipe(
        take(1),
        tap((state: LockState) => {
          // TODO check if this actually needs to fire an action - most likely dispatch false could be used
          return state.lockServicePayload.id
            ? this.lockItem()
            : this.store.dispatch(new ClearRefreshLockIntervalAction());
        })
      ) as Observable<any>;
    })
  ), { dispatch: false });


  releaseLock$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RELEASE_LOCK, LOG_OUT),
    tap(() => {
      this.lockService.setIsItemLockedFlag(false);
      this.removeStorageEventsListener();
      return (
        (this.isLockRequestInProgress || this.lockService.canReleaseLock()) && this.unlockItem()
      );
    }),
    map(() => new ReleaseLockSuccessAction()),
    catchError((e) => of(new LockFailedAction(e)))
  ));


  changeDetected$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_DETECTED),
    withLatestFrom(this.store.select(getLockState)),
    filter(([, state]) => {
      const handleChange =
        !state.isLockedByAnotherUser &&
        state.lockServicePayload.id &&
        (state.checkLockInterval || state.refreshLockInterval);
      return handleChange;
    }),
    map(([, state]: [any, LockState]) => {
      const updateData: any = {};
      // if user was inactive
      if (state.checkLockInterval) {
        this.store.dispatch(new ClearCheckLockIntervalAction());
        this.lockService.setIsItemLockedFlag(true);
        this.setStorageEventsListener();
        this.lockItem();
        updateData.refreshLockInterval = this.setRefreshLockInterval();
      }
      this.store.dispatch(new ClearInactivePeriodTimeoutAction());
      updateData.inactivePeriodTimeout = this.setInactivePeriodTimeout(state.lockServicePayload);
      return new UpdateLockStateAction(updateData);
    })
  ));

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private lockService: LockService,
    private authService: AuthService,
    private usersService: UsersService
  ) {}

  lockItem() {
    return this.lockService
      .setLock()
      .toPromise()
      .catch((error) => this.handleSetLockErrors(error));
  }

  handleSetLockErrors(error) {
    // TODO: Should be handled in a better way
    if (error.message !== 'Resource locked.') {
      return;
    }
    this.store
      .select(getLockState)
      .pipe(
        take(1),
        map((state) => state.lockServicePayload)
      )
      .subscribe((payload) => {
        this.lockFormAndDisplayMessage(null, payload);
        this.store.dispatch(new ReleaseLockSuccessAction());
      });
  }

  unlockItem() {
    return this.lockService.releaseLock().toPromise();
  }

  setRefreshLockInterval() {
    // 15 min
    const interval = 15 * 60 * 1000;
    return setInterval(() => this.store.dispatch(new RefreshLockAction()), interval);
  }

  setCheckLockInterval(payload) {
    // 30 seconds
    const interval = 30 * 1000;
    return setInterval(() => {
      this.lockService
        .getLock()
        .pipe(filter((data) => !!data))
        .subscribe(
          (data) => {
            const isLockedByAnotherUser = !!data && data.userId !== this.authService.getUserId();
            return isLockedByAnotherUser && this.lockFormAndDisplayMessage(data.userId, payload);
          },
          () => console.warn('Lock check failed!')
        );
    }, interval);
  }

  setInactivePeriodTimeout(payload) {
    // 1 hour
    const interval = 60 * 60 * 1000;
    return setTimeout(() => {
      this.lockService.setIsItemLockedFlag(false);
      this.removeStorageEventsListener();
      this.unlockItem();
      this.store.dispatch(new ClearRefreshLockIntervalAction());
      const checkLockInterval = payload.id ? this.setCheckLockInterval(payload) : true;
      this.store.dispatch(
        new UpdateLockStateAction({ checkLockInterval, inactivePeriodTimeout: null })
      );
    }, interval);
  }

  lockFormAndDisplayMessage(userId = null, payload) {
    this.removeStorageEventsListener();
    const observer = userId ? of({ userId }) : this.lockService.getLock(payload);
    observer
      .pipe(
        filter((data) => !!data),
        mergeMap((data: any) => this.usersService.getUser(data.userId))
      )
      .subscribe((user) => {
        const itemLabel = this.getResourceLabel(payload.type);
        this.snackBar.open(`${itemLabel} locked for editing by ${user.username}!`, $localize`Close`);
        this.store.dispatch(new UpdateLockStateAction({ isLockedByAnotherUser: true }));
      });
  }

  setStorageEventsListener() {
    window.addEventListener('storage', this.handleStorageEvents);
  }

  removeStorageEventsListener() {
    window.removeEventListener('storage', this.handleStorageEvents);
  }

  handleStorageEvents = () => {
    const isLocked = this.lockService.getIsItemLockedFlag();
    if (!isLocked) {
      this.lockService.setIsItemLockedFlag(true);
      setTimeout(() => this.lockItem(), 100);
    }
  };

  getResourceLabel(type) {
    switch(type) {
      case 'article':
        return 'Article';
      case 'collection':
        return 'Collection';
      case 'live_report':
        return 'Live Reporting';
      case 'live_report_post':
        return 'Post';
      case 'live_report_summary':
        return 'Summary';
      default:
        return 'Resource';
    }
  }
}
