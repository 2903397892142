import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { ImageCropService } from '../image-crop.service';
import { getUserFriendlyRatio, ImagesUploadService } from '../../../core/api';
import { debounceTime, filter, map, mergeMap, take } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { inRange } from 'lodash';
import { CdkDrag } from '@angular/cdk/drag-drop';

declare const Cropper;

@Component({
  selector: 'gd-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() image;
  @Input() imageSizeData;
  @Input() extraCropperOptions;

  cropper;
  cropperInitialized$ = new EventEmitter();
  cropperInitialized = false;
  cropperUpdate$ = new EventEmitter();
  previewEl;

  cropperConfig = {
    autoCrop: true,
    autoCropArea: 1,
    viewMode: 2,
    dragMode: 'move',
    background: false,
    movable: false,
    scalable: false,
    rotatable: true,
    zoomable: true,
    zoomOnWheel: false,
    responsive: true,
    restore: true,
    guides: true,
    center: false,
    highlight: false,
    checkOrientation: true,
    cropBoxMovable: true,
    cropBoxResizable: true,
    preview: null,
    modal: true
  };

  mainCropper = false;
  mainCropperBox = {};
  cropping$ = new EventEmitter();

  cropboxState$;
  offsetState$;
  cropActions$;
  maxOutputDimensions: { width?: number, height?: number } = {}

  componentSubs: Subscription = new Subscription();

  getUserFriendlyRatio = getUserFriendlyRatio;

  canvasData = null;
  // helper variable for detecting simple mouse clicks
  simpleMouseClick = false;
  focalPointPosition$ = this.cropService.relativeFocalPoint$.asObservable().pipe(
    filter(() => !!this.mainCropper), map(fp => {
    if (!fp || !this.canvasData) {
      return null;
    }
    return {
      x: (fp.x * this.canvasData.width),
      y: (fp.y * this.canvasData.height)
    }
  }));
  focalPointMoving;

  get focalPointInsideOfCroparea() {
    return this.cropService.focalPointInsideOfCroparea;
  }

  constructor(
    private elRef: ElementRef,
    private cropService: ImageCropService,
    private uploadService: ImagesUploadService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (!this.imageSizeData) {
      this.mainCropper = true;
    }

    if (this.imageSizeData) {
      const cropPositionLabel = this.cropService.getCropboxPositionLabel(this.imageSizeData['crop_position']);
      this.imageSizeData = {
        ...this.imageSizeData,
        resize_method_label: normalizeString(this.imageSizeData['resize_method']),
        crop_position_label: cropPositionLabel ? cropPositionLabel.value : ''
      };
    }
  }

  ngAfterViewInit() {
    // Set canvas boundaries before cropper is initialized
    if (this.mainCropper) {
      const canvasWrapper = this.elRef.nativeElement.querySelector('.gd-image-cropper.main');
      const canvasWrapperWidth = +window.getComputedStyle(canvasWrapper).width.replace('px', '');
      canvasWrapper.querySelector('.gd-image-cropper__canvas').style.maxWidth = `${canvasWrapperWidth}px`;
    }

    if (!this.cropper) {
      this.initializeCropper();
    }
  }

  initializeCropper() {
    const imageElement = this.elRef.nativeElement.querySelector('.gd-image-cropper__canvas-image');
    if (this.image) {
      imageElement.src = this.image.dataURL;
    } else {
      imageElement.src = '';
    }

    let aspectRatio = null;
    if (!this.mainCropper) {
      aspectRatio = this.imageSizeData.width / this.imageSizeData.height;
      const original = this.image.naturalDimensions;
      this.previewEl = this.elRef.nativeElement.querySelector(`div#crop-preview-${this.imageSizeData.key}`);

      const { width, height } = this.imageSizeData;
      // a number 300 is a max width/height of crop preview
      const scaleFactor = 300 / (width > height ? width : height);
      // prepare block element showing desired (scaled) image dimensions
      this.previewEl.parentElement.style.width = `${width * scaleFactor}px`;
      this.previewEl.parentElement.style.height = `${height * scaleFactor}px`;

      // prepare main image wrapper with max (scaled) image dimensions
      // if resize method is "crop from position" or "scale to fill", then set output image to be able to reach desired dimensions
      this.previewEl.style.width = `${width * scaleFactor}px`;
      this.previewEl.style.height = `${height * scaleFactor}px`;
      this.maxOutputDimensions = { width: this.imageSizeData.width, height: this.imageSizeData.height };

      if (this.imageSizeData.resize_method === 'scale_to_fit') {
        // if resize method is scale to fit, then keep original ratio and calculate max output image dimensions
        aspectRatio = original.width / original.height;
        const maxWidth = Math.round(width <= original.width ? width : original.width);
        const maxHeight = Math.round(height <= original.height ? height : original.height);
        this.maxOutputDimensions = calculateCropAreaDimensions(maxWidth, aspectRatio, maxHeight);
        this.previewEl.style.width = `${this.maxOutputDimensions.width * scaleFactor}px`;
        this.previewEl.style.height = `${this.maxOutputDimensions.height * scaleFactor}px`;
      }
    }

    this.cropper = new Cropper(imageElement, {
      ...this.cropperConfig,
      preview: this.imageSizeData ? `#crop-preview-${this.imageSizeData.key}` : '',
      minCropBoxWidth: this.mainCropper ? 0 : 20,
      cropBoxResizable: this.mainCropper || (this.imageSizeData && this.imageSizeData.resize_method !== 'crop_from_position'),
      ready: () => {
        this.canvasData = this.cropper.getCanvasData();
        // Update generated crops once croppers are ready
        if (!this.mainCropper) {
          this.cropper.setAspectRatio(aspectRatio);
          // If using pre-defined crop set fixed cropper
          if (this.imageSizeData.resize_method === 'crop_from_position') {
            this.initializeFixedCropper();
          }

          if (!this.imageSizeData.usePredefined) {
            // If there's cropbox data on image already, apply those parameters (re-crop)
            if (this.imageSizeData.cropbox) {
              const { x, y, width, height } = this.imageSizeData.cropbox;
              this.cropper.setData({ width, height });
              this.cropper.setData({ x, y });
            }

            // If there's no cropbox data on img, set current cropbox data as initial cropbox params
            if (!this.imageSizeData.cropbox) {
              this.imageSizeData.cropbox = this.cropper.getData();
              this.imageSizeData.initialCropbox = this.cropper.getData();
            }
          }
          // Start tracking cropbox state
          this.trackCropboxState();
          this.addActiveCropperEventsListener();
          if (this.imageSizeData.resize_method === 'scale_to_fill') {
            this.addFocalPointListener();
          }
        }
        // Register croppers as initialized
        this.cropperInitialized = true;
        this.cropperInitialized$.emit(true);

      },
      cropstart: this.handleCropStartEvent.bind(this),
      cropend: this.handleCropEndEvent.bind(this),
      cropmove: this.handleCropMoveEvent.bind(this)
    });

  }

  trackCropboxState() {
    // Get offset of position for main cropper box and image size croppers
    this.offsetState$ = this.cropService.getOffset()
      .pipe(
        filter(() => !this.mainCropper && !!this.cropper),
        mergeMap(offset => this.cropService.getCropboxState().pipe(take(1), map(cropbox => [offset, cropbox]))),
      )
      .subscribe(([offset, mainCropbox]) => {
        this.updateCropper(offset, mainCropbox);
        this.updateCropPreview();
      });

    // Get latest crop service actions and act based on type
    this.cropActions$ = this.cropService.getCropActions()
      .subscribe((action: any) => {
        switch (action.type) {
          case this.cropService.actionTypes.MAIN_CROP_END: {
            if (!this.mainCropper && this.imageSizeData) {
              this.imageSizeData.cropbox = this.cropper ? this.cropper.getData() : {};
            }
          }
        }
      });
  }

  handleCropStartEvent() {
    this.simpleMouseClick = true;
    if (this.mainCropper) {
      this.cropService.dispatch({
        type: this.cropService.actionTypes.UPDATE_MAIN_CROPPER_POSITION,
        payload: { ...this.cropper.getData() }
      });

      this.cropping$.emit(true);
    }
  }

  handleCropEndEvent(event) {
    if (this.mainCropper) {
      let cropbox = { ...this.cropper.getData() };
      // relative focal point
      let relFP = null;
      if (this.simpleMouseClick) {
        const { layerX, layerY } = event.detail.originalEvent;
        relFP = this.getRelativeFocalPoint(layerX, layerY);
        const fpOutside =
        !inRange(relFP.x * this.canvasData.naturalWidth, cropbox.x, cropbox.x + cropbox.width) ||
        !inRange(relFP.y * this.canvasData.naturalHeight, cropbox.y, cropbox.y + cropbox.height);
        if (fpOutside) {
          const coords = relFP ? this.getCropAreaCoordsByFocalPoint(relFP, cropbox, this.canvasData) : {};
          cropbox = { ...cropbox, ...coords };
          this.cropper.setData(cropbox);
          this.cropService.dispatch({
            type: this.cropService.actionTypes.UPDATE_MAIN_CROPPER_OFFSET,
            payload: cropbox
          });
        }
      }
      this.cropService.dispatch({
        type: this.cropService.actionTypes.UPDATE_MAIN_CROPPER_POSITION,
        payload: cropbox
      });

      if (relFP) {
        this.cropService.setRelativeFocalPoint(relFP.x, relFP.y);
      }

      // When main crop ends => check if offset is resetted, update positions of imageSizeData
      this.cropService.dispatch({
        type: this.cropService.actionTypes.MAIN_CROP_END
      });

      this.cropping$.emit(false);
    }

    // CROP BY RATIO OFF: If image crop moved as individual  dispatch action to update that crop and lock for modification
    if (!this.mainCropper) {
      this.imageSizeData.cropbox = this.cropper.getData();
      if (!this.cropService.cropByRatioEnabled) {
        this.imageSizeData.modified = true;
      }

      if (this.imageSizeData.cropMethod === 'preDefined') {
        this.imageSizeData.usePredefined = false;
      }

      this.cropper.setData(this.imageSizeData.cropbox);
      this.cropperUpdate$.emit(true);
    }
    this.simpleMouseClick = false;
  }

  handleCropMoveEvent() {
    this.simpleMouseClick = false;
    if (this.mainCropper) {
      const cropperData = { ...this.cropper.getData() };
      // check if focal point exists and if it's still within the selected crop area
      const relFP = this.cropService.relativeFocalPoint$.getValue();
      if (relFP) {
        const absoluteFP = { x: relFP.x * this.canvasData.naturalWidth, y: relFP.y * this.canvasData.naturalHeight };
        const xRange = [cropperData.x, cropperData.x + cropperData.width];
        const yRange = [cropperData.y, cropperData.y + cropperData.height];
        this.cropService.focalPointInsideOfCroparea = inRange(absoluteFP.x, xRange[0], xRange[1]) && inRange(absoluteFP.y, yRange[0], yRange[1]);
      }
      this.cropService.dispatch({
        type: this.cropService.actionTypes.UPDATE_MAIN_CROPPER_OFFSET,
        payload: { ...this.cropper.getData() }
      });
    }

    if (!this.mainCropper) {
      this.updateCropPreview();
      if (!this.imageSizeData.modified) {
        const { renditionId: cropperId, group } = this.imageSizeData;
        this.cropService.triggerActiveCropperChangeEvent({ cropperId, group, offset: { ...this.cropper.getData() } });
      }
    }
  }

  updateCropper(offset, mainCropbox, applyFocalPoint = true) {
    const offsetNotNull = Object.values(offset).some(val => val !== 0);
    if (this.imageSizeData.modified || !offsetNotNull) {
      return;
    }

    const previousCropperBox = this.imageSizeData.cropbox;
    let newWidth, newHeight, newXPosition, newYPosition;

    if (this.imageSizeData.resize_method === 'crop_from_position') {
      newWidth = previousCropperBox.width;
      newHeight = previousCropperBox.height;
      const { x, y } = getCropAreaPositions(this.imageSizeData.crop_position, mainCropbox, previousCropperBox);
      newXPosition = x;
      newYPosition = y;
    } else {
      const ratio = previousCropperBox.width / previousCropperBox.height;
      const { width, height } = calculateCropAreaDimensions(mainCropbox.width, ratio, mainCropbox.height);
      newWidth = width;
      newHeight = height;
      newXPosition = mainCropbox.x + ((mainCropbox.width - newWidth) / 2);
      newYPosition = mainCropbox.y + ((mainCropbox.height - newHeight) / 2);
    }
    this.cropper.setData({
      width: newWidth,
      height: newHeight,
      x: newXPosition,
      y: newYPosition
    });
    this.updateCropPreview();

    if (applyFocalPoint && this.focalPointInsideOfCroparea) {
      this.cropService.applyFocalPoint();
    }
  }

  initializeFixedCropper() {
    if (this.imageSizeData.resize_method === 'crop_from_position') {
      this.imageSizeData['imageLoading'] = true;
      const cropboxPosition = { left: 0, top: 0 };
      const predefinedCropPosition = this.imageSizeData.crop_position;

      const naturalDimensions = this.image.naturalDimensions;
      if ((naturalDimensions.width < +this.imageSizeData.width) && (naturalDimensions.height < +this.imageSizeData.height)) {
        this.imageSizeData.smallerThenTarget = true;
        this.cropper.setAspectRatio(NaN);
      }

      this.cropper.setData({ width: +this.imageSizeData.width, height: +this.imageSizeData.height });

      const cropbox = this.cropper.getCropBoxData();
      const container = this.cropper.getContainerData();
      if (this.imageSizeData.cropbox) {
        const { x, y } = this.imageSizeData.cropbox;
        cropboxPosition.left = container.width / (naturalDimensions.width / x);
        cropboxPosition.top = container.height / (naturalDimensions.height / y);
        this.cropper.setCropBoxData(cropboxPosition);
        this.imageSizeData.initialCropbox = this.cropper.getData();
        return;
      }
      switch (predefinedCropPosition) {
        case 'top_left':
          cropboxPosition.left = 0;
          cropboxPosition.top = 0;
          break;

        // Left Center
        case 'left':
          cropboxPosition.left = 0;
          cropboxPosition.top = (container.height / 2) - (cropbox.height / 2);
          break;

        case 'bottom_left':
          cropboxPosition.left = 0;
          cropboxPosition.top = container.height - cropbox.height;
          break;

        // Center Top
        case 'top':
          cropboxPosition.left = (container.width / 2) - (cropbox.width / 2);
          cropboxPosition.top = 0;
          break;

        // Center by x and y
        case 'center':
          cropboxPosition.left = (container.width / 2) - (cropbox.width / 2);
          cropboxPosition.top = (container.height / 2) - (cropbox.height / 2);
          break;

        // Center bottom
        case 'bottom':
          cropboxPosition.left = (container.width / 2) - (cropbox.width / 2);
          cropboxPosition.top = container.height - cropbox.height;
          break;

        // Top Right
        case 'top_right':
          cropboxPosition.left = container.width - cropbox.width;
          cropboxPosition.top = 0;
          break;

        // Center right
        case 'right':
          cropboxPosition.left = container.width - cropbox.width;
          cropboxPosition.top = (container.height / 2) - (cropbox.height / 2);
          break;

        // Bottom right
        case 'bottom_right':
          cropboxPosition.left = container.width - cropbox.width;
          cropboxPosition.top = container.height - cropbox.height;
          break;
      }
      this.cropper.setCropBoxData(cropboxPosition);
      this.imageSizeData.initialCropbox = this.cropper.getData();
    }
  }

  updateCropPreview() {
    // Updates preview for formats only with 'scale_to_fit' and 'crop_from_position' as resize method
    if (!this.imageSizeData || !['crop_from_position', 'scale_to_fit'].includes(this.imageSizeData.resize_method)) {
      return;
    }
    const cropData = this.cropper.getData();

    const { width: maxWidth, height: maxHeight } = this.maxOutputDimensions;
    const desiredDimensionsNotMatched = maxWidth < +this.imageSizeData.width || maxHeight < +this.imageSizeData.height
        || cropData.width < +this.maxOutputDimensions.width || cropData.height < +this.maxOutputDimensions.height;

    if (desiredDimensionsNotMatched) {
      this.applyCropboxFrameStyles({ 'background-color': '#FDD835', 'outline-color': '#FDD835' });
      this.renderer.setStyle(this.previewEl.parentElement, 'border-color', `#fdd835`);
    } else {
      this.applyCropboxFrameStyles({ 'background-color': '#39F', 'outline-color': '#39F' });
      this.renderer.setStyle(this.previewEl.parentElement, 'border-color', `rgba(27, 31, 35, 0.15)`);
    }

    if (cropData.width >= +maxWidth) {
      this.renderer.setStyle(this.previewEl, 'transform', `scale(1)`);
      return;
    }
    let widthDifference = (maxWidth - cropData.width);
    widthDifference = (widthDifference / maxWidth) * 100;

    if (this.imageSizeData.resize_method === 'crop_from_position' && this.imageSizeData.smallerThenTarget) {
      widthDifference = 0;
    }

    const shrinkFactor = (widthDifference / 100);
    const scaleRatio = 1 - shrinkFactor;

    this.renderer.setStyle(this.previewEl, 'transform', `scale(${scaleRatio})`);
  }

  setCropboxPosition(data) {
    const x = data.x;
    const y = data.y;
    let width = data.width;
    let height = data.height;

    if (!width && !height) {
      const cropperData = this.cropper.getData();
      width = cropperData.width;
      height = cropperData.height;
    }

    this.cropper.setData({ width, height, x, y });
    this.updateCropPreview();
  }

  setCropboxPositionFromPercentage({ x, y, width, height }) {
    const { naturalWidth, naturalHeight } = this.cropper.getCanvasData();
    const aspectRatio = naturalWidth / naturalHeight;
    const newWidth = (naturalWidth * width) / 100;
    const newHeight = newWidth / aspectRatio;

    const newX = +((naturalWidth * x) / 100).toFixed(0);
    const newY = +((naturalHeight * y) / 100).toFixed(0);

    // If it's not main cropper, its image size cropper then :)
    if (!this.mainCropper) {
      if (this.imageSizeData.cropMethod !== 'preDefined') {
        this.cropper.setData({ width: newWidth, height: newHeight, x: newX, y: newY });
      } else {
        this.cropper.setData({ x: newX, y: newY });
      }

      this.imageSizeData.cropbox = this.cropper.getData();
      this.cropperUpdate$.emit(true);
    }
  }

  applyCropboxFrameStyles(styles) {
    const cropFrameEl = this.elRef.nativeElement.querySelector('.cropper-crop-box');
    const cropperViewBoxEl = cropFrameEl.querySelector('.cropper-view-box');
    const cropFrameDots = cropFrameEl.querySelectorAll('.cropper-point');

    this.applyElementStyles(cropperViewBoxEl, styles);

    cropFrameDots.forEach(dotEl => {
      this.applyElementStyles(dotEl, styles);
    });
  }

  handleCropLockEvent(event) {
    // TODO: Find position/dimensions relative to main cropper and update cropper
    const modified = event.target.innerText === 'lock_open' ? true : false;
    this.imageSizeData = {
      ...this.imageSizeData,
      modified
    };
    this.cropperUpdate$.emit(true);
  }

  getCroppedCanvasAsImg({ width, height }) {
    return new Promise(resolve => {
      this.cropper.getCroppedCanvas({
        width: width,
        height: height,
        fillColor: '#333',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'low',
      }).toBlob((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        resolve(blobUrl);
      });
    });
  }

  applyElementStyles(el: HTMLElement, styles: {}) {
    Object.entries(styles).forEach(([property, value]: any) => {
      this.renderer.setStyle(el, property, value);
    });
  }

  addActiveCropperEventsListener() {
    const { renditionId: cropperId, group, modified: locked } = this.imageSizeData;
    this.componentSubs.add(
      this.cropService.activeCropperChangeEvents$
      .pipe(filter(data => {
        const cropByRatioEnabled = this.cropService.cropByRatioEnabled;
        return cropByRatioEnabled && !!data && data.cropperId !== cropperId && data.group === group && !locked;
      }))
      .subscribe(data => {
        this.updateCropper(data.offset, data.offset, false);
        this.updateCropPreview();
        this.imageSizeData.cropbox = this.cropper.getData();
      })
    );
  }

  getRelativeFocalPoint(x, y) {
    const xRange = [this.canvasData.left, this.canvasData.left + this.canvasData.width];
    const yRange = [this.canvasData.top, this.canvasData.top + this.canvasData.height];
    const focalPointAllowed = inRange(x, xRange[0], xRange[1]) && inRange(y, yRange[0], yRange[1]);
    if (!focalPointAllowed) {
      return null;
    }
    return { x: (x - this.canvasData.left) / this.canvasData.width, y: (y - this.canvasData.top) / this.canvasData.height };
  }

  removeFocalPoint(event) {
    if (!this.focalPointMoving) {
      this.cropService.removeRelativeFocalPoint();
    }
  }

  addFocalPointListener() {
    this.componentSubs.add(
      this.cropService.relativeFocalPoint$.asObservable().pipe(
        filter(fp => !!fp && !this.imageSizeData.modified),
      ).subscribe(fp => {
        const cropperData = this.cropper.getData();
        const { x, y } = this.getCropAreaCoordsByFocalPoint(fp, cropperData, this.canvasData);
        this.cropper.setData({ ...cropperData, x, y });
      })
    );
  }

  getCropAreaCoordsByFocalPoint(relativeFP, croparea, canvas) {
    const absoluteFP = { x: relativeFP.x * canvas.naturalWidth, y: relativeFP.y * canvas.naturalHeight };
    const mainCropbox: any = this.cropService.cropbox$.getValue();
    const minX = this.mainCropper ? 0 : mainCropbox.x;
    const minY = this.mainCropper ? 0 : mainCropbox.y;
    const x =
      absoluteFP.x - (croparea.width / 2) >= minX
        ? absoluteFP.x - (croparea.width / 2) : minX;
    const y =
      absoluteFP.y - (croparea.height / 2) >= minY
        ? absoluteFP.y - (croparea.height / 2) : minY;
    return { x, y };
  }

  ngOnDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
      this.imageSizeData = null;
    }

    if (this.cropboxState$) {
      this.cropboxState$.unsubscribe();
      this.offsetState$.unsubscribe();
      this.cropActions$.unsubscribe();
    }
    this.componentSubs.unsubscribe();
  }

  handleFocalPointMoveEvent(event) {
    const el = document.querySelector('.gd-image-cropper__focal-point-wrapper');
    const elRect = el.getBoundingClientRect();
    const dropPoint = { ...event.dropPoint };
    // handle the case when the event dropPoint is outside of the main cropper (X axis)
    if (!inRange(dropPoint.x, elRect.left, elRect.left + elRect.width)) {
      dropPoint.x = dropPoint.x < elRect.left ? elRect.left : elRect.left + elRect.width;
      // move the focal point for 11px to position it properly inside the croparea
      dropPoint.x += dropPoint.x === elRect.left ? 11 : -11;
    }
    // handle the case when the event dropPoint is outside of the main cropper (Y axis)
    if (!inRange(dropPoint.y, elRect.top, elRect.top + elRect.height)) {
      dropPoint.y = dropPoint.y < elRect.top ? elRect.top : elRect.top + elRect.height;
      // move the focal point for 11px to position it properly inside the croparea
      dropPoint.y += dropPoint.y === elRect.top ? 11 : -11;
    }
    const relFP = {
      x: (dropPoint.x - elRect.left) / this.canvasData.width,
      y: (dropPoint.y - elRect.top) / this.canvasData.height
    };
    let cropbox = { ...this.cropper.getData() };
    const fpOutside =
      !inRange(relFP.x * this.canvasData.naturalWidth, cropbox.x, cropbox.x + cropbox.width) ||
      !inRange(relFP.y * this.canvasData.naturalHeight, cropbox.y, cropbox.y + cropbox.height);
    if (fpOutside) {
      const coords = this.getCropAreaCoordsByFocalPoint(relFP, cropbox, this.canvasData);
      cropbox = { ...cropbox, ...coords };
      this.cropper.setData(cropbox);
      this.cropService.dispatch({
        type: this.cropService.actionTypes.UPDATE_MAIN_CROPPER_OFFSET,
        payload: cropbox,
      });
    }
    this.cropService.setRelativeFocalPoint(relFP.x, relFP.y);
    setTimeout(() => this.focalPointMoving = false, 100);
  }

}

function normalizeString(string = '') {
  return string
    .split('_')
    .map((word) => word.replace(/^\w/, (char) => char.toUpperCase()))
    .join(' ');
}

function getCropAreaPositions(position, container, cropbox) {
  position = position || 'center';
  const cropPositionMap = {
    'top_left': ['left', 'top'],
    'left': ['left', 'center'],
    'bottom_left': ['left', 'bottom'],
    'top': ['center', 'top'],
    'center': ['center', 'center'],
    'bottom': ['center', 'bottom'],
    'top_right': ['right', 'top'],
    'right': ['right', 'center'],
    'bottom_right': ['right', 'bottom'],
  };

  const [xRule, yRule] = cropPositionMap[position];
  const x = calculateCropAreaCoordinate(xRule, container.x, container.width, cropbox.width);
  const y = calculateCropAreaCoordinate(yRule, container.y, container.height, cropbox.height);
  return { x, y };
}

function calculateCropAreaCoordinate(position, startingPoint, containerSize, cropboxSize) {
  switch (position) {
    case 'left':
    case 'top':
      return startingPoint;
    case 'right':
    case 'bottom':
      return startingPoint + containerSize - cropboxSize;
    default:
      return startingPoint + (containerSize / 2) - (cropboxSize / 2);
  }
}

function calculateCropAreaDimensions(width, ratio, maxHeight) {
  const height = Math.floor(width / ratio);
  if (height <= maxHeight) {
    return { width, height };
  }
  return calculateCropAreaDimensions(--width, ratio, maxHeight);
}
