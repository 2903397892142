import { Injectable } from '@angular/core';
import { BehaviorSubject , Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';

interface CropOffset {
  x: number,
  y: number,
  width: number,
  height: number,
  rotate?: number
}

@Injectable({
  providedIn: 'root'
})
export class ImageCropService {

  cropbox = {};
  cropbox$ = new BehaviorSubject(this.cropbox);

  // the absolute X coordinate =  x * canvas_width
  // the absolute Y coordinate =  y * canvas_height
  relativeFocalPoint$ = new BehaviorSubject<{ x: number, y: number }>(null);
  focalPointInsideOfCroparea = false;

  // Moving offset
  initialOffset = { x: 0, y: 0, width: 0, height: 0 };
  offset = { x: 0, y: 0, width: 0, height: 0 };
  offset$ = new BehaviorSubject(<any>this.initialOffset);

  latestAction$ = new Subject();
  actionTypes = {
    UPDATE_MAIN_CROPPER_POSITION: '[Image Crop] Set main cropper position',
    UPDATE_MAIN_CROPPER_OFFSET: '[Image Crop] Set main cropper position offset',
    RESET_MAIN_CROPPER_OFFSET: '[Image Crop] Reset main cropper offset',
    MAIN_CROP_END: '[Image Crop] Main cropper moving end.'
  };

  cropPositions = [
    { key: 'top_left', value: $localize`Left Top` },
    { key: 'left', value: $localize`Left Center` },
    { key: 'bottom_left', value: $localize`Left Bottom` },
    { key: 'top', value: $localize`Center Top` },
    { key: 'center', value: $localize`Center` },
    { key: 'bottom', value: $localize`Center Bottom` },
    { key: 'top_right', value: $localize`Right Top` },
    { key: 'right', value: $localize`Right Center` },
    { key: 'bottom_right', value: $localize`Right bottom` }
  ];

  activeCropperChangeEvents: Subject<any> = new Subject();
  cropByRatioEnabled = false;

  get activeCropperChangeEvents$() {
    return this.activeCropperChangeEvents.asObservable();
  }

  constructor(
    private store: Store<AppState>
  ) {
    this.handleCropEvents();
  }

  getCropboxState() {
    return this.cropbox$.asObservable();
  }

  getOffset() {
    return this.offset$.asObservable();
  }

  getCropActions() {
    return this.latestAction$.asObservable();
  }

  dispatch(action) {
    this.latestAction$.next(action);
  }

  handleCropEvents() {
    this.getCropActions().subscribe((action: any) => {
      switch (action.type) {
        case this.actionTypes.UPDATE_MAIN_CROPPER_POSITION:
          const newCropboxData = action.payload;
          this.cropbox = { ...this.cropbox, ...newCropboxData };
          this.cropbox$.next(this.cropbox);
          break;

        case this.actionTypes.UPDATE_MAIN_CROPPER_OFFSET: {
          const currentPosition = action.payload;
          this.cropbox$.next({ ...currentPosition });

          this.offset.x = currentPosition.x - this.cropbox['x'];
          this.offset.y = currentPosition.y - this.cropbox['y'];

          let widthDiff = currentPosition.width - this.cropbox['width'];
          widthDiff = -(widthDiff / this.cropbox['width']) * 100;

          let heightDiff = currentPosition.height - this.cropbox['height'];
          heightDiff = -(heightDiff / this.cropbox['height']) * 100;

          this.offset.width = widthDiff;
          this.offset.height = heightDiff;

          this.offset$.next(this.offset);
          break;
        }

        default:
          break;
      }
    });
  }

  getCropboxPositions() {
    return this.cropPositions;
  }

  getCropboxPositionLabel(key) {
    return this.cropPositions.find((position: any) => position.key === key);
  }

  resetOffset() {
    this.offset = { ...this.initialOffset };
    this.offset$.next(this.offset);
  }

  resetCropbox() {
    this.cropbox = { };
    this.cropbox$.next(this.cropbox);
  }

  resetCropParams() {
    this.resetOffset();
    this.resetCropbox();
    this.activeCropperChangeEvents.next(null);
    this.setCropByRatioFlag(false);
    this.removeRelativeFocalPoint();
  }

  triggerActiveCropperChangeEvent(payload: { cropperId: string, group: string, offset: CropOffset }) {
    this.activeCropperChangeEvents.next(payload);
  }

  setCropByRatioFlag(enabled) {
    this.cropByRatioEnabled = enabled;
  }

  setRelativeFocalPoint(x, y) {
    this.relativeFocalPoint$.next({ x, y });
    this.focalPointInsideOfCroparea = true;
  }

  removeRelativeFocalPoint() {
    this.relativeFocalPoint$.next(null);
    this.focalPointInsideOfCroparea = false;
  }

  applyFocalPoint() {
    this.relativeFocalPoint$.next(this.relativeFocalPoint$.getValue());
  }

}
