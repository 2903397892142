<ng-container>
  <section [class.--read-only-simple-input]="readOnlyMode">
    <!--Single Selection-->
    <mat-form-field *ngIf="fieldConfig.inputType === 'single'"
                    [style.width.%]="width"
                    appearance="outline">
      <mat-label>{{ fieldConfig?.label }}</mat-label>
      <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  class="gd-select-field__selection-icon question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above" matSuffix fontSet="fal"
                  fontIcon="fa-question-circle"></mat-icon>
      <mat-select [formControl]="fieldControl"
                  [required]="isFormControlRequired"
                  (selectionChange)="handleSelectionChange($event)">
        <mat-option *ngIf="!isFormControlRequired && !readOnlyMode"
                    class="gd-select-field__no-selection"
                    [disabled]="!fieldControl.value">
          <span i18n>No selection</span>
          <mat-icon>backspace</mat-icon>
        </mat-option>
        <ng-container *ngFor="let option of (dataSource$ | async)">
          <mat-option *ngIf="!readOnlyMode || option.id === fieldControl.value"
                      (selectionChange)="handleSelectionChange($event)"
                      [value]="option.id"
                      [style]="readOnlyMode ? 'display: none;' : ''">
            {{ option.name || option.label }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="fieldControl.hasError('required')">{{ fieldConfig?.label }} is required.</mat-error>
      <mat-hint *ngIf="dataSourceMessage"
                [class.mat-error]="dataSourceMessageType === 'error'">
        {{ dataSourceMessage }}
      </mat-hint>
    </mat-form-field>
    <!--End Single Selection-->

    <!--Multiple Selection-->
    <mat-form-field *ngIf="fieldConfig.inputType === 'multiple'"
                    [style.width.%]="width"
                    appearance="outline">
        <mat-label>{{ fieldConfig?.label }}</mat-label>
        <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
              class="gd-select-field__selection-icon question-icon"
              [matTooltip]="infoTooltip"
              matTooltipPosition="above" matSuffix fontSet="fal"
              fontIcon="fa-question-circle"></mat-icon>
        <mat-select [formControl]="fieldControl"
                    [required]="isFormControlRequired"
                    multiple>
          <ng-container *ngFor="let option of (dataSource$ | async)">
            <mat-option [style]="readOnlyMode ? 'display: none;' : ''"
                        [value]="option.id">
              {{ option.name || option.label }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="fieldControl.hasError('required')">{{ fieldConfig?.label }} is required.</mat-error>
        <mat-hint *ngIf="dataSourceMessage"
                  [class.mat-error]="dataSourceMessageType === 'error'">
          {{ dataSourceMessage }}
        </mat-hint>
      </mat-form-field>
    <!--End Multiple Selection-->
  </section>
</ng-container>
