import { AbstractControl } from "@angular/forms";

/**
 * To avoid conflict with system defined fields we have, like `__sysData`, we prevent the users
 * form having custom fields which start with double underscore (`__`)
 */
export function doubleUnderscorePrefixValidator(selectedKeyControl: AbstractControl) {
  return hasDoubleUnderscorePrefix(selectedKeyControl.value)
    ? { doubleUnderscorePrefix: true }
    : null;
}

// simple string test function to check if the value begins with a `__`
export function hasDoubleUnderscorePrefix(value: string) {
  if (value === '__') {
    return true;
  }
  const startsWithDoubleUnderscorePattern = /^(?!__[^_]).*/;
  return !startsWithDoubleUnderscorePattern.test(value);
}
