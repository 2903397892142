import { Status } from "../article/article.model";


export interface Collection {
  id: number;
  glideId?: string;
  createdAt: number;
  createdBy?: number;
  updatedAt?: number;
  updatedBy?: number;
  firstPublishedAt?: number;
  firstPublishedBy?: number;
  lastPublishedAt?: number;
  lastPublishedBy?: number;
  overrideUpdatedAt?: number;
  latestRevision: CollectionRevision;
  publishedRevision?: CollectionRevision;
  workingRevision: CollectionRevision;
  stagedRevisionId?: number;
  publishedRevisionId?: number;
  latestRevisionId?: number;
  transmitUpdatedAt?: number;
  revisions: CollectionRevision[];
  isLocked: boolean;
}

export interface CollectionRevision {
  id: number;
  name: string;
  catchline?: string;
  description: string;
  publishedAt?: number;
  transmitUpdatedAt?: number;
  type: { id: number, name: string };
  status: Status;
  itemCount: number;
  primaryTaxonomy?: { id: number, name: string };
  taxonomies: any[];
  authors: any[];
  isLocked: boolean;
  stagedRevisionId?: number;
  publishedRevisionId?: number;
  latestRevisionId?: number;

  promoDetails: any;
  items: any[];
  url?: string;
  seo?: any;
  socialSharingData?: any;
  customTypeData?: any;
  image: { id: string, url: string };
  createdAt: number;
  publishFrom?: number;
  publishTo?: number;
}
export interface ActiveCollectionState {
  id: number;
  glideId: string;

  createdAt: number;
  updatedAt: number;
  overrideUpdatedAt?: boolean;
  firstPublishedAt?: number;
  lastPublishedAt?: number;
  transmitUpdatedAt?: number;

  workingRevision: any;
  activeRevisionId: number;
  publishedRevisionId: number;
  scheduledRevisionId: number;

  collectionRevisions: any[];
  collectionRevisionMap: any;

  loaded: boolean;
  loading: boolean;
}

export function getEmptyActiveCollection({
  loaded = false,
  loading = false,
  taxonomies = [],
  typeId = null
}): ActiveCollectionState {
  return {
    id: null,
    glideId: null,
    createdAt: null,
    updatedAt: null,
    overrideUpdatedAt: false,
    transmitUpdatedAt: null,

    collectionRevisionMap: {},
    collectionRevisions: [],

    activeRevisionId: null,
    publishedRevisionId: null,
    scheduledRevisionId: null,
    workingRevision: {
      type: { id: typeId },
      taxonomies,
      items: [],
      seo: {},
      authors: []
    },
    loaded,
    loading
  };
}
