<div *ngIf="widgetFormGroup && customFieldsList"
     [formGroup]="typecastAbstractToFormGroup(widgetFormGroup)"
     [fxLayout]="canDisplayTwoColumns ? 'row' : 'column'"
     fxLayoutAlign="space-between"
     fxLayoutGap="20px">
  <div [fxFlex]="canDisplayTwoColumns ? '50%' : '100%'"
       fxLayout="column"
       *ngFor="let col of (twoColumnLayoutAllowed ? [1, 2] : [1]); let colIndex = index;">
    <ng-container *ngFor="let field of customFieldsList; let i = index;">
      <ng-container [ngSwitch]="field.fieldType"
                    *ngIf="!twoColumnLayoutAllowed || i%2 === colIndex">
        <!-- maybe use this for the case when we have list of groups? -->
        <div *ngSwitchCase="'list'">
          <gd-field-array-builder *ngIf="!field.hidden"
                                  [formArrayName]="field.key"
                                  [arrayFieldControl]="typecastAbstractToFormArray(getFormFieldForKey(field.key, field.fieldType))"
                                  [arrayFieldConfig]="customFieldsList[i]"
                                  [hasActionPermission]="hasActionPermission"
                                  [usage]="usage"
                                  [activeLocale]="activeLocale"
                                  [readOnlyMode]="field.readOnly">
          </gd-field-array-builder>
        </div>

        <div *ngSwitchDefault>
          <div class="group">
            <gd-field-builder *ngIf="!field.hidden"
                              [width]="100"
                              [fieldConfig]="field"
                              [fieldControl]="widgetFormGroup.get(field.key)"
                              [hasActionPermission]="hasActionPermission"
                              [isEditingMode]="isEditingMode"
                              [usage]="usage"
                              [activeLocale]="activeLocale"
                              [readOnlyMode]="field.readOnly">
            </gd-field-builder>
          </div>
        </div>

      </ng-container>
    </ng-container>
  </div>
</div>
