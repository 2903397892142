import { UnsafeAction } from '../unsafe-action.interface';
import {
  GET_ACTIVE_COLLECTION, CREATE_COLLECTION, UPDATE_COLLECTION,
  PUBLISH_COLLECTION, UNPUBLISH_COLLECTION, CHANGE_COLLECTION_REVISION, CREATE_COLLECTION_SUCCESS, UPDATE_COLLECTION_SUCCESS,
  GET_ACTIVE_COLLECTION_SUCCESS, COLLECTION_ACTION_FAILED, CHANGE_COLLECTION_REVISION_SUCCESS,
  UNPUBLISH_COLLECTION_SUCCESS, PUBLISH_COLLECTION_SUCCESS, CREATE_NEW_EMPTY_COLLECTION, SET_COLLECTION_TYPE,
  RESET_ACTIVE_COLLECTION, COPY_COLLECTION_BY_ID, ADD_COLLECTION_AUTHOR, REMOVE_COLLECTION_AUTHOR, UPDATE_COLLECTION_STATUS, SCHEDULE_COLLECTION_SUCCESS, UNSCHEDULE_COLLECTION_SUCCESS
} from './collection.actions';
import { createSelector } from '@ngrx/store';
import { getEmptyActiveCollection, ActiveCollectionState } from './collection.model';

const initialState: ActiveCollectionState = getEmptyActiveCollection({});

export function collectionReducer(state: ActiveCollectionState = initialState, action: UnsafeAction) {
  switch (action.type) {

    case GET_ACTIVE_COLLECTION: {
      return getEmptyActiveCollection({ loading: true });
    }

    case CREATE_COLLECTION_SUCCESS:
    case UPDATE_COLLECTION_SUCCESS:
    case GET_ACTIVE_COLLECTION_SUCCESS: {
      const activeCollection = action.payload;
      const latestRevision = activeCollection.latestRevision;
      const newRevisionMap = {
        ...state.collectionRevisionMap,
        [latestRevision.id]: latestRevision
      };
      return {
        ...state,
        id: activeCollection.id,
        glideId: activeCollection.glideId,
        createdAt: activeCollection.createdAt,
        updatedAt: activeCollection.updatedAt,
        overrideUpdatedAt: activeCollection.overrideUpdatedAt,
        firstPublishedAt: activeCollection.firstPublishedAt || null,
        lastPublishedAt: activeCollection.lastPublishedAt || null,
        transmitUpdatedAt: activeCollection.transmitUpdatedAt || null,

        workingRevision: latestRevision,
        activeRevisionId: latestRevision.id,
        stagedRevisionId: activeCollection.stagedRevisionId,
        publishedRevisionId: activeCollection.publishedRevision && activeCollection.publishedRevision.id || null,
        scheduledRevisionId: activeCollection.scheduledRevisionId || null,
        collectionRevisions: activeCollection.revisions,
        collectionRevisionMap: newRevisionMap,
        loading: false,
        loaded: true
      };
    }

    case RESET_ACTIVE_COLLECTION: {
      return {
        ...state,
        activeCollection: null,
        revisionsMap: {},
        workingCollectionRevision: null
      };
    }

      case CREATE_COLLECTION:
      case UPDATE_COLLECTION:
      case PUBLISH_COLLECTION:
      case CHANGE_COLLECTION_REVISION:
      case COPY_COLLECTION_BY_ID: {
        return {
            ...state,
            loading: true
          };
      }

    case CHANGE_COLLECTION_REVISION_SUCCESS: {
      const latestRevision = action.payload;
      return {
        ...state,
        loading: false,
        workingRevision: latestRevision,
        activeRevisionId: latestRevision.id,
        collectionRevisionMap: {
          ...state.collectionRevisionMap,
          [latestRevision.id]: latestRevision
        }
      };
    }

      case UNPUBLISH_COLLECTION_SUCCESS: {
      const publishRevisionId = state.publishedRevisionId;
      const newCollectionRevisionsMap = state.collectionRevisionMap;
      const newWorkingRevision = {...newCollectionRevisionsMap[publishRevisionId]};
      newWorkingRevision.status = action.payload;
      newCollectionRevisionsMap[publishRevisionId] = newWorkingRevision;

      const newCollectionsList = [...state.collectionRevisions];
      newCollectionsList.forEach(collection => {
        if (collection.id === publishRevisionId) {
          collection.status = action.payload;
          collection.statusLabel = collection.status.label;
        }
      });

      return {
        ...state,
        publishedRevisionId: null,
        workingRevision: newWorkingRevision,
        collectionRevisionMap: newCollectionRevisionsMap,
        collectionRevisions: newCollectionsList,
        transmitUpdatedAt: null,
        overrideUpdatedAt: false,
      };
      }

      case PUBLISH_COLLECTION_SUCCESS: {
      const newPublishedRevision = action.payload.publishedRevisionId;
      const oldPublishedRevision = state.publishedRevisionId;
      const newCollectionRevisionsMap = state.collectionRevisionMap;
      if (oldPublishedRevision) {
        delete newCollectionRevisionsMap[oldPublishedRevision];
      }
      const newWorkingRevision = { ...newCollectionRevisionsMap[newPublishedRevision] };
      const oldCollectionStatus = { ...(action.payload.unpublishedState || newWorkingRevision.status) };
      const newCollectionStatus = action.payload.publishedState;
      newWorkingRevision.status = newCollectionStatus;
      newCollectionRevisionsMap[newPublishedRevision] = newWorkingRevision;

      const newCollectionsList = [...state.collectionRevisions];
      newCollectionsList.forEach(collection => {
        if (collection.id === newPublishedRevision) {
          collection.status = newCollectionStatus;
          collection.statusLabel = collection.status.label;
          return;
        }

        if (oldPublishedRevision && oldPublishedRevision === collection.id) {
          collection.status = oldCollectionStatus;
          collection.statusLabel = collection.status.label;
        }
      });

      return {
        ...state,
        id: action.payload.id,
        glideId: action.payload.glideId,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        firstPublishedAt: action.payload.firstPublishedAt,
        lastPublishedAt: action.payload.lastPublishedAt,
        workingRevision: newWorkingRevision,
        publishedRevisionId: action.payload.publishedRevisionId,
        stagedRevisionId: action.payload.stagedRevisionId,
        activeRevisionId: action.payload.publishedRevisionId,
        collectionRevisionMap: newCollectionRevisionsMap,
        loaded: true,
        loading: false,
        collectionRevisions: newCollectionsList,
        transmitUpdatedAt: action.payload.transmitUpdatedAt,
        overrideUpdatedAt: false,
      };
      }

      case SCHEDULE_COLLECTION_SUCCESS: {

        const newScheduledRevision = action.payload.scheduledRevisionId;
        const oldScheduledRevision = state.scheduledRevisionId;
        const newCollectionRevisionsMap = state.collectionRevisionMap;
        if (oldScheduledRevision && newScheduledRevision !== oldScheduledRevision) {
          delete newCollectionRevisionsMap[oldScheduledRevision];
        }

        const newWorkingRevision = { ...newCollectionRevisionsMap[newScheduledRevision] };
        const oldCollectionStatus = { ...newWorkingRevision.status };
        const newCollectionStatus = action.payload.scheduledState;
        newWorkingRevision.status = newCollectionStatus;
        newCollectionRevisionsMap[newScheduledRevision] = newWorkingRevision;


        const newCollectionsList = [...state.collectionRevisions];
        newCollectionsList.forEach(collection => {
          if (collection.id === newScheduledRevision) {
            collection.status = newCollectionStatus;
            collection.statusLabel = collection.status.label;
            return;
          }

          if (oldScheduledRevision && oldScheduledRevision === collection.id) {
            collection.status = oldCollectionStatus;
            collection.statusLabel = collection.status.label;
          }
        });

        return {
          ...state,
          id: action.payload.id,
          glideId: action.payload.glideId,
          createdAt: action.payload.createdAt,
          updatedAt: action.payload.updatedAt,
          firstPublishedAt: action.payload.firstPublishedAt,
          lastPublishedAt: action.payload.lastPublishedAt,
          workingRevision: newWorkingRevision,
          scheduledRevisionId: action.payload.scheduledRevisionId,
          stagedRevisionId: action.payload.stagedRevisionId,
          activeRevisionId: action.payload.scheduledRevisionId,
          collectionRevisionMap: newCollectionRevisionsMap,
          loaded: true,
          loading: false,
          collectionRevisions: newCollectionsList
        };
      }

      case UNSCHEDULE_COLLECTION_SUCCESS: {

        const scheduledRevisionId = state.scheduledRevisionId;
        const newCollectionRevisionsMap = state.collectionRevisionMap;
        const newWorkingRevision = { ...newCollectionRevisionsMap[scheduledRevisionId]};

        newWorkingRevision.status = action.payload;
        newCollectionRevisionsMap[scheduledRevisionId] = newWorkingRevision;

        const newCollectionsList = [...state.collectionRevisions];
        newCollectionsList.forEach(collection => {
          if (collection.id === scheduledRevisionId) {
            collection.status = action.payload;
            collection.statusLabel = collection.status.label;
          }
        });

        return {
          ...state,
          scheduledRevisionId: null,
          workingRevision: newWorkingRevision,
          collectionRevisionMap: newCollectionRevisionsMap,
          collectionRevisions: newCollectionsList
        };

      }

      case CREATE_NEW_EMPTY_COLLECTION: {
        return getEmptyActiveCollection(action.payload);
      }

      case SET_COLLECTION_TYPE: {
        const typeId = action.payload.id;
        // const taxonomies = action.payload.taxonomies;
        return {
          ...state,
          workingRevision: {
            ...state.workingRevision,
            type: { id: typeId },
            // taxonomies
          }
        };
      }

      case COLLECTION_ACTION_FAILED: {
        return {
          ...state,
          loading: false,
          loaded: true
        };
      }

      case ADD_COLLECTION_AUTHOR: {
        const authorExist = state.workingRevision.authors.find(author => author.id === action.payload.id);
        const authors = authorExist ? state.workingRevision.authors : [...state.workingRevision.authors, action.payload];
        return {
          ...state,
          workingRevision: {
            ...state.workingRevision,
            authors
          }
        };
      }

      case REMOVE_COLLECTION_AUTHOR: {
        const authors = state.workingRevision.authors.filter(author => author.id !== action.payload);
        return {
          ...state,
          workingRevision: {
            ...state.workingRevision,
            authors
          }
        };
      }

      case UPDATE_COLLECTION_STATUS: {
        const newWorkingRevision = state.workingRevision;
        newWorkingRevision.status = action.payload;
        return {
          ...state,
          workingRevision: newWorkingRevision
        };
      }

      default: {
        return state;
      }
  }
}

export const  getCollectionState = (state) => state.collections;

export const getCollectionLoading = createSelector(getCollectionState, data => data.loading);

export const getCollectionLoaded = createSelector(getCollectionState, data => data.loaded);

export const getActiveCollection = createSelector(getCollectionState, state => state);

export const getCollectionRevisionsList = createSelector(getCollectionState, state => state.collectionRevisions);

export const getWorkingRevision = createSelector(getCollectionState, data => data.workingRevision);

export const getWorkingRevisionId = createSelector(getWorkingRevision, workingRevision => workingRevision ? workingRevision.id : null);

export const getPublishedRevisionId = createSelector(getCollectionState, data => data.publishedRevisionId);

export const getStagedRevisionId = createSelector(getCollectionState, data => data.stagedRevisionId);

// eslint-disable-next-line max-len
export const isWorkingRevisionPublished = createSelector(getPublishedRevisionId, getWorkingRevisionId, (publishedRevisionId, workingRevisionId) => {
  return !publishedRevisionId ? false : publishedRevisionId === workingRevisionId;
});

export const getCollectionAuthors = createSelector(getWorkingRevision, workingRevision => workingRevision.authors);

export const getActiveCollectionTransmitUpdatedAt = createSelector(
  getActiveCollection,
  (activeCollection) => activeCollection.transmitUpdatedAt || null
);
