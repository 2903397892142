export const titanImageDimensionsLandscape = [
  { label: '1024x1024', width: 1024, height: 1024 },
  { label: '512x512', width: 512, height: 512 },
  { label: '576x384', width: 576, height: 384 },
  { label: '576x448', width: 576, height: 448 },
  { label: '640x384', width: 640, height: 384 },
  { label: '704x320', width: 704, height: 320 },
  { label: '704x384', width: 704, height: 384 },
  { label: '768x768', width: 768, height: 768 },
  { label: '1152x768', width: 1152, height: 768 },
  { label: '1152x896', width: 1152, height: 896 },
  { label: '1280x768', width: 1280, height: 768 },
  { label: '1408x640', width: 1408, height: 640 },
  { label: '1408x768', width: 1408, height: 768 },
  { label: '1152x640', width: 1152, height: 640 },
  { label: '1173x640', width: 1173, height: 640 },
];

export const titanImageDimensionsPortrait = [
  { label: '1024x1024', width: 1024, height: 1024 },
  { label: '320x704', width: 320, height: 704 },
  { label: '384x576', width: 384, height: 576 },
  { label: '384x640', width: 384, height: 640 },
  { label: '384x704', width: 384, height: 704 },
  { label: '448x576', width: 448, height: 576 },
  { label: '512x512', width: 512, height: 512 },
  { label: '640x1408', width: 640, height: 1408 },
  { label: '768x768', width: 768, height: 768 },
  { label: '768x1152', width: 768, height: 1152 },
  { label: '768x1280', width: 768, height: 1280 },
  { label: '768x1408', width: 768, height: 1408 },
  { label: '896x1152', width: 896, height: 1152 },
];

export const sdxlV1ImageDimensionsLandscape = [
  { label: '1024x1024', width: 1024, height: 1024 },
  { label: '512x512', width: 512, height: 512 },
  { label: '1152x896', width: 1152, height: 896 },
  { label: '1216x832', width: 1216, height: 832 },
  { label: '1344x768', width: 1344, height: 768 },
  { label: '1536x640', width: 1536, height: 640 },
];

export const sdxlV1ImageDimensionsPortrait = [
  { label: '1024x1024', width: 1024, height: 1024 },
  { label: '512x512', width: 512, height: 512 },
  { label: '640x1536', width: 640, height: 1536 },
  { label: '768x1344', width: 768, height: 1344 },
  { label: '832x1216', width: 832, height: 1216 },
  { label: '896x1152', width: 896, height: 1152 },
];

export const sdxlV0ImageDimensions = [
  { label: '512x512', width: 512, height: 512 },
  { label: '896x512', width: 896, height: 512 },
  { label: '512x896', width: 512, height: 896 },
];

export const stylePresetList = [
  { name: 'none', label: 'None' },
  { name: '3d-model', label: '3D Model' },
  { name: 'analog-film', label: 'Analog Film' },
  { name: 'anime', label: 'Anime' },
  { name: 'cinematic', label: 'Cinematic' },
  { name: 'comic-book', label: 'Comic Book' },
  { name: 'digital-art', label: 'Digital Art' },
  { name: 'enhance', label: 'Enhance' },
  { name: 'fantasy-art', label: 'Fantasy Art' },
  { name: 'isometric', label: 'Isometric' },
  { name: 'line-art', label: 'Line Art' },
  { name: 'low-poly', label: 'Low Poly' },
  { name: 'modeling-compound', label: 'Modeling Compound' },
  { name: 'neon-punk', label: 'Neon Punk' },
  { name: 'origami', label: 'Origami' },
  { name: 'photographic', label: 'Photographic' },
  { name: 'pixel-art', label: 'Pixel Art' },
  { name: 'tile-texture', label: 'Tile Texture' },
];
