import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
  GET_REDIRECTS, GET_REDIRECTS_SUCCESS, CREATE_REDIRECT, UPDATE_REDIRECT, DELETE_REDIRECT,
  CREATE_REDIRECT_SUCCESS, UPDATE_REDIRECT_SUCCESS, DELETE_REDIRECT_SUCCESS, SET_REDIRECTS_LOADING_FLAG, SET_PAGE_VIEW_OPTIONS
} from './redirects.actions';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';

export interface Redirect {
  id: number;
  source: string;
  target: string;
  pathRedirecttype: number;
  active: boolean;
}

export interface RedirectsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  redirects: Redirect[];
  redirectsListView?: any;
}

const initialState: RedirectsState = {
  loaded: false,
  loading: false,
  error: null,
  redirects: [],
  redirectsListView: {
    pageNumber: 0,
    pageSize: defaultPageSize,
    total: 0,
    pageSizeOptions: defaultPageSizeOptions
  }
};

export function redirectsReducer(state: RedirectsState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_REDIRECTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_REDIRECTS_SUCCESS: {
      const redirects = action.payload.data;
      const paginationData = action.payload.meta.page;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        redirects,
        redirectsListView: {
          ...state.redirectsListView,
          total: paginationData.total
        }
      };
    }

    case CREATE_REDIRECT:
    case UPDATE_REDIRECT:
    case DELETE_REDIRECT: {
      return { ...state, loading: true };
    }

    case CREATE_REDIRECT_SUCCESS:
    case UPDATE_REDIRECT_SUCCESS: {
      return { ...state, loading: false };
    }

    case DELETE_REDIRECT_SUCCESS: {
      const deletedId = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        redirects: state.redirects.filter(redirect => redirect.id !== deletedId),
        redirectsListView: {
          ...state.redirectsListView,
          total: state.redirectsListView.total - 1
         }
      };
    }

    case SET_REDIRECTS_LOADING_FLAG: {
      return { ...state, loading: action.payload };
    }

    case SET_PAGE_VIEW_OPTIONS: {
      const { pageNumber, pageSize } = action.payload;
      return {
        ...state,
        redirectsListView: {
          ...state.redirectsListView,
          pageNumber,
          pageSize
        }
      };
    }

    default: {
      return state;
    }
  }

}

export const getRedirectsState = (state) => state.redirects;

export const getRedirectsList = createSelector(getRedirectsState, (state): Redirect[] => state.redirects);

export const getRedirect = id => createSelector(getRedirectsList, redirects => redirects.find(redirect => redirect.id === id));

export const getRedirectsListView = createSelector(getRedirectsState, state => state.redirectsListView);

export const getRedirectsLoadingFlag = (state) => state.redirects.loading;
