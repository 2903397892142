<mat-icon class="gd-system-notifications-icon"
          fontSet="fal"
          fontIcon="fa-bell-exclamation"></mat-icon>
<h1 class="gd-system-notifications-dialog__title">{{label}}</h1>
<p class="gd-system-notifications-dialog__message"
   *ngIf="message">
  {{message}}
</p>
<p class="gd-system-notifications-dialog__message"
   *ngIf="!message"
   i18n>
  Please be patient until the maintenance mode is active. We'll be with you in no time.</p>
<div fxLayoutAlign="center stretch"
     fxLayout="row"
     fxLayoutGap="18px">

  <button type="button"
          mat-button
          color="primary"
          (click)="dialogRef.close()"
          data-cy="gd-mode-warning-no"
          i18n="Confirm modal button"
          i18n>I understand</button>
</div>
