import { PostBodyReferencedContentService } from './../../body-content/referenced-content/post-body-referenced-content.service';
import { ContentModel } from './../article/article-content.model';
import { getPostEditorBodyModel } from './posts.reducer';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, map, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { UnsafeAction } from "../unsafe-action.interface";
import {
  COPY_POST_CONTENT_NODES,
  CreatePostSuccessAction,
  CREATE_POST,
  CREATE_POST_CONTENT_NODE,
  CREATE_POST_SUCCESS,
  DeletePostSuccessAction,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  GetActivePostSuccessAction,
  GetPostListSuccessAction,
  GET_ACTIVE_POST,
  GET_POST_LIST,
  LoadPostBodyIntoEditorSpacerAction,
  LOAD_POST_BODY_INTO_EDITOR,
  PostListFailedAction,
  PublishPostSuccessAction,
  PUBLISH_POST,
  PUBLISH_POST_SUCCESS,
  RefreshPostsListAction,
  UnpublishPostSuccessAction,
  UNPUBLISH_POST,
  UNPUBLISH_POST_SUCCESS,
  UpdatePostSuccessAction,
  UPDATE_POST,
  UPDATE_POST_BODY_REFERENCED_CONTENT,
  UPDATE_POST_CONTENT_NODE,
  UPDATE_POST_SUCCESS
} from "./posts.actions";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PostsService } from "../../api/posts/posts.service";
import { Router } from "@angular/router";import { AppState } from '../app-reducer';
import { getActiveLiveReportPublishedRevision } from '../live-report/live-report.reducer';
import {
  DecrementLiveReportPostCountAction,
  GetActiveLiveReportAction,
  GetActiveLiveReportSuccessAction,
  GetSummaryAction,
  IncrementLiveReportPostCountAction,
} from '../live-report/live-report.actions';
import { LiveReportsService } from '../../api/live-reports/live-reports.service';

@Injectable()
export class PostEffects {
  constructor(
    private actions$: Actions,
    private postsService: PostsService,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private referencedContentService: PostBodyReferencedContentService,
    private router: Router,
    private liveReportService: LiveReportsService
  ) {}

  
  loadPosts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_POST_LIST),
    debounceTime(200),
    mergeMap((action: UnsafeAction) => {
      return this.postsService.getPostsList(action.payload).pipe(
        map((posts) => {
          return new GetPostListSuccessAction(posts);
        }),
        catchError((e) => {
          return of(new PostListFailedAction(e));
        })
      );
    })
  ));

  
  getPost$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACTIVE_POST),
    mergeMap((action: UnsafeAction) => {
      return this.postsService.getPost(action.payload).pipe(
        map((post) => new GetActivePostSuccessAction(post)),
        catchError((e) => {
          this.router.navigate([`live-reporting/${action.payload.liveReportId}/posts`]);
          this.snackBar.open($localize`Post not found.`, $localize`Close`, { duration: 4000 });
          return of(new GetActivePostSuccessAction(null));
        })
      );
    })
  ));

  
  createPost$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_POST),
    mergeMap((action: UnsafeAction) => this.injectEditorContentModel(action)),
    // fire post create action with modified payload
    mergeMap((postPayload) => {
      return this.postsService.createPost(postPayload).pipe(
        tap(() => this.snackBar.open($localize`Post created`, $localize`Close`, { duration: 4000 })),
        switchMap((post) => [
          new CreatePostSuccessAction(post),
          new IncrementLiveReportPostCountAction({}),
        ]),
        catchError((e) => of(new PostListFailedAction(e)))
      );
    })
  ));

  
  updatePost$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_POST),
    mergeMap((action: UnsafeAction) => this.injectEditorContentModel(action)),
    mergeMap((postPayload) => {
      return this.postsService.updatePost(postPayload).pipe(
        map((post) => new UpdatePostSuccessAction(post)),
        tap(() => this.snackBar.open($localize`Post updated`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new PostListFailedAction(e)))
      );
    })
  ));

  
  deletePost$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_POST),
    mergeMap((action: UnsafeAction) => {
      const { liveReportId, postId } = action.payload;
      return this.postsService.deletePost(liveReportId, postId).pipe(
        switchMap((post) => [
          new DeletePostSuccessAction(postId),
          new DecrementLiveReportPostCountAction({}),
        ]),
        tap(() => this.snackBar.open($localize`Post deleted`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new PostListFailedAction(e)))
      );
    })
  ));

  
  publishPost$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PUBLISH_POST),
    withLatestFrom(this.store.select(getActiveLiveReportPublishedRevision)),
    mergeMap(([action, publishedRevision]: [UnsafeAction, any]) => {
      return this.postsService.publishPost(action.payload).pipe(
        tap(() => {
          let message = $localize`Post published.`;
          if (!publishedRevision?.id) {
            message += $localize`Publish this Live Report from the Report setup tab to make it visible.`;
          }
          if (action.payload.fetchNewData) {
            this.liveReportService
              .getLiveReport(action.payload.liveReportId)
              .subscribe((liveReport) =>
                this.store.dispatch(new GetActiveLiveReportSuccessAction(liveReport))
              );
          }
          this.snackBar.open(message, $localize`Close`, { duration: 4000 });
        }),
        map((post) => new PublishPostSuccessAction(post)),
        catchError((e) => of(new PostListFailedAction(e)))
      );
    })
  ));
  
  unpublishPost$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UNPUBLISH_POST),
    mergeMap((action: UnsafeAction) => {
      return this.postsService.unpublishPost(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Post unpublished`, $localize`Close`, { duration: 4000 })),
        map((post) => new UnpublishPostSuccessAction(post)),
        catchError((e) => of(new PostListFailedAction(e)))
      );
    })
  ));

  
  refreshPostsList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      CREATE_POST_SUCCESS,
      UPDATE_POST_SUCCESS,
      PUBLISH_POST_SUCCESS,
      UNPUBLISH_POST_SUCCESS,
      DELETE_POST_SUCCESS
    ),
    map(() => new RefreshPostsListAction())
  ));

  
  loadEditorContentSpacerAction$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      LOAD_POST_BODY_INTO_EDITOR,
      UPDATE_POST_CONTENT_NODE,
      // TODO review CREATE_POST_CONTENT_NODE usage here
      CREATE_POST_CONTENT_NODE,
      UPDATE_POST_BODY_REFERENCED_CONTENT
    ),
    debounceTime(5),
    // add to content pipeline filter
    map(() => new LoadPostBodyIntoEditorSpacerAction())
  ));

  
  getReferencedContent$: Observable<any> = createEffect(() => this.actions$
    .pipe(ofType(LOAD_POST_BODY_INTO_EDITOR, COPY_POST_CONTENT_NODES, UPDATE_POST_CONTENT_NODE))
    .pipe(
      debounceTime(100),
      mergeMap(() => this.store.select(getPostEditorBodyModel).pipe(take(1))),
      tap((contentModel: ContentModel) => {
        const cachedReferencedContent = contentModel.referencedContent;
        this.referencedContentService.loadReferencedContentForContentModel(
          contentModel,
          cachedReferencedContent
        );
      })
    ), { dispatch: false });

  private injectEditorContentModel(action: UnsafeAction) {
    // take the post editor content from the post editor state
    // and merge it with the rest of the values
    return this.store.select(getPostEditorBodyModel).pipe(
      take(1),
      map((postEditorContent: ContentModel) => {
        const postEditorContentWithoutHtml = { ...postEditorContent };
        delete postEditorContentWithoutHtml.bodyHtml;
        return {
          ...action.payload,
          post: {
            ...action.payload.post,
            body: postEditorContentWithoutHtml,
          },
        };
      })
    );
  }
}

