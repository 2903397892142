import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../fury-core/admin/admin.component';
import { AuthGuard } from '../core/api/auth/auth-guard.guard';
import { PermissionGuard } from '../core/api/auth/permission-guard.guard';
import { ImageSizesResolver } from './image-sizes-resolver';

const routes: Routes = [
  {
    path: 'media',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'images',
        loadChildren: () => import('./+images/images.module').then(m => m.ImagesModule),
        canActivate: [PermissionGuard]
      },
      { path: 'galleries', loadChildren: () => import('./+galleries/galleries.module').then(m => m.GalleriesModule), canActivate: [PermissionGuard] },
      {
        path: 'files',
        loadChildren: () => import('./+files/files.module').then(m => m.FilesModule),
        canActivate: [PermissionGuard],
      },
      { path: 'media-config', loadChildren: () => import('./+configuration/configuration.module').then(m => m.ConfigurationModule), canActivate: [PermissionGuard] },
      { path: '', pathMatch: 'full', redirectTo: '/media/images' },
      { path: '**', redirectTo: 'misc/error404' },
    ],
    resolve: { imageCrops: ImageSizesResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlideMediaRoutingModule { }
