import {
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES,
  ARTICLE_LIST_QUERY,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  GetArticlesAction,
  UPDATE_ARTICLES_LIST,
  COPY_ARTICLE_URL_FAILED,
  COPY_ARTICLE_URL_SUCCESS,
  COPY_ARTICLE_URL,
  GET_LOCALIZED_VERSIONS_ARTICLES_SUCCESS,
  GetLocalizedVersions4ArticlesSuccess,
} from './article-list.actions';
import { Article } from '../article/article.model';
import { ArticlesList } from './article-list.model';
import { createSelector } from 'reselect';
import { UnsafeAction } from '../unsafe-action.interface';
import { ACCOUNT_CHANGED } from '../auth/auth.actions';
import { defaultPageSize } from '../constants/default-pagination.constants';

export const articlesListInitialState: ArticlesList = {
  loaded: false,
  loading: false,
  query: {
    page: {
      number: 0,
      size: defaultPageSize,
      total: 0,
    },
  },
  articles: [],
  localizedVersions: {}
};

export function articleListReducer(
  state = articlesListInitialState,
  action: UnsafeAction | GetLocalizedVersions4ArticlesSuccess
): ArticlesList {
  switch (action.type) {
    case ACCOUNT_CHANGED: {
      return { ...articlesListInitialState };
    }

    case GET_ARTICLES: {
      return { ...state, loading: true };
    }

    case GET_ARTICLES_SUCCESS: {
      const query = Object.assign({}, state.query, action.payload.query);
      return Object.assign({}, state, {
        query: query,
        loading: false,
        loaded: true,
        articles: action.payload.articles,
      });
    }

    case GET_LOCALIZED_VERSIONS_ARTICLES_SUCCESS: {
      const mappedVersions = (action as GetLocalizedVersions4ArticlesSuccess).localizedVersions
      .reduce((acc, lv) => {
        acc[lv.entityId] = lv;
        return acc;
      }, {})
      return {
        ...state,
        localizedVersions: mappedVersions
      };
    }

    case ARTICLE_LIST_QUERY: {
      const query = Object.assign({}, state.query, action.payload);
      return Object.assign({}, state, { query });
    }

    case DELETE_ARTICLE: {
      return Object.assign({}, state, {
        articles: state.articles.filter((a) => +a.id !== +action.payload),
      });
    }

    case UPDATE_ARTICLES_LIST: {
      const article = action.payload;
      return {
        ...state,
        articles: [...state.articles, article],
      };
    }

    case COPY_ARTICLE_URL: {
      return {
        ...state,
        loading: true,
      }
    }

    case COPY_ARTICLE_URL_FAILED:
    case COPY_ARTICLE_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getArticleListState = (state) => state.articlesList as ArticlesList;

export const getArticles = (state) => state.articlesList.articles;

export const getArticlesStateSafe = createSelector(getArticleListState, (articleListData: ArticlesList) => {
  return {
    articles: articleListData.articles.map((a) => Object.assign({}, a)),
    pagination: articleListData.query.page,
  };
});

export const getArticleLocalizedVersions = createSelector(getArticleListState, (articleListState) => {
  return articleListState.localizedVersions;
})

export const getArticleListFilters = (state) => state.articlesList.query.filter;

export const getFilteredArticles = createSelector(
  getArticles,
  getArticleListFilters,
  (articles, filter) => {
    return articles
      .filter(articleFilterFactory(filter))
      .map((article) => Object.assign({}, article));
  }
);

function articleFilterFactory(filterString) {
  const filterRegExp = new RegExp(filterString, 'i');
  return (article) =>
    !filterString || filterRegExp.test(article.title) || filterRegExp.test(article.author);
}
