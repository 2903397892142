import { UnsafeAction } from '../unsafe-action.interface';

export const GET_ACTIVE_LIVE_REPORT = '[Live Report] Get active live report';
export class GetActiveLiveReportAction implements UnsafeAction {
  readonly type = GET_ACTIVE_LIVE_REPORT;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_LIVE_REPORT_SUCCESS = '[Live Report] Get active live report success';
export class GetActiveLiveReportSuccessAction implements UnsafeAction {
  readonly type = GET_ACTIVE_LIVE_REPORT_SUCCESS;
  constructor(public payload: any) { }
}

export const RESET_ACTIVE_LIVE_REPORT = '[Live Report] Reset active live report';
export class ResetActiveLiveReportAction implements UnsafeAction {
  readonly type = RESET_ACTIVE_LIVE_REPORT;
  constructor(public payload: any) { }
}

export const LIVE_REPORT_FAILED = '[Live Report] Live reports failed';
export class LiveReportFailedAction implements UnsafeAction {
  readonly type = LIVE_REPORT_FAILED;
  constructor(public payload: any) { }
}

export const CREATE_NEW_EMPTY_LIVE_REPORT = '[Live Report] Create new empty live report';
export class CreateNewEmptyLiveReportAction implements UnsafeAction {
  readonly type = CREATE_NEW_EMPTY_LIVE_REPORT;
  constructor(public payload: any) { }
}
export const CREATE_LIVE_REPORT = '[Live Report] Create live report';
export class CreateLiveReportAction implements UnsafeAction {
  readonly type = CREATE_LIVE_REPORT;
  constructor(public payload: any) { }
}

export const CREATE_LIVE_REPORT_SUCCESS = '[Live Report] Create live report success';
export class CreateLiveReportSuccess implements UnsafeAction {
  readonly type = CREATE_LIVE_REPORT_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_LIVE_REPORT = '[Live Report] Update live report';
export class UpdateLiveReportAction implements UnsafeAction {
  readonly type = UPDATE_LIVE_REPORT;
  constructor(public payload: any) { }
}

export const UPDATE_LIVE_REPORT_SUCCESS = '[Live Report] Update live report success';
export class UpdateLiveReportSuccessAction implements UnsafeAction {
  readonly type = UPDATE_LIVE_REPORT_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_SUMMARY = '[Live Report] Get Live Report Summary';
export class GetSummaryAction implements UnsafeAction {
  readonly type = GET_SUMMARY;
  constructor(public payload: any) { }
}

export const GET_SUMMARY_SUCCESS = '[Live Report] Get Live Report Summary Success';
export class GetSummarySuccessAction implements UnsafeAction {
  readonly type = GET_SUMMARY_SUCCESS;
  constructor(public payload: any) { }
}

export const PUBLISH_LIVE_REPORT = '[Live Report] Publish Live Report';
export class PublishLiveReportAction implements UnsafeAction {
  readonly type = PUBLISH_LIVE_REPORT;
  constructor(public payload) { }
}

export const PUBLISH_LIVE_REPORT_SUCCESS = '[Live Report] Publish Live Report Success';
export class PublishLiveReportSuccessAction implements UnsafeAction {
  readonly type = PUBLISH_LIVE_REPORT_SUCCESS;
  constructor(public payload) { }
}

export const UNPUBLISH_LIVE_REPORT = '[Live Report] Unpublish Live Report';
export class UnpublishLiveReportAction implements UnsafeAction {
  readonly type = UNPUBLISH_LIVE_REPORT;
  constructor(public payload) { }
}

export const UNPUBLISH_LIVE_REPORT_SUCCESS = '[Live Report] Unpublish Live Report Success';
export class UnpublishLiveReportSuccessAction implements UnsafeAction {
  readonly type = UNPUBLISH_LIVE_REPORT_SUCCESS;
  constructor(public payload) { }
}

export const CREATE_SUMMARY = '[Live Report] Create Live Report Summary';
export class CreateSummaryAction implements UnsafeAction {
  readonly type = CREATE_SUMMARY;
  constructor(public payload: any) { }
}

export const SUMMARY_FAILED = '[Live Report] Live report summary failed';
export class SummaryFailedAction implements UnsafeAction {
  readonly type = SUMMARY_FAILED;
  constructor(public payload: any) { }
}

export const CREATE_SUMMARY_SUCCESS = '[Live Report] Create Live Report Summary Success';
export class CreateSummarySuccess implements UnsafeAction {
  readonly type = CREATE_SUMMARY_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_SUMMARY = '[Live Report] Update Live Report Summary';
export class UpdateSummaryAction implements UnsafeAction {
  readonly type = UPDATE_SUMMARY;
  constructor(public payload: any) { }
}

export const UPDATE_SUMMARY_SUCCESS = '[Live Report] Update Live Report Summary Success';
export class UpdateSummarySuccess implements UnsafeAction {
  readonly type = UPDATE_SUMMARY_SUCCESS;
  constructor(public payload: any) { }
}

export const PUBLISH_SUMMARY = '[Live Report] Publish Live Report Summary';
export class PublishSummaryAction implements UnsafeAction {
  readonly type = PUBLISH_SUMMARY;
  constructor(public payload: any) { }
}

export const PUBLISH_SUMMARY_SUCCESS = '[Live Report] Publish Live Report Summary Success';
export class PublishSummarySuccess implements UnsafeAction {
  readonly type = PUBLISH_SUMMARY_SUCCESS;
  constructor(public payload: any) { }
}
export const UNPUBLISH_SUMMARY = '[Live Report] Unpublish Live Report Summary';
export class UnpublishSummaryAction implements UnsafeAction {
  readonly type = UNPUBLISH_SUMMARY;
  constructor(public payload: any) { }
}

export const UNPUBLISH_SUMMARY_SUCCESS = '[Live Report] Unpublish Live Report Summary Success';
export class UnpublishSummarySuccess implements UnsafeAction {
  readonly type = UNPUBLISH_SUMMARY_SUCCESS;
  constructor(public payload: any) { }
}
export const LOAD_LIVE_REPORT_RELATED_ARTICLES = '[Live Report] Load related articles';
export class LoadLiveReportRelatedArticlesAction implements UnsafeAction {
  readonly type = LOAD_LIVE_REPORT_RELATED_ARTICLES;
  constructor(public payload: any) { }
}

export const LOAD_LIVE_REPORT_RELATED_ARTICLES_SUCCESS = '[Live Report] Load related articles success';
export class LoadLiveReportRelatedArticlesSuccessAction implements UnsafeAction {
  readonly type = LOAD_LIVE_REPORT_RELATED_ARTICLES_SUCCESS;
  constructor(public payload: any) { }
}

export const SET_LIVE_REPORT_LOADING_FLAG = '[Live Report] Set live report loading flag';
export class SetLiveReportLoadingFlagAction implements UnsafeAction {
  readonly type = SET_LIVE_REPORT_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const INCREMENT_POST_COUNT = '[Live Report] Increment live report post count';
export class IncrementLiveReportPostCountAction implements UnsafeAction {
  readonly type = INCREMENT_POST_COUNT;
  constructor(public payload: any) { }
}

export const DECREMENT_POST_COUNT = '[Live Report] Decrement live report post count';
export class DecrementLiveReportPostCountAction implements UnsafeAction {
  readonly type = DECREMENT_POST_COUNT;
  constructor(public payload: any) { }
}
