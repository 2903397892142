import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/api/auth/auth-guard.guard';
import { PermissionGuard } from '../core/api/auth/permission-guard.guard';
import { AdminComponent } from '../fury-core/admin/admin.component';

const routes: Routes = [
  {
    path: 'transmit',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'subscribers',
        loadChildren: () =>
          import('./+subscribers/subscribers.module').then((m) => m.SubscribersModule),
        canActivate: [PermissionGuard],
      },
      { path: '', pathMatch: 'full', redirectTo: '/transmit/subscribers' },
      { path: '**', redirectTo: 'misc/error404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GlideTransmitRoutingModule {}
