import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { RestService } from "../rest.service";

@Injectable()
export class ContentTrackerService {
  defaultParams = {
    service: 'glideCreate',
  };

  constructor(private rest: RestService) {}

  getArticleLinks(articleId, revisionId, view, page, size) {
    let reqPath = `?resourceType=article&resourceId=${articleId}&resourceRevisionId=${revisionId}`;
    if (view === 'in') {
      reqPath = `?referencedResourceType=article&referencedResourceId=${articleId}`
    }
    return this.rest
      .get(`resource-tracker${reqPath}&page=${page || 0}&size=${size}`,this.defaultParams)
      .pipe(map((res) => res));
  }

}
