<div class="gd-scrolling-list"
     fxLayout="row">

  <gd-scroller #scroller
               [scrollConfig]="{ scrollX: true, scrollY: false}">
    <div class="gd-scrolling-list-wrapper"
         fxLayout="row"
         fxLayoutAlign="center center"
         fxLayoutGap="8px"
         [style.height]="'130px'">

      <div #collectionItems
           *ngFor="let tile of collection"
           class="gd-scrolling-list__item"
           [attr.id]="tile.id"
           [class.active]="tile.active"
           [style.background-image]="'url(' + tile.thumbnail + ')'"
           (click)="previewGalleryImage(tile)">
        <img class="gd-scrolling-list__item-img"
             [src]="tile.thumbnail"
             alt="">
        <div class="gd-scrolling-list-wrapper__indicator"> </div>
      </div>

    </div>
  </gd-scroller>

</div>
