import { UnsafeAction } from '../unsafe-action.interface';

export const SET_ADVANCED_FILTERING_QUERY = '[Advanced filtering] Set advanced filtering query';
export class SetAdvancedFilteringQuery implements UnsafeAction {
  readonly type = SET_ADVANCED_FILTERING_QUERY;
  constructor(public payload: { include?: {}, includeSome?: {}, exclude?: {}, excludeSome?: {} }) { }
}

export const UPDATE_HEADLINE_FILTER = '[Advanced filtering] Update headline filter';
export class UpdateHeadlineFilter implements UnsafeAction {
  readonly type = UPDATE_HEADLINE_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_HEADLINE_EXCLUDE_FILTER = '[Advanced filtering] Update headline exclude filter';
export class UpdateHeadlineExcludeFilter implements UnsafeAction {
  readonly type = UPDATE_HEADLINE_EXCLUDE_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_HEADLINE_FILTER_ARTICLE_LIST = '[Advanced filtering] Update Article list headline filter';
export class UpdateArticleListHeadlineFilter implements UnsafeAction {
  readonly type = UPDATE_HEADLINE_FILTER_ARTICLE_LIST;
  constructor(public payload: any) {}
}

export const UPDATE_CONTENT_LOCALE_ID_FILTER = '[Advanced filtering] Update Article list content locale id filter';
export class UpdateArticleListContentLocaleIdFilter implements UnsafeAction {
  readonly type = UPDATE_CONTENT_LOCALE_ID_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_CONTENT_LOCALE_FILTER = '[Advanced filtering] Update content locale filter';
export class UpdateContentLocaleFilter implements UnsafeAction {
  readonly type = UPDATE_CONTENT_LOCALE_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_LAST_MODIFIED_BY_FILTER = '[Advanced filtering] Update Last Modified by filter';
export class UpdateLastModifiedByFilter implements UnsafeAction {
  readonly type = UPDATE_LAST_MODIFIED_BY_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_BYLINE_FILTER = '[Advanced filtering] Update Byline filter';
export class UpdateBylineFilter implements UnsafeAction {
  readonly type = UPDATE_BYLINE_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_UPDATED_AT_FILTER = '[Advanced filtering] Update Updated at filter';
export class UpdateUpdatedAtFilter implements UnsafeAction {
  readonly type = UPDATE_UPDATED_AT_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_TYPE_FILTER = '[Advanced filtering] Update Type filter';
export class UpdateTypeFilter implements UnsafeAction {
  readonly type = UPDATE_TYPE_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_TAXONOMIES_FILTER = '[Advanced filtering] Update Taxonomies filter';
export class UpdateTaxonomiesFilter implements UnsafeAction {
  readonly type = UPDATE_TAXONOMIES_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_STATUS_FILTER = '[Advanced filtering] Update Status filter';
export class UpdateStatusFilter implements UnsafeAction {
  readonly type = UPDATE_STATUS_FILTER;
  constructor(public payload: any) {}
}

export const RESET_ADVANCED_FILTER = '[Advanced filtering] Reset advanced filter';
export class ResetAdvancedFilters implements UnsafeAction {
  readonly type = RESET_ADVANCED_FILTER;
  constructor() {}
}

export const GET_CONTENT_FILTERS = '[Content Filters] Get content filters';
export class GetContentFiltersAction implements UnsafeAction {
  readonly type = GET_CONTENT_FILTERS;
  constructor(public payload: any = {}) {}
}

export const GET_CONTENT_FILTERS_SUCCESS = '[Content Filters] Get content filters success';
export class GetContentFiltersSuccessAction implements UnsafeAction {
  readonly type = GET_CONTENT_FILTERS_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const CREATE_CONTENT_FILTER = '[Content Filters] Create content filter';
export class CreateContentFilterAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_FILTER;
  constructor(public payload: any) {}
}

export const CREATE_CONTENT_FILTER_SUCCESS = '[Content Filters] Create content filter success';
export class CreateContentFilterSuccessAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_FILTER_SUCCESS;
  constructor(public payload: any) {}
}

export const UPDATE_CONTENT_FILTER = '[Content Filters] Update content filter';
export class UpdateContentFilterAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_CONTENT_FILTER_SUCCESS = '[Content Filters] Update content filter success';
export class UpdateContentFilterSuccessAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_FILTER_SUCCESS;
  constructor(public payload: any) {}
}

export const SET_DEFAULT_CONTENT_FILTER = '[Content Filters] Set default content filter';
export class SetDefaultContentFilterAction implements UnsafeAction {
  readonly type = SET_DEFAULT_CONTENT_FILTER;
  constructor(public payload: any) {}
}

export const SET_DEFAULT_CONTENT_FILTER_SUCCESS = '[Content Filters] Set default content filter success';
export class SetDefaultContentFilterSuccessAction implements UnsafeAction {
  readonly type = SET_DEFAULT_CONTENT_FILTER_SUCCESS;
  constructor(public payload: any) {}
}

export const DELETE_CONTENT_FILTER = '[Content Filters] Delete content filter';
export class DeleteContentFilterAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_FILTER;
  constructor(public payload: any) {}
}

export const DELETE_CONTENT_FILTER_SUCCESS = '[Content Filters] Delete content filter success';
export class DeleteContentFilterSuccessAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_FILTER_SUCCESS;
  constructor(public payload: any) {}
}

export const CONTENT_FILTER_FAILED_ACTION = '[Content Filters] Content Filters action failed';
export class ContentFiltersFailedAction implements UnsafeAction {
    readonly type = CONTENT_FILTER_FAILED_ACTION;
    // payload is the error
    constructor(public payload: any) { }
}

export const SET_ACTIVE_CONTENT_FILTER = '[Content Filters] Set active content filter';
export class SetActiveContentFilterAction implements UnsafeAction {
  readonly type = SET_ACTIVE_CONTENT_FILTER;
  constructor(public payload: any) {}
}

export const UPDATE_TAXONOMY_INCLUDE_TYPE = '[Advanced filtering] Update Taxonomy include type';
export class UpdateTaxonomyIncludeType implements UnsafeAction {
  readonly type = UPDATE_TAXONOMY_INCLUDE_TYPE;
  constructor(public payload: any) {}
}

export const UPDATE_TAXONOMY_EXCLUDE_TYPE = '[Advanced filtering] Update Taxonomy exclude type';
export class UpdateTaxonomyExcludeType implements UnsafeAction {
  readonly type = UPDATE_TAXONOMY_EXCLUDE_TYPE;
  constructor(public payload: any) {}
}

export const ARTICLE_LIST_RESET_ADVANCED_FILTER = '[Advanced filtering] Reset advanced filtering from Article list';
export class ResetAdvancedFilteringFromArticleList implements UnsafeAction {
  readonly type = ARTICLE_LIST_RESET_ADVANCED_FILTER;
  constructor() {}
}

export const CLEAR_DEFAULT_CONTENT_FILTER = '[Content Filters] Clear default content filter';
export class ClearDefaultContentFilterAction implements UnsafeAction {
  readonly type = CLEAR_DEFAULT_CONTENT_FILTER;
  constructor(public payload: any) {}
}

export const CLEAR_DEFAULT_CONTENT_FILTER_SUCCESS = '[Content Filters] Clear default content filter success';
export class ClearDefaultContentFilterSuccessAction implements UnsafeAction {
  readonly type = CLEAR_DEFAULT_CONTENT_FILTER_SUCCESS;
  constructor(public payload: any) {}
}

export const CLEAR_LOADED_FILTERS = '[Content Filters] Clear loaded filters';
export class ClearLoadedFiltersAction implements UnsafeAction {
  readonly type = CLEAR_LOADED_FILTERS;
  constructor() {}
}
