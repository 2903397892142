import {
  LOAD_POST_BODY_INTO_EDITOR,
  UPDATE_POST_BODY_REFERENCED_CONTENT,
  LOAD_POST_BODY_INTO_EDITOR_SPACER
} from './../store/posts/posts.actions';
import { UnsafeAction } from '../store/unsafe-action.interface';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, distinctUntilChanged, delay, take } from 'rxjs/operators';
import { AppState } from '../store/app-reducer';
import { map } from 'rxjs/operators';
import { ContentModel } from '../store/article/article-content.model';
import { BodyContentUpdateModel, EditorBodyContentService } from './editor-body-content.service.interface';
import {
  COPY_POST_CONTENT_NODES,
  UPDATE_POST_CONTENT_NODE,
  CopyPostContentNodesAction,
  CreatePostContentNodeAction,
  RemovePostContentNodeAction,
  UpdatePostBodyAction,
  UpdatePostContentNodeAction,
} from '../store/posts/posts.actions';
import {
  getPostEditorBodyModel,
  getPostContentNode,
  getPostContentNodesArray,
  getPostReferencedContent,
  getPostWorkingRevision,
  getPostListReferencedContent,
  getPostQuickViewContentNode
} from '../store/posts/posts.reducer';
import { PostListReferencedContentService } from './referenced-content/post-list-referenced-content.service';



/**
 * ArticleContentService fetches and processes article body content
 * based on the current state of active article.
 *
 * It is primarily used by article body editor components as a means of
 * getting content model and structure
 */
@Injectable({
  providedIn: 'root',
})
export class LiveReportPostContentService implements EditorBodyContentService {
  timeout: ReturnType<typeof setTimeout>;

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private postListReferencedContentService: PostListReferencedContentService
  ) { }

  updateContent(contentModel: ContentModel) {
    this.store.dispatch(new UpdatePostBodyAction(contentModel));
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.store.select(getPostListReferencedContent).pipe(take(1)).subscribe(postListReferencedContent => {
        this.postListReferencedContentService.loadReferencedContentForContentModel(contentModel, postListReferencedContent);
      });
    }, 5000);
  }

  getReferencedContent() {
    return this.store.select(getPostReferencedContent);
  }

  // TODO try and form this as ngrx effect, starting from the action, then joining in the content
  getContent(): Observable<BodyContentUpdateModel> {
    return combineLatest([
      this.store.select(getPostEditorBodyModel),
      this.actions$,
    ]).pipe(
      // NOTE: DO NOT add CREATE_ARTICLE_CONTENT_NODE action here, it will cause issues
      delay(50),
      // NOTE this causes issues with referenced content, maybe extract that in a separate stream
      // never fire two events for the exact same action
      distinctUntilChanged(),
      filter(([c, action]) => {
        // These are all the actions which should result in article content updates
        const propagateOnAction = [
          LOAD_POST_BODY_INTO_EDITOR,
          LOAD_POST_BODY_INTO_EDITOR_SPACER,
          UPDATE_POST_CONTENT_NODE,
          COPY_POST_CONTENT_NODES,
          UPDATE_POST_BODY_REFERENCED_CONTENT,
        ];
        return propagateOnAction.includes(action.type);
      }),
      map(([activeRevisionArticleContent, action]) => {
        // Merge article body content and referenced content data
        return {
          content: activeRevisionArticleContent,
          reinitializeContent: this.isContentInitAction(action),
          // this flag allows an event to be emitted, but flagged so that downstream consumers
          // can ignore it. this is a fix which allows LOAD_POST_BODY_INTO_EDITOR to be
          // purged from this stream
          skipEditorUpdate: action.type === LOAD_POST_BODY_INTO_EDITOR_SPACER,
          // TODO investigate when we'd need this set
          resetEditorHasBeenInteractedWith: false
        };
      }),
    );
  }

  /**
   * These actions basically mean that the article content
   * is going to be reinitialized - so, a new content model is
   * loaded and the fieldControl gets set to pristine
   */
  private isContentInitAction(action: UnsafeAction) {
    const contentInitActions = [ LOAD_POST_BODY_INTO_EDITOR ];
    return contentInitActions.includes(action.type);
  }

  getBodyContent() {
    return this.store.select(getPostEditorBodyModel);
  }

  getContentNodesArray() {
    return this.store.select(getPostContentNodesArray);
  }

  getContentNode(nodeId) {
    return this.store.select(getPostContentNode(nodeId));
  }

  copyContentNodes(data: { contentNodes: any[], referencedContent: any }) {
    this.store.dispatch(new CopyPostContentNodesAction(data));
  }

  removeContentNode(nodeId) {
    this.store.dispatch(new RemovePostContentNodeAction(nodeId));
  }

  updateContentNode(nodeData) {
    this.store.dispatch(new UpdatePostContentNodeAction(nodeData));
  }

  createContentNode(nodeId, nodeData) {
    this.store.dispatch(new CreatePostContentNodeAction({ nodeId, nodeData }));
  }

  getValidateLoadedImagePreviews(): Observable<boolean> {
    return of(null).pipe(filter(a => !!a));
  }

  // returning null as we don't care about locales on LRs right now
  getContentLocale() {
    return of(null);
  }

  getQuickViewContentNode(nodeId) {
    return this.store.select(getPostQuickViewContentNode(nodeId));
  }
}
