import { UnsafeAction } from '../unsafe-action.interface';
import { GalleriesState, Gallery } from './galleries.model';
import { createSelector } from 'reselect';

import {
  GET_GALLERIES,
  GET_GALLERIES_COMPLETE,
  CREATE_GALLERY,
  GET_GALLERY,
  GET_GALLERY_COMPLETE,
  CREATE_GALLERY_REVISION_COMPLETE,
  CREATE_GALLERY_REVISION,
  SET_GALLERY_ACTIVE_REVISION,
  DELETE_GALLERY,
  DELETE_GALLERY_COMPLETE,
  SET_PAGE_VIEW_OPTIONS,
  PUBLISH_GALLERY_REVISION,
  GET_GALLERY_REVISION_COMPLETE,
  SET_GALLERY_LOADING_FLAG,
  GET_LOCALIZED_VERSIONS_GALLERIES_SUCCESS,
  GetLocalizedVersions4GalleriesSuccess,
  LOAD_LOCALIZED_VERSIONS,
  LOCALIZE_GALLERY_SUCCESS,
  CLEAR_GALLERIES
} from './galleries.actions';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import { AppState } from '../app-reducer';

const initialState: GalleriesState = {
  loaded: false,
  loading: false,
  galleries: {},
  galleriesView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  localizedVersions: [],
  activeGalleryId: null,
  activeRevisionId: null,
  localizedVersionsList: {}
};

export function galleriesReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {

    case GET_GALLERIES:
    case GET_GALLERY:
    case CREATE_GALLERY:
    case CREATE_GALLERY_REVISION:
    case DELETE_GALLERY:
    case PUBLISH_GALLERY_REVISION: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case GET_GALLERIES_COMPLETE: {
      return {
        ...state,
        galleries: action.payload.galleries,
        galleriesView: { ...state.galleriesView, total: action.payload.total },
        loading: false,
        loaded: true
      };
    }

    case CREATE_GALLERY_REVISION_COMPLETE: {
      const gallery = action.payload;
      return {
        ...state,
        galleries: {
          ...state.galleries,
          [gallery.id]: gallery
        },
        activeGallery: gallery,
        activeRevisionId: gallery.latest.id,
        loaded: true,
        loading: false,
      };
    }

    case LOCALIZE_GALLERY_SUCCESS:
    case GET_GALLERY_COMPLETE: {
      const fetchedGallery = action.payload;
      return {
        ...state,
        galleries: {
          ...state.galleries,
          [fetchedGallery.id]: fetchedGallery
        },
        activeGalleryId: fetchedGallery.id,
        activeRevisionId: fetchedGallery.latest.id,
        loading: false,
        loaded: true
      };
    }

    case GET_GALLERY_REVISION_COMPLETE: {
      let fetchedGalleryRevision = action.payload;
      const galleryToUpdate = { ...state.galleries[fetchedGalleryRevision.gallery_id] };
      const revisionToUpdateIndex = galleryToUpdate.revisions
        .findIndex(revision => revision.id === fetchedGalleryRevision.id);

      fetchedGalleryRevision = {
        ...fetchedGalleryRevision,
        ...galleryToUpdate.revisions[revisionToUpdateIndex],
        fetched: true
      };

      galleryToUpdate.revisions[revisionToUpdateIndex] = fetchedGalleryRevision;

      return {
        ...state,
        galleries: {
          ...state.galleries,
          [galleryToUpdate.id]: galleryToUpdate
        }
      };
    }

    case DELETE_GALLERY_COMPLETE: {
      const newGalleriesState = { ...state.galleries };
      delete newGalleriesState[action.payload];
      return {
        ...state,
        galleries: newGalleriesState,
        loading: false,
        loaded: true
      };
    }

    case SET_GALLERY_ACTIVE_REVISION: {
      return {
        ...state,
        activeRevisionId: action.payload
      };
    }

    case SET_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        galleriesView: {
          ...state.galleriesView,
          currentPage: pageIndex,
          pageSize: pageSize || state.galleriesView.pageSize
        }
      };
    }

    case SET_GALLERY_LOADING_FLAG: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case GET_LOCALIZED_VERSIONS_GALLERIES_SUCCESS: {
      const mappedVersions = (action as GetLocalizedVersions4GalleriesSuccess).localizedVersions
      .reduce((acc, lv) => {
        acc[lv.entityId] = lv;
        return acc;
      }, {})
      return {
        ...state,
        localizedVersionsList: mappedVersions
      }
    }
    case LOAD_LOCALIZED_VERSIONS: {
      return {
        ...state,
        localizedVersions: action.payload.localizedVersions
      };
    }

    case CLEAR_GALLERIES: {
      return {
        ...state,
        galleries: {},
        localizedVersions: [],
        localizedVersionsList: {}
      }
    }

    default: {
      return state;
    }
  }
}

export const getGalleriesState = (state: AppState) => state.galleries
export const getGalleriesArray = createSelector(getGalleriesState, state => Object.values(state.galleries))

export const getGalleries = (state: AppState) => (state.galleries.galleries)
export const getActiveRevisionId = (state) => state.galleries.activeRevisionId;
export const getActiveGalleryId = (state) => state.galleries.activeGalleryId;

export const getActiveGallery = createSelector(getGalleries, getActiveGalleryId, (galleries, activeGalleryId) => {
  const activeGallery = galleries[activeGalleryId] || null;
  if(activeGallery && activeGallery.revisions) {
    // we're getting the revisions array in reverse order (oldest first), so we sort it so that
    // latest revision is now on the beginning of the array. doing this via ID, since they auto increment
    activeGallery.revisions.sort((a, b) => (b.id - a.id));
  }
  return activeGallery;
});

// this will return active revision once it is loaded, i.e. once the
// images for that revision are available
export const getActiveRevision = createSelector(
  getActiveGallery,
  getActiveRevisionId,
  (activeGallery: Gallery, activeRevisionId) => {
    if (!activeGallery || !activeGallery.revisions) {
      return null;
    }
    const revision = activeGallery.revisions.find((r) => r.id === activeRevisionId);
    if (!revision || !revision.images) {
      return null;
    }
    return revision;
  }
);

export const getGalleriesPageView = (state) => state.galleries.galleriesView;

export const getGalleriesLoadingFlag = (state) => state.galleries.loading;

export const getActiveGalleryLocalizedVersions = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.localizedVersions
);

export const getGalleriesListLocalizedVersions = createSelector(
  getGalleriesState,
  (galleriesState) => galleriesState.localizedVersionsList
);