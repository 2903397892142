import { ContentPanel } from './content-panels.model';
import { UnsafeAction } from '../unsafe-action.interface';
import * as contentPanelsAction from './content-panels.actions';
import { createSelector } from 'reselect';

export interface ContentPanelsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  items: ContentPanel[];
}

const initialState: ContentPanelsState = {
  loaded: false,
  loading: false,
  error: null,
  items: []
};

export function contentPanelsReducer(state: ContentPanelsState = initialState, action: UnsafeAction) {
  switch (action.type) {

    case contentPanelsAction.GET_CONTENT_PANELS:
    case contentPanelsAction.CREATE_CONTENT_PANELS:
    case contentPanelsAction.UPDATE_CONTENT_PANELS:
    case contentPanelsAction.DELETE_CONTENT_PANELS: {
      return {
        ...state,
        loading: true
      };
    }

    case contentPanelsAction.GET_CONTENT_PANELS_SUCCESS: {
      const items = action.payload.sort((a, b) => a.position - b.position);
      return {
        ...state,
        loaded: true,
        loading: false,
        items
      };
    }

    case contentPanelsAction.CREATE_CONTENT_PANELS_SUCCESS: {
      const contentPanel = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, contentPanel]
      };
    }

    case contentPanelsAction.DELETE_CONTENT_PANELS_SUCCESS: {
      const deletedId = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        items: state.items.filter(contentPanel => contentPanel.id !== deletedId)
      };
    }

    case contentPanelsAction.UPDATE_CONTENT_PANELS_SUCCESS: {
      const updatedContentPanel: ContentPanel = action.payload;
      const updatedIndex = state.items.findIndex(el => el.id === updatedContentPanel.id);
      const newItems = [
        ...state.items.slice(0, updatedIndex),
        updatedContentPanel,
        ...state.items.slice(updatedIndex + 1)
      ];

      return {
        ...state,
        loading: false,
        error: null,
        items: newItems
      };
    }

    case contentPanelsAction.UPDATE_CONTENT_PANEL_POSITION: {
      const {contentPanelId, currentIndex } = action.payload;

      const updatedContentPanel = state.items.find(item => item.id === contentPanelId);
      updatedContentPanel.position = currentIndex;

      const filteredItems = state.items.filter(el => el.id !== updatedContentPanel.id);
      const reorderedItems = [...filteredItems.slice(0, currentIndex), updatedContentPanel, ...filteredItems.slice(currentIndex)]
      .map((el, position) =>  ({...el, position }));

      return {
        ...state,
        items: reorderedItems
      };
    }

    default: {
      return state;
    }

  }
}

export const getContentPanelState = (state) => state.contentPanels;

export const getContentPanels = createSelector(getContentPanelState, contentPanel => contentPanel.items);

export const getContentPanelsLoading = createSelector(getContentPanelState, contentPanel => contentPanel.loading);
export const getContentPanelsLoaded = createSelector(getContentPanelState, contentPanel => contentPanel.loaded);
