<div class="splide"
     [ngClass]="configuration.key"
     [id]="containerId"
     [dir]="dir$ | async">
  <div class="splide__track">
    <div class="splide__list">
      <div class="splide__slide gd-cf-preview"
           *ngFor="let item of slides"
           [ngClass]="'--field-' + item.fieldType"
           [class.gd-cf-preview--has-promo-image]="!!item.thumbnail || (item.images?.length)"
           [class.gd-cf-preview--has-one-slide]="slides.length <= 1"
           [class.--unpublished]="item.status && item.status.name !== 'Published'">
        <div class="gd-cf-preview__img-wrapper"
             [class.--image]="!!item.thumbnail || (item.images?.length)"
             [ngClass]="!!item.images?.length && ('--images-' + item.images.length)"
             [class.--bg-white]="[FieldType.Gallery].includes(item.fieldType) && item.images?.length">
          <img [src]="item?.thumbnail"
               *ngIf="item?.thumbnail">
          <div *ngIf="item.thumbnail || item.images?.length"
               class="gd-cf-preview__preview-field-wrapper">
            <div class="gd-cf-preview__preview-field-icon-wrapper">
              <ng-container *ngIf="configuration.fieldType === FieldType.FormFragment">
                <mat-icon fontSet="fal"
                          fontIcon="fa-list-alt"></mat-icon>
                <mat-icon fontSet="fal"
                          [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"></mat-icon>
              </ng-container>
              <mat-icon *ngIf="item.glideData"
                        fontSet="fal"
                        [fontIcon]="item.icon"
                        [svgIcon]="item.iconSvg"
                        class="gd-cf-preview__preview-field-icon"></mat-icon>
            </div>
            <span [matTooltip]="item.fieldLabel || configuration.label"
                  class="gd-cf-preview__preview-field-type">
              {{item.fieldLabel || configuration.label}}
            </span>
          </div>
          <!-- handle galleries -->
          <ng-container *ngIf="item.images?.length">
            <img [src]="url"
                 *ngFor="let url of item.images; let i = index"
                 [style.z-index]="item.images.length - i">
          </ng-container>
          <ng-container
                        *ngIf="configuration.fieldType === FieldType.FormFragment && !item.thumbnail && !item.images?.length && (item.icon || item.iconSvg)">
            <mat-icon fontSet="fal"
                      fontIcon="fa-list-alt"></mat-icon>
            <mat-icon fontSet="fal"
                      [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"></mat-icon>
          </ng-container>
          <mat-icon *ngIf="item.glideData && !item.thumbnail && !item.images?.length && (item.icon || item.iconSvg)"
                    fontSet="fal"
                    [fontIcon]="item.icon"
                    [svgIcon]="item.iconSvg"
                    class="gd-cf-preview__field-icon"></mat-icon>
          <span *ngIf="!item.thumbnail && !(item.images?.length)"
                class="gd-cf-preview__field-type"
                [style.width]="(item.status) ? '50%' : 'fit-content'"
                [style.margin]="item.glideData ? '0 10px' : '0'"
                [matTooltip]="item.fieldLabel || configuration.label">
            {{item.fieldLabel || configuration.label}}
          </span>
          <mat-chip-list *ngIf="item.status && (statusMap$ | async)[item.status.name] as status"
                         [class.--no-preview-image]="!item.thumbnail && !(item.images?.length)">
            <mat-chip [matTooltip]="item.status.tooltip || ''"
                      [matTooltipDisabled]="!item.status.tooltip"
                      matTooltipPosition="above"
                      [style.color]="status.color"
                      [style.background-color]="status.backgroundColor">
              <span i18n="@@statusList">{status.name, select,
                Published {Published} Ready {Ready} Review {Review} Scheduled {Scheduled} Unpublished {Unpublished}
                Draft {Draft} Deleted {Deleted} other { {{status.name}} } }</span>
              <mat-icon *ngIf="item.status?.showScheduleIcon"
                        fontSet="fal"
                        fontIcon="fa-clock"></mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div *ngIf="item.notAllowed" class="gd-cf-preview__not-allowed" i18n>
          You don't have permission to view this resource
        </div>
        <div *ngIf="item.values.length > 1"
             class="gd-cf-preview__see-all-button"
             (click)="handleEditItem()">
             <mat-icon class="gd-cf-preview__see-all-button-icon"
             fontSet="fal"
             fontIcon="fa-edit"
             i18n-matTooltip></mat-icon>
          <span i18n>Edit to see all</span>
        </div>
        <div #valueContainer *ngIf="!item.notAllowed" class="gd-cf-preview__values-wrapper"
             [class.--no-preview-image]="!item.thumbnail && !(item.images?.length)">

          <span *ngIf="!hasValueSet(item)"
                class="gd-cf-preview__value"
                i18n>Preview field value is not selected</span>
          <div class="gd-cf-preview__value"
               [class.--code-snippet]="item.fieldType === FieldType.Snippet"
               *ngFor="let val of item.values"
               [style.width.%]="100"
               [style.padding-bottom]="item.values.length > 1 ? '4px' : 'unset'"
               [style.border-bottom]="item.values.length > 1 ? '0.5px solid #E0E0E0' : 'none'">
            <ng-container [ngSwitch]="item.fieldType">
              <!-- CHECKBOX -->
              <mat-checkbox *ngSwitchCase="FieldType.CheckboxGroup"
                            [checked]="val.checked"
                            [title]="val.label"
                            disabled>{{ val.label }}</mat-checkbox>

              <!-- TOGGLE -->
              <mat-slide-toggle *ngSwitchCase="FieldType.Toggle"
                                [checked]="val.checked"
                                disabled>{{ val.label }}</mat-slide-toggle>

              <!-- RADIO BUTTON -->
              <mat-radio-button *ngSwitchCase="FieldType.RadioGroup"
                                [checked]="val.checked"
                                [title]="val.label"
                                disabled>{{ val.label }}</mat-radio-button>

              <ng-container *ngSwitchDefault>
                <img *ngIf="val?.icon"
                     [src]="val?.icon">
                <div class="gd-cf-preview__colour-indicator"
                     [style.background-color]="val.label"
                     *ngIf="item.fieldType === FieldType.Colour"></div>
                <span class="gd-cf-preview__value-text"
                      [class.--preview-image]="!!item.thumbnail || (item.images?.length > 1)">
                  <ng-container *ngIf="localesMap$ | async as locales">
                    <img *ngIf="locales[item.contentLocaleId]"
                         [style.margin]="(dir$ | async) !== 'rtl' ? '0 6px 0 0' : '0 0 0 6px'"
                         [src]="locales[item.contentLocaleId].iconUrl || '/assets/img/flags/xx.svg'">
                  </ng-container>
                  <a [title]="val.alt || val.label"
                     [href]="val.link"
                     target="_blank"
                     *ngIf="val.link">
                    {{ val.label }}
                    <mat-icon *ngIf="!val.label"
                              matTooltip="Open in new tab"
                              i18n-matTooltip
                              matTooltipPosition="below">open_in_new</mat-icon>
                  </a>
                  <span *ngIf="!val.link"
                        [innerHTML]="val.label"></span>
                  <mat-icon class="gd-cf-preview__tooltip"
                            fontSet="fal"
                            [fontIcon]="val.tooltip?.icon || 'fa-exclamation-circle'"
                            [matTooltip]="val.tooltip?.message"
                            [style.color]="val.tooltip?.color || '#b00020'"
                            *ngIf="val.tooltip"></mat-icon>
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
