import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
    GET_ACCOUNT_SETTINGS, GET_ACCOUNT_SETTINGS_SUCCESS, UPDATE_ACCOUNT_SETTINGS,
    UPDATE_ACCOUNT_SETTINGS_SUCCESS, ACCOUNT_SETTINGS_ACTION_FAILED, UPDATE_SETTINGS_REFERENCED_CONTENT, REFRESH_ACCOUNT_SETTINGS_SUCCESS, UPDATE_INTEGRATION_CONFIG, UPDATE_INTEGRATION_CONFIG_SUCCESS, SET_ACCOUNT_SETTINGS_LOADING_FLAG
} from './account-settings.actions';

interface AccountSettingsItem {
    [key: string]: string;
}

export interface AccountSettingsState {
    loaded: boolean;
    loading: boolean;
    error: string;
    settings: { [key: string]: AccountSettingsItem };
    referencedContent: { [key: string]: any };
}

const initialState: AccountSettingsState = {
    loaded: false,
    loading: false,
    error: null,
    settings: {},
    referencedContent: {}
};

export function accountSettingsReducer(state: AccountSettingsState = initialState, action: UnsafeAction) {

    switch (action.type) {

      case GET_ACCOUNT_SETTINGS:
      case UPDATE_INTEGRATION_CONFIG: {
            return { ...state, loading: true };
        }

        case GET_ACCOUNT_SETTINGS_SUCCESS: {
            const settings = { ...action.payload };
            return {
                loaded: true,
                loading: false,
                error: null,
                settings,
                referencedContent: {}
            };
        }

        case UPDATE_ACCOUNT_SETTINGS: {
            return { ...state, loading: true };
        }

        case UPDATE_ACCOUNT_SETTINGS_SUCCESS: {
            const settings = { ...action.payload };
            return {
                ...state,
                loading: false,
                error: null,
                settings
            };
        }

        case UPDATE_SETTINGS_REFERENCED_CONTENT: {
            return { ...state, referencedContent: action.payload };
        }

        case ACCOUNT_SETTINGS_ACTION_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        }

        case REFRESH_ACCOUNT_SETTINGS_SUCCESS: {
            const settings = { ...action.payload };
            return { ...state, settings };
        }

        case UPDATE_INTEGRATION_CONFIG_SUCCESS: {
          return { ...state, loading: false };
        }

        case SET_ACCOUNT_SETTINGS_LOADING_FLAG: {
          return { ...state, loading: action.payload };
        }

        default: {
            return state;
        }
    }

}

export const getAccountSettingsState = (state) => state.accountSettings;

export const getAccountSettings = createSelector(getAccountSettingsState, state => state.settings);

export const getAccountPreviewSettings = createSelector(
  getAccountSettings,
  accountSettings => accountSettings && accountSettings.previewService || null
);

export const getAccountSettingsLoading = createSelector(getAccountSettingsState, state => state.loading);

export const getAccountSettingsLoaded = createSelector(getAccountSettingsState, state => state.loaded);

export const getBaseUrl = createSelector(getAccountSettingsState, state => {
    if (state.settings && state.settings.siteConfig) {
        return state.settings.siteConfig.baseUrl || '';
    }
    return '';
});

export const getArticlePageRoute = createSelector(getAccountSettingsState, state => {
    const isArticlePageSet = state.settings && state.settings.pagesConfig && state.settings.pagesConfig.articlePageId;
    const isRefContentLoaded = Object.keys(state.referencedContent).length !== 0;
    const defaultRoutePattern = '/<rootSection>/<articleId>/<seoHeadline>';
    if (!isArticlePageSet || !isRefContentLoaded) {
        return defaultRoutePattern;
    }
    const articlePageId = state.settings.pagesConfig.articlePageId;

    const routes = state.referencedContent[articlePageId].routes;
    if (!routes || routes.length === 0) {
        return defaultRoutePattern;
    }

    return (routes.find(route => route.pathPattern.includes('sectionPath')) || routes[0]).pathPattern;
});

export const getTimezone = createSelector(getAccountSettingsState, state => {
    if (state.settings && state.settings.siteConfig) {
        return state.settings.siteConfig.timezone || 'Europe/London';
    }
    return null;
});

export const getBrightcoveSettings = createSelector(getAccountSettingsState, state => {

  const brightcoveSettings = state.settings && state.settings.brightcove;
  if (!brightcoveSettings) {
    return null;
  }
  const areSettingsValid = Object.keys(brightcoveSettings).filter(key => !brightcoveSettings[key]).length === 0;
  return areSettingsValid ? brightcoveSettings : null;
});

export const getDailymotionSettings = createSelector(getAccountSettingsState, state => {

  const dailymotionSettings = state.settings && state.settings.dailymotion;
  if (!dailymotionSettings) {
    return null;
  }
  const areSettingsValid = Object.keys(dailymotionSettings).filter(key => !dailymotionSettings[key]).length === 0;
  return areSettingsValid ? dailymotionSettings : null;
});

export const getTwitchSettings = createSelector(getAccountSettingsState, state => {

  const twitchSettings = state.settings && state.settings.twitch;
  if (!twitchSettings) {
    return null;
  }
  const areSettingsValid = Object.keys(twitchSettings).filter(key => !twitchSettings[key]).length === 0;
  return areSettingsValid ? twitchSettings : null;
});

