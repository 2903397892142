import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { AccountSettingsService } from '../api/account-settings/accounts-settings.service';

@Injectable()
export class DateFormatService {
  get activeTimezone() {
    return this.accountSettingsService.getTimezone();
  }

  get defaultFormat() {
    return this.accountSettingsService.getShortDateFormat() || 'DD-MMM-YYYY h:mm A';
  }

  constructor(
    private accountSettingsService: AccountSettingsService,
  ) { }

  getFormatedDate(time, format = null, timezone = null) {
    format = format || this.defaultFormat;
    timezone = timezone || this.activeTimezone;
    return time && moment(time).tz(timezone).format(format);
  }

  getLocalizedTimestamp(time, timezone = null) {
    time = time || moment().valueOf();
    timezone = timezone || this.activeTimezone;
    return moment.tz(moment(time).format('YYYY-MM-DD HH:mm'), timezone);
  }

  /**
   * This function is NOT timezone aware, it will treat the timestamp passed in to it
   * according to local timezone.
   * TODO test if this acts as expected if Base Settings > Timezone is set to other than local time
   *
   * @returns date (default format, beginning of the day) and time ('HH:mm') strings
   */
  extractDateAndTimeFromTimestamp(timestamp) {
    const time = moment(timestamp).format('HH:mm');
    const date = moment(timestamp).startOf('day').format();
    return { date, time };
  }
}

// TODO analyze the usage of this carefully and see if any problems occur
// this function transforms HH:mm time format into milliseconds. it is NOT timezone aware
// it is primarily used in filtering on article and collections list
// the entire data + time conversion should be handled by DateFormatService to ensure correct behavior
export function getTimeInMilliseconds(time) {
  // time format: 'HH:mm' (e.g. '14:30')
  if (!time) {
    return 0;
  }
  const timeParts = time.split(':');
  return (timeParts[0] * 3600 + timeParts[1] * 60) * 1000;
}

export function extractDateAndTimeFromTimestamp(timestamp) {
  const time = moment(timestamp).format('HH:mm');
  const date = moment(timestamp).startOf('day').format();
  return { date, time };
}
