import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from '../rest.service';

@Injectable()
export class AdNetworkSettingsService {
  constructor(private rest: RestService) {}

  getAdNetworkSettingsSizes() {
    return this.rest.get('widgets/ad-network-sizes').pipe(map((sizeData) => sizeData.data));
  }

  getAdvertTypes() {
    return this.rest.get('widgets/advert-types').pipe(map((typeData) => typeData.data));
  }
}
