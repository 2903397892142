<mat-card class="gd-image-upload" [ngClass]="config.viewAs" [dir]="dir$ | async">
  <div
    fxLayout="row"
    fxLayoutGap="18px"
    fxLayoutAlign="end center"
    class="gd-image-upload__header"
    [style.padding-bottom]="'10px'"
  >
    <div
      class="gd-image-upload__header-actions"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxFlex="100%"
      fxLayoutGap="8px"
    >
      <div fxLayout="row" fxLayoutGap="8px">
        <button
          *ngIf="cropperActive && !imageCropper?.showCropPreviews"
          mat-raised-button
          color="primary"
          i18n
          (click)="discardImageCrop()"
        >
          Back
        </button>
      </div>

      <div fxLayout="row"
           fxLayoutGap="10px">
          <div [matTooltip]="errorMessage"
               [matTooltipDisabled]="!hasGettyError"
               *ngIf="!generateImageTab">
            <button *ngIf="!cropperActive && !imageToCrop?.processing"
                    mat-button
                    mat-raised-button
                    class="gd-image-getty__button"
                    [disabled]="queueInProcessing || hasGettyError"
                    (click)="loadGettyWidget()">
              <mat-icon *ngIf="!isGettyLoaded"
                        class="gd-image-upload__getty-icon-spinner"
                        fontSet="far"
                        fontIcon="fa-spinner-third"></mat-icon>
              <ng-container *ngIf="isGettyLoaded">
                <span class="gd-image-upload__getty-icon"></span>
                <span i18n>Import from Getty</span>
                <mat-icon *ngIf="hasGettyError"
                          fontSet="far"
                          fontIcon="fa-info-circle"></mat-icon>
              </ng-container>
            </button>
          </div>
        <button
          *ngIf="!cropperActive && hasEnqueuedImages && !imageToCrop?.processing && !generateImageTab"
          mat-button
          mat-raised-button
          [disabled]="queueInProcessing"
          (click)="openImageSelectorDialog()"
        >
          <mat-icon svgIcon="glideCustomIcon_image-search"></mat-icon>
          <span i18n>Browse</span>
        </button>

        <button
          mat-raised-button
          color="primary"
          *ngIf="cropperActive && !imageToCrop.cropped"
          (click)="handleCropImage()"
        >
          <mat-icon>crop</mat-icon>
          <span style="margin-left: 4px" i18n>Crop</span>
        </button>

        <button
          mat-button
          mat-raised-button
          color="primary"
          data-cy="gd-image-upload__button"
          class="gd-image-upload__button"
          [class.--disabled]="!hasEnqueuedImages || queueInProcessing || uploadDisabled"
          [disabled]="!hasEnqueuedImages || queueInProcessing || uploadDisabled"
          *ngIf="!cropperActive && !imageToCrop?.processing && config.viewAs === 'standalone' && !generateImageTab"
          (click)="startUpload()"
        >
          <mat-icon fontSet="fal" fontIcon="fa-cloud-upload"></mat-icon>
          <span style="margin-left: 4px" i18n>Upload</span>
        </button>
      </div>
    </div>
  </div>

  <!-- Dropzone block -->
  <gd-dropzone
    *ngIf="!generateImageTab"
    #dropzone
    [dialog]="'native'"
    [dzConfig]="dzConfig"
    data-cy="gd-dropzone"
    class="gd-image-upload-dropzone-wrapper"
    [class.reduce-dropzone]="queuedImages.length !== 0"
    [class.hide-dropzone]="cropperActive || (queuedImages.length !== 0 && dzConfig.embedModal) || imageToCrop?.processing"
    [class.small-screen]="queuedImages.length === 0 && dzConfig.embedModal"
    (queuedFilesEvent)="handleQueuedFilesEvent($event)"
  >
  </gd-dropzone>

  <!-- Queued Images Grid Block for Standalone page -->
  <div *ngIf="hasEnqueuedImages" style="height: 100%; max-height: calc(100% - 60px);">
    <gd-scroller style="padding: 0 4px">
      <!-- <gd-drag-and-drop-wrapper
        *ngIf="queuedImages.length !== 0 && !cropperActive"
        (dropzoneConfig)="({ triggerMethod: 'default', dialog: 'native' })"
      > -->
      <gd-images-view-grid
        *ngIf="config.multiUpload && !generateImageTab"
        [collection]="queuedImages"
        (triggerRemoveImageFromList)="removeImageFromQueue($event)"
        (triggerCropImage)="toggleCropImage($event)"
        (triggerAddImageMeta)="triggerAddImageMeta($event)"
        [options]="gridConfig"
        [style.height]="cropperActive ? 0 : 'unset'"
        [style.margin-top]="0">
      </gd-images-view-grid>
      <!-- </gd-drag-and-drop-wrapper> -->
    </gd-scroller>

    <gd-image-crop-edit
      #imageCropper
      *ngIf="cropperActive"
      [image]="imageToCrop"
      [crops]="imageToCrop?.crops"
      [cropOptions]="cropOptions"
      (discardCropEvent)="handleDiscardCropEvent()"
      (saveCropEvent)="handleSaveImageCrop()"
    >
    </gd-image-crop-edit>
  </div>

  <div *ngIf="!hasEnqueuedImages && generateImageTab && !cropperActive">
    <gd-generate-image-form #generateImageForm (queuedFilesEvent)="handleQueuedFilesEvent($event)" (imageGeneratedFormEmitter)="imageGeneratedChange($event)" [imageData]="imageData" [usage]="usage"></gd-generate-image-form>
  </div>

  <div *ngIf="cropperActive && !imageToCrop.cropped"
       fxLayout="row"
       fxLayoutAlign="flex-end"
       class="gd-image-upload__footer-actions">
    <button mat-raised-button
            color="primary"
            (click)="handleCropImage()">
      <mat-icon>crop</mat-icon>
      <span style="margin-left: 4px" i18n>Crop</span>
    </button>
  </div>

  <div
    *ngIf="config.viewAs === 'standalone'"
    fxLayout="row"
    fxLayoutAlign="space-between"
    class="gd-image-upload__footer-actions"
  >
    <button *ngIf="!cropperActive && !imageToCrop" mat-button mat-raised-button routerLink="/media/images" i18n>
      Back
    </button>
  </div>
</mat-card>
