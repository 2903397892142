import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { of } from 'rxjs';

@Injectable()
export class MultichannelPublishingService {
  constructor(private rest: RestService) {}

  getMultichannelPublishingInfo({ id, revisionId, type }: any = {}) {
    return this.rest
      .get(`multi-channel-publishing/${type}/${id}/revisions/${revisionId}`)
      .pipe(map(({ data }) => data));
  }

  send(payload: { resourceId, resourceType, revisionId, subscriberId }) {
    return this.rest.post('multi-channel/publish', payload);
  }
}
