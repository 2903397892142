import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ArticlesService } from '../../api';
import { UnsafeAction } from '../unsafe-action.interface';
import { GetArticleQuickViewSuccessAction, GET_ARTICLE_QUICK_VIEW } from './article-quick-view.actions';

@Injectable()
export class ArticleQuickViewEffects {
  constructor(private actions$: Actions, private articlesService: ArticlesService) {}
  
  loadArticleQuickView$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ARTICLE_QUICK_VIEW),
    debounceTime(200),
    mergeMap((action: UnsafeAction) => {
      return this.articlesService.getArticleById(action.payload).pipe(
        map((article) => {
          return new GetArticleQuickViewSuccessAction(article);
        })
        // catchError((e) => {
        //   return of(new PostListFailedAction(e));
        // })
      );
    })
  ));
}
