import { UnsafeAction } from '../unsafe-action.interface';

import {
  GET_IMAGES,
  GET_IMAGES_COMPLETE,
  GET_IMAGES_FAILED,
  GET_TAGS_COMPLETE,
  UPDATE_IMAGE_DETAILS,
  UPDATE_IMAGE_DETAILS_COMPLETE,
  DELETE_IMAGE,
  SET_SELECTED_IMAGE,
  SET_PAGE_VIEW_OPTIONS,
  CLEAR_IMAGES_STATE,
  RESET_IMAGES_STATE,
  SET_IMAGES_LOADING_FLAG,
  CLEAR_TAGS_ACTION,
  DELETE_IMAGE_SUCCESS,
} from './images.actions';
import { Image } from './images.model';
import { createSelector } from '@ngrx/store';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import { filter } from 'lodash-es';

export interface ImagesState {
  loading: boolean;
  loaded: boolean;
  images: { [key: string]: Image };
  selectedImage: {};
  imagesView: {};
  requestError: string;
}

export const initialState = {
  loading: false,
  loaded: false,
  tags: [],
  images: {},
  selectedImage: {},
  imagesView: {
    pageIndex: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions,
  },
  requestError: null,
};

export function imagesReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {
    case UPDATE_IMAGE_DETAILS:
    case GET_IMAGES: {
      return {
        ...state,
        imagesView: {
            ...state.imagesView,
            pageIndex: action.payload.page,
        },
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_IMAGE_DETAILS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_IMAGES_COMPLETE: {
      const imagesMap = action.payload.items.reduce((acc, curr) => {
        return { ...acc, [curr.id]: { ...curr } };
      }, {});
      return {
        ...state,
        loading: false,
        loaded: true,
        images: { ...imagesMap },
        imagesView: {
          ...state.imagesView,
          total: action.payload.total,
        },
        requestError: null,
      };
    }

    case GET_TAGS_COMPLETE: {
      const tagPriorityMap = { 'system': 1, 'user': 2, 'rekognition': 3 };
      const uniqueTags = action.payload.reduce((acc, tag) => {
        if (acc[tag.name]) {
          acc[tag.name] = {
            ...tag,
            // needed for tags autocomplete field and filtering (should be improved in future)
            versions: [...acc[tag.name].versions, tag].sort((a, b) => tagPriorityMap[a.group] < tagPriorityMap[b.group] ? -1 : 1),
          };
        } else {
          acc[tag.name] = { ...tag, versions: [tag] };
        }

        return acc;
      }, {});

      const uniqueTagsArray = Object.values(uniqueTags);
      return { ...state, tags: uniqueTagsArray };
    }

    case UPDATE_IMAGE_DETAILS_COMPLETE: {
      const { imageId, imageData } = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        images: {
          ...state.images,
          [imageId]: {
            ...state.images[imageId],
            metaData: [...imageData],
          },
        },
      };
    }

    case SET_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        imagesView: {
          ...state.imagesView,
          pageIndex,
          pageSize,
        },
      };
    }

    case SET_SELECTED_IMAGE: {
      return {
        ...state,
        selectedImage: action.payload,
      };
    }

    case CLEAR_IMAGES_STATE: {
      return {
        ...state,
        images: {},
      };
    }

    case RESET_IMAGES_STATE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case GET_IMAGES_FAILED: {
      return {
        ...state,
        loaded: false,
        loading: false,
        requestError: action.payload,
      };
    }

    case SET_IMAGES_LOADING_FLAG: {
      const loading = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case CLEAR_TAGS_ACTION: {
      return {
        ...state,
        tags: []
      }
    }
    
    case DELETE_IMAGE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    }

    case DELETE_IMAGE_SUCCESS: {
      return Object.assign({}, state, {
        images: filter(state.images, (img: any) => img.id !== action.payload),
      });
    }

    default: {
      return state;
    }
  }
}

export const getImages = state =>
  Object.keys(state.images.images).map(image => state.images.images[image]);
export const getImagesTags = state => state.images.tags;
export const getImagesView = state => state.images.imagesView;
export const getSelectedImage = state => state.images.selectedImage;

export const getImagesData = createSelector(
  getImages,
  images => images
);
