import { map, tap } from 'rxjs/operators';
import { FieldGroup } from './../../store/field-groups/field-groups.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RestService } from '../rest.service';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { PassthroughCacheFactoryService } from '../cache/api-cache-factory.service';

@Injectable()
export class FieldGroupsService {

  /**
   * This cache is used instead of simple get request, it will first try to
   * get the requested URL from ongoing requests buffer, then from cache,
   * and finally from the API by using the passed apiClient
   */
  passthroughCache = this.passthroughCacheFactory.createCache({
    domain: 'custom-field-groups',
    apiClient: this.rest,
    ttl: 60 * 1000
  });


  constructor(
    private rest: RestService,
    private passthroughCacheFactory: PassthroughCacheFactoryService,
  ) {}

  getFieldGroups({ ids = null, name= '', types = [], pageIndex = 0, pageSize = defaultPageSize }: any = {}): Observable<{
    fieldGroups: FieldGroup[];
    meta: any;
  }> {
    let requestPath = `custom-field-groups?page=${pageIndex || 0}&size=${pageSize || defaultPageSize}`;
    requestPath += name ? `&name=${name}` : '';
    requestPath += types && types.length ? `&types=${types.join(',')}` : '';
    requestPath += ids ? `&ids=${ids}` : '';
    return this.passthroughCache.getData(requestPath).pipe(
      map((customFieldGroups) => {
        return {
          meta: customFieldGroups.meta,
          fieldGroups: customFieldGroups.data,
        };
      })
      );
  }

  getFieldGroup(id): Observable<FieldGroup> {
    return this.passthroughCache.getData(`custom-field-groups/${id}`).pipe(
      map((customFieldGroups) => {
        return customFieldGroups.data;
      })
    );
  }

  createFieldGroup(contentFilter): Observable<FieldGroup> {
    const newContentFilter = { ...contentFilter, id: Date.now() };
    return this.rest
      .post('custom-field-groups', newContentFilter)
      .pipe(
        tap(() => this.passthroughCache.clear()),
        map((newFieldGroup) => newFieldGroup.data)
      );
  }

  updateFieldGroup(contentFilter): Observable<FieldGroup> {
    return this.rest
      .put(`custom-field-groups/${contentFilter.id}`, contentFilter)
      .pipe(
        tap(() => this.passthroughCache.clear()),
        map((updatedFieldGroup) => updatedFieldGroup.data)
      );
  }

  deleteFieldGroup(id): Observable<boolean> {
    return this.rest.delete(`custom-field-groups/${id}`).pipe(
      tap(() => this.passthroughCache.clear()),
      map((articleData: any) => {
        return articleData.data;
      })
    );
  }

  getReferencedCustomFieldGroupsByIds(customFieldGroupIds) {
    if (customFieldGroupIds.length < 1) {
      return of([]);
    }

    const size = customFieldGroupIds.length <= 200 ? customFieldGroupIds.length : 200;

    return this.passthroughCache.getData(`custom-field-groups?ids=${customFieldGroupIds.join(',')}&size=${size}`)
      .pipe(map((customFieldGroups: any) => customFieldGroups.data));
  }
}
