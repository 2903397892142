import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  AfterViewInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { GridOptions } from './grid-options.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { queueBeingProcessed } from '../../core/store/images-upload/images-upload.reducer';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { Permissions } from '../../core/store/auth/permissions';
import { fileQueueBeingProcessed } from '../../core/store/files-upload/files-upload.reducer';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'gd-images-view-grid',
  templateUrl: './images-view-grid.component.html',
  styleUrls: ['./images-view-grid.component.scss'],
})
export class ImagesViewGridComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() collection;
  @Input() options = {};
  @Input() hasDeletePermission = true;

  @Output() toggleEditImage = new EventEmitter();
  @Output() triggerDeleteImage = new EventEmitter();
  @Output() triggerPreviewImage = new EventEmitter();
  @Output() triggerRemoveImageFromList = new EventEmitter();
  @Output() triggerDownloadImage = new EventEmitter();
  @Output() triggerCropImage = new EventEmitter();
  @Output() triggerAddImageMeta = new EventEmitter();
  @Output() triggerEditFile = new EventEmitter();

  @Input() selectedImages = [];
  @Output() selectedImagesDispatcher = new EventEmitter();

  @Output() layoutUpdated = new EventEmitter();
  @Output() setIsDropzoneEnabled = new EventEmitter();
  @Input() hasActionPermission = true;

  gridOptions: GridOptions = {
    viewAs: 'standalone',
    view: '',
    showActionControls: true,
    showUploadActionControls: false,
    enableSelection: false,
    multipleSelection: false,
    canRemoveFromList: false,
    showProgressOverlay: false,
    showProcessedIcon: false,
    fixedGrid: false,
    gap: 8,
    columns: 5, // Cplumns number initially set to 5 since defined page sizes for images collection are 20,40 etc
    thumbnail: {
      width: 220,
      height: 165,
      aspectRatio: 4 / 3,
    },
    thumbnailDraggable: false,
    showGalleryIcon: false,
    sortable: false,
  };

  gridProperties = <any>{};
  @Input() loading;
  uploadQueueInProcess$ = this.store.select(queueBeingProcessed);
  fileQueueInProcess$ = this.store.select(fileQueueBeingProcessed);

  @Output() sortElements = new EventEmitter();
  sortableOptions = {
    animation: 200,
    sort: !!this.gridOptions.sortable,
    onUpdate: (event: any) => {
      const { oldIndex, newIndex } = event;
      this.sortElements.emit({ oldIndex, newIndex });
    },
  };

  @ViewChild('grid') gridWrapperEl: ElementRef;

  constructor(
    private store: Store<AppState>,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.gridOptions = { ...this.gridOptions, ...this.options };
  }

  ngAfterViewInit() {
    if (!this.loading) {
      setTimeout(() => (this.loading = this.store.select(store => store.images.loading)), 0);
    }

    // Add event listener on drop to prevent img opening if dropped on grid
    this.gridWrapperEl.nativeElement.addEventListener(
      'dragover',
      e => {
        e.preventDefault();
      },
      true
    );

    this.gridWrapperEl.nativeElement.addEventListener(
      'drop',
      e => {
        e.preventDefault();
      },
      true
    );

    // this.gridWrapperEl = this.elRef.nativeElement.querySelector('.gd-images-view-grid__wrapper');
    this.updateGridProps();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.collection &&
      changes.collection.currentValue !== changes.collection.previousValue
    ) {
      this.collection = changes.collection.currentValue;
    }

    if (changes.options && changes.options.currentValue) {
      this.gridOptions = { ...this.gridOptions, ...changes.options.currentValue };
      this.sortableOptions = { ...this.sortableOptions, sort: !!this.gridOptions.sortable };
    }
  }

  processQue() {
    if (this.gridOptions.view !== 'files') {
      return this.uploadQueueInProcess$;
    } else {
      return this.fileQueueInProcess$;
    }
  }
  toggleEdit(image) {
    this.toggleEditImage.emit(image);
  }

  deleteImage(image) {
    this.triggerDeleteImage.emit(image);
  }

  previewImage(image) {
    this.triggerPreviewImage.emit(image);
  }

  selectImage(image) {
    if (this.gridOptions.enableSelection) {
      if (this.gridOptions.multipleSelection) {
        const imageAlreadySelected = this.selectedImages.find(img => img.id === image.id);
        if (imageAlreadySelected) {
          this.selectedImages = this.selectedImages.filter(img => img.id !== image.id);
        } else {
          this.selectedImages = [...this.selectedImages, image];
        }
      } else {
        this.selectedImages = [image];
      }

      // Dispatch event with selected images array after it's updated
      this.selectedImagesDispatcher.emit(this.selectedImages);
    }
  }

  removeImageFromList(image) {
    this.triggerRemoveImageFromList.emit(image);
  }

  dragstartHandle(event, row) {
    this.setIsDropzoneEnabled.emit(false);
    if (this.gridOptions.thumbnailDraggable) {
      const thumb = document.getElementById('image-thumb-' + row.id);
      if (event.dataTransfer.setDragImage) {
        event.dataTransfer.setDragImage(thumb, 75, 75);
      }
      const data = JSON.stringify({
        type: 'image',
        id: row.id,
        preview: row.formats['150x100'],
      });
      event.dataTransfer.setData('text/plain', data);
    }
  }

  updateGridProps() {
    const wrapperRect = this.gridWrapperEl.nativeElement.getBoundingClientRect();

    let gridColumns: any = 'auto-fill';
    const thumbnailAspectRatio: any = this.gridOptions.thumbnail.aspectRatio;
    const gridGap = this.gridOptions.gap;
    let thumbnailWidth = this.gridOptions.thumbnail.width;
    const thumbnailHeight = +(thumbnailWidth / thumbnailAspectRatio).toFixed(0);

    if (this.gridOptions.fixedGrid) {
      const densedGridWidth = wrapperRect.width - this.gridOptions.columns * gridGap;
      thumbnailWidth = +(densedGridWidth / this.gridOptions.columns).toFixed(0);
      gridColumns = this.gridOptions.columns;
    }
    // Set grid gap
    this.renderer.setStyle(this.gridWrapperEl.nativeElement, 'grid-gap', `${gridGap}px`);
    // Set minimal width for grid fraction and set grid reflow/columns behavior
    this.setGridColumns(gridColumns, thumbnailWidth);
    // Set min-height for thumbnail in order to reserve space and placholder (used within template)
    this.gridProperties = {
      [this.gridOptions.fixedGrid ? 'height' : 'min-height']: `${thumbnailHeight}px`,
    };

    if (!this.gridOptions.fixedGrid) {
      this.gridProperties.height = '100%';
    }
  }

  setGridColumns(columns, columnWidth) {
    this.renderer.setStyle(
      this.gridWrapperEl.nativeElement,
      'grid-template-columns',
      `repeat(${columns}, minmax(${columnWidth}px, 1fr))`
    );
  }

  checkIfImageIsSelected(imageToCheck) {
    if (this.selectedImages.length) {
      return this.selectedImages.some(img => img.id === imageToCheck.id);
    }

    return false;
  }

  toggleDownload(image) {
    this.triggerDownloadImage.emit(image);
  }

  getThumbnailTitle(thumbnail) {
    let title = thumbnail.title ? thumbnail.title : '';
    const isGalleryModal = this.gridOptions.showGalleryIcon;
    if (thumbnail.imageMetaData && thumbnail.imageMetaData.headline && !isGalleryModal) {
      title = thumbnail.imageMetaData.headline;
    }

    return title;
  }

  trackGridList(index, item) {
    return item && item.id ? item.id : index;
  }
}
