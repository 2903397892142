import { Action } from '@ngrx/store';
import { FieldGroup } from './field-groups.model';

export enum FieldGroupsActionTypes {
  LOAD = '[FieldGroups] Load List',
  LOAD_SUCCESS = '[FieldGroups] Load List Success',
  LOAD_ONE = '[FieldGroups] Load One',
  LOAD_ONE_SUCCESS = '[FieldGroups] Load One Success',
  SET_ACTIVE = '[FieldGroups] Set Active Id',
  CREATE = '[FieldGroups] Create',
  CREATE_SUCCESS = '[FieldGroups] Create Success',
  UPDATE = '[FieldGroups] Update',
  UPDATE_SUCCESS = '[FieldGroups] Update Success',
  DELETE = '[FieldGroups] Delete',
  DELETE_SUCCESS = '[FieldGroups] Delete Success',
  FAILURE = '[FieldGroups] Load Failure',
  SET_FIELD_GROUPS_PAGE_VIEW = '[FieldGroups] Set Field Groups Page View',
}

export class LoadFieldGroupsAction implements Action {
  readonly type = FieldGroupsActionTypes.LOAD;
  constructor(public payload: any) {}
}

export class LoadFieldGroupsSuccessAction implements Action {
  readonly type = FieldGroupsActionTypes.LOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadOneFieldGroupAction implements Action {
  readonly type = FieldGroupsActionTypes.LOAD_ONE;
  constructor(public payload: number) {}
}

export class LoadOneFieldGroupSuccessAction implements Action {
  readonly type = FieldGroupsActionTypes.LOAD_ONE_SUCCESS;
  constructor(public payload: FieldGroup) {}
}

export class CreateFieldGroupAction implements Action {
  readonly type = FieldGroupsActionTypes.CREATE;
  constructor(public payload: FieldGroup) {}
}

export class CreateFieldGroupSuccessAction implements Action {
  readonly type = FieldGroupsActionTypes.CREATE_SUCCESS;
  constructor(public payload: FieldGroup) {}
}

export class UpdateFieldGroupAction implements Action {
  readonly type = FieldGroupsActionTypes.UPDATE;
  constructor(public payload: FieldGroup) {}
}

export class UpdateFieldGroupSuccessAction implements Action {
  readonly type = FieldGroupsActionTypes.UPDATE_SUCCESS;
  constructor(public payload: FieldGroup) {}
}

export class DeleteFieldGroupAction implements Action {
  readonly type = FieldGroupsActionTypes.DELETE;
  constructor(public payload: number) {}
}

export class DeleteFieldGroupSuccessAction implements Action {
  readonly type = FieldGroupsActionTypes.DELETE_SUCCESS;
  constructor(public payload: number) {}
}

export class SetActiveFieldGroupAction implements Action {
  readonly type = FieldGroupsActionTypes.SET_ACTIVE;
  constructor(public payload: number) {}
}

export class SetFieldGroupsPageViewAction implements Action {
  readonly type = FieldGroupsActionTypes.SET_FIELD_GROUPS_PAGE_VIEW;
  constructor(public payload: any) {}
}

export class FailureFieldGroupAction implements Action {
  readonly type = FieldGroupsActionTypes.FAILURE;
  constructor(public payload: { error: Error }) {}
}

export type FieldGroupActions =
  | LoadFieldGroupsAction
  | LoadFieldGroupsSuccessAction
  | LoadOneFieldGroupAction
  | LoadOneFieldGroupSuccessAction
  | CreateFieldGroupAction
  | CreateFieldGroupSuccessAction
  | UpdateFieldGroupAction
  | UpdateFieldGroupSuccessAction
  | DeleteFieldGroupAction
  | DeleteFieldGroupSuccessAction
  | SetActiveFieldGroupAction
  | FailureFieldGroupAction
  | SetFieldGroupsPageViewAction;
