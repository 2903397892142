import { Injectable } from "@angular/core";
import { ContentNode, EmbeddedType, ReferencedContentType } from "../../store/article/article-content.model";
import { ImagesService, RestService, loadImageUrl, resolveArticleBodyImage, ArticlesService } from '../../api';
import { GalleriesService } from "../galleries/galleries.service";
import { ContentPanelsService } from "../content-panels/content-panels.service";
import { FilesService } from "../files/files.service";
import { ContentTagsService } from "../content-tags/content-tags.service";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/app-reducer";

@Injectable()
export class ReferenceContentService {
  constructor(
    private articlesService: ArticlesService,
    private galleriesService: GalleriesService,
    private imagesService: ImagesService,
    private rest: RestService,
    private contentPanelService: ContentPanelsService,
    private filesService: FilesService,
    private contentTagsService: ContentTagsService,
    private store: Store<AppState>,
  ) { }

  public getNewReferencedContent(body, cachedRefContent) {
    const referencedContentTypes = Object.values(ReferencedContentType);
    const nodes = body.contentNodes;
    const referencedContent = Object.values(nodes)
      // TODO revisit this cast to any after ng9 update
      .filter(
        (node: ContentNode) =>
          node.dataId &&
          referencedContentTypes.includes(node.type as any) &&
          this.shouldLoadElement(node, cachedRefContent)
      )
      .map((item: ContentNode) => ({ ...item }));

    return referencedContent;
  }

  public generateNodesMap(referencedContent) {
    return referencedContent.reduce((acc, item) => {
      const nodes = [...(acc[item.type] || []), item];
      return { ...acc, [item.type]: nodes };
    }, {});
  }

  public loadReferencedItems(nodesMap, updateReferencedContent, ...args) {
    // updateReferencedContent - callback function
    // args - data intended to be used in callback function
    Object.keys(nodesMap).forEach((type) => {
      const resourceMap = this.createResourceMap(nodesMap[type]);
      switch (type) {
        case EmbeddedType.ArticleMention:
          return this.loadArticles(resourceMap, updateReferencedContent, ...args);
        case EmbeddedType.Gallery:
          return this.loadGalleries(resourceMap, updateReferencedContent, ...args);
        case EmbeddedType.Image:
          return this.loadImages(resourceMap, updateReferencedContent, ...args);
        case EmbeddedType.ContentTag:
          return this.loadContentTags(resourceMap, updateReferencedContent, ...args);
        case EmbeddedType.ContentPanel:
          return this.loadContentPanels(resourceMap, updateReferencedContent, ...args);
        case EmbeddedType.File:
          return this.loadFiles(resourceMap, updateReferencedContent, ...args);
        case EmbeddedType.SystemWidget:
        case EmbeddedType.ThirdPartyWidget:
          return this.loadWidgets(nodesMap[type], type, updateReferencedContent, ...args);
        case EmbeddedType.ImageURL:
          return this.loadImageUrlData(resourceMap, updateReferencedContent, ...args);
        default:
          return;
      }
    });
  }
  private loadArticles(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return this.articlesService.getArticleById(id).subscribe(
        (article) => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              dataUrl: article.publishedRevision.url,
              innerText: article.publishedRevision.headline,
            };
          });
          updateReferencedContent(affectedNodes, ...args);
        },
        (err) => {
          console.error(`Error while loading referenced article data for article id: ${id}`);
          updateReferencedContent(resourceMap[id], ...args);
        }
      );
    });
  }

  private loadGalleries(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return this.galleriesService.getGalleryDetails(id, true).subscribe(
        (gallery) => {
          let affectedNodes = resourceMap[id];
          if (!gallery || !gallery.published) {
            console.warn(`Cannot find a published gallery with the id ${id}.`);
          } else {
            const src = gallery.promoImage
              ? gallery.promoImage.thumbnail
              : './assets/img/embeded-gallery-placeholder.png';
            affectedNodes = affectedNodes.map((node) => {
              return {
                id: node.id,
                dataId: node.dataId,
                title: gallery.published.title,
                src,
              };
            });
          }
          updateReferencedContent(affectedNodes, ...args);
        },
        (err) => {
          console.error(`Error while loading referenced gallery data for gallery id: ${id}`);
          updateReferencedContent(resourceMap[id], ...args);
        }
      );
    });
  }

  private loadImages(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return this.imagesService
        .getImage(id)
        .then((image) => {
          image = this.imagesService.processImage(image);
          const src = resolveArticleBodyImage(image);
          const metaData = image.metaData.reduce(
            (acc, { key, value }) => ({ ...acc, [key]: value }),
            {}
          );
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              src,
              tags: image.tags.map((tag) => tag.name),
              ...metaData,
              isNotLoaded: true
            };
          });
          setTimeout(() => {
            affectedNodes.forEach(node => {
              return this.imagesService.setArticleEmbeddedImageChecker({ id: node.id, dataId: node.dataId, src: node.src, attempt: 1 });
            });
          }, 200);
          updateReferencedContent(affectedNodes, ...args);
        })
        .catch((err) => {
          console.error(
            'Error while loading referenced image data for image id:\n',
            id,
            '\nFor article content node ids:\n',
            resourceMap[id].map((node) => node.id).join(', ')
          );
          const nodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              src: './assets/img/outline-broken_image-24px.svg',
              tags: [],
              metaData: {},
            };
          });
          updateReferencedContent(nodes, ...args);
        });
    });
  }

  private loadContentTags(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return this.contentTagsService.getContentTag(id).subscribe(
        (contentTag) => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              identifier: contentTag.identifier || node.identifier,
              dataId: node.dataId,
              title: contentTag.name,
              colour: contentTag.colour,
            };
          });
          updateReferencedContent(affectedNodes, ...args);
        },
        (err) => {
          console.error(
            `Error while loading referenced content tag data for content tag id: ${id}`
          );
          updateReferencedContent(resourceMap[id], ...args);
        }
      );
    });
  }

  private loadContentPanels(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return this.contentPanelService.getContentPanelById(+id).subscribe(
        (contentPanel) => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              title: contentPanel.name,
              colour: contentPanel.colour,
            };
          });
          updateReferencedContent(affectedNodes, ...args);
        },
        (err) => {
          console.error(
            `Error while loading referenced content panel data for content panel id: ${id}`
          );
          updateReferencedContent(resourceMap[id], ...args);
        }
      );
    });
  }

  private loadFiles(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return this.filesService
        .getFileById(id)
        .then((file) => {
          if (!file || !file.id) {
            console.error(`Error while loading referenced file data for file id: ${id}`);
            return updateReferencedContent(resourceMap[id], ...args);
          }
          const type = file.filename.match(/[^.]+$/g)[0];
          const src = this.filesService.resolveEmbedFileThumbnail(type);
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              id: node.id,
              dataId: node.dataId,
              name: file.meta_data.name,
              title: file.meta_data.name,
              src,
            };
          });
          updateReferencedContent(affectedNodes, ...args);
        })
        .catch((err) => {
          console.error(`Error while loading referenced file data for file id: ${id}`);
          updateReferencedContent(resourceMap[id], ...args);
        });
    });
  }

  private loadWidgets(nodes, type, updateReferencedContent, ...args) {
    const requestPath =
      type === EmbeddedType.ThirdPartyWidget ? 'third-party-widgets' : 'system-widgets';
    return this.rest.get(requestPath).subscribe(
      ({ data }) => {
        const updatedNodes = nodes.map((node) => {
          const widget = data.find((wt) => wt.id === node.dataId);
          if (!widget) {
            console.error(
              `Error while loading referenced widget data for widget id: ${node.dataId}`
            );
            return node;
          }
          const src =
            node.type === EmbeddedType.SystemWidget
              ? './assets/img/custom-icons/glideCustomIcon_system-widgets.svg'
              : './assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg';
          return {
            id: node.id,
            dataId: node.dataId,
            title: widget.name,
            src,
          };
        });
        updateReferencedContent(updatedNodes, ...args);
      },
      (err) => {
        console.error(`Error while loading referenced widget type data.`);
        updateReferencedContent(nodes, ...args);
      }
    );
  }

  private loadImageUrlData(resourceMap, updateReferencedContent, ...args) {
    Object.keys(resourceMap).forEach((id) => {
      return loadImageUrl(id)
        .then(() => {
          const affectedNodes = resourceMap[id].map((node) => {
            return {
              ...node,
              src: node.dataUrl,
            };
          });
          updateReferencedContent(affectedNodes, ...args);
        })
        .catch(() => {
          const nodes = resourceMap[id].map((node) => {
            console.error('Error while loading image from url:\n', node.dataUrl);
            return {
              ...node,
              src: '/assets/img/embed-image-url-gray.svg',
            };
          });
          updateReferencedContent(nodes, ...args);
        });
    });
  }

  private createResourceMap(nodes) {
    /**
     * Resource Map Template: { resourceId: [resourceNode1, resourceNode2] }
     * Resource Map Example (type: gallery): { 2: [galleryNode1, galleryNode2, ...] }
     */
    const resourceIds = Object.keys(
      nodes.reduce((acc, node) => ({ ...acc, [node.dataId]: true }), {})
    );
    const resourceMap = resourceIds.reduce((acc, id) => {
      return { ...acc, [id]: nodes.filter(({ dataId }) => (+dataId || dataId) === (+id || id)) };
    }, {});
    return resourceMap;
  }

  private shouldLoadElement(element, referencedContent) {
    if (!referencedContent) {
      return true;
    }
    return !referencedContent[element.id] || referencedContent[element.id].dataId !== element.dataId;
  }

}

