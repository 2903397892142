import { UnsafeAction } from '../unsafe-action.interface';

export const ADD_IMAGES_TO_QUEUE = '[Images Upload] Add images to queue';
export class AddImagesToQueueAction implements UnsafeAction {
  readonly type = ADD_IMAGES_TO_QUEUE;
  constructor(public payload: any) {}
}

export const ADD_IMAGES_TO_QUEUE_DONE = '[Images Upload] Add images to queue done';
export class AddImagesToQueueDoneAction implements UnsafeAction {
  readonly type = ADD_IMAGES_TO_QUEUE_DONE;
  constructor(public payload: any) {}
}

export const UPDATE_QUEUED_IMAGES = '[Images Upload] Update queued images';
export class UpdateQueuedImagesAction implements UnsafeAction {
  readonly type = UPDATE_QUEUED_IMAGES;
  constructor(public payload: any) {}
}

export const REMOVE_IMAGE_FROM_QUEUE = '[Images Upload] Remove image from queue';
export class RemoveImageFromQueueAction implements UnsafeAction {
  readonly type = REMOVE_IMAGE_FROM_QUEUE;
  constructor(public payload: any) {}
}

export const UPLOAD_STARTED = '[Images Upload] Images upload started';
export class UploadStartedAction implements UnsafeAction {
  readonly type = UPLOAD_STARTED;
  constructor() {}
}

export const START_POLLING_IMAGE_STATUS = '[Images Upload] Start polling image status';
export class StartPollingImageStatusAction implements UnsafeAction {
  readonly type = START_POLLING_IMAGE_STATUS;
  constructor() {}
}

export const IMAGE_UPLOADED = '[Images Upload] Image uploaded';
export class ImageUploadedAction implements UnsafeAction {
  readonly type = IMAGE_UPLOADED;
  constructor(public payload: any) {}
}

export const UPDATE_UPLOADED_IMAGE = '[Images Upload] Update uploaded image';
export class UpdateUploadedImageAction implements UnsafeAction {
  readonly type = UPDATE_UPLOADED_IMAGE;
  constructor(public payload: any) {}
}

export const ALL_IMAGES_UPLOADED = '[Images Upload] All images uploaded';
export class AllImagesUploadedAction implements UnsafeAction {
  readonly type = ALL_IMAGES_UPLOADED;
  constructor(public payload: any) {}
}

export const UPDATE_PROCESSED_IMAGE = '[Images Upload] Update processed image';
export class UpdateProcessedImageAction implements UnsafeAction {
  readonly type = UPDATE_PROCESSED_IMAGE;
  constructor(public payload: any) {}
}

export const UPDATE_QUEUED_IMAGE_META = '[Images Upload] Update queued image meta';
export class UpdateQueuedImageMetaAction implements UnsafeAction {
  readonly type = UPDATE_QUEUED_IMAGE_META;
  constructor(public payload: any) {}
}

// Crop related actions
export const UPDATE_QUEUED_IMAGE_CROPS = '[Images Upload] Update queued image crops';
export class UpdateQueuedImageCropsAction implements UnsafeAction {
  readonly type = UPDATE_QUEUED_IMAGE_CROPS;
  constructor(public payload: any) {}
}

export const RESET_IMAGES_UPLOAD_STATE = '[Images Upload] Reset images upload state';
export class ResetImagesUploadStateAction implements UnsafeAction {
  readonly type = RESET_IMAGES_UPLOAD_STATE;
  constructor() {}
}

export const DISCARD_IMAGE_CROPS_CHANGES = '[Images Upload] Discard image crops changes';
export class DiscardImageCropsChangesAction implements UnsafeAction {
  readonly type = DISCARD_IMAGE_CROPS_CHANGES;
  constructor(public payload: any) {}
}

export const SET_IMAGES_PROCESSING_STATE = '[Images Upload] Set images processing state';
export class SetImagesProcessingStateAction implements UnsafeAction {
  readonly type = SET_IMAGES_PROCESSING_STATE;
  constructor(public payload: any) {}
}
