import { generateShortId } from '../../../shared/shared-functions';
import { ContentNode, StructureNode, ContentModel } from './article-content.model';

export function regenerateBodyNodeIds(workingRevisionBody: ContentModel): ContentModel {
  if (!workingRevisionBody) {
    return workingRevisionBody;
  }

  const { contentNodes, contentStructure } = workingRevisionBody;
  const oldToNewIdMap = {};

  // regenerate content node ids and remap the contentNodes object
  const updatedContentNodes = Object.entries(contentNodes).reduce((acc, [key, node]: [string, ContentNode]) => {
    const newId = generateShortId();
    oldToNewIdMap[key] = newId;
    node.id = newId;
    acc[newId] = {...node, id: newId};
    return acc;
  }, {});

  // do a recursive pass and reassign all node IDs
  const updatedContentStructure = reassignStructureIds(contentStructure, oldToNewIdMap);

  return {
    ...workingRevisionBody,
    contentNodes: updatedContentNodes,
    contentStructure: updatedContentStructure
  };
}

function reassignStructureIds(structure: StructureNode[], oldToNewIdMap): StructureNode[] {
  return structure.map((node: StructureNode) => {
    let newId = oldToNewIdMap[node.id];
    if (!newId) {
      newId = generateShortId();
      oldToNewIdMap[node.id] = newId;
    }
    node.id = newId;
    node.attributes.id = newId;
    node.parentId = oldToNewIdMap[node.parentId] || node.parentId;
    node.childNodes = reassignStructureIds(node.childNodes, oldToNewIdMap);
    return node;
  });
}
