import { UnsafeAction } from '../unsafe-action.interface';

export const GET_ACCESS_BUNDLES = '[Access Bundles] Get access bundles';
export class GetAccessBundlesAction implements UnsafeAction {
  readonly type = GET_ACCESS_BUNDLES;
  constructor(public payload: any = {}) {}
}

export const GET_ACCESS_BUNDLES_SUCCESS = '[Access Bundles] Get access bundles success';
export class GetAccessBundlesSuccessAction implements UnsafeAction {
  readonly type = GET_ACCESS_BUNDLES_SUCCESS;
  constructor(public payload: any) {}
}


export const DELETE_ACCESS_BUNDLE = '[Access Bundles] Delete Access Bundles';
export class DeleteAccessBundleAction implements UnsafeAction {
  readonly type = DELETE_ACCESS_BUNDLE;
  constructor(public payload: number) { }
}

export const DELETE_ACCESS_BUNDLE_SUCCESS = '[Access Bundles] Delete Access Bundles success';
export class DeleteAccessBundleSuccessAction implements UnsafeAction {
  readonly type = DELETE_ACCESS_BUNDLE_SUCCESS;
  constructor(public payload: number) { }
}

export const GET_ACCESS_BUNDLE = '[Access Bundles] Get active access bundle';
export class GetAccessBundle implements UnsafeAction {
  readonly type = GET_ACCESS_BUNDLE;
  constructor(public payload: any = {}) {}
}

export const GET_ACCESS_BUNDLE_SUCCESS = '[Access Bundles] Get active access bundle success';
export class GetAccessBundleSuccess implements UnsafeAction {
  readonly type = GET_ACCESS_BUNDLE_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const CREATE_ACCESS_BUNDLE = '[Access Bundles] Create access bundle';
export class CreateAccessBundle implements UnsafeAction {
  readonly type = CREATE_ACCESS_BUNDLE;
  constructor(public payload: any = {}) {}
}

export const CREATE_ACCESS_BUNDLE_SUCCESS = '[Access Bundles] Create access bundle success';
export class CreateAccessBundleSuccess implements UnsafeAction {
  readonly type = CREATE_ACCESS_BUNDLE_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const UPDATE_ACCESS_BUNDLE = '[Access Bundles] Update access bundle';
export class UpdateAccessBundle implements UnsafeAction {
  readonly type = UPDATE_ACCESS_BUNDLE;
  constructor(public payload: any = {}) {}
}

export const UPDATE_ACCESS_BUNDLE_SUCCESS = '[Access Bundles] Update access bundle success';
export class UpdateAccessBundleSuccess implements UnsafeAction {
  readonly type = UPDATE_ACCESS_BUNDLE_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const CHANGE_ACCESS_BUNDLE_REVISION = '[Access Bundles] Change access bundle revision';
export class ChangeAccessBundleRevision implements UnsafeAction {
  readonly type = CHANGE_ACCESS_BUNDLE_REVISION;
  constructor(public payload: any = {}) {}
}

export const CHANGE_ACCESS_BUNDLE_REVISION_SUCCESS = '[Access Bundles] Change access bundle revision success';
export class ChangeAccessBundleRevisionSuccess implements UnsafeAction {
  readonly type = CHANGE_ACCESS_BUNDLE_REVISION_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const PUBLISH_ACCESS_BUNDLE = '[Access Bundles] Publish access bundle';
export class PublishAccessBundle implements UnsafeAction {
  readonly type = PUBLISH_ACCESS_BUNDLE;
  constructor(public payload: any = {}) {}
}

export const PUBLISH_ACCESS_BUNDLE_SUCCESS = '[Access Bundles] Publish access bundle success';
export class PublishAccessBundleSuccess implements UnsafeAction {
  readonly type = PUBLISH_ACCESS_BUNDLE_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const UNPUBLISH_ACCESS_BUNDLE = '[Access Bundles] Unpublish access bundle';
export class UnpublishAccessBundle implements UnsafeAction {
  readonly type = UNPUBLISH_ACCESS_BUNDLE;
  constructor(public payload: any = {}) {}
}

export const UNPUBLISH_ACCESS_BUNDLE_SUCCESS = '[Access Bundles] Unpublish access bundle success';
export class UnpublishAccessBundleSuccess implements UnsafeAction {
  readonly type = UNPUBLISH_ACCESS_BUNDLE_SUCCESS;
  constructor(public payload: any = {}) {}
}

export const RESET_ACTIVE_ACCESS_BUNDLE_STATE = '[Access Bundles] Reset active access bundle state';
export class ResetActiveAccessBundleState implements UnsafeAction {
  readonly type = RESET_ACTIVE_ACCESS_BUNDLE_STATE;
  constructor() {}
}

export const ACCESS_BUNDLES_ACTION_FAILED = '[Access Bundles] Generic failure action';
export class AccessBundlesFailedAction implements UnsafeAction {
  readonly type = ACCESS_BUNDLES_ACTION_FAILED;
  constructor(public payload: any) {}
}

export const CLEAR_ACCESS_BUNDLES_STATE = '[Access Bundles] Clear access bundles state';
export class ClearAccessBundlesStateAction implements UnsafeAction {
  readonly type = CLEAR_ACCESS_BUNDLES_STATE;
  constructor() { }
}
