import { UntypedFormGroup } from "@angular/forms";

export function checkIfRichTextEditorIsSet(formGroup: UntypedFormGroup) {
  const textValue = formGroup.value;
  const emptyTextEditor = '';

  if (textValue !== null) {
    return textValue !== null && textValue !== emptyTextEditor ? null : { required: true };
  }
}
