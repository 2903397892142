import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { MatIconRegistry } from '@angular/material/icon';
import { ErrorHandler, NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { FacebookService } from './api/facebook/facebook.service';
import { StorageService } from './api/storage/storage.service';

import {
  AuthService,
  AuthGuard,
  RestService,
  ArticlesService,
  ImagesService,
  ImagesUploadService,
  SystemNotificationService
} from './api';
import { ArticleListEffects } from './store/articles-list/article-list.effects';
import { appReducer, clearState } from './store/app-reducer';
import { ThemeManager } from './miscellaneous/theme-manager.service';
import { TaxonomiesService } from './api/taxonomies/taxonomies.service';
import { ActiveArticleEffects } from './store/article/article.effects';
import { AuthEffects } from './store/auth/auth.effects';
import { TaxonomiesEffects } from './store/taxonomies/taxonomies.effects';
import { RoutesEffects } from './store/routes/route.effects';
import { RoutesService } from './api/routes/routes.service';
import { WidgetTypesEffects } from './store/widget-types/widget-types.effects';
import { environment } from '../../environments/environment';
import { ImagesEffects } from './store/images/images.effects';
import { GalleriesEffects } from './store/galleries/galleries.effects';
import { GalleriesWorkflowEffects } from './store/galleries-workflow/galleries-workflow.effects';
import { ImagesConfigurationEffects } from './store/images-configuration/images-configuration.effects';
import { GalleriesService } from './api/galleries/galleries.service';
import { FieldGroupsEffects } from './store/field-groups/field-groups.effects';
import { ImagesUploadEffects } from './store/images-upload/images-upload.effects';

import { UsersEffects } from './store/users/users.effects';
import { UsersService } from './api/users/users.service';
import { WorkflowsEffects } from './store/workflows/workflow.effects';
import { PermissionGuard } from './api/auth/permission-guard.guard';
import { AdvertsEffects } from './store/adverts/adverts.effects';
import { UserPreferencesService } from './api/user-preferences/user-preferences.service';
import { AdvertsGroupEffects } from './store/adverts-group/adverts-group.effects';
import { TemplatesEffects } from './store/templates/templates.effects';
import { DragulaModule } from 'ng2-dragula';
import { DateFormatService } from './miscellaneous/date-format.service';
import { AdNetworkSettingsService } from './api/ad-network-settings/ad-network-settings.service';
import { PageEffects } from './store/pages/pages.effects';
import { MenusEffects } from './store/menus/menus.effects';
import { MenusService } from './api/menus/menus.service';
import { AccountSettingsEffects } from './store/account-settings/account-settings.effects';
import { HtmlSnippetsEffects } from './store/html-snippets/html-snippets.effects';
import { HtmlSnippetsService } from './api/html-snippets/html-snippets.service';
import { LoginGuard } from './api/auth/login-guard.guard';
import { LockService } from './api/lock/lock.service';
import { LockEffects } from './store/lock/lock.effects';
import { ProxyService } from './api/proxy/proxy.service';
import { AccountSettingsService } from './api/account-settings/accounts-settings.service';
import { PreviewV1Service } from './api/preview/preview-v1.service';
import { TributeService } from './api/tribute/tribute.service';
import { CharacterCountLimitsService } from './api/character-count-limits.service';
import { ArticleTypesEffects } from './store/article-type/article-type.effects';
import { AuthorsService } from './api/authors/authors.service';
import { AuthorsEffects } from './store/authors/authors.effects';
import { ItemFormPermissionsGuard } from './miscellaneous/item-form-permissions.guard';
import { RedirectsService } from './api/redirects/redirects.service';
import { RedirectsEffects } from './store/redirects/redirects.effects';
import { DataTokensService } from './api/data-tokens/data-tokens.service';
import { ContentPanelsEffects } from './store/content-panels/content-panels.effects';
import { ContentPanelsService } from './api/content-panels/content-panels.service';
import { CollectionTypesEffects } from './store/collection-type/collection-type.effects';
import { CollectionEffects } from './store/collection/collection.effects';
import { CollectionsService } from './api/collections/collections.service';
import { CollectionListEffects } from './store/collection-list/collection-list.effects';
import { FilesService } from './api/files/files.service';
import { FilesEffects } from './store/files/files.effects';
import { FilesUploadEffects } from './store/files-upload/files-upload.effects';
import { FilesUploadService } from './api/files/files-upload.service';
import { ContentTagsEffects } from './store/content-tags/content-tags.effects';
import { FieldGroupsService } from './api/field-groups/field-groups.service';
import { ContentTagsService } from './api/content-tags/content-tags.service';
import { SsoProvidersEffects } from './store/sso/sso.effects';
import { SSOService } from './api/sso/sso.service';
import { TaxonomyConfigurationsEffects } from './store/taxonomy-configuration/taxonomy-configuration.effects';
import { TaxonomyConfigurationService } from './api/taxonomy-configuration/taxonomy-configuration.service';
import { ContentFiltersService } from './api/content-filters/content-filters.service';
import { AdvancedFilteringOptionEffects } from './store/advanced-filtering-option/advanced-filtering-option.effects';
import { WidgetTypesService } from './api/widgets/widgets.service';
import { AccessBundlesService } from './api/access-bundles/access-bundles.service';
import { AccessBundlesEffects } from './store/access-bundles/access-bundles.effects';
import { VerifySettingsService } from './api/verify-settings/verify-settings.service';
import { VerifySettingsEffects } from './store/verify-settings/verify-settings.effects';
import { LiveReportEffects } from './store/live-report/live-report.effects';
import { LiveReportsService } from './api/live-reports/live-reports.service';
import { LiveReportsListEffects } from './store/live-reports-list/live-reports-list.effects';
import { PostEffects } from './store/posts/posts.effects';
import { PostsService } from './api/posts/posts.service';
import { ReferenceContentService } from './api/reference-content/reference-content.service';
import { ColumnManager } from './miscellaneous/column-manager.service';
import { ArticleQuickViewEffects } from './store/article-quick-view/article-quick-view.effects';
import { MixPanelService } from './api/mixpanel/mixpanel.service';
import { RichTextEditorTransformService } from '../gpp-shared/rich-text-editor/rich-text-editor/rich-text-editor-transform.service';
import { PreviousUrlService } from './api/previous-url/previous-url.service';
import { GlobalErrorHandler } from './global-error-handler';
import { SystemNotificationEffects } from './store/system-notifications/system-notifications.effects';
import { SubscribersService } from './api/subscribers/subscribers.service';
import { SubscribersEffects } from './store/subscribers/subscribers.effects';
import { MultichannelPublishingService } from './api/multichannel-publishing/multichannel-publishing.service';
import { GettyIntegrationService } from './api/image-integrations/getty-integration.service';
import { TwitchIntegrationService } from './api/video-integrations/twitch-integration.service';
import { ContentTrackerService } from './api/content-tracker/content-tracker.service';
import { ContentLocalesEffects } from './store/content-locales/content-locales.effects';
import { ContentLocalesService } from './api/content-locales/content-locales.service';
import { EnvSettingService } from '../../environments/env-setting.service';
import { LiveReportTypesEffects } from './store/live-report-types/live-report-types.effects';
import { LiveReportTypesService } from './api/live-report-types/live-report-types.service';
import { ReferencedContentRepositoryEffects } from './store/referenced-content-repository/referenced-content-repository.effects';
import { ReferencedContentRepositoryService } from './api/referenced-content-repository/referenced-content-repository.service';

// use redux dev tools only in non production enviroments
const reduxDevTools = environment.production
  ? []
  : StoreDevtoolsModule.instrument({ maxAge: 50 });

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DragulaModule.forRoot(),
    // ngrxSuite
    StoreModule.forRoot(appReducer, {
      metaReducers: [clearState],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    reduxDevTools,
    EffectsModule.forRoot([
      ArticleListEffects,
      ActiveArticleEffects,
      WorkflowsEffects,
      AuthEffects,
      TaxonomiesEffects,
      ArticleTypesEffects,
      RoutesEffects,
      PageEffects,
      TemplatesEffects,
      WidgetTypesEffects,
      ImagesEffects,
      GalleriesEffects,
      FilesEffects,
      FilesUploadEffects,
      GalleriesWorkflowEffects,
      ImagesConfigurationEffects,
      UsersEffects,
      ImagesUploadEffects,
      AdvertsEffects,
      AdvertsGroupEffects,
      MenusEffects,
      AccountSettingsEffects,
      HtmlSnippetsEffects,
      LockEffects,
      AuthorsEffects,
      RedirectsEffects,
      ContentPanelsEffects,
      CollectionTypesEffects,
      CollectionEffects,
      CollectionListEffects,
      ContentTagsEffects,
      SsoProvidersEffects,
      FieldGroupsEffects,
      TaxonomyConfigurationsEffects,
      AdvancedFilteringOptionEffects,
      AccessBundlesEffects,
      VerifySettingsEffects,
      LiveReportEffects,
      LiveReportsListEffects,
      PostEffects,
      ArticleQuickViewEffects,
      SubscribersEffects,
      ContentLocalesEffects,
      SystemNotificationEffects,
      LiveReportTypesEffects,
      ReferencedContentRepositoryEffects
    ])
  ],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MatIconRegistry,
    RestService,
    AuthService,
    AuthGuard,
    PermissionGuard,
    TaxonomiesService,
    ArticlesService,
    ThemeManager,
    RoutesService,
    ImagesService,
    ImagesUploadService,
    GalleriesService,
    FilesService,
    FilesUploadService,
    UsersService,
    FacebookService,
    StorageService,
    DateFormatService,
    AdNetworkSettingsService,
    UserPreferencesService,
    MenusService,
    HtmlSnippetsService,
    LoginGuard,
    LockService,
    ProxyService,
    PreviewV1Service,
    AccountSettingsService,
    TributeService,
    CharacterCountLimitsService,
    AuthorsService,
    ContentLocalesService,
    ItemFormPermissionsGuard,
    RedirectsService,
    DataTokensService,
    ContentPanelsService,
    CollectionsService,
    ContentTagsService,
    FieldGroupsService,
    SSOService,
    TaxonomyConfigurationService,
    ContentFiltersService,
    WidgetTypesService,
    AccessBundlesService,
    VerifySettingsService,
    LiveReportsService,
    PostsService,
    ReferenceContentService,
    ColumnManager,
    MixPanelService,
    RichTextEditorTransformService,
    PreviousUrlService,
    SubscribersService,
    MultichannelPublishingService,
    GettyIntegrationService,
    TwitchIntegrationService,
    ContentTrackerService,
    ContentLocalesService,
    SystemNotificationService,
    EnvSettingService,
    LiveReportTypesService,
    ReferencedContentRepositoryService
  ],
  declarations: []
})
export class CoreModule { }
