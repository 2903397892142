import { UnsafeAction } from '../unsafe-action.interface';
import { ContentPanel } from './content-panels.model';

export const GET_CONTENT_PANELS = '[CONTENT PANELS] Get Content panels';
export class GetContentPanelsAction implements UnsafeAction {
  readonly type = GET_CONTENT_PANELS;
  constructor(public payload: any = {}) {}
}

export const GET_CONTENT_PANELS_SUCCESS = '[CONTENT PANELS] Get Content panels success';
export class GetContentPanelsSuccessAction implements UnsafeAction {
  readonly type = GET_CONTENT_PANELS_SUCCESS;
  constructor(public payload: any) {}
}

export const CREATE_CONTENT_PANELS = '[CONTENT PANELS] Create Content panels';
export class CreateContentPanelsAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_PANELS;
  constructor(public payload: ContentPanel) {}
}

export const CREATE_CONTENT_PANELS_SUCCESS = '[CONTENT PANELS] Create Content panels success';
export class CreateContentPanelsSuccessAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_PANELS_SUCCESS;
  constructor(public payload: ContentPanel) {}
}

export const UPDATE_CONTENT_PANELS = '[CONTENT PANELS] Update Content panels';
export class UpdateContentPanelsAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_PANELS;
  constructor(public payload: ContentPanel) {}
}

export const UPDATE_CONTENT_PANELS_SUCCESS = '[CONTENT PANELS] Update Content panels success';
export class UpdateContentPanelsSuccessAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_PANELS_SUCCESS;
  constructor(public payload: ContentPanel) {}
}

export const DELETE_CONTENT_PANELS = '[CONTENT PANELS] Delete Content panel';
export class DeleteContentPanelsAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_PANELS;
  constructor(public payload: number) {}
}

export const DELETE_CONTENT_PANELS_SUCCESS = '[CONTENT PANELS] Delete Content panel success';
export class DeleteContentPanelsSuccessAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_PANELS_SUCCESS;
  constructor(public payload: number) {}
}

export const CONTENT_PANELS_FAILED = '[CONTENT PANELS] Generic failure action';
export class ContentPanelsFailedAction implements UnsafeAction {
  readonly type = CONTENT_PANELS_FAILED;
  constructor(public payload: any) {}
}

export const SET_CONTENT_PANELS_PAGE_VIEW_OPTIONS = '[CONTENT PANELS] Set page view option';
export class SetContentPanelsPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_CONTENT_PANELS_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) {}
}

export  const UPDATE_CONTENT_PANEL_POSITION = '[CONTENT PANELS] Update content panel position';
export class UpdateContentPanelPositionAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_PANEL_POSITION;
  constructor(public payload: any) {}
}

export const REORDER_CONTENT_PANELS = '[CONTENT PANELS] Update all content panels';
export class ReorderContentPanelsAction implements UnsafeAction {
  readonly type = REORDER_CONTENT_PANELS;
  constructor() {}
}
