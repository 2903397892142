import { UnsafeAction } from './../unsafe-action.interface';
import { mergeMap, map, tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { UpdateLiveReportTypeAction } from './live-report-types.actions';
import { UpdateLiveReportTypeSuccessAction } from './live-report-types.actions';
import { LiveReportType } from './live-report-types.model';
import { LiveReportTypeActionTypes } from './live-report-types.actions';
import { FailureLiveReportTypeAction } from './live-report-types.actions';
import { LoadLiveReportTypesSuccessAction } from './live-report-types.actions';
import { LiveReportTypesService } from '../../api/live-report-types/live-report-types.service';

@Injectable()
export class LiveReportTypesEffects {

  loadLiveReportTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LiveReportTypeActionTypes.LOAD),
    mergeMap(
      () =>
        this.liveReportTypeService.get().pipe(
          map((configuration: any) => new LoadLiveReportTypesSuccessAction(configuration)),
          catchError((e) => of(new FailureLiveReportTypeAction({ error: e })))
        ) as Observable<UnsafeAction>
    )
  ));


  updateLiveReportType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LiveReportTypeActionTypes.UPDATE),
    mergeMap((action: UpdateLiveReportTypeAction) => {
      return this.liveReportTypeService.update(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Live report type saved`, $localize`Close`, { duration: 2000 })),
        map(
          (newConfiguration: LiveReportType) =>
            new UpdateLiveReportTypeSuccessAction(newConfiguration)
        ),
        catchError((e) => of(new FailureLiveReportTypeAction({ error: e })))
      ) as Observable<UnsafeAction>;
    })
  ));

  constructor(
    private actions$: Actions,
    private liveReportTypeService: LiveReportTypesService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}
}
