export function extractAccountSlugFromHostname(url?) {
  const hostnameFromUrl = !!url && url.replace(/https?:\/\//, '').replace(/\/.*/, '');
  // in hostname like `altchar.pub.nonprod-gc1.gpp.io` we're interested in the `altchar` bit
  const hostname = hostnameFromUrl || location.host || location.hostname;
  // we're splitting by hostname by Publisher service prefix, `.[env]-pub.` or `.pub.`
  // if we can split the hostname by that, first element is the account slug
  // if we cannot split the hostname, the account slug is not there
  const splitHostname = hostname.split(/\.[a-zA-Z-_]*pub\./);
  const hostHasClientAccountSlugPrefix = splitHostname && splitHostname.length > 1;
  return hostHasClientAccountSlugPrefix ? splitHostname[0] : null;
}

export function replaceAccountSlugInURL(url, newAccountSlug) {
  const currentAccountSlug = extractAccountSlugFromHostname(url);
  // nothing to replace
  if(!currentAccountSlug || newAccountSlug) {
    return url;
  }
  return url.replace(currentAccountSlug, newAccountSlug)
}
