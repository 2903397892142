import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { get } from 'lodash-es';
import { hasRequiredValidation } from '../has-required-validation/has-required-validation';
import { generateHelpTooltip } from '../info-tooltip.text';

interface LabelValue {
  label: string;
  value: string;
}

@Component({
  selector: 'gd-radio-button-configuration',
  templateUrl: './radio-button-configuration.component.html',
  styleUrls: ['./radio-button-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonConfigurationComponent implements OnInit, OnChanges {
  @Input() fieldControl;
  @Input() fieldConfig;
  @Input() label;
  @Input() readOnlyMode = false;

  isFormControlRequired = false;
  radioButtonOptions: LabelValue[] = [];
  infoTooltip = '';

  constructor() { }

  ngOnInit(): void {
    this.radioButtonOptions = get(this.fieldConfig, 'dataSource.value', []);

    // set radio button validators
    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    if (this.isFormControlRequired) {
      this.fieldControl.setValidators([Validators.required]);
      this.fieldControl.updateValueAndValidity();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
  }

  clearRadioButtons() {
    this.fieldControl.reset();
    this.fieldControl.markAsDirty();
  }
}
