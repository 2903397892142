export const defaultSettingsType = 'appStore';

interface SettingsMenuItem {
    type: string;
    title: string;
}

export const SettingsMenu: Array<SettingsMenuItem> = [
    { type: 'appStore', title: 'App Store' },
    { type: 'characterCountLimits', title: 'Character Count Limits' },
    { type: 'siteConfig', title: 'Base Settings' },
    { type: 'brightcove', title: 'Brightcove' },
    { type: 'fb', title: 'Facebook' },
    { type: 'ga', title: 'Google Analytics' },
    { type: 'gtm', title: 'Google Tag Manager' },
    { type: 'relatedArticles', title: 'Related Articles' },
    { type: 'workflowSetup', title: 'Workflow' },
    { type: 'dataStudio', title: 'Data Studio'},
    { type: 'previewService', title: 'Preview Service'},
    { type: 'autosave', title: 'Autosave'},
    { type: 'collectionLocking', title: 'Collection Locking'},
    { type: 'connectService', title: 'Connect Settings' },
    { type: 'i18n', title: 'Language Settings'},
    { type: 'urlSeo', title: 'URLs and SEO'},
    { type: 'getty', title: 'Getty Images'},
    { type: 'dailymotion', title: 'Dailymotion' },
    { type: 'amazonBedrock', title: 'GAIA' },
    { type: 'textEditor', title: 'Text Editor' },
    { type: 'twitch', title: 'Twitch' },
    // TODO wipe out all access tag references, and component in settings
    // { type: 'accessTags', title: 'Access Tags' },
];

export enum SettingsTitles {
    appStore = 'App Store',
    characterCountLimits = 'Character Count Limits',
    siteConfig = 'Base Settings',
    brightcove = 'Brightcove',
    fb = 'Facebook',
    ga = 'Google Analytics',
    gtm = 'Google Tag Manager',
    relatedArticles = 'Related Articles',
    workflowSetup = 'Workflow',
    connectService = 'Connect Settings',
    dataStudio = 'Data Studio',
    previewService = 'Preview Service',
    autosave = 'Autosave',
    collectionLocking = 'Collection Locking',
    i18n = 'Language Settings',
    urlSeo = 'URLs and SEO',
    getty = 'Getty Images',
    dailymotion = 'Dailymotion',
    amazonBedrock = 'GAIA - Glide AI Assistant',
    textEditor = 'Text Editor',
    twitch = 'Twitch',
    // accessTags = 'Access Tags',
}

export enum autosave {
  enabledOnArticle = 'Enable autosave on article form',
}

export enum appStore {
    iTunesAppUrl = 'Itunes App Url',
    playStoreAppUrl = 'Playstore App Url',
    affiliateId = 'Affiliate ID',
}

export enum previewService {
    version = 'Preview Service Version',
    apiUrl = 'API URL',
    httpHeaders = 'HTTP Headers'
}

export enum characterCountLimits {
    // Article
    articleHeadlineMin = 'Min',
    articleHeadlineMax = 'Max',
    articleStandfirstMin = 'Min',
    articleStandfirstMax = 'Max',
    articleShortStandfirstMin = 'Min',
    articleShortStandfirstMax = 'Max',
    articlePromoTitleMin = 'Min',
    articlePromoTitleMax = 'Max',
    articlePromoSubtitleMin = 'Min',
    articlePromoSubtitleMax = 'Max',

    // Gallery
    galleryHeadlineMin = 'Min',
    galleryHeadlineMax = 'Max',
    galleryStandfirstMin = 'Min',
    galleryStandfirstMax = 'Max',

    // Image
    imageCaptionMax = 'Max'
}

export enum siteConfig {
    baseUrl = 'Base URL',
    mediaBaseUrl = 'Media Base URL',
    timezone = 'Timezone',
    siteMode = 'Site Mode',
    shortDateFormat = 'Short Date Format',
    longDateFormat = 'Long Date Format',
    sitemapUrl = 'Sitemap URL',
    robotsTxt = 'Robots txt',
    adsTxt = 'Ads txt',
}

export enum brightcove {
    accountId = 'Account ID',
    policyKey = 'Policy Key',
    previewPlayerId = 'Preview Player ID'
}

export enum dailymotion {
    previewPlayerId = 'Preview Player ID',
    verticalPlayerId = 'Vertical Player ID'
}

export enum twitch {
  clientId = 'Client ID',
  clientSecret = 'Client Secret'
}

export enum fb {
    appId = 'App ID',
    pageId = 'Page ID'
}

export enum ga {
    accountId = 'Account ID',
    trackingId = 'Tracking ID',
    domain = 'Domain',
}

export enum gtm {
    containerId = 'Container ID'
}

export enum relatedArticles {
    numberOfRelatedArticles = 'Number of related articles'
}

export enum workflowSetup {
    workflowId = 'Active Workflow ID'
}

export enum dataStudio {
    url = 'Data Studio Url'
}

export enum collectionLocking {
    enabled = 'Enable collection locking'
}

export enum i18n {
  defaultLocale = 'Default Account Language'
}

// on the api the Connect settings are nested, but we'll map them to flat list here
export enum connectService {
  i18n__defaultLocaleCode = 'Default Locale Code'
}

export enum urlSeo {
  allowedCharacters = 'Allowed URL Characters'
}

export enum getty {}

export enum amazonBedrock {
  enabled = 'Use GAIA for AI generated content'
}

export enum textEditor {
  showEditorPreferences = 'Show text editor preferences',
  useSmartQuotes = 'Use smart quotes by default'
}

// settings that could be saved in local storage
export const NON_SENSITIVE_SETTINGS = {
    autosave: true,
    siteConfig: ['baseUrl', 'mediaBaseUrl', 'timezone', 'shortDateFormat'],
    relatedArticles: ['numberOfRelatedArticles'],
    characterCountLimits: true,
    collectionLocking: true,
    previewService: true,
    i18n: ['defaultLocale'],
    urlSeo: ['allowedCharacters'],
    amazonBedrock: true,
    textEditor: true,
};
