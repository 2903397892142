import { debounceTime } from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlideContentActionBusService } from '../../../core/body-content/glide-content-actions-bus.service';
import { ScriptInjectorService } from '../../../core/miscellaneous/script-injector.service';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-embed-tiktok-dialog',
  templateUrl: './embed-tiktok-dialog.component.html',
  styleUrls: ['./embed-tiktok-dialog.component.scss']
})
export class EmbedTikTokDialogComponent implements OnInit, OnDestroy {

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    url: ['', Validators.required],
    src: [''],
  });

  blockId;
  postLoaded = false;
  componentSub: Subscription = new Subscription();
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  get urlControl() {
    return this.embeddableForm.get('url');
  }

  get srcControl() {
    return this.embeddableForm.get('src');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedTikTokDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private actionBus: GlideContentActionBusService,
    private http: HttpClient,
    private scriptInjector: ScriptInjectorService,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.loadTikTokScript();
    this.componentSub.add(
      this.urlControl.valueChanges.pipe(debounceTime(200)).subscribe(
        url => this.processTikTokUrl(url)
      )
    );
    this.embeddableForm.patchValue(this.dialogInputData);
    this.blockId = this.dialogInputData.blockId;
  }

  processTikTokUrl(url) {
    const postContainer = document.getElementById('tiktok-container');
    postContainer.innerHTML = '';
    this.postLoaded = false;
    if (!url) {
      return this.urlControl.setErrors({ 'invalidUrl': true });
    }

    const glideContentType = this.dialogInputData.glideContentType;
    this.actionBus.setContentLoading(glideContentType, true);
    this.getTikTokPostData(url).then((data: any) => {
      this.actionBus.setContentLoading(glideContentType, false);
      if (data.status_msg === 'Something went wrong') {
        return this.urlControl.setErrors({ 'invalidUrl': true });
      }
      this.postLoaded = true;
      postContainer.innerHTML = data.html || '';
      this.loadTikTokPost();

      this.urlControl.setErrors(null);
      this.srcControl.setValue(data.thumbnail_url || null);
    });
  }

  getTikTokPostData(postUrl: string) {
    const requestUrl = `https://www.tiktok.com/oembed?url=${postUrl}`
    return this.http.get(requestUrl).toPromise();
  }

  save() {
    const formModel = this.embeddableForm.value;
    const contentNodeData = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel
    };
    this.dialogRef.close(contentNodeData);
    return;
  }

  //rerun tik tok embed script after fetching data to load tiktok video player
  loadTikTokPost() {
    const loadTikTokPost = document.createElement("script");
    loadTikTokPost.type = "text/javascript";
    loadTikTokPost.src = "https://www.tiktok.com/embed.js";
    loadTikTokPost.async = true;
    const script = document.getElementById('tiktokPostContainer');
    script.append(loadTikTokPost);
  }

  //run tik tok SDK script to load tiktok post
  loadTikTokScript() {
    if (document.getElementById('tiktok-sdk')) {
      return;
    }
    this.scriptInjector
      .injectScript('https://www.tiktok.com/embed.js', { id: 'tiktok-sdk' })
      .then(() => {})
      .catch(() => console.log('Error: TikTok software development kit failed to load'));
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }

}
