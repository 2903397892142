import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { RestService } from '../rest.service';

export interface PostListQuery {
  id: number;
  page?: number;
  size?: number;
  name?: string;
  authorIds?: number[];
  taxonomyIds?: number[];
  published?:boolean;
  hideScheduled?: boolean;
  orderBy?: string;
}
@Injectable()
export class PostsService {

  constructor(private rest: RestService) { }

  getPostsList({ id, page, size, name, authorIds, taxonomyIds, published = null, hideScheduled, orderBy }: PostListQuery) {
    let query = `page=${page || 0}&size=${size || defaultPageSize}`;

    if (name && name.length) {
      query += `&search=${name}`;
    }

    if (Array.isArray(authorIds) && authorIds.length) {
      query += `&authorIds=${authorIds}`;
    }

    if (Array.isArray(taxonomyIds) && taxonomyIds.length) {
      query += `&taxonomyIds=${taxonomyIds}`;
    }

    if (published !== null) {
      query += `&published=${published}`;
    }

    if (hideScheduled) {
      query += `&hideScheduled=${hideScheduled}`;
    }

    if(orderBy && orderBy.length) {
      query += `&orderBy=${orderBy}`
    }

    return this.rest.get(`live-reports/${id}/posts?${query}`);
  }

  getPost({ liveReportId, postId }) {
    return this.rest.get(`live-reports/${liveReportId}/posts/${postId}`).pipe(map((response: any) => response.data));
  }

  createPost({ liveReportId, post }) {
    return this.rest.post(`live-reports/${liveReportId}/posts`, post).pipe(map((response: any) => response.data));
  }

  updatePost({ liveReportId, post }) {
    return this.rest.put(`live-reports/${liveReportId}/posts/${post.id}`, post).pipe(map((response: any) => response.data));
  }

  deletePost(liveReportId, postId) {
    return this.rest.delete(`live-reports/${liveReportId}/posts/${postId}`);
  }

  publishPost({ liveReportId, postId }) {
    return this.rest.post(`live-reports/${liveReportId}/posts/${postId}/publish`).pipe(map((response: any) => response.data));
  }

  unpublishPost({ liveReportId, postId }) {
    return this.rest.post(`live-reports/${liveReportId}/posts/${postId}/unpublish`).pipe(map((response: any) => response.data));
  }
}
