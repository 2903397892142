import { UnsafeAction } from '../unsafe-action.interface';
import { AdvertGroup } from './adverts-group.model';

export const GET_ADVERTS_GROUP = '[ADVERTS GROUP] Get Adverts group';
export class GetAdvertsGroupAction implements UnsafeAction {
  readonly type = GET_ADVERTS_GROUP;
  constructor(public payload: any = {}) { }
}

export const GET_ADVERTS_GROUP_SUCCESS = '[ADVERTS GROUP] Get Adverts group success';
export class GetAdvertsGroupSuccessAction implements UnsafeAction {
  readonly type = GET_ADVERTS_GROUP_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_ADVERTS_GROUP_ERROR = '[ADVERTS GROUP] Get Adverts group error';
export class GetAdvertsGroupErrorAction implements UnsafeAction {
  readonly type = GET_ADVERTS_GROUP_ERROR;
  constructor(public payload: any) { }
}

export const CREATE_ADVERTS_GROUP = '[ADVERTS GROUP] Create Adverts group';
export class CreateAdvertsGroupAction implements UnsafeAction {
  readonly type = CREATE_ADVERTS_GROUP;
  constructor(public payload: AdvertGroup) { }
}

export const CREATE_ADVERTS_GROUP_SUCCESS = '[ADVERTS GROUP] Create Adverts group success';
export class CreateAdvertsGroupSuccessAction implements UnsafeAction {
  readonly type = CREATE_ADVERTS_GROUP_SUCCESS;
  constructor(public payload: AdvertGroup) { }
}

export const UPDATE_ADVERTS_GROUP = '[ADVERTS GROUP] Update Adverts group';
export class UpdateAdvertsGroupAction implements UnsafeAction {
  readonly type = UPDATE_ADVERTS_GROUP;
  constructor(public payload: AdvertGroup) { }
}

export const UPDATE_ADVERTS_GROUP_SUCCESS = '[ADVERTS GROUP] Update Adverts group success';
export class UpdateAdvertsGroupSuccessAction implements UnsafeAction {
  readonly type = UPDATE_ADVERTS_GROUP_SUCCESS;
  constructor(public payload: AdvertGroup) { }
}

export const DELETE_ADVERTS_GROUP = '[ADVERTS GROUP] Delete Adverts group';
export class DeleteAdvertsGroupAction implements UnsafeAction {
  readonly type = DELETE_ADVERTS_GROUP;
  constructor(public payload: number) { }
}

export const DELETE_ADVERTS_GROUP_SUCCESS = '[ADVERTS GROUP] Delete Adverts group success';
export class DeleteAdvertsGroupSuccessAction implements UnsafeAction {
  readonly type = DELETE_ADVERTS_GROUP_SUCCESS;
  constructor(public payload: number) { }
}

export const ADVERTS_GROUP_ACTION_FAILED = '[ADVERTS GROUP] Generic failure action';
export class AdvertsGroupFailedAction implements UnsafeAction {
  readonly type = ADVERTS_GROUP_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const SET_ADVERT_GROUPS_PAGE_VIEW_OPTIONS = '[ADVERT GROUPS] Set age iew options';
export class SetAdvertGroupsPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_ADVERT_GROUPS_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}
