<h2 mat-dialog-title
    style="margin-bottom: 12px;">{{ title }}</h2>

<div mat-dialog-content
     style="text-align: center;">{{message}}</div>

<div mat-dialog-actions
     fxLayoutAlign="center center"
     fxLayout="row">
  <button style="width: 100%; margin-top: 4px;"
          mat-button
          mat-dialog-close>OK</button>
</div>
