import { UnsafeAction } from '../unsafe-action.interface';

export const GET_GALLERIES_WORKFLOWS = '[Gallery Workflow] Get galleries workflows';
export class GetGalleriesWorkflowsAction implements UnsafeAction {
  readonly type = GET_GALLERIES_WORKFLOWS;
  constructor() { }
}

export const GET_GALLERIES_WORKFLOWS_SUCCESS = '[Gallery Workflow] Get galleries workflows done';
export class GetGalleriesWorkflowsSuccessAction implements UnsafeAction {
  readonly type = GET_GALLERIES_WORKFLOWS_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_GALLERIES_WORKFLOWS_ERROR = '[Gallery  Workflow] Get galleries workflows error';
export class GetGalleriesWorkflowsErrorAction implements UnsafeAction {
  readonly type = GET_GALLERIES_WORKFLOWS_ERROR;
  constructor(public payload: any) { }
}
