import { Injectable } from '@angular/core';
import { ManageEmbeddedImageVisibilityAction, UpdateReferencedContentAction } from '../../store/article/article.actions';
import { AbstractReferencedContentService } from './abstract-referenced-content.service';


@Injectable({
  providedIn: 'root',
})
export class ArticleBodyReferencedContentService extends AbstractReferencedContentService {
  protected EmbeddedImageVisibilityAction = ManageEmbeddedImageVisibilityAction;
  protected UpdateReferencedContentAction = UpdateReferencedContentAction;
}
