import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { SidenavService } from './sidenav.service';
import isArray from 'lodash-es/isArray';
import sortBy from 'lodash-es/sortBy';
import keys from 'lodash-es/keys';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MixPanelService } from '../../core/api/mixpanel/mixpanel.service';

@Component({
  selector: 'gd-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() smallDisplay;
  items: SidenavItem[];

  private _itemsSubscription: Subscription;
  private _routerEventsSubscription: Subscription;
  activeSidenavItemSubscription;
  activeItemsMap = {};
  isInit = true;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private mixPanelService: MixPanelService,
  ) { }

  ngOnInit() {
    this._itemsSubscription = this.sidenavService.items$
      .subscribe((items: SidenavItem[]) => {
        this.items = this.sortRecursive(items, 'position');
      });

    this._routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenavService.nextCurrentlyOpenByRoute(event.url);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 400);
      }
    });

    if (this.isInit) {
      this.sidenavService.nextCurrentlyOpenByRoute(this.router.url);
      this.isInit = false;
    }
    this.activeSidenavItemSubscription = this.sidenavService.currentlyActive$.subscribe(currentlyActive => {
      this.activeItemsMap = currentlyActive.reduce((obj, item) => {
        obj[item.id] = true;
        return obj;
      }, {});
    });

    if (this.smallDisplay) {
      const isIcon = this.isIconSidenav();
      if (!isIcon) {
          this.sidenavService.isIconSidenav = !this.sidenavService.isIconSidenav;
      }
     }
  }

  toggleIconSidenav() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
    this.sidenavService.isIconSidenav = !this.sidenavService.isIconSidenav;
    this.sidenavService.isIconSidenav ? this.mixPanelService.trackEvent('SideMenuColapsed', {}) : this.mixPanelService.trackEvent('SideMenuExpanded', {});
  }

  isIconSidenav(): boolean {
    return this.sidenavService.isIconSidenav;
  }

  sortRecursive(array: SidenavItem[], propertyName: string) {
    const that = this;

    array.forEach(function (item) {
      const itemKeys = keys(item);
      itemKeys.forEach(function (key) {
        if (isArray(item[key])) {
          item[key] = that.sortRecursive(item[key], propertyName);
        }
      });
    });

    return sortBy(array, propertyName) as SidenavItem[];
  }

  sendMixPanelEvent(ev, item){
    // if we click on a subitem we don't want to run this mixpanel event
    if(ev.target.innerText === item.name) {
      this.mixPanelService.trackEvent('MenuItemClicked', {
        menu_item_name: item.name,
      });
    }
  }

  ngOnDestroy() {
    this._itemsSubscription.unsubscribe();
    this._routerEventsSubscription.unsubscribe();
    this.activeSidenavItemSubscription.unsubscribe();
  }
}
