import { ContentModel } from './../article/article-content.model';
import { UnsafeAction } from '../unsafe-action.interface';

export const GET_POST_LIST = '[Post List] Get Post List';
export class GetPostListAction implements UnsafeAction {
  readonly type = GET_POST_LIST;
  constructor(public payload: any) { }
}

export const GET_POST_LIST_SUCCESS = '[Post List] Get Post List Success';
export class GetPostListSuccessAction implements UnsafeAction {
  readonly type = GET_POST_LIST_SUCCESS;
  constructor(public payload: any) { }
}

export const POST_LIST_FAILED = '[Post List] Post List Failed';
export class PostListFailedAction implements UnsafeAction {
  readonly type = POST_LIST_FAILED;
  constructor(public payload: any) { }
}

export const GET_EMPTY_POST_LIST = '[Post List] Get Empty Post List';
export class GetEmptyPostListAction implements UnsafeAction {
  readonly type = GET_EMPTY_POST_LIST;
  constructor() { }
}

export const DELETE_POST = '[Post List] Delete Post';
export class DeletePostAction implements UnsafeAction {
  readonly type = DELETE_POST;
  constructor(public payload: any) { }
}

export const DELETE_POST_SUCCESS = '[Post List] Delete Post Success';
export class DeletePostSuccessAction implements UnsafeAction {
  readonly type = DELETE_POST_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_POSTS_PAGE_VIEW = '[Post List] Update Post Pagination';
export class UpdatePostsPageViewAction implements UnsafeAction {
  readonly type = UPDATE_POSTS_PAGE_VIEW;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_POST = '[Posts] Get Active Post';
export class GetActivePostAction implements UnsafeAction {
  readonly type = GET_ACTIVE_POST;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_POST_SUCCESS = '[Posts] Get Active Post Success';
export class GetActivePostSuccessAction implements UnsafeAction {
  readonly type = GET_ACTIVE_POST_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_POST = '[Posts] Create post';
export class CreatePostAction implements UnsafeAction {
  readonly type = CREATE_POST;
  constructor(public payload: any = {}) { }
}

export const CREATE_POST_SUCCESS = '[Posts] Create post success';
export class CreatePostSuccessAction implements UnsafeAction {
  readonly type = CREATE_POST_SUCCESS;
  constructor(public payload: any = {}) { }
}

export const UPDATE_POST = '[Posts] Update post';
export class UpdatePostAction implements UnsafeAction {
  readonly type = UPDATE_POST;
  constructor(public payload: any = {}) { }
}

export const UPDATE_POST_SUCCESS = '[Posts] Update post success';
export class UpdatePostSuccessAction implements UnsafeAction {
  readonly type = UPDATE_POST_SUCCESS;
  constructor(public payload: any = {}) { }
}

export const PUBLISH_POST = '[Posts] Publish Post';
export class PublishPostAction implements UnsafeAction {
  readonly type = PUBLISH_POST;
  constructor(public payload) { }
}

export const PUBLISH_POST_SUCCESS = '[Posts] Publish Post Success';
export class PublishPostSuccessAction implements UnsafeAction {
  readonly type = PUBLISH_POST_SUCCESS;
  constructor(public payload) { }
}

export const UNPUBLISH_POST = '[Posts] Unpublish Post';
export class UnpublishPostAction implements UnsafeAction {
  readonly type = UNPUBLISH_POST;
  constructor(public payload) { }
}

export const UNPUBLISH_POST_SUCCESS = '[Posts] Unpublish Post Success';
export class UnpublishPostSuccessAction implements UnsafeAction {
  readonly type = UNPUBLISH_POST_SUCCESS;
  constructor(public payload) { }
}

export const REFRESH_POSTS_LIST = '[Posts] Refresh Posts List';
export class RefreshPostsListAction implements UnsafeAction {
  readonly type = REFRESH_POSTS_LIST;
  constructor() { }
}

// used for communication between "posts list" and "post edit" components
export const LOAD_NEW_POST = '[Posts] Load new post';
export class LoadNewPostAction implements UnsafeAction {
  readonly type = LOAD_NEW_POST;
  constructor(public payload: any) { }
}

export const UPDATE_POST_LIST_REFERENCED_CONTENT = '[Posts] Update Post List Referenced Content';
export class UpdatePostListReferencedContentAction implements UnsafeAction {
  readonly type = UPDATE_POST_LIST_REFERENCED_CONTENT;
  constructor(public payload: any) { }
}

export const MANAGE_POST_LIST_EMBEDDED_IMAGE_VISIBILITY = '[Post] Manage visibility of embedded image in post list';
export class ManageEmbeddedImageVisibilityAction implements UnsafeAction {
  readonly type = MANAGE_POST_LIST_EMBEDDED_IMAGE_VISIBILITY;
  constructor(public payload: any) { }
}

// post body actions:
export const UPDATE_POST_BODY_REFERENCED_CONTENT = '[Posts] Update post body referenced content';
export class UpdatePostBodyReferencedContentAction implements UnsafeAction {
  readonly type = UPDATE_POST_BODY_REFERENCED_CONTENT;
  constructor(public payload: ContentModel) {}
}

export const MANAGE_POST_EDITOR_EMBEDDED_IMAGE_VISIBILITY = '[Posts] Manage visibility of embedded image in editor';
export class ManagePostEditorEmbeddedImageVisibilityAction implements UnsafeAction {
  readonly type = MANAGE_POST_EDITOR_EMBEDDED_IMAGE_VISIBILITY;
  constructor(public payload: any) { }
}

/**
 * This action is almost the same as UpdatePostBodyAction, but we need a separate action
 * for the purpose of regulating the flow of data in content service. Load action will
 * be triggered on initial load or revision switch, and update will be triggered on any
 * update the user makes in editor itself
 */
export const LOAD_POST_BODY_INTO_EDITOR = '[Posts] Load post body into editor';
export class LoadPostBodyIntoEditorAction implements UnsafeAction {
  readonly type = LOAD_POST_BODY_INTO_EDITOR;
  constructor(public payload: ContentModel) { }
}

// this action is fired after loading the body into editor to reset the
// content delivery pipeline
export const LOAD_POST_BODY_INTO_EDITOR_SPACER = '[Posts] Load post body into editor spacer';
export class LoadPostBodyIntoEditorSpacerAction implements UnsafeAction {
  readonly type = LOAD_POST_BODY_INTO_EDITOR_SPACER;
  constructor() { }
}

/**
 * see notes for LoadPostBodyIntoEditorAction
 */
export const UPDATE_POST_BODY = '[Posts] Update post body';
export class UpdatePostBodyAction implements UnsafeAction {
  readonly type = UPDATE_POST_BODY;
  constructor(public payload: ContentModel) { }
}

export const COPY_POST_CONTENT_NODES = '[Posts] Copy body content nodes';
export class CopyPostContentNodesAction implements UnsafeAction {
  readonly type = COPY_POST_CONTENT_NODES;
  constructor(public payload: { contentNodes: any[], referencedContent: any }) { }
}

export const REMOVE_POST_CONTENT_NODE = '[Posts] Remove post content nodes';
export class RemovePostContentNodeAction implements UnsafeAction {
  readonly type = REMOVE_POST_CONTENT_NODE;
  constructor(public payload: string) { }
}

export const CREATE_POST_CONTENT_NODE = '[Posts] Create post content node';
export class CreatePostContentNodeAction implements UnsafeAction {
  readonly type = CREATE_POST_CONTENT_NODE;
  constructor(public payload: any) { }
}

export const UPDATE_POST_CONTENT_NODE = '[Posts] Update post content node';
export class UpdatePostContentNodeAction implements UnsafeAction {
  readonly type = UPDATE_POST_CONTENT_NODE;
  constructor(public payload: any) { }
}

export const CLEAR_POST_BODY_EDITOR = '[Posts] Clear post editor';
export class ClearPostEditorAction implements UnsafeAction {
  readonly type = CLEAR_POST_BODY_EDITOR;
  constructor() { }
}

export const LIVE_REPORT_TAB_CHANGE = '[Live Report] Change live Report page tab';
export class ChangeLiveReportPageTabAction implements UnsafeAction {
  readonly type = LIVE_REPORT_TAB_CHANGE;
  constructor() { }
}
