import {map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, of, from, combineLatest } from 'rxjs';
import { AppState } from '../app-reducer';
import { FilesUploadService } from '../../api/files/files-upload.service';
import {
  ADD_FILES_TO_QUEUE,
  AddFilesToQueueDoneAction,
  UPLOAD_FILE_STARTED,
  FileUploadedAction,
  AllFilesUploadedAction,
  UpdateQueuedFilesAction,
  FILE_UPLOADED,
  UpdateUploadedFilesAction,
} from './files-upload.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { getQueuedFilesArray } from './files-upload.reducer';
import { getActiveFolder } from '../files/files.reducer';

@Injectable()
export class FilesUploadEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private filesUploadService: FilesUploadService,
    private snackBar: MatSnackBar
  ) {}


  $addFilesToQueue: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(ADD_FILES_TO_QUEUE),
      map((action: UnsafeAction) => {
        // File validation for queued files
        const filesToEnqueue = action.payload.map(file => {
          file.exceedsLimit = this.filesUploadService.fileSizeLimitExceeds(file.fileData.size);

          // Check if file exceeds limit
          if (file.exceedsLimit) {
            this.snackBar.open($localize`File size limit exceeded`, null, { duration: 3000 });
          }

          file.metaData = file.metaData || {};
          file.metaData.name = file.metaData.name || (file.filename || file.fileData.name).replace(/\.[^.]*$/g, '');
          file.invalid = file.exceedsLimit;

          return { ...file };
        });
        return filesToEnqueue;
      }),
      map((queuedFiles: any) => new AddFilesToQueueDoneAction(queuedFiles))
    ));


  uploadFile$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(UPLOAD_FILE_STARTED),
      tap(() => this.snackBar.open($localize`Uploading. Please wait...`, null, { duration: 0 })),
      withLatestFrom(
        combineLatest([this.store.select(getQueuedFilesArray), this.store.select(getActiveFolder)])
      ),
      mergeMap(([action, [queuedFiles, activeFolder]]: [UnsafeAction, any]) => {
      const nonUploadedFiles = queuedFiles.filter(file => !file.uploadUrl);
      const validFiles = nonUploadedFiles.filter(file => !file.invalid);
      let formatFilename = validFiles.map(file => {
        const queuedFilenames = Object.keys(queuedFiles).map((keyname) => {
          return queuedFiles[keyname].filename;
        });
        const uploadedFilenames = activeFolder.content.map(child => child.filename);
        const usedNames = [...queuedFilenames, ...uploadedFilenames];
        file.filename = this.filesUploadService.generateName(file.filename || file.fileData.name, usedNames);
        return {...file};
      });
      return this.filesUploadService.createFileRequest(formatFilename, activeFolder).pipe(map(uploadUrls => {
        formatFilename = formatFilename.map((file: any) => {
          uploadUrls.upload_request
            .filter(({ filename }) => filename === (file.filename || file.fileData.name))
            .forEach(entry => {
              file.id = entry.file_id;
              file.uploadUrl = entry.upload_url;
              file.requestID = entry.request_id;
              file.uploading = true;
              file.name = entry.filename;
            });
          return file;
        });
        return new UpdateQueuedFilesAction(formatFilename);
      }));
    }),
    tap(
      (action: UnsafeAction) => {
        const queuedFiles = action.payload;

        let queuedFiles$ = queuedFiles.map((file: any) =>
          this.filesUploadService
            .uploadFile(file.fileData, file.uploadUrl).pipe(
            mergeMap(response => of({ httpEvent: response, file: file })))
        );

        queuedFiles$ = from(queuedFiles$);
        queuedFiles$
          .pipe(mergeMap((val: any) => val))
          .subscribe(uploadStatus => {
            if (uploadStatus.httpEvent.loaded && uploadStatus.httpEvent.total) {
              const uploadProgress =
                (uploadStatus.httpEvent.loaded / uploadStatus.httpEvent.total) * 100;
              this.filesUploadService.dispatchUploadProgress({
                file: uploadStatus.file.queueID,
                progress: uploadProgress,
              });
            }

            if (uploadStatus.httpEvent.status && uploadStatus.httpEvent.status === 200) {
              this.store.dispatch(new FileUploadedAction(uploadStatus.file));
              this.filesUploadService.dispatchUploadProgress({
                file: uploadStatus.file.queueID,
                progress: 'UPLOADED',
              });

              // Check if all files are uploaded
              const allUploaded = queuedFiles.every(file => file.uploaded);
              if (allUploaded) {
                this.snackBar.open($localize`Upload completed. Files are being processed.`, null, {
                  duration: 2000,
                });
                // Dispatch event that all files are uploaded!! Components will react on this event
                this.store.dispatch(new AllFilesUploadedAction(queuedFiles));
              }
            }
          });
      },
      err => this.snackBar.open($localize`Can't upload files. Please try again`, null, { duration: 2000 })
    )
  ));


  fileUploaded$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(FILE_UPLOADED),
      tap((action: UnsafeAction) => {
        this.store.dispatch(new UpdateUploadedFilesAction(action.payload));
      })
    ), { dispatch: false });
}
