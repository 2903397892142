<form fxLayout="column"
      fxLayoutAlign="space-around strech"
      [formGroup]="embeddableForm"
      (ngSubmit)="save()"
      novalidate>
    <div class="gd-embed-brightcove-dialog"
     fxLayout="column"
     fxLayoutAlign="space-between strech"
     [dir]="dir$ | async">

     <h2 mat-dialog-title i18n>Embed Brightcove Video</h2>
     <div class="gd-embed-brightcove-dialog__data-wrapper">
       <div>
          <section class="gd-embed-brightcove-dialog__preview">
              <iframe id="video-iframe"
              allowfullscreen
              webkitallowfullscreen
              mozallowfullscreen
              width="100%"
              height="100%"></iframe>
            </section>
       </div>
    <div fxLayout="column" fxFlex="270px">
    <div>
      <section>
        <mat-form-field class="gd-embed-brightcove-vimeo-dialog__id-wrapper" appearance="outline">
          <mat-label>Id</mat-label>
          <input matInput
                 type="text"
                 required
                 formControlName="id"
                 autocomplete="off">
          <mat-error *ngIf="idControl.hasError('invalidVideoId')" i18n>Invalid video ID</mat-error>
          <mat-error *ngIf="idControl.hasError('required')" i18n>Video ID is required</mat-error>
          <mat-error *ngIf="idControl.hasError('invalidBrightcoveSettings')" i18n>Brightcove settings are not valid
          </mat-error>
        </mat-form-field>
      </section>

      <section>
          <mat-form-field class="gd-embed-brightcove-dialog__name-wrapper"
                          appearance="outline"
                          [floatLabel]="!!dialogInputFormData.name ? 'always' : 'never'">
              <mat-label i18n>Name</mat-label>
              <textarea matInput
                        cdkTextareaAutosize
                        type="text"
                        [placeholder]="dialogInputFormData.name"
                        formControlName="name"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="4"
                        class="gd-custom-scroller"></textarea>
              <mat-error *ngIf="embeddableForm.get('name').hasError('required')" i18n>Name is required</mat-error>
            </mat-form-field>
      </section>
      <section>
        <div class="gd-article-form__input">
          <mat-slide-toggle formControlName="autoplay" i18n>Autoplay</mat-slide-toggle>
        </div>
      </section>
    </div>
  </div>
 </div>

  <section class="gd-embed-brightcove-dialog__description-wrapper">
        <mat-form-field class="gd-embed-brightcove-dialog__description"
                        appearance="outline"
                        [floatLabel]="!!dialogInputFormData.description ? 'always' : 'never'">
          <mat-label >Description</mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    type="text"
                    [placeholder]="dialogInputFormData.description"
                    formControlName="description"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="7"
                    class="gd-custom-scroller"></textarea>
        </mat-form-field>
    </section>
  <section class="gd-embed-brightcove-dialog-buttons">
      <button mat-raised-button
              color="primary"
              [disabled]="embeddableForm.invalid || embeddableForm.pending"
              type="submit"
              i18n
              class="gd-embed-brightcove-dialog-buttons--save">Save</button>
      <button mat-raised-button
              color="danger"
              i18n
              mat-dialog-close>Cancel</button>
    </section>
  </div>
</form>
