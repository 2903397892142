<div *ngIf="promoType==='image'">
   <div class="gd-promo-image__image-wrapper"
        [class.--disabled]="!hasActionPermission"
        [class.--collections]="sectionType==='collections'">
     <img class="promo-image --image"
          [class.--collections]="sectionType==='collections'"
          *ngIf="selectedImage"
          [src]="previewImageSrc.previewImage || previewImageSrc.url || previewImageSrc.thumbnail"
          alt=""
          [class.disabled]="!hasActionPermission"
          [class.--small-image]="isImageSmallDimensions">
   <div class="gd-promo-image__thumbnail-overlay"
        *ngIf="selectedImage">
       <div class="gd-promo-image__thumbnail-overlay--action-icons"
            fxLayout="column">
         <div fxFlex="75%"></div>
         <div fxFlex="25%"
              fxLayout="row"
              fxLayoutAlign="space-evenly center"
              *ngIf="hasActionPermission">
           <span (click)="selectImage('image')"
                 class="gd-promo-image__action-buttons"
                 matTooltip="Change image"
                 matTooltipPosition="below">
             <mat-icon fontSet="fal" fontIcon="fa-edit"></mat-icon>
           </span>
           <span (click)="navigateToItem()"
                  class="gd-promo-image__action-buttons"
                  matTooltip="Open in new tab"
                  i18n-matTooltip
                  matTooltipPosition="below">
              <mat-icon>open_in_new</mat-icon>
            </span>
           <span (click)="removeImage('image')"
                 class="gd-promo-image__action-buttons"
                 matTooltip="Delete image"
                 matTooltipPosition="below">
            <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
           </span>
         </div>
       </div>
   </div>

   <figure (click)="selectImage('image')"
          *ngIf="!selectedImage"
          mat-card-image
          class="promo-image-placeholder"
          [class.--collections]="sectionType==='collections'"
          [class.disabled]="!hasActionPermission">
    <h5>
     <img src="./../../../../assets/img/glide-logo-small.svg" alt="Image placeholder" height="42" width="42">
      <span i18n>Click to select image</span>
    </h5>
  </figure>
      </div>
    </div>

 <div *ngIf="promoType==='icon'">
    <div class="gd-promo-image__icon-wrapper">
      <div class="gd-promo-image__icon">
        <img class="gd-promo-image__icon-preview"
             *ngIf="selectedIcon"
             [src]="previewImageSrc.thumbnail || previewImageSrc.url || previewImageSrc.previewImage"/>
        <img *ngIf="!selectedIcon"
              src="./../../../../assets/img/glide-logo-small.svg"
              class="gd-promo-image__icon-placeholder"
              height="42"
              width="42"/>
              <div class="gd-promo-image__icon__new-tab-button"
                   *ngIf="selectedIcon">
                <button (click)="navigateToItem()"
                        matTooltip="Open in new tab"
                        i18n-matTooltip
                        matTooltipPosition="below">
                  <mat-icon>open_in_new</mat-icon>
                </button>
              </div>
       </div>

       <div class="gd-promo-image__icon-buttons-wrapper">
        <button *ngIf="selectedIcon"
                 mat-raised-button
                 class="gd-promo-image__icon-remove-button"
                 color="accent"
                 (click)="removeImage('icon')"
                 [disabled]="!hasActionPermission">
            {{ getRemoveBtnLabel() }}
         </button>
         <button mat-raised-button
                color="primary"
                (click)="selectImage('icon')"
                [disabled]="!hasActionPermission">
          {{ getChangeBtnLabel() }}
        </button>
      </div>
    </div>
  </div>
