<div class="gd-image-preview-container"
     [ngClass]="config.previewedContent"
     [dir]="dir$ | async">
  <div class="gd-image-preview">
    <div [class.inactive]="startOfList || disableArrows"
         class="gd-image-preview__navigation-arrow left"
         (click)="loadPreviousImage()">
      <button #navigationButton
              mat-icon-button
              class="gd-image-preview__navigation-btn"
              color="primary">
        <mat-icon class="gd-image-preview__navigation-btn" fontSet="fal" [fontIcon]="(dir$ | async) === 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"></mat-icon>
      </button>
    </div>

    <div #previewWrapperElRef
         class="gd-image-preview-wrapper">

      <img #previewImageElRef
           class="gd-image-preview__img"
           [style.max-width]="selectedImage.orientation === 'horizontal' ? config.maxPreviewWidth : imageRef.width + 'px'"
           [style.max-height]="config.maxPreviewHeight"
           [src]="selectedImageSrc"
           *ngIf="selectedImageSrc"
           [@previewImageEnter] />

      <img class="gd-image-preview__img --placeholder"
           [src]="selectedImage?.gridThumbnail"
           [style.max-width]="config.maxPreviewWidth"
           [style.max-height]="config.maxPreviewHeight"
           *ngIf="!selectedImageSrc"
           [@hidePlaceholderImage] />

      <svg class='hideSvgSoThatItSupportsFirefox'>
        <filter id='sharpBlur'>
          <feGaussianBlur stdDeviation='6'></feGaussianBlur>
          <feColorMatrix type="matrix"
                         values=".7 0 0 0 0 0 .7 0 0 0
                                 0 0 .7 0 0 0 0 0 1 0" />
          <feComposite in2='SourceGraphic'
                       operator='in'>
          </feComposite>
        </filter>
      </svg>
    </div>

    <div [class.inactive]="endOfList || disableArrows"
         class="gd-image-preview__navigation-arrow right"
         (click)="loadNextImage()">
      <button #navigationButton
              mat-icon-button
              class="gd-image-preview__navigation-btn"
              color="primary">
        <mat-icon class="gd-image-preview__navigation-btn" fontSet="fal" [fontIcon]="(dir$ | async) === 'rtl' ? 'fa-angle-left' : 'fa-angle-right'"></mat-icon>
      </button>
    </div>

  </div>
</div>

<div class="gd-scrolling-list-wrapper"
     *ngIf="config.showThumbnails">
  <gd-scrolling-list [collection]="loadedImages"
                     [previewedImage]="selectedImage">
  </gd-scrolling-list>
</div>

<div class="gd-image-preview__toolbar"
     fxLayout="row"
     fxLayoutAlign="space-between center"
     [dir]="dir$ | async">

  <div class="gd-image-preview__toolbar-col"
       fxFlexAlign="start">
       <mat-icon *ngIf="hasActionPermissions"
                 fonsSet="fal"
                 fontIcon="trash"
                 class="gd-image-preview__toolbar-btn"
                 (click)="triggerEventAction('DELETE_IMAGE')"></mat-icon>
  </div>

  <div class="gd-image-preview__toolbar-col"
       fxLayout="row"
       fxLayoutGap="16px">
    <mat-icon *ngIf="config.previewedContent !== 'gallery'"
              fontSet="fal"
              fontIcon="fa-edit"
              class="gd-image-preview__toolbar-btn"
              (click)="triggerEventAction('EDIT_IMAGE')"></mat-icon>
    <mat-icon (click)="closeDialog()" fontSet="fal" fontIcon="fa-times"></mat-icon>
  </div>
</div>
