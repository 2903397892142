import {filter} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ActivationStart } from '@angular/router';
import { AppState } from '../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { SetIsDisplayedFlagAction, ResetUISectionTitleStateAction } from '../../core/store/ui-section-title/ui-section-title.actions';
import {
  getSectionTitle,
  getIsDisplayedFlag,
  getSectionHasUpdates,
  getIsPublishedFlag,
  getSectionDescription,
  getIsLiveFlag,
  getSectionInfo,
} from '../../core/store/ui-section-title/ui-section-title.reducer';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-ui-section-title',
  templateUrl: './ui-section-title.component.html',
  styleUrls: ['./ui-section-title.component.scss']
})

export class UISectionTitleComponent implements OnInit, OnDestroy {

  isDisplayed$ = this.store.select(getIsDisplayedFlag);
  title$ = this.store.select(getSectionTitle);
  hasUpdates$ = this.store.select(getSectionHasUpdates);
  isPublished$ = this.store.select(getIsPublishedFlag);
  description$ = this.store.select(getSectionDescription);
  isLive$ = this.store.select(getIsLiveFlag);
  sectionInfo$ = this.store.select(getSectionInfo);

  noTitleLabel = $localize`No Title`;
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private bidiService: BidiService,
    ) { }

  ngOnInit(): void {
    const route = this.getCorrectRouteSnapshot(this.route);
    this.processRouteParams(route);

    this.router.events.pipe(
      filter(event => event instanceof ActivationStart))
      .subscribe((event: ActivationStart) => {
        // only invoke for terminal routes
        if (!!event.snapshot.firstChild) {
          return;
        }
        this.processRouteParams(event.snapshot);
      });
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetUISectionTitleStateAction());
  }

  processRouteParams(route) {
    const isFileEditPage = route['_routerState'].url.includes('media/files/edit');
    const isDisplayed = !!(route.data.displaySectionTitle && route.params.id && route.params.id !== 'create') || isFileEditPage;
    this.store.dispatch(new SetIsDisplayedFlagAction(isDisplayed));
  }

  getCorrectRouteSnapshot(route) {
    if (!route.firstChild) {
      return route.snapshot;
    }
    return this.getCorrectRouteSnapshot(route.firstChild);
  }

}
