import { Injectable } from '@angular/core';
import { ManageEmbeddedImageVisibilityAction, UpdatePostListReferencedContentAction } from '../../store/posts/posts.actions';
import { AbstractReferencedContentService } from './abstract-referenced-content.service';

@Injectable({
  providedIn: 'root',
})
export class PostListReferencedContentService extends AbstractReferencedContentService {
  protected EmbeddedImageVisibilityAction = ManageEmbeddedImageVisibilityAction;
  protected UpdateReferencedContentAction = UpdatePostListReferencedContentAction;
}
