import { Injectable } from '@angular/core';
import { ArticlesService } from '../articles/articles.service';
import Tribute from 'tributejs';
import { EmbeddedType } from '../../store/article/article-content.model';
import { highlightSearchTerm } from '../../../shared/shared-functions';
import { PostsService } from '../posts/posts.service';
import { get } from 'lodash-es';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getActiveWorkflowForArticles } from '../../store/workflows/workflow.reducer';
import { GetActiveWorkflowAction } from '../../store/workflows/workflow.actions';
import * as moment from 'moment-timezone';
import { getContentLocalesList } from '../../store/content-locales/content-locales.reducer';
import { SetTaxonomyFilterAction, getActiveArticleLocale, getFilteredTaxonomies } from '../../store';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';
import { PublicationState } from '../../store/advanced-filtering-option/advanced-filtering-option.model';
import { Subscription } from 'rxjs';

declare const $;

export const MentionKeys = {
  Article: '#',
  Taxonomy: '~',
  Post: '^',
};

@Injectable()
export class TributeService {

  tribute;
  isFilteringEnabled = true;
  editor;
  contentType;
  timeout;
  contentLocaleList = [];
  activeLocaleId = null;
  entities = ['article', 'post', 'taxonomy'];

  mentionDataFetch: Subscription = null;

  articleMentionConfig = {
    trigger: MentionKeys.Article,
    lookup: item => item.headline + item.catchline,
    fillAttr: 'headline',
    autocompleteMode: true,
    selectTemplate: item => {
      if (!this.isFilteringEnabled || !item || !item.original) {
        return '';
      }
      this.triggerEditorTranslateAndEmitFn();
      const published = !!item.original.publishedRevisionId;
      const scheduled = !!item.original.scheduledRevision;
      const publishDate = get(item, 'original.scheduledRevision.publishDateFrom');
      const scheduledOn = moment(publishDate).format('DD-MMM-YYYY h:mm A');
      const state = {
        status: published ?  'published' : scheduled ? 'scheduled' : 'unpublished',
        message: published ? '' : scheduled ? scheduledOn : `Article: [${item.original.headline}] is not published`,
      };
      const message = state?.message;
      const status = state?.status;
      const articleUrl = window.location.origin + `/articles/${item.original.id}`;
      return (
        `<span class="fr-deletable gd-content-${state?.status}" contenteditable="false" data-type="${EmbeddedType.ArticleMention}">` +
        `<a data-id="${item.original.id}" href="${
          item.original.url || articleUrl || '-'
        }" contenteditable="true">${item.original.headline}${status !== 'published' ? `<i class="fal ${
          status === 'scheduled' ? 'fa-clock' : 'fa-exclamation-circle'
        }" contenteditable="false" title="${message}">&nbsp</i>` : ''}</a></span>`
      );
    },
    menuItemTemplate: item => this.createItemTemplate(item.original),
    values: (text, callback) => this.articleMentionFilter(text, data => callback(data)),
  };

  postMentionConfig = {
    trigger: MentionKeys.Post,
    lookup: (item) => {
      return get(item, 'publishedRevision.headline', '') + get(item, 'publishedRevision.catchline', '');
    },
    fillAttr: 'headline',
    autocompleteMode: true,
    selectTemplate: (item) => {
      if (!this.isFilteringEnabled || !item || !item.original) {
        return '';
      }
      this.triggerEditorTranslateAndEmitFn();
      return `<span class="fr-deletable" contenteditable="false" data-type="${EmbeddedType.PostMention}">`
        + `<a data-id="${item.original.id}" data-live-report-id="${item.original.liveReportId}" href="#${item.original.glideId || '-'}" contenteditable="true">${get(item, 'original.publishedRevision.headline', 'No Post Headline')}</a></span>`;
    },
    menuItemTemplate: (item) => {
      return this.createItemTemplate(item.original, 'liveReports');
    },
    values: (text, callback) => {
      return this.postMentionFilter(text, data => callback(data));
    }
  };

  taxonomyMentionConfig = {
    trigger: MentionKeys.Taxonomy,
    lookup: (tax) => {
      return [...tax.taxonomyDisplayData].pop();
    },
    autocompleteMode: true,
    selectTemplate: (item) => {
      if (!this.isFilteringEnabled || !item || !item.original) {
        return '';
      }
      this.triggerEditorTranslateAndEmitFn();
      const tax = get(item, `original.localizedDisplayData.${this.activeLocaleId}`, item.original);
      return `<span class="fr-deletable" contenteditable="false" data-type="${EmbeddedType.TaxonomyMention}">`
        + `<a data-id="${item.original.id}" href="taxonomies/${item.original.id}" contenteditable="true" data-name="${tax.name}" data-slug="${tax.slug}">${tax.name}</a></span>`;
    },
    menuItemTemplate: (item) => {
      return this.createItemTemplate(item.original, 'taxonomies');
    },
    values: (text, callback) => {
      return this.taxonomyMentionFilter(text, data => callback(data));
    }
  };

  articleMentionFilter = (text, callback) => {
    this.entities.forEach(entity => $('.tribute-container').removeClass(`--${entity}-mention`));
    clearTimeout(this.timeout);

    $('.tribute-container').addClass('custom --article-mention');
    this.isFilteringEnabled ? $('.tribute-container').removeClass('--hidden') : $('.tribute-container').addClass('--hidden');
    if (text.length < 3 || !this.isFilteringEnabled) {
      return callback([]);
    }
    this.timeout = setTimeout(() => {
      if (this.mentionDataFetch) {
        this.mentionDataFetch.unsubscribe();
      }
      const queryFilter = {
        include: {
          headlineOrCatchline: {
            like: text,
          },
          publicationState: PublicationState.ScheduledOrPublished,
          // should we do generateUrl here? maybe not, done in referenced data fetch
        },
      };
      this.mentionDataFetch = this.articlesService
        .getArticlesAdvancedFiltering({
          page: 0,
          limit: 5,
          filter: queryFilter,
        }, { noPagination: true })
        .subscribe((data) => {
          this.mentionDataFetch = null;
          callback(data.articles);
        });
      this.timeout = null;
    }, 300);
  }

  postMentionFilter = (text, callback) => {
    this.entities.forEach(entity => $('.tribute-container').removeClass(`--${entity}-mention`));
    clearTimeout(this.timeout);

    $('.tribute-container').addClass('custom --post-mention');
    this.isFilteringEnabled ? $('.tribute-container').removeClass('--hidden') : $('.tribute-container').addClass('--hidden');
    if (text.length < 3 || !this.isFilteringEnabled) {
      return callback([]);
    }

    const pathname = window.location.pathname;
    const liveReportingRegex = /\/live-reporting\/(\d+)/;
    const isMatch = liveReportingRegex.exec(pathname);

    const liveReportId = Array.isArray(isMatch) && isMatch[1];

    const id = parseInt(liveReportId, 10);

    this.timeout = setTimeout(() => {
      this.postsService
        .getPostsList({ id, page: 0, size: 5, name: text, published: true })
        .toPromise().then(response => {
          const data = response.data.map(post => ({ ...post, liveReportId: id }));

          callback(data);
        }).catch(err => {
          callback([]);
        });
      this.timeout = null;
    }, 200);
  }

  taxonomyMentionFilter = (text, callback) => {
    const tributeEl = $('.tribute-container');
    this.entities.forEach(entity => tributeEl.removeClass(`--${entity}-mention`));
    tributeEl.addClass('custom --taxonomy-mention');
    clearTimeout(this.timeout);
    this.isFilteringEnabled ? tributeEl.removeClass('--hidden') : tributeEl.addClass('--hidden');
    if (text.length < 3 || !this.isFilteringEnabled) {
      return callback([]);
    }
    this.timeout = setTimeout(() => {
      const showTaxonomyPath = this.accountSettingsService.getShowTaxonomyPathFlag();
      const localeId = this.contentType === 'ARTICLE' ? this.activeLocaleId : null;
      this.store.dispatch(new SetTaxonomyFilterAction(text));
      this.store.select(getFilteredTaxonomies(50, showTaxonomyPath, true, localeId))
        .pipe(take(1))
        .subscribe(taxonomies => {
          callback(taxonomies)
      })
      this.timeout = null;
    }, 200);
  }

  constructor(
    private articlesService: ArticlesService,
    private postsService: PostsService,
    private store: Store,
    private accountSettingsService: AccountSettingsService
  ) {
    this.store.select(getContentLocalesList).subscribe(locales => this.contentLocaleList = locales);
    this.store.select(getActiveArticleLocale)
      .pipe(map(cl => cl?.id || null))
      .subscribe(localeId => this.activeLocaleId = localeId);
   }

  init(contentType) {
    this.contentType = contentType;
    let collection = [this.articleMentionConfig, this.taxonomyMentionConfig];
    if (contentType === 'LR_POST') {
      collection = [...collection, this.postMentionConfig];
    }
    this.tribute = new Tribute({
      collection,
      allowSpaces: true,
      noMatchTemplate: () => {
        const isValidInput = this.tribute.currentMentionTextSnapshot && this.tribute.currentMentionTextSnapshot.length >= 3;
        return isValidInput ? '<li>No Match</li>' : '<li style="font-style: italic">Type at least 3 letters..</li>';
      }
    });
  }
  createItemTemplate(item, type = 'articles') {
    if (type === 'articles') {
      let articleLocale = null;
      highlightSearchTerm(item, this.tribute.currentMentionTextSnapshot, type);
      let html = `<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${item.headlinePlaceholder}</span>` +
                 `<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${item.catchlinePlaceholder}</span>`;
      const activeLocales = this.contentLocaleList.filter(locale => locale.active);
      if (activeLocales.length > 1) {
        articleLocale = this.contentLocaleList.find(locale => locale.id === item.contentLocaleId);
      }
      if (articleLocale) {
        html = `<div style="display: flex; flex-direction: row">` +
                    `<img src="${articleLocale.iconUrl}" style="padding-right:10px; width: 20px" />` +
                    `<span style="display:flex; flex-direction:column; width: 300px">` +
                      html +
                    `</span>` +
                `<div>`;
      }
      return html;
    }
    if (type === 'liveReports') {
      highlightSearchTerm(item.publishedRevision, this.tribute.currentMentionTextSnapshot, type);
      return `<span>${item.publishedRevision.headlinePlaceholder}</span><span>${item.publishedRevision.catchlinePlaceholder}</span>`;
    }
    if (type === 'taxonomies') {
      const text = this.highlightTextPart([...item.taxonomyDisplayData].pop(), this.tribute.currentMentionTextSnapshot);
      const menuItemParts = [...item.taxonomyDisplayData];
      menuItemParts[menuItemParts.length - 1] = text;
      const output = menuItemParts.map(part => {
        if (part !== 'NODE_PLACEHOLDER') {
          return `<span>${part}</span>`;
        }
        return `<span class="taxonomy-placeholder" title="${item.fullPath}"></span>`;
      }).join('<span><b>></b></span>');
      return `<div class="taxonomy-dropdown-item">${output}</div>`
    }
  }

  showTributeMenu(element, trigger) {
    this.isFilteringEnabled = true;
    const collectionIndex = this.tribute.collection.findIndex(c => c.trigger === trigger);
    this.tribute.showMenuForCollection(element, collectionIndex);
  }

  attach(editor) {
    this.editor = editor;
    this.tribute.attach(editor.el);
  }

  isActive() {
    return this.tribute.isActive && this.isFilteringEnabled;
  }

  setIsFilteringEnabledFlag(flag) {
    this.isFilteringEnabled = flag;
  }

  reset() {
    this.tribute.detach(this.editor.el);
    setTimeout(() => {
      this.init(this.contentType);
      this.tribute.attach(this.editor.el);
    }, 100);
  }

  highlightTextPart(text, term) {
    term = (term || '').toLowerCase();
    const textLC = text.toLowerCase();
    if (!textLC.includes(term)) {
      return text;
    }
    const index = textLC.indexOf(term);
    const highlightedPart = text.slice(index, index + term.length);
    return text.replace(highlightedPart, `<b>${highlightedPart}</b>`)
  }

  triggerEditorTranslateAndEmitFn() {
    setTimeout(() => this.editor.events.trigger('blur'), 50);
  }
}
