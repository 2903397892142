import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '..';
import { ArticleType } from '../../store/article-type/article-type.model';
import { of } from 'rxjs';
import { get } from 'lodash-es';
import { FoundationModelProvider, FoundationModels } from '../../../shared/content-generation/content-generation-models';

@Injectable({
  providedIn: 'root',
})
export class ArticleTypesService {
  constructor(private rest: RestService) { }

  getAll({ name = '', pageNumber = 0, pageSize = 200 }: any = {}) {
    name = encodeURIComponent(name) || '';
    const requestPath = `article-types?name=${name}&page=${pageNumber || 0}&size=${pageSize || 20}`;
    return this.rest.get(requestPath).pipe(map(response => {
      return response.data.map(at => prepareArticleType(at));
    }));
  }

  getBasicProjection({ name = '', pageNumber = 0, pageSize = 200 }: any = {}) {
    name = encodeURIComponent(name) || '';
    const requestPath = `article-types/basic-projection?name=${name}&page=${pageNumber || 0}&size=${pageSize || 20}`;
    return this.rest.get(requestPath).pipe(map(response => {
      return response.data.map(at => prepareArticleType(at));
    }));
  }

  getOne(id: number) {
    if(!id) {
      throw new Error("Invalid article type ID: " + id);
    }
    return this.rest.get('article-types/' + id).pipe(map(response => prepareArticleType(response.data)));
  }

  create(articleType: ArticleType) {
    const payload = { ...articleType };
    delete payload.id;
    return this.rest.post('article-types', payload).pipe(map((articleTypeData: any) => prepareArticleType(articleTypeData.data)));
  }

  update(articleType: ArticleType) {
    return this.rest
      .put('article-types/' + articleType.id, articleType).pipe(
      map((articleTypeData: any) => prepareArticleType(articleTypeData.data)));
  }

  delete(id: number) {
    return this.rest.delete('article-types/' + id);
  }

  getArticleTypesByIds(articleTypesIds) {
    if (articleTypesIds.length < 1) {
      return of([]);
    }

    const size = articleTypesIds.length <= 200 ? articleTypesIds.length : 200;

    return this.rest.get(`article-types?ids=${articleTypesIds.join(',')}&size=${size}`).pipe(map(response => response.data));
  }
}

// options displayed in a default order
export const articleSidebarOptions = {
  full_width_editor: {
    key: 'full_width_editor',
    label: $localize`Full-width Text Editor`,
    enabled: true,
    isDefault: true,
    icon: 'fa-text-width',
    position: 1
  },
  summary_generation: {
    key: 'summary_generation',
    label: $localize`Summary`,
    enabled: false,
    isDefault: false,
    icon: 'fa-file-alt',
    position: 2
  },
  draft_generation: {
    key: 'draft_generation',
    label: $localize`Drafting Assistant`,
    enabled: false,
    isDefault: false,
    icon: '',
    svgIcon: 'glideCustomIcon_gaia_drafting_assistant',
    position: 3
  },
  related_articles: {
    key: 'related_articles',
    label: $localize`Related Articles`,
    enabled: false,
    isDefault: false,
    icon: 'fa-quote-right',
    position: 4
  },
};

function prepareArticleType(articleType) {
  let articleSidebar = get(articleType, 'additionalConfiguration.articleSidebar', [])
    .filter(option => !!Object.keys(option).length)
    .map(option => ({ ...(articleSidebarOptions[option.key] || {}), ...option }));
  Object.keys(articleSidebarOptions)
    .filter(key => !articleSidebar.find(option => option?.key === key))
    .forEach(key => articleSidebar.push({ ...articleSidebarOptions[key], position: articleSidebar.length + 1}));

  const additionalConfiguration = articleType.additionalConfiguration || {};
  // support old article types
  if (!additionalConfiguration.articleSidebar) {
    const relArticlesOption = articleSidebar.find(option => option.key === 'related_articles');
    relArticlesOption.enabled = !additionalConfiguration.hasOwnProperty('relatedArticlesPanelHidden') || !additionalConfiguration.relatedArticlesPanelHidden;
    if (relArticlesOption.enabled) {
      articleSidebar.forEach(option => option.isDefault = false);
      relArticlesOption.isDefault = true;
    }
  }

  // make sure sidebar options are always in a default order until we add reordering functionality
  articleSidebar.forEach(option => {
    option.position = articleSidebarOptions[option.key]?.position || articleSidebar.length;
  });
  articleSidebar = articleSidebar.sort((a, b) => a.position - b.position);
  return {
    ...articleType,
    additionalConfiguration: { ...additionalConfiguration, articleSidebar }
   };
}

export function prepareTextGenerationConfig(modelId, settings) {
  const textGenerationConfig: any = Object.entries(settings).reduce((acc, [key, val]) => {
    const fieldToSkip = ['modelId', 'title', 'description'];
    return fieldToSkip.includes(key) ? acc : { ...acc, [key]: val };
  }, {});

  // Amazon models
  const amazonModelIds = FoundationModels
    .filter((m) => m.provider === FoundationModelProvider.Amazon)
    .map((m) => m.modelId);
  if (amazonModelIds.includes(modelId)) {
    return { textGenerationConfig };
  }

  // AI21Lab models
  const jurassicModelIds = FoundationModels
    .filter((m) => m.provider === FoundationModelProvider.AI21Lab)
    .map((m) => m.modelId);
  if (jurassicModelIds.includes(modelId)) {
    const additionalParams = (textGenerationConfig.specialTokens || [])
      .reduce((acc, token) => ({ ...acc, [token]: true }), {});
    delete textGenerationConfig.specialTokens;

    ['presencePenalty', 'countPenalty', 'frequencyPenalty'].forEach(key => {
      const prop = key.replace('Penalty', '');
      textGenerationConfig[prop] = { scale: textGenerationConfig[key], ...additionalParams };
      delete textGenerationConfig[key];
    });

    return textGenerationConfig;
  }
  return textGenerationConfig;
}

