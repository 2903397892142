<div class="gd-add-content-tag-dialog"
[dir]="dir$ | async">
  <form fxLayout="column"
        fxLayoutAlign="space-around stretch"
        [formGroup]="contentTagForm"
        (ngSubmit)="save()"
        novalidate>
    <h2 mat-dialog-title i18n="Modal headline">Add Content Tag</h2>

    <section fxFlex="100%" fxLayout="column">
      <mat-form-field class="gd-add-content-tag-dialog__field"
                      appearance="outline"
                      floatLabel="always">
        <mat-label i18n>Content Tag</mat-label>
        <mat-select formControlName="identifier"
                    i18n-placeholder
                    placeholder="Select Content Tag">
          <mat-option *ngFor="let ct of contentTags$ | async"
                      [value]="ct.identifier"
                      (click)="selectContentTag(ct)">
            {{ ct.name }}
          </mat-option>
        </mat-select>
        <mat-error i18n>Content Tag is required</mat-error>
      </mat-form-field>
      <mat-checkbox *ngIf="!editingMode && usage !== 'template'" style="margin-bottom: 10px" formControlName="includeClosingMarker" i18n>Include closing content tag</mat-checkbox>
    </section>

    <section class="gd-add-content-tag-dialog__section-buttons">
      <button mat-raised-button
              color="primary"
              type="submit"
              i18n
              [disabled]="contentTagForm.invalid">Save</button>
      <button mat-raised-button
              color="danger"
              i18n
              mat-dialog-close>Cancel</button>
    </section>
  </form>
</div>
