import { filter } from "rxjs/operators";

declare const $: any;

export function clearTextEditor(modalsService) {

  // Check if the command is already defined
  if($.FroalaEditor.COMMANDS.clearTextEditor) {
    return;
  }

  $.FroalaEditor.DefineIconTemplate('fas', '<mat-icon class="mat-icon froala fas fa-[NAME]"></mat-icon>')

  $.FroalaEditor.DefineIcon('clearTextEditor', { ALT: 'Clear all button', template: 'fas', NAME: 'times' });

  $.FroalaEditor.RegisterCommand('clearTextEditor', {
    title: 'Clear All',
    icon: 'clearTextEditor',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      modalsService
        .confirm(
          $localize`Clear All`,
          $localize`Clear content editor?`
        )
        .pipe(filter((r) => !!r))
        .subscribe(() => {
          //Read the content from the editor
          this.html.set('');
        });
    }
  });
}
