import { AccountSettingsService } from './account-settings/accounts-settings.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CharacterCountLimitsService {

  constructor(private accountSettingsService: AccountSettingsService) { }

  hasAppropriateLength(section, value, field) {
    if (!value) {
      return;
    }
    const characterCountLimits = this.accountSettingsService.getCharacterCountLimits();
    const minLimit = characterCountLimits[section + field + 'Min'];
    const maxLimit = characterCountLimits[section + field + 'Max'];
    const isMaxLimitSatisfied = this.fieldMeetsMaxLength(value, maxLimit);
    const isMinLimitSatisfied = this.fieldMeetsMinLength(value, minLimit);
    return value.length && isMaxLimitSatisfied && isMinLimitSatisfied;

  }

  fieldMeetsMaxLength(value, maxLimit) {
    if (!maxLimit && maxLimit !== 0) {
      return true;
    }
    return value.length <= maxLimit;
  }

  fieldMeetsMinLength(value, minLimit) {
    if (!minLimit) {
      return true;
    }
    return value.length >= minLimit;
  }
}
