import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { getActiveArticleLocale } from '../../core/store';
import { filter, map, Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { ArticleContentService } from '../../core/body-content/article-content.service';
import { ModalsService } from '../../shared/modals/modals.service';
import { FroalaEmbedWidgetComponent } from '../../shared/froala-embed-widget/froala-embed-widget.component';
import { Router, RouterModule } from '@angular/router';
import { LiveReportPostContentService } from '../../core/body-content/live-report-post-content.service';
import { EditorBodyContentService } from '../../core/body-content/editor-body-content.service.interface';
import { WidgetConfigPreviewModule } from '../widget-config-preview/widget-config-preview.module';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ReferencedContentRepositoryService } from '../../core/api/referenced-content-repository/referenced-content-repository.service';
import { ResourceType } from '../../core/store/referenced-content-repository/referenced-content-repository.model';
@Component({
  standalone: true,
  selector: 'gd-editor-widget-card-frame',
  styleUrls: ['./editor-widget-card-frame.component.scss'],
  templateUrl: './editor-widget-card-frame.component.html',
  imports: [
    WidgetConfigPreviewModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
  host: {
    '[class.widget-loading]': 'isLoading'
  },
})
export class EditorWidgetCardFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('id') nodeId;
  @Input('data-id') dataId;
  @Input('usage') usage;
  node = null;
  widget = null;
  widgetIcon: any;
  widgetColour: string;
  previewFieldNumber: number;
  activeLocale;
  displayOptions = [
    { id: -1, name: $localize`:Article widget display option:Inline`, className: 'fr-dii' },
    { id: -2, name: $localize`:Article widget display option:Break text`, className: 'fr-dib' },
  ];
  displayOptionSelected = -2;
  alignOptions = [
    { id: -1, name: `alignLeft`, icon: 'fa-align-left', className: 'fr-fil' },
    { id: -2, name: `alignCenter`, icon: 'fa-align-center', className: 'fr-fic'},
    { id: -3, name: `alightRight`, icon: 'fa-align-right', className: 'fr-fir' },
  ];
  alignOptionSelected = -2;
  contextShadowRoot = null;

  glideContentType;
  contentService: EditorBodyContentService = null;
  componentSub: Subscription = new Subscription();
  isLoading = true;

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private modalsService: ModalsService,
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router,
    private referencedContentService: ReferencedContentRepositoryService
  ) {}

  ngOnInit() {
    this.glideContentType = this.router.url.includes('article') ? 'ARTICLE' : 'LR_POST';
    this.initServicesBasedOnGlideContentType();
    this.contextShadowRoot = this.el.nativeElement.shadowRoot;
    const getContentNodeFunction = this.usage === 'quickView' ? 'getQuickViewContentNode' : 'getContentNode';
    this.componentSub.add(
      this.contentService[getContentNodeFunction](this.nodeId)
        .pipe(take(1))
        .subscribe((node) => {
          if (!node) {
            return;
          }
          this.node = node;
          const align = this.node?.alignment;
          this.alignOptionSelected = align === 'left' ? -1 : align === 'center' ? -2 : -3;
          this.displayOptionSelected = this.node.isInline ? -1 : -2;
        })
    );

    this.componentSub.add(
      this.referencedContentService
        .getData(+this.dataId, ResourceType.Widget)
        .subscribe((widgetTypes) => {
          this.widget = widgetTypes.data;
          if (!this.widget) {
            return;
          }
          this.previewFieldNumber = this.calculateNumberOfFields(this.widget);
          const className = this.previewFieldNumber === 1 ? 'one-column-preview' : 'two-column-preview';
          this.updateClass('addClass', className);
          this.widgetColour = this.widget?.colour || '#5c6bc0';
          const defaultWidgetIcon = this.getDefaultIcon(this.widget.thirdParty);
          this.widgetIcon = this.widget?.icon || defaultWidgetIcon;
          this.isLoading = false;
        })
    );

    this.componentSub.add(
      this.store
        .select(getActiveArticleLocale)
        .pipe(take(1))
        .subscribe((activeLocale) => (this.activeLocale = activeLocale))
    );
  }

  ngAfterViewInit(): void {
    this.addLinks();
  }

  initServicesBasedOnGlideContentType() {
    switch (this.glideContentType) {
      case 'ARTICLE': {
        this.contentService = this.injector.get(ArticleContentService);
        break;
      }

      case 'LR_POST': {
        this.contentService = this.injector.get(LiveReportPostContentService);
        break;
      }

      default: {
        throw new Error('Invalid Glide content type supplied to body content editor!');
      }
    }
  }

  calculateNumberOfFields(widget) {
    return [widget?.primaryPreviewField, widget?.secondaryPreviewField].filter((key) => {
      return !!key && !!widget?.configuration.find((field) => field.key === key);
    }).length;
  }

  getDefaultIcon(isWidgetThirdParty) {
    if (isWidgetThirdParty) {
      return {
        icon: 'glideCustomIcon_third-party-widgets',
        isCustomSvg: true,
        label: $localize`3rd Party widget`,
      };
    }
    return {
      icon: 'glideCustomIcon_system-widgets',
      isCustomSvg: true,
      label: $localize`System widget`,
    };
  }

  deleteNode() {
    this.contentService.removeContentNode(this.nodeId);
    this.triggerEditorChange('widgetDeletedChangeEvent', { changed: true, nodeId: this.nodeId });
  }

  editNode() {
    const data = {
      ...this.node,
      embedType: this.node.type,
      id: this.node.dataId,
      blockId: this.node.id,
      editingMode: true,
      glideContentType: this.glideContentType,
      activeLocale: this.activeLocale,
    };

    this.editWidgetModal(data)
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.triggerEditorChange('widgetChangeEvent', { changed: true });
        this.contentService.updateContentNode(res);
      });
  }

  editDisplay(id) {
    const removeClassName = this.displayOptions.find(option => option.id === this.displayOptionSelected).className;
    this.updateClass('removeClass', removeClassName)
    const addClassName = this.displayOptions.find(option => option.id === id).className;
    this.updateClass('addClass', addClassName)
    this.displayOptionSelected = id;
    this.triggerEditorChange('widgetAlignOrDisplayChangeEvent', { changed: true });
  }

  editAlign(item) {
    const removeClassName = this.alignOptions.find(option => option.id === this.alignOptionSelected).className;
    this.updateClass('removeClass', removeClassName)
    const addClassName = this.alignOptions.find(option => option.id === item.id).className;
    this.updateClass('addClass', addClassName)
    this.alignOptionSelected = item.id;
    this.triggerEditorChange('widgetAlignOrDisplayChangeEvent', { changed: true });
  }

  triggerEditorChange(eventName, eventData) {
    var event = new CustomEvent(eventName, {
      detail: eventData,
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(event);
  }

  editWidgetModal(dialogInputData) {
    return this.modalsService
      .custom($localize`Embed Widget`, FroalaEmbedWidgetComponent, {
        data: dialogInputData,
        width: '850px',
        autoFocus: dialogInputData?.editingMode ? 'gd-dynamic-widget-form' : true,
        disableClose: true,
        maxHeight: '90vh',
      })
      .pipe(
        map((event) => {
          if (event) {
            return {
              type: dialogInputData.embedType,
              blockId: dialogInputData.blockId,
              updateData: event.updateData,
            };
          }
          return null;
        })
      );
  }

  addLinks() {
    let link = this.renderer.createElement('link');
    link.rel = 'stylesheet';
    link.href = '../../../assets/fa-5/all.min.css';
    let fontIcons = this.renderer.createElement('link');
    fontIcons.rel = 'stylesheet';
    fontIcons.href = 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';

    const shadowRoot = this.el.nativeElement.shadowRoot;
    shadowRoot.appendChild(link);
    shadowRoot.appendChild(fontIcons);
  }

  updateClass(method, className) {
    this.renderer[method](this.el.nativeElement, className);
  }

  ngOnDestroy(): void {
    this.componentSub.unsubscribe();
  }
}
