import { HtmlSnippet } from './html-snippets.model';
import { filter, mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';
import { SetSectionTitleAction } from '../ui-section-title/ui-section-title.actions';
import { HtmlSnippetsService } from '../../api/html-snippets/html-snippets.service';
import {
  GET_HTML_SNIPPETS,
  GetHtmlSnippetsSuccessAction,
  HtmlSnippetFailedAction,
  CREATE_HTML_SNIPPET,
  CreateHtmlSnippetSuccessAction,
  DELETE_HTML_SNIPPET,
  DeleteHtmlSnippetSuccessAction,
  GET_HTML_SNIPPET,
  GetHtmlSnippetSuccessAction,
  UPDATE_HTML_SNIPPET,
  UpdateHtmlSnippetSuccessAction,
  HTML_SNIPPET_ACTION_FAILED,
} from './html-snippets.actions';

@Injectable()
export class HtmlSnippetsEffects {

  loadHtmlSnippets$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_HTML_SNIPPETS)).pipe(
    mergeMap((action) => {
      return this.htmlSnippetsService.getHtmlSnippets().pipe(
        map((htmlSnippets) => new GetHtmlSnippetsSuccessAction(htmlSnippets)),
        catchError((e) => of(new HtmlSnippetFailedAction(e)))
      );
    })
  ));


  createHtmlSnippet$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_HTML_SNIPPET),
    mergeMap((action: UnsafeAction) => {
      const htmlSnippet = { ...action.payload };
      return this.htmlSnippetsService.createHtmlSnippet(htmlSnippet).pipe(
        tap((data) => {
          this.snackBar.open($localize`HTML Snippet created successfully.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/widgets/html-snippets/']);
          setTimeout(() => this.store.dispatch(new SetSectionTitleAction(data.label)), 0);
          return data;
        }),
        map((data: HtmlSnippet) => new CreateHtmlSnippetSuccessAction(data)),
        catchError((e) => of(new HtmlSnippetFailedAction(e)))
      );
    })
  ));


  deleteHtmlSnippet$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_HTML_SNIPPET)).pipe(
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.htmlSnippetsService.deleteHtmlSnippet(id).pipe(
        map(() => new DeleteHtmlSnippetSuccessAction(id)),
        tap(() => this.snackBar.open($localize`HTML Snippet deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Failed to delete HTML Snippet!`, $localize`Close`);
          return of(new HtmlSnippetFailedAction(e));
        })
      );
    })
  ));


  getHtmlSnippet$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_HTML_SNIPPET),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload.id;
      return this.htmlSnippetsService.getHtmlSnippet(id).pipe(
        filter((htmlSnippet) => {
          if (htmlSnippet) {
            return true;
          }
          this.router.navigate(['/widgets/html-snippets/']);
          setTimeout(
            () => this.snackBar.open($localize`Invalid HTML Snippet Id.`, $localize`Close`, { duration: 4000 }),
            100
          );
          return false;
        }),
        map((htmlSnippet) => new GetHtmlSnippetSuccessAction(htmlSnippet)),
        catchError((e) => of(new HtmlSnippetFailedAction(e)))
      );
    })
  ));


  updateHtmlSnippet$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_HTML_SNIPPET)).pipe(
    mergeMap((action: UnsafeAction) => {
      const htmlSnippet = { ...action.payload };
      return this.htmlSnippetsService.updateHtmlSnippet(htmlSnippet).pipe(
        map((data) => new UpdateHtmlSnippetSuccessAction(data)),
        tap(() => {
          this.snackBar.open($localize`HTML Snippet successfully updated.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/widgets/html-snippets/']);
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update HTML Snippet!`, $localize`Close`);
          return of(new HtmlSnippetFailedAction(e));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(HTML_SNIPPET_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    }),
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));


  notFound$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(HTML_SNIPPET_ACTION_FAILED),
    tap((err: any) => {
      this.router.navigate(['/widgets/html-snippets/']);
      this.snackBar.open($localize`HTML Snippet not found.`, $localize`Close`, { duration: 4000 });
    }),
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private htmlSnippetsService: HtmlSnippetsService
  ) {}
}
