
import {map} from 'rxjs/operators';
import { RestService } from '../rest.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RoutesService {

  constructor(private rest: RestService) { }

  getRoutes() {
    return this.rest
      .get(`routes`).pipe(
      map((payload: any) => payload.data));
  }

  createRoute(payload) {
    const newRoute = { ...payload };
    delete newRoute.id;

    return this.rest
      .post('routes', newRoute);
  }

  updateRoute(payload) {
    const updatedRoute = { ...payload };
    return this.rest
      .put('routes/' + updatedRoute.id, updatedRoute).pipe(
      map((response: any) => response.data));
  }

  updateAllRoutes(updatedRoutes) {
    return this.rest
      .put('routes/', updatedRoutes).pipe(
      map((response: any) => response.data));
  }

  deleteRoute(payload) {
    return this.rest
      .delete('routes/' + payload.id, payload);
  }

  getRoutesByPageId(id) {
    return this.rest
      .get(`pages/${id}/routes`).pipe(
      map((response: any) => response.data));
  }

}
