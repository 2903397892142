import { UnsafeAction } from '../unsafe-action.interface';

export const GET_COLLECTIONS = '[COLLECTIONS LIST] Get Collections';
export class GetCollectionsAction implements UnsafeAction {
  readonly type = GET_COLLECTIONS;
  constructor(public payload: any = {}) { }
}

export const GET_COLLECTIONS_SUCCESS = '[COLLECTIONS LIST] Get Collections Success';
export class GetCollectionsSuccessAction implements UnsafeAction {
  readonly type = GET_COLLECTIONS_SUCCESS;
  constructor(public payload: any) { }
}

export const DELETE_COLLECTION = '[COLLECTIONS LIST] Delete Collection';
export class DeleteCollectionAction implements UnsafeAction {
  readonly type = DELETE_COLLECTION;
  constructor(public payload: number) { }
}

export const DELETE_COLLECTION_SUCCESS = '[COLLECTIONS LIST] Delete Collection Success';
export class DeleteCollectionSuccessAction implements UnsafeAction {
  readonly type = DELETE_COLLECTION_SUCCESS;
  constructor(public payload: number) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[COLLECTIONS LIST] Set page view options';
export class SetPageViewOptions implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const COLLECTION_ACTION_FAILED = '[COLLECTIONS LIST] Generic failure action';
export class CollectionFailedAction implements UnsafeAction {
  readonly type = COLLECTION_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const COPY_COLLECTION_URL = '[COLLECTIONS LIST]  Copy collection url';
export class CopyCollectionUrlAction implements UnsafeAction {
  readonly type = COPY_COLLECTION_URL;
  constructor(public payload: any) { }
}

export const COPY_COLLECTION_URL_SUCCESS = '[COLLECTIONS LIST]  Copy collection url success';
export class CopyCollectionUrlSuccessAction implements UnsafeAction {
  readonly type = COPY_COLLECTION_URL_SUCCESS;
  constructor() { }
}
