import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { get } from 'lodash-es';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, startWith, tap } from 'rxjs';
import { AppState } from '../../../core/store/app-reducer';
import { getActiveWorkflowForGalleries } from '../../../core/store/galleries-workflow/galleries-workflow.reducer';
import { getActiveWorkflow } from '../../../core/store/workflows/workflow.reducer';
import { BidiService } from '../../../core/i18n/bidi.service';
import { getContentLocalesState } from '../../../core/store/content-locales/content-locales.reducer';
import { GetContentLocalesAction } from '../../../core/store/content-locales/content-locales.actions';
import { MixPanelService } from '../../../core/api/mixpanel/mixpanel.service';

const ALL_LOCALES_FILTER = { id: 0, label: $localize`All Locales`, active: true };

@Component({
  selector: 'gd-localizations-list',
  templateUrl: './localizations-list.component.html',
  styleUrls: ['./localizations-list.component.scss'],
})
export class LocalizationsListComponent implements OnChanges, OnInit {
  @Input() localizedVersions;
  @Input() activeLocaleId: number;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() class;
  @Input() usedLocales;
  // TODO separate domain and usage
  @Input() usage: 'ARTICLE_LIST' | 'ARTICLE_EDIT' | 'ARTICLE_CREATE' | 'GALLERY_CREATE' | 'GALLERY_EDIT' | 'GALLERY_LIST' | 'TAXONOMY_QUICK_CREATE' | 'LOCALE_DEFAULT_VALUE' = 'ARTICLE_EDIT';
  @Input() allowedContentLocales = [];

  @Output() localeChange = new EventEmitter();

  get selectOnly() {
    return ['ARTICLE_LIST', 'GALLERY_LIST', 'TAXONOMY_QUICK_CREATE', 'LOCALE_DEFAULT_VALUE'].includes(this.usage);
  }

  activeLocale = null;
  localeEntries = {};

  options$ = null;

  contentLocales$ = this.getContentLocalesList();
  localizedVersions$ = new BehaviorSubject([]);
  activeLocaleId$ = new BehaviorSubject(null);

  myControl = new UntypedFormControl(null);
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private store: Store<AppState>,
    private bidiService: BidiService,
    private mixPanelService: MixPanelService,
  ) {}

  ngOnInit() {

    this.options$ = combineLatest([
      this.contentLocales$.pipe(startWith([])),
      this.localizedVersions$,
      this.getActiveWorkflow(),
      this.activeLocaleId$,
    ]).pipe(
      debounceTime(100),
      map(([contentLocales, localizedVersions, workflow, ]) => {

        const localizationsMap = (localizedVersions || []).reduce((acc, localization) => {
          const status = get(workflow, 'statesGraph.' + localization.statusId , null);
          if(status) {
            localization.status = { ...status, letter: status.name[0] };
          }

          acc[localization.contentLocaleId] = localization;
          return acc;
        }, {});

        // on article list we need a value to stand in for all locales
        // add it to the start of the array if it is not already present
        const isListUsage = this.usage === 'ARTICLE_LIST' || this.usage === 'GALLERY_LIST';
        if (isListUsage && !contentLocales.find(cl => cl.id === 0)) {
          contentLocales.unshift(ALL_LOCALES_FILTER);
        }

        return contentLocales
          // display active locales, or locales with existing localizations or the explicitly passed active one
          .filter(locale => locale.active || !!localizationsMap[locale.id] || locale.id === this.activeLocaleId)
         // filter for allowed locales
          .filter(locale =>
            // if we dont have allowed locales, all the locales go through
            this.allowedContentLocales.length === 0 ||
            // if we have allowed locales passed from gallery embed modal, display only allowed content locales
            (this.allowedContentLocales.includes(locale.id) ||
            // add all locales label to allowed locales
            (locale.id === 0 && this.allowedContentLocales.length > 1))
            )
          .filter(locale => !this.usedLocales?.some(local => (local === locale.id) && locale.id !== this.activeLocaleId))
          .map((locale) => {
            const active = (+locale.id === +this.activeLocaleId) || this.allowedContentLocales.length === 1;

            return {
              ...locale,
              active,
              localizedEntity: localizationsMap[locale.id] || null,
            };
          });
      }),
      tap((res) => {
        // as the consequence of upstream changes, update the selected control
        this.activeLocale = res.find((l) => l.active);
        this.localeEntries = res.reduce((acc, locale) => {
          acc[locale.id] = locale;
          return acc;
        }, {});

        this.myControl.setValue(this.activeLocale);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const localizedVersions = changes.localizedVersions;
    if (localizedVersions) {
      this.localizedVersions$.next(localizedVersions.currentValue);
    }

    const activeLocaleId = changes.activeLocaleId;
    if (activeLocaleId) {
      this.activeLocaleId$.next(activeLocaleId.currentValue);
    }

    // in article create usage the dropdown is disabled
    const usage = changes.usage;
    if(usage) {
      if(usage.currentValue === 'ARTICLE_CREATE' || usage.currentValue === 'GALLERY_CREATE') {
        this.myControl.disable();
      }
      if(
        (usage.previousValue === 'ARTICLE_CREATE' && usage.currentValue !== 'ARTICLE_CREATE')
        || (usage.previousValue === 'GALLERY_CREATE' && usage.currentValue !== 'GALLERY_CREATE')
      ) {
        this.myControl.enable();
      }
    }
  }

  private getActiveWorkflow() {
    if(this.usage === 'GALLERY_CREATE' || this.usage === 'GALLERY_EDIT') {
      return this.store.select(getActiveWorkflowForGalleries);
    }

    return this.store.select(getActiveWorkflow);
  }

  public setLocaleById(localeId) {
    const locale = this.localeEntries[localeId];
    if (!locale) {
      return;
    }
    this.myControl.setValue(locale);
  }

  public getDefaultLocale() {
    return Object.values(this.localeEntries).find((locales: any) => locales.defaultLocale);
  }

  handleLocaleChange(event: MatSelectChange) {
    this.localeChange.emit(event.value);
    this.sendMixPanelEvent('ArticleLocaleCreate');
  }

  onInputClick() {
    this.sendMixPanelEvent('ArticleLocaleDropdown');
  }

  sendMixPanelEvent(event) {
    if (this.usage === 'ARTICLE_EDIT') {
      this.mixPanelService.trackEvent(event, {});
    }
  }

  getContentLocalesList() {
   return this.store.select(getContentLocalesState).pipe(
     tap((contentLocalesState) => {
       if (!(contentLocalesState.loaded || contentLocalesState.loading)) {
         this.store.dispatch(new GetContentLocalesAction());
       }
     }),
     filter((contentLocalesState) => {
       return contentLocalesState.loaded;
     }),
     map((contentLocalesState: any) => contentLocalesState.contentLocales)
   );
  }
}
