/**
 * Used in article and post content reducers. The function processes nodes
 * that are associated with corresponding referenced content entries
 */
export function prepareReferencedContent(oldReferencedContent, contentBlock) {
  const data: any = {};
  // possible attributes - will be set only if exist
  const attributesToSet = ['id', 'dataId', 'title', 'src', 'colour'];
  attributesToSet.filter(attr => !!contentBlock[attr]).forEach(attr => data[attr] = contentBlock[attr]);

  // in case of image node save original image meta
  if (contentBlock.sourceMeta) {
    Object.keys(contentBlock.sourceMeta).forEach(attr => data[attr] = contentBlock.sourceMeta[attr]);
    // for uploaded images
    data.isNotLoaded = contentBlock.sourceMeta.isNotLoaded || false;
  }

  // remove from content node those attributes who updates dynamically
  const attributesToRemove = ['src', 'title', 'sourceMeta', 'colour'];
  attributesToRemove.forEach(attr => delete contentBlock[attr]);

  return {
    ...oldReferencedContent,
    [data.id]: { ...oldReferencedContent[data.id], ...data }
  };
}
