<div
  class="gd-images-view-grid {{ gridOptions.viewAs }}"
  [class.non-interactive]="uploadQueueInProcess$ | async"
>
  <!-- Grid wrapper -->
  <div
    #grid
    class="gd-images-view-grid__wrapper"
    [sortablejs]="collection"
    [sortablejsOptions]="sortableOptions"
    [class.fixed-grid]="gridOptions.fixedGrid"
  >
    <!-- Grid item -->
    <gd-image-thumbnail
      *ngFor="let item of collection; trackBy: trackGridList"
      [item]="item"
      [loading]="loading | async"
      [config]="gridOptions"
      [thumbnailProps]="gridProperties"
      [draggable]="gridOptions.thumbnailDraggable"
      (dragstart)="dragstartHandle($event, item)"
      (dragend)="setIsDropzoneEnabled.emit(true)"
      [title]="getThumbnailTitle(item)"
      [isSelected]="checkIfImageIsSelected(item)"
      [hasDeletePermission]="hasDeletePermission"
      [uploadQueueActive]="processQue()"
      (click)="selectImage(item)"
      (preview)="previewImage($event)"
      (edit)="toggleEdit($event)"
      (delete)="deleteImage($event)"
      (selectImage)="selectImage($event)"
      (download)="toggleDownload($event)"
      (remove)="removeImageFromList($event)"
      (addMeta)="triggerAddImageMeta.next($event)"
      (editFile)="triggerEditFile.next($event)"
      (crop)="triggerCropImage.next($event)"
      [hasActionPermission]="hasActionPermission"
    >
    </gd-image-thumbnail>
  </div>
</div>
