import { filter, tap, switchMap, catchError, map, mergeMap, withLatestFrom, take, debounceTime } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { AppState } from '../app-reducer';
import { GalleriesService } from '../../api/galleries/galleries.service';
import {
  GET_GALLERIES,
  GetGalleriesCompleteAction,
  CREATE_GALLERY,
  CREATE_GALLERY_REVISION,
  GET_GALLERY,
  GetGalleryCompleteAction,
  CreateGalleryRevisionCompleteAction,
  DELETE_GALLERY,
  DeleteGalleryCompleteAction,
  GALLERIES_ACTION_FAILED,
  GalleriesFailedAction,
  PUBLISH_GALLERY_REVISION,
  GetGalleryAction,
  GetGalleryRevisionAction,
  GET_GALLERY_REVISION,
  GetGalleryRevisionCompleteAction,
  SET_GALLERY_ACTIVE_REVISION,
  GALLERIES_ACTION_NOT_FOUND,
  GalleriesNotFoundAction,
  GET_GALLERIES_COMPLETE,
  GetLocalizedVersions4Galleries,
  GET_LOCALIZED_VERSIONS_GALLERIES,
  GetLocalizedVersions4GalleriesSuccess,
  LOCALIZE_GALLERY,
  CREATE_GALLERY_REVISION_COMPLETE,
  GET_GALLERY_COMPLETE,
  LoadLocalizedVersionsAction,
  LocalizeGalleryAction,
  LocalizeGallerySuccessAction,
} from './galleries.actions';
import { ContentLocalesService } from '../../api/content-locales/content-locales.service';
import { NewLocalizationModes } from '../../../gpp-shared/content-locale-components/localize-mode-dialog/new-localization-modes';
import { getActiveWorkflowForGalleries } from '../galleries-workflow/galleries-workflow.reducer';
import { GalleryRevision } from './galleries.model';

@Injectable()
export class GalleriesEffects {

  getGalleries$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_GALLERIES)).pipe(
    withLatestFrom(this.store.select((state) => state.galleries.galleriesView)),
    mergeMap(([action, galleriesView]: [UnsafeAction, any]) =>
      this.galleriesService.getGalleries({ ...action.payload, pageView: galleriesView }).pipe(
        map((galleriesData) => new GetGalleriesCompleteAction(galleriesData)),
        catchError((e) => of(new GalleriesFailedAction({ error: e, action })))
      )
    )
  ));

  createGallery$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_GALLERY)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.galleriesService.createGallery(action.payload).pipe(
        switchMap((newGallery) =>
          this.galleriesService.createGalleryRevision(newGallery.id, action.payload).pipe(
            switchMap((galleryRevision) => {
              return this.galleriesService
                .getGalleryDetails(galleryRevision.revision.gallery_id)
                .pipe(
                  tap((response) => {
                    this.snackBar.open($localize`Gallery Saved`, $localize`Close`, { duration: 4000 });
                    this.router.navigate(['media', 'galleries', newGallery.id]);
                  }),
                  map((response) => new CreateGalleryRevisionCompleteAction(response)),
                  catchError((e) => of(new GalleriesFailedAction({ error: e, action })))
                );
            })
          )
        )
      )
    )
  ));

  $createGalleryRevision$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(CREATE_GALLERY_REVISION))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        return this.galleriesService
          .createGalleryRevision(action.payload.galleryID, action.payload.gallery)
          .pipe(
            switchMap((galleryRevision) => {
              return this.galleriesService
                .getGalleryDetails(galleryRevision.revision.gallery_id)
                .pipe(
                  tap(() =>
                    this.snackBar.open($localize`Gallery Revision Saved`, $localize`Close`, { duration: 4000 })
                  ),
                  map((response) => new CreateGalleryRevisionCompleteAction(response)),
                  catchError((e) => of(new GalleriesFailedAction({ error: e, action })))
                );
            })
          );
      })
    ));

  getLocalizedVersions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_GALLERY_REVISION_COMPLETE, GET_GALLERY_COMPLETE, LOCALIZE_GALLERY),
      debounceTime(500),
      filter((action: UnsafeAction)=> !!action.payload.id),
      mergeMap((action: UnsafeAction) => {
        // TODO handle localize gallery action
        return this.galleriesService.getLocalizedVersionsByGalleryId(action.payload?.id);
      }),
      map((localizedVersions: any) => new LoadLocalizedVersionsAction(localizedVersions)),
      catchError((e) => {
        console.error('Failed getting localized versions for galleries', e);
        return of(new GalleriesFailedAction({ error: e }));
      })
    )
  );

  localizeGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOCALIZE_GALLERY),
      mergeMap((action: LocalizeGalleryAction) =>
        combineLatest([
          this.galleriesService.getGalleryDetails(+action.payload.id, false),
          this.contentLocaleService.getContentLocaleById(+action.payload.contentLocaleId),
          this.store.select(getActiveWorkflowForGalleries)
        ])
        .pipe(
          filter(([gallery, contentLocale, workflow]: any) => !!gallery && !!contentLocale && !!workflow),
          map(([gallery, contentLocale, workflow]: any) => {
            const title = $localize`:Title for the new gallery localization:Localisation copy of ${gallery.latest.title}`;

            const initialWorkflowStatus = workflow.startingState;

            const latestRevision = gallery.revisions.find(revision => revision.id === gallery.latest.id);
            let newLatestRevision: GalleryRevision = {
              ...latestRevision,
              title,
              id: -1,
              created_at: null,
              updated_at: null,
              status: initialWorkflowStatus,
              status_id: initialWorkflowStatus.id,
            };

            // reset relevant data properties when creating new blank localization
            const localizationMode = action.payload.mode;
            if (localizationMode === NewLocalizationModes.CreateNew) {
              newLatestRevision = {
                ...newLatestRevision,
                taxonomies: [],
                metaData: [],
                primaryTaxonomyId: null,
                images: [],
                tags: [],
                promoImage: null,
              };
            }

            const newGallery = {
              ...gallery,
              id: 0,
              published_revision_id: null,
              published_revision_at: null,
              latest_revision_id: -1,
              latest: newLatestRevision,
              revisions: [newLatestRevision],
              contentLocaleCode: contentLocale.localeCode,
              contentLocaleId: contentLocale.id,
              localizationId: action.payload.localizationId,
            };

            // we will not be needing the owners part, it will be generated on the api
            delete newGallery.owners;

            return new LocalizeGallerySuccessAction(newGallery);
          })
        )

      )

    )
  )


  getGallery$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_GALLERY)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.galleriesService.getGalleryDetails(action.payload).pipe(
        map((gallery) => new GetGalleryCompleteAction(gallery)),
        catchError((e) => of(new GalleriesNotFoundAction({ error: e, action })))
      )
    )
  ));

  galleryRevisionChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(SET_GALLERY_ACTIVE_REVISION))
    .pipe(
      withLatestFrom(this.store.select((state) => state.galleries)),
      map(([action, galleriesState]: any) => {
        const activeGallery = galleriesState.galleries[galleriesState.activeGalleryId];
        const revisionToSet = activeGallery.revisions.find(
          (revision) => revision.id === action.payload
        );
        return {
          revision: revisionToSet,
          fetched: revisionToSet.fetched,
          galleryId: galleriesState.activeGalleryId,
        };
      }),
      map((payload) => {
        if (payload.fetched) {
          return new GetGalleryRevisionCompleteAction(payload.revision);
        } else {
          this.store.dispatch(new GetGalleryRevisionAction(payload));
        }
      })
    ), { dispatch: false });

  getGalleryRevision$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_GALLERY_REVISION)).pipe(
    mergeMap((action: UnsafeAction) => {
      const { payload } = action;
      return this.galleriesService
        .getGalleryRevision(payload.galleryId, payload.revision.id)
        .pipe(map((revision) => new GetGalleryRevisionCompleteAction(revision)));
    })
  ));

  deleteGallery$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_GALLERY)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.galleriesService.deleteGallery(action.payload).pipe(
        tap((response) => this.snackBar.open($localize`Gallery deleted`, $localize`Close`, { duration: 4000 })),
        map((response) => {
          return new DeleteGalleryCompleteAction(action.payload);
        }),
        catchError((e) => of(new GalleriesFailedAction({ error: e, action })))
      )
    )
  ));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GALLERIES_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}:actionType:`, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  publishRevision$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(PUBLISH_GALLERY_REVISION)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.galleriesService.publishGalleryRevision(action.payload).pipe(
        tap((response) => {
          const message = action.payload.status.name === 'Published'
            ? $localize`Gallery published`
            : $localize`Gallery unpublished`;
          this.snackBar.open(message, $localize`Close`, { duration: 4000 });
        }),
        map((response: any) => new GetGalleryAction(response.gallery.id)),
        catchError((e) => of(new GalleriesFailedAction({ error: e, action })))
      );
    })
  ));

  actionNotFound$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GALLERIES_ACTION_NOT_FOUND)).pipe(
    tap((err: any) => {
      this.router.navigate(['media', 'galleries']);
      this.snackBar.open($localize`Gallery not found.`, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  loadLocalizedVersionsData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_GALLERIES_COMPLETE),
    map((action: GetGalleriesCompleteAction) => {
      const galleriesArray = Object.values(action.payload.galleries) as any;
      const galleryIds = galleriesArray.map((gallery) => gallery.id);
      return new GetLocalizedVersions4Galleries(galleryIds);
    })
  ))

  loadLocalizedVersionsData2$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_LOCALIZED_VERSIONS_GALLERIES),
    mergeMap((action: GetLocalizedVersions4Galleries) => {
      if(action.galleryIds.length === 0) {
        return of(new GetLocalizedVersions4GalleriesSuccess([]));
      }
      return this.galleriesService
        .getLocalizedVersionsForGalleries(action.galleryIds)
        .pipe(
          map(localizedVersions => (new GetLocalizedVersions4GalleriesSuccess(localizedVersions))),
          catchError((e) => of(new GalleriesFailedAction({ error: e, action })))
        )
    })
  ))

  constructor(
    private actions$: Actions,
    private galleriesService: GalleriesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private contentLocaleService: ContentLocalesService,
    private store: Store<AppState>
  ) {}
}
