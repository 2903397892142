import { Injectable } from '@angular/core';
import { SidenavItem, SidenavItemOptions } from './sidenav-item/sidenav-item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import each from 'lodash-es/each';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { AppState } from '../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { Permissions } from '../../core/store/auth/permissions';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { i18nSidenavLabels } from './i18n-sidenav-labels';

@Injectable()
export class SidenavService {

  private _itemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _items: SidenavItem[] = [];
  items$: Observable<SidenavItem[]> = this._itemsSubject.asObservable();

  private _currentlyOpenSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _currentlyOpen: SidenavItem[] = [];
  currentlyOpen$: Observable<SidenavItem[]> = this._currentlyOpenSubject.asObservable();

  private _currentlyActiveSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _currentlyActive: SidenavItem[] = [];
  currentlyActive$: Observable<SidenavItem[]> = this._currentlyActiveSubject.asObservable();

  isIconSidenav: boolean;

  constructor(
    private snackbar: MatSnackBar,
    private permissionService: PermissionService,
    private router: Router,
    private store: Store<AppState>
  ) {

    this.permissionService.getUserPermision()
      .pipe(debounceTime(10))
      .subscribe(() => {
        this.makeSidenavItems();
        this.nextCurrentlyOpenByRoute(router.url);
      });
  }

  makeSidenavItems() {
    if (this._items.length !== 0) {
      this._items = [];
      this._itemsSubject.next(this._items);
    }
    const menu = this;

    const dashboard = menu.addItem(i18nSidenavLabels.Dashboard, 'fa-money-check', '/dashboard', 1);
    const articles = this.permissionService.hasPermission(Permissions.GC_ARTICLE_READ)
      && menu.addItem(i18nSidenavLabels.Articles, 'fa-quote-right', '/articles', 1);
    const collections = this.permissionService.hasPermission(Permissions.GC_COLLECTION_READ)
      && menu.addItem(i18nSidenavLabels.Collections, 'glideCustomIcon_collections', '/collections', 1);
    const liveReporting = this.permissionService.hasPermission(Permissions.GC_LIVE_REPORT_READ)
      && menu.addItem(i18nSidenavLabels.LiveReporting, 'glideCustomIcon_live-report', '/live-reporting', 1);

    const hasAnyMediaPermissions = this.permissionService.hasAnyMediaPermissions();

    if (hasAnyMediaPermissions) {
      const media = menu.addItem(i18nSidenavLabels.Media, 'fa-photo-video', '/media', 1);
      const mediaImages = this.permissionService.hasPermission(Permissions.GM_IMAGE_READ)
        && menu.addSubItem(media, i18nSidenavLabels.Images, 'fa-image', '/media/images', 1, { exactRouteMatch: false });
      const mediaGalleries = this.permissionService.hasPermission(Permissions.GM_GALLERY_READ)
        && menu.addSubItem(media, i18nSidenavLabels.Galleries, 'glideCustomIcon_galleries', '/media/galleries', 2, { exactRouteMatch: false });
      const mediaFiles = this.permissionService.hasPermission(Permissions.GM_FILE_READ) &&
        menu.addSubItem(media, i18nSidenavLabels.Files, 'fa-copy', '/media/files/list', 3, { exactRouteMatch: false });
      const mediaSettings = this.permissionService.hasPermission(Permissions.GM_CONFIGURE_READ)
        && menu.addSubItem(media, i18nSidenavLabels.Configure, 'glideCustomIcon_media-settings', '/media/media-config', 4, { exactRouteMatch: false });
    }

    const hasAnyVerifyPermissions = this.permissionService.hasAnyVerifyPermissions();
    if (hasAnyVerifyPermissions) {
      const i18nVerify = $localize`Verify`;
      const verify = menu.addItem(i18nVerify, 'glideCustomIcon_verify', '/verify', 1);

      const i18nAccessBundles = $localize`Access Bundles`;
      const verifyAccessBundles = this.permissionService.hasPermission(Permissions.GV_ACCESS_BUNDLE_READ)
        && menu.addSubItem(verify, i18nAccessBundles, 'glideCustomIcon_accessBundles', '/verify/access-bundles', 1, { exactRouteMatch: false });

      const verifySettings = this.permissionService.hasPermission(Permissions.GV_CONFIGURE_READ)
        && menu.addSubItem(verify, i18nSidenavLabels.Configure, 'glideCustomIcon_verifySettings', '/verify/verify-config', 2, { exactRouteMatch: false });
    }

    const taxonomies = this.permissionService.hasPermission(Permissions.GC_TAXONOMY_READ)
      && menu.addItem(i18nSidenavLabels.Taxonomies, 'glideCustomIcon_taxonomies', '/taxonomies', 1);

    const hasAnySiteBuilderPermissions = this.permissionService.hasAnySiteBuilderPermissions();
    if (hasAnySiteBuilderPermissions) {
      const siteBuilder = menu.addItem(i18nSidenavLabels.SiteBuilder, 'fa-wrench', '/site-builder', 1);
      const menus = this.permissionService.hasPermission(Permissions.GC_MENU_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.Menus, 'fa-bars', '/widgets/menus', 1, { exactRouteMatch: false });
      const pages = this.permissionService.hasPermission(Permissions.GC_PAGE_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.Pages, 'fa-window', '/site-builder/pages', 2, { exactRouteMatch: false });
      const templates = this.permissionService.hasPermission(Permissions.GC_TEMPLATE_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.Templates, 'fa-object-group', '/site-builder/templates', 3, { exactRouteMatch: false });
      const routes = this.permissionService.hasAnyRoutingPermissions()
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.Routing, 'fa-random', '/site-builder/page-routes', 4);
      const pageRoutes = this.permissionService.hasPermission(Permissions.GC_ROUTE_READ)
        && menu.addSubItem(routes, i18nSidenavLabels.PageRoutes, 'glideCustomIcon_page-routes', '/site-builder/page-routes', 0, { exactRouteMatch: false });
      const redirects = this.permissionService.hasPermission(Permissions.GC_REDIRECT_READ)
        && menu.addSubItem(routes, i18nSidenavLabels.Redirects, 'fa-exchange', '/site-builder/redirects', 0, { exactRouteMatch: false });
      const systemWidgets = this.permissionService.hasPermission(Permissions.GC_SYSTEM_WIDGET_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.SystemWidgets, 'glideCustomIcon_system-widgets', '/site-builder/system-widgets', 5, { exactRouteMatch: false });
      const thirdPartyWidgets = this.permissionService.hasPermission(Permissions.GC_3RD_PARTY_WIDGET_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.ThirdPartyWidgets, 'glideCustomIcon_third-party-widgets', '/site-builder/third-party-widgets', 6, { exactRouteMatch: false });
      const htmlSnippets = this.permissionService.hasPermission(Permissions.GC_HTML_SNIPPET_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.HTMLSnippets, 'fa-code', '/widgets/html-snippets', 7, { exactRouteMatch: false });
      const articleTypes = this.permissionService.hasPermission(Permissions.GC_ARTICLE_TYPE_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.ArticleTypes, 'glideCustomIcon_article-configuration', '/site-builder/article-types', 8, { exactRouteMatch: false });
      const taxonomyConfiguration = this.permissionService.hasPermission(Permissions.GC_TAXONOMY_CONFIGURATION_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.TaxonomyConfiguration, 'glideCustomIcon_taxonomy-configuration', '/site-builder/taxonomy-configuration', 9, { exactRouteMatch: false });
      const contentPanels = this.permissionService.hasPermission(Permissions.GC_CONTENT_PANEL_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.ContentPanels, 'glideCustomIcon_content-panels-configuration', '/site-builder/content-panels', 10, { exactRouteMatch: false });
      const collectionTypes = this.permissionService.hasPermission(Permissions.GC_COLLECTION_TYPE_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.CollectionTypes, 'glideCustomIcon_collection-configuration', '/site-builder/collection-types', 11, { exactRouteMatch: false });
      const liveReportTypes = this.permissionService.hasPermission(Permissions.GC_LIVE_REPORT_TYPE_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.LiveReportTypes, 'glideCustomIcon_live-report-types', '/site-builder/live-report-types', 12, { exactRouteMatch: false });
      const contentTags = this.permissionService.hasPermission(Permissions.GC_CONTENT_TAG_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.ContentTags, 'glideCustomIcon_content-tags-confguration', '/site-builder/content-tags', 13, { exactRouteMatch: false });
      const customFieldGroups = this.permissionService.hasPermission(Permissions.GC_FIELD_GROUP_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.CustomFieldGroups, 'fa-list-alt', '/site-builder/custom-field-groups', 14, { exactRouteMatch: false });
      const contentLocales = this.permissionService.hasPermission(Permissions.GC_CONTENT_LOCALE_READ)
        && menu.addSubItem(siteBuilder, i18nSidenavLabels.ContentLocales, 'fa-globe', '/site-builder/content-locales', 15, { exactRouteMatch: false });
    }
    // const hasWidgetTypePermissions = this.permissionService.hasAnyWidgetTypePermissions();
    // if (hasWidgetTypePermissions) {
    //   const widget = menu.addItem('Widgets', 'widgets', '/site-builder/widget', 1);

    //   /* WIDGETTYPE Permissions are removed from project. Adverts and Advert groups sections will need new permissions.

    //      const adverts = this.permissionService.hasPermission(Permissions.GC_WIDGETTYPE_READ) &&
    //      menu.addSubItem(widget, 'Adverts', '/widgets/adverts', 1, { exactRouteMatch: false });

    //    const advertsGroup = this.permissionService.hasPermission(Permissions.GC_WIDGETTYPE_READ) &&
    //      menu.addSubItem(widget, 'Advert groups', '/widgets/advert-groups', 1); */

    //   const menus = this.permissionService.hasPermission(Permissions.GC_MENU_READ)
    //     && menu.addSubItem(widget, 'Menus', '/widgets/menus', 1, { exactRouteMatch: false });

    //   const htmlSnippets = this.permissionService.hasPermission(Permissions.GC_HTML_SNIPPET_READ)
    //     && menu.addSubItem(widget, 'HTML Snippets', '/widgets/html-snippets', 1, { exactRouteMatch: false });

    // }

    const hasAnyUserPermissions = this.permissionService.hasAnyUserPermissions();
    if (hasAnyUserPermissions) {
      const glideUsers = menu.addItem(i18nSidenavLabels.Users, 'fa-user-friends', '/glide-users', 1);
      const users = this.permissionService.hasPermission(Permissions.GU_USER_READ)
        && menu.addSubItem(glideUsers, i18nSidenavLabels.Users, 'fa-users', '/glide-users/users', 1, { exactRouteMatch: false });
      const authors = this.permissionService.hasPermission(Permissions.GC_AUTHOR_READ)
        //&& menu.addSubItem(glideUsers, 'contacts', '/authors', 2);
        && menu.addSubItem(glideUsers, i18nSidenavLabels.Authors, 'glideCustomIcon_authors', '/authors', 2, { exactRouteMatch: false });
      const roles = this.permissionService.hasPermission(Permissions.GU_ROLE_READ)
        && menu.addSubItem(glideUsers, i18nSidenavLabels.Roles, 'fa-user-tie', '/glide-users/roles', 3, { exactRouteMatch: false });
      const ssoSettings = this.permissionService.hasPermission(Permissions.GU_SSO_READ)
        && menu.addSubItem(glideUsers, i18nSidenavLabels.SSO, 'fa-key', '/glide-users/sso', 4, { exactRouteMatch: false });
      const accounts = this.permissionService.hasPermission(Permissions.GU_ACCOUNT_READ)
        && menu.addSubItem(glideUsers, i18nSidenavLabels.Accounts, 'glideCustomIcon_accounts', '/glide-users/accounts', 5, { exactRouteMatch: false });
    }

    const hasAnyTransmitPermissions = this.permissionService.hasAnyTransmitPermissions();
    if (hasAnyTransmitPermissions) {
      const transmit = menu.addItem(i18nSidenavLabels.Transmit, 'glideCustomIcon_transmit', '/transmit', 1);
      const transmitSubscribers = this.permissionService.hasPermission(Permissions.GT_SUBSCRIBER_READ)
        && menu.addSubItem(transmit, i18nSidenavLabels.Subscribers, 'glideCustomIcon_event-subscribers', '/transmit/subscribers', 1, { exactRouteMatch: false });
    }

     //SN killSwitch const hasAnyNotificationPermissions = this.permissionService.hasAnyNotificationPermissions();
     //SN killSwitch if(hasAnyNotificationPermissions){
     //SN killSwitch   const notifications = menu.addItem( i18nSidenavLabels.Notifications, 'fa-bell', '/notifications', 1);
     //SN killSwitch   const systemNotifications = this.permissionService.hasPermission(Permissions.GC_SYSTEM_NOTIFICATION_CREATE)
     //SN killSwitch   && menu.addSubItem(notifications, i18nSidenavLabels.SystemNotifications, 'fa-bell-exclamation', '/notifications/system-notifications', 1, { exactRouteMatch: false });
     //SN killSwitch }


    const settings = menu.addItem(i18nSidenavLabels.Settings, 'fa-cog', '/settings', 1);
  }

  addItem(name: string, icon: string, route: any, position: number, badge?: string, badgeColor?: string, customClass?: string) {
    const item = new SidenavItem({
      name: name,
      icon: icon,
      isCustomSvgIcon: icon.startsWith('glideCustomIcon_') ? true : false,
      route: route,
      subItems: [],
      position: position || 99,
      badge: badge || null,
      badgeColor: badgeColor || null,
      customClass: customClass || null
    });

    this._items.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }


  addSubItem(parent: SidenavItem, name: string, icon: string, route: any, position: number, options: SidenavItemOptions = { exactRouteMatch: true }) {
    // use route pattern by preference, but if no routePatter is supplied and exact match is off
    // then match route as base pathe, so route and route/:id will both register as route matches for the item
    const routePattern = options.routePattern || (!options.exactRouteMatch && (route + '.*')) || null;
    const item = new SidenavItem({
      name: name,
      icon: icon,
      isCustomSvgIcon: icon.startsWith('glideCustomIcon_') ? true : false,
      route: route,
      routePattern: routePattern,
      parent: parent,
      subItems: [],
      position: position || 99
    });

    parent.subItems.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }

  removeItem(item: SidenavItem) {
    const index = this._items.indexOf(item);
    if (index > -1) {
      this._items.splice(index, 1);
    }

    this._itemsSubject.next(this._items);
  }

  isOpen(item: SidenavItem) {
    return (this._currentlyOpen.indexOf(item) !== -1);
  }

  toggleCurrentlyOpen(item: SidenavItem) {
    let currentlyOpen = this._currentlyOpen;

    if (this.isOpen(item)) {
      if (currentlyOpen.length > 1) {
        currentlyOpen.length = this._currentlyOpen.indexOf(item);
      } else {
        currentlyOpen = [];
      }
    } else {
      currentlyOpen = this.getAllParents(item);
    }

    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  getAllParents(item: SidenavItem, currentlyOpen: SidenavItem[] = []) {
    currentlyOpen.unshift(item);

    if (item.hasParent()) {
      return this.getAllParents(item.parent, currentlyOpen);
    } else {
      return currentlyOpen;
    }
  }

  nextCurrentlyOpen(currentlyOpen: SidenavItem[]) {
    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
    this._currentlyActive = currentlyOpen;
    this._currentlyActiveSubject.next(currentlyOpen);
  }

  nextCurrentlyOpenByRoute(route: string) {
    let currentlyOpen = [];
    if (route.startsWith('/media/files')) {
      route = '/media/files/list'
    }
    const item = this.findByRouteRecursive(route, this._items);

    if (item && item.hasParent()) {
      currentlyOpen = this.getAllParents(item);
    } else if (item) {
      currentlyOpen = [item];
    }
    this.nextCurrentlyOpen(currentlyOpen);
  }

  findByRouteRecursive(route: string, collection: SidenavItem[]) {
    let result = null;
    for (const sideNavItem of collection) {
      const patternMatches = sideNavItem.routePattern && route.match(sideNavItem.routePattern);
      if (patternMatches || sideNavItem.route === route) {
        result = sideNavItem;
        break;
      }
    }

    if (!result) {
      each(collection, (item) => {
        if (item.hasSubItems()) {
          const found = this.findByRouteRecursive(route, item.subItems);

          if (found) {
            result = found;
            return false;
          }
        }
      });
    }

    return result;
  }

  get currentlyOpen() {
    return this._currentlyOpen;
  }

}
