import { mergeMap, tap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  GET_CONTENT_TAGS,
  GetContentTagsAction,
  GetContentTagsSuccessAction,
  ContentTagFailedAction,
  CREATE_CONTENT_TAG,
  CreateContentTagAction,
  CreateContentTagSuccessAction,
  UPDATE_CONTENT_TAG,
  UpdateContentTagAction,
  UpdateContentTagSuccessAction,
  DELETE_CONTENT_TAG,
  DeleteContentTagAction,
  CONTENT_TAG_ACTION_FAILED,
  DeleteContentTagSuccessAction,
} from './content-tags.actions';
import { ContentTagsService } from '../../api/content-tags/content-tags.service';

@Injectable()
export class ContentTagsEffects {
  constructor(
    private actions$: Actions,
    public snackBar: MatSnackBar,
    public router: Router,
    public contentTagService: ContentTagsService
  ) {}


  loadContentTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_CONTENT_TAGS),
    mergeMap((action: GetContentTagsAction) =>
      this.contentTagService.getContentTags(action.payload).pipe(
        map((contentTags) => new GetContentTagsSuccessAction(contentTags)),
        catchError((e) => of(new ContentTagFailedAction({ error: e })))
      )
    )
  ));


  createContentTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_CONTENT_TAG),
    mergeMap((action: CreateContentTagAction) =>
      this.contentTagService.createContentTags(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Content tag saved`, $localize`Close`, { duration: 2000 });
          this.router.navigate(['site-builder/content-tags']);
        }),
        map((contentTag) => new CreateContentTagSuccessAction(contentTag)),
        catchError((e) => of(new ContentTagFailedAction({ error: e })))
      )
    )
  ));


  updateContentTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_CONTENT_TAG),
    mergeMap((action: UpdateContentTagAction) =>
      this.contentTagService.updateContentTags(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Content tag saved`, $localize`Close`, { duration: 2000 });
          this.router.navigate(['site-builder/content-tags']);
        }),
        map((contentTag) => new UpdateContentTagSuccessAction(contentTag)),
        catchError((e) => of(new ContentTagFailedAction({ error: e })))
      )
    )
  ));


  deleteContentTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_CONTENT_TAG),
    mergeMap((action: DeleteContentTagAction) =>
      this.contentTagService.deleteContentTags(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Content tag deleted`, $localize`Close`, { duration: 2000 })),
        map((contentTag) => new DeleteContentTagSuccessAction(action.payload)),
        catchError((e) => of(new ContentTagFailedAction({ error: e })))
      )
    )
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CONTENT_TAG_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });
}
