import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { Menu } from './menus.model';
import {
  GET_MENUS, CREATE_MENU, DELETE_MENU, GET_MENUS_SUCCESS,
  CREATE_MENU_SUCCESS, DELETE_MENU_SUCCESS, GET_ACTIVE_MENU,
  GET_ACTIVE_MENU_SUCCESS, UPDATE_ACTIVE_MENU, UPDATE_ACTIVE_MENU_SUCCESS,
  SET_PAGE_VIEW_OPTIONS, ADD_MENU_ITEM, UPDATE_MENU_ITEM, DELETE_MENU_ITEM, REORDER_MENU_ITEMS,
  CHANGE_MENU_ITEM_PUBLISHED_STATE, SET_MENUS_FILTER
} from './menus.actions';
import { activeMenuReducer } from './active-menu.reducer';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import { get } from 'lodash-es';

export interface MenusState {
  loaded: boolean;
  loading: boolean;
  error: string;
  menus: Menu[];
  activeMenu?: Menu;
  menusView?: any;
  filter?: string;
}

const initialState: MenusState = {
  loaded: false,
  loading: false,
  error: null,
  menus: [],
  activeMenu: null,
  menusView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  filter: ''
};

export function menusReducer(state: MenusState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_MENUS: {
      return {
        ...state,
        loading: true,
        loaded: false,
        menusView: {
          ...state.menusView,
          currentPage: 0
        },
        filter: ''
      };
    }

    case GET_MENUS_SUCCESS: {
      const menus = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        menus,
        activeMenu: null,
        menusView: {
          ...state.menusView,
          total: menus.length
        }
      };
    }

    case CREATE_MENU:
    case DELETE_MENU:
    case GET_ACTIVE_MENU:
    case UPDATE_ACTIVE_MENU: {
      return { ...state, loading: true };
    }

    case CREATE_MENU_SUCCESS: {
      const menu = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        menus: [...state.menus, menu],
        activeMenu: menu
      };
    }

    case DELETE_MENU_SUCCESS: {
      const deletedId = action.payload;
      const menus = state.menus.filter(menu => menu.id !== deletedId);
      return {
        ...state,
        loading: false,
        error: null,
        menus,
        menusView: {
          ...state.menusView,
          total: menus.length
        }
      };
    }

    case SET_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        menusView: {
          ...state.menusView,
          currentPage: pageIndex,
          pageSize: pageSize || state.menusView.pageSize
        }
      };
    }

    case SET_MENUS_FILTER: {
      const filter = typeof action.payload === 'string' ? action.payload.trim().toLowerCase() : '';
      const total = state.menus.filter(item => item.name.toLowerCase().includes(filter)).length;
      return {
        ...state,
        menusView: {
          ...state.menusView,
          currentPage: 0,
          total
        },
        filter
      };
    }

    case GET_ACTIVE_MENU_SUCCESS:
    case UPDATE_ACTIVE_MENU_SUCCESS:
    case ADD_MENU_ITEM:
    case UPDATE_MENU_ITEM:
    case DELETE_MENU_ITEM:
    case CHANGE_MENU_ITEM_PUBLISHED_STATE:
    case REORDER_MENU_ITEMS: {
      const activeMenu = activeMenuReducer(state.activeMenu, action);
      return {
        ...state,
        loading: false,
        error: null,
        activeMenu
      };
    }

    default: {
      return state;
    }
  }

}

export const getMenusState = (state) => state.menus;

export const getMenusLoading = createSelector(getMenusState, state => state.loading);

export const getMenusLoaded = createSelector(getMenusState, state => state.loaded);

export const getActiveMenu = createSelector(getMenusState, state => state.activeMenu);

export const getMenusPageView = createSelector(getMenusState, state => state.menusView);

export const getMenusList = createSelector(getMenusState, state => state.menus);

export const getActiveMenuItems = createSelector(getActiveMenu, activeMenu => {
  if (!activeMenu) {
    return {};
  }
  return activeMenu.menuItems || {};
});

// helper selector function for filtering and pagination
export const getFilteredMenusList = (applyPagination = true) => createSelector(getMenusState, state => {
  const filteredMenus = state.menus.filter(item => item.name.toLowerCase().includes(state.filter));
  if (filteredMenus.length === 0) {
    return [];
  }
  if (!applyPagination) {
    return filteredMenus;
  }
  const startIndex = state.menusView.currentPage * state.menusView.pageSize;
  const endIndex = startIndex + state.menusView.pageSize;
  return filteredMenus.slice(startIndex, endIndex);
});

export const getMenuItem = (id: number) => createSelector(getMenusState, (state) => {
  if (!id) {
    return null;
  }
  return { ...state.menus.find(menu => menu.id === id) };
});

export const getParentMenuItems = (parentMenuItemId: string) => createSelector(getActiveMenuItems, (items) => {
  const parentElementPath = findParentElements(items, parentMenuItemId, []);
  return parentElementPath;
});

export const getChildMenuItems = (menuItemId: string) => createSelector(getActiveMenuItems, (items) => {
  return findChildElements(items, menuItemId);
});


export const getActiveMenuItemsArray = createSelector(getActiveMenuItems, items => {
  return Object.keys(items).map(id => items[id]);
});

export const getActiveMenuItemsTreeView = createSelector(getActiveMenuItems, items => {
  return Object.keys(items)
    .filter(itemId => items[itemId].parentMenuItemId === null)
    .map(itemId => formatMenuItem(items, itemId))
    .sort((x, y) => x.position - y.position);
});

function formatMenuItem(items, itemId) {
  // for new menu items use menuItemId as temporary id
  // temporary id will be deleted on save menu action
  const item = items[itemId];

  return {
    id: item.id || item.menuItemId,
    label: item.label,
    menuId: item.menuId,
    menuItemId: item.menuItemId,
    parentMenuItemId: item.parentMenuItemId,
    taxonomyId: item.taxonomyId,
    pageId: item.pageId,
    url: item.url,
    position: item.position,
    published: checkIfMenuItemIsPublished(items, item),
    children: item.children
      .map(childId => formatMenuItem(items, childId))
      .sort((x, y) => x.position - y.position),
    colour: item.colour || '#ddd',
    image: item.image || {},
    isPublishable: checkIfMenuItemIsPublishable(items, item),
    isUnpublishable: !item.children.map(childId => items[childId].published).find(isChildPublish => isChildPublish === true),
	  description: item.description || '',
  };
}

function checkIfMenuItemIsPublishable(items, item) {
  if(!item.parentMenuItemId) {
    // when we don't have parent element id then menu item is publishable
    return true;
  }

  return get(items[item.parentMenuItemId], 'published', false);
}


function checkIfMenuItemIsPublished(items, item) {
  if(!item.parentMenuItemId) {
    // if parent element
    return item.published;
  }

  const isParentElementPublished = get(items[item.parentMenuItemId], 'published', false);
  if(isParentElementPublished) {
    return item.published;
  }

  items[item.menuItemId].published = false;
  return false;
}

function findParentElements(items, parentMenuItemId, parentElementPath) {
  if(!parentMenuItemId) {
    return parentElementPath;
  }

  const parentElement = items[parentMenuItemId];
  if(parentElement) {
    parentElementPath.push(parentElement);
    return findParentElements(items, parentElement.parentMenuItemId, parentElementPath)
  }

  return parentElementPath;
}

function findChildElements(items, menuItemId) {
  if(!menuItemId) {
    return [];
  }

  const menuItem = items[menuItemId];
  if(!menuItem) {
    return [];
  }

  return menuItem.children.map(childMenuItemId => {
    return items[childMenuItemId];
  });
}
