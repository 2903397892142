import { UnsafeAction } from '../unsafe-action.interface';

export const GET_REFERENCED_CONTENT = '[Referenced Content] Get Referenced Content Action';
export class GetReferencedContentAction implements UnsafeAction {
  readonly type = GET_REFERENCED_CONTENT;
  constructor(public payload: { ids: any[], type: string }) { }
}

export const GET_REFERENCED_CONTENT_SUCCESS = '[Referenced Content] Get Referenced Content Success Action';
export class GetReferencedContentSuccessAction implements UnsafeAction {
  readonly type = GET_REFERENCED_CONTENT_SUCCESS;
  constructor(public payload: any = {}) { }
}

export const CLEAR_REFERENCED_CONTENT_REPOSITORY = '[Referenced Content] Clear referenced content repository action';
export class ClearReferencedContentRepositoryAction implements UnsafeAction {
  readonly type = CLEAR_REFERENCED_CONTENT_REPOSITORY;
  constructor(public payload: any = {}) { }
}

export const REFERENCED_CONTENT_ACTION_FAILED = '[Referenced Content] Generic failure action';
export class ReferencedContentFailedAction implements UnsafeAction {
  readonly type = REFERENCED_CONTENT_ACTION_FAILED;
  constructor(public payload: any) { }
}
