<div class="gd-image-download {{ image.orientation }}"
     fxLayout="column"
     fxLayoutAlign="space-between"
     [dir]="dir$ | async">
  <!-- <h3>Choose sizes of image</h3> -->
  <div fxLayout="row"
       fxLayoutGap="24px"
       fxLayoutAlign="center">

    <div fxFlex="35%"
         fxLayoutAlign="center flex-start">
      <div *ngIf="imageSrc"
           class="gd-image-download__image {{ image.orientation }}"
           [ngStyle]="{'background-image': 'url(' + imageSrc + ')'}"
           [class.--icon]="isScaleDownRequired(image)">
      </div>
      <div *ngIf="!imageSrc"
           class="gd-image-download__image-placholder">
        <mat-spinner color="accent"
                     diameter="55">
        </mat-spinner>
      </div>

    </div>

    <div fxFlex="65%">
      <perfect-scrollbar class="gd-image-download__scroller"
                         #scrollbar>
        <div class="gd-image-download__image-renditions">
          <gd-renditions-view [renditions]="image.formats"></gd-renditions-view>
        </div>
      </perfect-scrollbar>
    </div>

  </div>

  <div class="gd-image-download__actions"
       fxLayout="row"
       fxLayoutAlign="flex-end"
       fxLayoutGap="12px">
    <button mat-button
            mat-raised-button
            mat-dialog-close
            i18n="Image download modal button"
            i18n>
      Cancel
    </button>
  </div>
</div>
