import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { UserPreferencesService } from '../api/user-preferences/user-preferences.service';

// this service should be used in pair with advanced-filters component
// it handles the toggling of columns and reordering aswell
// also handles the preferences bit so the components don't have to
@Injectable()
export class ColumnManager {
  public columns = [];
  public userPrefColKey = '';

  // note: two states are used as we want to show all the columns in the filters
  // but only need the active ones in the list
  private activeColumnsState = new BehaviorSubject([]);
  private columnsState = new BehaviorSubject([]);

  constructor(private userPreferencesService: UserPreferencesService) {}

  /**
   * method used in list component
   * @returns current column state
   */
  getActiveColumns(): Observable<any> {
    return this.activeColumnsState.asObservable().pipe(
      map((columns) => columns.filter(({ active }) => active)),
      map((columns) => columns.map(({ key }) => key)),
      map((columns) => [...columns, 'actions'])
    );
  }

  /**
   * used for sorting, as you can sort active and inactive columns
   * @returns full columns list
   */
  getColumns(): Observable<any> {
    return this.columnsState.asObservable().pipe(filter(columns => !isEmpty(columns)));
  }

  /**
   * method used in advanced filters
   * note: we don't need the 'actions' bit to show in advanced filters
   * @returns current column state
   */
  getFilteredColumns(): Observable<any> {
    return this.activeColumnsState.asObservable().pipe(
      map((columns) => columns.filter(({ active }) => active)),
      map((columns) => columns.filter(({ key }) => key !== 'actions')),
      map((columns) => columns.map(({ key }) => key))
    );
  }

  /**
   * method used for toggling and reordering columns from advanced filters
   * @param activeColumns
   */
  setActiveColumns(activeColumns) {
    this.activeColumnsState.next(activeColumns);
    this.userPreferencesService.setUserPreference(this.userPrefColKey, activeColumns);
  }

  /**
   * used for sorting
   * @param columns set full list
   */
  setColumns(columns) {
    this.columnsState.next(columns);
  }

  /**
   * Method used for initializing the column-manager service
   * @param columns firstRowColumns
   * @param userPrefColKey key used for saving / loading user preferences
   */
  initColumnManager(columns, userPrefColKey) {
    this.userPrefColKey = userPrefColKey;
    this.columns = columns.map((column) => ({
      ...column,
      active: column.hasOwnProperty('active') ? column.active : true,
    }));

    const activeColumns =
      this.userPreferencesService.getUserPreference(userPrefColKey) || this.columns;
    this.activeColumnsState.next(activeColumns);
    this.columnsState.next(activeColumns);
  }

  /**
   * method that transforms the column keys into columns
   * @param columnKeys
   * @returns
   */
  mapKeysIntoColumns(columnKeys) {
    return columnKeys
      .filter((key) => key !== 'actions')
      .map((key) => this.columns.find((column) => column.key === key));
  }

  /**
   * sets the default column preferences / order
   */
  setDefaultList() {
    this.userPreferencesService.setUserPreference(this.userPrefColKey, this.columns);
    this.activeColumnsState.next(this.columns);
    this.columnsState.next(this.columns);
  }
}
