import {debounceTime} from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlideContentActionBusService } from '../../../core/body-content/glide-content-actions-bus.service';
import { ScriptInjectorService } from '../../../core/miscellaneous/script-injector.service';
import { BidiService } from '../../../core/i18n/bidi.service';

declare const instgrm: any;

@Component({
  selector: 'gd-embed-instagram-dialog',
  templateUrl: './embed-instagram-dialog.component.html',
  styleUrls: ['./embed-instagram-dialog.component.scss']
})
export class EmbedInstagramDialogComponent implements OnInit, OnDestroy {

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    url: ['', Validators.required],
    src: [''],
  });

  blockId;
  loading = false;
  postLoaded = false;
  componentSub: Subscription = new Subscription();
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  get urlControl() {
    return this.embeddableForm.get('url');
  }

  get srcControl() {
    return this.embeddableForm.get('src');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedInstagramDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private actionBus: GlideContentActionBusService,
    private http: HttpClient,
    private scriptInjector: ScriptInjectorService,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.loadInstagramScript();
    const postContainer = document.getElementById('instagram-container');
    this.componentSub.add(
      this.urlControl.valueChanges.pipe(debounceTime(200)).subscribe(
        url => {
          postContainer.innerHTML = '';
          this.postLoaded = false;
          if (!url) {
            return this.urlControl.setErrors({ 'required': true });
          }
          const glideContentType = this.dialogInputData.glideContentType;
          this.actionBus.setContentLoading(glideContentType, true);
          this.getInstagramPostData(url).then((data: any) => {
            this.actionBus.setContentLoading(glideContentType, false);
            if (!data) {
              return this.urlControl.setErrors({ 'invalidUrl': true });
            }
            this.postLoaded = true;
            postContainer.innerHTML = data.html || '';
            this.loadInstagramScript();

            this.urlControl.setErrors(null);
            this.srcControl.setValue(data.thumbnail_url || null);
          });
        }
      )
    );

    this.embeddableForm.patchValue(this.dialogInputData);
    this.blockId = this.dialogInputData.blockId;
  }

  getInstagramPostData(postUrl: string) {
    const accessToken = `&access_token=${environment.instagramAppId}|${environment.instagramClientToken}`;
    const requestUrl = `https://graph.facebook.com/v9.0/instagram_oembed?url=${postUrl}${accessToken}`;
    return this.http.get(requestUrl).toPromise();
  }

  save() {
    const formModel = this.embeddableForm.value;
    const data = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel
    };
    this.dialogRef.close(data);
  }

  // run instagram SDK script to load post
  loadInstagramScript() {
    if (document.getElementById('instagram-sdk')) {
      instgrm.Embeds.process();
      return;
    }
    this.scriptInjector
      .injectScript('https://www.instagram.com/embed.js', { id: 'instagram-sdk' })
      .then(() => instgrm.Embeds.process())
      .catch(() => console.log('Error: Instagram software development kit failed to load'));
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }

}
