import { createSelector } from 'reselect';
import {
  PageTemplate, LayoutBlock, TemplateNode,
  PageTemplateRevision, PageWidget, NodeIdConst,
  TemplateRevisionDesignation,
  TemplateNodeType, TemplateTreeItem
} from './templates.model';
import { AppState } from '../app-reducer';
import { sortNodesByPosition, PageTemplatesMap } from './templates.reducer';
import * as regexEscape from 'escape-string-regexp';
import { flatten } from 'lodash-es';
import { sortByLabelAsc } from '../utility-functions';



export const getPageTemplates = (state: AppState) => state.templates.templates;
export const getActiveTemplateId = (state: AppState) => state.templates.activeTemplateId;
export const getActiveRevisionDesignation = (state: AppState) => state.templates.activeRevisionDesignation;
export const getActiveTemplate = (state: AppState) => state.templates.activeTemplate;
export const getTemplateNameFilter = (state: AppState) => state.templates.nameFilter;
export const getIsWorkingRevisionModified = (state: AppState) => state.templates.workingRevisionModified;
export const getTemplatesLoading = (state: AppState) => state.templates.loading;
export const getTemplatesLoaded = (state: AppState) => state.templates.loaded;

export const getActiveTemplateFormDetails = createSelector(
  getActiveTemplate,
  (activeTemplate: PageTemplate) => {
    if (activeTemplate) {
      return {
        label: activeTemplate.label,
        parentId: activeTemplate.parentId || null,
        rootComponent: activeTemplate.rootComponent || 'DEFAULT'
      };
    }
    return { label: '', parentId: null, rootComponent: 'DEFAULT' };
  }
);

export const getActiveRevisionOfActiveTemplate = createSelector(
  getActiveTemplate,
  getActiveRevisionDesignation,
  (activeTemplate: PageTemplate, activeRevisionDesegnation) => {
    if (!activeTemplate) {
      return null;
    }
    return activeRevisionDesegnation === TemplateRevisionDesignation.Working
      ? activeTemplate.workingRevision
      : activeTemplate.publishedRevision;
  }
);

export const getCanChangeParentTemplate = createSelector(
  getActiveRevisionDesignation,
  getActiveRevisionOfActiveTemplate,
  (activeRevisionDesegnation, activeRevision: PageTemplateRevision) => {
    const publishedRevisionActive = activeRevisionDesegnation === TemplateRevisionDesignation.Published;
    if (!activeRevision || publishedRevisionActive) {
      return false;
    }
    return activeRevision.blocks.length === 0
      && activeRevision.widgets.length === 0;
  }
);

export const getTemplateHasPlacedChildLayoutNode = createSelector(
  getActiveRevisionOfActiveTemplate,
  (activeRevision: PageTemplateRevision) => {
    const childLayoutBlockPresent = activeRevision && activeRevision.blocks
      .some((n: LayoutBlock) => n.childLayout);
    return childLayoutBlockPresent;
  }
);

export const getActiveTemplateRevisionsData = createSelector(
  getActiveTemplate,
  getActiveRevisionDesignation,
  (activeTemplate: PageTemplate, activeRevisionDesignation) => {
    if (!activeTemplate) {
      return {};
    }

    const isWorkingRevisionActive = activeRevisionDesignation === TemplateRevisionDesignation.Working;
    const revisions = [
      { id: TemplateRevisionDesignation.Working, label: 'Working revision', active: isWorkingRevisionActive }
    ];

    if (activeTemplate.publishedRevision) {
      revisions.push({ id: TemplateRevisionDesignation.Published, label: 'Published revision', active: !isWorkingRevisionActive });
    }

    const activeRevisionLabel = isWorkingRevisionActive
      ? 'Working revision'
      : 'Published revision';

    const activeRevisionId = isWorkingRevisionActive
      ? activeTemplate.workingRevision.id
      : activeTemplate.publishedRevision.id;

    return { activeRevisionLabel, activeRevisionId, activeRevisionDesignation, revisions };
  });

export const getPageTemplatesAsArray = createSelector(getPageTemplates, (templates) => {
  return templates && Object.values(templates) || [];
});

export const getPublishedTemplates = createSelector(
  getPageTemplatesAsArray,
  (templates: PageTemplate[]) => {
    return templates.filter((pt: PageTemplate) => !!pt.publishedRevision);
  });

// make a separate action for updating active template parent
export const getParentTemplatesOfActiveTemplate = createSelector(
  getPageTemplates,
  getActiveTemplate,
  (allTemplates, activeTemplate: PageTemplate) => {
    if (!activeTemplate || !allTemplates) {
      return [];
    }
    const parentTemplates: PageTemplate[] = [];
    let currentParent = allTemplates[activeTemplate.parentId];
    while (currentParent) {
      parentTemplates.push(currentParent);
      currentParent = allTemplates[currentParent.parentId];
    }
    return parentTemplates;
  }
);

export const allParentTemplatesPublished = createSelector(
  getParentTemplatesOfActiveTemplate,
  (parentTemplates: PageTemplate[]) => {
    return parentTemplates.every((t: PageTemplate) => !!t.publishedRevision);
  }
);

export const getParentTemplatesNodesTree = createSelector(
  getParentTemplatesOfActiveTemplate,
  (parentTemplates) => {
    // take published revisions if available on parent templates, working revisions if not
    const relevantParentRevisions = parentTemplates
      .map((t: PageTemplate) => t.publishedRevision || t.workingRevision);

    // all nodes -> denormalize
    const parentTemlateNodes = relevantParentRevisions
      .reduce((allNodes, revision: PageTemplateRevision) => {
        return { ...allNodes, ...getRevisionNodes(revision, false) };
      }, {});
    resolveChildren(parentTemlateNodes);
    const denormalizedParentNodesTree = Object.values(parentTemlateNodes)
      .filter((n: TemplateNode) => !n.parentBlockId)
      // will need to wrap this up in a sensible way for node builder
      .map((n: TemplateNode) => denormalizeNodeChildTemplateNodes(n, parentTemlateNodes));
    return denormalizedParentNodesTree;
  });

export const getActivetemplateDenormalizedNodesGroupedByParent = createSelector(
  getActiveTemplate,
  getActiveRevisionDesignation,
  (activeTemplate: PageTemplate, revisionDesignation) => {
    if (!activeTemplate) {
      return null;
    }

    let relevantActiveTemplateRevision = activeTemplate.workingRevision;
    if (revisionDesignation !== TemplateRevisionDesignation.Working && activeTemplate.publishedRevision) {
      relevantActiveTemplateRevision = activeTemplate.publishedRevision;
    }
    // all nodes -> denormalize
    const activeTemplateNodes = getRevisionNodes(relevantActiveTemplateRevision);
    resolveChildren(activeTemplateNodes);
    const denormalizedActiveTemplateNodesMap = Object.values(activeTemplateNodes)
      .filter((n: TemplateNode) => !n.parentBlockId || !activeTemplateNodes[n.parentBlockId])
      .map((n: TemplateNode) => denormalizeNodeChildTemplateNodes(n, activeTemplateNodes))
      .reduce((acc, n: TemplateNode) => {
        const parentBlockId = n.parentBlockId || NodeIdConst.Root;
        if (acc[parentBlockId]) {
          acc[parentBlockId].push(n);
        } else {
          acc[n.parentBlockId || NodeIdConst.Root] = [n];
        }
        return acc;
      }, {});

    return denormalizedActiveTemplateNodesMap;
  });

// This is the end product to be used on the UI
export const getActiveTemplateUIData = createSelector(
  getPageTemplates,
  getActiveTemplate,
  getActivetemplateDenormalizedNodesGroupedByParent,
  getParentTemplatesNodesTree,
  getActiveRevisionDesignation,
  (allTemplates, activeTemplate: PageTemplate, denormalizedActiveTemplateNodesMap: TemplateNode,
    denormalizedParentNodesTree, activeRevisionDesignation) => {
    if (!activeTemplate || !allTemplates) {
      return null;
    }
    const firstParent = allTemplates[activeTemplate.parentId];
    const layoutParentNode = relevantRevisionChildLayoutBlock(firstParent);
    // this is the id of the parent's childLayout block - if null, child template can only add new widgets
    const layoutParentNodeId = layoutParentNode && layoutParentNode.id || null;
    const canPlaceLayoutBlocks = !!(layoutParentNodeId || !firstParent);
    const rootNode = createSyntheticRootNode(denormalizedParentNodesTree, denormalizedActiveTemplateNodesMap);
    const updatesAvailableData = resolveLastUpdateData(activeTemplate);

    return {
      activeTemplate, canPlaceLayoutBlocks, layoutParentNodeId, rootNode,
      denormalizedActiveTemplateNodesMap, denormalizedParentNodesTree,
      activeRevisionDesignation, ...updatesAvailableData
    };

  }
);

function createSyntheticRootNode(parentTemplatesTree, activetemplateNodeGrouped = {}) {
  const rootNode: LayoutBlock = {
    id: NodeIdConst.Root,
    isSyntheticRoot: true,
    parentBlockId: '',
    childLayout: false,
    parentId: null,
    label: '',
    cssClass: '',
    baseLayoutCssClass: 'gc-row',
    position: 0,
    additionalItems: {},
    editable: false,
    type: TemplateNodeType.LayoutBlock,
    childBlocks: [],
    childWidgets: [],
    childrenIds: [],
  };

  // if the active template has no root nodes - the root nodes belong to the parent
  if (!activetemplateNodeGrouped[NodeIdConst.Root]) {
    const rootNodes: any[] = parentTemplatesTree;
    rootNode.childrenIds = rootNodes.map(n => n.id);
    rootNode.childWidgets = rootNodes
      .filter((n: TemplateNode) => n.type === 'WIDGET')
      .sort(sortNodesByPosition);
    rootNode.childBlocks = rootNodes
      .filter((n: TemplateNode) => n.type === 'CONTAINER')
      .sort(sortNodesByPosition);
  }

  return rootNode;
}

function resolveChildren(nodesMap: { [key: number]: TemplateNode }) {
  const nodes: TemplateNode[] = Object.values(nodesMap);
  for (const node of nodes) {
    const parent: TemplateNode = nodesMap[node.parentBlockId];
    if (!parent) {
      continue;
    }
    parent.childrenIds.push(node.id);
  }
  return nodesMap;
}

function getRevisionNodes(revision: PageTemplateRevision, editable = true) {
  const revisionNodes = {};
  for (const widget of revision.widgets) {
    revisionNodes[widget.id] = { ...widget, editable, type: 'WIDGET' };
  }
  for (const block of revision.blocks) {
    revisionNodes[block.id] = {
      ...block,
      editable,
      type: 'CONTAINER',
      childBlocks: [],
      childWidgets: [],
      childrenIds: []
    };
  }
  return revisionNodes;
}

function denormalizeNodeChildTemplateNodes(node: TemplateNode, nodesMap) {
  if (!node.childrenIds || node.childrenIds.length === 0) {
    return node;
  }

  node.childBlocks = node.childrenIds
    .map(nId => nodesMap[nId])
    .filter((cn: TemplateNode) => !!cn && cn.type === 'CONTAINER')
    .sort(sortNodesByPosition)
    .map(n => (denormalizeNodeChildTemplateNodes(n, nodesMap) as LayoutBlock));

  node.childWidgets = node.childrenIds
    .map(nId => nodesMap[nId])
    .filter((cn: TemplateNode) => !!cn && cn.type === 'WIDGET')
    .sort(sortNodesByPosition)
    .map(n => (denormalizeNodeChildTemplateNodes(n, nodesMap) as PageWidget));

  return node;
}

function relevantRevisionChildLayoutBlock(template: PageTemplate) {
  if (!template) {
    return null;
  }
  const relevantRevision = template.publishedRevision || template.workingRevision;
  return relevantRevision.blocks.find((block: LayoutBlock) => block.childLayout);
}


export const getActiveTemplateDescendantsFlat = createSelector(
  getActiveTemplateId,
  getPageTemplatesAsArray,
  (activeTemplateId, allTemplates: PageTemplate[]) => {
    return getTemplateDescendatnts(activeTemplateId, allTemplates);
  }
);

function getTemplateDescendatnts(templateId, allTemplates: PageTemplate[]) {
  const templateChildren = allTemplates
    .filter((t: PageTemplate) => t.parentId === templateId);
  if (templateChildren.length === 0) {
    return [];
  }
  const templateGrandchildren: PageTemplate[][] = templateChildren
    .map(t => getTemplateDescendatnts(t.id, allTemplates))
    .filter(descendantArray => descendantArray.length !== 0);
  const templateGrandchildrenFlat: PageTemplate[] = flatten(templateGrandchildren);
  return [...templateChildren, ...templateGrandchildrenFlat];
}

export const activeTemplateHasPublishedDescendants = createSelector(
  getActiveTemplateDescendantsFlat,
  (descendants: PageTemplate[]) => {
    return descendants.some((t: PageTemplate) => !!t.publishedRevision);
  }
);


// TREE VIEW
export const getTemplatesFilteredByName = createSelector(
  getPageTemplatesAsArray,
  getTemplateNameFilter,
  (templatesArray: PageTemplate[], nameFilter: string) => {
    if (!nameFilter) {
      return templatesArray;
    }
    const filterRegExp = new RegExp(regexEscape(nameFilter), 'i');
    return templatesArray
      .filter(t => filterRegExp.test(t.label));
  }
);

export const getTemplateSuggestionsByName = createSelector(
  getTemplatesFilteredByName,
  getTemplateNameFilter,
  (filteredTemplatesArray: PageTemplate[], nameFilter: string) => {
    if (!nameFilter) {
      return [];
    }
    const suggestionListMaxLength = 4;
    return filteredTemplatesArray
      .slice(0, suggestionListMaxLength)
      .map((t: PageTemplate) => t.label);
  }
);

export const getFilteredPageTemplatesTree = createSelector(
  getPageTemplates,
  getTemplatesFilteredByName,
  (templatesMap: PageTemplatesMap, filteredTemplatesArray: PageTemplate[]) => {
    if (filteredTemplatesArray.length === 0) {
      return [];
    }
    const filteredTempaltesMap = filteredTemplatesArray
      .reduce((acc, template: PageTemplate) => {
        acc[template.id] = template;
        let parent = templatesMap[template.parentId];
        while (parent) {
          acc[parent.id] = parent;
          parent = templatesMap[parent.parentId];
        }
        return acc;
      }, {});
    const filteredTemplatesWithParents: PageTemplate[] = Object.values(filteredTempaltesMap);

    const templatesAsNormalizedTreeItems = filteredTemplatesWithParents
      .reduce((acc, t: PageTemplate) => {
        acc[t.id] = templateToProtoTemplateTreeItem(t, templatesMap);
        return acc;
      }, {});

    const denormalizedTempalteItemsTree: TemplateTreeItem[] = Object.values(templatesAsNormalizedTreeItems)
      .filter((t: any) => !t.parentId)
      .map((rlt: any) => denormalizeNodeChildTemplates(rlt, templatesAsNormalizedTreeItems))
      .sort(sortByLabelAsc);

    return denormalizedTempalteItemsTree as TemplateTreeItem[];
  });

export const hasWidgetsInChildBlocksOfActiveTemplate = (node: TemplateNode) => createSelector(
  getActiveTemplate,
  activeTemplate => {
    if (!node.id || node.type !== 'CONTAINER') {
      return false;
    }
    const childContainerIds = getChildContainerIds(node);
    const widgetParentIds = activeTemplate.workingRevision.widgets.map(({ parentBlockId }) => parentBlockId);
    return childContainerIds.some(id => widgetParentIds.includes(id));
  });

export const hasNodeWidgetsInChildTemplates = (node: TemplateNode) => createSelector(
  getPageTemplatesAsArray,
  getActiveTemplateId,
  (templates, activeTemplateId) => {
    if (!node.id || node.type !== 'CONTAINER') {
      return false;
    }
    const childTemplates = getAllChildTemplates(templates, +activeTemplateId);
    const childContainerIds = getChildContainerIds(node);
    return childContainerIds.some(nodeId => checkIfNodeContainsWidgetsInChildTemplate(nodeId, childTemplates));
  });

function templateToProtoTemplateTreeItem(template: PageTemplate, templatesMap: PageTemplatesMap) {
  const children = Object.values(templatesMap)
    .filter((t: PageTemplate) => t.parentId === template.id)
    .map(t => t.id);
  const hasDescendants = children.length !== 0;
  const isPublished = !!template.publishedRevision;
  let deleteTooltip = '';
  if (hasDescendants) {
    deleteTooltip += 'Cannnot delete template that has descendants! ';
  }
  if (isPublished) {
    deleteTooltip += 'Cannnot delete published template!';
  }

  const { updatesAvailableForPublishedRevision, lastUpdateAt } = resolveLastUpdateData(template);

  return {
    lastUpdateAt,
    updatesAvailableForPublishedRevision,
    deleteTooltip,
    hasDescendants,
    isPublished,
    childrenIds: children,
    id: template.id,
    parentId: template.parentId,
    label: template.label
  };
}

function resolveLastUpdateData(template: PageTemplate) {
  const publishedRevisionUpdatedAt = template.publishedRevision && template.publishedRevision.updatedAt || 0;
  const updatesAvailableForPublishedRevision = template.publishedRevision
    && template.workingRevision.updatedAt > publishedRevisionUpdatedAt;
  const lastUpdateAt = updatesAvailableForPublishedRevision
    ? template.workingRevision.updatedAt
    : (publishedRevisionUpdatedAt || template.workingRevision.updatedAt);
  return { updatesAvailableForPublishedRevision, lastUpdateAt };
}

function denormalizeNodeChildTemplates(node: TemplateTreeItem, nodesMap) {
  if (!node.childrenIds || node.childrenIds.length === 0) {
    return node;
  }
  node.children = node.childrenIds
    .map(nId => nodesMap[nId])
    .filter(cn => !!cn)
    .map(n => denormalizeNodeChildTemplates(n, nodesMap))
    .sort(sortByLabelAsc);
  return node;
}

function getChildContainerIds(node, ids = []) {
  if (node.childBlocks.length === 0) {
    ids.push(node.id);
  } else {
    node.childBlocks.forEach(block => getChildContainerIds(block, ids));
  }
  return ids;
}

function checkIfNodeContainsWidgetsInChildTemplate(nodeId, templates) {
  return templates.some(template => {
    const publishedRevisionWidgets = template.publishedRevision ? template.publishedRevision.widgets : [];
    const widgets = [...template.workingRevision.widgets, ...publishedRevisionWidgets];
    return widgets.some(widget => widget.parentBlockId === nodeId);
  });
}

function getAllChildTemplates(templates, id, children = []) {
  const childTemplates = templates.filter(template => template.parentId === id);
  if (!childTemplates.length) {
    return [];
  }
  childTemplates.forEach(child => {
    children.push(child);
    getAllChildTemplates(templates, child.id, children);
  });
  return children;
}
