
/**
 * This utility fn adds the UI locale language as a path prefix to a given
 * absolute publisher path
 *
 * @param url absolute path for a Publisher section
 * @returns
 */

export function addUILocale(url) {
  if (window.locale === 'en') {
    return url;
  }
  return '/' + window.locale + url;
}
