import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Splide from '@splidejs/splide';
import { valueAsArray } from '../../../../core/store';
import { CFPreviewItem } from '../../../../core/api/referenced-content-repository/referenced-content-repository.service';
import { FieldType } from '../../../../core/store/referenced-content-repository/referenced-content-repository.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/app-reducer';
import { getActiveWorkflow } from '../../../../core/store/workflows/workflow.reducer';
import { map, take } from 'rxjs';
import { keyBy } from 'lodash';
import { BidiService } from '../../../../core/i18n/bidi.service';
import { getContentLocalesList } from '../../../../core/store/content-locales/content-locales.reducer';
import { generateShortId } from '../../../../shared/shared-functions';

@Component({
  selector: 'gd-custom-field-preview',
  templateUrl: './custom-field-preview.component.html',
  styleUrls: ['./custom-field-preview.component.scss'],
})
export class CustomFieldPreviewComponent implements OnInit, AfterViewInit {
  @Input() selectedValue;
  @Input() configuration;
  @Input() previewData: CFPreviewItem | CFPreviewItem[];
  @Input() widgetColor;
  @Input() usage: 'article' | 'template' | 'collection';
  containerId = generateShortId();
  @Input() contextShadowRoot = null;

  @Output() editItem = new EventEmitter();

  @ViewChild('valueContainer', { static: false }) valueContainer: ElementRef;

  FieldType = FieldType;

  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  direction: 'rtl' | 'ltr';

  statusMap$ = this.store.select(getActiveWorkflow).pipe(
    map((workflow: any) => {
      return keyBy([...Object.values(workflow?.statesGraph || {})], 'name') as any;
    })
  );

  localesMap$ = this.store.select(getContentLocalesList).pipe(
    map((contentLocales: any) => {
      return keyBy(contentLocales, 'id') as any;
    })
  );

  get slides() {
    return valueAsArray(this.previewData).filter((val) => !!val);
  }

  constructor(private store: Store<AppState>, private bidiService: BidiService) {}

  ngOnInit() {
    this.dir$.pipe(take(1)).subscribe(direction => this.direction = direction);

    if (Array.isArray(this.previewData)) {
      setTimeout(() => {
        let sliderEl: any;
        if (this.contextShadowRoot) {
          sliderEl = this.contextShadowRoot.getElementById(this.containerId);
        } else {
          sliderEl = document.getElementById(this.containerId);
        }
        if (sliderEl) {
          new Splide(sliderEl, {
            drag: false,
            waitForTransition: true,
            direction: this.direction === 'rtl' ? 'rtl' : 'ltr'
          }).mount();
        }
      }, 200);
    }
  }

  ngAfterViewInit() {
    const ValueElements = Array.from(this.valueContainer.nativeElement.children);
    let hasHiddenValues = false;

    // Calculating what element is not in full view inside its parent
    ValueElements.forEach((element: HTMLElement, index) => {
      if (index === 0) {
        return;
      }
      const valuesListElement: HTMLElement =  element.parentElement;
      const seeAllButton: HTMLElement = element.parentElement.parentElement.querySelector(
        '.gd-cf-preview__see-all-button'
      )
      // If element height and its position form top is bigger that the parent element than we add the hidden class on that element
      if (element.offsetTop + element.offsetHeight > valuesListElement.offsetHeight) {
        element.classList.add('hidden');
        seeAllButton.style.visibility = 'visible';
        // If the flag is not triggered assign height to values list
        if (!hasHiddenValues) {
          const valueListHeight = valuesListElement.offsetHeight
          // We set the height of values list to the offsetTop position of the overflown element ie
          // That position should be the bottom position of the last fully visible element
          valuesListElement.style.maxHeight = `${element.offsetTop}px`;
          valuesListElement.style.minHeight = `${element.offsetTop}px`;
          valuesListElement.style.height = `${element.offsetTop}px`;
          // If there is no room for see all button remove another element
          if (valueListHeight - element.offsetTop <= 20 && index !==1) {
            (ValueElements[index-1] as HTMLElement).classList.add('hidden');
          }
        }
        // When we set the height of values list we set the flag to true so it don't set the height again
        hasHiddenValues = true;
      }
    });
  }

  hasValueSet(item) {
    return item.values?.some((v) => +v.label || !!v.label || v.link);
  }

  handleEditItem() {
    this.editItem.emit()
  }
}
