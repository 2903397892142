import { Route } from './route.model';
import { UnsafeAction } from '../unsafe-action.interface';

export const GET_ROUTES = '[Route] Get routes';
export class GetRoutesAction implements UnsafeAction {
  readonly type = GET_ROUTES;
  constructor() { }
}

export const GET_ROUTES_SUCCESS = '[Route] Get routes success';
export class GetRoutesSuccessAction implements UnsafeAction {
  readonly type = GET_ROUTES_SUCCESS;
  constructor(public payload: { routes: Route[], query: any }) { }
}

export const CREATE_ROUTE = '[Route] Create route';
export class CreateRouteAction implements UnsafeAction {
  readonly type = CREATE_ROUTE;
  constructor(public payload: Route) { }
}

export const CREATE_ROUTE_SUCCESS = '[Route] Create route success';
export class CreateRouteSuccessAction implements UnsafeAction {
  readonly type = CREATE_ROUTE_SUCCESS;
  constructor(public payload: Route) { }
}

export const CREATE_ROUTE_FAILED = '[Route] Create route failed';
export class CreateRouteFailedAction implements UnsafeAction {
  readonly type = CREATE_ROUTE_FAILED;
  constructor(public err: any) { }
}

export const UPDATE_ROUTE = '[Route] Update route';
export class UpdateRouteAction implements UnsafeAction {
  readonly type = UPDATE_ROUTE;
  constructor(public payload: any) { }
}

export const UPDATE_ROUTE_SUCCESS = '[Route] Update route success';
export class UpdateRouteSuccessAction implements UnsafeAction {
  readonly type = UPDATE_ROUTE_SUCCESS;
  constructor(public payload: Route) { }
}

export const UPDATE_ROUTE_FAILED = '[Route] Update route failed';
export class UpdateRouteFailedAction implements UnsafeAction {
  readonly type = UPDATE_ROUTE_FAILED;
  constructor(public err: any) { }
}

export const UPDATE_ROUTE_POSITION = '[Route] Update route position';
export class UpdateRoutePositionAction implements UnsafeAction {
  readonly type = UPDATE_ROUTE_POSITION;
  constructor(public payload: any) { }
}

export const UPDATE_ALL_ROUTES = '[Route] Update all routes';
export class UpdateAllRoutesAction implements UnsafeAction {
  readonly type = UPDATE_ALL_ROUTES;
  constructor() { }
}

export const DELETE_ROUTE = '[Route] Delete route';
export class DeleteRouteAction implements UnsafeAction {
  readonly type = DELETE_ROUTE;
  constructor(public payload: any) { }
}

export const DELETE_ROUTE_SUCCESS = '[Route] Delete route success';
export class DeleteRouteSuccessAction implements UnsafeAction {
  readonly type = DELETE_ROUTE_SUCCESS;
  constructor(public payload: any) { }
}

export const DELETE_ROUTE_FAILED = '[Route] Delete route failed';
export class DeleteRouteFailedAction implements UnsafeAction {
  readonly type = DELETE_ROUTE_FAILED;
  constructor(public err: any) { }
}

export const ROUTE_ACTION_FAILED = '[Route] Generic failure action';
export class RouteFailedAction implements UnsafeAction {
  readonly type = ROUTE_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const SET_ROUTE_FILTER = '[Route] Set route filter';
export class SetRouteFilterAction implements UnsafeAction {
  readonly type = SET_ROUTE_FILTER;
  constructor(public payload: String) { }
}
