<mat-form-field [appearance]="appearance"
                [class]="class"
                [class.gd-localizations-list__filter]="usage === 'ARTICLE_LIST' || usage === 'GALLERY_LIST'"
                [class.gd-gallery-list__filter]="usage === 'GALLERY_LIST'"
                floatLabel="always"
                class="gd-localizations-list"
                [dir]="dir$ | async">
  <mat-label i18n>Locale</mat-label>
  <mat-select [formControl]="myControl"
              (selectionChange)="handleLocaleChange($event)"
              (click)="onInputClick()">
    <mat-select-trigger>
      <img class="gd-locale-flag-icon"
           *ngIf="myControl.value?.id !== 0"
           [src]="myControl.value?.iconUrl || '/assets/img/flags/xx.svg'"
           alt="">
      <span class="gd-localizations-list__locale-label">{{myControl.value?.label}}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let locale of (options$ | async)"
                [value]="locale"
                fxLayout="row"
                fxLayoutAlign="start center">
      <img class="gd-locale-flag-icon"
           *ngIf="locale?.id !== 0"
           [src]="locale?.iconUrl || '/assets/img/flags/xx.svg'"
           alt=""
           [class.--no-locale-version]="!locale?.localizedEntity && !locale?.active && !selectOnly">
      <span class="gd-localizations-list__locale-label"
            [class.--no-locale-version]="!locale?.localizedEntity && !locale?.active && !selectOnly">
        {{locale?.label}}
      </span>
      <span *ngIf="locale?.localizedEntity && locale?.localizedEntity?.status && usage !== 'ARTICLE_LIST' && usage !== 'GALLERY_LIST'"
            class="gd-locale__localization-status"
            [matTooltip]="locale.localizedEntity.status.name"
            [style.background-color]="locale.localizedEntity.status.backgroundColor"
            [style.color]="locale.localizedEntity.status.color">
        {{locale.localizedEntity.status.letter}}
      </span>
      <mat-icon *ngIf="!locale?.localizedEntity && !locale?.active && !selectOnly"
                class="gd-locale-add-new"
                fontSet="fal"
                fontIcon="fa-plus"></mat-icon>
    </mat-option>
  </mat-select>
</mat-form-field>
