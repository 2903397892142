import { Action } from '@ngrx/store';
import { CollectionType } from './collection-type.model';

export enum CollectionTypeActionTypes {
  LOAD = '[CollectionTypes] Load List',
  LOAD_SUCCESS = '[CollectionTypes] Load List Success',
  LOAD_ONE = '[CollectionTypes] Load One',
  LOAD_ONE_SUCCESS = '[CollectionTypes] Load One Success',
  SET_ACTIVE = '[CollectionTypes] Set Active Id',
  CREATE = '[CollectionTypes] Create',
  CREATE_SUCCESS = '[CollectionTypes] Create Success',
  UPDATE = '[CollectionTypes] Update',
  UPDATE_SUCCESS = '[CollectionTypes] Update Success',
  DELETE = '[CollectionTypes] Delete',
  DELETE_SUCCESS = '[CollectionTypes] Delete Success',
  FAILURE = '[CollectionTypes] Load Failure',
  CLEAR = '[CollectionTypes] Clear',
  SET_EMBEDDABLE = '[CollectionTypes] Set Embeddable Resource',
  SET_EMBEDDABLE_SUCCESS = '[CollectionTypes] Set Embeddable Resource Success'

}

export class LoadCollectionTypesAction implements Action {
  readonly type = CollectionTypeActionTypes.LOAD;
}

export class LoadCollectionTypesSuccessAction implements Action {
  readonly type = CollectionTypeActionTypes.LOAD_SUCCESS;
  constructor(public payload: CollectionType[]) {}
}

export class LoadOneCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.LOAD_ONE;
  constructor(public payload: number) {}
}

export class LoadOneCollectionTypeSuccessAction implements Action {
  readonly type = CollectionTypeActionTypes.LOAD_ONE_SUCCESS;
  constructor(public payload: CollectionType) {}
}

export class CreateCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.CREATE;
  constructor(public payload: CollectionType) {}
}

export class CreateCollectionTypeSuccessAction implements Action {
  readonly type = CollectionTypeActionTypes.CREATE_SUCCESS;
  constructor(public payload: CollectionType) {}
}

export class UpdateCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.UPDATE;
  constructor(public payload: CollectionType) {}
}

export class UpdateCollectionTypeSuccessAction implements Action {
  readonly type = CollectionTypeActionTypes.UPDATE_SUCCESS;
  constructor(public payload: CollectionType) {}
}

export class DeleteCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.DELETE;
  constructor(public payload: number) {}
}

export class DeleteCollectionTypeSuccessAction implements Action {
  readonly type = CollectionTypeActionTypes.DELETE_SUCCESS;
  constructor(public payload: number) {}
}

export class SetActiveCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.SET_ACTIVE;
  constructor(public payload: number) {}
}

export class FailureCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class ClearCollectionTypeAction implements Action {
  readonly type = CollectionTypeActionTypes.CLEAR;
  constructor() {}
}

export class SetEmbeddableResourceAction implements Action {
  readonly type = CollectionTypeActionTypes.SET_EMBEDDABLE;
  constructor() {}
}

export class SetEmbeddableResourceSuccessAction implements Action {
  readonly type = CollectionTypeActionTypes.SET_EMBEDDABLE_SUCCESS;
  constructor(public payload) {}
}

export type CollectionTypeActions =
  | LoadCollectionTypesAction
  | LoadCollectionTypesSuccessAction
  | LoadOneCollectionTypeAction
  | LoadOneCollectionTypeSuccessAction
  | CreateCollectionTypeAction
  | CreateCollectionTypeSuccessAction
  | UpdateCollectionTypeAction
  | UpdateCollectionTypeSuccessAction
  | DeleteCollectionTypeAction
  | DeleteCollectionTypeSuccessAction
  | SetActiveCollectionTypeAction
  | FailureCollectionTypeAction
  | ClearCollectionTypeAction
  | SetEmbeddableResourceAction
  | SetEmbeddableResourceSuccessAction;
