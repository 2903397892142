
import {catchError,  startWith, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { throwError, interval, Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

interface AdditionalOptions {
  headers?: { [key: string]: string };
  service?: string;
  isPlainText?: boolean;
  withCredentials?: boolean;
  omitDefaultHeaders?: boolean;
  domain?: string;
}

@Injectable()
export class RestService {
  private defaultHeaders = { 'Content-Type': 'application/json' };
  private serviceUrlMapping = {
    glideCreate: environment.glideCreate + environment.glideCreateVersion,
    glideUsers: environment.glideUsers + environment.glideUsersVersion,
    glideMedia: environment.glideMediaDomain,
    // used for override purposes, preview services will provide full URL
    glidePreview: '',
    glideTransmit: environment.glideTransmit + environment.glideTransmitVersion,
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) {
    this.authService.getAuthDataStream().subscribe((tokenData) => {
      if (!tokenData) {
        this.clearAuthToken();
        return;
      }
      this.setAuthToken(tokenData.authToken);
    });
  }

  setAuthToken(token) {
    this.defaultHeaders['Authorization'] = 'Bearer ' + token;
  }

  clearAuthToken() {
    this.defaultHeaders = { 'Content-Type': 'application/json' };
  }

  get<T = any>(url: string, options: AdditionalOptions = {}): Observable<T> {
    return this.authService.checkAuthentication().pipe(
      mergeMap(() => {
        const { requestUrl, requestOptions } = this.resolveRequestOptions(url, options);
        return this.http.get(requestUrl, requestOptions).pipe(catchError(this.handleError.bind(this)));
      })
    );
  }

  poll<T = any>(url: string, pollInterval: number, options: AdditionalOptions): Observable<T> {
    return interval(pollInterval)
      .pipe(
        startWith(0),
        // the get method is guarded by ensureUserIsAuthenticated
        mergeMap(() => this.get(url, options))
      ).pipe(
      catchError(this.handleError.bind(this))) as Observable<T>;
  }

  post<T = any>(url: string, payload: any = {}, options: AdditionalOptions = {}): Observable<T> {
    return this.authService.checkAuthentication().pipe(
      mergeMap(() => {
        const body = options.isPlainText ? payload : JSON.stringify(payload);
        const { requestUrl, requestOptions } = this.resolveRequestOptions(url, options);
        return this.http.post(requestUrl, body, requestOptions).pipe(catchError(this.handleError.bind(this)));
      })
    );
  }

  put<T = any>(url: string, payload: any, options: AdditionalOptions = {}): Observable<T> {
    return this.authService.checkAuthentication().pipe(
      mergeMap(() => {
        const body = options.isPlainText ? payload : JSON.stringify(payload);
        const { requestUrl, requestOptions } = this.resolveRequestOptions(url, options);
        return this.http
          .put(requestUrl, body, requestOptions).pipe(
          catchError(this.handleError.bind(this))) as Observable<T>;
      })
    );
  }

  delete<T = any>(url: string, options: AdditionalOptions = {}): Observable<T> {
    return this.authService.checkAuthentication().pipe(
      mergeMap(() => {
        const { requestUrl, requestOptions } = this.resolveRequestOptions(url, options);
        return this.http.delete(requestUrl, requestOptions).pipe(catchError(this.handleError.bind(this)));
      })
    );
  }

  private resolveRequestOptions(url: string, options: AdditionalOptions) {
    const headersRaw = options.omitDefaultHeaders
      ? { ...options.headers }
      : { ...this.defaultHeaders, ...options.headers };
    const requestOptions = { headers: new HttpHeaders(headersRaw), withCredentials: false };
    if (options.withCredentials) {
      requestOptions.withCredentials = true;
    }
    const requestUrl = this.resolveFullServiceUrl(url, options.service, options.domain);
    return { requestUrl, requestOptions };
  }

  private resolveFullServiceUrl(url, service, domain) {
    if (domain) {
      return domain + url;
    }
    if (!!service) {
      return this.serviceUrlMapping[service] + url;
    }
    return this.serviceUrlMapping.glideCreate + url;
  }

  private handleError(httpError: HttpErrorResponse) {
    const error = httpError.error;
    // NOTE: for debugging only
    // const errMsg = error.message
    //   ? error.message
    //   : error.status
    //     ? `${error.status} - ${error.statusText}`
    //     : 'Server error';
    // console.error(errMsg);
    const isLoginAttempt = !!httpError.url && httpError.url.indexOf('auth') !== -1;
    const isUnauthorized =
      httpError.status === 401 || (error?.message && error?.message.match('ExpiredJwtException'));
    // log out user if the is not authorized, and if he is not trying to log in
    if (isUnauthorized && !isLoginAttempt) {
      this.authService.logout();
      // display a simple message letting user know what happened
      setTimeout(() => {
        this.snackBar.dismiss();
        // TODO verify this message is no longer relevan and remove this bit of code
        console.log('User session has expired');
        // this.snackBar.open('Your session has expired', $localize`Close`, { duration: 20000 });
      }, 20);
    }
    const err =
      typeof error === 'object'
        ? { ...error, status: httpError.status }
        : { error, status: httpError.status };
    return throwError(err);
  }
}
