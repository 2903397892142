import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Subscription,
  catchError,
  combineLatest,
  debounceTime,
  filter,
  map,
  mergeMap,
  of,
  take,
  tap,
} from 'rxjs';
import { BidiService } from '../../../core/i18n/bidi.service';
import { CustomFormConstructorService } from '../../../gpp-shared/widget-configuration/form-constructor/custom-form-constructor.service';
import { EmbeddablesDataService } from '../../editors/embeddables-data.service';
import { generateShortId } from '../../shared-functions';
import { TwitchIntegrationService } from '../../../core/api/video-integrations/twitch-integration.service';
import { ScriptInjectorService } from '../../../core/miscellaneous/script-injector.service';

declare const Twitch: any;

@Component({
  selector: 'gd-embed-twitch-dialog',
  templateUrl: './embed-twitch-dialog.component.html',
  styleUrls: ['./embed-twitch-dialog.component.scss'],
})
export class EmbedTwitchDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  mediaForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: [''],
    src: [''],
    description: [''],
    autoplay: [false],
  });

  id;
  metaDataConfig;
  componentSub: Subscription = new Subscription();
  editingMode = false;
  allowMeta = false;
  content = 'video'; // 'video' | 'stream'
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  tabIndex: number = 0;
  initialValues = null;

  @ViewChild('focusInput') focusInput: ElementRef;
  @ViewChild('previewEl') previewEl: ElementRef;

  get idControl() {
    return this.mediaForm.get('id');
  }

  get metaData(): UntypedFormControl {
    return this.mediaForm.get('itemMetaData') as UntypedFormControl;
  }

  get videoUrl() {
    return this.previewEl ? this.previewEl.nativeElement.src : null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedTwitchDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private fc: CustomFormConstructorService,
    private embeddablesDataService: EmbeddablesDataService,
    public domSanitizer: DomSanitizer,
    private bidiService: BidiService,
    private twitchIntegrations: TwitchIntegrationService,
    private scriptInjector: ScriptInjectorService
  ) {}

  ngOnInit(): void {
    this.tabIndex = this.dialogInputData.tabIndex;
    this.loadTwitchScript();
    this.editingMode = !!this.dialogInputData.editingMode;
    this.componentSub.add(this.createIdInputSub());
    this.allowMeta = !!this.dialogInputData.allowMeta;
    this.content = this.dialogInputData.embedType.endsWith('stream') ? 'stream' : 'video';
    if (this.editingMode && this.dialogInputData.id) {
      // triggers form initialization
      this.idControl.setValue(this.dialogInputData.id);
    }
    this.prepareItemMetaData();
  }

  ngAfterViewInit() {
    setTimeout(() => this.focusInput.nativeElement.focus(), 200);
  }

  ngOnDestroy(): void {
    this.componentSub.unsubscribe();
  }

  createIdInputSub() {
    let firstChange = !this.editingMode || this.dialogInputData.id;
    return combineLatest([
      this.idControl.valueChanges.pipe(
        debounceTime(200),
        tap(() => this.clearPreviousData()),
        filter((id) => !!id),
        map((id) => (id || '').trim())
      ),
      this.twitchIntegrations.auth().pipe(take(1)),
    ]).subscribe(([id, authorisationData]) => {
      if (id && authorisationData) {
        if (authorisationData?.status === 'missing-credentials') {
          this.idControl.setErrors({ missingTwitchSettings: true });
          return this.idControl.markAsTouched();
        }
        if (authorisationData?.status === 'invalid-client') {
          this.idControl.setErrors({ invalidClientTwitchSettings: true });
          return this.idControl.markAsTouched();
        }
        if (authorisationData?.status === 'invalid-secret') {
          this.idControl.setErrors({ invalidSecretTwitchSettings: true });
          return this.idControl.markAsTouched();
        }
        if (authorisationData?.status === 'invalid-credentials') {
          this.idControl.setErrors({ invalidCredentialsTwitchSettings: true });
          return this.idControl.markAsTouched();
        }
        this.idControl.markAllAsTouched();
        this.getMediaData(id, authorisationData).subscribe((data) => {
          const error = !data || data.error || data.status === 404 ? { invalidId: true } : null;
          this.idControl.setErrors(error);
          if (error) {
            return;
          }
          const videoDefaultOptions = {
            width: 450,
            height: 300,
            autoplay: false,
          };
          const videoData = data.data[0];

          const showSavedData = firstChange && this.editingMode;
          firstChange = false;

          if (showSavedData) {
            const { src, name, description, autoplay, id } = this.dialogInputData;
            const options =
              this.content === 'stream'
                ? { ...videoDefaultOptions, channel: id }
                : { ...videoDefaultOptions, video: id };
            this.removeIframe();
            new Twitch.Player('twitch-embed', options);

            return this.mediaForm.patchValue({ src, name, description, autoplay });
          }

          const { title, description, thumbnail_url, id, user_login } = videoData;
          const options =
            this.content === 'stream'
              ? { ...videoDefaultOptions, channel: user_login }
              : { ...videoDefaultOptions, video: id };
          this.removeIframe();
          new Twitch.Player('twitch-embed', options);

          this.mediaForm.patchValue({
            name: title,
            description,
            src: thumbnail_url
              .replace('%{width}x%{height}', '320x180')
              .replace('{width}x{height}', '320x180'),
          });
        });
      }
    });
  }

  getMediaData(id, authorisationData) {
    if (this.content === 'video') {
      return this.embeddablesDataService
        .getTwitchVideoData(id, authorisationData)
        .pipe(catchError((err) => of(err)));
    }
    return this.embeddablesDataService
      .getTwitchStreamData(id, authorisationData)
      .pipe(catchError((err) => of(err)));
  }

  save() {
    this.dialogRef.close(this.prepareOutputObj());
  }

  prepareOutputObj() {
    const data = this.mediaForm.value;
    data.id = data.id ? data.id.trim() : data.id;
    data.src = data.src || './assets/img/dailymotion-icon.svg';

    if (this.content === 'playlist') {
      delete data.autoplay;
    }
    if (!this.allowMeta) {
      delete data.itemMetaData;
    }
    if (this.allowMeta) {
      return data;
    }

    const payload = {
      type: this.dialogInputData.embedType,
      blockId: this.dialogInputData.blockId || generateShortId(),
      updateData: data,
    };
    return { payload };
  }

  clearPreviousData() {
    this.removeIframe();
    ['name', 'description', 'src'].forEach((key) => {
      this.mediaForm.get(key).setValue('');
    });
  }

  prepareItemMetaData() {
    this.mediaForm.removeControl('itemMetaData');

    const passedConfig = this.dialogInputData.itemMetaDataConfig;
    this.metaDataConfig = passedConfig && passedConfig.length > 0 ? passedConfig : null;
    const initialValues = this.editingMode ? this.dialogInputData.itemMetaData || {} : null;
    // TODO refactor - move responsibility to DynamicWidgetFormComponent
    this.initialValues = initialValues;
    const itemFormGroup = this.fc.createFormGroup(this.metaDataConfig, initialValues, {
      editMode: !!initialValues,
    });
    this.mediaForm.addControl('itemMetaData', itemFormGroup);
  }

  getSaveButtonTooltipMessage() {
    if (this.mediaForm.valid) {
      return '';
    }
    if (this.metaData.invalid) {
      if (this.isCustomDataTaxonomyAllowed()) {
        return $localize`Some of the used Taxonomies on custom fields are invalid.`;
      }
      return $localize`Metadata form is invalid`;
    }
    return $localize`Form is invalid`;
  }

  isCustomDataTaxonomyAllowed() {
    const configuration = this.metaData as UntypedFormControl;
    if (!configuration) {
      return false;
    }
    return Object.values(configuration['controls']).some((control: UntypedFormControl) =>
      control.hasError('taxonomyErr')
    );
  }

  loadTwitchScript() {
    if (document.getElementById('twitch-embed')) {
      return;
    }

    this.scriptInjector
      .injectScript('https://embed.twitch.tv/embed/v1.js', { id: 'twitch-embed' })
      .catch(() => console.log('Error: Twitch software development kit failed to load'));
  }

  removeIframe() {
    const TwitchEmbed = document.getElementById('twitch-embed');
    if (TwitchEmbed.firstChild) {
      TwitchEmbed.removeChild(TwitchEmbed.firstChild);
    }
  }
}
