<div class="gd-expandable-wrapper" *ngIf="chipFormControl.value.length > 0" #expandableWrapper>
        <mat-chip-list #chipList
                class="gd-expandable__chip-list">
                <ng-container [ngSwitch]="type">
                    <ng-container *ngSwitchCase="expandableChipTypes.TAG">
                        <mat-chip *ngFor="let item of chipFormControl.value; let i = index"
                            class="gd-expandable-chip"
                            [style.background]="chipColorMap[item.group]"
                            [style.color]="'#fff'"
                            [style.font-weight]="'500'"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeSelectedItem(item)">{{item.name}}
                                <mat-icon matChipRemove
                                        style="color: #fff"
                                        fontSet="fal"
                                        fontIcon="fa-times-circle"></mat-icon>
                        </mat-chip>
                    </ng-container>
                    <ng-container *ngSwitchCase="expandableChipTypes.USER">
                        <mat-chip *ngFor="let item of chipFormControl.value; let i = index"
                            class="gd-expandable-chip"
                            [style.background]=""
                            [style.color]="'#fff'"
                            [style.font-weight]="'500'"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeSelectedItem(item)">{{ item.firstName + ' ' + item.lastName }}
                        <mat-icon matChipRemove
                            style="color: #fff"
                            fontSet="fal"
                            fontIcon="fa-times-circle"></mat-icon>
                        </mat-chip>
                    </ng-container>
                    <ng-container *ngSwitchCase="expandableChipTypes.TAXONOMY">
                        <mat-chip *ngFor="let taxonomy of chipFormControl.value; let i = index"
                        [selectable]="false"
                        class="gd-expandable-chip"
                        [removable]="true"
                        (removed)="removeSelectedItem(taxonomy)">
                            <div *ngIf="!showTaxonomyPath"><span>{{taxonomy.name}}</span></div>
                            <div *ngIf="showTaxonomyPath">
                            <span *ngFor="let name of taxonomy.taxonomyDisplayData; let last = last; let first = first;" [dir]="dir$ | async">
                                <mat-icon class="arrow-taxonomy-icon"
                                        *ngIf='!first'
                                        fontSet="fal"
                                        [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"> </mat-icon>
                                <mat-icon *ngIf="name === 'NODE_PLACEHOLDER'"
                                        class="arrow-taxonomy-icon"
                                        [matTooltip]="taxonomy.fullPath"
                                        [matTooltipClass]="'taxonomy-path-tooltip'"
                                        fontSet="fal"
                                        fontIcon="fa-ellipsis-h-alt">
                                </mat-icon>
                                <span *ngIf="name !== 'NODE_PLACEHOLDER'">{{ name }}</span>
                            </span>
                            </div>
                            <mat-icon style="color: #fff"
                                    matChipRemove
                                    fontSet="fal"
                                    fontIcon="fa-times-circle"></mat-icon>
                        </mat-chip>

                    </ng-container>
                    <ng-container *ngSwitchCase="expandableChipTypes.DEFAULT">
                        <mat-chip *ngFor="let item of chipFormControl.value; let i = index"
                            class="gd-expandable-chip"
                            [style.background]="'#E0E0E0'"
                            [style.color]="'#000000DE'"
                            [style.font-weight]="'500'"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeSelectedItem(item)">{{item.name}}
                                <mat-icon matChipRemove
                                style="color: #00000099"
                                fontSet="fal"
                                fontIcon="fa-times-circle"></mat-icon>
                        </mat-chip>
                    </ng-container>

                </ng-container>
        </mat-chip-list>
</div>

