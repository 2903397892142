import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from '../rest.service';

@Injectable()
export class ContentFiltersService {
  constructor(private rest: RestService) {}

  getContentFilters({ domain = 'Article', currentPage = 0, pageSize = 20 }: any = {}) {
    let requestPath = `content-filters?page=${currentPage || 0}&size=100`;
    requestPath += domain ? `&domain=${encodeURIComponent(domain || 'Article')}` : '';
    return this.rest.get(requestPath).pipe(map(({ data }) => data));
  }

  getContentFilter(id) {
    return this.rest.get(`content-filters/${id}`).pipe(map((response) => response.data));
  }

  createContentFilter(contentFilter) {
    return this.rest.post('content-filters', contentFilter).pipe(map((response) => response.data));
  }

  updateContentFilter(contentFilter) {
    return this.rest
      .put(`content-filters/${contentFilter.id}`, contentFilter)
      .pipe(map((response: any) => response.data));
  }

  deleteContentFilter(id) {
    return this.rest.delete(`content-filters/${id}`);
  }

  setDefaultFilter(id) {
    return this.rest
      .post(`content-filters/${id}/default`)
      .pipe(map((response: any) => response.data));
  }

  clearDefaultFilter(id) {
    return this.rest
      .delete(`content-filters/${id}/default`)
      .pipe(map((response: any) => response.data));
  }
}
