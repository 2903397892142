import { UnsafeAction } from '../unsafe-action.interface';
import {
  UPDATE_ARTICLE_BODY,
  UPDATE_ARTICLE_CONTENT_NODE,
  REMOVE_ARTICLE_CONTENT_NODE,
  COPY_ARTICLE_CONTENT_NODES,
  CREATE_ARTICLE_CONTENT_NODE
} from './article.actions';
import { ContentNode, EmbeddedType } from './article-content.model';
import { ArticleRevision } from './article.model';
import { resolveArticleBodyImage } from '../../api/images/images.service'

export function articleContentReducer(state: ArticleRevision = null, action: UnsafeAction): ArticleRevision {
  if (state === null) {
    return null;
  }

  // this updates active latest revision article body each time the change in body happens
  switch (action.type) {
    case UPDATE_ARTICLE_BODY: {
      const articleBody = action.payload;
      if (!articleBody) { return state; }
      const contentStructure = articleBody.contentStructure;
      const oldContentNodes = state.body ? state.body.contentNodes : {};

      const contentNodes = createContentNodesObject(oldContentNodes, articleBody.contentNodes);
      return {
        ...state,
        body: {
          ...articleBody,
          contentStructure,
          contentNodes
        }
      };
    }

    case UPDATE_ARTICLE_CONTENT_NODE: {
      const targetBlockId = action.payload.blockId;
      const articleBody: any = state.body;
      const targetBlock: ContentNode = articleBody.contentNodes[targetBlockId];

      if (!targetBlock) {
        return state;
      }

      const updatedBlock = updateContentBlock(targetBlock, action.payload.updateData);
      return {
        ...state,
        body: {
          ...articleBody,
          contentNodes: {
            ...articleBody.contentNodes,
            [targetBlockId]: updatedBlock
          },
        }
      };
    }

    case CREATE_ARTICLE_CONTENT_NODE: {
      const targetBlockId = action.payload.blockId || action.payload.nodeId;
      const articleBody: any = state.body;
      const nodeData = action.payload.updateData || action.payload.nodeData;

      const newContentNode = updateContentBlock({ id: targetBlockId }, nodeData);
      return {
        ...state,
        body: {
          ...articleBody,
          contentNodes: {
            ...articleBody.contentNodes,
            [targetBlockId]: newContentNode
          },
        }
      };
    }

    case COPY_ARTICLE_CONTENT_NODES: {
      const articleBody: any = state.body;
      // expect an array of content block nodes
      const targetBlocks = action.payload.contentNodes
        .reduce((acc, block) => {
          acc[block.id] = block;
          return acc;
        }, {});
      return {
        ...state,
        body: {
          ...articleBody,
          contentNodes: {
            ...articleBody.contentNodes,
            ...targetBlocks
          },
        }
      };
    }

    case REMOVE_ARTICLE_CONTENT_NODE: {
      const newBody = {
        ...state.body,
        contentNodes: { ...state.body.contentNodes }
      };
      delete newBody.contentNodes[action.payload];
      return {
        ...state,
        body: newBody
      };
    }

    default:
      return state;
  }

}

// TODO extract into util functions for content reducers
function updateContentBlock(targetBlock: any, data) {
  const src = getSrcByEmbeddedType(targetBlock.type, data);
  data = { ...data, dataId: data.id, dataUrl: data.url, src };
  // if present, assign the document block id as well
  if (data.elementId) {
    data.id = data.elementId;
  }
  // possible attributes of content node, set only if exist
  const attributes = ['dataId', 'dataUrl', 'src', 'name', 'title', 'caption', 'alt', 'credit',
    'sourceMeta', 'alignment', 'imageFormat', 'imageStyle', 'description', 'configuration', 'autoplay', 'colour', 'identifier', 'startingMarkerId', 'duration', 'durationMillis', 'thumbnails', 'copyright', 'height', 'width'];

  const contentNode: any = { ...targetBlock, description: data.description, name: data.name };
  attributes.filter(attr => ['caption', 'alt', 'credit'].includes(attr) || !!data[attr]).forEach(attr => {
    // note: value can be empty string but not undefined
    // fixes image credits on images uploaded from editor
    if (data[attr] !== undefined) {
      contentNode[attr] = data[attr]
    }
  });

  if (data.hasOwnProperty('autoplay')) {
    contentNode['autoplay'] = data.autoplay;
  }

  // publishedAt is treated separately to avoid saving this info accidentally for some other resources
  if (targetBlock.type === EmbeddedType.YouTube && data.publishedAt) {
    contentNode.publishedAt = data.publishedAt;
  }
  return contentNode;
}

// TODO extract into util functions for content reducers
function getSrcByEmbeddedType(type, data) {
  switch (type) {
    case EmbeddedType.Image:
      return resolveArticleBodyImage(data.image || data);
    case EmbeddedType.Gallery:
      return data.thumbnail || data.src || './assets/img/embeded-gallery-placeholder.png';
    case EmbeddedType.YouTube:
      return `https://img.youtube.com/vi/${data.id}/0.jpg`;
    case EmbeddedType.FacebookPost:
      return data.id ? `https://graph.facebook.com/${data.id}/picture` : './assets/img/facebook-logo-blue.png';
    case EmbeddedType.TwitterPost:
      return './assets/img/x-twitter-logo-black.png';
    case EmbeddedType.InstagramPost:
      return './assets/img/instagram-logo.svg';
    case EmbeddedType.Vimeo:
      return data.src || './assets/img/vimeo-logo-blue.png';
    case EmbeddedType.Brightcove:
      return data.src || './assets/img/brightcove-logo-color.png';
    case EmbeddedType.BrightcovePlaylist:
      return data.src || './assets/img/brightcove-logo-color.png';
    case EmbeddedType.SystemWidget:
      return data.id ? './assets/img/custom-icons/glideCustomIcon_system-widgets.svg' : './assets/img/custom-icons/glideCustomIcon_system-widgets.svg';
    case EmbeddedType.ThirdPartyWidget:
      return data.id ? './assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg' : './assets/img/custom-icons/glideCustomIcon_third-party-widgets.svg';
    case EmbeddedType.File:
      return data.src || './assets/img/files/default.svg';
    case EmbeddedType.ImageURL:
      return data.src || data.url || './assets/img/embed-image-url-dark.svg';
    case EmbeddedType.TikTokPost:
      return './assets/img/tiktok-logo-dark.svg';
    case EmbeddedType.JWPlayerVideo:
    case EmbeddedType.JWPlayerPlaylist:
      return data.src || './assets/img/jw-logo-placeholder.svg';
    case EmbeddedType.DailymotionVideo:
    case EmbeddedType.DailymotionPlaylist:
      return data.src || './assets/img/dailymotion-logo.svg';
    case EmbeddedType.TwitchVideo:
    case EmbeddedType.TwitchStream:
      return data.src || './assets/img/twitch-icon.svg';
    default:
      return '';
  }
}

// TODO extract into util functions for content reducers
function createContentNodesObject(oldContentNodes, newContentNodes) {
  Object.keys(newContentNodes).forEach(key => {
    const overrideOldValues = newContentNodes[key].type !== EmbeddedType.Url
      || (newContentNodes[key].type === EmbeddedType.Url && newContentNodes[key].src);
    newContentNodes[key] = overrideOldValues ? { ...oldContentNodes[key], ...newContentNodes[key] } : { ...oldContentNodes[key] };
    // ensure we have node type in any case
    newContentNodes[key].type = newContentNodes[key].type || oldContentNodes[key].type;

    // TODO this should be thrown out once content node data is in the body
    // add handling for the links!
    // if the html attributes or target are null, remove them
    const contentNode = newContentNodes[key];
    if(contentNode.type === EmbeddedType.Link) {
      if(contentNode.htmlAttributes === null) {
        delete contentNode.htmlAttributes;
      }
      if(contentNode.target === null) {
        delete contentNode.target;
      }
    }
  });
  return newContentNodes;
}

