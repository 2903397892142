export interface PageTemplate {
  updatedAt?: any;
  createdBy?: any;
  updatedBy?: any;
  id: number;
  parentId: number;
  label: string;
  workingRevision: PageTemplateRevision;
  publishedRevision: PageTemplateRevision;
  additionalItems: { [key: string]: any };
  rootComponent: string;
}

export interface PageTemplateRevision {
  id?: number;
  blocks: LayoutBlock[];
  widgets: PageWidget[];
  htmlStructure?: string;
  createdAt?: number;
  updatedAt?: number;
}

export interface TemplateNode {
  id?: string;
  // Note: root level blocks have no parent
  parentBlockId: string;
  parentId?: number;
  label: string;
  cssClass: string;
  baseLayoutCssClass: string;
  position: number;
  additionalItems?: {
    width?: string;
    widthUnits?: string;
    customStyles?: string;
    tagName?: string;
    omitWrapperElement?: boolean;
  };
  // temporary properties calculated on the FE
  editable?: boolean;
  type?: TemplateNodeType;
  childBlocks?: LayoutBlock[];
  childWidgets?: PageWidget[];
  childrenIds?: string[];
  isSyntheticRoot?: boolean;
  // TODO investigeate the sub types
  subType?: any;
  subTypeId?: any;
  configuration?: any;
  contentTag?: string;
}

export enum TemplateNodeType {
  Widget = 'WIDGET',
  LayoutBlock = 'CONTAINER'
}


export interface LayoutBlock extends TemplateNode {
  // child templates can only place blocks inside childLayout blocks
  childLayout: boolean;
}

export interface PageWidget extends TemplateNode {
  visible: boolean;
  configuration: { [key: string]: any };
}

export const NodeIdConst = {
  Root: null,
  New: -1
};

export const NewTemplateId = -1;

export enum TemplateRevisionDesignation {
  Working = 'WORKING',
  Published = 'PUBLISHED'
}


export interface TemplateTreeItem {
  id: number;
  parentId: number;
  label: string;
  children: TemplateTreeItem[];
  childrenIds?: number[];
  isPublished: boolean;
  hasDescendants: boolean;
  deleteTooltip: string;
}
