import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-embed-anchor-link',
  templateUrl: './embed-anchor-link.component.html',
  styleUrls: ['./embed-anchor-link.component.scss']
})
export class EmbedAnchorLinkComponent implements OnInit {

  editingMode = false;
  blockId;

  anchorLinkFormBuilder: UntypedFormGroup = this.formBuilder.group({
    id: ['']
  });
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedAnchorLinkComponent>,
    private formBuilder: UntypedFormBuilder,
    private bidiService: BidiService,
  ) { }

  ngOnInit() {
    this.editingMode = this.dialogInputData.editingMode ? true : false;
    this.blockId = this.dialogInputData.blockId;

    if (this.editingMode) {
      this.anchorLinkFormBuilder.patchValue({id: this.dialogInputData.id});
    }
  }

  save() {
    const formData = this.anchorLinkFormBuilder.value;
    const contentNodeData = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formData
    };
    return this.dialogRef.close(contentNodeData);
  }

  cancel() {
   return this.dialogRef.close();
  }
}
