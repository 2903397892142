import { LOGIN, LOGGED_IN, LOGIN_FAILED, LOG_OUT_SUCCESS, LOAD_ACTIVE_USER_DETAILS_SUCCESS, SET_ACTIVE_USER_AVATAR, RESET_PASSWORD, RESET_PASSWORD_ERROR } from './auth.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { mapPermissionIdToLabel, mapFieldPermissionIdToLabel } from './permissions.map';

export interface UserData {
  sectionPermissions: any[];
  taxonomyPermissions?: any[];
  accountId: number;
  userId: number;
  username: string;
  jti: string;
  sub: string;
  iat: number;
  exp: number;
  // additional data from /profiles endpoint used for mixpanel
  profileData?: ProfileData;
}

export interface ProfileData {
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: any[];
}

export interface AccountModel {
  id: any;
  name: string;
  description: string;
  createdAt: number;
  updatedAt: number;
  updatedBy: number;
  sysCreatedAt: number;
  sysUpdatedAt: number;
  slug: string;
  connectApiKeys: string[];
  icon: string;
  colour: string;
  roles: SimpleRoleListItem[];
}

export const AllTaxonomiesAccessSysRoleName = 'All Taxonomies Access';

export const SectionRoleTypeName = 'SECTION_ACCESS';
export type SectionRoleType = 'SECTION_ACCESS';
export const TaxonomyRoleTypeName = 'TAXONOMY_ACCESS';
export type TaxonomyRoleType = 'TAXONOMY_ACCESS';
export const allAccountRoleTypes = [SectionRoleTypeName, TaxonomyRoleTypeName];

export type AccountRoleType = SectionRoleType | TaxonomyRoleType;

export interface SimpleRoleListItem {
  id: number;
  name: string;
  type: AccountRoleType;
  description: string;
}

export interface AuthState {
  loggedIn: boolean;
  loginError: string;
  userData: UserData;
  accounts: AccountModel[];
  activeAccount: AccountModel;
  userAvatar: { id: string, path?: string };
  resetPasswordError: string;
}

export const authInitialState: AuthState = {
  loggedIn: false,
  loginError: null,
  userData: null,
  accounts: [],
  activeAccount: null,
  userAvatar: null,
  resetPasswordError: null,
};

export function authReducer(state = authInitialState, action: UnsafeAction): AuthState {

  switch (action.type) {

    case LOGIN:
    case LOG_OUT_SUCCESS:
    case RESET_PASSWORD: {
      return { ...state, ...authInitialState } as AuthState;
    }

    case LOGGED_IN: {
      if (!action.payload.userData || !action.payload.accounts) {
        throw new Error('Login error: user data or accounts are not defined');
      }
      const activeAccount = action.payload.accounts.find(acc => +acc.id === +action.payload.userData.accountId);
      return {
        ...state,
        loggedIn: true,
        loginError: null,
        activeAccount: activeAccount,
        userData: action.payload.userData,
        accounts: action.payload.accounts,
      } as AuthState;
    }

    case LOGIN_FAILED: {
      let message = action.payload.message;
      if (action.payload.status === 400) {
        message = $localize`Incorrect username or password`;
      }

      const serverUnreachableCondition = message?.type === 'error' && message?.loaded === 0;
      if (action.payload.status === 0 || serverUnreachableCondition) {
        message = $localize`Server unreachable`;
      }
      return {
        ...authInitialState,
        loginError: message,
      } as AuthState;
    }

    case LOAD_ACTIVE_USER_DETAILS_SUCCESS: {
      // additional data from /profile endpoint used for mixpanel
      const profileData: ProfileData = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        //! ROLES ARE NO LONGER PROVIDED
        roles: [],
      }
      return {
        ...state,
        userData: {
          ...state.userData,
          profileData,
        },
        userAvatar: action.payload.image || null
      };
    }

    case SET_ACTIVE_USER_AVATAR: {
      return { ...state, userAvatar: action.payload };
    }

    case RESET_PASSWORD_ERROR: {
      let message = action.payload.message;
      if (action.payload.status === 400) {
        message = $localize`Password reset link is already used or expired`;
      }
      if (action.payload.status === 0) {
        message = $localize`Server unreachable`;
      }
      return {
        ...authInitialState,
        resetPasswordError: message,
      } as AuthState;
    }

    default: {
      return state;
    }
  }
}

export const selectAuthState = state => state.auth;

export const selectActiveUserDetails = createSelector(selectAuthState, (auth) => auth.userData);
export const selectUserSectionPermission = createSelector(selectAuthState, (auth) => {
  if (!auth['userData']) {
    return {};
  }
  return auth['userData'].sectionPermissions
    .map(mapPermissionIdToLabel)
    .reduce((permissionsMap, item) => {
      permissionsMap[item] = item;
      return permissionsMap;
    }, {});
});

export const getActiveAccountId = createSelector(selectAuthState, (auth) => +auth.userData?.accountId);

export const getUserAvatarPath = createSelector(selectAuthState, ({ userAvatar }) => userAvatar ? userAvatar.path : '');

export const selectUserFieldPermissions = createSelector(selectAuthState, (auth) => {
  if (!auth['userData']) {
    return {};
  }
  return auth['userData'].fieldPermissions
    .map(mapFieldPermissionIdToLabel)
    .reduce((fieldPermissionsMap, item) => {
      fieldPermissionsMap[item] = item;
      return fieldPermissionsMap;
    }, {});
});

export const getActiveAccount = createSelector(selectAuthState, (auth) => auth.activeAccount);
export const getActiveUserAccounts = createSelector(selectAuthState, (auth) => auth.accounts);
