<div class="gd-files-preview" [dir]="dir$ | async">
  <div class="gd-files-preview-wrapper">
    <div
      [class.inactive]="indexOfSelectedFile === 0 || !showNavButtons"
      class="gd-file-preview__navigation-arrow"
      (click)="loadPreviousFile()"
    >
      <button
        [disabled]="indexOfSelectedFile === 0 || !showNavButtons"
        mat-icon-button
        class="gd-file-preview__navigation-btn"
        color="primary"
      >
        <mat-icon class="gd-file-preview__navigation-btn" fontSet="fal" [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-left' : 'fa-angle-right'"></mat-icon>
      </button>
    </div>
    <div>
      <img
        *ngIf="fileDetails.fileType === 'image' && animationListener"
        [src]="fileUrl"
        alt="fileDetails.filename"
        class="gd-files-preview-image"
        [@slide]
      />
      <embed
        [@slide]
        type="application/pdf"
        *ngIf="fileDetails.fileType === 'document' && animationListener"
        [src]="fileUrl"
        class="gd-files-preview-document"
      />
      <div
        *ngIf="fileDetails.fileType === 'unsupportedType' && animationListener"
        class="gd-files-preview-fail"
        [@slide]
      >
        <mat-icon class="gd-files-preview__fail-icon">visibility_off</mat-icon>
        <span class="gd-files-preview-fail__span-text" style="margin-bottom: 8px" i18n>We can not preview this file type.</span
        >
        <span class="gd-files-preview-fail__span-text" i18n>Try downloading the file to view it.</span>
        <button
          class="gd-files-preview__fail-download"
          mat-raised-button
          color="ascent"
          (click)="downloadFile()"
        >
          <mat-icon class="gd-files-preview__fail-button-icon" fontSet="fal" fontIcon="fa-cloud-download"></mat-icon> <span i18n>Download</span>
        </button>
      </div>
    </div>
    <div
      [class.inactive]="indexOfSelectedFile === loadedFiles.length - 1 || !showNavButtons"
      class="gd-file-preview__navigation-arrow"
      (click)="loadNextFile()"
    >
      <button
        [disabled]="indexOfSelectedFile === loadedFiles.length - 1 || !showNavButtons"
        mat-icon-button
        class="gd-file-preview__navigation-btn"
        color="primary"
      >
        <mat-icon class="gd-file-preview__navigation-btn" fontSet="fal" [fontIcon]="(dir$ | async) !== 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"></mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="gd-files-preview__toolbar" fxLayout="row" fxLayoutAlign="space-between center" [dir]="dir$ | async">
  <div class="gd-files-preview__header">
    <mat-icon class="file-thumbnail" *ngIf="!fileDetails?.isSvgIcon"  fontSet="fal" fontIcon="{{resolveThumbnailImg(fileDetails)}}"></mat-icon>
    <mat-icon class="file-thumbnail" *ngIf="fileDetails?.isSvgIcon" svgIcon="{{resolveThumbnailImg(fileDetails)}}"></mat-icon>
    <div class="gd-files-preview__header-span">
      <span class="gd-files-preview__header-span-text">{{ fileDetails?.meta_data.name }}</span>
      <span class="gd-files-preview__header-span-text"
        >{{ contentType }} - {{ returnFileSize(fileDetails?.file_size) }}</span
      >
    </div>
  </div>
  <div class="gd-files-preview__toolbar-col" fxLayout="row" fxLayoutGap="16px">
    <mat-icon class="gd-files-preview__toolbar-col-download" (click)="downloadFile()" fontSet="fal" fontIcon="fa-cloud-download"></mat-icon
    >
    <mat-icon (click)="closeDialog()" fontSet="fal" fontIcon="fa-times"></mat-icon>
  </div>
</div>
