import { catchError, filter, debounceTime, map, take, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RoutesService } from '../../api/routes/routes.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Action, Store } from '@ngrx/store';
import {
  GET_ROUTES,
  GetRoutesSuccessAction,
  CREATE_ROUTE,
  CreateRouteSuccessAction,
  UPDATE_ROUTE,
  UpdateRouteSuccessAction,
  DELETE_ROUTE,
  DeleteRouteSuccessAction,
  ROUTE_ACTION_FAILED,
  RouteFailedAction,
  UPDATE_ALL_ROUTES,
} from './route.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { getRoutesList } from './route.reducers';
import { AppState } from '../app-reducer';

@Injectable()
export class RoutesEffects {

  loadList$: Observable<Action> = createEffect(() => this.actions$
    // fetch data on GET_ROUTES or refetch on ACCOUNT_CHANGED
    .pipe(ofType(GET_ROUTES))
    .pipe(
      debounceTime(50),
      mergeMap(() => this.store.select((state: any) => state.routes).pipe(take(1))),
      filter((state: any) => state.loading),
      mergeMap((action: UnsafeAction) => {
        return this.routesService.getRoutes().pipe(
          map((routesListData) => {
            const routesList: any = [];
            for (const routeData of routesListData) {
              routesList.push({ parentId: null, pageId: null, ...routeData });
            }
            return new GetRoutesSuccessAction(routesList);
          }),
          catchError((e) => of(new RouteFailedAction({ error: e, action })))
        );
      })
    ));


  createRoute$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_ROUTE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.routesService.createRoute(action.payload).pipe(
        tap((route) => {
          this.snackBar.open($localize`Route saved`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/site-builder/page-routes/']);
        }),
        map((route) => new CreateRouteSuccessAction(route.data)),
        catchError((err) => {
          this.snackBar.open($localize`Unable to save route.`, $localize`Close`, { duration: 4000 });
          return of(new RouteFailedAction(err));
        })
      )
    )
  ));


  updateRoute$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_ROUTE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.routesService.updateRoute(action.payload).pipe(
        tap((route: any) => {
          this.snackBar.open($localize`Route updated`, $localize`Close`, { duration: 4000 });
          route.data = { parentId: null, pageId: null, ...route.data };
          this.router.navigate(['/site-builder/page-routes/']);
        }),
        map((route) => {
          return new UpdateRouteSuccessAction(route);
        }),
        catchError((err) => {
          this.snackBar.open($localize`Unable to update route.`, $localize`Close`, { duration: 4000 });
          return of(new RouteFailedAction(err));
        })
      )
    )
  ));


  updateRoutePositions$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_ALL_ROUTES)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.store.select(getRoutesList).pipe(
        take(1),
        mergeMap((updatedRoutes) => {
          return this.routesService.updateAllRoutes(updatedRoutes).pipe(
            tap(() => this.snackBar.open($localize`Route positions updated`, $localize`Close`, { duration: 4000 })),
            map((routes) => new GetRoutesSuccessAction(routes))
          );
        })
      );
    })
  ));


  deleteRoute$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_ROUTE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.routesService.deleteRoute(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Route deleted`, $localize`Close`, { duration: 4000 })),
        map(() => new DeleteRouteSuccessAction(action.payload)),
        catchError((err) => {
          this.snackBar.open($localize`Unable to delete route.`, $localize`Close`, { duration: 4000 });
          return of(new RouteFailedAction(err));
        })
      )
    )
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(ROUTE_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  constructor(
    private actions$: Actions,
    private routesService: RoutesService,
    public snackBar: MatSnackBar,
    private store: Store<AppState>,
    private router: Router
  ) {}
}
