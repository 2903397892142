<div class="gd-translation-loader-dialog__wrapper"
     [dir]="dir$ | async">
  <p class="gd-translation-loader-dialog__message">
    <mat-icon fontSet="fal"
              svgIcon="glideCustomIcon_gaia_stars"></mat-icon>
    <span i18n>Article is being translated. Please wait..</span>
  </p>
  <mat-progress-spinner mode="indeterminate"
                        diameter="30"
                        class="gd-translation-loader-dialog__spinner">
  </mat-progress-spinner>
</div>
