import { UnsafeAction } from '../unsafe-action.interface';

export const GET_VERIFY_SETTINGS = '[Verify settings] Get verify settings';
export class GetVerifySettingsAction implements UnsafeAction {
  readonly type = GET_VERIFY_SETTINGS;
  constructor() {}
}

export const GET_VERIFY_SETTINGS_COMPLETE = '[Verify settings] Get verify settings completed';
export class GetVerifySettingsCompleteAction implements UnsafeAction {
  readonly type = GET_VERIFY_SETTINGS_COMPLETE;
  constructor(public payload: any) {}
}


export const UPDATE_VERIFY_SETTINGS = '[Files] Update verify settings';
export class UpdateVerifySettingsAction implements UnsafeAction {
  readonly type = UPDATE_VERIFY_SETTINGS;
  constructor(public payload: any) {}
}

export const UPDATE_VERIFY_SETTINGS_COMPLETE = '[Files] Update verify settings completed';
export class UpdateVerifySettingsCompletedAction implements UnsafeAction {
  readonly type = UPDATE_VERIFY_SETTINGS_COMPLETE;
  constructor(public payload: any) {}
}

export const VERIFY_SETTINGS_ERROR = '[Verify settings] Verify settings error';
export class VerifySettingsErrorAction implements UnsafeAction {
  readonly type = VERIFY_SETTINGS_ERROR;
  constructor(public payload: any) {}
}