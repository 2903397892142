<div class="gd-triple-choice-dialog__wrapper"
     [dir]="dir$ | async">
  <h2 class="mat-h2 gd-triple-choice-dialog__title">
    {{ title }}
    <ng-container *ngIf="usage === 'AUTOSAVE'">
      <mat-icon fontSet="fal"
                fontIcon="fa-cloud"
                class="gd-triple-choice-dialog__autosave-icon"></mat-icon>
    </ng-container>
  </h2>

  <p class="gd-triple-choice-dialog__message"
     [innerHTML]="message"></p>

  <div fxLayoutAlign="space-around stretch"
       fxLayout="row"
       fxLayoutGap="24px">
    <button class="gd-triple-choice-dialog__confirm-button"
            type="button"
            mat-button
            color="primary"
            (click)="dialogRef.close(TripleChoiceDialogSelection.Confirm)"
            data-cy="gd-confirm-yes">{{confirmNoActionButton}}</button>
    <button class="gd-triple-choice-dialog__confirm-button"
            type="button"
            mat-button
            color="accent"
            (click)="dialogRef.close(TripleChoiceDialogSelection.ConfirmWithAction)"
            data-cy="gd-confirm-yes-action">{{confirmWithActionButton}}</button>
    <button type="button"
            mat-button
            color="primary"
            (click)="dialogRef.close(TripleChoiceDialogSelection.Cancel)"
            data-cy="gd-confirm-no">{{closeButton}}</button>
  </div>
</div>
