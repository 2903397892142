import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { RestService } from '../rest.service';

export interface LiveReportListQueryParameters {
  page?: number;
  size?: number;
  filter?: {
    include?: any;
    includeSome?: any;
    exclude?: any;
    excludeSome?: any;
  };
  published?: boolean;
  orderBy?: string;
}
@Injectable()
export class LiveReportsService {

  constructor(private rest: RestService) { }

  getLiveReportsList({ page = 0, size = defaultPageSize, filter = null, published = null, orderBy = null }: LiveReportListQueryParameters = {}) {
    let requestPath = `live-reports?page=${page || 0}&size=${size || defaultPageSize}`;
    requestPath += published !== null ? `&published=${published}` : '';
    requestPath += orderBy !== null ? `&orderBy=${orderBy}` : '';
    if (filter && Object.keys(filter).length > 0) {
      const filterStr = JSON.stringify(filter);
      requestPath += `&filter=${encodeURIComponent(filterStr)}`;
    }
    return this.rest.get(requestPath).pipe(map(({ meta, data }) => ({ meta, liveReports: data })));
  }

  getReferencedLiveReportsByIds(liveReportsIds) {
    if (liveReportsIds.length < 1) {
      return of([]);
    }

    const size = liveReportsIds.length <= 200 ? liveReportsIds.length : 200;

    return this.rest.get(`live-reports/basic-projection?ids=${liveReportsIds.join(',')}&size=${size}`).pipe(
      map(response => response.data));
  }

  getLiveReport(id) {
    return this.rest.get(`live-reports/${id}`).pipe(map((response: any) => response.data));
  }

  getLiveReportUrlPreview({ id, headline, primaryTaxonomyId, seo }) {
    return this.rest
      .post(`live-reports/${id}/url-preview/`, { id, headline, primaryTaxonomyId, seo }).pipe(
        map(response => response.data),
        map(data => data.length > 0 ? data[0].url : ''));
  }

  createLiveReport(newLiveReport) {
    return this.rest.post('live-reports', newLiveReport).pipe(map((response: any) => response.data));
  }

  updateLiveReport(liveReport) {
    const { id } = liveReport;
    return this.rest.put(`live-reports/${id}`, liveReport).pipe(map((response: any) => response.data));
  }

  publishLiveReport(id) {
    return this.rest.post(`live-reports/${id}/publish`).pipe(map((response: any) => response.data));
  }

  unpublishLiveReport(id) {
    return this.rest.post(`live-reports/${id}/unpublish`).pipe(map((response: any) => response.data));
  }

  deleteLiveReport(id) {
    return this.rest.delete(`live-reports/${id}`);
  }

  getSummary(id) {
    return this.rest.get(`live-reports/${id}/summary`).pipe(map((response: any) => response.data));
  }
  createSummary(id, body) {
    return this.rest.post(`live-reports/${id}/summary`, body).pipe(map((response: any) => response.data));
  }
  updateSummary(id, body) {
    return this.rest.put(`live-reports/${id}/summary`, body).pipe(map((response: any) => response.data));
  }
  publishSummary(id) {
    return this.rest.post(`live-reports/${id}/summary/publish`).pipe(map((response: any) => response.data));
  }
  unpublishSummary(id) {
    return this.rest.post(`live-reports/${id}/summary/unpublish`).pipe(map((response: any) => response.data));
  }

  getRelatedArticles({ liveReportId, revisionId, size, useAutomaticRelatedArticles }) {
    const type = useAutomaticRelatedArticles ? 'automatic' : 'manual';
    let requestPath = `live-reports/${liveReportId}/revisions/${revisionId}/related-articles?type=${type}`;
    if (size) {
      requestPath += `&size=${size}`;
    }
    return this.rest
      .get(requestPath).pipe(
        map((response: any) => response.data));
  }
}
