import {
  catchError,
  map,
  take,
  tap,
  delay,
  switchMap,
  retryWhen,
  delayWhen,
  scan,
  filter,
  mergeMap,
} from 'rxjs/operators';
import { Inject, Injectable, LOCALE_ID, NgZone } from '@angular/core';
import { Router } from '@angular/router';
// import { decode } from 'jsonwebtoken';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-reducer';
import { LoggedInAction, LoadActiveUserDetailsAction } from '../../store/auth/auth.actions';
import { selectActiveUserDetails } from '../../store/auth/auth.reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { StorageService } from '../storage/storage.service';
import { RefreshAccountSettingsAction } from '../../store/account-settings/account-settings.actions';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, of, Observable, Observer, throwError, timer } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExtendSessionDialogComponent } from '../../../shared/extend-session-dialog/extend-session-dialog.component';
import { LoadTaxonomyConfigurationsAction } from '../../store/taxonomy-configuration/taxonomy-configuration.actions';
import { base64ToArrayBuffer } from './base64-to-array-buffer';
import { ungzip } from 'pako';
import { RefreshTokenDialogComponent } from '../../../shared/refresh-token-dialog/refresh-token-dialog.component';
import { get } from 'lodash-es';
import { UserPreferencesService } from '../user-preferences/user-preferences.service';
import { getAccountSettings } from '../../store/account-settings/account-settings.reducer';
import { extractAccountSlugFromHostname } from './domain-account-slug-utilities';
import { MixPanelService } from '../mixpanel/mixpanel.service';
import { isNumber } from 'lodash';


// if there is 1[ms] until the token expires, it should not be considered valid
// here, we set a 10[s] time meaning we should consider tokens expired if they have
// 10[s] left to live. this is so that we can execute update operations timely
const TOKEN_EXPIRE_TOLERANCE = 10 * 1000;

const printInfoLogs =
  !environment.production ||
  environment.envNotes.includes('GlideCloud1 QA environment') ||
  environment.envNotes.includes('GlideCloud1 DEV environment') ||
  environment.envNotes.includes('This is a local');
const tokenExpiredRegex = /^(?=.*\btoken\b)(?=.*\bexpired\b).*$/gim;

export enum ActiveUserTokenState {
  Valid,
  Expired,
  NoToken,
}

@Injectable()
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string;
  userData = null;
  accounts = [];
  authToken = '';
  refreshToken = '';
  refreshTokenCreatedAt = null;
  tokenRefreshTimeout;
  loginAccountId = null;

  // this is for SSO falback, when user doens't have access to
  // desired account from SSO config, but has other valid accounts
  public fallbackAccountId = null;
  public fallbackAccountSlug = null;

  // debugging flags
  simulateTokenRefreshFailure = false;
  simulateTokenExpired = false;

  // retry options
  maxTokenRefreshRetries = 5;
  retryDelay = 10000;

  // make no duplicate
  restAuthCheck$ = null;
  refreshTokenExtend$ = null;
  refreshTokenExtendDialog$: MatDialogRef<ExtendSessionDialogComponent> = null;

  // refresh token dialog
  refreshToken$ = null;
  refreshTokenDialog$: MatDialogRef<RefreshTokenDialogComponent> = null;

  private glideUsersUrl: string = environment.glideUsers + environment.glideUsersVersion;

  private authRequestConfig = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private authDataStream = new BehaviorSubject(null);
  private activeLanguage = '';
  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store<AppState>,
    public snackBar: MatSnackBar,
    public storageService: StorageService,
    private dialog: MatDialog,
    private zone: NgZone,
    private userPreferencesService: UserPreferencesService,
    @Inject(LOCALE_ID) locale: string,
  ) {
    this.activeLanguage = locale;
    this.handleAuthUpdate = this.handleAuthUpdate.bind(this);
    this.store.select(selectActiveUserDetails).subscribe((userData) => (this.userData = userData));
    // methods used for simulating refresh token failure
    // should only be available on QA or locally
    if (printInfoLogs) {
      (<any>window).__setSimulateTokenRefreshFailure = (flagValue) => {
        this.simulateTokenRefreshFailure = flagValue;
        // opening dialog from service constructor doesn't detect changes
        // this causes dialog component to not fire ngOnInit
        // note: only when calling the setTokenRefreshTimeout method from the window interface
        this.zone.run(() => this.setTokenRefreshTimeout(undefined, 8000));
      };
      (<any>window).__setSimulateTokenExpired = (flagValue) => {
        this.simulateTokenExpired = flagValue;
      };
    }
  }

  checkAuthentication() {
    // work with only one observable here. on complete we reset it,
    // but until then everyone should wait for the same auth resolve
    if (this.restAuthCheck$) {
      return this.restAuthCheck$;
    }

    this.restAuthCheck$ = new Observable((observer: Observer<any>) => {
      // if we don't have user data, nothing we can do
      if (!this.userData) {
        throw new Error('No user data, cannot restore user session!');
      }

      // if the auth token is OK, continue
      const authTokenHasExpired = this.isAuthTokenExpired();
      if (!authTokenHasExpired) {
        observer.next(true);
        this.restAuthCheck$ = null;
        observer.complete();
        return;
      }

      this.refreshAuthToken()
        .pipe(
          tap(() => this.store.dispatch(new LoadActiveUserDetailsAction())),
          take(1),
          delay(4)
        )
        .subscribe((userData) => {
          observer.next(true);
          this.restAuthCheck$ = null;
          observer.complete();
        });
    });
    return this.restAuthCheck$;
  }

  async loadActiveIfValid(): Promise<ActiveUserTokenState> {
    /*
      i18nActiveUser is used in case of remember me functionality is not selected on login screen
      in case of language switching software refresh will happen because window.location.href will refresh the page
      in order to stop system from exiting to login screen we have temporary data in local storage for i18n (i18nActiveUser)
      Setting of i18nActiveUser is done with this.storageService.setI18nActiveUserData(userData) - search project to check where i18nActiveUser is set
    */
    const i18nActiveUser = this.storageService.getI18nActiveUserData();
    const userFromStore = this.storageService.loadUserFromStore();
    const activeUser = i18nActiveUser || userFromStore;

    if (!activeUser) {
      return ActiveUserTokenState.NoToken;
    }

    const tokenData: any = this.decode(activeUser.token);

    // TODO add tolerances
    const tokenExpiresAt = tokenData.exp * 1000;
    // taking TOKEN_EXPIRE_TOLERANCE away from tokenExpiresAt means we do updates sooner
    const authTokenHasExpired = tokenExpiresAt - TOKEN_EXPIRE_TOLERANCE < Date.now();

    // adding TOKEN_EXPIRE_TOLERANCE makes the token look older, hence we execute updates sooner
    const refreshTokenAge = Date.now() - activeUser.refreshTokenCreatedAt + TOKEN_EXPIRE_TOLERANCE;
    const refreshTokenHasExpired: boolean = refreshTokenAge > environment.refreshTokenMaxAge;

    // don't load the user data if the tokens have expired
    if (authTokenHasExpired && refreshTokenHasExpired) {
      this.storageService.clearUserFromStore();
      return ActiveUserTokenState.Expired;
    }

    if (!authTokenHasExpired && !refreshTokenHasExpired) {
      this.activateUserFromStore(activeUser, tokenData);
      this.nextAuthDataState();
      // set the next token update period compensating for token age
      const tokenTTL = tokenData.exp * 1000 - Date.now();
      this.setTokenRefreshTimeout(tokenTTL);
      // load showTaxonomyPathFlag
      this.store.dispatch(new LoadTaxonomyConfigurationsAction());
      this.store.dispatch(new LoadActiveUserDetailsAction());
      return ActiveUserTokenState.Valid;
    }

    return this.refreshAuthToken(tokenData.accountId, activeUser.refreshToken)
      .toPromise()
      .then(() => {
        // load showTaxonomyPathFlag
        this.store.dispatch(new LoadTaxonomyConfigurationsAction());
        this.store.dispatch(new LoadActiveUserDetailsAction());
        return ActiveUserTokenState.Valid;
      })
      .catch((err) => {
        console.error('Failed to refresh auth token!');
        return ActiveUserTokenState.Expired;
      });
  }

  activateUserFromStore(activeUser, tokenData = null) {
    tokenData = tokenData || this.decode(activeUser.token);
    this.isLoggedIn = true;
    this.authToken = activeUser.token;
    this.refreshToken = activeUser.refreshToken;
    this.refreshTokenCreatedAt = activeUser.refreshTokenCreatedAt;
    this.accounts = activeUser.accounts;
    this.userData = tokenData;
    this.store.dispatch(new LoggedInAction({ accounts: activeUser.accounts, userData: tokenData }));
  }

  setTokenRefreshTimeout(
    authTokenTTL = environment.authTokenMaxAge,
    refreshTokenDelayOverride = null
  ) {
    // if the auth and refresh tokens are ok, set timeout for token refresh before it expires
    clearTimeout(this.tokenRefreshTimeout);
    const refreshTokenDelay = authTokenTTL - environment.tokenRefreshThreshold;
    this.tokenRefreshTimeout = setTimeout(() => {
      this.refreshAuthToken().subscribe(
        () => this.store.dispatch(new RefreshAccountSettingsAction()),
        () => console.warn('Failed to refresh token after an interval!')
      );
    }, refreshTokenDelayOverride || refreshTokenDelay);
  }

  login(credentials, options = { doRedirect: true, setRememberMe: true }) {
    if (options.setRememberMe) {
      localStorage.setItem('rememberMe', credentials.rememberMe);
    }

    return this.http.post(this.glideUsersUrl + 'auth', credentials, this.authRequestConfig).pipe(
      map(this.handleAuthUpdate),
      tap((userData) => {
        if (options.doRedirect) {
          this.getAuthDataStream().pipe(
            mergeMap((tokenData) =>
              this.store.select(getAccountSettings).pipe(
                map((settings) => ({ settings, tokenData })),
                take(1)
              )
            ),
            take(1)
          ).subscribe(({tokenData}) => {

            const { accountId } = tokenData;

            const preferredLanguage = accountId ? this.getPreferredLanguage(accountId) : 'en';
            if(!credentials.rememberMe) {
              this.storageService.setI18nActiveUserData(userData);
            }


            if (preferredLanguage === 'en') {
              if(preferredLanguage === this.activeLanguage) {
                this.router.navigateByUrl(this.redirectUrl || '/dashboard');
              } else {
                window.location.href = this.redirectUrl || '/dashboard';
              }
            } else {
              if(preferredLanguage === this.activeLanguage) {
                this.router.navigateByUrl(this.redirectUrl || '/dashboard');
              } else {
                window.location.href = `/${preferredLanguage}${this.redirectUrl || '/dashboard'}`;
              }
        }
          });
        }
        this.setTokenRefreshTimeout();
      })
    );
  }

  getPreferredLanguage(accountId) {
    const userPreferenceLanguage = this.userPreferencesService.getUserPreference('i18n.defaultLocale');
    const accountSettings = this.storageService.loadAccountSettingsFromStore(accountId)
    const defaultLocale = get(accountSettings, 'i18n.defaultLocale', 'en');
    const preferredLanguage = userPreferenceLanguage || defaultLocale;
    return preferredLanguage;
  }

  // refresh auth token, or change active account if valid accountId is provided
  refreshAuthToken(accountId = null, refreshToken = null) {
    const tokenExpired = this.isSessionRefreshTokenExpired();
    const shouldAttemptToExtendSession = this.isUserLoggedIn() && tokenExpired;
    if (shouldAttemptToExtendSession) {
      return this.extendSession();
    }

    const noLoadedUserAuthData = !this.userData || !this.refreshToken;
    const noPassedInUserAuthData = !refreshToken || !accountId;
    const noValidDataForRefresh = noLoadedUserAuthData && noPassedInUserAuthData;
    if (noValidDataForRefresh) {
      throw new Error(`Cannot execute refresh auth token for users without valid auth data!`);
    }

    this.loginAccountId = accountId;
    const body = {
      accountId: accountId || (this.userData && this.userData.accountId),
      refreshToken: refreshToken || this.refreshToken,
    };
    return this.http
      .post(this.glideUsersUrl + 'auth/refresh-token', body, this.authRequestConfig)
      .pipe(
        switchMap((res) => {
          if (this.simulateTokenRefreshFailure) {
            const message = this.simulateTokenExpired
              ? 'The auth refresh token provided has expired'
              : 'QA Synthetic Error';
            const errorResponse = new HttpErrorResponse({
              error: { code: `ERROR`, message },
              status: 500,
              statusText: 'Bad Request',
            });
            return throwError(errorResponse);
          }
          return of(res);
        }),
        // error ocurred, analyze before throwing it
        retryWhen((error) =>
          error.pipe(
            scan((acc, innerError) => {
              const { message } = innerError.error;
              console.log(`Attempt ${acc}: ${message}`);
              if (acc === this.maxTokenRefreshRetries || message.match(tokenExpiredRegex)) {
                throw innerError;
              }
              return acc + 1;
            }, 1),
            take(this.maxTokenRefreshRetries),
            delayWhen((val) => {
              console.log(`Retrying request, with ${val * this.retryDelay} delay`);
              return timer(val * this.retryDelay);
            })
          )
        ),
        catchError((response) => {
          // token expired, ask user to enter password
          if (response.error.message.match(tokenExpiredRegex)) {
            this.simulateTokenRefreshFailure = false;
            const activeUser = this.storageService.loadUserFromStore();
            return this.extendSession(activeUser?.ssoLogin);
          }
          // is token still valid
          if (!this.isSessionRefreshTokenExpired()) {
            // implement manual refresh
            this.simulateTokenRefreshFailure = false;
            return this.refreshTokenDialog(body);
          }
          // we ran out of retries and error still not resolved
          this.logout();
          this.snackBar.open($localize`Your session has expired!`, $localize`Close`, { duration: 20000 });
          return of(false);
        }),
        map(this.handleAuthUpdate),
        // ensure continuous update op
        tap(() => this.setTokenRefreshTimeout())
      );
  }

  // handle the case when user session runs out but they are still working
  // in this case open a modal requiring users to re-enter their password for normal users
  // or show sso login for sso users
  extendSession(ssoLogin = false) {
    if (this.refreshTokenExtend$) {
      return this.refreshTokenExtend$;
    }
    this.refreshTokenExtend$ = new Observable((observer: Observer<any>) => {
      this.refreshTokenExtendDialog$ =
        this.refreshTokenExtendDialog$ ||
        this.dialog.open(ExtendSessionDialogComponent, {
          data: { username: this.userData.username, accountId: this.getUserAccountId(), accountSlug: this.getAccountSlug(), ssoLogin },
          width: '400px',
          backdropClass: 'gd-extend-session-dialog__backdrop',
          autoFocus: true,
          disableClose: true,
        });
      // get the password from the extend session dialog
      this.refreshTokenExtendDialog$.afterClosed().subscribe((loginResult) => {
        this.refreshTokenExtendDialog$ = null;
        // exit loop if password is not provided in modal
        if (loginResult === null) {
          this.logout();
          this.snackBar.open($localize`Your session has expired!`, $localize`Close`, { duration: 20000 });
          observer.next(false);
        } else {
          // login after the user closes the modal
          this.handleAuthUpdate(loginResult);
          this.setTokenRefreshTimeout();
          this.store.dispatch(new LoadActiveUserDetailsAction());
          observer.next(true);
        }
        observer.complete();
        this.refreshTokenExtend$ = null;
      });
    });
    return this.refreshTokenExtend$;
  }

  getAccountSlug() {
    return this.userData?.accountSlug || extractAccountSlugFromHostname();
  }

  // handle the case when refresh token is still valid and refresh token request failed
  // in this case open modal requiring users to refresh it manually
  refreshTokenDialog(authRequestBody) {
    if (this.refreshToken$) {
      return this.refreshToken$;
    }
    this.refreshToken$ = new Observable((observer: Observer<any>) => {
      this.refreshTokenDialog$ =
        this.refreshTokenDialog$ ||
        this.dialog.open(RefreshTokenDialogComponent, {
          data: { authRequestBody: authRequestBody, username: (this.userData && this.userData.username) || null },
          width: '400px',
          backdropClass: 'gd-refresh-token-dialog__backdrop',
          autoFocus: true,
          disableClose: true,
        });
      // get refresh token data from dialog
      this.refreshTokenDialog$.afterClosed().subscribe((refreshTokenData) => {
        this.refreshTokenDialog$ = null;
        // exit if refresh is not initiated | clicked cancel
        if (refreshTokenData === null) {
          this.logout();
          this.snackBar.open($localize`Your session has expired!`, $localize`Close`, { duration: 20000 });
          observer.next(false);
        } else {
          // update auth data
          this.handleAuthUpdate(refreshTokenData);
          this.setTokenRefreshTimeout();
          observer.next(true);
        }
        observer.complete();
        this.refreshToken$ = null;
      });
    });
    return this.refreshToken$;
  }

  // TODO refactor this into a ngrx side effect
  handleAuthUpdate(response) {
    if (response.status !== 'SUCCESS') {
      throw new Error(response.message);
    }
    if (!!this.loginAccountId) {
      // Check if account valid
      const tokenAccounts = response.data.accounts;
      const accountExists = tokenAccounts.find(
        (account) => account.id === +this.loginAccountId
      );
      this.loginAccountId = null;

      // fallback account check:
      if(!accountExists && tokenAccounts.lngth !== 0) {
        const fallbackAccount = tokenAccounts[0];
        this.fallbackAccountId = fallbackAccount.id;
        this.fallbackAccountSlug = fallbackAccount.slug;
        // note: this error mesage string is ued in login guard too
        throw new Error('Failed to access account!');
      }

      if (!accountExists) {
        this.snackBar.open($localize`Account does not exist`, $localize`Close`, { duration: 5000 });
        this.router.navigate(['auth/login']);
        throw new Error('Account does not exist');
      }
    }

    // same token, no need for update
    if (
      response.data.accessToken === this.authToken &&
      response.data.refreshToken === this.refreshToken
    ) {
      return this.userData;
    }

    this.isLoggedIn = true;
    this.authToken = response.data.accessToken;
    this.accounts = response.data.accounts;

    // did you get a new token? if no - don't refresh this
    this.refreshTokenCreatedAt =
      response.data.refreshToken === this.refreshToken ? this.refreshTokenCreatedAt : Date.now();

    this.refreshToken = response.data.refreshToken;
    // save the token into the local store
    const userData = {
      token: this.authToken,
      accounts: this.accounts,
      refreshToken: this.refreshToken,
      refreshTokenCreatedAt: this.refreshTokenCreatedAt,
    };

    const tokenData: any = this.decode(userData.token);
    this.userData = tokenData;

    this.nextAuthDataState();
    this.store.dispatch(new LoggedInAction({ accounts: this.accounts, userData: tokenData }));
    const oldUserData = this.storageService.loadUserFromStore();
    if (oldUserData) {
      userData['ssoLogin'] = oldUserData.ssoLogin;
    }
    this.storageService.saveUserInStore(userData);
    return userData;
  }

  logout(options = { redirectToLogin: true, resetRedirectUrl: true }) {
    // TODO utilize the logout endpoint on users
    this.authDataStream.next(null);
    this.storageService.clearUserFromStore();

    this.isLoggedIn = false;
    clearTimeout(this.tokenRefreshTimeout);
    this.loginAccountId = null;
    this.refreshToken = null;
    this.userData = null;

    if (options.resetRedirectUrl) {
      setTimeout(() => (this.redirectUrl = null), 200);
    }

    if (options.redirectToLogin) {
      this.router.navigate(['auth/login']);
    }
  }

  getUserId() {
    return (this.userData && +this.userData.userId) || null;
  }

  isUserSuperadmin() {
    return this.userData?.username === 'superadmin';
  }

  getUserAccountId() {
    return (this.userData && +this.userData.accountId) || null;
  }

  isUserLoggedIn() {
    return this.isLoggedIn;
  }

  decode(token) {
    const parts = token.split('.');
    let decodedToken: any = atob(parts[1]);
    decodedToken = JSON.parse(decodedToken);

    // handle zipped token transparently, unzip and merge with the token body
    if (decodedToken.zip) {
      const buffer = base64ToArrayBuffer(decodedToken.zip);
      const decompressedToken = ungzip(buffer, { to: 'string' });
      const decompressedTokenBody = JSON.parse(decompressedToken);
      decodedToken = { ...decodedToken, ...decompressedTokenBody };
      delete decodedToken.zip;
    }

    decodedToken.taxonomyPermissions = this.decodeTaxonomyPermissions(
      decodedToken.taxonomyPermissions
    );

    return decodedToken;
  }

  decodeTaxonomyPermissions(taxonomyPermissions: any[]): number[] {
    // fallback for malformed taxonomy permission array
    if (!taxonomyPermissions || taxonomyPermissions.length === 0) {
      return [0];
    }

    // if we have numbers, this is the old style of taxonomyPermission
    // so just return the unchanged taxonomyPermissions array
    if (isNumber(taxonomyPermissions[0])) {
      return taxonomyPermissions;
    }

    // decode ranges and return results
    return this.unfoldIdRanges(taxonomyPermissions);
  }

  private unfoldIdRanges(encodedRanges: string[]) {
    const ids = [];
    const encodedRangesLength = encodedRanges.length;
    for (let i = 0; i < encodedRangesLength; i++) {
      const range = encodedRanges[i];
      // if there is not `-` separator, this is just id as a string
      if (!range.includes('-')) {
        ids.push(+range);
        continue;
      }

      // else get parts around the separator and treat them as start
      // and end of the id sequence, then generate the sequence
      const [start, end] = range.split('-').map((n) => +n);
      for (let i = start; i <= end; i++) {
        ids.push(i);
      }
    }
    return ids;
  }

  nextAuthDataState() {
    this.authDataStream.next({
      authToken: this.authToken,
      userId: +this.userData.userId,
      accountId: +this.userData.accountId,
    });
  }

  getAuthDataStream() {
    return this.authDataStream.asObservable();
  }

  public isSessionRefreshTokenExpired(): boolean {
    // adding TOKEN_EXPIRE_TOLERANCE makes the token look older, hence we execute updates sooner
    const refreshTokenAge = Date.now() - this.refreshTokenCreatedAt + TOKEN_EXPIRE_TOLERANCE;
    const refreshTokenHasExpired = refreshTokenAge > environment.refreshTokenMaxAge;
    return refreshTokenHasExpired;
  }

  public isAuthTokenExpired(): boolean {
    const tokenExpiresAt = this.userData.exp * 1000;
    // taking TOKEN_EXPIRE_TOLERANCE away from tokenExpiresAt means we do updates sooner
    const authTokenHasExpired = tokenExpiresAt - TOKEN_EXPIRE_TOLERANCE < Date.now();
    return authTokenHasExpired;
  }

  getUserTaxonomyPermissions() {
    return this.userData ? this.userData.taxonomyPermissions : [];
  }

  // this returns a map of taxonomy permissions for faster lookup
  getUserTaxonomyPermissionsMap() {
    const taxonomyPermissions: number[] = this.userData ? this.userData.taxonomyPermissions : [];
    return taxonomyPermissions.reduce((acc, tp) => {
      acc[tp] = true;
      return acc;
    }, {});
  }

  sendResetPasswordEmail(email) {
    return this.http.post(this.glideUsersUrl + 'reset-password/request', email);
  }

  resetPassword(password, resetPasswordToken, options = { doRedirect: true }) {
    return this.http
      .post(this.glideUsersUrl + `reset-password/${resetPasswordToken}`, { password: password })
      .pipe(
        map(this.handleAuthUpdate),
        tap(() => {
          if (options.doRedirect) {
            this.router.navigateByUrl(this.redirectUrl || '/');
          }
          this.setTokenRefreshTimeout();
        })
      );
  }
}
