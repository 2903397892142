<div class="gd-embed-content-panel-dialog" [dir]="dir$ | async">
  <form fxLayout="column"
        fxLayoutAlign="space-around stretch"
        [formGroup]="embeddableForm"
        (ngSubmit)="save()"
        novalidate>
    <div style="display:flex; flex-direction: row; justify-content: space-between;">
      <h2 mat-dialog-title i18n="Modal headline" *ngIf="!dialogInputData.editingMode">Insert Content Panel</h2>
      <h2 mat-dialog-title i18n="Modal headline" *ngIf="dialogInputData.editingMode">Edit Content Panel</h2>
      <div style="display:flex; flex-direction: row; padding-top: 4px">
        <mat-icon *ngIf="embeddableForm.get('id').value && dialogInputData.editingMode"
                  matTooltip="Copy Content Panel block ID into clipboard"
                  style="cursor: pointer;"
                  fontSet="far"
                  fontIcon="fa-copy"
                  (click)="clipboardCopyId(dialogInputData.blockId)">
        </mat-icon>
        <mat-icon matTooltip="Open in new tab"
                  i18n-matTooltip
                  style="cursor: pointer;"
                  *ngIf="embeddableForm.get('id').value"
                  (click)="openInNewTab(embeddableForm.get('id').value)">open_in_new</mat-icon>
      </div>
    </div>
      <div class="gd-embed-content-panel-dialog__scrollable-area">
        <section>
      <mat-form-field class="gd-embed-content-panel-dialog__field"
                      appearance="outline"
                      floatLabel="always">
        <mat-label i18n>Content Panel</mat-label>
        <mat-select formControlName="id"
                    i18n-placeholder
                    placeholder="Select Content Panel">
          <mat-option *ngFor="let cp of contentPanels$ | async"
                      [value]="cp.id"
                      (click)="selectContentPanel(cp)">
            {{ cp.name }}
          </mat-option>
        </mat-select>
        <mat-error i18n="Validation message">Content panel is required</mat-error>
      </mat-form-field>
      <p *ngIf="(contentPanels$ | async).length === 0"
         i18n
         style="margin-top: 16px">
        There are no created content panels.
      </p>
        </section>

        <section *ngIf="embeddableForm.get('id').value">
      <ng-container *ngIf="customFieldsConfig; else noConfigRequired">
        <div [style.margin-bottom]="'10px'" i18n>Custom Data</div>
        <gd-dynamic-widget-form [formConfig]="customFieldsConfig"
                                [widgetFormGroup]="configurationControl"
                                [initialValues]="initialValues"
                                [isEditingMode]="!loadDefaultValues"
                                [usage]="dialogInputData.glideContentType"
                                [activeLocale]="dialogInputData.activeLocale">
        </gd-dynamic-widget-form>
      </ng-container>

      <ng-template #noConfigRequired>
        <div [style.margin-bottom]="'10px'" i18n="There are no custom data fields that allow additional config">No configuration needed.</div>
      </ng-template>
        </section>
      </div>

    <section class="gd-embed-content-panel-dialog__section-buttons">
      <button mat-raised-button
              color="primary"
              type="submit"
              [disabled]="embeddableForm.invalid">
              <span [matTooltip]="getSaveButtonTooltipMessage()"
              [matTooltipDisabled]="!embeddableForm.get('id').value || embeddableForm.valid"
              i18n>
          Save
        </span>
      </button>
      <button mat-raised-button
              color="danger"
              i18n
              mat-dialog-close>Cancel</button>
    </section>
  </form>
</div>
