import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AccountSettingsService } from '../../core/api/account-settings/accounts-settings.service';

@Pipe({
  name: 'gdDateTransform',
  pure: false
})
export class DateTransformPipe implements PipeTransform {
  activeTimezone = this.accountSettingsService.getTimezone();
  defaultFormat = this.accountSettingsService.getShortDateFormat() || 'DD-MMM-YYYY h:mm A';

  constructor(private accountSettingsService: AccountSettingsService) {
  }

  transform(value: any, format = null, timezone = null): any {
    if (!value) {
      return '';
    }
    format = format || this.defaultFormat;
    timezone = timezone || this.activeTimezone;
    return moment(value).tz(timezone).format(format);
  }

}
