import { UnsafeAction } from '../unsafe-action.interface';
import {
  PageTemplate, TemplateNode,
  TemplateRevisionDesignation,
  NewTemplateId
} from './templates.model';
import {
  SET_ACTIVE_TEMPLATE_ID, LOAD_TEMPLATES, GET_TEMPLATES,
  SET_ACTIVE_TEMPLATE_REVISION_DESIGNATION,
  LOAD_NEW_EMPTY_TEMPLATE, CLEAR_NEW_EMPTY_TEMPLATE, CREATE_TEMPLATE_SUCCESS,
  CREATE_NODE, CREATE_NODE_COLLECTION, UPDATE_NODE_DATA, UPDATE_NODE_POSITION,
  DELETE_NODE, UPDATE_ACTIVE_TEMPLATE_DATA, UPDATE_TEMPLATE, PUBLISH_TEMPLATE,
  UNPUBLISH_TEMPLATE, UPDATE_TEMPLATE_SUCCESS, PUBLISH_TEMPLATE_SUCCESS, SET_TEMPLATE_FILTER,
  UNPUBLISH_TEMPLATE_SUCCESS,
  SET_TEMPLATES_LOADING_FLAG
} from './templates.actions';
import { cloneDeep } from 'lodash-es';
import { CREATE_TEMPLATE, DELETE_TEMPLATE, DELETE_TEMPLATE_COMPLETED } from './templates.actions';
import { activeTemplateRevisionReducer } from './active-template-revision.reducer';


export interface PageTemplatesMap {
  [key: number]: PageTemplate;
}



export interface TemplatesState {
  // DATA - reflection of DB state
  templates: { [key: number]: PageTemplate };
  // UI - generated for FE manipulation and viewing
  loaded: boolean;
  loading: boolean;
  activeTemplateId: string;
  activeTemplate: PageTemplate;
  activeRevisionDesignation: TemplateRevisionDesignation;
  nameFilter: string;
  workingRevisionModified: boolean;
}

export const templatesInitialState: TemplatesState = {
  // DATA
  templates: null,
  // UI
  loaded: false,
  loading: false,
  activeTemplateId: null,
  activeTemplate: null,
  activeRevisionDesignation: TemplateRevisionDesignation.Published,
  // filters and flags
  nameFilter: '',
  workingRevisionModified: false
};

export function templatesReducer(state = templatesInitialState, action: UnsafeAction): TemplatesState {

  switch (action.type) {


    // active tempalte updates are defered to sub reducer
    case CREATE_NODE:
    case CREATE_NODE_COLLECTION:
    case UPDATE_NODE_DATA:
    case UPDATE_NODE_POSITION:
    case DELETE_NODE: {
      const activeRevision = resolveActiveRevision(state.activeTemplate, state.activeRevisionDesignation);
      const activeTemplateUpdated = { ...state.activeTemplate };
      const activeRevisionUpdated = activeTemplateRevisionReducer(activeRevision, action);
      // update working revision nodes
      if (TemplateRevisionDesignation.Working === state.activeRevisionDesignation) {
        activeTemplateUpdated.workingRevision = activeRevisionUpdated;
      }
      if (TemplateRevisionDesignation.Published === state.activeRevisionDesignation) {
        activeTemplateUpdated.publishedRevision = activeRevisionUpdated;
      }
      return {
        ...state,
        workingRevisionModified: true,
        activeTemplate: activeTemplateUpdated
      };
    }

    case UPDATE_ACTIVE_TEMPLATE_DATA: {
      const templateDetails = action.payload;
      if (templateDetails.parentId === 'ROOT') {
        templateDetails.parentId = null;
      }
      const updateActiveTemplate: PageTemplate = {
        ...state.activeTemplate,
        ...templateDetails
      };
      return {
        ...state,
        activeTemplate: updateActiveTemplate
      };
    }

    case UPDATE_TEMPLATE:
    case PUBLISH_TEMPLATE:
    case UNPUBLISH_TEMPLATE:
    case DELETE_TEMPLATE:
    case CREATE_TEMPLATE:
    case GET_TEMPLATES: {
      return { ...state, loading: true };
    }

    case LOAD_TEMPLATES: {
      const templates = action.payload
        .reduce((acc, template: PageTemplate) => {
          acc[template.id] = template;
          return acc;
        }, {});

      if (state.templates && state.templates[NewTemplateId]) {
        templates[NewTemplateId] = state.templates[NewTemplateId];
      }

      const activeTemplateData = state.activeTemplateId && templates
        && templates[state.activeTemplateId];

      return {
        ...state,
        ...loadActiveTemplate(activeTemplateData, state.activeRevisionDesignation),
        templates,
        loaded: true,
        loading: false
      };
    }

    // for creating new templates
    case LOAD_NEW_EMPTY_TEMPLATE: {
      const newTemplates = {
        ...(state.templates || {}),
        [NewTemplateId]: createNewTemplate()
      };
      return {
        ...state,
        templates: newTemplates
      };
    }

    case CLEAR_NEW_EMPTY_TEMPLATE: {
      if (!state.templates) {
        return state;
      }
      const newTemplates = { ...state.templates };
      delete newTemplates[NewTemplateId];
      return {
        ...state,
        templates: newTemplates
      };
    }

    case CREATE_TEMPLATE_SUCCESS: {
      const newTemplate: PageTemplate = action.payload;
      const templates = {
        ...state.templates,
        [newTemplate.id]: newTemplate
      };

      return {
        ...state,
        templates,
        loading: false,
        workingRevisionModified: false
      };
    }


    case UNPUBLISH_TEMPLATE_SUCCESS:
    case PUBLISH_TEMPLATE_SUCCESS:
    case UPDATE_TEMPLATE_SUCCESS: {

      const updatedTemplate: PageTemplate = action.payload;
      const templates = {
        ...state.templates,
        [updatedTemplate.id]: updatedTemplate
      };

      const revisionToLoad = action.type === PUBLISH_TEMPLATE_SUCCESS
        ? TemplateRevisionDesignation.Published
        : state.activeRevisionDesignation;

      return {
        ...state,
        templates,
        ...loadActiveTemplate(updatedTemplate, revisionToLoad),
        loading: false,
        workingRevisionModified: false
      };
    }

    case SET_ACTIVE_TEMPLATE_ID: {
      const activeTemplateId = action.payload;

      const activeTemplateData = activeTemplateId && state.templates
        && state.templates[activeTemplateId];

      return {
        ...state,
        ...loadActiveTemplate(activeTemplateData),
        activeTemplateId,
        workingRevisionModified: false
      };
    }

    case SET_ACTIVE_TEMPLATE_REVISION_DESIGNATION: {
      const activeRevisionDesignation = action.payload;
      const activeTemplateData = state.activeTemplateId && state.templates
        && state.templates[state.activeTemplateId];

      return {
        ...state,
        ...loadActiveTemplate(activeTemplateData, activeRevisionDesignation),
        workingRevisionModified: false
      };
    }

    case SET_TEMPLATE_FILTER: {
      const nameFilter = action.payload;
      return { ...state, nameFilter };
    }

    case DELETE_TEMPLATE_COMPLETED: {
      const deletedTemplateId = action.payload;
      const templates = { ...state.templates };
      delete templates[deletedTemplateId];
      return {
        ...state,
        templates,
        loading: false
      };
    }

    case SET_TEMPLATES_LOADING_FLAG: {
      const loading = action.payload;
      return {
        ...state,
        loading
      };
    }

    default:
      return state;
  }

}

// create a copy of active template to work on
function loadActiveTemplate(activeTemplateData: PageTemplate, activeRevisionDesignation = TemplateRevisionDesignation.Published) {
  if (!activeTemplateData) {
    return {};
  }
  // load in relevant active template revision
  const activeTemplate: PageTemplate = cloneDeep(activeTemplateData);
  // switch to working revision if the loaded template has no published revision
  activeRevisionDesignation = activeTemplate.publishedRevision && activeRevisionDesignation
    || TemplateRevisionDesignation.Working;
  return {
    activeRevisionDesignation,
    activeTemplate
  };
}

function resolveActiveRevision(activeTemplate: PageTemplate, activeRevisionDesignation: TemplateRevisionDesignation) {
  return activeRevisionDesignation === TemplateRevisionDesignation.Working
    ? activeTemplate.workingRevision
    : activeTemplate.publishedRevision;
}

function createNewTemplate() {
  const newTemplate: PageTemplate = {
    id: NewTemplateId,
    parentId: null,
    label: '',
    additionalItems: {},
    publishedRevision: null,
    workingRevision: {
      blocks: [],
      widgets: []
    },
    rootComponent: 'DEFAULT'
  };
  return newTemplate;
}

export function sortNodesByPosition(a: TemplateNode, b: TemplateNode) {
  return a.position - b.position;
}
