import { Injectable } from '@angular/core';
interface optionsParameters {
  reApply?: boolean;
  async?: boolean;
  resolveFn?: any;
  rejectFn?: any;
  id?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ScriptInjectorService {
  loadedScripts = {};

  constructor() {}

  injectScript(url, options: optionsParameters = { reApply: false, async: true, resolveFn: null, rejectFn: null, id: 'default' }) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.onload = (options.resolveFn && options.resolveFn(resolve, reject)) || resolve;
      script.onerror = (options.rejectFn && options.rejectFn(resolve, reject)) || reject;
      script.async = options.async;
      script.src = url;
      script.id = options.id;
    });
  }
}
