import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { RestService } from '../rest.service';

@Injectable()
export class SystemNotificationService {
  constructor(private rest: RestService) {}

  getSystemNotifications(pageOptions) {
    let requestPath = `system-notifications?page=${pageOptions.currentPage || 0}&size=${
      pageOptions.pageSize || defaultPageSize
    }`;
    return this.rest.get(requestPath);
  }

  getSystemNotification(id: number) {
    return this.rest.get(`system-notifications/${id}`).pipe(map((response) => response.data));
  }

  createSystemNotification(payload) {
    return this.rest.post('system-notifications', payload).pipe(map((response) => response.data));
  }

  updateSystemNotification(payload) {
    return this.rest
      .put(`system-notifications/${payload.id}`, payload)
      .pipe(map((response: any) => response.data));
  }

  cancelSystemNotification(payload) {
    return this.rest
      .put(`system-notifications/${payload.id}`, { ...payload, canceled: true })
      .pipe(map((response: any) => response.data));
  }

  deleteSystemNotification(id) {
    return this.rest.delete(`system-notifications/${id}`);
  }
}
