import {debounceTime} from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription , Observable } from 'rxjs';
import { GlideContentActionBusService } from '../../../core/body-content/glide-content-actions-bus.service';
import { BidiService } from '../../../core/i18n/bidi.service';

declare const $: any;

@Component({
  selector: 'gd-embed-vimeo-dialog',
  templateUrl: './embed-vimeo-dialog.component.html',
  styleUrls: ['./embed-vimeo-dialog.component.scss']
})
export class EmbedVimeoDialogComponent implements OnInit, OnDestroy {

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: [''],
    src: [''],
    description: [''],
    autoplay: [false],
    height: [''],
    width: ['']
  });

  blockId;
  isValidVideoId = false;
  idSubscription: Subscription;
  videoContainer;

  editingMode = false;
  itemDetails = null;
  patchedValue = false;
  validYouTubeId = null;
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  get idControl() {
    return this.embeddableForm.get('id');
  }

  get nameControl() {
    return this.embeddableForm.get('name');
  }

  get descriptionControl() {
    return this.embeddableForm.get('description');
  }

  get srcControl() {
    return this.embeddableForm.get('src');
  }

  get vimeoAutoplayControl() {
    return this.embeddableForm.get('autoplay');
  }

  get vimeoHeightControl() {
    return this.embeddableForm.get('height');
  }

  get vimeoWidthControl() {
    return this.embeddableForm.get('width');
  }

  dialogInputFormData = {
    id : '',
    name: '',
    src: '',
    description: '',
    autoplay: false,
    height: '',
    width: ''
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedVimeoDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private actionBus: GlideContentActionBusService,
    private bidiService: BidiService,
  ) { }

  ngOnInit(): void {
    this.editingMode = this.dialogInputData['editingMode'] ? true : false;
    this.videoContainer = document.getElementById('video-preview');
    this.blockId = this.dialogInputData.blockId;
    if (this.editingMode && this.dialogInputData.id) {
      this.embeddableForm.patchValue(this.dialogInputData);
      this.validateVideoId(this.dialogInputData.id).subscribe(data => {
        this.videoContainer.innerHTML = data.html;
        const video = document.querySelector('#video-preview iframe');
        video.setAttribute('width', '100%');
        video.setAttribute('height', '100%');
        this.validYouTubeId = data.video_id;
        this.patchedValue = true;
        this.dialogInputFormData = {
          id: data.video_id,
          name: data.title,
          description: data.description,
          src: data.thumbnail_url,
          autoplay: false,
          height: data.height,
          width: data.width
        };
      });
     }

    this.idSubscription = this.idControl.valueChanges.pipe(debounceTime(400)).subscribe(
      id => {
        const validPatchedUrl = (this.validYouTubeId === this.idControl.value) && this.patchedValue;
        const emptyFormFlag = !this.idControl.value && this.idControl.hasError('required') && !this.itemDetails && !this.patchedValue;
        if (validPatchedUrl || emptyFormFlag) {
          return;
        }
        this.videoContainer.innerHTML = '';
        this.isValidVideoId = false;
        const videoId = this.extractVideoId(id);
        if (videoId !== id) {
          return this.idControl.setValue(videoId);
        }
        if (!videoId) {
          this.itemDetails = null;
          this.patchValue();
          this.idControl.setErrors({ 'required': true });
          return;
        }
        const glideContentType = this.dialogInputData.glideContentType;
        this.actionBus.setContentLoading(glideContentType, true);
        this.validateVideoId(id).subscribe(data => {
          this.actionBus.setContentLoading(glideContentType, false);
          if (!data) {
            this.itemDetails = null;
            this.patchValue();
            return this.idControl.setErrors({ 'invalidVideoId': true });
          }
          this.itemDetails = data;
          this.videoContainer.innerHTML = data.html;
          const video = document.querySelector('#video-preview iframe');
          video.setAttribute('width', '100%');
          video.setAttribute('height', '100%');
          this.isValidVideoId = true;
          this.validYouTubeId = data.video_id;
          this.idControl.setErrors(null);
          this.patchValue();
        });
      }
      );
    }

    validateVideoId(id: string): Observable<any> {
      const autoplayFlag =  this.dialogInputData.id === id ? this.vimeoAutoplayControl.value : false;
      return new Observable(observer => {
      const requestUrl = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + id + '&autoplay=' + autoplayFlag;
      // TODO replace this with either fetch or angular http client
      $.ajax({
        url: requestUrl,
        dataType: 'json',
        success: data => {
          observer.next(data);
          observer.complete();
        },
        error: () => {
          observer.next(null);
          observer.complete();
        }
      });
    });
  }

  save() {
    const formModel = {
      id: this.idControl.value,
      name: this.nameControl.value || this.dialogInputFormData['name'],
      description: this.descriptionControl.value || this.dialogInputFormData['description'],
      src: this.srcControl.value,
      autoplay: this.vimeoAutoplayControl.value,
      height: this.vimeoHeightControl.value,
      width: this.vimeoWidthControl.value
    };

    const contentNodeData = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel
    };

    this.dialogRef.close(contentNodeData);
    return;
  }

  ngOnDestroy(): void {
    if (this.idSubscription) {
      this.idSubscription.unsubscribe();
    }
  }

  patchValue() {
    if (this.itemDetails) {
      this.dialogInputFormData = {
        id: this.itemDetails.video_id,
        name: this.itemDetails.title,
        description: this.itemDetails.description,
        src: this.itemDetails.thumbnail_url,
        autoplay: false,
        height: this.itemDetails.height,
        width: this.itemDetails.width
      };
      this.embeddableForm.patchValue(this.dialogInputFormData);
      this.patchedValue = true;
      return;
    }
    this.embeddableForm.patchValue({
      name: null,
      description: null,
      autoplay: false,
    });

    this.dialogInputFormData = {...this.embeddableForm.value};
    this.patchedValue = false;
  }

  extractVideoId(url) {
    if (this.isVimeoUrl(url)) {
      return url.split('?')[0].split('/').pop();
    }
    return url;
  }

  isVimeoUrl(url) {
    const regex = /^(https?\:\/\/)?(www\.vimeo\.com|vimeo\.com)\/.+$/;
    return regex.test(url);
  }
}
