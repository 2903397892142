<div *ngIf="!readOnlyMode" class="gd-text-value-input">
  <mat-form-field class="gd-widget-types-build-form__custom-data-input"
                  [class.--error-field]="(fieldControl.invalid && fieldControl.touched && !textValueInputFormControl.touched)"
                  [class.--empty-required-field]="(dataSourceValue.length === 0 && fieldControl.hasError('required') && textValueInputFormControl.touched)"
                  appearance="outline">
    <mat-label>{{fieldConfig.label || 'Value'}}{{isFormControlRequired ? ' *' : ''}}</mat-label>
    <input matInput
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="false"
           (matChipInputTokenEnd)="addDataSource($event)"
           type="text"
           [disabled]="!hasUpdatePermissionFlag"
           [placeholder]="placeholderText"
           autocomplete="off"
           [formControl]="textValueInputFormControl"
           [style.width]="'95%'">
  </mat-form-field>
  <button mat-icon-button
          color="primary"
          [disabled]="!textValueInputFormControl.value.trim()"
          (click)="addDataSourceUsingButton(textValueInputFormControl.value)">
    <mat-icon fontSet="fal"
              fontIcon="fa-plus"></mat-icon>
  </button>
</div>
<div *ngIf="readOnlyMode"
     style="display: flex; flex-direction: row; align-items: center; margin-bottom: 12px; gap: 6px;">
  <div class="gd-field-builder__read-only-label">
    {{ fieldConfig?.label }} {{isFormControlRequired ? ' *' : ''}}</div>
  <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
            class="question-icon"
            [matTooltip]="infoTooltip"
            matTooltipPosition="above"
            fontSet="fal"
            fontIcon="fa-question-circle"
            style="color: #8e8e8e"></mat-icon>
</div>
<section fxFlex='100%'
         style="margin-bottom: 20px">
  <mat-chip-list matPrefix
                 #chipList
                 [sortablejs]="dataSourceValue" sortablejsContainer=".mat-chip-list-wrapper" [sortablejsOptions]="options"
                 [style]="readOnlyMode ? 'pointer-events: none;' : ''">
    <mat-chip *ngFor="let value of dataSourceValue; let i = index" ngx-sortablejs-item>
      {{value}}
      <mat-icon matChipRemove
                *ngIf="hasUpdatePermissionFlag && !readOnlyMode"
                (click)="removeDataSource(i)"
                fontSet="fal"
                fontIcon="fa-times-circle"></mat-icon>
    </mat-chip>
  </mat-chip-list>
</section>
