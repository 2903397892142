import {tap, debounceTime, catchError, mergeMap, map} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { LOGIN, LoggedInAction, LoginFailedAction, ACCOUNT_CHANGED, LOG_OUT, LogOutSuccessAction, LoadActiveUserDetailsAction, SEND_EMAIL, RESET_PASSWORD, SendEmailSuccessAction, ResetPasswordErrorAction, LOGGED_IN } from './auth.actions';
import { AuthService } from '../../api/auth/auth.service';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { UserPreferencesService } from '../../api/user-preferences/user-preferences.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-reducer';
import { LoadTaxonomyConfigurationsAction } from '../taxonomy-configuration/taxonomy-configuration.actions';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MixPanelService } from '../../api/mixpanel/mixpanel.service';
import { ClearArticleTypeAction } from '../article-type/article-type.actions';
import { ClearCollectionTypeAction } from '../collection-type/collection-types.actions';
import { AccountSettingsService } from '../../api/account-settings/accounts-settings.service';
import { isEqual } from 'lodash-es';
import { ResetSamlConfigAction } from '../sso/sso.actions';

@Injectable()
export class AuthEffects {


  login$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(LOGIN)).pipe(
    map((action: UnsafeAction) => action.payload),
    mergeMap((loginCredentials) =>
      this.authService
        .login(loginCredentials).pipe(
        map((userData: any) => {
          // load showTaxonomyPathFlag
          this.store.dispatch(new LoadTaxonomyConfigurationsAction());
          this.store.dispatch(new LoadActiveUserDetailsAction());

          this.mixPanelService.setProfile();
          return new LoggedInAction({ accounts: userData.accounts, userData: this.authService.decode(userData.token) });
        }),
        catchError((httpError: HttpErrorResponse) => {
          const err = httpError.error;
          return  of(new LoginFailedAction({ message: err && err.json && err.json().message || err, status: err.status || httpError.status }))
        }),)
    ),));


  logout$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(LOG_OUT)).pipe(
    debounceTime(100),
    map(() => {
      this.userPreferencesService.clearIsLockedFlags();
      this.authService.logout();
      return new LogOutSuccessAction();
    }),));


  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED)).pipe(
    tap(() => {
      this.store.dispatch(new ClearArticleTypeAction());
      this.store.dispatch(new ClearCollectionTypeAction());
      this.store.dispatch(new ResetSamlConfigAction());
    })), { dispatch: false });


  sendEmail$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(SEND_EMAIL)).pipe(
      map((action: UnsafeAction) => action.payload),
      mergeMap((email) =>
        this.authService
          .sendResetPasswordEmail(email).pipe(
            map((res: any) => {
              this.snackBar.open($localize`A recovery email has been sent. Please check you inbox.`, $localize`Close`, { duration: 2000 });
              return new SendEmailSuccessAction(res);
            }),
            catchError((httpError: HttpErrorResponse) => {
              this.snackBar.open($localize`Failed to send the recovery email.`, $localize`Close`, { duration: 2000 });
              const err = httpError.error;
              return of(new LoginFailedAction({ message: err && err.json && err.json().message || err, status: err.status }))
            }))
      )));


  resetPassword$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(RESET_PASSWORD)).pipe(
      map((action: UnsafeAction) => action.payload),
      mergeMap(({ password, resetPasswordToken }) =>
        this.authService
          .resetPassword(password, resetPasswordToken).pipe(
            map((userData: any) => {
              this.snackBar.open($localize`Your password has been changed successfully.`, $localize`Close`, { duration: 2000 });
              this.store.dispatch(new LoadTaxonomyConfigurationsAction());
              this.store.dispatch(new LoadActiveUserDetailsAction());
              return new LoggedInAction({ accounts: userData.accounts, userData: this.authService.decode(userData.token) });
            }),
            catchError((httpError: HttpErrorResponse) => {
              let errMsg;
              if (httpError.status === 401) {
                errMsg = $localize`Password update unsuccessful - the password reset link has expired.`
              }
              if (httpError.status === 404) {
                errMsg = $localize`Password update unsuccessful - the password reset link has already been used.`
              }
              if (httpError.status === 503 || !httpError.status) {
                errMsg = $localize`Password update unsuccessful - the service is temporarily unavailable.`
              }
              this.snackBar.open(errMsg, $localize`Close`, { duration: 4000 });

              const err = httpError.error;
              return of(new ResetPasswordErrorAction({ message: err && err.json && err.json().message || err, status: err.status }))
            }))
      )));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private userPreferencesService: UserPreferencesService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private mixPanelService: MixPanelService,
    private accountSettingsService: AccountSettingsService,
  ) { }
}
