import { Direction, Directionality } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BidiService {
  private uiLocaleCode: string;
  private contentLocaleCode: string;

  private direction = new BehaviorSubject(null);

  constructor() {}

  setUiLocaleCode(localeCode) {
    this.uiLocaleCode = localeCode;
    this.direction.next(this.getLocaleDirectionality(this.uiLocaleCode));
  }

  setContentLocaleCode(localeCode) {
    this.contentLocaleCode = localeCode;

    const uiLocaleDir = this.getLocaleDirectionality(this.uiLocaleCode);
    const contentLocaleDir = this.getLocaleDirectionality(this.contentLocaleCode);

    if (uiLocaleDir === 'rtl' || contentLocaleDir === 'rtl') {
      this.direction.next('rtl');
      return;
    }

    this.direction.next('ltr');
  }

  // remove the content locale override and fallback to active UI locale
  resetContentLocaleCode() {
    this.contentLocaleCode = this.uiLocaleCode;
    this.direction.next(this.getLocaleDirectionality(this.uiLocaleCode));
  }

  getEffectiveLocaleDirectionality() {
    return this.direction.asObservable().pipe(
      filter((dir) => !!dir),
      distinctUntilChanged()
    );
  }

  getLocaleDirectionality(localeCode: string): Direction {
    return this.isLocaleRtl(localeCode) ? 'rtl' : 'ltr';
  }

  // this list is generated by the script in `/scripts/locale-dir.js
  isLocaleRtl(localeCode: string): boolean {
    return !!{
      ar: true,
      'ar-DZ': true,
      'ar-BH': true,
      'ar-TD': true,
      'ar-KM': true,
      'ar-DJ': true,
      'ar-EG': true,
      'ar-ER': true,
      'ar-IQ': true,
      'ar-IL': true,
      'ar-JO': true,
      'ar-KW': true,
      'ar-LB': true,
      'ar-LY': true,
      'ar-MR': true,
      'ar-MA': true,
      'ar-OM': true,
      'ar-PS': true,
      'ar-QA': true,
      'ar-SA': true,
      'ar-SO': true,
      'ar-SS': true,
      'ar-SD': true,
      'ar-SY': true,
      'ar-TN': true,
      'ar-AE': true,
      'ar-YE': true,
      ku: true,
      'ku-Arab': true,
      'ku-Arab-IQ': true,
      dv: true,
      'dv-MV': true,
      he: true,
      'he-IL': true,
      'ku-Arab-IR': true,
      'mzn-IR': true,
      nqo: true,
      'nqo-GN': true,
      ps: true,
      'ps-AF': true,
      'ps-PK': true,
      fa: true,
      'fa-AF': true,
      'fa-IR': true,
      sd: true,
      'sd-Arab': true,
      'sd-Arab-PK': true,
      ur: true,
      'ur-IN': true,
      'ur-PK': true,
      ug: true,
      'ug-CN': true,
    }[localeCode];
  }
}
