import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Optional,
  Inject,
  Output,
  OnDestroy,
  Input,
} from '@angular/core';
import { PermissionService } from '../../../core/api/auth/permissions.service';
import { Permissions } from '../../../core/store/auth/permissions';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Router, ActivatedRoute } from '@angular/router';
import { DropzoneComponent } from '../../../shared/dropzone/dropzone.component';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { getQueuedFilesState } from '../../../core/store/files-upload/files-upload.reducer';
import {
  RemoveFileFromQueueAction,
  UploadFilesStartedAction,
  ALL_FILES_UPLOADED,
  ResetFilesUploadStateAction,
} from '../../../core/store/files-upload/files-upload.actions';
import { ModalsService } from '../../../shared/modals/modals.service';
import { FilesUploadService } from '../../../core/api/files/files-upload.service';
import { ofType, Actions } from '@ngrx/effects';
import { EventEmitter } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { getActiveFolder } from '../../../core/store/files/files.reducer';
import { GetFilesAction } from '../../../core/store/files/files.actions';
import { FilesService } from '../../../core/api/files/files.service';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  providers: [{ provide: MatDialogRef, useValue: FilesUploadComponent }],
})
export class FilesUploadComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dropzone', { static: true })
  dropzone: DropzoneComponent;
  @Input() uploadConfig = <any>{};
  @Output() openFileUploadForm = new EventEmitter();
  @Output() filesProcessed = new EventEmitter();

  filesUpload = [];
  queuedFiles = [];
  uploadDisabled = false;
  firstQueuedFile = null;
  uploadStateSubscriptions: Subscription = new Subscription();
  queuedFiles$ = new BehaviorSubject(<any>[]);
  activePath;
  queueInProcessing = false;
  hasEnqueuedFiles = false;
  isDropzoneEnabled = true;
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private permissionService: PermissionService,
    private fileUploadService: FilesUploadService,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: Actions,
    private store: Store<AppState>,
    private modalsService: ModalsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogInputData: any,
    private filesService: FilesService,
    private bidiService: BidiService,
  ) {}

  get queuedFilesObs() {
    return this.queuedFiles$.asObservable();
  }

  hasUploadPermission = this.permissionService.hasPermission(Permissions.GM_FILE_WRITE);

  config = {
    viewAs: 'standalone',
    autoImport: false,
    multiUpload: true,
    embedModal: false,
  };

  gridOptions = {
    viewAs: 'standalone',
    view: 'files',
    showActionControls: false,
    showUploadActionControls: true,
    canRemoveFromList: true,
    showProgressOverlay: true,
    showProcessedIcon: true,
    thumbnailDraggable: false,
  };

  dzConfig = {
    multiUpload: true,
    view: 'files',
    embedModal: false,
  };

  ngOnInit() {
    if (!this.hasUploadPermission) {
      return this.router.navigate(['/media/files/list']);
    }
    this.config = { ...this.config, ...this.uploadConfig };
    this.dzConfig = { ...this.dzConfig, multiUpload: this.config.multiUpload, embedModal: this.config.embedModal };

    if (this.config.viewAs !== 'contained') {
      const folderPath = this.route.snapshot['_routerState'].url.replace('/media/files/upload', '');
      this.store.dispatch(new GetFilesAction({folderPath}));
    }
      const queuedFilesStateSub = this.store
      .select(getQueuedFilesState)
      .subscribe(async queuedFiles => {
        this.queuedFiles = queuedFiles;
        this.hasEnqueuedFiles = queuedFiles.length !== 0;
        this.uploadDisabled = this.queuedFiles.every(file => file.invalid);
        if (queuedFiles.length && !this.config.multiUpload) {
          this.firstQueuedFile = this.queuedFiles[0];
        }
        this.queuedFiles$.next(this.queuedFiles);

      });
    this.uploadStateSubscriptions.add(queuedFilesStateSub);
    const activeFolderSub = this.store.select(getActiveFolder).subscribe(activeFolder => {
      this.activePath = activeFolder.path;
    });
    this.uploadStateSubscriptions.add(activeFolderSub);
    this.listenForFilesProcessedEvent();
    this.trackUploadProgress();

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.config = { ...this.config, ...this.uploadConfig, ...changes.uploadConfig.currentValue };
    }
  }

  listenForFilesProcessedEvent() {
    const filesProcessedSub = this.actions$
      .pipe(ofType(ALL_FILES_UPLOADED), debounceTime(3000))
      .subscribe((action: any) => {
        // => If standalone page just redirect to files section
        // => Files View will properly reset queue state
        const activePath = this.activePath === '/' ? '' : this.activePath;
        const decodeUrl = decodeURIComponent(this.router.url);
        if (decodeUrl.endsWith('upload' + activePath) && this.config.viewAs === 'standalone') {
          this.queueInProcessing = false;
          this.router.navigate(['/media/files/list' + activePath]);
          this.store.dispatch(new ResetFilesUploadStateAction());
        }

        if (this.config.viewAs === 'contained' && this.config.autoImport) {
          this.queueInProcessing = false;
          this.filesProcessed.emit(action.payload);
        }
      });

    // Add subscription to group for easier disposal of subscriptions
    this.uploadStateSubscriptions.add(filesProcessedSub);
  }

  trackUploadProgress() {
    // Get upload progress and queuedFiles combines
    this.fileUploadService.getUploadProgress().subscribe((uploadProgress: any) => {
      // Update upload progress
      const fileToUpdate = this.queuedFiles.find(
        (file: any) => file.queueID === uploadProgress.file
      );
      if (fileToUpdate) {
        fileToUpdate['uploadProgress'] = uploadProgress.progress;
      }
    });
  }


  onFilesUpdate(event): void {
    const files = event.files ? event.files[0] : event.target.files;
    this.filesUpload = files;
  }

  startUpload(): void {
    this.queueInProcessing = true;
    this.store.dispatch(new UploadFilesStartedAction(this.filesUpload));
  }

  openFileSelectorDialog(file?) {
    const payload = file ? { changeFile: true, currentFile: file.queueID } : {};
    this.dropzone.openNativeDialog(payload);
  }

  removeFileFromQueue(file) {
    this.store.dispatch(new RemoveFileFromQueueAction(file.queueID));
  }

  handleEditFileForm(file) {
    if (this.config.viewAs === 'contained') {
      this.openFileUploadForm.emit(file);
    } else {
    this.modalsService.uploadEditFileForm({ file });
  }
  }
  goBack() {
    const activePath = this.activePath === '/' ? '' : this.activePath;
    this.router.navigate(['/media/files/list' + activePath]);
  }

  resolveThumbnailImg(itemImg) {
    const regex = /.*(?=\.)./g;
    if (!!itemImg.fileData.name) {
      const type = itemImg.fileData.name.toLowerCase().replace(regex, '');
      return this.filesService.resolveFileThumbnail(type);
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetFilesUploadStateAction());
    // Tear down upload state subscriptions
    this.uploadStateSubscriptions.unsubscribe();
  }
}
