import { UnsafeAction } from '../unsafe-action.interface';

import { createSelector } from '@ngrx/store';
import {
  ADD_FILES_TO_QUEUE_DONE,
  UPDATE_QUEUED_FILES,
  REMOVE_FILES_FROM_QUEUE,
  CLEAR_QUEUED_FILES,
  RESET_FILES_UPLOAD_STATE,
  UPLOAD_FILE_STARTED,
  ALL_FILES_UPLOADED,
  FILES_PROCESSED,
  UPDATE_UPLOADED_FILE,
  UPDATE_PROCESSED_FILE,
  UPDATE_QUEUED_FILE,
} from './files-upload.actions';

export interface FilesUploadState {
  uploading: boolean;
  processing: boolean;
  checkingFilesStatus: boolean;
  queuedFiles: {};
}

const initialState: FilesUploadState = {
  uploading: false,
  processing: false,
  checkingFilesStatus: false,
  queuedFiles: {},
};

export function filesUploadReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {
    case ADD_FILES_TO_QUEUE_DONE: {
      const filesToEnqueue = action.payload
        .map(file => {
          return {
            ...file,
            tags: [],
            uploaded: false,
            processing: false,
            processed: false,
            uploadProgress: 0,
          };
        })
        .reduce((acc, curr) => ({ ...acc, [curr.queueID]: curr }), {});

      return {
        ...state,
        queuedFiles: {
          ...state.queuedFiles,
          ...filesToEnqueue,
        },
      };
    }

    case UPDATE_QUEUED_FILES: {
      const updatedQueuedFiles = action.payload.reduce((acc, file) => {
        acc[file.queueID] = file;
        return acc;
      }, {});

      return {
        ...state,
        queuedFiles: {
          ...state.queuedFiles,
          ...updatedQueuedFiles,
        },
      };
    }

    case REMOVE_FILES_FROM_QUEUE: {
      const fileID = action.payload;
      const newQueuedFilesState = { ...state.queuedFiles };
      delete newQueuedFilesState[fileID];
      return {
        ...state,
        queuedFiles: newQueuedFilesState,
      };
    }

    case CLEAR_QUEUED_FILES: {
      return {
        ...state,
        queuedFiles: {},
      };
    }

    case UPLOAD_FILE_STARTED: {
      return {
        ...state,
        uploading: true,
      };
    }

    case ALL_FILES_UPLOADED: {
      return {
        ...state,
        uploading: false,
        processing: true,
      };
    }

    case FILES_PROCESSED: {
      return {
        ...state,
        processing: false,
        uploading: false,
        checkingFilesStatus: false,
      };
    }

    case UPDATE_UPLOADED_FILE: {
      const uploadedFile = action.payload;
      uploadedFile.processing = true;
      uploadedFile.processed = false;
      uploadedFile.uploaded = true;
      uploadedFile.uploading = false;
      return {
        ...state,
        queuedFiles: {
          ...state.queuedFiles,
          [uploadedFile.queueID]: uploadedFile,
        },
      };
    }

    case UPDATE_PROCESSED_FILE: {
      const processedFile = action.payload;
      processedFile.processed = true;
      processedFile.processing = false;
      processedFile.uploaded = true;
      return {
        ...state,
        queuedFiles: {
          ...state.queuedFiles,
          [processedFile.queueID]: processedFile,
        },
      };
    }

    case RESET_FILES_UPLOAD_STATE: {
      return {
        ...state,
        ...initialState,
      };
    }
    case UPDATE_QUEUED_FILE: {
      const { queueID, filename, metaData } = action.payload;
      let fileToUpdate = state.queuedFiles[queueID];

      let newData = {
        ...fileToUpdate,
        filename: filename,
        metaData: {
          name: metaData.name,
          description: metaData.description,
        },
      };

      return {
        ...state,
        queuedFiles: {
          ...state.queuedFiles,
          [queueID]: newData,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const getQueuedFiles = state => state.filesUpload.queuedFiles;
export const getQueuedFilesArray = state => Object.values(state.filesUpload.queuedFiles);

export const getQueuedFilesState = createSelector(getQueuedFilesArray, files => files);

export const fileQueueBeingProcessed = state => {
  return state.filesUpload.uploading || state.filesUpload.processing;
};
