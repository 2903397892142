import { Action } from '@ngrx/store';
import { ArticleType, ArticleTypeBasic } from './article-type.model';

export enum ArticleTypeActionTypes {
  LOAD = '[ArticleTypes] Load List',
  LOAD_SUCCESS = '[ArticleTypes] Load List Success',
  LOAD_BASIC = '[ArticleTypes] Load Basic List',
  LOAD_BASIC_SUCCESS = '[ArticleTypes] Load Basic List Success',
  LOAD_ONE = '[ArticleTypes] Load One',
  LOAD_ONE_SUCCESS = '[ArticleTypes] Load One Success',
  SET_ACTIVE = '[ArticleTypes] Set Active Id',
  CREATE = '[ArticleTypes] Create',
  CREATE_SUCCESS = '[ArticleTypes] Create Success',
  UPDATE = '[ArticleTypes] Update',
  UPDATE_SUCCESS = '[ArticleTypes] Update Success',
  DELETE = '[ArticleTypes] Delete',
  DELETE_SUCCESS = '[ArticleTypes] Delete Success',
  FAILURE = '[ArticleTypes] Load Failure',
  CLEAR = '[ArticleTypes] Clear',
}

export class LoadArticleTypesAction implements Action {
  readonly type = ArticleTypeActionTypes.LOAD;
}

export class LoadArticleTypesSuccessAction implements Action {
  readonly type = ArticleTypeActionTypes.LOAD_SUCCESS;
  constructor(public payload: ArticleType[]) {}
}

export class LoadBasicArticleTypesAction implements Action {
  readonly type = ArticleTypeActionTypes.LOAD_BASIC;
}

export class LoadBasicArticleTypesSuccessAction implements Action {
  readonly type = ArticleTypeActionTypes.LOAD_BASIC_SUCCESS;
  constructor(public payload: ArticleTypeBasic[]) {}
}

export class LoadOneArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.LOAD_ONE;
  constructor(public payload: number) {}
}

export class LoadOneArticleTypeSuccessAction implements Action {
  readonly type = ArticleTypeActionTypes.LOAD_ONE_SUCCESS;
  constructor(public payload: ArticleType) {}
}

export class CreateArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.CREATE;
  constructor(public payload: ArticleType) {}
}

export class CreateArticleTypeSuccessAction implements Action {
  readonly type = ArticleTypeActionTypes.CREATE_SUCCESS;
  constructor(public payload: ArticleType) {}
}

export class UpdateArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.UPDATE;
  constructor(public payload: ArticleType) {}
}

export class UpdateArticleTypeSuccessAction implements Action {
  readonly type = ArticleTypeActionTypes.UPDATE_SUCCESS;
  constructor(public payload: ArticleType) {}
}

export class DeleteArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.DELETE;
  constructor(public payload: number) {}
}

export class DeleteArticleTypeSuccessAction implements Action {
  readonly type = ArticleTypeActionTypes.DELETE_SUCCESS;
  constructor(public payload: number) {}
}

export class SetActiveArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.SET_ACTIVE;
  constructor(public payload: number) {}
}

export class FailureArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class ClearArticleTypeAction implements Action {
  readonly type = ArticleTypeActionTypes.CLEAR;
  constructor() {}
}

export type ArticleTypeActions =
  | LoadArticleTypesAction
  | LoadArticleTypesSuccessAction
  | LoadBasicArticleTypesAction
  | LoadBasicArticleTypesSuccessAction
  | LoadOneArticleTypeAction
  | LoadOneArticleTypeSuccessAction
  | CreateArticleTypeAction
  | CreateArticleTypeSuccessAction
  | UpdateArticleTypeAction
  | UpdateArticleTypeSuccessAction
  | DeleteArticleTypeAction
  | DeleteArticleTypeSuccessAction
  | SetActiveArticleTypeAction
  | FailureArticleTypeAction
  | ClearArticleTypeAction;
