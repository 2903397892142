<div class="gm-create-folder-meta-card" [dir]="dir$ | async">
  <div class="gm-create-folder-meta-card__path">
    <span i18n>Files</span>
    <div class="gm-create-folder-meta-card__path-urls" *ngFor="let url of breadcrumb">
      <mat-icon fontSet="fal" fontIcon="fa-angle-right"></mat-icon>
      <div>{{ url }}</div>
    </div>
  </div>

  <div class="gm-create-folder-meta-card__body">
    <h3 class="gm-create-folder__header" i18n>New Folder</h3>
    <form class="gm-create-folder-meta-card__form" [formGroup]="createFolderForm">
      <mat-form-field fxFlex="100%" appearance="outline" style="padding-bottom: 14px">
        <mat-label i18n>Folder Name</mat-label>
        <textarea matInput
                  formControlName="folderName"
                  autocomplete="off"
                  required
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="3"
                  class="gd-custom-scroller"></textarea>
        <mat-error *ngIf="createFolderForm.controls.folderName.errors?.required" i18n
          >Folder Name field is required</mat-error
        >
        <mat-error *ngIf="createFolderForm.controls.folderName.errors?.pattern" i18n
          >Following characters are not allowed: < > , # & + : “ / \ | ? *</mat-error
        >
        <mat-error *ngIf="createFolderForm.controls.folderName.errors?.maxlength" i18n
          >Max length 255 characters.</mat-error
        >
      </mat-form-field>
      <mat-label class="gm-create-folder_description-label" i18n>Description</mat-label>
      <section class="gm-create-folder__editor-wrapper">
        <gd-rich-text-editor
          [froalaConfigOptions]="richTextEditorOptions"
          (editorChange)="handleEditorContentChange($event)"
          [editorData]="richTextEditorData"
        ></gd-rich-text-editor>
      </section>
    </form>
    <div class="gm-create-folder-meta-card__buttons">
      <button mat-raised-button [mat-dialog-close] class="gm-create-folder-meta-card__cancel-btn" i18n>Cancel</button>
      <button mat-button mat-raised-button color="primary" (click)="createFolder()" i18n>Create</button>
    </div>
  </div>
</div>
