import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app-reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CopyLiveReportUrlSuccessAction, COPY_LIVE_REPORT_URL, DeleteLiveReportSuccessAction, DELETE_LIVE_REPORT, GetLiveReportsListSuccessAction, GET_LIVE_REPORTS_LIST, LiveReportingFailedAction } from './live-reports-list.action';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { UnsafeAction } from '../unsafe-action.interface';
import { LiveReportsService } from '../../api/live-reports/live-reports.service';
import { LiveReportFailedAction } from '../live-report/live-report.actions';

@Injectable()
export class LiveReportsListEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private router: Router,
    private liveReportsService: LiveReportsService,
  ) { }

  
  getLiveReportsList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_LIVE_REPORTS_LIST),
    mergeMap((action: any) => {
      return this.liveReportsService.getLiveReportsList(action.payload).pipe(
        map((response) => new GetLiveReportsListSuccessAction(response)),
        catchError((e) => of(new LiveReportingFailedAction(e)))
      );
    })
  ));

  
  deleteLiveReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_LIVE_REPORT),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.liveReportsService.deleteLiveReport(id).pipe(
        map(() => new DeleteLiveReportSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Live Report deleted`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new LiveReportingFailedAction(e)))
      );
    })
  ));

  
  copyLiveReportUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(COPY_LIVE_REPORT_URL), mergeMap((action: UnsafeAction) => {
    return this.liveReportsService.getLiveReportUrlPreview({...action.payload}).pipe(
         map((liveReportUrl) => {
           if(!liveReportUrl) {
          this.snackBar.open($localize`This link is unavailable. Please contact the account administrator to help.`, $localize`Close`, { duration: 4000 });
           } else {
              navigator.clipboard.writeText(liveReportUrl);
          this.snackBar.open($localize`Copied to clipboard.`, $localize`Close`, { duration: 4000 });
           }
           return new CopyLiveReportUrlSuccessAction()
         }),
         catchError((e) => {
        this.snackBar.open($localize`Action Failed: Live reporting path cannot be generated for this article.`, $localize`Close`, { duration: 4000 });
          return of(new LiveReportFailedAction({}))
         })
     );
  })));
}
