import {tap,  catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable , of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { ArticlesService, RestService } from '../../core/api';
import { FieldDataSource } from '../../core/store/widget-types/widget-types.model';
import { DataSourceOptions } from '../../gpp-shared/custom-form-builder/data-source-options.enum';
import {
  GetArticlesAction, getArticles, GetTaxonomiesAction, getFilteredTaxonomies, SetTaxonomyFilterAction, GetArticlesSuccessAction
} from '../../core/store';
import { GetAdvertsAction, GetAdvertsSuccessAction } from '../../core/store/adverts/adverts.actions';
import { getAdvertsList } from '../../core/store/adverts/adverts.reducer';
import { GetHtmlSnippetsAction, SetHtmlSnippetsFilterAction } from '../../core/store/html-snippets/html-snippets.actions';
import { getFilteredHtmlSnippetsList } from '../../core/store/html-snippets/html-snippets.reducer';
import { GetMenusAction, SetMenusFilterAction } from '../../core/store/menus/menus.actions';
import { getFilteredMenusList } from '../../core/store/menus/menus.reducer';


@Injectable()
export class WidgetConfigurationDataService {

  dataValue;
  returnFlag = false;
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private articlesService: ArticlesService,
    private restService: RestService,
  ) { }

  getDataFromServer(url: string): Observable<any> {
    return this.http.get<any>(url)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAngularJsonBug(error))
      );
  }

  private handleAngularJsonBug(error: HttpErrorResponse) {
    const JsonParseError = 'Http failure during parsing for';
    const matches = error.message.match(new RegExp(JsonParseError, 'ig'));
    if (error.status === 200 && matches.length === 1) {
      console.log('error', error);

      return of();
    }
    if (error.status === 403 || error.status === 401) {
      console.log('Error40x', error);
      return of();
    }
    return throwError(error);
  }

  getDataSourceValue(config: any, itemId) {
    switch (config['fieldType']) {
      case 'select':
      case 'autocomplete':
        return this.getSource(config['dataSource'], itemId, config['fieldType']);
      default:
        return of();
    }
  }

  getSource(source: FieldDataSource, itemId = null, fieldType) {
    if (source && source.value !== undefined) {
      if (source.value && source.value.constructor === Array && source.type === DataSourceOptions.CustomData) {
        this.returnFlag = true;
        return of(source.value);
      }

      if (source && source.type === DataSourceOptions.ExternalData) {
        const url = source.value['value'];
        const functionData = source.value['functionData'];
        const serverData = this.getDataFromServer(url);
        this.returnFlag = true;

        if (functionData['slideToggle']) {
          const functionD = new Function('data', functionData.transformFunction);
          return serverData.pipe(map(data => functionD(data)));
        }
        return serverData;
      }
      itemId = typeof itemId === 'object' ? null : itemId;
      if (source.value === 'articles') {
        this.returnFlag = true;
        if (fieldType === 'select') {
          this.store.dispatch(new GetArticlesAction({ page: 0, limit: 200 }));
        } else {
          itemId ? this.loadItem(itemId, source.value) : this.store.dispatch(new GetArticlesAction({ page: 0, limit: 10 }));
        }
        return this.store.select(getArticles)
          .pipe(map(articles => articles.map(article => ({ id: article.id, name: article.headline,
                    catchline: article.catchline, headline: article.headline }))));
      }

      if (source.value === 'taxonomies') {
        this.returnFlag = true;
        this.store.dispatch(new GetTaxonomiesAction());
        return this.store.select(getFilteredTaxonomies(false));
      }

      if (source.value === 'adverts') {
        this.returnFlag = true;
        if (fieldType === 'select') {
          this.store.dispatch(new GetAdvertsAction({ page: 0, limit: 200 }));
        } else {
          itemId ? this.loadItem(itemId, source.value) : this.store.dispatch(new GetAdvertsAction({ page: 0, limit: 10 }));
        }
        return this.store.select(getAdvertsList);
      }

      if (source.value === 'htmlSnippets') {
        this.returnFlag = true;
        this.store.dispatch(new GetHtmlSnippetsAction());
        return this.store.select(getFilteredHtmlSnippetsList(false))
          .pipe(map(snippets => snippets.map(snippet => ({ id: snippet.id, name: snippet.label }))));
      }

      if (source.value === 'menus') {
        this.returnFlag = true;
        this.store.dispatch(new GetMenusAction());
        return this.store.select(getFilteredMenusList(false)).pipe(map(menus => menus.map(menu => ({ id: menu.id, name: menu.name }))));
      }

      if (!this.returnFlag) {
        return of([]);
      }
    }
  }

  updateFilter(sourceType, keystroke) {
    if (typeof keystroke === 'number') {
      return;
    }
    switch (sourceType) {
      case 'articles':
        return this.store.dispatch(new GetArticlesAction({ page: 0, limit: 10, headline: keystroke, catchline: keystroke }));
      case 'htmlSnippets':
        return this.store.dispatch(new SetHtmlSnippetsFilterAction(keystroke));
      case 'menus':
        return this.store.dispatch(new SetMenusFilterAction(keystroke));
      case 'taxonomies':
        return this.store.dispatch(new SetTaxonomyFilterAction(keystroke));
      case 'adverts':
        return this.store.dispatch(new GetAdvertsAction({ page: 0, limit: 10, name: keystroke }));

      default:
        return;
    }
  }

  clearFilter(sourceType) {
    switch (sourceType) {
      case 'articles':
        return this.store.dispatch(new GetArticlesAction({ page: 0, limit: 10, headline: '', catchline: '' }));
      case 'htmlSnippets':
        return this.store.dispatch(new SetHtmlSnippetsFilterAction(''));
      case 'menus':
        return this.store.dispatch(new SetMenusFilterAction(''));
      case 'taxonomies':
        return this.store.dispatch(new SetTaxonomyFilterAction(''));
      case 'adverts':
        return this.store.dispatch(new GetAdvertsAction({ page: 0, limit: 10, name: '' }));

      default:
        return;
    }
  }

  loadItem(id, type) {
    switch (type) {
      case 'articles':
        return this.articlesService.getArticleById(id).pipe(
          tap(article => {
            article = { ...article, headline: article.latestRevision.headline };
            this.store.dispatch(new GetArticlesSuccessAction({ articles: [article], query: null }));
          }))
          .toPromise();
      case 'adverts':
        // TODO: Create adverts service
        return this.restService.get(`widgets/adverts/${id}`).pipe(
          map((response: any) => response.data),
          tap(advert => this.store.dispatch(new GetAdvertsSuccessAction({ adverts: [advert], query: null }))),);
      default:
        return of(null);
    }
  }
}
