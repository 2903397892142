import { createSelector } from 'reselect';
import { ArticleType, ArticleTypeBasic } from './article-type.model';
import { ArticleTypeActionTypes, ArticleTypeActions, FailureArticleTypeAction } from './article-type.actions';
import { AppState } from '../app-reducer';
import { get, orderBy } from 'lodash-es';

export interface ArticleTypeState {
  loaded: boolean;
  loading: boolean;
  error: any;
  activeArticleTypeId: number;
  db: { [key: number]: ArticleType };
  // this is for a simplified list of article types with just id, name and is default flag
  basicList: ArticleTypeBasic[];
  basicListLoaded: boolean;
}

const initialState: ArticleTypeState = {
  loaded: false,
  loading: false,
  activeArticleTypeId: null,
  error: null,
  db: {},
  basicList: [],
  basicListLoaded: false
};

export function articleTypesReducer(state = initialState, action: ArticleTypeActions) {
  switch (action.type) {
    case ArticleTypeActionTypes.CREATE:
    case ArticleTypeActionTypes.UPDATE:
    case ArticleTypeActionTypes.DELETE:
    case ArticleTypeActionTypes.LOAD_ONE:
    case ArticleTypeActionTypes.LOAD: {
      return {
        ...state,
        loading: true,
        error: null,
        db: action.type === ArticleTypeActionTypes.LOAD ? {} : state.db
      };
    }

    case ArticleTypeActionTypes.SET_ACTIVE: {
      return {
        ...state,
        activeArticleTypeId: action.payload,
      };
    }

    case ArticleTypeActionTypes.LOAD_SUCCESS: {
      const data: ArticleType[] = action.payload;
      const articleTypesDb = data.reduce((acc, at) => {
        acc[at.id] = at;
        return acc;
      }, {});

      return {
        ...state,
        db: articleTypesDb,
        loaded: true,
        loading: false,
        error: null,
      };
    }

    case ArticleTypeActionTypes.LOAD_BASIC: {
      return {
        ...state,
        loading: true
      };
    }


    case ArticleTypeActionTypes.LOAD_BASIC_SUCCESS: {
      const data: ArticleTypeBasic[] = action.payload;
      return {
        ...state,
        basicList: data,
        basicListLoaded: true,
        loading: false,
      };
    }

    case ArticleTypeActionTypes.LOAD_ONE_SUCCESS:
    case ArticleTypeActionTypes.UPDATE_SUCCESS:
    case ArticleTypeActionTypes.CREATE_SUCCESS: {
      const newArticleType: ArticleType = action.payload;
      return {
        ...state,
        db: { ...state.db, [newArticleType.id]: newArticleType },
        loading: false,
        error: null,
      };
    }

    case ArticleTypeActionTypes.DELETE_SUCCESS: {
      if (!action.payload) {
        return { ...state, loading: false };
      }
      const newDb = { ...state.db };
      delete newDb[action.payload];
      return {
        ...state,
        db: newDb,
        loading: false,
      };
    }

    case ArticleTypeActionTypes.CLEAR: {
      return {
        ...state,
        db: {},
        loading: false
      }
    }

    case ArticleTypeActionTypes.FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    default: {
      return state;
    }
  }
}

export const getArticleTypesState = (state: AppState) => state.articleTypes;
export const getArticleTypes = (state: AppState) => state.articleTypes.db;

export const getArticleTypeLoading = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => articleTypesState.loading
);

export const getArticleTypeLoaded = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => articleTypesState.loaded
);

export const getActiveArticleType = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => {
    const articleType = articleTypesState.db[articleTypesState.activeArticleTypeId] || null;
    if (articleType === null) {
      return null;
    }
    return {
      ...articleType,
      customStyles: get(articleType, 'customStyles', ''),
      customImageStyles: get(articleType, 'customImageStyles', []),
      additionalConfiguration: {
        ...articleType.additionalConfiguration,
        editorConfiguration:  get(articleType, 'additionalConfiguration.editorConfiguration', {}),
      }
    } as ArticleType;
  }
);

export const getArticleTypesError = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => articleTypesState.error
);

export const getArticleTypesList = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => Object.values(articleTypesState.db)
);

export const getArticleTypesBasicList = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => articleTypesState.basicList
);

export const getArticleTypesBasicListLoaded = createSelector(
  getArticleTypesState,
  (articleTypesState: ArticleTypeState) => articleTypesState.basicListLoaded
);

export const getDefaultFirstSortedArticleTypesBasicList = createSelector(
  getArticleTypesBasicList,
  (articleTypes: ArticleType[]) => orderBy(articleTypes, ['defaultType', 'name'], ['desc', 'asc'])
);

export const getDefaultFirstSortedArticleTypesList = createSelector(
  getArticleTypesList,
  (articleTypes: ArticleType[]) => orderBy(articleTypes, ['defaultType', 'name'], ['desc', 'asc'])
);

export const getArticleTypeById = (id: number) => {
  return createSelector(
    getArticleTypesState,
    (articleTypesState: ArticleTypeState) => articleTypesState.db[id] || null
  );
};

export const getDefaultArticleType = createSelector(
  getArticleTypesList,
  (articleTypes: ArticleType[]) => articleTypes.find(at => at.defaultType)
);

export const getNonDefaultArticleTypes = createSelector(
  getArticleTypesList,
  (articleTypes: ArticleType[]) => articleTypes.filter(at => !at.defaultType)
);
