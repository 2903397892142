import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  GET_REDIRECTS,
  CREATE_REDIRECT,
  DELETE_REDIRECT,
  UPDATE_REDIRECT,
  REDIRECT_ACTION_FAILED,
  GetRedirectsSuccessAction,
  RedirectFailedAction,
  CreateRedirectSuccessAction,
  UpdateRedirectSuccessAction,
  DeleteRedirectSuccessAction,
} from './redirects.actions';
import { RedirectsService } from '../../api/redirects/redirects.service';

@Injectable()
export class RedirectsEffects {

  loadRedirects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_REDIRECTS),
    mergeMap((action: UnsafeAction) => {
      return this.redirectsService.getRedirects(action.payload).pipe(
        map((redirects) => new GetRedirectsSuccessAction(redirects)),
        catchError((e) => of(new RedirectFailedAction(e)))
      );
    })
  ));


  createRedirect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_REDIRECT),
    mergeMap((action: UnsafeAction) => {
      const redirect = action.payload;
      delete redirect.id;
      return this.redirectsService.createRedirect(redirect).pipe(
        map((data) => new CreateRedirectSuccessAction(data)),
        tap(() => {
          this.snackBar.open($localize`Redirect created successfully.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/site-builder/redirects']);
        }),
        catchError((e) => of(new RedirectFailedAction(e)))
      ) as Observable<UnsafeAction>;
    })
  ));


  deleteRedirect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_REDIRECT),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.redirectsService.deleteRedirect(id).pipe(
        map(() => new DeleteRedirectSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Redirect deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Failed to delete redirect!`, $localize`Close`);
          return of(new RedirectFailedAction(e));
        })
      );
    })
  ));


  updateRedirect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_REDIRECT),
    mergeMap((action: UnsafeAction) => {
      return this.redirectsService.updateRedirect(action.payload).pipe(
        map((redirect) => new UpdateRedirectSuccessAction(redirect)),
        tap(() => {
          this.router.navigate(['/site-builder/redirects']);
          this.snackBar.open($localize`Redirect successfully updated.`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update redirect!`, $localize`Close`);
          return of(new RedirectFailedAction(e));
        })
      ) as Observable<UnsafeAction>;
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(REDIRECT_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private redirectsService: RedirectsService
  ) {}
}
