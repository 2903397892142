
import {map} from 'rxjs/operators';
import { RestService } from '../rest.service';
import { Injectable } from '@angular/core';
import { CollectionType } from '../../store/collection-type/collection-type.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionTypeService {
  constructor(
    private rest: RestService
  ) { }

  getAll({ name = '', pageNumber = 0, pageSize = 100 }: any = {}) {
    name = encodeURIComponent(name) || '';
    const requestPath = `collection-types?name=${name}&page=${pageNumber || 0}&size=${pageSize || 20}`;
    return this.rest.get(requestPath).pipe(map(response => response.data));
  }

  getOne(id: number) {
    return this.rest.get('collection-types/' + id).pipe(map(response => response.data));
  }

  create(collectionType: CollectionType) {
    const payload = { ...collectionType };
    delete payload.id;
    return this.rest.post('collection-types', payload).pipe(map((response: any) => response.data));
  }

  update(collectionType: CollectionType) {
    return this.rest
      .put('collection-types/' + collectionType.id, collectionType).pipe(
      map((response: any) => response.data));
  }

  delete(id: number) {
    return this.rest.delete('collection-types/' + id);
  }

  getEmbeddableResources() {
    return this.rest.get('collection-types/embeddable-resources').pipe(map((response: any) => response.data));
  }

  getCollectionTypesByIds(collectionTypesIds) {

    if (collectionTypesIds.length < 1) {
      return of([]);
    }

    const size = collectionTypesIds.length <= 200 ? collectionTypesIds.length : 200;

    return this.rest.get(`collection-types?ids=${collectionTypesIds.join(',')}&size=${size}`).pipe(map(response => response.data));
  }
}
