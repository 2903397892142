import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit,
  DoCheck,
} from '@angular/core';
import { BehaviorSubject, filter, map, take } from 'rxjs';
import { ImagesUploadService } from '../../core/api';
import { FilesService } from '../../core/api/files/files.service';
import { UserPreferencesService } from '../../core/api/user-preferences/user-preferences.service';
import { BidiService } from '../../core/i18n/bidi.service';
import { AppState } from '../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { getImageSizesArray } from '../../core/store/images-configuration';

@Component({
  selector: 'gd-image-thumbnail',
  templateUrl: './image-thumbnail.component.html',
  styleUrls: ['./image-thumbnail.component.scss'],
})
export class ImageThumbnailComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() item;
  @Input() config;
  @Input() isSelected;
  @Input() loading;
  @Input() thumbnailProps;
  @Input() hasDeletePermission;
  @Input() uploadQueueActive;
  @Input() hasActionPermission = true;

  @Output() preview: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() download: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() selectImage: EventEmitter<any> = new EventEmitter();
  @Output() addMeta: EventEmitter<any> = new EventEmitter();
  @Output() crop: EventEmitter<any> = new EventEmitter();
  @Output() editFile: EventEmitter<any> = new EventEmitter();

  imageThumbnailSrcAvailable = false;
  imageBeingProcessed = new BehaviorSubject(false);
  thumbnailImageSrc = null;
  theme = 'default';
  viewGridActive = false;

  @ViewChild('thumbnailImage') thumbnailImage: ElementRef;
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();
  hasCroppableRenditions$ = this.store.select(getImageSizesArray).pipe(map(renditions => (renditions || []).some(r => r.cropAllowed)));
  deletedImageId = null;

  constructor(
    private elRef: ElementRef,
    private uploadService: ImagesUploadService,
    private filesService: FilesService,
    private userPreferenceService: UserPreferencesService,
    private bidiService: BidiService,
    private store: Store<AppState>,
  ) { }

  ngDoCheck() {
    if (this.theme !== this.userPreferenceService.getUserPreference('theme')) {
      this.theme = this.userPreferenceService.getUserPreference('theme');
      this.ngOnInit;
    }
    this.viewGridActive = this.userPreferenceService.getUserPreference('images.viewGridActive');
  }
  ngOnInit() {
    this.thumbnailImageSrc = this.item.gridThumbnail;
    this.imageThumbnailSrcAvailable = true;
    if (this.config.view === 'files') {
      this.item.fileData.isSvgIcon = this.resolveThumbnailImg(this.item).startsWith('glideCustomIcon_') ? true : false;
    }
    // For Gallery modals that show Publish and Unpublished content.
    // Case when latest revision of published gallery isn't published.
    if (this.config.showGalleryIcon && this.config.showUnpublishedContent) {
      const invalidStatus = this.item.isPublished && this.item.status && this.item.status.name !== 'Published';
      if (invalidStatus) {
        this.item.status = { name: 'Published', bg_color: '#169BD7', color: '#FFF' };
      }
    }
  }

  ngAfterViewInit() {
    const imgEl = this.thumbnailImage && this.thumbnailImage.nativeElement;

    if (imgEl) {
      imgEl.onerror = () => {
        this.imageBeingProcessed.next(true);
        if (this.item.croppedThumbnail) {
          return this.thumbnailImageSrc = this.item.croppedThumbnail;
        }
        const imgThumbnail = this.uploadService.findInTempQueue(this.item.id);
        setTimeout(() => this.uploadService.removeFromTempQueue(this.item.id), 1000);
        if (!imgThumbnail) {
          return this.imageThumbnailSrcAvailable = false;
        }
        this.item.thumbnail = this.uploadService.sanitazeBlobURL(imgThumbnail);
      };
    }
  }

  resolveThumbnailImg(itemImg) {
    const regex = /.*(?=\.)./g;
    if (!!itemImg.fileData.name) {
      const type = itemImg.fileData.name.toLowerCase().replace(regex, '');
      return this.filesService.resolveFileThumbnail(type);
    }
  }

  getStatusChipStyle(status) {
    if (!status) {
      return {};
    }
    const bgColor = status.bg_color || status.backgroundColor;
    const bgColorObject = bgColor ? { 'background-color': bgColor } : { 'border-color': status.color };
    const color = status.color;
    return { ...bgColorObject, color };
  }

  triggerCroppingProcess() {
    this.hasCroppableRenditions$.pipe(
      take(1),
      filter(cropAllowed => !this.item.invalid && cropAllowed)
    ).subscribe(() => this.crop.emit(this.item));
  }

  deleteImage(image) {
    this.deletedImageId = image.id;
    this.delete.emit(image);
  }
}
