import { LiveReportListQueryParameters } from '../../api/live-reports/live-reports.service';
import { UnsafeAction } from '../unsafe-action.interface';

export const GET_LIVE_REPORTS_LIST = '[Live Reports] Get Live Reports';
export class GetLiveReportsListAction implements UnsafeAction {
  readonly type = GET_LIVE_REPORTS_LIST;
  constructor(public payload: LiveReportListQueryParameters = {}) { }
}

export const GET_LIVE_REPORTS_LIST_SUCCESS = '[Live Reports] Get live reports success';
export class GetLiveReportsListSuccessAction implements UnsafeAction {
  readonly type = GET_LIVE_REPORTS_LIST_SUCCESS;
  constructor(public payload: any) { }
}

export const SET_LIVE_REPORTS_LIST_PAGE_VIEW = '[Live Reports] Set live reports page view';
export class SetLiveReportsListPageViewAction implements UnsafeAction {
  readonly type = SET_LIVE_REPORTS_LIST_PAGE_VIEW;
  constructor(public payload: any) { }
}

export const DELETE_LIVE_REPORT = '[Live Reports] Delete live report';
export class DeleteLiveReportAction implements UnsafeAction {
  readonly type = DELETE_LIVE_REPORT;
  constructor(public payload: any) { }
}

export const DELETE_LIVE_REPORT_SUCCESS = '[Live Reports] Delete live report success';
export class DeleteLiveReportSuccessAction implements UnsafeAction {
  readonly type = DELETE_LIVE_REPORT_SUCCESS;
  constructor(public payload: any) { }
}

export const LIVE_REPORTS_ACTION_FAILED = '[Live Reports] Generic failure action';
export class LiveReportingFailedAction implements UnsafeAction {
  readonly type = LIVE_REPORTS_ACTION_FAILED;
  constructor(public payload: any) { }
}


export const COPY_LIVE_REPORT_URL = '[Live Reports] Copy live report url';
export class CopyLiveReportUrlAction implements UnsafeAction {
  readonly type = COPY_LIVE_REPORT_URL;
  constructor(public payload: any) { }
}

export const COPY_LIVE_REPORT_URL_SUCCESS = '[Live Reports] Copy live report url success';
export class CopyLiveReportUrlSuccessAction implements UnsafeAction {
  readonly type = COPY_LIVE_REPORT_URL_SUCCESS;
  constructor() { }
}
