import { debounceTime, filter, mergeMap, take, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of, forkJoin } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  GET_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_SETTINGS,
  ACCOUNT_SETTINGS_ACTION_FAILED,
  GetAccountSettingsSuccessAction,
  UpdateAccountSettingsSuccessAction,
  AccountSettingsFailedAction,
  GET_ACCOUNT_SETTINGS_SUCCESS,
  UpdateSettingsReferencedContentAction,
  REFRESH_ACCOUNT_SETTINGS,
  RefreshAccountSettingsSuccessAction,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_INTEGRATION_CONFIG,
  UpdateIntegrationConfigSuccessAction,
} from './account-settings.actions';
import { AuthService, ImagesService } from '../../api';
import { AppState } from '../app-reducer';
import { getAccountSettings } from './account-settings.reducer';
import { RoutesService } from '../../api/routes/routes.service';
import { AccountSettingsService } from '../../api/account-settings/accounts-settings.service';
import { ACCOUNT_CHANGED, LoadActiveUserDetailsAction } from '../auth';

@Injectable()
export class AccountSettingsEffects {

  loadAccountSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACCOUNT_SETTINGS),
    filter(() => !!this.authService.getUserAccountId()),
    mergeMap(() => {
      const accountId = this.authService.getUserAccountId();
      return this.accountSettingsService.getAccountSettings(accountId).pipe(
        map((settings) => new GetAccountSettingsSuccessAction(settings)),
        catchError((e) => of(new AccountSettingsFailedAction(e)))
      );
    })
  ));


  updateAccountSettings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(UPDATE_ACCOUNT_SETTINGS))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        const accountId = this.authService.getUserAccountId();
        const data = action.payload;
        return this.accountSettingsService.updateAccountSettings({ accountId, data }).pipe(
          map(() => new UpdateAccountSettingsSuccessAction(data)),
          tap(() => {
            this.store.dispatch(new LoadActiveUserDetailsAction());
            this.snackBar.open($localize`Account settings successfully updated`, $localize`Close`, {
              duration: 4000,
            });
          }),
          catchError((e) => {
            this.snackBar.open($localize`Failed to update Account settings!`, $localize`Close`);
            return of(new AccountSettingsFailedAction(e));
          })
        );
      })
    ));


  getReferencedContent$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(GET_ACCOUNT_SETTINGS_SUCCESS))
    .pipe(
      mergeMap(() => this.store.select(getAccountSettings).pipe(take(1))),
      filter((settings: any) => !!settings.pagesConfig),
      map((settings) => {
        const pagesConfig = settings.pagesConfig;
        const requests = [];
        Object.keys(pagesConfig)
          .filter((key) => !!pagesConfig[key])
          .forEach((key) =>
            requests.push(
              this.routesService
                .getRoutesByPageId(pagesConfig[key])
                .pipe(map((routes) => ({ pageId: pagesConfig[key], routes })))
            )
          );
        return requests;
      }),
      filter((requests) => requests && requests.length !== 0),
      mergeMap((requests) => forkJoin(requests)),
      map((routesData) => {
        const referencedContent = {};
        routesData.forEach(({ pageId, routes }) => (referencedContent[pageId] = { routes }));
        return new UpdateSettingsReferencedContentAction(referencedContent);
      }),
      catchError((e) => of(new AccountSettingsFailedAction({ error: e })))
    ));


  refreshAccountSettings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(REFRESH_ACCOUNT_SETTINGS, ACCOUNT_CHANGED))
    .pipe(
      debounceTime(50),
      filter(() => this.authService.isUserLoggedIn() && !!this.authService.getUserAccountId()),
      mergeMap(() => {
        const accountId = this.authService.getUserAccountId();
        return this.accountSettingsService.getAccountSettings(accountId).pipe(
          tap((settings) =>
            this.accountSettingsService.refreshAccountSettingsInStore(accountId, settings)
          ),
          map((settings) => new RefreshAccountSettingsSuccessAction(settings)),
          catchError((e) => of(new AccountSettingsFailedAction(e)))
        );
      })
    ));


  handleSettingsChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(GET_ACCOUNT_SETTINGS_SUCCESS, UPDATE_ACCOUNT_SETTINGS_SUCCESS))
    .pipe(
      mergeMap(() => this.store.select(getAccountSettings).pipe(take(1))),
      tap((settings) => {
        const accountId = this.authService.getUserAccountId();
        return this.accountSettingsService.refreshAccountSettingsInStore(accountId, settings);
      })
    ), { dispatch: false });

  updateIntegrationConfig$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(UPDATE_INTEGRATION_CONFIG))
    .pipe(
      mergeMap((action: UnsafeAction) => this.imagesService.updateIntegrationConfig(action.payload)),
      map(() => new UpdateIntegrationConfigSuccessAction()),
      catchError((e) => {
        console.error(e)
        return of(new AccountSettingsFailedAction(e))
      })
    ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ACCOUNT_SETTINGS_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private store: Store<AppState>,
    private routesService: RoutesService,
    private accountSettingsService: AccountSettingsService,
    private imagesService: ImagesService,
  ) {}
}
