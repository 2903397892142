import { LabelValue } from './../../../label-value-list/label-value-interface';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Validators, } from '@angular/forms';
import { ActiveLabelValue } from '../../../label-value-list/label-value-interface';
import { get } from 'lodash-es';
import { checkIfCheckboxIsSelected } from './checkbox-validator';
import { hasRequiredValidation } from '../has-required-validation/has-required-validation';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { generateHelpTooltip } from '../info-tooltip.text';

/**
 * This component handles instantiation of checkbox group custom field.
 * The component will output the selected values as string array, e.g `["foo", "bar"]`,
 * where "foo" and "bar" are keys of the option from config. If no options are selected: []
 */
@Component({
  selector: 'gd-checkbox-configuration',
  templateUrl: './checkbox-configuration.component.html',
  styleUrls: ['./checkbox-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CheckboxConfigurationComponent implements OnInit, OnChanges {
  @Input() label;
  @Input() fieldControl;
  @Input() fieldConfig;
  @Input() readOnlyMode = false;

  isFormControlRequired = false;
  checkboxGroupOptions: ActiveLabelValue[] = [];
  selectedOptionsMap: {[key: string]: boolean} = {};

  infoTooltip = '';

  constructor() { }

  ngOnInit(): void {
    this.checkboxGroupOptions = get(this.fieldConfig, 'dataSource.value', []);

    this.isFormControlRequired = hasRequiredValidation(this.fieldConfig);
    if (this.isFormControlRequired) {
      this.fieldControl.setValidators([Validators.required, checkIfCheckboxIsSelected]);
      this.fieldControl.updateValueAndValidity();
    }

    // initialize the checkbox group values
    const selectedValues = this.fieldControl.value;
    if (!Array.isArray(selectedValues)) {
      return;
    }
    this.selectedOptionsMap = this.checkboxGroupOptions.reduce((acc, cv: LabelValue) => {
      acc[cv.value] = selectedValues.includes(cv.value);
      return acc;
    }, {});
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
  }

  onCheckboxChange(event: MatCheckboxChange, item: LabelValue) {
    this.selectedOptionsMap[item.value] = event.checked;

    // derive the selected values array from the selectedOptionsMap
    const selectedValues = Object.entries(this.selectedOptionsMap)
      .filter(([key, value ]) => value)
      .reduce((acc, [key] ) => {
        acc.push(key);
        return acc;
      }, []);

    this.fieldControl.setValue(selectedValues);
    this.fieldControl.markAsDirty();

    if (this.isFormControlRequired) {
      this.fieldControl.updateValueAndValidity();
    }
  }
}
