import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BroadcastService } from '../../core/tab-broadcast/broadcast.service';

@Component({
  selector: 'gd-extend-session-dialog',
  templateUrl: './extend-session-dialog.component.html',
  styleUrls: ['./extend-session-dialog.component.scss'],
})
export class ExtendSessionDialogComponent implements OnInit, OnDestroy {
  passwordForm = this.fb.group({
    password: ['', Validators.required],
  });

  username: string;
  makingRequest = false;
  ssoUser;
  accountId;
  accountSlug;
  componentSubs: Subscription = new Subscription();

  // see if you can use this from auth
  glideUsersUrl: string = environment.glideUsers + environment.glideUsersVersion;
  private authRequestConfig = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<ExtendSessionDialogComponent>,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.ssoUser = this.dialogInputData.ssoLogin;
    this.accountId = this.dialogInputData.accountId;
    this.accountSlug = this.dialogInputData.accountSlug;
    this.username = this.dialogInputData.username;
    this.componentSubs.add(
      this.broadcastService
        .messagesOfType('SSO_REFRESH_SESSION')
        .subscribe(this.handleBroadcastMessage.bind(this))
    );
  }

  async submit() {
    const formValue = this.passwordForm.value;
    const credentials = {
      accountId: this.accountId,
      username: this.username,
      password: formValue.password,
    };

    this.makingRequest = true;
    this.http.post(this.glideUsersUrl + 'auth', credentials, this.authRequestConfig).subscribe(
      (res) => {
        this.dialogRef.close(res);
      },
      (err) => {
        this.makingRequest = false;
        this.passwordForm.get('password').setErrors({ invalidPassword: true });
      }
    );
  }

  ngOnDestroy(): void {
    this.makingRequest = false;
    this.componentSubs.unsubscribe();
  }

  exit() {
    this.dialogRef.close(null);
  }

  handleBroadcastMessage = (data): void => {
    // hard coded SUCCESS response for SSO logins
    // auth is handled by the SSO provider
    const res = {
      data: { ...data.payload, accessToken: data.payload.token },
      status: 'SUCCESS',
    };
    this.dialogRef.close(res);
    this.snackBar.open(`Session successfully extended`, $localize`Close`, { duration: 5000 });
  };

  samlLogin() {
    this.createPopupWin(
      `${environment.glideAuth}saml/login/account/${this.accountSlug}?refreshSession=true`,
      '_blank',
      600,
      600
    );
  }

  createPopupWin(pageURL, pageTitle, popupWinWidth, popupWinHeight) {
    const left = (screen.width - popupWinWidth) / 2;
    const top = (screen.height - popupWinHeight) / 4;

    window.open(
      pageURL,
      pageTitle,
      'resizable=yes, width=' +
        popupWinWidth +
        ', height=' +
        popupWinHeight +
        ', top=' +
        top +
        ', left=' +
        left
    );
  }
}
