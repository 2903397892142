import { createSelector } from 'reselect';
import { TaxonomyConfiguration } from './taxonomy-configuration.model';
import { TaxonomyConfigurationActionTypes, TaxonomyConfigurationActions } from './taxonomy-configuration.actions';
import { AppState } from '../app-reducer';

export interface TaxonomyConfigurationState {
  loaded: boolean;
  loading: boolean;
  error: string;
  configuration: TaxonomyConfiguration;
}

const initialState: TaxonomyConfigurationState = {
  loaded: false,
  loading: false,
  error: null,
  configuration: null,
};

export function taxonomyConfigurationReducer(state = initialState, action: TaxonomyConfigurationActions) {
  switch (action.type) {
    case TaxonomyConfigurationActionTypes.UPDATE:
    case TaxonomyConfigurationActionTypes.LOAD: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case TaxonomyConfigurationActionTypes.LOAD_SUCCESS: {
      const configuration: TaxonomyConfiguration = action.payload;
      return {
        ...state,
        configuration,
        loaded: true,
        loading: false,
        error: null,
      };
    }

    case TaxonomyConfigurationActionTypes.UPDATE_SUCCESS: {
      const configuration: TaxonomyConfiguration = action.payload;
      return {
        ...state,
        configuration,
        loading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const getTaxonomyConfigurationsState = (state: AppState) => state.taxonomyConfiguration;
export const getTaxonomyConfigurations = (state: AppState) => state.taxonomyConfiguration.configuration;

export const getTaxonomyConfigurationLoading = createSelector(
  getTaxonomyConfigurationsState,
  (taxonomyConfigurationState: TaxonomyConfigurationState) => taxonomyConfigurationState.loading
);

export const getTaxonomyConfigurationLoaded = createSelector(
  getTaxonomyConfigurationsState,
  (taxonomyConfigurationState: TaxonomyConfigurationState) => taxonomyConfigurationState.loaded
);
