import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent {

  public title: string;
  public message: string;
  public confirmButton: string;
  public closeButton: string;
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    private bidiService: BidiService,
    ) { }

}
