export enum PageType {
  Default = 'DEFAULT',
  Article = 'ARTICLE',
  Gallery = 'GALLERY',
  Error4xx = 'ERROR4XX',
  Error5xx = 'ERROR5XX',
  Collection = 'COLLECTION',
  LiveReport = 'LIVE_REPORT',
}

export const  PageTypeLabels = {
  [PageType.Default] : $localize`Default`,
  [PageType.Article] : $localize`Article`,
  [PageType.Gallery] : $localize`Gallery`,
  [PageType.Error4xx] : $localize`Error 4xx`,
  [PageType.Error5xx] : $localize`Error 5xx`,
  [PageType.Collection] : $localize`Collection`,
  [PageType.LiveReport] : $localize`Live Report`,
};

export interface Page {
  id: number;
  published: boolean;
  parentId: number;
  publishedRevision: PageRevision;
  workingRevision: PageRevision;
}

export interface PageRevision {
  label: string;
  type: PageType;
  createdAt: number;
  routeIds: number[];
  updatedAt: number;
  dataLayer: { [key: string]: any };
  seo: {
    title: string,
    metaDescription: string,
    keywords: string[],
    author: string
  };
}

export interface PageTreeItem {
  id: number;
  parentId: number;
  label: string;
  childrenIds?: number[];
  children: PageTreeItem[];
  hasDescendants: boolean;
  canDelete: boolean;
  deleteTooltip: string;
  hasUnpublishedUpdates:  boolean;
  routeIds: number[];
}

export interface PagesMap {
  [key: number]: Page;
}

export const NewPageId = -1;
