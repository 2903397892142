import { UnsafeAction } from '../unsafe-action.interface';

export const GET_SAML_CONFIG = '[SSO] Get Saml Config';
export class GetSamlConfigAction implements UnsafeAction {
  readonly type =  GET_SAML_CONFIG;
  constructor() {}
}

export const GET_SAML_CONFIG_COMPLETE = '[SSO] Get Saml Config Complete';
export class GetSamlConfigCompleteAction implements UnsafeAction {
  readonly type = GET_SAML_CONFIG_COMPLETE;
  constructor(public payload: any) {}
}

export const UPDATE_SAML_CONFIG = '[SSO] Update Saml Config';
export class UpdateSamlConfigAction implements UnsafeAction {
  readonly type =  UPDATE_SAML_CONFIG;
  constructor(public payload: any) {}
}

export const UPDATE_SAML_CONFIG_COMPLETE = '[SSO] Update Saml Config Complete';
export class UpdateSamlConfigCompleteAction implements UnsafeAction {
  readonly type = UPDATE_SAML_CONFIG_COMPLETE;
  constructor(public payload: any) {}
}

export const CREATE_SAML_CONFIG = '[SSO] Create Saml Config';
export class CreateSamlConfigAction implements UnsafeAction {
  readonly type =  CREATE_SAML_CONFIG;
  constructor(public payload: any) {}
}

export const CREATE_SAML_CONFIG_COMPLETE = '[SSO] Create Saml Config Complete';
export class CreateSamlConfigCompleteAction implements UnsafeAction {
  readonly type = CREATE_SAML_CONFIG_COMPLETE;
  constructor(public payload: any) {}
}

export const SSO_PROVIDERS_FAILED = '[SSO] Generic failure action';
export class SamlProvidersFailedAction implements UnsafeAction {
  readonly type = SSO_PROVIDERS_FAILED;
  constructor(public payload: any) {}
}

export const RESET_SAML_CONFIG = '[SSO] Reset saml config';
export class ResetSamlConfigAction implements UnsafeAction {
  readonly type = RESET_SAML_CONFIG;
  constructor() {}
}
