import { mergeMap, tap, map, catchError, throttleTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { ContentLocalesService } from '../../api/content-locales/content-locales.service';
import {
  ClearContentLocalesStateAction,
  ContentLocaleFailedAction,
  CONTENT_LOCALE_ACTION_FAILED,
  CreateContentLocaleAction,
  CreateContentLocaleSuccessAction,
  CREATE_CONTENT_LOCALE,
  DeleteContentLocaleAction,
  DeleteContentLocaleSuccessAction,
  DELETE_CONTENT_LOCALE,
  GetContentLocalesAction,
  GetContentLocalesSuccessAction,
  GetOneContentLocaleAction,
  GetOneContentLocaleSuccessAction,
  GET_CONTENT_LOCALES,
  GET_ONE_CONTENT_LOCALE,
  UpdateContentLocaleAction,
  UpdateContentLocaleSuccessAction,
  UPDATE_CONTENT_LOCALE,
} from './content-locales.actions';
import { ACCOUNT_CHANGED } from '../auth/auth.actions';

@Injectable()
export class ContentLocalesEffects {
  constructor(
    private actions$: Actions,
    public snackBar: MatSnackBar,
    public router: Router,
    public contentLocalesService: ContentLocalesService
  ) {}

  loadContentLocales$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_CONTENT_LOCALES),
      mergeMap((action: GetContentLocalesAction) =>
        this.contentLocalesService.getAllContentLocales().pipe(
          tap((contentLocales) => {
            if (contentLocales.length === 0)
              this.router.navigate(['/site-builder/content-locales/create'], {
                queryParams: { createDefaultLocale: true },
              });
          }),
          map((contentLocales) => new GetContentLocalesSuccessAction(contentLocales)),
          catchError((e) => of(new ContentLocaleFailedAction({ error: e, action })))
        )
      )
    )
  );

  getContentLocale$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_ONE_CONTENT_LOCALE),
      mergeMap((action: GetOneContentLocaleAction) =>
        this.contentLocalesService.getContentLocaleById(action.payload).pipe(
          map((contentLocale) => new GetOneContentLocaleSuccessAction(contentLocale)),
          catchError((e) => of(new ContentLocaleFailedAction({ error: e, action })))
        )
      )
    )
  );

  createContentLocale$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_CONTENT_LOCALE),
      mergeMap((action: CreateContentLocaleAction) =>
        this.contentLocalesService.createContentLocale(action.payload).pipe(
          map((contentLocale) => {
            this.snackBar.open($localize`Content Locale created successfully.`, $localize`Close`, {
              duration: 2000,
            });
            this.router.navigate(['site-builder/content-locales/',contentLocale && contentLocale.id]);
            return new CreateContentLocaleSuccessAction(contentLocale);
          }),
          catchError((e) => of(new ContentLocaleFailedAction({ error: e, action })))
        )
      )
    )
  );

  updateContentLocale$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_CONTENT_LOCALE),
      mergeMap((action: UpdateContentLocaleAction) =>
        this.contentLocalesService.updateContentLocale(action.payload).pipe(
          tap(() => {
            this.snackBar.open($localize`Content Locale updated successfully.`, $localize`Close`, {
              duration: 2000,
            });
          }),
          map((contentLocale) => new UpdateContentLocaleSuccessAction(contentLocale)),
          catchError((e) => {
            this.snackBar.open($localize`Failed to update content locale!`, $localize`Close`,{
              duration: 4000,
            });
            return of(new ContentLocaleFailedAction({ error: e, action }));
          })
        )
      )
    )
  );

  deleteContentLocale$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_CONTENT_LOCALE),
      mergeMap((action: DeleteContentLocaleAction) => {
        return this.contentLocalesService.deleteContentLocale(action.payload).pipe(
          map(() => new DeleteContentLocaleSuccessAction(action.payload)),
          tap(() =>
            this.snackBar.open($localize`Content Locale deleted.`, $localize`Close`, {
              duration: 4000,
            })
          ),
          catchError((e) => {
            this.snackBar.open($localize`Cannot delete content locale.`, $localize`Close`, {
              duration: 4000,
            });
            return of(new ContentLocaleFailedAction({ error: e, action }));
          })
        );
      })
    )
  );

  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .pipe(map(() => new ClearContentLocalesStateAction())));

  actionFailed$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CONTENT_LOCALE_ACTION_FAILED),
        tap((err: any) => {
          const actionType =
            (err && err.payload && err.payload.action && err.payload.action.type) ||
            $localize`Unknown`;
           this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, {
             duration: 4000,
           });
        })
      ),
    { dispatch: false }
  );
}
