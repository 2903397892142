export function generateHelpTooltip(tooltip: string, readOnly: boolean) {

  if (!readOnly) {
    return tooltip;
  }

  if (tooltip.length) {
    return tooltip + ' ' + $localize`[Read-only]`;
  }

  return $localize`Read-only`
}
