<div class="gd-tags-input"
     fxLayout="row"
     fxLayoutAlign="center center">

  <mat-form-field fxFlex="100%" appearance="outline" class="gd-tags-autocomplete__input">
    <mat-label i18n>Select tag</mat-label>
    <input #tagInputRef
           type="text"
           aria-label="Number"
           matInput
           autocomplete="off"
           placeholder="Start typing the tag name"
           i18n-placeholder
           [formControl]="tagInputFormControl"
           [matAutocomplete]="auto"
           (keyup.enter)="handleValueSelected(tagInputRef.value)">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                      (optionSelected)="handleTagSelected($event)"
                      autoActiveFirstOption>
      <mat-option *ngFor="let option of filteredOptions$ | async "
                  [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button mat-icon-button
          [disabled]="!tagInputRef.value.trim()"
          *ngIf="!loading"
          color="primary"
          (click)="handleValueSelected(tagInputRef.value)">
    <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
  </button>

  <div *ngIf="loading">
    <mat-spinner mode="indeterminate"
                 color="accent"
                 diameter="20">
    </mat-spinner>
  </div>

</div>
