import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { AdminComponent } from './admin/admin.component';
import { BreadcrumbsComponent } from './breadcrumb/breadcrumb.component';
import { FavoritesComponent } from './toolbar/favorites/favorites.component';
import { IconSidenavDirective } from './sidenav/icon-sidenav.directive';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { QuickpanelComponent } from './quickpanel/quickpanel.component';
import { SearchComponent } from './search/search.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FileManagerBreadcrumbComponent } from './file-manager-breadcrumb/file-manager-breadcrumb.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    SidenavComponent,
    SidenavItemComponent,
    IconSidenavDirective,
    SearchComponent,
    BreadcrumbsComponent,
    AdminComponent,
    QuickpanelComponent,
    LoadingOverlayComponent,
    ToolbarComponent,
    FavoritesComponent,
    FileManagerBreadcrumbComponent,
  ],
  exports: [
    SidenavComponent,
    SidenavItemComponent,
    IconSidenavDirective,
    SearchComponent,
    BreadcrumbsComponent,
    AdminComponent,
    QuickpanelComponent,
    LoadingOverlayComponent,
    ToolbarComponent,
    FavoritesComponent,
    FileManagerBreadcrumbComponent,
  ]


})
export class FuryCoreModule { }
