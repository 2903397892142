import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FilesService } from '../../core/api/files/files.service';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-file-manager-breadcrumb',
  templateUrl: './file-manager-breadcrumb.component.html',
  styleUrls: ['./file-manager-breadcrumb.component.scss']
})
export class FileManagerBreadcrumbComponent implements OnInit, OnDestroy {

  breadcrumbs = [];
  componentSub: Subscription = new Subscription();
  isFile = false;
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private filesService: FilesService,
    private bidiService: BidiService,
  ) { }

  ngOnInit(): void {
    const routeUrl = this.route.snapshot['_routerState'].url;
    this.prepareEditPageBreadcrumbs(routeUrl);
    this.breadcrumbs = this.createBreadcrumbsArray(routeUrl);

    const routeSub = this.router.events
      .pipe(filter(navigationEnd => navigationEnd instanceof NavigationEnd))
      .subscribe(({ url }: any) => {
        this.isFile = false;
        if (!url.startsWith('/media/files/edit')) {
          this.breadcrumbs = this.createBreadcrumbsArray(url)
          return;
        }
        this.prepareEditPageBreadcrumbs(url);
    });

    this.componentSub.add(routeSub);
  }

  prepareEditPageBreadcrumbs(url) {
    if (!url.startsWith('/media/files/edit')) {
        return;
    }
    const id = url
      .slice(0, url.includes('?') ? url.lastIndexOf('?') : url.length)
      .replace('/media/files/edit/', '');
    const type = this.route.snapshot.queryParams?.type;
    this.isFile = type !== 'folder';
    const path = url.replace('/media/files/edit', '');
    this.filesService.getFileById(id, type).then(item => {
      this.breadcrumbs = this.createBreadcrumbsArray(`/media/files/edit${item.path}`);
    });
  }

  resolveLocation(url) {
    if (url.startsWith('/media/files/list')) {
      return 'list';
    }
    if (url.startsWith('/media/files/edit')) {
      return 'edit';
    }
    return 'upload';
  }

  createBreadcrumbsArray(url) {
    const mainBreadcrumbs = [{ label: 'Media'}, { label: 'Files', url: '/media/files/list' }]
    const location = this.resolveLocation(url);
    const urlSegments = url.replace(`/media/files/${location}`, '')
    .split('/').filter(value => !!value).map(value => decodeURIComponent(value));

    const additionalBreadcrumbs = urlSegments.map((label, key) => {
      const breadcrumbUrl = '/media/files/list/' + urlSegments.slice(0, key + 1).join('/');
      return { label: label.split('?')[0], url: breadcrumbUrl };
    });
    const breadcrumbs = [...mainBreadcrumbs, ...additionalBreadcrumbs];

    // check if it's edit or upload
    if (['edit', 'upload'].includes(location)) {
      const labelsMap = { edit: 'Update', upload: 'Upload' };
      breadcrumbs.push({label: labelsMap[location]});
    }
    // in case of file edit form disable file breadcrumb
    if (this.isFile) {
      delete breadcrumbs[breadcrumbs.length - 2].url;
    }
    return breadcrumbs.filter(bc => !!bc?.label);
  }

  localizeLabel(label) {
    return this.breadcrumbLabelMap[label] ? this.breadcrumbLabelMap[label] : label;

  }

  breadcrumbLabelMap = {
    'Media' : $localize`Media`,
    'Files' : $localize`Files`
  }

  ngOnDestroy(): void {
    this.componentSub.unsubscribe();
  }
}
