import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { WidgetType } from '../../store/widget-types/widget-types.model';
import { map } from 'rxjs/operators';

@Injectable()
export class WidgetTypesService {
  constructor(private rest: RestService) {}

  getThirdPartyWidgets() {
    return this.rest
      .get('third-party-widgets')
      .pipe(map((response) => this.processWidgetTypes(response.data)));
  }

  getSystemWidgets() {
    return this.rest
      .get('system-widgets')
      .pipe(map((response) => this.processWidgetTypes(response.data)));
  }

  getAllWidgets() {
    return this.rest.get('widget-types').pipe(map((response) => this.processWidgetTypes(response.data)));
  }

  processWidgetTypes(typesArray: WidgetType[]) {
    if (!typesArray || typesArray.length === 0) {
      return [];
    }

    return typesArray.map((wt: WidgetType) => {
      // API currently servers widget type config as a plain text
      wt.configuration = JSON.parse(wt.configuration as string);
      return wt;
    });
  }
}
