import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import {
  CANCEL_SYSTEM_NOTIFICATION,
  CANCEL_SYSTEM_NOTIFICATION_SUCCESS,
  CREATE_SYSTEM_NOTIFICATION,
  CREATE_SYSTEM_NOTIFICATION_SUCCESS,
  DELETE_SYSTEM_NOTIFICATION,
  DELETE_SYSTEM_NOTIFICATION_SUCCESS,
  GET_SYSTEM_NOTIFICATIONS,
  GET_SYSTEM_NOTIFICATIONS_SUCCESS,
  SET_SYSTEM_NOTIFICATION_PAGE_VIEW_OPTIONS,
  SET_SYSTEM_NOTIFICATION_LOADING_FLAG,
  UPDATE_SYSTEM_NOTIFICATION,
  UPDATE_SYSTEM_NOTIFICATION_SUCCESS,
} from './system-notifications.action';

export interface SystemNotification {
  id: number;
  label: string;
  message: string;
  startAt: number;
  duration: number;
  createdAt: number;
  updatedAt: number;
  createdBy: number;
  updatedBy: number;
  canceled?: boolean;
}

export interface SystemNotificationState {
  loaded: boolean;
  loading: boolean;
  error: string;
  systemNotifications: SystemNotification[];
  systemNotificationView?: SystemNotificationPageView;
}

export interface SystemNotificationPageView {
  currentPage: number;
  total: number;
  pageSize: number;
  pageSizeOptions: any[];
}

const initialState: SystemNotificationState = {
  loaded: false,
  loading: false,
  error: null,
  systemNotifications: [],
  systemNotificationView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions,
  },
};

export function systemNotificationReducer(
  state: SystemNotificationState = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case GET_SYSTEM_NOTIFICATIONS: {
      if (action['loadingFlag']) {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      delete state.loaded,
      delete state.loading;
      return {
        ...state,
      };
    }

    case GET_SYSTEM_NOTIFICATIONS_SUCCESS: {
      const systemNotifications = action.payload.data;
      const meta = action.payload.meta;
      if (state.loading) {
        return {
          ...state,
          error: null,
          loaded: true,
          loading: false,
          systemNotifications,
          systemNotificationView: {
            ...state.systemNotificationView,
            total: meta.page.total,
          },
        };
      }
      return {
        ...state,
        error: null,
        systemNotifications,
        systemNotificationView: {
          ...state.systemNotificationView,
          total: meta.page.total,
        },
      };
    }

    case CREATE_SYSTEM_NOTIFICATION:
    case UPDATE_SYSTEM_NOTIFICATION:
    case CANCEL_SYSTEM_NOTIFICATION:
    case DELETE_SYSTEM_NOTIFICATION: {
      return { ...state, loading: true };
    }

    case CREATE_SYSTEM_NOTIFICATION_SUCCESS: {
      return { ...state, loading: false };
    }
    case UPDATE_SYSTEM_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        systemNotifications: action.payload,
      };
    }

    case CANCEL_SYSTEM_NOTIFICATION_SUCCESS: {
      const canceledId = action.payload;
      const systemNotifications = state.systemNotifications.map((systemNotification) => {
        if (systemNotification.id === canceledId) {
          return (systemNotification = { ...systemNotification, canceled: true });
        }
        return systemNotification;
      });
      return {
        ...state,
        loading: false,
        error: null,
        systemNotifications,
      };
    }

    case DELETE_SYSTEM_NOTIFICATION_SUCCESS: {
      const deletedId = action.payload;
      const systemNotifications = state.systemNotifications
        .map((systemNotification) => {
          if (systemNotification.id !== deletedId) {
            return systemNotification;
          }
        })
        .filter((systemNotifications) => !!systemNotifications);
      return {
        ...state,
        loading: false,
        error: null,
        systemNotifications,
      };
    }

    case SET_SYSTEM_NOTIFICATION_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        systemNotificationView: {
          ...state.systemNotificationView,
          currentPage: pageIndex,
          pageSize: pageSize || state.systemNotificationView.pageSize,
        },
      };
    }

    case SET_SYSTEM_NOTIFICATION_LOADING_FLAG: {
      return { ...state, loading: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getSystemNotificationState = (state) => state.systemNotifications;

export const getSystemNotificationView = createSelector(
  getSystemNotificationState,
  (state): SystemNotificationPageView => state.systemNotificationView
);

export const getSystemNotificationList = createSelector(
  getSystemNotificationState,
  (state) => state.systemNotifications
);

export const getSystemNotification = (id) =>
  createSelector(getSystemNotificationState, (state) =>
    state.systemNotifications.find((systemNotification) => systemNotification.id === id)
  );

export const getSystemNotificationLoadingFlag = (state) => {
  if (state.systemNotification?.loading) {
    return state.systemNotification.loading;
  }
};
