import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import { HtmlSnippet, HtmlSnippetViewOptions } from './html-snippets.model';
import {
  GET_HTML_SNIPPETS, GET_HTML_SNIPPETS_SUCCESS, GET_HTML_SNIPPET, CREATE_HTML_SNIPPET,
  UPDATE_HTML_SNIPPET, DELETE_HTML_SNIPPET, CREATE_HTML_SNIPPET_SUCCESS,
  DELETE_HTML_SNIPPET_SUCCESS, SET_PAGE_VIEW_OPTIONS, SET_HTML_SNIPPETS_FILTER,
  SET_ACTIVE_HTML_SNIPPET, UPDATE_HTML_SNIPPET_SUCCESS, GET_HTML_SNIPPET_SUCCESS
} from './html-snippets.actions';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';

export interface HtmlSnippetsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  htmlSnippets: HtmlSnippet[];
  htmlSnippetsView?: HtmlSnippetViewOptions;
  filter: string;
  activeHtmlSnippet: HtmlSnippet;
}

const initialState: HtmlSnippetsState = {
  loaded: false,
  loading: false,
  error: null,
  htmlSnippets: [],
  htmlSnippetsView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  filter: '',
  activeHtmlSnippet: null
};

export function htmlSnippetsReducer(state: HtmlSnippetsState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_HTML_SNIPPETS: {
      return {
        ...state,
        loading: true,
        loaded: false,
        htmlSnippetsView: {
          ...state.htmlSnippetsView,
          currentPage: 0
        },
        filter: '',
        activeHtmlSnippet: null
      };
    }

    case GET_HTML_SNIPPETS_SUCCESS: {
      const htmlSnippets = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        htmlSnippets,
        htmlSnippetsView: {
          ...state.htmlSnippetsView,
          total: htmlSnippets.length
        }
      };
    }

    case GET_HTML_SNIPPET:
    case CREATE_HTML_SNIPPET:
    case UPDATE_HTML_SNIPPET:
    case DELETE_HTML_SNIPPET: {
      return { ...state, loading: true };
    }

    case GET_HTML_SNIPPET_SUCCESS:
    case CREATE_HTML_SNIPPET_SUCCESS:
    case UPDATE_HTML_SNIPPET_SUCCESS: {
      const htmlSnippet = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        activeHtmlSnippet: htmlSnippet
      };
    }

    case DELETE_HTML_SNIPPET_SUCCESS: {
      const deletedId = action.payload;
      const total = state.htmlSnippetsView['total'];
      const htmlSnippets = state.htmlSnippets.filter(htmlSnippet => htmlSnippet.id !== deletedId);
      return {
        ...state,
        loading: false,
        error: null,
        htmlSnippets,
        htmlSnippetsView: {
          ...state.htmlSnippetsView,
          total: total - 1
      }
    };
  }

    case SET_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        htmlSnippetsView: {
          ...state.htmlSnippetsView,
          currentPage: pageIndex,
          pageSize: pageSize
        }
      };
    }

    case SET_HTML_SNIPPETS_FILTER: {
      const filter = typeof action.payload.filter === 'string' ? action.payload.filter.trim().toLowerCase() : '';
      const total = state.htmlSnippets.filter(item => item.label.toLowerCase().includes(filter)).length;
      const currentPage = action.payload.currentPage;
      return {
        ...state,
        htmlSnippetsView: {
          ...state.htmlSnippetsView,
          currentPage,
          total
        },
        filter
      };
    }

    // action will set active html snippet if exist in store
    case SET_ACTIVE_HTML_SNIPPET: {
      const id = action.payload;
      const activeHtmlSnippet = state.htmlSnippets.find(item => item.id === id);
      return {
        ...state,
        activeHtmlSnippet
      };
    }

    default: {
      return state;
    }
  }

}
// HtmlSnippetsState
export const getHtmlSnippetsState = (state): HtmlSnippetsState => state.htmlSnippets;

export const getHtmlSnippetsLoading = createSelector(getHtmlSnippetsState, state => state.loading);

export const getHtmlSnippetsLoaded = createSelector(getHtmlSnippetsState, state => state.loaded);

export const getHtmlSnippetsPageView = createSelector(getHtmlSnippetsState, (state): HtmlSnippetViewOptions => state.htmlSnippetsView);

export const getHtmlSnippetsList = createSelector(getHtmlSnippetsState, state => state.htmlSnippets);

export const getActiveHtmlSnippet = createSelector(getHtmlSnippetsState, state => state.activeHtmlSnippet);

// helper selector function for filtering and pagination
export const getFilteredHtmlSnippetsList = (applyPagination = true) => createSelector(getHtmlSnippetsState, (state) => {
  const filteredHtmlSnippets = state.htmlSnippets.filter(item => item.label.toLowerCase().includes(state.filter));
  if (filteredHtmlSnippets.length === 0) {
    return [];
  }
  if (!applyPagination) {
    return filteredHtmlSnippets;
  }
  const startIndex = state.htmlSnippetsView.currentPage * state.htmlSnippetsView.pageSize;
  const endIndex = startIndex + state.htmlSnippetsView.pageSize;
  return filteredHtmlSnippets.slice(startIndex, endIndex);
});

export const getHtmlSnippet = id => createSelector(getHtmlSnippetsState, (state) => {
  if (!id) { return null; }
  return Object.values(state.htmlSnippets).find((item: any) => item.id === id);
});

