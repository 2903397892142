import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { StorageService } from '../storage/storage.service';
import { AppState } from '../../store/app-reducer';
import { Store } from '@ngrx/store';
import { RefreshAccountSettingsSuccessAction, RefreshAccountSettingsAction } from '../../store/account-settings/account-settings.actions';
import * as moment from 'moment-timezone';
import { NON_SENSITIVE_SETTINGS } from '../../../glide-users/+settings/settings-configuration';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { get } from 'lodash-es';

@Injectable()
export class AccountSettingsService {

  restParams = {
    service: 'glideUsers',
  };

  settings;

  allowedUrlChars = [
    { label: `a-z / 0-9 / "-" / "."/ "_" / "~"`, value: 'a-z0-9_.~-' },
    { label: `a-z / A-Z / 0-9 / "-" / "."/ "_" / "~"`, value: 'a-zA-Z0-9_.~-'},
    { label: `a-z / 0-9 / "-" / "_" / "~"`, value: 'a-z0-9_~-' },
    { label: `a-z / A-Z / 0-9 / "-" / "_" / "~"`, value: 'a-zA-Z0-9_~-' },
    { label: `a-z / 0-9 / "-"`, value: 'a-z0-9-' },
    { label: `a-z / A-Z / 0-9 / "-"`, value: 'a-zA-Z0-9-' },
  ]

  constructor(
    private rest: RestService,
    private storageService: StorageService,
    private store: Store<AppState>,
    private authService: AuthService
  ) {
    this.authService.getAuthDataStream().subscribe(tokenData => {
      if (!tokenData) {
        this.reset();
        return;
      }
      this.init(tokenData.accountId);
    });
   }

  getAccountSettings(accountId) {
    return this.rest.get(`accounts/${accountId}/settings`, this.restParams).pipe(
      map(response => response.data));
  }

  updateAccountSettings({ accountId, data }) {
    return this.rest.put(`accounts/${accountId}/settings`, data, this.restParams).pipe(
      map((response: any) => response.data));
  }

  refreshAccountSettingsInStore(accountId, settings, showTaxonomyPath = null, manualTaxonomiesOrdering = null) {
    if (showTaxonomyPath === null) {
      showTaxonomyPath = this.getShowTaxonomyPathFlag();
    }
    if (manualTaxonomiesOrdering === null) {
      manualTaxonomiesOrdering = this.getManualTaxonomyReordering();
    }
    this.settings = { ...settings, showTaxonomyPath, manualTaxonomiesOrdering };
    this.storageService.saveAccountSettingsInStore(accountId, prepareSettingsForStore(this.settings));
  }

  init(accountId) {
    // load old settings from local storage and update settings state
    this.settings = this.storageService.loadAccountSettingsFromStore(accountId);
    const settings = { ...this.settings };
    // taxonomy path flag is not part of account settings
    delete settings.showTaxonomyPath;
    this.store.dispatch(new RefreshAccountSettingsSuccessAction(settings));
    // get fresh settings from database
    setTimeout(() => this.store.dispatch(new RefreshAccountSettingsAction()), 0);
  }

  reset() {
    this.settings = {};
  }

  getTimezone() {
    const settings = this.settings && this.settings.siteConfig || {};
    return settings.timezone || moment.tz.guess();
  }

  getBaseUrl() {
    const settings = this.settings && this.settings.siteConfig || {};
    return settings.baseUrl || '';
  }

  getNumberOfRelatedArticles() {
    const settings = this.settings && this.settings.relatedArticles || {};
    return settings.numberOfRelatedArticles || 5;
  }

  getDeliverDomain() {
    const deliverDomain = this.settings.siteConfig &&  this.settings.siteConfig.baseUrl;
    return deliverDomain;
  }

  getCharacterCountLimits() {
    return this.settings && this.settings.characterCountLimits || {};
  }

  getShortDateFormat() {
    const settings = this.settings && this.settings.siteConfig || {};
    return settings.shortDateFormat || null;
  }

  getShowTaxonomyPathFlag() {
    return this.settings && this.settings.showTaxonomyPath || false;
  }

  getManualTaxonomyReordering() {
    return this.settings && this.settings.manualTaxonomiesOrdering || {};
  }

  getMediaBaseUrl() {
    const settings = this.settings && this.settings.siteConfig || {};
    return settings.mediaBaseUrl || environment.glideMediaCDN;
  }

  getCollectionLockingEnabledFlag() {
    const settings = this.settings && this.settings.collectionLocking || {};
    return settings.enabled || false;
  }

  getPreviewServiceSettings() {
    return  this.settings && this.settings.previewService || {};
  }

  getPreviewServiceVersion() {
    const settings = this.settings && this.settings.previewService || {};
    return settings.version || null;
  }

  getAutosaveSettings() {
    return get(this.settings, 'autosave', {});
  }

  getLanguageSettings() {
    const settings = this.settings && this.settings.i18n || {};
    return settings.defaultLocale;
  }

  getAllowedUrlChars() {
    const settings = this.settings && this.settings.urlSeo || {};
    const allowedCharsSettings = settings.allowedCharacters;
    if (!allowedCharsSettings) {
      return;
    }
    const allowedChars = this.allowedUrlChars.find(i => i.value === allowedCharsSettings);
    return allowedChars
  }

  isGAIAEnabled() {
    return get(this.settings, 'amazonBedrock.enabled', false);
  }

  getShowEditorPreferencesFlag() {
    const settings = this.settings && this.settings.textEditor || {};
    return settings.showEditorPreferences || false;
  }

  getSmartQuotesEnabledFlag() {
    const settings = this.settings && this.settings.textEditor || {};
    return settings.useSmartQuotes || false;
  }
}

function prepareSettingsForStore(allSettings) {
  const settings: any = { id: allSettings.id };
  Object.keys(NON_SENSITIVE_SETTINGS)
    .filter(key => key !== 'showTaxonomyPath' && key !== 'manualTaxonomiesOrdering' && allSettings[key])
    .forEach(key => {
      if (typeof NON_SENSITIVE_SETTINGS[key] === 'boolean') {
        return settings[key] = { ...allSettings[key] };
      }
      settings[key] = {};
      NON_SENSITIVE_SETTINGS[key].forEach(item => settings[key][item] = allSettings[key][item]);
    });
    settings.showTaxonomyPath = allSettings.showTaxonomyPath;
    settings.manualTaxonomiesOrdering = allSettings.manualTaxonomiesOrdering;
  return settings;
}
