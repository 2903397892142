import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BidiService } from '../../../core/i18n/bidi.service';

export enum TripleChoiceDialogSelection {
  Cancel,
  Confirm,
  ConfirmWithAction,
}

@Component({
  selector: 'gd-triple-choice-dialog',
  templateUrl: './triple-choice-dialog.component.html',
  styleUrls: ['./triple-choice-dialog.component.scss'],
})
export class TripleChoiceDialogComponent {
  TripleChoiceDialogSelection = TripleChoiceDialogSelection;

  public title: string;
  public message: string;
  // buttons, basically leave, leave and do something, and stay
  public confirmNoActionButton: string;
  public confirmWithActionButton: string;
  public closeButton: string;
  public usage: string;

  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    public dialogRef: MatDialogRef<TripleChoiceDialogComponent>,
    private bidiService: BidiService
  ) {}
}
