import { UnsafeAction } from '../unsafe-action.interface';

export const SET_IS_DISPLAYED_FLAG = '[UI Section Title] Set Is Displayed flag';
export class SetIsDisplayedFlagAction implements UnsafeAction {
  readonly type = SET_IS_DISPLAYED_FLAG;
  constructor(public payload: any) { }
}

export const SET_SECTION_TITLE = '[UI Section Title] Set Section Title';
export class SetSectionTitleAction implements UnsafeAction {
  readonly type = SET_SECTION_TITLE;
  constructor(public payload: string) { }
}

export const SET_SECTION_TITLE_DATA = '[UI Section Title] Set Section Title Data';
export class SetSectionTitleDataAction implements UnsafeAction {
  readonly type = SET_SECTION_TITLE_DATA;
  constructor(public payload: { name: string, hasUpdates: boolean }) { }
}

export const RESET_UI_SECTION_TITLE_STATE = '[UI Section Title] Reset UI Section Title state';
export class ResetUISectionTitleStateAction implements UnsafeAction {
  readonly type = RESET_UI_SECTION_TITLE_STATE;
  constructor() { }
}

export const SET_SECTION_LIVE_FLAG = '[UI Section Title] Set Live flag';
export class SetSectionLiveFlagAction implements UnsafeAction {
  readonly type = SET_SECTION_LIVE_FLAG;
  constructor(public payload: any) {}
}

export const SET_SECTION_INFO = '[UI Section Title] Set Section Info';
export class SetSectionInfoAction implements UnsafeAction {
  readonly type = SET_SECTION_INFO;
  constructor(public payload: any) {}
}
