<div class="gd-editor-widget-card-frame-wrapper gd-widget-preview-wrapper" draggable="true">
  <div class="gd-editor-widget-card-frame gd-widget-preview-container"
       [class.--one-preview-field-selected]="previewFieldNumber && previewFieldNumber === 1"
       [style.border-color]="widgetColour"
       *ngIf="node?.id">
    <div class="gd-editor-widget-card-frame__header">
      <!-- <mat-icon fontSet="far" fontIcon="fa-grip-lines" style="font-size: 18px; width: 18px; height: 18px;"></mat-icon> -->
      <div class="gd-editor-widget-card-frame__widget-name">
        <span class="gd-editor-widget-card-frame__widget-icon">
          <mat-icon *ngIf="!!widgetIcon && (widgetIcon)?.type === 'font_awesome'"
                    [fontSet]="(widgetIcon)?.name.split(' ')[0]"
                    [fontIcon]="(widgetIcon)?.name.split(' ')[1]">
          </mat-icon>
          <mat-icon *ngIf="!!widgetIcon && widgetIcon?.isCustomSvg"
                    [svgIcon]="widgetIcon.icon">
          </mat-icon>
        </span>
        <span [matTooltip]="node?.title || widget?.name"
              class="gd-editor-widget-card-frame__widget-name-text">
          {{ node?.title || widget?.name }}
        </span>
      </div>
      <div class="gd-editor-widget-card-frame__action-buttons"
           *ngIf="usage !== 'quickView'">
        <!-- display and align menu items -->
        <mat-menu #menuAlign="matMenu">
          <ng-container *ngFor="let option of alignOptions let i = index">
            <button mat-menu-item
                    class="gd-editor-widget-card-frame__align-menu"
                    [value]="option.id"
                    i18n="@@alignOptionsName"
                    (click)="editAlign(option)"
                    [style]="option.id === alignOptionSelected ? 'background: rgba(0, 0, 0, .12)' : ''">
              <mat-icon fontSet="fal"
                        [fontIcon]="option.icon"
                        i18n-matTooltip
                        matTooltipPosition="above"></mat-icon>
            </button>
          </ng-container>
        </mat-menu>
        <mat-menu #menuDisplay="matMenu">
          <ng-container *ngFor="let option of displayOptions let i = index">
            <button mat-menu-item
                    [value]="option.id"
                    i18n="@@displayOptionsName"
                    (click)="editDisplay(option.id)"
                    [style]="option.id === displayOptionSelected ? 'background: rgba(0, 0, 0, .12)' : ''">
              {{option.name}}
            </button>
          </ng-container>
        </mat-menu>
        <!-- end of display and align menu items -->

        <!-- button and menu for one column widget preview -->
        <button mat-icon-button
                class="gd-editor-widget-card-frame__action-buttons-menu"
                (click)="editNode()">
          <mat-icon fontSet="fal"
                    fontIcon="fa-edit"
                    style="font-size: 16px"></mat-icon>
        </button>
        <button mat-icon-button
                [matMenuTriggerFor]="WidgetActionButtons"
                class="gd-editor-widget-card-frame__action-buttons-menu">
          <mat-icon fontSet="fal"
                    fontIcon="fa-ellipsis-v"></mat-icon>
        </button>
        <mat-menu overlapTrigger="true"
                  #WidgetActionButtons="matMenu"
                  yPosition="below">
          <button mat-menu-item
                  [matMenuTriggerFor]="menuAlign">
            <mat-icon fontSet="far"
                      fontIcon="fa-align-justify"
                      matTooltip="Align"
                      i18n-matTooltip
                      matTooltipPosition="above"></mat-icon>
            Align</button>
          <button mat-menu-item
                  [matMenuTriggerFor]="menuDisplay">
            <mat-icon fontSet="fas"
                      [svgIcon]="'glideCustomIcon_displayWidgetCard'"
                      mat-button-icon
                      i18n-matTooltip
                      matTooltipPosition="above"></mat-icon>Display</button>
          <button mat-menu-item
                  type="button"
                  (click)="deleteNode()">
            <mat-icon fontSet="fal"
                      fontIcon="fa-trash"
                      matTooltip="Delete"
                      i18n-matTooltip
                      matTooltipPosition="above">
            </mat-icon> Delete Item</button>
        </mat-menu>
      </div>
    </div>
    <div class="gd-editor-widget-card-frame__preview-fields gd-scroller-custom">
      <gd-widget-card-builder [widgetId]="node.dataId"
                              [config]="node.configuration"
                              [contextShadowRoot]="contextShadowRoot"
                              (editItem)="editNode()"></gd-widget-card-builder>
    </div>
  </div>
</div>
