import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { formControlFactory } from '../../../glide-create/+site-builder/+templates/widget-form-utilities';

// TODO investigate where and how this is used
@Component({
  selector: 'gd-field-array-builder',
  templateUrl: './field-array-builder.component.html',
  styleUrls: ['./field-array-builder.component.scss']
})
export class FieldArrayBuilderComponent {

  @Input() arrayFieldConfig;
  @Input() arrayFieldControl: UntypedFormArray;
  @Input() hasActionPermission = true;
  @Input() isEditingMode;
  @Input() usage: string;
  @Input() activeLocale = null;
  @Input() readOnlyMode = false;

  constructor(private formBuilder: UntypedFormBuilder) { }

  addGroupToArrayFor(event) {
    event.preventDefault();
    event.stopPropagation();
    // generate form group model from the array fields configuration
    const formGroup = this.arrayFieldConfig.fields.reduce((formGroupModel, field) => {
      formGroupModel[field.key] = formControlFactory(field, this.formBuilder);
      return formGroupModel;
    }, {});
    this.arrayFieldControl.push(this.formBuilder.group(formGroup));
  }

  removeGroupFromArrayFor(event: Event, index?) {
    event.preventDefault();
    event.stopPropagation();
    this.arrayFieldControl.removeAt(index);
  }

}
