import { mergeMap, catchError, map, withLatestFrom, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppState } from '../app-reducer';
import {
  GET_SUBSCRIBERS,
  SubscriberFailedAction,
  GetSubscribersSuccessAction,
  CREATE_SUBSCRIBER,
  CreateSubscriberSuccessAction,
  DELETE_SUBSCRIBER,
  DeleteSubscriberSuccessAction,
  UPDATE_SUBSCRIBER,
  UpdateSubscriberSuccessAction,
  SUBSCRIBER_ACTION_FAILED,
  GET_ONE_SUBSCRIBER,
  GetOneSubscriberSuccessAction,
  GetTransmitEventsSuccessAction,
  GET_TRANSMIT_EVENTS,
  CreateSubscriberAction,
  GetTransmitEventsAction,
  DeleteSubscriberAction,
  UpdateSubscriberAction,
  GetSubscribersAction,
  GetOneSubscriberAction,
} from './subscribers.actions';
import { SubscribersService } from '../../api/subscribers/subscribers.service';

@Injectable()
export class SubscribersEffects {

  loadSubscribers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_SUBSCRIBERS),
    withLatestFrom(this.store.select((state) => state.subscribers.subscribersView)),
    mergeMap(([action, pageView]: [GetSubscribersAction, any]) => {
      return this.subscribersService.getSubscribers({ ...pageView, ...action.payload }).pipe(
        map((response) => new GetSubscribersSuccessAction(response)),
        catchError((e) => of(new SubscriberFailedAction({ error: e, action })))
      );
    })
  ));

  getSubscriber$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ONE_SUBSCRIBER),
    mergeMap((action: GetOneSubscriberAction) => {
      return this.subscribersService.getSubscriberById(action.payload).pipe(
        map((res: any) => new GetOneSubscriberSuccessAction(res)),
        catchError((e) => {
          this.snackBar.open($localize`Subscriber not found.`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['transmit/subscribers']);
          return of(new SubscriberFailedAction({ error: e, action }));
        })
      )
    })
  ));

  getTransmitEventsArray$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_TRANSMIT_EVENTS),
    mergeMap((action: GetTransmitEventsAction) => {
      return this.subscribersService.getTransmitEvents().pipe(
        map((res: any) => new GetTransmitEventsSuccessAction(res)),
        catchError((e) => of(new SubscriberFailedAction({ error: e, action })))
      )
    })
  ));

  createSubscriber$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_SUBSCRIBER),
    mergeMap((action: CreateSubscriberAction) =>
      this.subscribersService.createSubscriber(action.payload).pipe(
        map((subscriber) => {
          this.snackBar.open($localize`Subscriber created successfully.`, $localize`Close`, {
            duration: 2000,
          });
          this.router.navigate(['transmit/subscribers/', subscriber && subscriber.id]);
          return new CreateSubscriberSuccessAction(subscriber);
        }),
        catchError((e) => of(new SubscriberFailedAction({ error: e, action })))
      )
    )
  ));

  deleteSubscriber$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_SUBSCRIBER),
    mergeMap((action: DeleteSubscriberAction) => {
      const id = action.payload;
      return this.subscribersService.deleteSubscriber(id).pipe(
        map(() => new DeleteSubscriberSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Subscriber deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Cannot delete subscriber.`, $localize`Close`, {
            duration: 4000,
          });
          return of(new SubscriberFailedAction({ error: e, action }));
        })
      );
    })
  ));

  updateSubscriber$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_SUBSCRIBER),
    mergeMap((action: UpdateSubscriberAction) => {
      return this.subscribersService.updateSubscriber(action.payload).pipe(
        map((data) => new UpdateSubscriberSuccessAction(data)),
        tap(() => {
          this.snackBar.open($localize`Subscriber successfully updated.`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update subscriber!`, $localize`Close`);
          return of(new SubscriberFailedAction({ error: e, action }));
        })
      );
    })
  ));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SUBSCRIBER_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private subscribersService: SubscribersService
  ) { }
}
