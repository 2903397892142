import {debounceTime,  filter, take } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { getWorkingRevision } from '../../../core/store';
import { combineLatest, Subscription } from 'rxjs';
import { getArticleTypes } from '../../../core/store/article-type/article-type.reducer';
import { loadImageUrl } from '../../../core/api';
import { BidiService } from '../../../core/i18n/bidi.service';


declare const $: any;

@Component({
  selector: 'gd-embed-image-url-dialog',
  templateUrl: './embed-image-url-dialog.component.html',
  styleUrls: ['./embed-image-url-dialog.component.scss'],
})
export class EmbedImageViaURLComponent implements OnInit, OnDestroy {
  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    url: ['', Validators.required],
    src: [''],
    caption: [''],
    credit: [''],
    alt: [''],
    imageStyle: ['default'],
  });

  blockId;
  displayImage = '/assets/img/embed-image-url-gray.svg';
  editingMode = false;
  isAltTextLoaded = false;

  get urlControl() {
    return <UntypedFormControl>this.embeddableForm.get('url');
  }

  get captionControl() {
    return <UntypedFormControl>this.embeddableForm.get('caption');
  }

  get altControl() {
    return <UntypedFormControl>this.embeddableForm.get('alt');
  }

  articleImageStyles = [];
  isArticleForm;
  componentSub: Subscription = new Subscription();
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedImageViaURLComponent>,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.editingMode = this.dialogInputData['editingMode'] ? true : false;
    this.blockId = this.dialogInputData.blockId || null;
    this.isArticleForm = !!this.blockId;

    if (this.editingMode && this.dialogInputData.url) {
      loadImageUrl(this.dialogInputData.url)
      .then(() => (this.displayImage = this.dialogInputData.url))
      .catch((err) => {
        this.displayImage = '/assets/img/embed-image-url-gray.svg';
        this.urlControl.setErrors(err);
      });;
      this.isAltTextLoaded = !!this.dialogInputData.alt;
      this.embeddableForm.patchValue(this.dialogInputData);
    }

    const glideContentType = this.dialogInputData.glideContentType;
    // TODO refactor this
    if (glideContentType === 'ARTICLE') {
      combineLatest([
        this.store.select(getWorkingRevision),
        this.store.select(getArticleTypes)
      ])
        .pipe(
          filter(([workingRev, articleTypes]) => !!workingRev && !!articleTypes),
          take(1)
        )
        .subscribe(([workingRev, articleTypes]) => {
          const articleType = articleTypes[workingRev.typeId];
          this.articleImageStyles = [...(articleType.customImageStyles || [])];
        });
    }

    // Use caption as alt if alt hasn't been set
    this.componentSub.add(
      this.captionControl.valueChanges.pipe(debounceTime(200)).subscribe(caption => {
        const shouldUseCaption =
          (this.altControl.pristine && !this.isAltTextLoaded) || !this.altControl.value;
        if (shouldUseCaption) {
          this.altControl.setValue(caption);
        }
      })
    );

    this.urlControl.valueChanges.pipe(debounceTime(50)).subscribe((imageUrl) => {
      loadImageUrl(imageUrl)
        .then((val) => (this.displayImage = imageUrl))
        .catch((err) => {
          this.displayImage = '/assets/img/embed-image-url-gray.svg';
          return this.urlControl.setErrors(err);
        });
    });
  }

  save() {
    const formModel = {
      url: this.embeddableForm.get('url').value,
      src: this.embeddableForm.get('url').value,
      caption: this.embeddableForm.get('caption').value || '',
      credit: this.embeddableForm.get('credit').value || '',
      alt: this.embeddableForm.get('alt').value || '',
      imageStyle: this.embeddableForm.get('imageStyle').value,
      id: this.embeddableForm.get('url').value,
    };
    if (!this.isArticleForm) {
      return this.dialogRef.close(formModel);
    }

    const contentNodeData = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel,
    };
    this.dialogRef.close(contentNodeData);
    return;
  }

  ngOnDestroy() {
    this.componentSub.unsubscribe();
  }
}
