import { mergeMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app-reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RestService } from '../../api';
import { AdvertGroup } from './adverts-group.model';
import { Observable, of } from 'rxjs';
import * as advertsGroupAction from './adverts-group.actions';
import { UnsafeAction } from '../unsafe-action.interface';

@Injectable()
export class AdvertsGroupEffects {

  loadAdvertsGroupList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsGroupAction.GET_ADVERTS_GROUP),
    withLatestFrom(this.store.select((state) => state.advertsGroup.advertGroupsView)),
    mergeMap(([action, advertGroupsView]: [UnsafeAction, any]) => {
      return this.getAdvertsGroup({ ...action.payload, pageView: advertGroupsView }).pipe(
        map(
          (advertsGroupListData) =>
            new advertsGroupAction.GetAdvertsGroupSuccessAction(advertsGroupListData)
        ),
        catchError((e) => of(new advertsGroupAction.GetAdvertsGroupErrorAction(e)))
      );
    })
  ));


  createAdvertsGroup$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(advertsGroupAction.CREATE_ADVERTS_GROUP))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        return this.createAdvertGroup(action.payload).pipe(
          tap((advert) => {
            this.router.navigate(['/widgets/advert-groups', advert.id]);
            this.snackBar.open('Advert group created', $localize`Close`, { duration: 4000 });
          }),
          map((payload) => new advertsGroupAction.CreateAdvertsGroupSuccessAction(payload)),
          catchError((e) => of(new advertsGroupAction.GetAdvertsGroupErrorAction(e)))
        );
      })
    ));


  deleteAdvertsGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsGroupAction.DELETE_ADVERTS_GROUP),
    mergeMap((action: UnsafeAction) => {
      const idToDelete = action.payload.id;
      return this.deleteAdvertsGroup(idToDelete).pipe(
        map(() => new advertsGroupAction.DeleteAdvertsGroupSuccessAction(idToDelete)),
        tap(() => this.snackBar.open('Advert group deleted', $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open('Failed to delete Advert group!', $localize`Close`);
          return of(new advertsGroupAction.GetAdvertsGroupErrorAction(e));
        })
      );
    })
  ));


  updateAdvertsGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsGroupAction.UPDATE_ADVERTS_GROUP),
    mergeMap((action: UnsafeAction) => {
      const advertsGrouptoUpdate = action.payload;
      return this.updateAdvertsGroup(advertsGrouptoUpdate).pipe(
        map(() => new advertsGroupAction.UpdateAdvertsGroupSuccessAction(advertsGrouptoUpdate)),
        tap(() => {
          this.router.navigate(['/widgets/advert-groups']);
          this.snackBar.open('Advert group successfully updated', $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open('Failed to update Advert group!', $localize`Close`);
          return of(new advertsGroupAction.GetAdvertsGroupErrorAction(e));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsGroupAction.ADVERTS_GROUP_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private actions$: Actions,
    private rest: RestService
  ) {}

  // change end-point
  getAdvertsGroupById(id: number) {
    return this.rest.get('widgets/advert-groups/' + id).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  getAdvertsGroup(payload) {
    const { name, pageView } = payload;
    let queryUrl = `widgets/advert-groups?size=${pageView.pageSize}&page=${pageView.currentPage}`;
    if (name) {
      queryUrl += `&name=${name}`;
    }
    return this.rest.get(queryUrl).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  createAdvertGroup(payload: AdvertGroup) {
    const newAdvert = { ...payload };
    delete newAdvert.id;
    return this.rest.post('widgets/advert-groups/', newAdvert).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  updateAdvertsGroup(payload: AdvertGroup) {
    const updatedAdvert = { ...payload };
    return this.rest.put('widgets/advert-groups/' + payload.id, updatedAdvert).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  deleteAdvertsGroup(id: number) {
    return this.rest.delete('widgets/advert-groups/' + id).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }
}
