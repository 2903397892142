export interface ArticleQuickViewState {
  article: {
    id: number;
    publishedRevision: {};
    latestRevision: {};
  };
  referencedContent: {};
  loading: boolean;
}

export function getArticleQuickViewInitialState(): ArticleQuickViewState {
  return {
    article: {
      id: null,
      publishedRevision: {},
      latestRevision: {},
    },
    referencedContent: {},
    loading: false,
  };
}
