import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app-reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { RestService } from '../../api';
import { Observable, of } from 'rxjs';
import * as advertsAction from './adverts.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { Advert } from './adverts.model';

@Injectable()
export class AdvertsEffects {

  loadAdvertsList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsAction.GET_ADVERTS),
    mergeMap((action) => {
      return this.getAdvert(action['payload']).pipe(
        map((advertsListData) => new advertsAction.GetAdvertsSuccessAction(advertsListData)),
        catchError((e) => of(new advertsAction.GetAdvertsErrorAction(e)))
      );
    })
  ));


  createAdvert$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsAction.CREATE_ADVERTS),
    mergeMap((action: UnsafeAction) => {
      return this.createAdvert(action.payload).pipe(
        map((advertsListData) => new advertsAction.CreateAdvertsSuccessAction(advertsListData)),
        tap(() => {
          this.router.navigate(['/widgets/adverts']);
          this.snackBar.open($localize`Adverts created`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => of(new advertsAction.GetAdvertsErrorAction(e)))
      );
    })
  ));


  deleteAdvert$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsAction.DELETE_ADVERTS),
    mergeMap((action: UnsafeAction) => {
      const idToDelete = action.payload.id;
      return this.deleteAdvert(idToDelete).pipe(
        map(() => new advertsAction.DeleteAdvertsSuccessAction(idToDelete)),
        tap(() => this.snackBar.open($localize`Advert deleted`, $localize`Close`, { duration: 4000 })),
        catchError((e) => {
          this.snackBar.open($localize`Failed to delete advert!`, $localize`Close`);
          return of(new advertsAction.GetAdvertsErrorAction(e));
        })
      );
    })
  ));


  updateAdvert$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsAction.UPDATE_ADVERTS),
    mergeMap((action: UnsafeAction) => {
      const advertToUpdate = action.payload;
      return this.updateAdvert(advertToUpdate).pipe(
        map(() => new advertsAction.UpdateAdvertsSuccessAction(advertToUpdate)),
        tap(() => {
          this.router.navigate(['/widgets/adverts']);
          this.snackBar.open($localize`Adverts successfully updated`, $localize`Close`, { duration: 4000 });
        }),
        catchError((e) => {
          this.snackBar.open($localize`Failed to update Adverts!`, $localize`Close`);
          return of(new advertsAction.GetAdvertsErrorAction(e));
        })
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(advertsAction.ADVERTS_ACTION_FAILED),
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private actions$: Actions,
    private rest: RestService
  ) {}

  getAdvertById(id: number) {
    return this.rest.get('widgets/adverts/' + id).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  getAdvert({ page = 0, limit = 10, name = '', type = '' }) {
    let requestPath = `widgets/adverts?page=${page || 0}&size=${limit || 10}`;
    if (name) {
      requestPath += `&name=${name}`;
    }
    if (type) {
      requestPath += `&type=${type}`;
    }
    return this.rest.get(requestPath).pipe(
      map((advertsData: any) => {
        const adverts = advertsData.data;
        const query = { page: advertsData.meta.page };
        return { adverts, query };
      })
    );
  }

  createAdvert(payload: Advert) {
    const newAdvert = { ...payload };
    delete newAdvert.id;
    return this.rest.post('widgets/adverts', newAdvert).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  updateAdvert(payload: Advert) {
    const updatedAdvert = { ...payload };
    return this.rest.put('widgets/adverts/' + payload.id, updatedAdvert).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }

  deleteAdvert(id: number) {
    return this.rest.delete('widgets/adverts/' + id).pipe(
      map((adverts: any) => {
        return adverts.data;
      })
    );
  }
}
