
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CoreModule } from '@angular/flex-layout';
import { RestService } from '..';

@Injectable({
  providedIn: CoreModule
})
export class WorkflowsService {

  constructor(private rest: RestService) { }

  getActiveWorkflow() {
    return this.rest.get('workflows/active-workflow').pipe(
      map(response => response.data),
      map(wf => {
        wf.statesGraph = this.parseStateTrasnitionsGraph(wf.workflowTransitions);
        return wf;
      }),);
  }

  setActiveWorkflow(id: number) {
    return this.rest.post('workflows/active-workflow', { id }).pipe(
      map(response => response.data));
  }

  getWorkflows() {
    return this.rest.get('workflows').pipe(
      map(response => response.data),
      map(wf => {
        wf.map(w => {
          w.statesGraph = this.parseStateTrasnitionsGraph(w.workflowTransitions);

          return w;
        });
        return wf;
      }),);
  }

  parseStateTrasnitionsGraph(workflowTransitions: any[]) {
    return workflowTransitions.reduce((statesSet, transition) => {
      const end = statesSet[transition.endingState.id] || transition.endingState;
      const start = statesSet[transition.startingState.id] || transition.startingState;
      start.canTransitionTo = [...(start.canTransitionTo || []), end.id];
      statesSet[start.id] = start;
      statesSet[end.id] = end;
      return statesSet;
    }, {});
  }


}
