import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
  CLEAR_REFERENCED_CONTENT_REPOSITORY,
  GET_REFERENCED_CONTENT,
  GET_REFERENCED_CONTENT_SUCCESS,
} from './referenced-content-repository.actions';
import { getUniqueArray, valueAsArray } from '..';
import { ReferencedContentRepository } from './referenced-content-repository.model';
import { cloneDeep } from 'lodash';

const initialState: ReferencedContentRepository = {
  items: {},
};

export function referencedContentRepositoryReducer(
  state: ReferencedContentRepository = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case GET_REFERENCED_CONTENT: {
      const type = action.payload.type;
      const ids = valueAsArray(action.payload.ids);
      return {
        ...state,
        items: {
          ...state.items,
          ...ids.reduce(
            (acc, id) => ({
              ...acc,
              [`${type}:${id}`]: { data: {}, sysMeta: { id, loading: true } },
            }),
            {}
          ),
        },
      };
    }

    case GET_REFERENCED_CONTENT_SUCCESS: {
      const type = action.payload.type;
      const data = valueAsArray(action.payload.response.data || []);
      const ids = getUniqueArray(
        [...valueAsArray(action.payload.ids), ...data.map((item) => item.id)].filter((id) => !!id)
      );
      const error = action.payload.response.error;
      const sysMeta = { loading: false, loadedAt: Date.now() };
      return {
        ...state,
        items: {
          ...state.items,
          ...ids.reduce((acc, id) => {
            const item = data.find((el) => el?.id === id) || {};
            return {
              ...acc,
              [`${type}:${id}`]: {
                data: { ...item },
                sysMeta: {
                  ...sysMeta,
                  id,
                  deleted: !item?.id && (!error || error?.status !== 403),
                  allowed: error?.status !== 403,
                },
              },
            };
          }, {}),
        },
      };
    }

    case CLEAR_REFERENCED_CONTENT_REPOSITORY: {
      return cloneDeep(initialState);
    }

    default: {
      return state;
    }
  }
}

export const getReferencedContentRepositoryState = (state) => state.referencedContentRepository;

export const getReferencedContentRepository = createSelector(
  getReferencedContentRepositoryState,
  (state): ReferencedContentRepository => state.items
);

export const getReferencedContentItems = (ids, type) =>
  createSelector(getReferencedContentRepositoryState, (state) => {
    return ids.reduce((acc, id) => {
      const item = state.items[`${type}:${id}`];
      if (!item) {
        return acc;
      }
      const includeInResults =
        item.sysMeta.loading || item.sysMeta.deleted || !!item.sysMeta.loadedAt;
      return includeInResults ? [...acc, item] : acc;
    }, []);
  });
