import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  Inject,
  ElementRef,
  Renderer2,
  DoCheck,
} from '@angular/core';
import { Subscription , Observable , combineLatest } from 'rxjs';
import { UploadEditFileFormComponent } from '../upload-edit-file-form/upload-edit-file-form.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { UpdateQueuedFileAction, ResetFilesUploadStateAction } from '../../core/store/files-upload/files-upload.actions';
import { FilesService } from '../../core/api/files/files.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { fileQueueBeingProcessed } from '../../core/store/files-upload/files-upload.reducer';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { getFiles, getActiveFolder, getFilesPageView } from '../../core/store/files/files.reducer';
import { GetUsersAction, getUsersList, ClearUsersListAction, getUsersMap } from '../../core/store/users';
import { filter, map, debounceTime, tap, startWith, take } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { GET_FILES_COMPLETE, GetFilesAction, ResetFilesListAction, SetActiveFolderAction, SetFilesPageViewOptions } from '../../core/store/files/files.actions';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { UserPreferencesService } from '../../core/api/user-preferences/user-preferences.service';
import { ImagesService } from '../../core/api/images/images.service';
import { GlideContentActionBusService } from '../../core/body-content/glide-content-actions-bus.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { unionBy, isEmpty } from 'lodash';
import { getUniqueArray } from '../../core/store';
import { BidiService } from '../../core/i18n/bidi.service';
import { Actions } from '@ngrx/effects';

const CUSTOM_FORMAT = {
  parse: { dateInput: 'DD-MMM-YYYY' },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'gd-embed-files-dialog',
  templateUrl: './embed-files-dialog.component.html',
  styleUrls: ['./embed-files-dialog.component.scss'],
  animations: [
    trigger('showMetaCard', [
      state('preview', style({ transform: 'translateX(100%)' })),
      state('details', style({ transform: 'translateX(0%)' })),
      state('*', style({ transform: 'translateX(0%)' })),
      state('void', style({ transform: 'translateX(100%)' })),
      transition('preview => details', [animate(300)]),
      transition('details => preview', [animate(300)]),
      transition('void => *', [animate(300)]),
      transition('* => void', [animate(300)]),
    ]),
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMAT }],
})
export class EmbedFilesDialogComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  @ViewChild('fileUpload', { static: true }) fileUpload;
  @ViewChild('fileMetaForm') fileMetaForm: UploadEditFileFormComponent;
  @ViewChild('scrollbar') scrollbar;

  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  filterFormGroup: UntypedFormGroup;
  displayedColumns = ['key', 'owner', 'uploadedDate', 'control'];
  secondRowDisplayedColumns = this.displayedColumns.map(col => col + '-second-row');
  thirdRowDisplayedColumns = this.displayedColumns.map(col => col + '-third-row');
  loaded$: Observable<boolean> = this.store.select(store => store.files.loaded);
  dataSource$: Observable<any>;

  activeTab = 'Library';
  enqueuedFile = null;
  hasEnqueuedFiles = false;
  queueBeingProcessed$;
  loading$;

  // Default Upload Config
  uploadConfig = {
    viewAs: 'contained',
    autoImport: true,
    multipleSelect: false,
    multiUpload: false,
    embedModal: true,
  };
  activeFolder;
  folderPath;
  filesEmbedSubs: Subscription = new Subscription();
  filterOwnerControl: UntypedFormControl = new UntypedFormControl();
  filteredUsers$: Observable<any>;
  fileData: any = [];
  modalLayout = { contentHeight: null, extraHeight: 0 };
  theme = "default";

  noResultsMessage = $localize`Folder is empty.`
  activeToggleMenuItem = 'preview';
  allFilesFlag = false;
  get selectedOwners() {
    return this.filterFormGroup.get('owners').value || [];
  }
  fileList = [];
  pageView;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private store: Store<AppState>,
    private filesService: FilesService,
    private dialogRef: MatDialogRef<EmbedFilesDialogComponent>,
    private fb: UntypedFormBuilder,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private userPreferenceService: UserPreferencesService,
    private bidiService: BidiService,
    private imagesService: ImagesService,
    private actionBus: GlideContentActionBusService,
    private actions$: Actions,
  ) {}

  ngDoCheck() {
    if (this.theme !== this.userPreferenceService.getUserPreference('theme')) {
      this.theme = this.userPreferenceService.getUserPreference('theme');
    }
  }

  ngOnInit() {
    if (this.dialogInputData.uploadConfig) {
      this.uploadConfig = { ...this.uploadConfig, ...this.dialogInputData.uploadConfig };
    }

    if (this.dialogInputData.selectedFiles) {
      this.fileData = [...this.dialogInputData.selectedFiles];
    }

    this.store.dispatch(new ResetFilesListAction());
    this.store.dispatch(new ResetFilesUploadStateAction());
    this.dataSource$ = combineLatest([
      this.store.select(getFiles),
      this.store.select(getUsersMap)
    ]).pipe(
      map((data) => {
        const [files, usersMap] = data;
        const updatedByIds = files.map(file => parseInt(file.owners[0].user_id)).filter(id => id !== 1)
        const userIds = getUniqueArray(updatedByIds) as number[];

        if(!userIds.some((id: number) => !usersMap[id])) {
          return data;
        }

        this.store.dispatch(new GetUsersAction({ ids: userIds }));
        return null;
      }),
      filter(data => !!data),
      map(([files, usersMap]) => {
        return files.map(file => {
          file.isSvgIcon = this.isCustomSvgIcon(this.resolveThumbnailImg(file));
          const ownerId = file.owners.length ? file.owners[0].user_id : null;
          const name =
            file.type === 'folder' ? file.filename : file.meta_data.name || file.filename;
          this.fileList = files.filter(file => file.type !== 'folder');
          const uploadedBy = +ownerId === 1 ? 'Superadmin' : this.extractUserInfo(usersMap[+ownerId]);
          return { ...file, name, uploadedBy };
        });
      }),
      map(files =>
        files
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          .sort((a, b) => (a.type > b.type ? -1 : 1))
      )
    );
    this.filterFormGroup = this.fb.group({
      fileName: [''],
      fileOwner: [''],
      dateFrom: [''],
      dateTo: [''],
      owners: [[]],
    });
    this.filesEmbedSubs.add(this.store.select(getActiveFolder).subscribe(activeFolder => {
      this.activeFolder = activeFolder;
    }));

    this.filesEmbedSubs.add(
      this.store.select(getFilesPageView).subscribe(pW => this.pageView = pW)
    );
    const pageSize = +this.userPreferenceService.getUserPreference('files.pageSize') || 50;
    this.store.dispatch(new SetFilesPageViewOptions({ pageSize }));
    this.filterData();
    this.initializeLoaders();

    this.filesEmbedSubs.add(
      this.filterOwnerControl.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        filter(keystroke => typeof keystroke === 'string'),
        map(keystroke => (keystroke || '').trim()),
      ).subscribe(name =>  {
        !!name ? this.store.dispatch(new GetUsersAction({ search: name })) : this.store.dispatch(new ClearUsersListAction())
      })
    );

    this.filteredUsers$ = this.store.select(getUsersList).pipe(
      map(users => {
        const user = this.filterOwnerControl.value;
        const showResults = typeof user === 'string' && !!user.trim();
        return showResults ? users : [];
    }));
  }

  ngAfterViewInit() {
    this.filesEmbedSubs.add(this.fileUpload.queuedFilesObs.subscribe(queuedFiles => {
      this.hasEnqueuedFiles = queuedFiles.length !== 0;

      if (!this.uploadConfig.multiUpload && this.hasEnqueuedFiles) {
        this.enqueuedFile = queuedFiles[0];
      }
    }));
    setTimeout(() => this.setModalContentStyles(), 0);
  }

  setModalContentStyles() {
    let actionsHeight = 0;
    let modalHeaderHeight = 0;
    let modalNavigationHeight = 0;

    const modalHeight = +window.getComputedStyle(this.elRef.nativeElement).height.replace('px', '');
    const modalActionsHeight = +window
      .getComputedStyle(this.elRef.nativeElement.querySelector('mat-dialog-actions'))
      .height.replace('px', '');
    actionsHeight = modalActionsHeight;

    const modalHeaderTabs = this.elRef.nativeElement.querySelector('mat-tab-header');
    const tabsHeight = +window.getComputedStyle(modalHeaderTabs).height.replace('px', '');
    modalHeaderHeight = tabsHeight;

    const availableHeight = modalHeight - modalHeaderHeight - actionsHeight;
    const contentWrapper = this.elRef.nativeElement.querySelector(
      '.gd-embed-media-files-tabs__content-wrapper'
    );
    this.renderer.setStyle(contentWrapper, 'height', `${availableHeight}px`);
    this.modalLayout.contentHeight = availableHeight;
  }

  filterData(resetPagination = true) {
    if (resetPagination) {
      this.store.dispatch(new SetFilesPageViewOptions({ pageIndex: 0 }));
    }
    const dateFrom = this.filterFormGroup.get('dateFrom').value;
    const dateTo = this.filterFormGroup.get('dateTo').value;
    const userIds = this.selectedOwners.map(owner => owner.id);
    if (this.dialogInputData.usage === 'watermark') {
      this.activeFolder.path = '/Watermark';
    }
    const payload = {
      folderPath: this.activeFolder.path,
      searchName: this.filterFormGroup.get('fileName').value,
      searchOwner: this.filterFormGroup.get('fileOwner').value,
      searchDateFrom: dateFrom && moment(dateFrom).format('YYYY-MM-DD'),
      searchDateTo: dateTo && moment(dateTo).format('YYYY-MM-DD'),
      userIds,
      pageIndex: this.pageView.pageIndex,
      pageSize: this.pageView.pageSize,
    };
    this.store.dispatch(new GetFilesAction(payload));
    this.actions$.pipe(filter(action => action.type === GET_FILES_COMPLETE), take(1))
      .subscribe(() => this.scrollbar.scrollTo(0, 0, 0));
  }

  handleTabChanged(event) {
    this.activeTab = event.tab.textLabel;
    this.enqueuedFile = null;
    this.store.dispatch(new ResetFilesUploadStateAction());
  }

  openFolder(folder) {
    if (folder.type !== 'folder') {
      return '';
    }
    this.activeFolder.path = folder.path;
    this.allFilesFlag = false;
    this.clearFilters();
  }

  highlightFile(file) {
    const fileAlreadySelected = this.fileData.find(f => f.id === file.id);
    if (fileAlreadySelected) {
      this.fileData = this.fileData.filter(f => f.id !== file.id);
     } else {
      this.fileData = this.uploadConfig.multipleSelect ? [...this.fileData, file] : [file];
    }
  }

  insertFile() {
    if (!this.fileData || this.fileData.length === 0) {
      return;
    }
    if (!this.uploadConfig.multipleSelect && this.dialogInputData.usage !== 'article' && this.dialogInputData.usage !== 'collectionItem') {
      return this.dialogRef.close({ files: this.fileData[0] });
    }
    // embedding file in article or post body
    return this.dialogRef.close({ files: this.fileData });
  }

  clearFilters() {
    this.filterFormGroup.reset();
    this.filterData();
  }

  goBack() {
    if (this.dialogInputData.usage === 'watermark') {
      return;
    }
    this.activeFolder.path = this.activeFolder.path.substr(
      0,
      this.activeFolder.path.lastIndexOf('/')
    );
    this.filterData();
  }

  resolveType(item): string {
    const regex = /.*(?=\.)./g;
    return item.type === 'folder' ? $localize`Folder` : item.filename.replace(regex, '');
  }

  resolveName(item): string {
    if (item.type === 'folder') {
      return item.filename;
    }
    const regex = /\.[^.]*$/g;
    return item.meta_data.name || item.filename.replace(regex, '');
  }

  resolveThumbnailImg(item) {
    if (item.type === 'folder') {
      return 'fa-folder';
    }
    const regex = /[^.]+$/g;
    const type = item.filename ? item.filename.toLowerCase().match(regex)[0] : '';
    return this.filesService.resolveFileThumbnail(type);
  }

  filterByOwner(owner) {
    this.filterOwnerControl.setValue('');
    const owners = [...this.selectedOwners];
    const ownerExist = owners.find(item => item.id === owner.id);
    if (ownerExist) {
      return;
    }
    owners.push(owner);
    this.filterFormGroup.get('owners').setValue(owners);
    this.filterData();
  }

  removeOwner(owner) {
    const owners = this.selectedOwners.filter(item => item.id !== owner.id);
    this.filterFormGroup.get('owners').setValue(owners);
    this.filterData();
  }

  openFileSelector() {
    const payload = this.uploadConfig.multiUpload ? undefined : this.enqueuedFile;
    this.fileUpload.openFileSelectorDialog(payload);
  }

  initializeLoaders() {
    this.loading$ = this.store.select(store => store.files.loading);
    this.queueBeingProcessed$ = this.store.select(fileQueueBeingProcessed);

    this.queueBeingProcessed$.subscribe(queueProcessing => {
      if (queueProcessing) {
        this.dialogRef.disableClose = true;
      }
      this.dialogRef.disableClose = false;
    });
  }

  removeEnqueuedFile() {
    this.fileUpload.removeFileFromQueue(this.enqueuedFile);
    this.store.dispatch(new ResetFilesUploadStateAction());
    this.enqueuedFile = null;
  }

  async uploadFile() {
    if (!this.activeFolder?.id && this.dialogInputData.usage === 'watermark') {
      await this.prepareWatermarkFolder();
    }
    const glideContentType = this.dialogInputData.glideContentType;
    this.actionBus.setContentLoading(glideContentType, true);
    if (!this.uploadConfig.multiUpload) {
      const file = this.fileMetaForm.getEditFileResponse();
      this.store.dispatch(
        new UpdateQueuedFileAction({
          queueID: file.queueID,
          filename: file.filename,
          metaData: {
            description: file.metaData.description,
            name: file.metaData.name,
          },
        })
      );
    }
    this.fileUpload.startUpload();
  }

  async handleFilesProcessed(files) {
    if (!this.uploadConfig.multiUpload) {
      const fileId = files[0].id;
      return this.filesService.getFileById(fileId).then(file => {
        this.fileData = [file];
        this.insertFile();
      });
    }
    await Promise.all(files.map(async(file) => {
      const fileId = file.id;
      await this.filesService.getFileById(fileId).then(file => {
        this.fileData = [...this.fileData, file];
      });
    }))
    this.insertFile();
  }

  isCustomSvgIcon(icon) {
    return icon.startsWith('glideCustomIcon_') ? true : false;
  }

  prepareWatermarkFolder() {
    const payload = {
      description: "",
      folderPath: "/",
      name: "Watermark",
      parent: null,
      isWatermark: true
    }
    return this.filesService.createFolder(payload).toPromise()
      .then(res => this.store.dispatch(new SetActiveFolderAction({...res, content: []})));
  }


  handleOpenFileMetaForm(event) {
    this.enqueuedFile = event;
    this.handleToggleMenuEvent({ value: 'details' });
  }

  handleToggleMenuEvent(event) {
    this.activeToggleMenuItem = event.value;
  }

  saveFileMeta() {
    const file = this.fileMetaForm.getEditFileResponse();
    this.store.dispatch(
      new UpdateQueuedFileAction({
        queueID: file.queueID,
        filename: file.filename,
        metaData: {
          description: file.metaData.description,
          name: file.metaData.name,
        },
      })
    );
    this.handleToggleMenuEvent({ value: 'preview' });
    this.enqueuedFile = null;
  }

  isFileSelected(item) {
    return this.fileData.some(f => f.id === item.id);
  }

  allFiles(e) {
    this.allFilesFlag = e.checked;
    if (this.allFilesFlag) {
      this.fileData = unionBy(this.fileList, this.fileData, 'id');
    } else {
      this.fileData = [];
    }
  }

  extractUserInfo(user) {
    if (!user) {
      return $localize `:User name:Unknown`;
    }
    return user.firstName + ' ' + user.lastName;
  }

  handlePageChanged({ pageIndex, pageSize }) {
    this.store.dispatch(new SetFilesPageViewOptions({ pageIndex, pageSize }));
    const resetPagination = false;
    this.filterData(resetPagination);
    this.userPreferenceService.setUserPreference('files.pageSize', pageSize);
  }

  ngOnDestroy() {
    this.filesEmbedSubs.unsubscribe();
    const glideContentType = this.dialogInputData.glideContentType;
    this.actionBus.setContentLoading(glideContentType, false);
    this.store.dispatch(new ResetFilesUploadStateAction());
    this.store.dispatch(new ResetFilesListAction());
  }

  localizeMessage(filesFlag) {
    return filesFlag ? $localize`Unselect all files` : $localize`Select all files`;
  }
}
