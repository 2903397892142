import { UnsafeAction } from '../unsafe-action.interface';
import { WidgetType } from './widget-types.model';

export const GET_WIDGET_TYPES = '[WIDGET_TYPES] Get Widget types';
export class GetWidgetTypesAction implements UnsafeAction {
  readonly type = GET_WIDGET_TYPES;
  constructor(public payload?: any) { }
}

export const GET_WIDGET_TYPES_SUCCESS = '[WIDGET_TYPES] Get Widget types success';
export class GetWidgetTypesSuccessAction implements UnsafeAction {
  readonly type = GET_WIDGET_TYPES_SUCCESS;
  constructor(public payload: any) { }
}

export const GET_WIDGET_TYPES_ERROR = '[WIDGET_TYPES] Get Widget types error';
export class GetWidgetTypesErrorAction implements UnsafeAction {
  readonly type = GET_WIDGET_TYPES_ERROR;
  constructor(public payload: any) { }
}

export const CREATE_WIDGET_TYPE = '[WIDGET_TYPES] Create Widget type';
export class CreateWidgetTypeAction implements UnsafeAction {
  readonly type = CREATE_WIDGET_TYPE;
  constructor(public payload: WidgetType) { }
}

export const CREATE_WIDGET_TYPE_SUCCESS = '[WIDGET_TYPES] Create Widget type success';
export class CreateWidgetTypeSuccessAction implements UnsafeAction {
  readonly type = CREATE_WIDGET_TYPE_SUCCESS;
  constructor(public payload: WidgetType) { }
}

export const UPDATE_WIDGET_TYPE = '[WIDGET_TYPES] Update Widget type';
export class UpdateWidgetTypeAction implements UnsafeAction {
  readonly type = UPDATE_WIDGET_TYPE;
  constructor(public payload: WidgetType) { }
}

export const UPDATE_WIDGET_TYPE_SUCCESS = '[WIDGET_TYPES] Update Widget type success';
export class UpdateWidgetTypeSuccessAction implements UnsafeAction {
  readonly type = UPDATE_WIDGET_TYPE_SUCCESS;
  constructor(public payload: WidgetType) { }
}

export const DELETE_WIDGET_TYPE = '[WIDGET_TYPES] Dlete Widget type';
export class DeleteWidgetTypeAction implements UnsafeAction {
  readonly type = DELETE_WIDGET_TYPE;
  constructor(public payload: any) { }
}

export const DELETE_WIDGET_TYPE_SUCCESS = '[WIDGET_TYPES] Dlete Widget type success';
export class DeleteWidgetTypeSuccessAction implements UnsafeAction {
  readonly type = DELETE_WIDGET_TYPE_SUCCESS;
  constructor(public payload: any) { }
}

export const WIDGET_TYPES_ACTION_FAILED = '[WIDGET_TYPES] Generic failure action';
export class WidgetTypesFailedAction implements UnsafeAction {
  readonly type = WIDGET_TYPES_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[WIDGET_TYPES] Set page view options';
export class SetPageViewOptions implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const SET_WIDGET_TYPES_FILTER = '[WIDGET_TYPES] Set Widget Types filter';
export class SetWidgetTypesFilterAction implements UnsafeAction {
  readonly type = SET_WIDGET_TYPES_FILTER;
  constructor(public payload: any) { }
}

export const CLEAR_WIDGET_TYPES = '[WIDGET_TYPES] Clear Widget Types items';
export class ClearWidgetTypesAction implements UnsafeAction {
  readonly type = CLEAR_WIDGET_TYPES;
  constructor() { }
}
