import { Action } from '@ngrx/store';
import { Article } from '../article/article.model';
import { UnsafeAction } from '../unsafe-action.interface';

export const GET_ARTICLES = '[ArticleList] Get Articles';
export class GetArticlesAction implements UnsafeAction {
  readonly type = GET_ARTICLES;
  constructor(public payload: any) {
  }
}

export const GET_ARTICLES_SUCCESS = '[ArticleList] Get Articles Success';
export class GetArticlesSuccessAction implements UnsafeAction {
  readonly type = GET_ARTICLES_SUCCESS;
  constructor(public payload: { articles: Article[], query: any }) { }
}

export const GET_LOCALIZED_VERSIONS_ARTICLES = '[ArticleList] Get Localized versions for Articles';
export class GetLocalizedVersions4Articles implements Action {
  readonly type = GET_LOCALIZED_VERSIONS_ARTICLES;
  constructor(public articleIds: number[]) { }
}

export const GET_LOCALIZED_VERSIONS_ARTICLES_SUCCESS = '[ArticleList] Get Localized versions for Articles Success';
export class GetLocalizedVersions4ArticlesSuccess implements Action {
  readonly type = GET_LOCALIZED_VERSIONS_ARTICLES_SUCCESS;
  constructor(public localizedVersions: any[]) { }
}

export const ARTICLE_LIST_QUERY = '[ArticleList] Query Articles';
export class QueryArticlesAction implements UnsafeAction {
  readonly type = ARTICLE_LIST_QUERY;
  constructor(public payload: any) { }
}

export const DELETE_ARTICLE = '[ArticleList] Delete Article';
export class DeleteArticleAction implements UnsafeAction {
  readonly type = DELETE_ARTICLE;
  constructor(public payload: number) { }
}

export const DELETE_ARTICLE_SUCCESS = '[ArticleList] Delete Article Success';
export class DeleteArticleSuccessAction implements UnsafeAction {
  readonly type = DELETE_ARTICLE_SUCCESS;
  constructor(public payload: number) { }
}

export const ARTICLE_LIST_ACTION_FAILED = '[ArticleList] Generic failure action';
export class ArticleListFailedAction implements UnsafeAction {
  readonly type = ARTICLE_LIST_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const UPDATE_ARTICLES_LIST = '[ArticleList] Update articles list';
export class UpdateArticlesListAction implements UnsafeAction {
  readonly type = UPDATE_ARTICLES_LIST;
  constructor(public payload: any) { }
}

export const COPY_ARTICLE_URL = '[ArticleList] Copy article url';
export class CopyArticleUrlAction implements UnsafeAction {
  readonly type = COPY_ARTICLE_URL;
  constructor(public payload: any) { }
}

export const COPY_ARTICLE_URL_SUCCESS = '[ArticleList] Copy article url success';
export class CopyArticleUrlSuccessAction implements UnsafeAction {
  readonly type = COPY_ARTICLE_URL_SUCCESS;
  constructor( ) { }
}

export const COPY_ARTICLE_URL_FAILED = '[ArticleList] Copy article url failed';
export class CopyArticleUrlFailedAction implements UnsafeAction {
  readonly type = COPY_ARTICLE_URL_FAILED;
  constructor() { }
}
