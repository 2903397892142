
import {mergeMap, map, debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { of, timer } from 'rxjs';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-reducer';
import { ManageEmbeddedImageVisibilityAction } from '../../store/article/article.actions';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { get } from 'lodash-es';
import { StorageService } from '../storage/storage.service';
import { AuthService } from '../auth/auth.service';
import { GetImageGenerationSettingsAction, GetImageGenerationSettingsCompleteAction } from '../../store/images-configuration';

interface ImageFilteringParameters {
  page?: number;
  size?: number;
  tags?: number[];
  uploadedBy?: number[];
  uploadedAt? : {
    gte: number;
    lte: number;
  }
  search?: string;
  filter?: any;
}
@Injectable()
export class ImagesService {
  defaultParams = {
    service: 'glideMedia',
  };
  // map of valid image urls
  validImageUrlMap:any = {}
  // map of invalid image urls
  invalidImageUrlMap: any = {};
  settings = {};

  constructor(
    private rest: RestService,
    private accountSettingsService: AccountSettingsService,
    private store: Store<AppState>,
    private storageService: StorageService,
    private authService: AuthService
    ) {
      this.authService.getAuthDataStream().subscribe(tokenData => {
        if (!tokenData) {
          this.reset();
          return;
        }
        this.init(tokenData.accountId);
      });
    }

  getImages({ page = 0, size = defaultPageSize, tags, uploadedBy, uploadedAt, search, filter }: ImageFilteringParameters) {
    let requestPath = `images?size=${size}&page=${page}`;
    if (!!filter && Object.keys(filter).length > 0) {
      // use advance filtering
      const filterStr = JSON.stringify(filter);
      requestPath += `&filter=${encodeURIComponent(filterStr)}`;
    }

    requestPath += (search || '').length ? `&search=${search}` : '';
    requestPath += (tags || []).length ? `&tags=${tags.join(',')}` : '';
    requestPath += (uploadedBy || []).length ? `&uploadedBy=${uploadedBy.join(',')}` : '';

    if(!!uploadedAt && Object.keys(uploadedAt).length > 0) {
      const { gte, lte } = uploadedAt;
      if(gte && lte ){
        const range = JSON.stringify(uploadedAt);
        requestPath += `&uploadedAt=${encodeURIComponent(range)}`;
      }
    }

    return this.rest
      .get(requestPath, { service: 'glideMedia' }).pipe(
      map(response => this.processImages(response)));
  }

  getImage(imageId) {
    return this.rest.get(`images/${imageId}`, { service: 'glideMedia' }).toPromise();
  }

  updateImageDetails(payload) {
    const { imageId, imageData } = payload;
    const requestUrl = `images/${imageId}`;
    return this.rest.put(requestUrl, imageData, this.defaultParams);
  }

  getImagesStatus() {
    return this.rest.get(`images/requests`, this.defaultParams);
  }

  getObjectsStatus(payload) {
    return this.rest.post(`images/requests`, payload, this.defaultParams);
  }

  getUserConfiguration() {
    return this.rest.get('settings', this.defaultParams);
  }

  updateUserConfiguration(payload) {
    return this.rest.put('settings', payload, this.defaultParams);
  }

  getTags({ name,  group, page = 0, size = 20 }) {
    let requestUrl = `tags?page=${page || 0}&size=${size || 20}`;
    requestUrl += name ? `&name=${name}` : '';
    requestUrl += group ? `&group=${group}` : '';
    return this.rest.get(requestUrl, this.defaultParams);
  }

  getTagsByIds(tagIds) {

    if (tagIds.length < 1) {
      return of([]);
    }

    const size = tagIds.length <= 200 ? tagIds.length : 200;

    return this.rest.get(`tags?ids=${tagIds.join(',')}&size=${size}`, this.defaultParams)
  }

  deleteImage(imageId) {
    return this.rest.delete(`images/${imageId}`, { service: 'glideMedia' });
  }

  updateImageTags(imageId, tags) {
    return this.rest.post(`images/${imageId}/tags`, tags, this.defaultParams).toPromise();
  }

  deleteImageTag(imageId, tagId) {
    return this.rest.delete(`images/${imageId}/tags/${tagId}`, this.defaultParams).toPromise();
  }

  updatePremiumImageTag(imageId, tagId, body) {
    return this.rest.put(`images/${imageId}/tags/${tagId}/premium`, body, this.defaultParams).toPromise();
  }

  getImageAsCollectionItem(imageId) {
    return this.rest.get(`images/${imageId}`, { service: 'glideMedia' }).pipe(
      mergeMap(image => image.error ? of(null) : of(image)));
  }

  updateIntegrationConfig(config) {
    return this.rest.put(`integrations/config`, config, this.defaultParams);
  }

  validateImageUrl = url => {
    return new Promise(res => {
      // TODO check why we get a string null here
      if (!url || url === 'null' || this.invalidImageUrlMap[url]) {
        return res({ url, isValid: false });
      }
      if (url && this.validImageUrlMap[url]) {
        return res({ url, isValid: true });
      }
      const image = new Image();
      image.src = url;
      image.onload = () => {
        this.validImageUrlMap[url] = true;
        res({ url, isValid: true });
      }
      image.onerror = () => {
        this.invalidImageUrlMap[url] = true;
        res({ url, isValid: false });
      };
    });
  }

  // output example: { 'url1': true, 'url2': false }
  validateImageUrlList = urls => {
    return Promise.all(urls.map(url => this.validateImageUrl(url))
    ).then(data => {
      return  data.reduce((acc: any, { url, isValid }) => ({ ...acc, [url]: isValid }), {});
    });
  }

  getTagListByIds(ids) {
    let requestUrl = `tags?ids=${ids}`
    return this.rest.get(requestUrl, this.defaultParams);
  }

  processImages(data) {
    return {
      items: data.items.map(img => this.processImage(img)),
      total: data.total,
    };
  }

  processImage(image) {
    const formats = image.formats
      .filter(format => format.type !== 'original')
      .sort((a, b) => parseInt(a.height, 10) < parseInt(b.height, 10) ? -1 : 1)
      .sort((a, b) => parseInt(a.width, 10) < parseInt(b.width, 10) ? -1 : 1);

    const mandatoryFormatsMap = formats
      .filter(({ mandatory }) => mandatory)
      .reduce((acc, { width, height, key }) => {
        return {
          ...acc,
          [`${width}x${height}`]: this.generateImageUrl(key),
        };
      }, {});

    let originalFormatKey = null;
    const originalFormat = image.formats.find(format => format.type === 'original');
    if (originalFormat) {
      originalFormatKey = originalFormat ? this.generateImageUrl(originalFormat.key) : null;
      formats.push(originalFormat);
    }

    const orientation = originalFormat
      ? parseInt(originalFormat.width, 10) > parseInt(originalFormat.height, 10)
        ? 'landscape'
        : 'portrait'
      : null;

    const imageMetaData =
      (image.metaData &&
        image.metaData.reduce(
          (acc, meta) => ({
            ...acc,
            [meta.key]: meta.value,
          }),
          {}
        )) ||
      {};

    const tags = extractTags(get(image, 'tags', []));
    const isImageSmallDimensions = checkImageSmallDimensions(image);

    return {
      ...image,
      formats,
      previewImage: mandatoryFormatsMap['950x633'],
      listThumbnail: mandatoryFormatsMap['150x100'],
      gridThumbnail: mandatoryFormatsMap['240x190'],
      thumbnail: mandatoryFormatsMap['400x260'] || mandatoryFormatsMap['950x633'], // use preview size as fallback
      original: originalFormatKey,
      thumbnailLoaded: true,
      orientation,
      imageMetaData,
      tags,
      isImageSmallDimensions
    };
  }

  generateImageUrl(path) {
    if (!path) {
      return '';
    }
    const mediaBaseUrl = this.accountSettingsService.getMediaBaseUrl();
    return mediaBaseUrl + path;
  }

  /**
   * TODO rewrite this in a reactive way
   * the image service should not be dealing firing actions specific to different
   * content types, or manipulating the dom. Instructions on what to do should be
   * returned to the caller
   */
  setArticleEmbeddedImageChecker(node, ActionConstructor = ManageEmbeddedImageVisibilityAction) {
    const [numberOfAttempts, delay] = [12, 10000];
    const { attempt } = node;
    const image = new Image();
    image.src = node.src;

    image.onload = () => {
      if (removeImagePlaceholder(node)) {
        this.dispatchImageVisibilityActionForContentType({ ...node, src: null, isNotLoaded: false }, ActionConstructor);
      }
    };
    image.onerror = () => {
      // needed for stopping execution of image checker
      let timeout = null;
      const isLastAttempt = attempt === numberOfAttempts;
      const src = isLastAttempt ? './assets/img/outline-broken_image-24px.svg' : null;

      if (isLastAttempt && !removeImagePlaceholder({ ...node, src }, true)) {
        return;
      }
      if (!isLastAttempt) {
        timeout = setTimeout(() => this.setArticleEmbeddedImageChecker({ ...node, attempt: attempt + 1 }), delay);
      }
      this.dispatchImageVisibilityActionForContentType({ ...node, src, isNotLoaded: !isLastAttempt, timeout }, ActionConstructor);
    };
  }

  dispatchImageVisibilityActionForContentType(imageNode, ActionConstructor) {
    if (!ActionConstructor) {
      console.warn('No action to dispatch for updating embedded image visibility!');
      return;
    }
    this.store.dispatch(new ActionConstructor(imageNode));
  }

  getImageGenerationSettings() {
    return this.rest.get('settings/gen-ai', this.defaultParams).pipe(map(res => res?.data));
  }

  updateImageGenerationSettings(payload) {
    return this.rest.put('settings/gen-ai', payload, this.defaultParams).pipe(map(res => res?.data));
  }

  refreshMediaSettingsInStore(accountId, settings) {
    this.settings = {...settings};
    this.storageService.saveMediaSettingsInStore(accountId, {...settings});
  }

  init(accountId) {
    // load old settings from local storage and update settings state
    this.settings = this.storageService.loadMediaSettingsInStore(accountId);
    const settings = { ...this.settings };
    this.store.dispatch(new GetImageGenerationSettingsCompleteAction(settings));
  }

  reset() {
    this.settings = {};
  }

  isMediaAIGenEnabled() {
    return get(this.settings, 'enabled', false);
  }

  mediaAIGenModelSettings() {
    return get(this.settings, 'imageModels[0]', {});
  }

}


// not the best place for doing this but easy to use as it needs to be called from effects too
// simplest way to avoid unnecessary content transformations which can cause data loss
const removeImagePlaceholder = (node, isImageBroken = false) => {
  const imgEl = document.getElementById(node.id);
  const imgParent = imgEl && imgEl.closest('span[data-type="media/image"]');
  if (!imgEl || !imgParent) {
    return false;
  }
  imgEl.setAttribute('src', node.src);
  imgEl.classList.remove('gd-content-loading');
  if (!isImageBroken) {
    imgParent.classList.add('--image');
    imgEl.classList.remove('--bg-contain');
    const credit = imgParent.querySelector('.gd-image-credit');
    if(credit) {
      credit.classList.remove('--hidden');
    }
  }
  return true;
};

export const loadImageUrl = async (url) => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = url;
    image.onload = () => {
      resolve(true);
    };
    image.onerror = () => {
      reject({ invalidImageUrl: true });
    };
  });
}

export function resolveArticleBodyImage (image) {
  if (!image.formats) {
    return image.src;
  }
  const originalFormat = image.formats.find(format => format.type === 'original');
  // if original image width is over 256px return gridThumbnail
  if (parseInt(originalFormat.width) > 256) {
    return image.gridThumbnail
  }
  return image.original;
}

export const commonRatios = {
  '1:1': [1, 1],
  '1:2': [0.49, 0.51],
  '2:1': [1.99, 2.01],
  '3:2': [1.49, 1.51],
  '2:3': [0.66, 0.68],
  '4:3': [1.32, 1.34],
  '3:4': [0.74, 0.76],
  '16:9': [1.77, 1.79],
  '9:16': [0.55, 0.57]
};

export function getUserFriendlyRatio(width, height) {
  const ratio = +(width / height).toFixed(2);

  const common = Object.keys(commonRatios).find(key => {
    const [min, max] = commonRatios[key];
    return ratio >= min && ratio <= max;
  });

  if (common) {
    const [w, h] = common.split(':');
    const perfectRatio = (+w / +h) === (width / height);
    return common + (!perfectRatio ? '*' : '');
  }

  let w = 0, h = 0;
  while (!w || (w < width && h % 1)) {
    w++;
    h = w / (width/height);
  }
  return w < width ? `${w}:${h}` : `${width}:${height}`;
}

export function extractTags(tags) {
  return tags.map(tag => ({ id: tag.id, group: tag.group, name: tag.name, premium: !!tag.image_tags.premium }));
}

export function checkImageSmallDimensions (image) {
  if (!image.formats) {
    return false;
  }
  const originalFormat = image.formats.find(format => format.type === 'original');
  if (!originalFormat || parseInt(originalFormat.width) > 256) {
    return false;
  }
  return true;
}