import { catchError, debounceTime, filter, map, mergeMap, tap } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subscription, combineLatest, of } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomFormConstructorService } from '../../../gpp-shared/widget-configuration/form-constructor/custom-form-constructor.service';
import { EmbeddablesDataService } from '../../editors/embeddables-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { generateShortId } from '../../shared-functions';
import { BidiService } from '../../../core/i18n/bidi.service';
import { getDailymotionSettings } from '../../../core/store/account-settings/account-settings.reducer';
import { AppState } from '../../../core/store/app-reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'gd-embed-dailymotion-dialog',
  templateUrl: './embed-dailymotion-dialog.component.html',
  styleUrls: ['./embed-dailymotion-dialog.component.scss'],
})
export class EmbedDailymotionDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  mediaForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: [''],
    src: [''],
    description: [''],
    autoplay: [false],
    height: [''],
    width: ['']
  });

  previewSrc = null;
  // used mostly for displaying list of videos added in playlist
  videos;
  playerId;
  metaDataConfig;
  componentSub: Subscription = new Subscription();
  totalVideos;
  editingMode = false;
  allowMeta = false;
  content = 'video'; // 'video' | 'playlist'
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  tabIndex: number = 0;
  initialValues = null;


  @ViewChild('focusInput') focusInput: ElementRef;
  @ViewChild('previewEl') previewEl: ElementRef;

  get idControl() {
    return this.mediaForm.get('id');
  }

  get metaData(): UntypedFormControl {
    return this.mediaForm.get('itemMetaData') as UntypedFormControl;
  }

  get videoUrl() {
    return this.previewEl ? this.previewEl.nativeElement.src : null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<EmbedDailymotionDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private fc: CustomFormConstructorService,
    private embeddablesDataService: EmbeddablesDataService,
    public domSanitizer: DomSanitizer,
    private bidiService: BidiService
  ) {}

  ngOnInit(): void {
    this.tabIndex = this.dialogInputData.tabIndex;
    this.editingMode = !!this.dialogInputData.editingMode;
    this.componentSub.add(this.createIdInputSub());
    this.allowMeta = !!this.dialogInputData.allowMeta;
    this.content = this.dialogInputData.embedType.endsWith('playlist') ? 'playlist' : 'video';
    if (this.editingMode && this.dialogInputData.id) {
      // triggers form initialization
      this.idControl.setValue(this.dialogInputData.id);
    }
    this.prepareItemMetaData();
  }

  ngAfterViewInit() {
    setTimeout(() => this.focusInput.nativeElement.focus(), 200);
  }

  createIdInputSub() {
    let firstChange = !this.editingMode || this.dialogInputData.id;
    return combineLatest([
      this.idControl.valueChanges.pipe(
        debounceTime(200),
        tap(() => this.clearPreviousData()),
        filter((id) => !!id),
        map((id) => (id || '').trim()),
        mergeMap((id) => (id ? this.getMediaData(id) : of(false)))
      ),
      this.store.select(getDailymotionSettings),
    ]).subscribe(([data, settings]) => {

      if (!settings) {
        return this.idControl.setErrors({ invalidDailymotionSettings: true });
      }
      this.idControl.markAsTouched();

      const error = !data || data.error || this.idControl.value.length > 19 || data.status === 404 ? { invalidId: true } : null;
      this.idControl.setErrors(error);
      if (error) {
        return;
      }

      this.playerId = settings.previewPlayerId;
      this.previewSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.content === 'video'
          ? data.embed_url
          : `https://geo.dailymotion.com/player/${this.playerId}.html?playlist=${data.id}`
      );

      const showSavedData = firstChange && this.editingMode;
      firstChange = false;
      if (showSavedData) {
        const { src, name, description, autoplay, id, height, width } = this.dialogInputData;
        if (this.content === 'playlist') {
          this.totalVideos = data.videos_total;
          this.embeddablesDataService
            .getDailymotionPlaylistVideos(id)
            .subscribe((data) => (this.videos = { ...data, id }));
        }
        return this.mediaForm.patchValue({ src, name, description, autoplay, height, width });
      }
      const { title, description, thumbnail_480_url, id, name, height, width } = data;
      if (this.content === 'playlist') {
        this.totalVideos = data.videos_total;
        this.embeddablesDataService
          .getDailymotionPlaylistVideos(id)
          .subscribe((data) => (this.videos = { ...data, id }));
      }
      this.mediaForm.patchValue({
        name: title || name,
        description,
        src: thumbnail_480_url,
        height: height,
        width: width
      });
    });
  }

  getMediaData(id) {
    if (this.content === 'video') {
      return this.embeddablesDataService
        .getDailymotionVideoData(id)
        .pipe(catchError((err) => of(err)));
    }
    return this.embeddablesDataService
      .getDailymotionPlaylistData(id)
      .pipe(catchError((err) => of(err)));
  }

  save() {
    this.dialogRef.close(this.prepareOutputObj());
  }

  clearPreviousData() {
    this.videos = null;
    this.previewSrc = null;
    ['name', 'description', 'src'].forEach((key) => {
      this.mediaForm.get(key).setValue('');
    });
  }

  prepareOutputObj() {
    const data = this.mediaForm.value;
    data.id = data.id ? data.id.trim() : data.id;
    data.src = data.src || './assets/img/dailymotion-icon.svg';

    if (this.content === 'playlist') {
      delete data.autoplay;
    }
    if (!this.allowMeta) {
      delete data.itemMetaData;
    }
    if (this.allowMeta) {
      return data;
    }

    const payload = {
      type: this.dialogInputData.embedType,
      blockId: this.dialogInputData.blockId || generateShortId(),
      updateData: data,
    };
    return { payload };
  }

  ngOnDestroy(): void {
    this.componentSub.unsubscribe();
  }

  prepareItemMetaData() {
    this.mediaForm.removeControl('itemMetaData');

    const passedConfig = this.dialogInputData.itemMetaDataConfig;
    this.metaDataConfig = passedConfig && passedConfig.length > 0 ? passedConfig : null;
    const initialValues = this.editingMode ? this.dialogInputData.itemMetaData || {} : null;
    // TODO refactor - move responsibility to DynamicWidgetFormComponent
    this.initialValues = initialValues;
    const itemFormGroup = this.fc.createFormGroup(this.metaDataConfig, initialValues, {
      editMode: !!initialValues,
    });
    this.mediaForm.addControl('itemMetaData', itemFormGroup);
  }

  previewVideo(id, playlist) {
    const src = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://geo.dailymotion.com/player/${this.playerId}.html?video=${id}&playlist=${playlist}`
    );
    if (src === this.videoUrl) {
      return;
    }
    this.previewSrc = null;
    setTimeout(() => (this.previewSrc = src), 0);
  }

  getSaveButtonTooltipMessage() {
    if (this.mediaForm.valid) {
      return '';
    }
    if (this.metaData.invalid) {
      if (this.isCustomDataTaxonomyAllowed()) {
        return $localize`Some of the used Taxonomies on custom fields are invalid.`;
      }
      return $localize`Metadata form is invalid`;
    }
    return $localize`Form is invalid`;
  }

  isCustomDataTaxonomyAllowed() {
    const configuration = this.metaData as UntypedFormControl;
    if (!configuration) {
      return false;
    }
    return Object.values(configuration['controls']).some((control: UntypedFormControl) =>
      control.hasError('taxonomyErr')
    );
  }
}
