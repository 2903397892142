import { NgModule } from '@angular/core';

// Fury imports
import { BreadcrumbService } from './fury-core/breadcrumb/breadcrumb.service';
import { SidenavService } from './fury-core/sidenav/sidenav.service';

// GD specific
import { AppComponent } from './app.component';
import { FuryCoreModule } from './fury-core/fury-core.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { RoutingModule } from './app-routing.module';
import { GlideCreateModule } from './glide-create/glide-create.module';
import { GlideMediaModule } from './glide-media/glide-media.module';
import { GlideUsersModule } from './glide-users/glide-users.module';
import { GlideTransmitModule } from './glide-transmit/glide-transmit.module';
import { PermissionService } from './core/api/auth/permissions.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { getPaginatorIntl } from './core/i18n/mat-paginator-i18n';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    FuryCoreModule,
    RoutingModule,
    GlideCreateModule,
    GlideMediaModule,
    GlideUsersModule,
    GlideTransmitModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    // fury-core
    SidenavService,
    BreadcrumbService,
    PermissionService,
    // end fury-core
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
