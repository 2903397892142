import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../fury-core/admin/admin.component';
import { AuthGuard } from '../core/api/auth/auth-guard.guard';
import { PermissionGuard } from '../core/api/auth/permission-guard.guard';
import { MyProfileComponent } from './+users/my-profile/my-profile.component';

const routes: Routes = [
  {
    path: 'glide-users',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'accounts',
        loadChildren: () => import('./+accounts/accounts.module').then((m) => m.AccountsModule),

        canActivate: [PermissionGuard],
      },
      {
        path: 'roles',
        loadChildren: () => import('./+roles/roles.module').then((m) => m.RolesModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./+users/users.module').then((m) => m.UsersModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'sso',
        loadChildren: () =>
          import('./+sso-settings/sso-settings.module').then((m) => m.SsoSettingsModule),

        canActivate: [PermissionGuard],
      },
      { path: '', pathMatch: 'full', redirectTo: '/glide-users/users' },
      { path: '**', redirectTo: 'misc/error404' },
    ],
  },
  {
    path: 'settings',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./+settings/settings.module').then((m) => m.SettingsModule),
      },
      { path: '**', redirectTo: 'misc/error404' },
    ],
  },
  {
    path: 'my-profile',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { title: $localize`My Profile` },
    children: [
      { path: '', pathMatch: 'full', component: MyProfileComponent },
      { path: '**', redirectTo: 'misc/error404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GlideUsersRoutingModule {}
