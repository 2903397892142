<div class="gd-embed-article-anchor-link"
[dir]="dir$ | async">
  <form [formGroup]="anchorLinkFormBuilder"
        (ngSubmit)="save()"
        fxLayout="column">
    <h2 mat-dialog-title i18n>Embed Article Anchor Link</h2>

    <section>
      <mat-form-field class="gd-embed-article-anchor-link__link-id"
                      appearance="outline">
        <mat-label i18n="This is the id of the HTML element to which the anchor link will point to">Destination ID</mat-label>
        <input matInput
               type="text"
               formControlName="id"
               autocomplete="off">
      </mat-form-field>
    </section>

    <section class="gd-embed-article-anchor-link__section-buttons">
      <button mat-raised-button
              color="primary"
              type="submit"
              i18n
              [disabled]="!anchorLinkFormBuilder.dirty" i18n>Save</button>
      <button mat-raised-button
              color="danger"
              i18n
              mat-dialog-close i18n>Cancel</button>
    </section>

  </form>
</div>
