// TODO move into generic body model, as this isn't article specific
export interface ContentModel {
  bodyHtml?: string;
  contentStructure: any[];
  contentNodes: { [key: string]: ContentNode };
  referencedContent?: { [key: string]: any };
}

export interface StructureNode {
  id: string;
  parentId: string;
  childNodes: StructureNode[];
  attributes: { [key: string]: string };
  tagName: string;
  innerText: string;
}

export interface ContentNode {
  id: string;
  type: string;
  dataId?: string | number;
  dataUrl?: string;
  src?: string;
  name?: string;
  title?: string;
  authorUsername?: string;
  alignment?: string;
  brightcoveAutoplay?: number;
  innerText?: string;
  isInline?: boolean;
  target?: string;
  innerHTML?: string;
  // for widgets
  configuration?: {[key: string]: any };
  // form images
  caption?: string;
  alt?: string;
  credit?: string;
  imageFormat?: string;
  imageStyle?: string;
  liveReportId?: string;
  htmlAttributes?: object;
  slug?: string;
}

export enum EmbeddedType {
  FacebookPost = 'social/facebook',
  TwitterPost = 'social/twitter',
  InstagramPost = 'social/instagram',
  Image = 'media/image',
  YouTube = 'video/youtube',
  Brightcove = 'video/brightcove',
  BrightcovePlaylist = 'video/brightcove-playlist',
  Vimeo = 'video/vimeo',
  JWPlayerVideo = 'video/jwplayer',
  JWPlayerPlaylist = 'video/jwplayer-playlist',
  Gallery = 'media/gallery',
  File = 'media/file',
  Note = 'content/note',
  Link = 'content/link',
  ArticleMention = 'mention/article',
  TaxonomyMention = 'mention/taxonomy',
  Url = 'content/url',
  SystemWidget = 'widget/system',
  ThirdPartyWidget = 'widget/third-party',
  ContentPanel = 'widget/content-panel',
  AnchorLink = 'content/anchor-link',
  ContentTag = 'widget/content-tag',
  ImageURL = 'image/url',
  TikTokPost = 'social/tiktok',
  PostMention = 'mention/post',
  DailymotionVideo = 'video/dailymotion',
  DailymotionPlaylist = 'video/dailymotion-playlist',
  TwitchVideo = 'video/twitch',
  TwitchStream = 'video/twitch-stream'
}

export enum ReferencedContentType {
  Image = 'media/image',
  Gallery = 'media/gallery',
  File = 'media/file',
  ArticleMention = 'mention/article',
  SystemWidget = 'widget/system',
  ThirdPartyWidget = 'widget/third-party',
  ContentPanel = 'widget/content-panel',
  ContentTag = 'widget/content-tag',
  PostMention = 'mention/post',
  ImageURL = 'image/url',
  TaxonomyMention = 'mention/taxonomy',
}

export function getEmptyArticleContentModel(): ContentModel {
  return {
    bodyHtml: '',
    contentStructure: [],
    contentNodes: {},
    referencedContent: {}
  };
}
