<!-- Dialog Content -->
<mat-dialog-content class="gd-embed-media-files-dialog__content"
                    [dir]="dir$ | async">
  <!-- Tabbed Layout -->
  <mat-tab-group class="gd-embed-media-files-tabs" (selectedTabChange)="handleTabChanged($event)">
    <!-- Library Tab -->
    <mat-tab label="Library" i18n-label>
      <!-- Tab Label -->

      <ng-template mat-tab-label>
        <mat-icon svgIcon="glideCustomIcon_galleries"></mat-icon>
        <span class="gd-embed-media-files-tabs__tab-label" i18n="File library tab label in embed file dialog">Library</span>
      </ng-template>

      <div
        class="gd-embed-media-files-tabs__content-wrapper"
        [style.height]="modalLayout.contentHeight + 'px'"
      >
        <gd-scroller #scrollbar>
          <div class="gd-files-view__list" [formGroup]="filterFormGroup">
            <mat-table #table [dataSource]="dataSource$ | async">
              <!-- First row -->
              <ng-container matColumnDef="key" sticky>
                <mat-header-cell
                  fxFlex="40%"
                  class="gd-files-view__name-header"
                  *matHeaderCellDef
                ></mat-header-cell>
                <mat-cell
                  class="gd-files-view__name-cell"
                  fxFlex="40%"
                  *matCellDef="let element"
                >
                  <div class="gd-files-view__list-thumbnail">
                    <mat-icon class="file-thumbnail" id="file-thumb-{{ element.id }}" *ngIf="!element.isSvgIcon"  fontSet="fal" fontIcon="{{resolveThumbnailImg(element)}}"></mat-icon>
                    <mat-icon class="file-thumbnail" id="file-thumb-{{ element.id }}" *ngIf="element.isSvgIcon" svgIcon="{{resolveThumbnailImg(element)}}"></mat-icon>
                  </div>
                  <div class="gd-files-view-list__first-column">
                    {{ resolveName(element) }}
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="owner" sticky>
                <mat-header-cell
                  fxFlex="18%"
                  class="gd-files-view__owner-header"
                  *matHeaderCellDef
                ></mat-header-cell>
                <mat-cell fxFlex="18%" *matCellDef="let element">{{
                  element.uploadedBy || ''
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="uploadedDate" sticky>
                <mat-header-cell
                  fxFlex="30%"
                  class="gd-files-view__upload-date-header"
                  *matHeaderCellDef
                ></mat-header-cell>
                <mat-cell fxFlex="30%" *matCellDef="let element" dir="ltr">
                  {{ element.updated_at | gdDateTransform }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="control" sticky>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" style="margin-left: 80px;">
                  <mat-icon *ngIf="isFileSelected(element)" class="gd-embed-media-files__check-icon">
                    check_circle
                  </mat-icon>
                </mat-cell>
              </ng-container>
              <!-- End of first row -->

              <!-- Second row -->
              <ng-container matColumnDef="key-second-row">
                <mat-header-cell fxFlex="40%" *matHeaderCellDef>
                  <mat-form-field fxFlex="80%" class="gd-files-view__filters-label">
                    <mat-label i18n>Any file or folder name</mat-label>
                    <input
                      matInput
                      formControlName="fileName"
                      (keyup.enter)="filterData()"
                      autocomplete="off"
                    />
                  </mat-form-field>
                </mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="owner-second-row">
                <mat-header-cell fxFlex="18%" *matHeaderCellDef>
                  <mat-form-field fxFlex="90%" class="gd-files-view__filters-label">
                    <mat-label i18n>Start typing any User Name</mat-label>
                    <input
                      matInput
                      [formControl]="filterOwnerControl"
                      [matAutocomplete]="ownerAutocomplete"
                    />
                    <mat-autocomplete
                      autoActiveFirstOption
                      #ownerAutocomplete="matAutocomplete"
                      (optionSelected)="filterByOwner($event.option.value)"
                    >
                      <mat-option *ngFor="let owner of filteredUsers$ | async" [value]="owner">
                        {{ owner.firstName + ' ' + owner.lastName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="uploadedDate-second-row">
                <mat-header-cell
                  fxFlex="30%"
                  *matHeaderCellDef
                  class="gd-files-view__uploadedDate-cell"
                >
                  <mat-form-field fxFlex="45%">
                    <mat-label class="gd-files-view__filters-label" i18n>From</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerFrom"
                      (dateChange)="filterData()"
                      formControlName="dateFrom"
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field fxFlex="45%">
                    <mat-label class="gd-files-view__filters-label" i18n>To</mat-label>
                    <input
                      matInput
                      [matDatepicker]="pickerTo"
                      (dateChange)="filterData()"
                      formControlName="dateTo"
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                  </mat-form-field>
                </mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="control-second-row">
                <mat-header-cell *matHeaderCellDef>
                  <button mat-button
                          class="gd-files-list__button"
                          matTooltip="Clear filters"
                          i18n-matTooltip
                          matTooltipPosition="above"
                          (click)="clearFilters()">
                    <mat-icon fontSet="fal"
                              fontIcon="fa-stream"></mat-icon>
                  </button>
                  <mat-checkbox *ngIf="uploadConfig.multipleSelect && (dialogInputData?.usage === 'mediaWidgetConfig' || dialogInputData?.usage === 'collectionItem')"
                                color="primary"
                                (change)="allFiles($event)"
                                [checked]="allFilesFlag"
                                style="margin-bottom: 4px;"
                                [matTooltip]="localizeMessage(allFilesFlag)"></mat-checkbox>
                </mat-header-cell>
              </ng-container>
              <!-- End of second row -->

              <!-- Third row -->
              <ng-container matColumnDef="key-third-row">
                <mat-header-cell fxFlex="40%" class="gd-files-view__name-header" *matHeaderCellDef>
                  <mat-cell class="gd-files-view__name-cell" (click)="goBack()" style="padding-left: 0px !important;" [class.disabled]="dialogInputData.usage==='watermark'">
                      <div class="gd-files-view__list-thumbnail">
                      <img *ngIf="activeFolder.name && dialogInputData.usage!=='watermark'"
                            class="file-thumbnail"
                            [src]="theme === 'default' ? 'assets/img/files/folder-back.svg' : 'assets/img/files/folder-back-white.svg'"/>
                      <mat-icon class="file-thumbnail" *ngIf="dialogInputData.usage==='watermark'"  fontSet="fal" fontIcon="fa-folder"></mat-icon>
                      </div>
                    <div class="gd-files-view-list__first-column">{{ activeFolder.name }}</div>
                  </mat-cell>
                </mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="owner-third-row">
                <mat-header-cell fxFlex="18%" *matHeaderCellDef>
                  <mat-chip-list #chipList class="gd-files-view__owner-chip-list">
                    <ng-container *ngFor="let owner of selectedOwners">
                      <mat-chip
                        [selectable]="false"
                        [removable]="true"
                        (removed)="removeOwner(owner)"
                      >
                        <span style="font-size: 12px">{{ owner.firstName + ' ' + owner.lastName }}</span>
                        <mat-icon matChipRemove fontSet="fal" fontIcon="fa-times-circle"></mat-icon>
                      </mat-chip>
                    </ng-container>
                  </mat-chip-list>
                </mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="uploadedDate-third-row">
                <mat-header-cell fxFlex="30%" *matHeaderCellDef></mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="control-third-row">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
              </ng-container>
              <!-- End of third row -->

              <mat-header-row
                *matHeaderRowDef="secondRowDisplayedColumns"
                [class.--border-hidden]="selectedOwners.length || activeFolder.name"
              ></mat-header-row>
              <mat-header-row
                *matHeaderRowDef="thirdRowDisplayedColumns"
                style="min-height: 32px; align-items: start;"
                [class.--hidden]="!activeFolder.name && !selectedOwners.length"
              >
            </mat-header-row>
              <mat-row
                class="gd-item-list__row"
                *matRowDef="let row; columns: displayedColumns"
                [class.--selected]="isFileSelected(row)"
                (click)="row.type === 'folder' ? openFolder(row) : highlightFile(row)"
              ></mat-row>
            </mat-table>
          </div>
          <div
            class="gd-files-view__table-no-result"
            *ngIf="(dataSource$ | async)?.length === 0 && (loaded$ | async)"
          >
            <gd-table-no-results [isTable]="true" [isMedia]="false" [message]="noResultsMessage">
            </gd-table-no-results>
          </div>
          <mat-paginator [pageIndex]="pageView.pageIndex"
                 [pageSize]="pageView.pageSize"
                 [length]="pageView.total"
                 (page)="handlePageChanged($event)"
                 [pageSizeOptions]="pageView.pageSizeOptions">
          </mat-paginator>
        </gd-scroller>
      </div>
    </mat-tab>

    <!-- Upload Tab -->
    <mat-tab label="Upload" i18n-label>
      <!-- Tab Label -->
      <ng-template mat-tab-label>
        <mat-icon fontSet="fal" fontIcon="fa-cloud-upload"></mat-icon>
        <span class="gd-embed-media-files-tabs__tab-label" i18n="Upload file tab label in embed file dialog" i18n>Upload</span>
      </ng-template>

      <div class="gd-embed-media-files-tabs__content-wrapper">
        <div class="gd-embed-media-files-tabs__toggle-menu">
          <div class="gd-embed-media-files-tabs__toggle-menu-back-button">
            <button mat-button
                    mat-raised-button
                    i18n
                    *ngIf="uploadConfig.viewAs === 'contained' &&
                      uploadConfig.multiUpload &&
                      activeToggleMenuItem === 'details'"
                    (click)="handleToggleMenuEvent({ value: 'preview'})">
              Back
            </button>
          </div>
          <div fxFlex="35%" class="gd-embed-media-files-tabs__toggle-menu-column">
            <button
              mat-raised-button
              color="primary"
              (click)="openFileSelector()"
              *ngIf="activeToggleMenuItem !== 'details'"
            >
              <mat-icon style="width: 27px; height: 27px;" svgIcon="glideCustomIcon_image-search"></mat-icon>
              <span i18n>Browse</span>
            </button>

            <button
              *ngIf="enqueuedFile && !uploadConfig.multiUpload"
              mat-button
              mat-icon-button
              style="margin-left: 12px; width: 36px; height: 36px"
              (click)="removeEnqueuedFile()"
            >
              <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
            </button>
          </div>
        </div>
        <!-- Show Preview for queued file -->
        <div class="gd-embed-media-files__upload-dropzone"
             [style.height]="(hasEnqueuedFiles && !uploadConfig.multiUpload) ? 0 : (modalLayout.contentHeight - 60) + 'px'">
          <gd-files-upload
            #fileUpload
            [uploadConfig]="uploadConfig"
            (filesProcessed)="handleFilesProcessed($event)"
            (openFileUploadForm)="handleOpenFileMetaForm($event)"
          >
          </gd-files-upload>
        </div>

        <!-- Single upload file edit form -->
        <div
          *ngIf="hasEnqueuedFiles && enqueuedFile && !uploadConfig.multiUpload"
          class="gd-embed-media-files__details"
          [style.height]="(modalLayout.contentHeight - 52) + 'px'">
          <gd-scroller #scrollbar>
            <gd-upload-edit-file-form #fileMetaForm [fileData]="enqueuedFile">
            </gd-upload-edit-file-form>
          </gd-scroller>
        </div>
        <!-- Mutiple upload file edit form -->
        <div [@showMetaCard]="activeToggleMenuItem"
             *ngIf="hasEnqueuedFiles && enqueuedFile && activeToggleMenuItem === 'details' && uploadConfig.multiUpload"
             class="gd-embed-media-files__multiple-upload-list"
             [style.top]="'60px'"
             [style.min-height]="(modalLayout.contentHeight - 52) + 'px'"
             [style.max-height]="(modalLayout.contentHeight - 52) + 'px'">
          <gd-upload-edit-file-form #fileMetaForm
                                    [fileData]="enqueuedFile">
          </gd-upload-edit-file-form>
        </div>

      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<!-- Dialog Actions -->
<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="end"
  fxLayoutGap="12px"
  class="gd-embed-media-files-dialog__actions"
  [dir]="dir$ | async"
>
  <button
    [disabled]="queueBeingProcessed$ | async"
    mat-flat-button
    [mat-dialog-close]="'UPLOAD_DONE'"
    i18n
  >
    Close
  </button>
  <button
    *ngIf="activeTab === 'Library'"
    [disabled]="!fileData.length"
    mat-raised-button
    color="primary"
    (click)="insertFile()"
    i18n
  >
    Select
  </button>
  <button
    *ngIf="activeTab === 'Upload' && (activeToggleMenuItem !== 'details' || !uploadConfig.multiUpload)"
    [disabled]="
      (!uploadConfig.multiUpload && (!hasEnqueuedFiles || enqueuedFile?.invalid)) ||
      (uploadConfig.multiUpload && (!hasEnqueuedFiles || enqueuedFile?.invalid)) ||
      (queueBeingProcessed$ | async)
    "
    mat-raised-button
    color="primary"
    (click)="uploadFile()"
    i18n
  >
    Upload
  </button>
   <button *ngIf="uploadConfig.multiUpload && hasEnqueuedFiles && activeToggleMenuItem === 'details'"
          mat-raised-button
          color="primary"
          i18n
          (click)="saveFileMeta()">
    Save
  </button>
</mat-dialog-actions>

