<div class="user-button-container" #userButton>
  <button class="user-button" mat-button [matMenuTriggerFor]="menu" (menuOpened)='menuOpened()'>
    <div fxLayout="row" fxLayoutAlign="start center">
      <img [src]="userAvatarUrl$ | async" class="avatar-flex">
      <span class="name" fxHide="true" fxHide.gt-sm="false">
        {{ username }} | {{ activeAccount?.name }}
      </span>
      <mat-icon fxHide="true" fxHide.gt-sm="false" fontSet="fal" fontIcon="fa-angle-down"></mat-icon>
    </div>
  </button>
</div>

<mat-menu class="user-menu" yPosition="below" [overlapTrigger]="false" #menu="matMenu">
  <div class="user-menu-inner">
    <button *ngIf="!isSuperadmin" mat-menu-item [routerLink]="['/my-profile']" (click)="saveCurrentUrlPath()">
      <mat-icon fontSet="fal" fontIcon="fa-user-circle"></mat-icon>
      <span i18n>My profile</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="invertebrates">
      <mat-icon svgIcon="glideCustomIcon_accounts"></mat-icon>
      <span i18n>Account</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="languages">
      <mat-icon [svgIcon]="nationalFlagsMap[activeLanguage]"></mat-icon>
      <span i18n>Languages</span>
    </button>
    <button mat-menu-item (click)="cycleTheme()">
      <mat-icon fontSet="fal" fontIcon="fa-palette"></mat-icon>
      <span i18n="Changes the UI visual theme from light to dark">Switch theme</span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="logout()" mat-menu-item>
      <mat-icon fontSet="fal" fontIcon="fa-sign-out-alt"></mat-icon>
      <span i18n>Logout</span>
    </button>
  </div>
</mat-menu>

<mat-menu #invertebrates="matMenu" xPosition="before">
  <button
    *ngFor="let account of accounts"
    (click)="changeAccount(account)"
    [class.gd-user-menu__accounts--active]="account?.id === activeAccount?.id"
    mat-menu-item>
    <span>{{account.name}}</span>
  </button>
</mat-menu>

<mat-menu #languages="matMenu" xPosition="before">
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('')"><mat-icon svgIcon="uk-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>English</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('en-US')"><mat-icon svgIcon="en-us-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>English (US)</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('ar')"><mat-icon svgIcon="ar-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Arabic</span></button>
  <button *ngIf="showBosnian" mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('bs')"><mat-icon svgIcon="bs-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Bosnian</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('zh-CN')"><mat-icon svgIcon="zh-cn-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Chinese</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('ja-JP')"><mat-icon svgIcon="ja-jp-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Japanese</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('fr')"><mat-icon svgIcon="fr-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>French</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('fi-FI')"><mat-icon svgIcon="fi-fi-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Finnish</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('de')"><mat-icon svgIcon="de-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>German</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('it')"><mat-icon svgIcon="it-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Italian</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('ko')"><mat-icon svgIcon="ko-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Korean</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('pt')"><mat-icon svgIcon="pt-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Portuguese</span></button>
  <button mat-menu-item class="gd-user-menu-language" (click)="changeLanguage('es')"><mat-icon svgIcon="es-flag"></mat-icon><span class="gd-user-menu-language-name" i18n>Spanish</span></button>
</mat-menu>

