import { Injectable } from '@angular/core';
import { AppState } from '../../store/app-reducer';
import { Store } from '@ngrx/store';
import { selectActiveUserDetails } from '../../store/auth/auth.reducer';
import { filter } from 'rxjs/operators';

@Injectable()
export class UserPreferencesService {
  activeUserKey;

  constructor(private store: Store<AppState>) {
    this.init();
  }

  init(): void {
    this.store.select(selectActiveUserDetails)
      .pipe(filter(userData => !!userData))
      .subscribe(userData => this.activeUserKey = btoa('preferences' + userData.userId));
  }

  getAllUserPreferences(): any {
    const userPreferences = localStorage.getItem(this.activeUserKey) ? JSON.parse(localStorage.getItem(this.activeUserKey)) : null;
    return userPreferences;
  }

  getUserPreference(key: string): any {
    const userPreferences = localStorage.getItem(this.activeUserKey) ? JSON.parse(localStorage.getItem(this.activeUserKey)) : null;
    if (!userPreferences || !userPreferences.hasOwnProperty(key)) {
      return null;
    }
    return userPreferences[key];
  }

  setUserPreference(key: string, value: any): void {
    let userPreferences = localStorage.getItem(this.activeUserKey) ? JSON.parse(localStorage.getItem(this.activeUserKey)) : null;
    if (!userPreferences) {
      userPreferences = {};
    }
    userPreferences[key] = value;
    localStorage.setItem(this.activeUserKey, JSON.stringify(userPreferences));
  }

  removeUserPreference(key: string) {
    const userPreferences = localStorage.getItem(this.activeUserKey) && JSON.parse(localStorage.getItem(this.activeUserKey));
    if (!userPreferences) {
      return null;
    }
    delete userPreferences[key];
    localStorage.setItem(this.activeUserKey, JSON.stringify(userPreferences));
  }

  clearIsLockedFlags() {
    const userPreferences = this.getAllUserPreferences();
    if (!userPreferences) {
      return;
    }
    Object.keys(userPreferences).filter(key => key.includes('Locked')).forEach(key => delete userPreferences[key]);
    localStorage.setItem(this.activeUserKey, JSON.stringify(userPreferences));
  }
}
