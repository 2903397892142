import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
  SET_IS_DISPLAYED_FLAG,
  SET_SECTION_TITLE,
  RESET_UI_SECTION_TITLE_STATE,
  SET_SECTION_TITLE_DATA,
  SET_SECTION_LIVE_FLAG,
  SET_SECTION_INFO,
} from './ui-section-title.actions';

export interface UISectionTitleState {
  isDisplayed: boolean;
  hasUpdates: boolean;
  isPublished: boolean;
  isLive: boolean;
  title: string;
  description: string;
  sectionInfo?: any;
}

const initialState: UISectionTitleState = {
  isDisplayed: false,
  hasUpdates: false,
  isPublished: false,
  isLive: false,
  sectionInfo: null,
  title: '',
  description: '',
};

export function uiSectionTitleReducer(
  state: UISectionTitleState = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case SET_IS_DISPLAYED_FLAG:
      return {
        ...state,
        isDisplayed: action.payload,
      };

    case SET_SECTION_TITLE:
      return {
        ...state,
        hasUpdates: false,
        title: action.payload,
      };

    case SET_SECTION_LIVE_FLAG:
      return {
        ...state,
        isLive: action.payload.isLive,
      };

    case SET_SECTION_INFO:
      return {
        ...state,
        showSectionInfo: true,
        sectionInfo: action.payload,
      };

    case SET_SECTION_TITLE_DATA:
      return {
        ...state,
        title: action.payload.name,
        hasUpdates: action.payload.hasUpdates,
        isPublished: action.payload.isPublished,
        isLive: action.payload.isLive,
        description: action.payload.description,
      };

    case RESET_UI_SECTION_TITLE_STATE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

export const getUISectionTitleState = (state) => state.uiSectionTitle;

export const getIsDisplayedFlag = createSelector(getUISectionTitleState, data => data.isDisplayed);

export const getSectionTitle = createSelector(getUISectionTitleState, data => data.title);
export const getSectionHasUpdates = createSelector(getUISectionTitleState, data => data.hasUpdates);
export const getIsPublishedFlag = createSelector(getUISectionTitleState, data => data.isPublished);
export const getSectionDescription = createSelector(getUISectionTitleState, data => data.description);
export const getIsLiveFlag = createSelector(getUISectionTitleState, data => data.isLive);
export const getSectionInfo = createSelector(getUISectionTitleState, data => data.sectionInfo);
