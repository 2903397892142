import { Injectable } from '@angular/core';
import { fromEvent, ReplaySubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

const MOBILE_BREAKPOINT = 960;
const LARGE_SCREEN_BREAKPOINT = 1366;

@Injectable({ providedIn: 'root' })
export class SidenavScreenSizeService {
  screenSize;
  sidenavSizeVars = new ReplaySubject(1);

  constructor() {
    this.screenSizeUpdate();
    fromEvent(window, 'resize').subscribe(() => this.screenSizeUpdate());
  }

  private screenSizeUpdate() {
    const windowInnerWidth = window.innerWidth;
    this.sidenavSizeVars.next({
      isMobile: windowInnerWidth < MOBILE_BREAKPOINT,
      isSmallScreen:
        windowInnerWidth > MOBILE_BREAKPOINT && windowInnerWidth <= LARGE_SCREEN_BREAKPOINT,
      isLarge: windowInnerWidth > LARGE_SCREEN_BREAKPOINT,
    });
  }

  getSizeState(): Observable<any> {
    return this.sidenavSizeVars
      .asObservable()
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
  }
}
