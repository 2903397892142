import { UnsafeAction } from '../unsafe-action.interface';
import { CREATE_LIVE_REPORT, CREATE_LIVE_REPORT_SUCCESS, CREATE_SUMMARY, CREATE_SUMMARY_SUCCESS, CREATE_NEW_EMPTY_LIVE_REPORT, GET_ACTIVE_LIVE_REPORT, GET_ACTIVE_LIVE_REPORT_SUCCESS, GET_SUMMARY, GET_SUMMARY_SUCCESS, LIVE_REPORT_FAILED, PUBLISH_LIVE_REPORT, PUBLISH_LIVE_REPORT_SUCCESS, PUBLISH_SUMMARY, PUBLISH_SUMMARY_SUCCESS, RESET_ACTIVE_LIVE_REPORT, UNPUBLISH_LIVE_REPORT, UNPUBLISH_LIVE_REPORT_SUCCESS, UNPUBLISH_SUMMARY, UNPUBLISH_SUMMARY_SUCCESS, UPDATE_LIVE_REPORT, UPDATE_LIVE_REPORT_SUCCESS, UPDATE_SUMMARY, UPDATE_SUMMARY_SUCCESS, SET_LIVE_REPORT_LOADING_FLAG, LOAD_LIVE_REPORT_RELATED_ARTICLES_SUCCESS, INCREMENT_POST_COUNT, DECREMENT_POST_COUNT } from './live-report.actions';
import { createSelector } from 'reselect';
import { AppState } from '../app-reducer';
import { ActiveLiveReportState, getEmptyActiveLiveReportState } from './live-report.model';

const initialState: ActiveLiveReportState = getEmptyActiveLiveReportState({});

export function activeLiveReportReducer(state: ActiveLiveReportState = initialState, action: UnsafeAction) {
  switch (action.type) {
    case GET_ACTIVE_LIVE_REPORT: {
      return getEmptyActiveLiveReportState({ loading: true });
    }

    case GET_ACTIVE_LIVE_REPORT_SUCCESS: {
      const activeLiveReport = action.payload;
      return {
        ...state,
        ...activeLiveReport,
        loading: false,
        loaded: true
      }
    }
    case RESET_ACTIVE_LIVE_REPORT: {
      return getEmptyActiveLiveReportState({});
    }
    case LIVE_REPORT_FAILED: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case CREATE_NEW_EMPTY_LIVE_REPORT: {
      return getEmptyActiveLiveReportState({});
    }
    case CREATE_LIVE_REPORT:
    case UPDATE_LIVE_REPORT:
    case PUBLISH_LIVE_REPORT:
    case CREATE_SUMMARY:
    case PUBLISH_SUMMARY:
    case UNPUBLISH_SUMMARY:
    case UPDATE_SUMMARY:
    case UNPUBLISH_LIVE_REPORT: {
      return { ...state, loading: true };
    }
    case CREATE_LIVE_REPORT_SUCCESS: {
      const liveReport = action.payload;
      return {
        ...state,
        ...liveReport,
        loaded: true,
        loading: false,
      };
    }
    case UPDATE_LIVE_REPORT_SUCCESS: {
      const liveReport = action.payload;
      return {
        ...state,
        ...liveReport,
        loaded: true,
        loading: false,
      };
    }
    case PUBLISH_LIVE_REPORT_SUCCESS: {
      const liveReport = action.payload;
      return {
        ...state,
        ...liveReport,
        loading: false,
      };
    }
    case UNPUBLISH_LIVE_REPORT_SUCCESS: {
      const liveReport = action.payload;
      const newState = {
        ...state,
        ...liveReport,
        loading: false,
      };
      delete newState.publishedRevision;
      return newState;
    }
    case GET_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    }
    case CREATE_SUMMARY_SUCCESS:
    case PUBLISH_SUMMARY_SUCCESS:
    case UNPUBLISH_SUMMARY_SUCCESS:
    case UPDATE_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload,
        loading: false,
      }
    }

    case SET_LIVE_REPORT_LOADING_FLAG: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case LOAD_LIVE_REPORT_RELATED_ARTICLES_SUCCESS: {
      const relatedArticles = action.payload;
      return {
        ...state,
        loading: false,
        relatedArticles
      };
    }

    case INCREMENT_POST_COUNT: {
      return {
        ...state,
        postCount: state.postCount + 1,
      }
    }

    case DECREMENT_POST_COUNT: {
      return {
        ...state,
        postCount: state.postCount - 1,
      }
    }

    default: {
      return state;
    }
  }
}

export const getActiveLiveReportState = (state: AppState): ActiveLiveReportState => state.activeLiveReport;
export const getActiveLiveReportWorkingRevision = createSelector(getActiveLiveReportState, (activeLiveReport) => activeLiveReport && activeLiveReport.workingRevision);
export const getLiveReportAuthors = createSelector(getActiveLiveReportWorkingRevision, (workingRevision) => workingRevision && workingRevision.authors);
export const getActiveLiveReportPublishedRevision = createSelector(getActiveLiveReportState, (activeLiveReport) => activeLiveReport && activeLiveReport.publishedRevision);
export const getLiveReportPostCount = createSelector(getActiveLiveReportState, (activeLiveReport) => activeLiveReport && activeLiveReport.postCount);
export const isActiveLiveReportLoading = createSelector(getActiveLiveReportState, ({ loading }) => loading);
export const isActiveLiveReportLoaded = createSelector(
  getActiveLiveReportState,
  (activeLiveReportState) => {
    return activeLiveReportState.loaded && !activeLiveReportState.loading;
  }
);

export const getSummaryState = createSelector(
  getActiveLiveReportState,
  state => state.summary
);

export const getLiveReportRelatedArticles = createSelector(getActiveLiveReportState, activeLiveReport => {
  return activeLiveReport.loading ? null : activeLiveReport.relatedArticles;
});

export const getAutofillAuthor = createSelector(getActiveLiveReportState, liveReport => {
  const revision = liveReport.publishedRevision || liveReport.workingRevision;
  return revision && revision.autofillAuthor;
});
