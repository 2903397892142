import { UnsafeAction } from '../unsafe-action.interface';

export const GET_USER_CONFIGURATION = '[Images Configuration] Get images configuration';
export class GetUserConfigurationAction implements UnsafeAction {
  readonly type = GET_USER_CONFIGURATION;
  constructor() { }
}

export const GET_USER_CONFIGURATION_COMPLETE = '[Images Configuration] Get images configuration completed';
export class GetUserConfigurationCompleteAction implements UnsafeAction {
  readonly type = GET_USER_CONFIGURATION_COMPLETE;
  constructor(public payload: any) { }
}

export const UPDATE_USER_CONFIGURATION = '[Images Configuration] Update user configuration';
export class UpdateUserConfigurationAction implements UnsafeAction {
  readonly type = UPDATE_USER_CONFIGURATION;
  constructor(public payload: any) { }
}

export const GET_IMAGE_GENERATION_SETTINGS = '[Images Configuration] Get image generation settings';
export class GetImageGenerationSettingsAction implements UnsafeAction {
  readonly type = GET_IMAGE_GENERATION_SETTINGS;
  constructor() { }
}

export const GET_IMAGE_GENERATION_SETTINGS_COMPLETE = '[Images Configuration] Get image generation settings completed';
export class GetImageGenerationSettingsCompleteAction implements UnsafeAction {
  readonly type = GET_IMAGE_GENERATION_SETTINGS_COMPLETE;
  constructor(public payload: any) { }
}

export const UPDATE_IMAGE_GENERATION_SETTINGS = '[Images Configuration] Update image generation settings';
export class UpdateImageGenerationSettingsAction implements UnsafeAction {
  readonly type = UPDATE_IMAGE_GENERATION_SETTINGS;
  constructor(public payload: any) { }
}

export const UPDATE_IMAGE_GENERATION_SETTINGS_COMPLETE = '[Images Configuration] Update image generation settings completed';
export class UpdateImageGenerationSettingsCompleteAction implements UnsafeAction {
  readonly type = UPDATE_IMAGE_GENERATION_SETTINGS_COMPLETE;
  constructor(public payload: any) { }
}

export const IMAGES_CONFIGURATION_ACTION_FAILED = '[ImagesConfiguration] Generic failure action';
export class ImagesConfigurationFailedAction implements UnsafeAction {
  readonly type = IMAGES_CONFIGURATION_ACTION_FAILED;
  constructor(public payload: any) { }
}
