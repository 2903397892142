import { createSelector } from 'reselect';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  CLEAR_CONTENT_LOCALES_STATE_ACTION,
  CREATE_CONTENT_LOCALE,
  CREATE_CONTENT_LOCALE_SUCCESS,
  DELETE_CONTENT_LOCALE,
  DELETE_CONTENT_LOCALE_SUCCESS,
  GET_CONTENT_LOCALES,
  GET_CONTENT_LOCALES_SUCCESS,
  GET_ONE_CONTENT_LOCALE,
  GET_ONE_CONTENT_LOCALE_SUCCESS,
  UPDATE_CONTENT_LOCALE,
  UPDATE_CONTENT_LOCALE_SUCCESS,
} from './content-locales.actions';
import { ContentLocale } from './content-locales.model';

export interface ContentLocalesState {
  loaded: boolean;
  loading: boolean;
  error: string;
  contentLocales: ContentLocale[];
  activeContentLocale: ContentLocale;
}

const initialState: ContentLocalesState = {
  loaded: false,
  loading: false,
  error: null,
  contentLocales: [],
  activeContentLocale: null,
};

export function contentLocalesReducer(
  state: ContentLocalesState = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case CREATE_CONTENT_LOCALE:
    case UPDATE_CONTENT_LOCALE:
    case DELETE_CONTENT_LOCALE:
    case GET_CONTENT_LOCALES: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_CONTENT_LOCALES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        contentLocales: action.payload,
      };
    }

    case GET_ONE_CONTENT_LOCALE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        activeContentLocale: null,
      };
    }

    case GET_ONE_CONTENT_LOCALE_SUCCESS:
    case CREATE_CONTENT_LOCALE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        activeContentLocale: action.payload,
      };
    }
    case UPDATE_CONTENT_LOCALE_SUCCESS: {
      const updatedContentLocale = action.payload;
      const updatedIndex = state.contentLocales.findIndex(
        (el) => el.id === updatedContentLocale.id
      );
      const contentLocales = [
        ...state.contentLocales.slice(0, updatedIndex),
        updatedContentLocale,
        ...state.contentLocales.slice(updatedIndex + 1),
      ];
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        contentLocales: contentLocales,
        activeContentLocale: updatedContentLocale,
      };
    }

    case DELETE_CONTENT_LOCALE_SUCCESS: {
      const deletedId = action.payload;
      const contentLocales = state.contentLocales.filter(
        (contentLocale) => contentLocale.id !== deletedId
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        contentLocales: contentLocales,
        activeContentLocale: null,
      };
    }

    case CLEAR_CONTENT_LOCALES_STATE_ACTION: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

export const getContentLocalesState = (state) => state.contentLocales;

export const getContentLocalesList = createSelector(
  getContentLocalesState,
  (state) => state.contentLocales
);

export const getActiveContentLocalesList = createSelector(
  getContentLocalesState,
  (state) => (state.contentLocales || []).filter(locale => locale.active)
);

export const getFilteredActiveContentLocalesList = (filter) => {
  return createSelector(
    getActiveContentLocalesList,
    (locales) => {
      filter = (filter || '').trim();
      if (filter) {
        return locales.filter((locale) => locale.label.toLowerCase().includes(filter.toLowerCase()));
      }
      return locales;
    }
  )
};

export const getContentLocalesByIds = (ids) => {
  return createSelector(
    getContentLocalesList,
    locales => {
      if (ids.length) {
        return ids.map(id => {
          return locales.find(locale => locale.id === id)
          || { id: id, label: `[Content Locale - ${id}]`, localeCode: '', slug: '', iconUrl: '/assets/img/flags/xx.svg', active: false, deleted: true };
        })
      }
      return [];
    }
  )
};

// returns a selector for a locale by ID or if the passed ID is 0 or null then it returns a default locale
export const getContentLocaleById = (localeId) => {
  return createSelector(
    getContentLocalesList,
    (localesList) => {
      if(localeId === 0) {
        return  (localesList || []).find(locale => locale.defaultLocale);
      }
      return (localesList || []).find(locale => locale.id === localeId);
    }
  )
}

export const multipleLocalesExist = createSelector(
  getContentLocalesList,
  (locales) => !!(locales && locales.length > 1)
);

export const getActiveContentLocale = createSelector(
  getContentLocalesState,
  (state) => state.activeContentLocale
);

export const getDefaultContentLocale = createSelector(
  getContentLocalesState,
  (state) => state.contentLocales.find(item => item.active && item.defaultLocale)
);
