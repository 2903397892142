import { OnInit, Component, Inject, SimpleChanges, OnChanges, OnDestroy, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateQueuedFileAction } from '../../core/store/files-upload/files-upload.actions';
import { BidiService } from '../../core/i18n/bidi.service';
import { FilesService } from '../../core/api/files/files.service';

@Component({
  selector: 'gd-upload-edit-file-form',
  templateUrl: './upload-edit-file-form.component.html',
  styleUrls: ['./upload-edit-file-form.component.scss'],
})
export class UploadEditFileFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fileData = null;
  file;
  editFileForm: UntypedFormGroup;
  isStandalone: Boolean = false;
  extension;
  taxonomyEditorData;
  isSaved = false;
  fileEditDetails = {};
  froalaConfigOptions = {
    quickInsertEnabled: false,
    heightMin: 150,
  };
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<UploadEditFileFormComponent>,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private bidiService: BidiService,
    private filesService: FilesService,
  ) {}

  ngOnInit() {
    this.editFileForm = this.initEditFileFormGroup();
    if (this.fileData) {
      this.isStandalone = true;
      this.file = { ...this.fileData};
    } else {
      this.file = {...this.dialogInputData.data.file };
    }
    this.updateEditFileFormGroup(this.file);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.fileData && changes.fileData.currentValue && changes.fileData.previousValue) {
      this.file = { ...changes.fileData.currentValue };
      this.updateEditFileFormGroup(this.file);
    }
  }

  initEditFileFormGroup() {
    return this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[^<>,#&+:"|?*\/\\\\]*$')]],
      filename: ['', [Validators.required, Validators.pattern('^[^<>,#&+:"|?*\/\\\\]*$')]],
      description: [''],
    });
  }

  updateEditFileFormGroup(data) {
    this.extension =  data.fileData.name.match(/[^.]+$/g);
    const regex = /\.[^.]*$/g;
    const name = data.fileData.name.replace(regex, '');
    const fileEditDetails = {
      filename: data.filename ? data.filename.replace(regex, '') : name,
      name: data.metaData.name || name,
      description: data.metaData.description || '',
    };
    this.editFileForm.patchValue({
      filename: fileEditDetails.filename,
      name: fileEditDetails.name,
      description: fileEditDetails.description,
    });
    this.taxonomyEditorData = this.editFileForm.value.description || '';
  }

  saveFileDetails() {
    this.dialogRef.close();
    this.isSaved = true;
  }

  handleEditorContentChange(change) {
    this.editFileForm.get('description').patchValue(change || '');
    this.editFileForm.markAsDirty();
  }

  checkNameField() {
    const nameControl = this.editFileForm.get('name');
    if (!nameControl.value.trim()) {
      nameControl.setValue(this.editFileForm.get('filename').value);
    }
  }

  getEditFileResponse() {
    const payload = {
      queueID: this.file.queueID,
          filename: this.editFileForm.value.filename + `.${this.extension}`,
          metaData: {
            description: this.editFileForm.value.description,
            name: this.editFileForm.value.name,
          },
    };
    return payload;
  }

  returnFileSize(size) {
    return this.filesService.resolveFileSize(size);
  }

  ngOnDestroy() {
    if (this.isSaved) {
      this.store.dispatch(
        new UpdateQueuedFileAction({
          queueID: this.file.queueID,
          filename: this.editFileForm.value.filename + `.${this.extension}`,
          metaData: {
            description: this.editFileForm.value.description,
            name: this.editFileForm.value.name,
          },
        })
      );
    }
  }
}
