import { NgModule } from "@angular/core";
import { DateTransformPipe } from "./date-transform.pipe";


@NgModule({
  declarations: [
    DateTransformPipe
  ],
  imports: [
  ],
  exports: [
    DateTransformPipe
  ]
})
export class DateTransformModule { }
