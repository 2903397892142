
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { of } from 'rxjs';

@Injectable()
export class HtmlSnippetsService {

  constructor(private rest: RestService) { }

  getHtmlSnippets() {
    return this.rest.get('widgets/html-snippets').pipe(
      map(response => response.data));
  }

  getHtmlSnippet(id) {
    return this.rest.get(`widgets/html-snippets/${id}`).pipe(
      map(response => response.data));
  }

  createHtmlSnippet(payload) {
    return this.rest.post('widgets/html-snippets', payload).pipe(
      map(response => response.data));
  }

  updateHtmlSnippet(payload) {
    const htmlSnippet = payload;
    return this.rest.put(`widgets/html-snippets/${htmlSnippet.id}`, htmlSnippet).pipe(
      map((response: any) => response.data));
  }

  deleteHtmlSnippet(id) {
    return this.rest.delete(`widgets/html-snippets/${id}`);
  }

  getFilteredHtmlSnippets(name) {
    let requestPath = `widgets/html-snippets?`;
    requestPath += name ? `name=${name}` : '';
    return this.rest
      .get(requestPath).pipe(
      map((response: any) => response.data));
  }

  getHtmlSnippetByIds(snippetIds) {
    if (snippetIds.length < 1) {
      return of([]);
    }
    return this.rest.get(`widgets/html-snippets?ids=${snippetIds.join(',')}`).pipe(
          map((response: any) => response.data));
  }
}
