import { UnsafeAction } from '../unsafe-action.interface';
import { Collection } from './collection.model';

export const CREATE_COLLECTION = '[COLLECTIONS] Create Collection';
export class CreateCollectionAction implements UnsafeAction {
  readonly type = CREATE_COLLECTION;
  constructor(public payload: Collection) { }
}

export const CREATE_COLLECTION_SUCCESS = '[COLLECTIONS] Create Collection Success';
export class CreateCollectionSuccessAction implements UnsafeAction {
  readonly type = CREATE_COLLECTION_SUCCESS;
  constructor(public payload: Collection) { }
}

export const UPDATE_COLLECTION = '[COLLECTIONS] Update Collection';
export class UpdateCollectionAction implements UnsafeAction {
  readonly type = UPDATE_COLLECTION;
  constructor(public payload: Collection) { }
}

export const UPDATE_COLLECTION_SUCCESS = '[COLLECTIONS] Update Collection Success';
export class UpdateCollectionSuccessAction implements UnsafeAction {
  readonly type = UPDATE_COLLECTION_SUCCESS;
  constructor(public payload: Collection) { }
}

export const COLLECTION_ACTION_FAILED = '[COLLECTIONS] Generic failure action';
export class CollectionFailedAction implements UnsafeAction {
  readonly type = COLLECTION_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_COLLECTION = '[COLLECTIONS] Get Active Collection';
export class GetActiveCollectionAction implements UnsafeAction {
  readonly type = GET_ACTIVE_COLLECTION;
  constructor(public payload: any) { }
}

export const GET_ACTIVE_COLLECTION_SUCCESS = '[COLLECTIONS] Get Active Collection Success';
export class GetActiveCollectionSuccessAction implements UnsafeAction {
  readonly type = GET_ACTIVE_COLLECTION_SUCCESS;
  constructor(public payload: any) { }
}

export const PUBLISH_COLLECTION = '[COLLECTIONS] Publish Collection';
export class PublishCollectionAction implements UnsafeAction {
  readonly type = PUBLISH_COLLECTION;
  constructor(public payload) { }
}

export const PUBLISH_COLLECTION_SUCCESS = '[COLLECTIONS] Publish Collection Success';
export class PublishCollectionSuccessAction implements UnsafeAction {
  readonly type = PUBLISH_COLLECTION_SUCCESS;
  constructor(public payload) { }
}

export const UNPUBLISH_COLLECTION = '[COLLECTIONS] Unpublish Collection';
export class UnpublishCollectionAction implements UnsafeAction {
  readonly type = UNPUBLISH_COLLECTION;
  constructor(public payload) { }
}

export const UNPUBLISH_COLLECTION_SUCCESS = '[COLLECTIONS] Unpublish Collection Success';
export class UnpublishCollectionSuccessAction implements UnsafeAction {
  readonly type = UNPUBLISH_COLLECTION_SUCCESS;
  constructor(public payload) { }
}

export const CHANGE_COLLECTION_REVISION = '[COLLECTIONS] Change Collection Revision';
export class ChangeCollectionRevisionAction implements UnsafeAction {
  readonly type = CHANGE_COLLECTION_REVISION;
  constructor(public payload) { }
}

export const CHANGE_COLLECTION_REVISION_SUCCESS = '[COLLECTIONS] Change Collection Revision Success';
export class ChangeCollectionRevisionSuccessAction implements UnsafeAction {
  readonly type = CHANGE_COLLECTION_REVISION_SUCCESS;
  constructor(public payload) { }
}

export const CREATE_NEW_EMPTY_COLLECTION = '[Collection] Create new empty collection';
export class CreateNewEmptyCollectionAction implements UnsafeAction {
  readonly type = CREATE_NEW_EMPTY_COLLECTION;
  constructor(public payload: { typeId?: number, taxonomyIds?: number[] }) { }
}

export const SET_COLLECTION_TYPE = '[Collection] Set collection type';
export class SetCollectionTypeAction implements UnsafeAction {
  readonly type = SET_COLLECTION_TYPE;
  constructor(public payload) { }
}

export const COPY_COLLECTION_BY_ID = '[Collection] Copy collection by id';
export class CopyCollectionByIdAction implements UnsafeAction {
  readonly type = COPY_COLLECTION_BY_ID;
  constructor(public payload) { }
}

export const RESET_ACTIVE_COLLECTION = '[Collection] Reset active collection';
export class ResetActiveCollectionAction implements UnsafeAction {
  readonly type = RESET_ACTIVE_COLLECTION;
  constructor() { }
}

export const ADD_COLLECTION_AUTHOR = '[Collection] Add author';
export class AddCollectionAuthorAction implements UnsafeAction {
  readonly type = ADD_COLLECTION_AUTHOR;
  constructor(public payload: any) { }
}

export const REMOVE_COLLECTION_AUTHOR = '[Collection] Remove author';
export class RemoveCollectionAuthorAction implements UnsafeAction {
  readonly type = REMOVE_COLLECTION_AUTHOR;
  constructor(public payload: any) { }
}

export const UPDATE_COLLECTION_STATUS = '[Collection] Update status';

export class UpdateCollectionStatus implements UnsafeAction {
  readonly type = UPDATE_COLLECTION_STATUS;
  constructor(public payload: any) { }
}

export const SCHEDULE_COLLECTION = '[Collection] Schedule collection';
export class ScheduleCollectionAction implements UnsafeAction {
  readonly type = SCHEDULE_COLLECTION;
  constructor(public payload) { }
}

export const SCHEDULE_COLLECTION_SUCCESS = '[Collection] Schedule collection success';
export class ScheduleCollectionSuccessAction implements UnsafeAction {
  readonly type = SCHEDULE_COLLECTION_SUCCESS;
  constructor(public payload) { }
}

export const UNSCHEDULE_COLLECTION = '[Collection] Unschedule collection';
export class UnscheduleCollectionAction implements UnsafeAction {
  readonly type = UNSCHEDULE_COLLECTION;
  constructor(public payload) { }
}

export const UNSCHEDULE_COLLECTION_SUCCESS = '[Collection] Unschedule collection success';
export class UnscheduleCollectionSuccessAction implements UnsafeAction {
  readonly type = UNSCHEDULE_COLLECTION_SUCCESS;
  constructor(public payload) { }
}
