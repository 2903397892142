export const hasRequiredValidation = (fieldConfig) => {
  if(fieldConfig) {
    const hasValidators = fieldConfig.validators.length > 0;
    const isDefaultValuesField = fieldConfig.isOnFieldConfigurationPage ? true : false;
    if (!hasValidators || isDefaultValuesField || fieldConfig.fieldType === 'toggle') {
      return false;
    }
    return fieldConfig.validators.some(element => element.validator === 'required');
  }
}
