import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter,
  Output,
  HostListener,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  Renderer2
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import * as Mousetrap from 'mousetrap';

import { AppState } from './../../core/store/app-reducer';
import { getSelectedImage, getImages } from '../../core/store/images/images.reducer';
import { SetSelectedImageAction } from '../../core/store/images/images.actions';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { PermissionService } from '../../core/api/auth/permissions.service';
import { Permissions } from './../../core/store/auth/permissions';
import { ImagesService } from '../../core/api';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  animations: [
    trigger(
      'hidePlaceholderImage', [
        state('void', style({ opacity: 0, transform: 'scale(1)', filter: 'none' })),
        state('*', style({ opacity: 1, transform: 'scale(1.05)' })),
        transition('* => void', [
          style({ opacity: 1, transform: 'scale(1.05)' }),
          animate('250ms ease-in-out', style({ opacity: 0, transform: 'scale(1)', filter: 'none' }))
        ])
      ]
    ),
    trigger(
      'previewImageEnter', [
        state('void', style({ transform: 'scale(1.05)' })),
        state('*', style({ transform: 'scale(1)' })),
        transition('void => *', [
          style({ transform: 'scale(1.05)' }),
          animate('300ms ease-in-out', style({ transform: 'scale(1)' }))
        ])
      ]
    ),
  ]
})
export class ImagePreviewComponent implements OnInit, OnDestroy {

  selectedImage;
  selectedImageSrc = null;
  previousImage;
  nextImage;

  imageRef = new Image();
  loadedImages = [];

  config = {
    previewedContent: 'images',
    showThumbnails: true,
    maxPreviewWidth: '950px',
    maxPreviewHeight: '633px'
  };

  startOfList = true;
  endOfList = true;
  disableArrows = false;

  imagesDataSub: Subscription;
  selectedPreviewImageSub: Subscription;
  dialogDataSub: Subscription;

  shouldChangeLocationURL = true;

  hasUpdateGalleryPermission = this.permissionService.hasPermission(Permissions.GM_GALLERY_UPDATE);
  hasDeleteImagePermission = this.permissionService.hasPermission(Permissions.GM_IMAGE_DELETE);
  hasActionPermissions;

  @Output() triggerEvent = new EventEmitter();

  @ViewChild('previewImageElRef') previewImageElRef: ElementRef;
  @ViewChild('previewWrapperElRef', { static: true }) previewWrapperElRef: ElementRef;
  @ViewChildren('navigationButton') navigationButtons: QueryList<any>;

  @HostListener('click', ['$event']) onclick(event) { this.handleClickEvents(event); }
  @HostListener('window:resize', ['$event']) onresize(event) { this.handleWindowResizeEvent(event); }
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    private store: Store<AppState>,
    private location: Location,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private imagesService: ImagesService,
    private bidiService: BidiService,
  ) {
    Mousetrap.bind(['left', 'right', 'esc'], this.handleKeyboardNavigation.bind(this));
    this.imageRef.addEventListener('load', this.imageLoadedEventHandler.bind(this));
  }

  ngOnInit() {
    this.shouldChangeLocationURL = this.activatedRoute.snapshot['_routerState'].url === '/media/images';

    const isGalleryContent = this.dialogInputData.config && this.dialogInputData.config.previewedContent === 'gallery';
    this.hasActionPermissions = isGalleryContent ? this.hasUpdateGalleryPermission : this.hasDeleteImagePermission;
    this.dialogDataSub = this.dialogInputData.data.subscribe(data => {
      this.loadedImages = [...data];
      const activeImage = this.loadedImages.find(img => img.active);

      if (activeImage) {
        this.selectedImage = activeImage;
        setTimeout(() => this.loadImage(), 0);
      }

      const indexOfSelectedImage = this.selectedImage && this.loadedImages
        .findIndex((value) => value.id === this.selectedImage.id);

      this.checkArrowsState(indexOfSelectedImage);
    });

    // Get selected image to preview from store
    this.selectedPreviewImageSub = this.store.select(getSelectedImage).subscribe(selectedImage => {
      this.selectedImage = selectedImage;

      if (this.selectedImage.id && this.shouldChangeLocationURL) {
        this.location.replaceState('/media/images/preview/' + this.selectedImage.id);
      }

      if (this.selectedImage.id) {
        setTimeout(() => this.loadImage(), 0);
      }

      this.loadedImages = this.loadedImages.map(img => ({ ...img, active: this.selectedImage.id === img.id }));
    });

    // Merge config params
    if (this.dialogInputData.config) {
      this.config = { ...this.config, ...this.dialogInputData.config };
    }
  }

  handleKeyboardNavigation(event) {
    // ArrowRight key event
    if (event.keyCode === 39) {
      this.loadNextImage();
    }
    // ArrowLeft key event
    if (event.keyCode === 37) {
      this.loadPreviousImage();
    }
    // ESC key event
    if (event.keyCode === 27) {
      this.closeDialog();
    }
  }

  handleWindowResizeEvent(event) {
    // Handle navigation visibility (hide navigtion items if window width is lower then 900 since image click is side sensitive)
    const { innerWidth } = event.target;
    this.navigationButtons.forEach(btn => {
      const btnEl = btn['_elementRef'].nativeElement;
      btnEl.style.opacity = innerWidth < 900 ? 0 : 1;
    });
  }

  loadImage() {
    const indexOfSelectedImage = this.loadedImages.findIndex((value) => value.id === this.selectedImage.id);
    const loadedImage = this.loadedImages[indexOfSelectedImage];
    if (!this.selectedImage.previewImage) {
      const previewRendition = this.selectedImage.formats.find(item => item.identifier === 'gm_preview')
      this.selectedImage.previewImage = this.imagesService.generateImageUrl(previewRendition.key);
    }
    if (loadedImage && !loadedImage.visited) {
      loadedImage.visited = true;
      this.selectedImageSrc = null;
      this.imageRef.src = this.selectedImage.previewImage;
    } else if (loadedImage && loadedImage.visited) {
      this.selectedImageSrc = this.selectedImage.previewImage;
    } else {
      this.imageRef.src = this.selectedImage.previewImage;
    }

    this.checkArrowsState(indexOfSelectedImage);
  }

  imageLoadedEventHandler(event) {
    const sourceUrl = event.target.src;
    setTimeout(() => {
      this.selectedImageSrc = sourceUrl;
      this.selectedImage.orientation = this.imageRef.width > this.imageRef.height ? 'horizontal' : 'vertical';
    }, 500);
  }

  loadPreviousImage() {
    const indexOfSelectedImage = this.loadedImages.findIndex((value) => value.id === this.selectedImage.id);
    this.previousImage = this.loadedImages[indexOfSelectedImage - 1];

    if (this.previousImage) {
      this.store.dispatch(new SetSelectedImageAction(this.previousImage));
    }
  }

  loadNextImage() {
    const indexOfSelectedImage = this.loadedImages.findIndex((value) => value.id === this.selectedImage.id);
    this.nextImage = this.loadedImages[indexOfSelectedImage + 1];

    if (this.nextImage) {
      this.store.dispatch(new SetSelectedImageAction(this.nextImage));
    }
  }

  getPreviewImageSource() {
    const imgSrc = !this.selectedImageSrc ? this.selectedImage.thumbnail : this.selectedImage.previewImage;
    return `url(${imgSrc})`;
  }

  checkArrowsState(indexOfSelectedImage): any {
    if (indexOfSelectedImage === -1) {
      return this.disableArrows = true;
    }
    this.disableArrows = false;
    this.startOfList = indexOfSelectedImage === 0;
    this.endOfList = indexOfSelectedImage === this.loadedImages.length - 1;
    return;
  }


  triggerEventAction(action): void {
    const data = { image: this.selectedImage, action };
    this.triggerEvent.emit(data);
  }

  handleClickEvents(event) {
    if (event.target) {
      // expected classes
      const classes = [
        'gd-image-preview__img',
        'gd-image-preview__toolbar-btn',
        'gd-image-preview__navigation-btn',
        'gd-scrolling-list__item-img',
        'gd-scrolling-list-wrapper'];

      const clickedElement = <HTMLElement>event.target;
      const className = clickedElement.className;

      // click is valid if clicked element contains any of expected classes
      const isValidClick = classes.some(c => {
        if (c !== 'gd-scrolling-list-wrapper') {
          return className.includes(c);
        }
        const scrollWrapper = document.getElementsByClassName(c)[0];
        return scrollWrapper ? scrollWrapper.contains(clickedElement) : false;
      });

      if (!isValidClick) {
        return this.closeDialog();
      }

      const clickOnPreviewImage = className.includes('gd-image-preview__img');
      if (!clickOnPreviewImage) {
        return;
      }
      const previewImgBounds = this.previewImageElRef.nativeElement.getBoundingClientRect();
      const xClickPosition = (event.clientX - previewImgBounds.left) + window.pageXOffset;
      const leftSideOfImage = previewImgBounds.width / 2;

      if (xClickPosition >= 0 && xClickPosition <= leftSideOfImage) {
        this.loadPreviousImage();
      } else {
        this.loadNextImage();
      }
    }
  }

  closeDialog() {
    if (this.shouldChangeLocationURL) {
      this.location.replaceState('/media/images');
    }

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.imagesDataSub) {
      this.imagesDataSub.unsubscribe();
    }

    if (this.dialogDataSub) {
      this.dialogDataSub.unsubscribe();
    }

    if (this.selectedPreviewImageSub) {
      this.selectedPreviewImageSub.unsubscribe();
    }
  }

}
