import { UnsafeAction } from '../unsafe-action.interface';
import { createSelector } from 'reselect';
import {
  GET_SUBSCRIBERS, GET_SUBSCRIBERS_SUCCESS, CREATE_SUBSCRIBER, UPDATE_SUBSCRIBER, DELETE_SUBSCRIBER,
  CREATE_SUBSCRIBER_SUCCESS, UPDATE_SUBSCRIBER_SUCCESS, DELETE_SUBSCRIBER_SUCCESS, SET_SUBSCRIBERS_PAGE_VIEW_OPTIONS, GET_ONE_SUBSCRIBER_SUCCESS, GET_ONE_SUBSCRIBER, GET_TRANSMIT_EVENTS, GET_TRANSMIT_EVENTS_SUCCESS
} from './subscribers.actions';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';
import { Subscriber } from './subscribers.model';

export interface SubscribersState {
  loaded: boolean;
  loading: boolean;
  error: string;
  subscribers: Subscriber[];
  subscribersView?: SubscribersPageView;
  activeSubscriber: Subscriber;
  eventTriggers: string[];
}

export interface SubscribersPageView {
  currentPage: number;
  total: number;
  pageSize: number;
  pageSizeOptions: any[];
}

const initialState: SubscribersState = {
  loaded: false,
  loading: false,
  error: null,
  subscribers: [],
  subscribersView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  activeSubscriber: null,
  eventTriggers: null,
};

export function subscribersReducer(state: SubscribersState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_TRANSMIT_EVENTS:
    case GET_SUBSCRIBERS:
    case CREATE_SUBSCRIBER:
    case UPDATE_SUBSCRIBER:
    case DELETE_SUBSCRIBER: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_SUBSCRIBERS_SUCCESS: {
      const subscribers = action.payload.data;
      const meta = action.payload.meta;
      return {
        ...state,
        loading: false,
        loaded: true,
        subscribers,
        subscribersView: {
          ...state.subscribersView,
          total: meta.page.total,
          pageSize: meta.page.size,
          currentPage: meta.page.number,
        }
      };
    }

    case GET_ONE_SUBSCRIBER: {
      return {
        ...state,
        loading: true,
        loaded: false,
        activeSubscriber: null,
      };
    }

    case GET_ONE_SUBSCRIBER_SUCCESS:
    case CREATE_SUBSCRIBER_SUCCESS: {
      const activeSubscriber = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        activeSubscriber,
      };
    }
    case GET_TRANSMIT_EVENTS_SUCCESS: {
      const eventTriggers = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        eventTriggers,
      };
    }

    case UPDATE_SUBSCRIBER_SUCCESS: {
      const updatedSubscriber = action.payload;
      const updatedIndex = state.subscribers.findIndex(el => el.id === updatedSubscriber.id);
      const newSubscribers = [
        ...state.subscribers.slice(0, updatedIndex),
        updatedSubscriber,
        ...state.subscribers.slice(updatedIndex + 1)
      ];
      return {
        ...state,
        loading: false,
        loaded: true,
        subscribers: newSubscribers,
        activeSubscriber: updatedSubscriber,
      };
    }

    case DELETE_SUBSCRIBER_SUCCESS: {
      const deletedId = action.payload;
      const subscribers = state.subscribers.filter(subscriber => subscriber.id !== deletedId);
      return {
        ...state,
        loading: false,
        loaded: true,
        subscribers,
        activeSubscriber: null,
      };
    }

    case SET_SUBSCRIBERS_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        subscribersView: {
          ...state.subscribersView,
          currentPage: pageIndex,
          pageSize: pageSize || state.subscribersView.pageSize
        }
      };
    }

    default: {
      return state;
    }
  }

}

export const getSubscribersState = (state) => {

  return state.subscribers
};

export const getSubscribersPageView = createSelector(getSubscribersState, (state): SubscribersPageView => state.subscribersView);

export const getSubscribersList = createSelector(getSubscribersState, state => state.subscribers);

export const getActiveSubscriber = createSelector(getSubscribersState, state => state.activeSubscriber);

export const getTransmitEvents = createSelector(getSubscribersState, state => state.eventTriggers);

