import { filter, take, catchError, mergeMap, map, tap, debounceTime } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import {
  GET_TAXONOMIES,
  GetTaxonomiesCompleteAction,
  DELETE_TAXONOMY,
  DeleteTaxonomyCompleteAction,
  CREATE_TAXONOMY,
  CreateTaxonomyCompleteAction,
  UpdateTaxonomyCompleteAction,
  UPDATE_TAXONOMY,
  REORDER_TAXONOMIES,
  ReorderTaxonomieCompleteAction,
  TAXONOMIES_ACTION_FAILED,
  TaxonomiesFailedAction,
  CREATE_QUICK_TAXONOMY,
  TAXONOMY_TREE_NODE_UPDATE,
  GET_TAXONOMY,
  TaxonomyTreeNodeUpdateComplete,
  ClearTaxonomiesAction,
} from './taxonomies.actions';
import { TaxonomiesService } from '../../api/taxonomies/taxonomies.service';
import { UnsafeAction } from '../unsafe-action.interface';
import { AppState } from '../app-reducer';
import { getTaxonomies, Taxonomy } from './taxonomies.reducer';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '../../../../../node_modules/@angular/router';
import { AuthService } from '../../api';
import { ACCOUNT_CHANGED } from '../auth';
import { GetTaxonomyCompleteAction } from '.';

@Injectable()
export class TaxonomiesEffects {

  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .pipe(map(() => new ClearTaxonomiesAction())));

  loadTaxonomies$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_TAXONOMIES)).pipe(
    debounceTime(100),
    mergeMap((action: UnsafeAction) => {
      return this.taxonomiesService
        .getTaxonomies()
        .pipe(
          map((taxonomies) => {
            return taxonomies.map(taxonomy => {
              const localizations = [taxonomy, ...(taxonomy.localizedVersions || [])];
              const localizedDisplayData = localizations.reduce((acc, tax) => {
                const localeId = tax.contentLocale?.id;
                return { ...acc, [localeId]: { name: tax.name, slug: tax.slug } };
              }, {});

              const taxonomyPermissions = this.authService.getUserTaxonomyPermissions();
              const taxonomyPermissionsMap = this.authService.getUserTaxonomyPermissionsMap();
              const hasAllTaxonomyPermissions = taxonomyPermissions.includes(0);

              return {
                ...taxonomy,
                localizedDisplayData,
                userHasPermission: hasAllTaxonomyPermissions || !!taxonomyPermissionsMap[taxonomy.id]};
            });
          }),
          map((taxonomies) => getNormalizedTaxonomies(taxonomies)),
          map((taxonomies) => assignTaxonomyLookupIds(taxonomies)),
          map((taxonomies) => new GetTaxonomiesCompleteAction(taxonomies))
        )
        .pipe(catchError((e) => {
          console.log(e)
          return of(new TaxonomiesFailedAction({ error: e, action }))
        }));
    })
  ));


  deleteTaxonomy$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_TAXONOMY)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.taxonomiesService.deleteTaxonomy(action.payload).pipe(
        map(() => new DeleteTaxonomyCompleteAction(action.payload)),
        catchError((e) => of(new TaxonomiesFailedAction({ error: e, action, message: $localize`Taxonomy delete failed! Taxonomy may be in use.` })))
      );
    })
  ));

  getSingleTaxonomy$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_TAXONOMY)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.taxonomiesService.getTaxonomy(action.payload).pipe(
        map((taxonomy: Taxonomy) => new GetTaxonomyCompleteAction(taxonomy)),
        catchError((e) => {
          // single taxonomy not found, redirect to taxonomies list
          if(e.code === 'NOT_FOUND') {
            this.router.navigate(['taxonomies/']);
          }
          return of(new TaxonomiesFailedAction({ error: e, action, message: `Invalid taxonomy Id: ${action.payload}!` }))
        })
      );
    })
  ));


  createTaxonomy$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_TAXONOMY, CREATE_QUICK_TAXONOMY)).pipe(
    mergeMap((action: UnsafeAction) => {
      const isQuickCreate = action.type === CREATE_QUICK_TAXONOMY;
      return this.taxonomiesService.createTaxonomy(action.payload).pipe(
        tap((taxonomy) => {
          const message =
            $localize`Taxonomy saved. Please make sure that your taxonomy permissions are as desired.`;
          this.snackBar.open(message, $localize`Close`, { duration: 4000 });
          // on regular taxonomy create do update the current route
          if(!isQuickCreate) {
            this.router.navigate(['taxonomies', taxonomy?.id]);
          }
        }),
        mergeMap(taxonomy => this.store.select(getTaxonomies).pipe(take(1), map(allTaxonomies => [taxonomy, allTaxonomies]))),
        map(([taxonomy, allTaxonomies]) => {
          const { contentLocale, name, slug } = taxonomy;
          const payloadAfterCreate = {
            ...taxonomy,
            parent: taxonomy.parent || null,
            children: taxonomy.children || [],
            userHasPermission: true,
            localizedDisplayData: { [contentLocale?.id]: { name, slug } },
            lookupId: getTaxonomyLookupId(taxonomy, allTaxonomies)
          };
          return new CreateTaxonomyCompleteAction(payloadAfterCreate);
        }),
        catchError((e) => of(new TaxonomiesFailedAction({ error: e, action })))
      );
    })
  ));

  updateTaxonomy$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_TAXONOMY)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.taxonomiesService.updateTaxonomy(action.payload).pipe(
        map((taxonomy) => {
          const taxonomyAfterEdit = {
            ...taxonomy,
            parent: taxonomy.parent || null,
            children: taxonomy.children || [],
          };
          return new UpdateTaxonomyCompleteAction(taxonomyAfterEdit);
        }),
        catchError((e) => of(new TaxonomiesFailedAction({ error: e, action })))
      )
    )
  ));

  reorderTaxonomies$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(REORDER_TAXONOMIES))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        const updatedTaxoId = action.payload.nodeId;
        return combineLatest([
          this.store.select(getTaxonomies).pipe(
            take(1),
            map((taxonomies) => taxonomies[updatedTaxoId])
          ),
          this.taxonomiesService.getTaxonomy(updatedTaxoId)
        ]).pipe(
          mergeMap(([taxoFromState, taxoFromApi]) => {
            const taxonomy = { ...taxoFromApi, ...taxoFromState };
            return this.taxonomiesService.updateTaxonomy(taxonomy).pipe(
              map((res) => new ReorderTaxonomieCompleteAction(res.data)),
              catchError((e) => of(new TaxonomiesFailedAction({ error: e, action })))
            );
          })
        );
      })
    ));

  updateTreeNodeTaxonomies$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(TAXONOMY_TREE_NODE_UPDATE))
    .pipe(
      mergeMap((action: UnsafeAction) => {
        const updatedTaxoId = action.payload.updateTaxonomy.id;
        return this.store.select(getTaxonomies).pipe(
          take(1),
          map((taxonomies) => taxonomies[updatedTaxoId]),
          mergeMap((taxoToUpdate) => {
            return this.taxonomiesService.updateTaxonomy(taxoToUpdate).pipe(
              map((res) => new TaxonomyTreeNodeUpdateComplete(res)),
              catchError((e) => of(new TaxonomiesFailedAction({ error: e, action })))
            );
          }),
          tap(() => {
            this.snackBar.open(`${action.payload.message || $localize`Taxonomy saved`}`, $localize`Close`, { duration: 4000 });
          })
        );
      })
    ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(TAXONOMIES_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      const message = err.payload?.message
        ? err.payload?.message
        : $localize`Action failed: ` + actionType;
      this.snackBar.open(message, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  constructor(
    private actions$: Actions,
    private taxonomiesService: TaxonomiesService,
    private store: Store<AppState>,
    public snackBar: MatSnackBar,
    public router: Router,
    private authService: AuthService
  ) {}
}

/**
 * @param taxonomies this is an array of raw taxonomies that have id, name and parent id
 *
 * @return {normalizedTaxonomies} this is the normalized form that of taxonomies with children IDs array
 */

function getNormalizedTaxonomies(taxonomies) {
  const normalizedTaxonomies = taxonomies.reduce((acc, taxonomy) => {
    taxonomy.children = [];
    taxonomy.parent = taxonomy.parent || taxonomy.parentId || null; // ensure root level taxonomies have parent id of null
    acc[taxonomy.id] = taxonomy;
    return acc;
  }, {});
  taxonomies.forEach((taxonomy) => {
    if (taxonomy.parent) {
      normalizedTaxonomies[taxonomy.parent].children.push(taxonomy.id);
    }
  });
  return normalizedTaxonomies;
}

function assignTaxonomyLookupIds(taxonomies) {
  Object.values(taxonomies).forEach((tax: any) => {
    tax.lookupId = getTaxonomyLookupId(tax, taxonomies);
  });
  return taxonomies;
}

// example: lookupId = 'lid-123-456-'
function getTaxonomyLookupId(taxonomy, allTaxonomies, firstInvocation = true) {
  let lookupParts = [taxonomy.id];
  if (taxonomy.parent) {
    lookupParts = [...lookupParts, getTaxonomyLookupId(allTaxonomies[taxonomy.parent], allTaxonomies, false)];
  }
  const prefix = firstInvocation ? 'lid-' : ''
  const suffix = firstInvocation ? '-' : ''
  return prefix + lookupParts.reverse().join('-') + suffix;
}
