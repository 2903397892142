import { Injectable } from '@angular/core';
import createTTLCache from './simple-ttl-cache';
import { environment } from '../../../../environments/environment';
import { Observable, of, tap } from 'rxjs';
import { ACCOUNT_CHANGED } from '../../store';
import { Actions, ofType } from '@ngrx/effects';

interface GetAPIClient {
  get(url: string, params?: any): any;
}

// TODO implement proper logging service
const printInfoLogs =
  !environment.production ||
  environment.envNotes.includes('GlideCloud1 QA environment') ||
  environment.envNotes.includes('GlideCloud1 DEV environment') ||
  environment.envNotes.includes('This is a local');

@Injectable({
  providedIn: 'root',
})
export class PassthroughCacheFactoryService {
  caches = {};

  constructor(private actions$: Actions) {
    // on account change clear all caches
    this.actions$.pipe(ofType(ACCOUNT_CHANGED)).subscribe(() => this.clearAllCaches());
  }

  private clearAllCaches() {
    Object.values(this.caches).forEach((cache: any) => cache.clear());
  }

  createCache({
    domain,
    apiClient,
    ttl,
  }: {
    domain: string;
    apiClient: GetAPIClient;
    ttl: number;
  }) {
    /**
     * This is a simple TTL cache in which an entry will live for <ttl> value.
     * The cache should be invalidated on every taxonomy resource mutation
     */
    const cache = createTTLCache(ttl);
    /**
     * THe request buffer
     */
    let requestBuffer = {};
    this.caches[domain] = cache;

    return {
      clear: () => {
        cache.clear();
        requestBuffer = {};
      },
      getData: (url, params = undefined): Observable<any> => {
        const cachedValue = cache.get(url);
        if (cachedValue) {
          if (printInfoLogs) {
            console.log(`Cache hit for ${domain} data at: ${url}`);
          }
          return of(cachedValue);
        }

        if (requestBuffer[url]) {
          if (printInfoLogs) {
            console.log(`Returning request from buffer for ${domain} data at: ${url}`);
          }
          return requestBuffer[url];
        }

        const request = apiClient.get(url, params).pipe(
          tap((value) => {
            cache.set(url, value);
            delete requestBuffer[url];
          })
        );
        requestBuffer[url] = request;

        return request;
      },
    };
  }
}
