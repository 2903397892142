import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-reducer';
import { UserPreferencesService } from '../user-preferences/user-preferences.service';
import { getLockedItemDetails, isItemLocked } from '../../store/lock/lock.reducer';
import { AuthService } from '../auth/auth.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LockService {
  lockedItemDetails;
  isItemLocked;

  constructor(
    private rest: RestService,
    private store: Store<AppState>,
    private authService: AuthService,
    private userPreferencesService: UserPreferencesService
  ) {
    this.store
      .select(getLockedItemDetails)
      .subscribe((lockedItem) => (this.lockedItemDetails = lockedItem));
    this.store.select(isItemLocked).subscribe((isLocked) => (this.isItemLocked = isLocked));
  }

  getLock(payload = null) {
    const { id, type } = payload || this.lockedItemDetails;
    if (!id) {
      return of(null);
    }
    return this.rest.get(`locks/${type}/${id}`).pipe(map((response) => response && response.data));
  }

  setLock(payload = null) {
    const { id, type } = payload || this.lockedItemDetails;
    if (!id || !type) {
      return of(null);
    }
    return this.rest.post(`locks/${type}/${id}`).pipe(map((response) => response.data));
  }

  releaseLock() {
    const { id, type } = this.lockedItemDetails;
    if (!id || !type) {
      return of(null);
    }
    return this.rest.delete(`locks/${type}/${id}`);
  }

  revokeLock({ id, type }) {
    return this.rest.put(`locks/revoke/${type}/${id}`, {});
  }

  getIsItemLockedFlag() {
    const { id, type } = this.lockedItemDetails;
    const preferenceKey = `${type}${id}Locked`; // e.g. preferenceKey = 'article15Locked';
    return this.userPreferencesService.getUserPreference(preferenceKey) || false;
  }

  setIsItemLockedFlag(flag) {
    const { id, type } = this.lockedItemDetails;
    if (!id || !type) {
      return;
    }
    const preferenceKey = `${type}${id}Locked`; // e.g. preferenceKey = 'article15Locked';
    if (flag) {
      return this.userPreferencesService.setUserPreference(preferenceKey, flag);
    }
    this.userPreferencesService.removeUserPreference(preferenceKey);
  }

  canReleaseLock() {
    return this.authService.isUserLoggedIn() && this.isItemLocked;
  }
}
