import { UnsafeAction } from '../unsafe-action.interface';
import { ContentLocale } from './content-locales.model';

export const GET_CONTENT_LOCALES = '[Content Locales] Get Content Locales';
export class GetContentLocalesAction implements UnsafeAction {
  readonly type = GET_CONTENT_LOCALES;
  constructor(public payload: any = {}) {}
}

export const GET_CONTENT_LOCALES_SUCCESS = '[Content Locales] Get Content Locales success';
export class GetContentLocalesSuccessAction implements UnsafeAction {
  readonly type = GET_CONTENT_LOCALES_SUCCESS;
  constructor(public payload: ContentLocale[]) {}
}

export const GET_ONE_CONTENT_LOCALE = '[Content Locales] Get One Content Locale';
export class GetOneContentLocaleAction implements UnsafeAction {
  readonly type = GET_ONE_CONTENT_LOCALE;
  constructor(public payload: number) {}
}

export const GET_ONE_CONTENT_LOCALE_SUCCESS = '[Content Locales] Get One Content Locale success';
export class GetOneContentLocaleSuccessAction implements UnsafeAction {
  readonly type = GET_ONE_CONTENT_LOCALE_SUCCESS;
  constructor(public payload: ContentLocale) {}
}

export const CREATE_CONTENT_LOCALE = '[Content Locale] Create Content Locale';
export class CreateContentLocaleAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_LOCALE;
  constructor(public payload: ContentLocale) {}
}

export const CREATE_CONTENT_LOCALE_SUCCESS = '[Content Locale] Create Content Locale success';
export class CreateContentLocaleSuccessAction implements UnsafeAction {
  readonly type = CREATE_CONTENT_LOCALE_SUCCESS;
  constructor(public payload: ContentLocale) {}
}

export const UPDATE_CONTENT_LOCALE = '[Content Locale] Update Content Locale';
export class UpdateContentLocaleAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_LOCALE;
  constructor(public payload: ContentLocale) {}
}

export const UPDATE_CONTENT_LOCALE_SUCCESS = '[Content Locale] Update Content Locale success';
export class UpdateContentLocaleSuccessAction implements UnsafeAction {
  readonly type = UPDATE_CONTENT_LOCALE_SUCCESS;
  constructor(public payload: ContentLocale) {}
}

export const DELETE_CONTENT_LOCALE = '[Content Locale] Delete Content Locale';
export class DeleteContentLocaleAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_LOCALE;
  constructor(public payload: number) {}
}

export const DELETE_CONTENT_LOCALE_SUCCESS = '[Content Locale] Delete Content Locale success';
export class DeleteContentLocaleSuccessAction implements UnsafeAction {
  readonly type = DELETE_CONTENT_LOCALE_SUCCESS;
  constructor(public payload: any) {}
}

export const CLEAR_CONTENT_LOCALES_STATE_ACTION = '[Content Locales] Clear Content Locales State';
export class ClearContentLocalesStateAction implements UnsafeAction {
  readonly type = CLEAR_CONTENT_LOCALES_STATE_ACTION;
  constructor() {}
}

export const CONTENT_LOCALE_ACTION_FAILED = '[Content Locale] Generic failure action';
export class ContentLocaleFailedAction implements UnsafeAction {
  readonly type = CONTENT_LOCALE_ACTION_FAILED;
  constructor(public payload: any) {}
}
