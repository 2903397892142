import { UnsafeAction } from '../unsafe-action.interface';
import * as regexEscape from 'escape-string-regexp';
import {
  GET_PAGES, GET_PAGES_SUCCESS, DELETE_PAGE, UPDATE_PAGE,
  CREATE_PAGE, CREATE_PAGE_SUCCESS,
  UPDATE_PAGE_SUCCESS,
  DELETE_PAGE_SUCCESS,
  PAGE_FAILED_ACTION,
  SET_PAGE_FILTER,
  SET_ACTIVE_PAGE_ID,
  PUBLISH_PAGE,
  UNPUBLISH_PAGE,
  SET_ACTIVE_PAGE,
  CLEAR_PAGES_STATE_ACTION
} from './pages.actions';
import { createSelector } from 'reselect';
import { Page, PagesMap, PageTreeItem, PageTypeLabels } from './pages.model';
import { AppState } from '../app-reducer';
import { sortByLabelAsc } from '../utility-functions';
import { getRoutes } from '../routes/route.reducers';

export interface PagesState {
  pages: any;
  // UI related state
  activePageId: number;
  loaded: boolean;
  loading: boolean;
  error: string;
  nameFilter: string;
  activePage: any;
}

export const pagesInitialState: PagesState = {
  pages: {},
  activePageId: null,
  loaded: false,
  loading: false,
  error: null,
  nameFilter: '',
  activePage: null,
};

export function pagesReducer(state: PagesState = pagesInitialState, action: UnsafeAction) {
  switch (action.type) {


    case GET_PAGES:
    case DELETE_PAGE:
    case UPDATE_PAGE:
    case PUBLISH_PAGE:
    case UNPUBLISH_PAGE:
    case CREATE_PAGE: {
      return { ...state, loading: true };
    }

    case CLEAR_PAGES_STATE_ACTION: {
      return { ...pagesInitialState };
    }

    case SET_PAGE_FILTER: {
      const nameFilter: string = action.payload;
      return { ...state, nameFilter };
    }

    case SET_ACTIVE_PAGE_ID: {
      const activePageId: number = action.payload;
      return { ...state, activePageId };
    }

    case GET_PAGES_SUCCESS: {
      const pagesArray = action.payload;

      const pages = pagesArray.reduce((acc, p: Page) => {
        acc[p.id] = p;
        return acc;
      }, {});

      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
        pages: pages,
      };
    }

    case CREATE_PAGE_SUCCESS: {
      const newPage = action.payload;
      const pages = {
        ...state.pages,
        [newPage.id]: newPage
      };

      return {
        ...state,
        loading: false,
        error: null,
        pages: pages,
        activePage: newPage
      };
    }

    case UPDATE_PAGE_SUCCESS: {
      const updatedPage = action.payload;
      const pages = {
        ...state.pages,
        [updatedPage.id]: { ...updatedPage }
      };

      return {
        ...state,
        loading: false,
        error: null,
        pages,
        activePage: { ...updatedPage }
      };
    }

    case DELETE_PAGE_SUCCESS: {
      const deletedId = action.payload;
      const pages = { ...state.pages };
      delete pages[deletedId];
      return {
        ...state,
        loading: false,
        error: null,
        pages: pages
      };
    }

    case SET_ACTIVE_PAGE: {
      const activePage = action.payload;
      return { ...state, activePage };
    }

    case PAGE_FAILED_ACTION: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }


  }
}

const getAppState = (state: AppState) => state;
export const getPagesState = (state: AppState) => state.pages;
export const getActivePageId = (state: AppState) => state.pages.activePageId;
export const getPages = createSelector(getPagesState, pagesState => pagesState.pages);
export const getPagesLoaded = createSelector(getPagesState, pagesState => pagesState.loaded);
export const getPagesLoading = createSelector(getPagesState, pagesState => pagesState.loading);

export const getActivePage = createSelector(getPagesState, state => state.activePage);

export const getPageNameFilter = (state: AppState) => state.pages.nameFilter;

export const getPagesAsArray = createSelector(
  getPages,
  (pages: PagesMap) => {
    if (!pages) {
      return [];
    }
    return Object.values(pages);
  }
);

export const getActivePageParents = createSelector(
  getPages,
  getActivePage,
  (pages: any, page: Page) => {
    if (!page) {
      console.warn($localize`Cannot fetch page parents, page not found`);
      return null;
    }
    const parentPages: Page[] = [];
    let currentParent = pages[page.parentId];
    while (currentParent) {
      parentPages.push(currentParent);
      currentParent = pages[currentParent.parentId];
    }
    return parentPages;
  });

export const getActivePageAllParentsPublished = createSelector(
  getActivePageParents,
  (activePageParents: Page[]) => {
    if (!activePageParents) {
      return false;
    }
    return activePageParents.every(parent => parent.published);
  });

export const getActivePageDescendants = createSelector(
  getPages,
  getActivePage,
  (pages: any, page: Page) => {
    if (!page) {
      console.warn('Cannot fetch page descendants, page not found');
      return null;
    }
    return fetchPageChildren(page, pages);
  });

function fetchPageChildren(page: Page, pagesMap: any) {
  const children = Object.values(pagesMap)
    .filter((t: Page) => t.parentId === page.id);

  return children.reduce((acc: Page[], pageChild: Page) => {
    return [...acc, ...fetchPageChildren(pageChild, pagesMap)];
  }, [...children]);
}

export const getActivePageHasPublishedChildren = createSelector(
  getActivePageDescendants,
  (activePageDescendants: Page[]) => {
    if (!activePageDescendants) {
      return false;
    }
    return activePageDescendants.some(child => child.published);
  });

export const getRouteIdsAssignedToAllPages = createSelector(
  getPagesAsArray,
  (pagesArray: Page[]) => {
    return pagesArray.reduce((routeIds, page: Page) => {
      const ids = [...page.workingRevision.routeIds];
      if (page.published) {
        page.publishedRevision.routeIds.filter(id => !ids.includes(id)).forEach(id => ids.push(id));
      }
      return [...routeIds, ...ids];
    }, []);
  }
);

export const getRouteIdsAssignedToPagesOtherThanActivePage = createSelector(
  getPagesAsArray,
  getActivePageId,
  (pagesArray: Page[], activePageId) => {
    return pagesArray.reduce((routeIds, page: Page) => {
      // skip active page
      if (activePageId === page.id) {
        return routeIds;
      }
      const ids = [...page.workingRevision.routeIds];
      if (page.published) {
        page.publishedRevision.routeIds.filter(id => !ids.includes(id)).forEach(id => ids.push(id));
      }
      return [...routeIds, ...ids];
    }, []);
  }
);

// TREE VIEW
export const getPagesFilteredByName = createSelector(
  getPagesAsArray,
  getPageNameFilter,
  (pagesArray: Page[], nameFilter: string) => {
    if (!nameFilter) {
      return pagesArray;
    }
    const filterRegExp = new RegExp(regexEscape(nameFilter), 'i');
    return pagesArray
      .filter(t => filterRegExp.test(t.published ? t.publishedRevision.label : t.workingRevision.label));
  }
);

export const getPageSuggestionsByName = createSelector(
  getPagesFilteredByName,
  getPageNameFilter,
  (filteredPagesArray: Page[], nameFilter: string) => {
    if (!nameFilter) {
      return [];
    }
    const suggestionListMaxLength = 4;
    return filteredPagesArray
      .slice(0, suggestionListMaxLength)
      .map((t: Page) => t.published ? t.publishedRevision.label : t.workingRevision.label);
  }
);


export const getFilteredPagesTree = createSelector(
  getAppState,
  getPages,
  getPagesFilteredByName,
  getRoutes,
  (state, pagesMap: PagesMap, filteredPagesArray: Page[], routesMap) => {
    if (filteredPagesArray.length === 0) {
      return [];
    }
    const filteredPagesMap = filteredPagesArray
      .reduce((acc, page: Page) => {
        acc[page.id] = page;
        let parent = pagesMap[page.parentId];
        while (parent) {
          acc[parent.id] = parent;
          parent = pagesMap[parent.parentId];
        }
        return acc;
      }, {});

    const filteredPagesWithParents: Page[] = Object.values(filteredPagesMap);

    const pagesAsNormalizedTreeItems = filteredPagesWithParents
      .reduce((acc, t: Page) => {
        acc[t.id] = pageToProtoPageTreeItem(t, pagesMap, routesMap);
        return acc;
      }, {});

    const denormalizedTempalteItemsTree: PageTreeItem[] = Object.values(pagesAsNormalizedTreeItems)
      .filter((t: any) => !t.parentId)
      .map((rlt: any) => denormalizeNodeChildPages(rlt, pagesAsNormalizedTreeItems))
      .sort(sortByLabelAsc);

    return denormalizedTempalteItemsTree as PageTreeItem[];
  });

function pageToProtoPageTreeItem(page: Page, pagesMap: PagesMap, routesMap) {
  const children = Object.values(pagesMap)
    .filter((t: Page) => t.parentId === page.id)
    .map(t => t.id);

  const hasDescendants = children.length !== 0;
  let deleteTooltip = '';
  deleteTooltip += hasDescendants ? $localize`Cannot delete page that has descendants!` : '';
  deleteTooltip += page.published ? $localize`Cannot delete published page!` : '';
  const hasUnpublishedUpdates = page.published ? (page.workingRevision.updatedAt > page.publishedRevision.updatedAt) : false;
  const updatedAt = page.published && !hasUnpublishedUpdates ? page.publishedRevision.updatedAt : page.workingRevision.updatedAt;
  const relevantRevision = (page.publishedRevision || page.workingRevision);
  const attachedRoutes = getAttachedRoutesFromIds(relevantRevision.routeIds, routesMap);

  return {
    deleteTooltip,
    hasDescendants,
    type: relevantRevision.type,
    typeLabel: PageTypeLabels[relevantRevision.type],
    published: page.published,
    updatedAt,
    childrenIds: children,
    id: page.id,
    parentId: page.parentId,
    label: page.published ? page.publishedRevision.label : page.workingRevision.label,
    hasUnpublishedUpdates,
    attachedRoutes
  };
}

function getAttachedRoutesFromIds(routeIds: number[], routesMap) {
  if(!routeIds.length) {
    return [];
  }

  if(!Object.keys(routesMap).length) {
    return [];
  }

  const routes = routeIds.map((rid) => {
    const route = routesMap[rid];
    return {
      id: route.id,
      name: route.name,
    };
  });

  return routes;
}

function denormalizeNodeChildPages(node: PageTreeItem, nodesMap) {
  if (!node.childrenIds || node.childrenIds.length === 0) {
    return node;
  }
  node.children = node.childrenIds
    .map(nId => nodesMap[nId])
    .filter(cn => !!cn)
    .map(n => denormalizeNodeChildPages(n, nodesMap))
    .sort(sortByLabelAsc);
  return node;
}

export const getPageById = id => createSelector(getPages, pages => pages[id]);

export const getActivePagePublishedRevision = createSelector(getActivePage, page => page && page.publishedRevision);

export const getActivePageWorkingRevision = createSelector(getActivePage, page => page && page.workingRevision);
