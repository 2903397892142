import { Injectable } from '@angular/core';
import { ContentPanelsService } from '../../api/content-panels/content-panels.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  GET_CONTENT_PANELS,
  GetContentPanelsSuccessAction,
  CREATE_CONTENT_PANELS,
  CreateContentPanelsSuccessAction,
  ContentPanelsFailedAction,
  UPDATE_CONTENT_PANELS,
  UpdateContentPanelsSuccessAction,
  DELETE_CONTENT_PANELS,
  DeleteContentPanelsSuccessAction,
  CONTENT_PANELS_FAILED,
  REORDER_CONTENT_PANELS,
} from './content-panels.actions';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UnsafeAction } from '../unsafe-action.interface';
import { getContentPanels } from './content-panels.reducer';
import { AppState } from '../app-reducer';
import { take, mergeMap, tap, catchError, map } from 'rxjs/operators';

@Injectable()
export class ContentPanelsEffects {
  constructor(
    public snackBar: MatSnackBar,
    public router: Router,
    public contentPanelService: ContentPanelsService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}


  loadContentPanels$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_CONTENT_PANELS),
    mergeMap((action: UnsafeAction) => {
      return this.contentPanelService.getAllContentPanels().pipe(
        map((response) => new GetContentPanelsSuccessAction(response)),
        catchError((e) => of(new ContentPanelsFailedAction({ error: e, action })))
      );
    })
  ));


  createContentPanel$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_CONTENT_PANELS),
    mergeMap((action: UnsafeAction) => {
      return this.contentPanelService.createContentPanel(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Content panel created`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/site-builder/content-panels']);
        }),
        map((contentPanel) => new CreateContentPanelsSuccessAction(contentPanel)),
        catchError((err) => of(new ContentPanelsFailedAction(err)))
      );
    })
  ));


  updateContentPanel$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_CONTENT_PANELS),
    mergeMap((action: UnsafeAction) => {
      return this.contentPanelService.updateContentPanel(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`Content panel saved`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/site-builder/content-panels']);
        }),
        map((contentPanel) => new UpdateContentPanelsSuccessAction(contentPanel)),
        catchError((err) => of(new ContentPanelsFailedAction(err)))
      );
    })
  ));


  deleteContentPanel$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_CONTENT_PANELS),
    mergeMap((action: UnsafeAction) => {
      return this.contentPanelService.deleteContentPanel(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Content panel deleted`, $localize`Close`, { duration: 4000 })),
        map(() => new DeleteContentPanelsSuccessAction(action.payload)),
        catchError((err) => of(new ContentPanelsFailedAction(err)))
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CONTENT_PANELS_FAILED),

    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });


  updateContentPanelsPositions$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(REORDER_CONTENT_PANELS),
    mergeMap((action: UnsafeAction) => {
      return this.store.select(getContentPanels).pipe(
        take(1),
        mergeMap((updatedContentPanels) => {
          return this.contentPanelService.updateContentPanelsPosition(updatedContentPanels).pipe(
            tap(() =>
              this.snackBar.open($localize`Content panels positions updated`, $localize`Close`, { duration: 4000 })
            ),
            map((contentPanels) => new GetContentPanelsSuccessAction(contentPanels)),
            catchError((err) => of(new ContentPanelsFailedAction(err)))
          );
        })
      );
    })
  ));
}
