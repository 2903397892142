import { Action } from '@ngrx/store';
import { TaxonomyConfiguration } from './taxonomy-configuration.model';

export enum TaxonomyConfigurationActionTypes {
  LOAD = '[TaxonomyConfigurations] Load List',
  LOAD_SUCCESS = '[TaxonomyConfigurations] Load List Success',
  UPDATE = '[TaxonomyConfigurations] Update',
  UPDATE_SUCCESS = '[TaxonomyConfigurations] Update Success',
  FAILURE = '[TaxonomyConfigurations] Load Failure',
}

export class LoadTaxonomyConfigurationsAction implements Action {
  readonly type = TaxonomyConfigurationActionTypes.LOAD;
  constructor() {}
}

export class LoadTaxonomyConfigurationsSuccessAction implements Action {
  readonly type = TaxonomyConfigurationActionTypes.LOAD_SUCCESS;
  constructor(public payload: TaxonomyConfiguration) {}
}

export class UpdateTaxonomyConfigurationAction implements Action {
  readonly type = TaxonomyConfigurationActionTypes.UPDATE;
  constructor(public payload: TaxonomyConfiguration) {}
}

export class UpdateTaxonomyConfigurationSuccessAction implements Action {
  readonly type = TaxonomyConfigurationActionTypes.UPDATE_SUCCESS;
  constructor(public payload: TaxonomyConfiguration) {}
}

export class FailureTaxonomyConfigurationAction implements Action {
  readonly type = TaxonomyConfigurationActionTypes.FAILURE;
  constructor(public payload: { error: Error }) {}
}

export type TaxonomyConfigurationActions =
  | LoadTaxonomyConfigurationsAction
  | LoadTaxonomyConfigurationsSuccessAction
  | UpdateTaxonomyConfigurationAction
  | UpdateTaxonomyConfigurationSuccessAction
  | FailureTaxonomyConfigurationAction;
