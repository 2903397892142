export default function createTTLCache(ttlInMilliseconds: number) {
  if (!ttlInMilliseconds || ttlInMilliseconds < 1000) {
    throw new Error('Invalid TTL for this cache!');
  }

  // TODO implement cache purge mechanism to avoid memory leaks

  // this is a simple object where we store the cached value and the time it was recoded
  let cacheStore: { [key: string]: { createdAt: number; value: any } } = {};

  return {
    set: (key: string, value: any): void => {
      cacheStore[key] = {
        value,
        createdAt: Date.now(),
      };
    },
    get: (key: string): any => {
      const cachedItem = cacheStore[key];
      // cache miss
      if (!cachedItem) {
        return null;
      }

      // if the cache create time is older than ttl time, reset the cache and report a miss
      const cacheExpired = cachedItem.createdAt < Date.now() - ttlInMilliseconds;
      if (cacheExpired) {
        cacheStore[key] = null;
        return null;
      }

      return cachedItem.value;
    },
    clear: (): void => {
      cacheStore = {};
    },
  };
}
