import { Component, OnInit, ElementRef, AfterViewInit, Input, SimpleChanges, OnChanges, Renderer2, OnDestroy } from '@angular/core';
import IScroll from 'iscroll';
import { generateShortId } from '../shared-functions';

@Component({
  selector: 'gd-scroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss']
})
export class ScrollerComponent implements OnInit, AfterViewInit, OnDestroy {

  scroller: IScroll;
  scrollerID = null;
  scrollerEl = null;
  scrollingAreaEl = null;

  @Input() scrollConfig = {};

  defaultScrollConfig: any = {
    mouseWheel: true,
    disableMouse: true,
    disableTouch: true,
    disablePointer: true,
    interactiveScrollbars: true,
    scrollbars: 'custom',
    useTransition: true
  };

  mutationObserver: MutationObserver;

  refreshTimeout;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.scrollerID = generateShortId();
  }

  ngAfterViewInit() {
    this.scrollerEl = this.elRef.nativeElement.querySelector(`#${this.scrollerID}`);
    this.scrollingAreaEl = this.elRef.nativeElement.querySelector('.gd-scroller__scrolling-area');
    this.defaultScrollConfig = { ...this.defaultScrollConfig, ...this.scrollConfig };
    this.scroller = new IScroll(this.scrollerEl, this.defaultScrollConfig);
    this.initMutationObserver();
  }

  initMutationObserver() {
    const config = { attributes: false, subtree: true, childList: true };
    // Create an observer instance linked to the callback function
    this.mutationObserver = new MutationObserver(this.handleMutationChange.bind(this));
    // Start observing the target node for configured mutations
    this.mutationObserver.observe(this.scrollingAreaEl, config);
  }

  handleMutationChange(mutationList, observer) {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        this.refreshScroller();
      }
    }
  }

  updateScrollAreaHeight(height) {
    this.renderer.setStyle(this.scrollerEl, 'height', `${height}px`);
    this.refreshScroller();
  }

  updateScrollingAreaWidth(width) {
    this.renderer.setStyle(this.scrollingAreaEl, 'width', `${width}px`);
    this.refreshScroller();
  }

  refreshScroller() {
    setTimeout(() => this.scroller.refresh(), 0);
  }

  scrollToElement(element) {
    this.scroller.scrollToElement(element, 300, 0, 100, IScroll.utils.ease.quadratic);
  }

  scrollTo(x, y, time?, easing = IScroll.utils.ease.quadratic) {
    this.scroller.scrollTo(x, y, time, easing);
  }

  scrollBy(x, y, time?, easing = IScroll.utils.ease.quadratic) {
    this.scroller.scrollBy(x, y, time, easing);
  }

  ngOnDestroy() {
    this.mutationObserver.disconnect();
  }

}
