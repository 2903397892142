import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import * as mixpanel from 'mixpanel-browser';
import { combineLatest } from 'rxjs';
import { filter, startWith, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../store/app-reducer';
import { getActiveAccount, selectActiveUserDetails } from '../../store/auth/auth.reducer';

@Injectable({ providedIn: 'root' })
export class MixPanelService {
  private userData$ = combineLatest([
    this.store.select(selectActiveUserDetails).pipe(startWith({}), filter(activeUser => !!activeUser && !isEmpty(activeUser) && !!activeUser.profileData)),
    this.store.select(getActiveAccount).pipe(startWith({}), filter(activeAccount => !!activeAccount && !isEmpty(activeAccount))),
  ])

  constructor(
    private store: Store<AppState>,
  ) {}

  private _trackEvent(eventName: string, eventObject: { [key: string]: any }): void {
    if(environment.env === 'local') {
      return;
    }
    try {
      mixpanel.track(eventName, eventObject);
    } catch (e) {
      console.error('Failed to track event', e);
    }
  }

  private _setProfile(username, eventObject: { [key: string]: any }): void {
    if(environment.env === 'local') {
      return;
    }
    mixpanel.identify(username);
    mixpanel.people.set(eventObject);
  }

  public init(userToken: string): void {
    if(environment.env === 'local') {
      return;
    }

    mixpanel.init(userToken, {
      debug: environment.env !== 'prod' ? true : false,
    });
  }

  public setProfile() {
    if(environment.env === 'local') {
      return;
    }
    this.userData$.pipe(take(1)).subscribe(([activeUser, activeAccount]) => {
        let profileData = {};
        if(activeUser.username !== 'superadmin') {
           profileData = {
            roles: activeUser.profileData.roles.map(role => ({
                name: role.name,
                id: role.id,
              })
            ) || [],
            $email: activeUser.profileData.email || '',
            $first_name: activeUser.profileData.firstName || '',
            $last_name: activeUser.profileData.lastName || '',
          }
        } else {
          profileData = {
            roles: 'Admin',
            $email: '',
            $first_name: 'Superadmin',
            $last_name: '',
          }
        }

        this._setProfile(activeUser.username, {
          $distinct_id: activeUser.username,
          account_id: activeUser.accountId,
          account: activeAccount.name,
          user_id: activeUser.userId,
          username: activeUser.username,
          ...profileData,
          env: environment.env || 'qa',
          client: environment.client,
        });

    });
  }

  public addGroup(key: string, id: string) {
    if(environment.env === 'local') {
      return;
    }
    mixpanel.add_group(key, id);
  }

  public trackEvent(eventName, data) {
    if(environment.env === 'local') {
      return;
    }
    this.userData$.pipe(take(1)).subscribe(([activeUser, activeAccount]) => {
      this._trackEvent(eventName, {
        $distinct_id: activeUser.username,
        env: environment.env,
        client: environment.client,
        account_id: activeUser.accountId,
        account: activeAccount.name,
        user_id: activeUser.userId,
        username: activeUser.username,
        ...data,
      })
    })
  }
}
