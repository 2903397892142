import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from '../core/store/app-reducer';
import { getImageSizesArray, GetUserConfigurationAction } from '../core/store/images-configuration';

@Injectable()
export class ImageSizesResolver implements Resolve<any> {

  constructor(
    private store: Store<AppState>
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise(resolve => {
      this.store.select(getImageSizesArray)
        .pipe(take(1))
        .subscribe(sizes => {
          if (sizes.length === 0) {
            this.store.dispatch(new GetUserConfigurationAction());
            resolve(null);
          } else {
            resolve(sizes);
          }
        });
    });
  }
}
