import { Component, OnInit, Inject, OnDestroy, HostListener, DoCheck } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/app-reducer';
import { getFiles } from '../../core/store/files/files.reducer';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { FilesService } from '../../core/api/files/files.service';
import { UserPreferencesService } from '../../core/api/user-preferences/user-preferences.service';
import { ImagesService } from '../../core/api/images/images.service';
import { BidiService } from '../../core/i18n/bidi.service';

@Component({
  selector: 'gd-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  animations: [
    trigger('slide', [
      state('void', style({ transform: 'scale(1.02)' })),
      state('*', style({ transform: 'scale(1)' })),
      transition('void => *', [
        style({ transform: 'scale(1.02)' }),
        animate('150ms ease-in', style({ transform: 'scale(1)' }))])
      ]),
  ]
})

export class FilePreviewComponent implements OnInit, OnDestroy, DoCheck {
  fileUrl;
  fileDetails;
  contentType;
  previewFileSub: Subscription = new Subscription();
  loaded = false;
  loadedFiles = [];
  animationListener = false;
  indexOfSelectedFile;
  showNavButtons;
  theme = 'default';
  @HostListener('click', ['$event']) onclick(event) {
    this.handleClickEvents(event);
  }
  @HostListener('document:keyup', ['$event']) KeyUpEvent(event: KeyboardEvent) {
    this.handleKeyboardNavigation(event);
  }
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    public dialogRef: MatDialogRef<FilePreviewComponent>,
    private store: Store<AppState>,
    private domSanitizer: DomSanitizer,
    private filesService: FilesService,
    private userPreferenceService: UserPreferencesService,
    private imagesService: ImagesService,
    private bidiService: BidiService,
  ) {}

  ngDoCheck() {
    if (this.theme !== this.userPreferenceService.getUserPreference('theme')) {
      this.theme = this.userPreferenceService.getUserPreference('theme');
      this.ngOnInit;
    }
  }
  ngOnInit() {
    this.fileDetails = this.dialogInputData.data;
    this.showNavButtons = this.dialogInputData.config.showNavigationButtons;
    this.fileDetails.fileType = this.resolveType(this.fileDetails);
    this.fileDetails.thumbnail = this.resolveThumbnailImg(this.fileDetails);
    this.fileDetails.isSvgIcon = this.isCustomSvgIcon(this.resolveThumbnailImg(this.fileDetails));
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.imagesService.generateImageUrl(this.dialogInputData.data.key));
    this.toggleAnimation();
    const loadedFilesSub = this.store
      .select(getFiles)
      .pipe(map(file => file.filter(item => item.type === 'file')), map(files =>
        files.sort((a, b) => (a.meta_data.name.toLowerCase() > b.meta_data.name.toLowerCase() ? 1 : -1))
      ))
      .subscribe(files => {
        this.loadedFiles = files;
        this.indexOfSelectedFile =
          this.fileDetails && this.loadedFiles.findIndex(value => value.id === this.fileDetails.id);
      });
    this.previewFileSub.add(loadedFilesSub);
  }

  toggleAnimation() {
    this.animationListener = false;
    setTimeout(() => {
      this.animationListener = true;
    }, 0);
  }

  loadFileByIndex() {
    this.fileDetails = this.loadedFiles[this.indexOfSelectedFile];
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.imagesService.generateImageUrl(this.fileDetails.key));
    this.fileDetails.fileType = this.resolveType(this.fileDetails);
    this.fileDetails.thumbnail = this.resolveThumbnailImg(this.fileDetails);
    this.toggleAnimation();
  }

  loadNextFile() {
    const canLoadNext = this.indexOfSelectedFile < this.loadedFiles.length - 1 && this.showNavButtons;
    if (!canLoadNext) {
      return;
    }
    this.indexOfSelectedFile++;
    this.loadFileByIndex();
  }

  loadPreviousFile() {
    const canLoadPrevious = this.indexOfSelectedFile > 0 && this.showNavButtons;
    if (!canLoadPrevious) {
      return;
    }
    this.indexOfSelectedFile--;
    this.loadFileByIndex();
  }

  handleKeyboardNavigation(event) {
    if (!this.showNavButtons) {
      return;
    }
    if (event.keyCode === 39 && this.indexOfSelectedFile !== (this.loadedFiles.length - 1)) {
      this.loadNextFile();
    }
    if (event.keyCode === 37 && this.indexOfSelectedFile !== 0) {
      this.loadPreviousFile();
    }
    if (event.keyCode === 27) {
      this.closeDialog();
    }
  }

  handleClickEvents(event) {
    if (event.target) {
      // expected classes
      const classes = [
        'gd-files-preview__toolbar-col',
        'gd-file-preview__navigation-btn',
        'gd-files-preview-image',
        'gd-files-preview-document',
        'gd-files-preview-fail',
        'gd-files-preview__fail-button-icon',
        'mat-button-wrapper',
      ];

      const clickedElement = <HTMLElement>event.target;
      const className = clickedElement.className;
      // click is valid if clicked element contains any of expected classes
      const isValidClick = classes.some(c => className.includes(c));
      if (!isValidClick) {
        return this.closeDialog();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  resolveType(item) {
    const regex = /[^.]+$/g;
    const extension = item.filename ? item.filename.toLowerCase().match(regex)[0] : '';
    this.contentType = extension;
    switch (extension) {
      case 'pdf':
        return 'document';
      case 'svg':
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'image';
      default:
        return 'unsupportedType';
    }
  }

  resolveThumbnailImg(item) {
    const regex = /[^.]+$/g;
    const extension = item.filename ? item.filename.toLowerCase().match(regex)[0] : '';
    return this.filesService.resolveFileThumbnail(extension);
  }

  downloadFile() {
    const url = this.imagesService.generateImageUrl(this.fileDetails.key);
    fileSaver.saveAs(url, this.fileDetails.filename);
  }

  isCustomSvgIcon(icon) {
    return icon.startsWith('glideCustomIcon_') ? true : false;
  }

  returnFileSize(size) {
    return this.filesService.resolveFileSize(size);
  }

  ngOnDestroy(): void {
    this.previewFileSub.unsubscribe();
  }
}
