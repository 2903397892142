import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SSOService } from '../../api/sso/sso.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  SamlProvidersFailedAction,
  SSO_PROVIDERS_FAILED,
  GET_SAML_CONFIG,
  GetSamlConfigCompleteAction,
  UPDATE_SAML_CONFIG,
  UpdateSamlConfigCompleteAction,
  CREATE_SAML_CONFIG,
  CreateSamlConfigCompleteAction,
} from './sso.actions';
import { UnsafeAction } from '../unsafe-action.interface';

@Injectable()
export class SsoProvidersEffects {
  constructor(
    public snackBar: MatSnackBar,
    public router: Router,
    public ssoProvidersService: SSOService,
    private actions$: Actions
  ) {}

  loadSamlConfig$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_SAML_CONFIG),
    mergeMap(() => {
      return this.ssoProvidersService.getConfig().pipe(
        map((response) => new GetSamlConfigCompleteAction(response)),
        catchError((e) => of(new SamlProvidersFailedAction(e)))
      );
    })
  ));

  updateSamlConfig$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_SAML_CONFIG),
    mergeMap((action: UnsafeAction) => {
      return this.ssoProvidersService.updateSamlConfig(action.payload).pipe(
        map((response) => new UpdateSamlConfigCompleteAction(response)),
        tap(() => this.snackBar.open($localize`SSO Config saved`, $localize`Close`, { duration: 4000 }) ),
        catchError((e) => of(new SamlProvidersFailedAction(e)))
      );
    })
  ));

  createSamlConfig$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_SAML_CONFIG),
    mergeMap((action: UnsafeAction) => {
      return this.ssoProvidersService.createSamlConfig(action.payload).pipe(
        map((response) => new CreateSamlConfigCompleteAction(response)),
        tap(() => this.snackBar.open($localize`SSO Config saved`, $localize`Close`, { duration: 4000 }) ),
        catchError((e) => of(new SamlProvidersFailedAction(e)))
      );
    })
  ));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SSO_PROVIDERS_FAILED),
    tap((err: any) => {
      const errMessage = err?.payload?.message;
      if(errMessage && errMessage.match(/there is no protocol/i)) {
        const ssoNotConfiguredMessage = $localize`SSO is not configured for this account`;
        this.snackBar.open(ssoNotConfiguredMessage, $localize`Close`, { duration: 4000 });
        return;
      }
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ` + actionType, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });
}
