export const NewLocalizationModes = {
  CreateNew: 'createNew',
  CopyCustomData: 'copyCustomDataOnly',
  Copy: 'copy',
  TranslateWithCustomData: 'translateWithCustomData',
  TranslateWithoutCustomData: 'translateWithoutCustomData',
};

export const NewALocalizationModeLabels = {
  [NewLocalizationModes.CreateNew]: $localize`Create blank`,
  [NewLocalizationModes.Copy]: $localize`Copy all content exactly`,
  [NewLocalizationModes.CopyCustomData]: $localize`Copy Custom Data only`,
  [NewLocalizationModes.TranslateWithCustomData]: $localize`GAIA Translate content & copy Custom Data`,
  [NewLocalizationModes.TranslateWithoutCustomData]: $localize`GAIA Translate content & Custom Data blank`,
};
