import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable()
export class ThemeManager {

  private activeTheme = 'default';
  private state = new BehaviorSubject(this.activeTheme);

  constructor(private overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add(this.activeTheme);
  }

  setTheme(themeName) {
    this.overlayContainer.getContainerElement().classList.remove(this.activeTheme);
    this.activeTheme = themeName;
    this.overlayContainer.getContainerElement().classList.add(this.activeTheme);
    this.state.next(themeName);
  }

  getTheme(): Observable<string> {
    return this.state.asObservable();
  }

  getActiveTheme(): string {
    return this.activeTheme;
  }

}
