import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { environment } from '../../../environments/environment';

/**
 * dialog used to refresh token manually after automatic retries failed
 * called only if token is still valid but the refresh request fails
 * in this case open a modal requiring users to refresh the token manually
 * note: there's session extend dialog that is used when token is not valid anymore
 */
@Component({
  selector: 'gd-refresh-token-dialog',
  templateUrl: './refresh-token-dialog.component.html',
  styleUrls: ['./refresh-token-dialog.component.scss'],
})
export class RefreshTokenDialogComponent implements OnInit, OnDestroy {
  authRequestBody;
  makingRequest = false;

  glideUsersUrl: string = environment.glideUsers + environment.glideUsersVersion;
  private authRequestConfig = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  userData;
  refreshTokenFailed = false;
  passwordForm = new UntypedFormControl([''], [Validators.required])
  invalidPasswordRegex = /^(?=.*\bincorrect\b)(?=.*\bpassword\b).*$/gim;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<RefreshTokenDialogComponent>,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.authRequestBody = this.dialogInputData.authRequestBody;
  }

  async submit() {
    this.makingRequest = true;
    const reqEndpoint = this.refreshTokenFailed ? 'auth' : 'auth/refresh-token';
    const credentials = {
      username: this.dialogInputData.username,
      password: this.passwordForm.value || null,
    };

    const reqBody = this.refreshTokenFailed ? credentials : this.authRequestBody;

    this.http
      .post(this.glideUsersUrl + reqEndpoint, reqBody, this.authRequestConfig)
      .subscribe(
        (res) => {
          this.dialogRef.close(res);
        },
        (err) => {
          this.makingRequest = false;
          // failed to refresh session - refresh token expired
          if (!this.refreshTokenFailed) {
            this.refreshTokenFailed = true;
            return;
          }
          // incorrect password error
          if (err.error.message.match(this.invalidPasswordRegex)) {
            this.passwordForm.setErrors({ invalidPassword: true });
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.makingRequest = false;
  }

  exit() {
    this.dialogRef.close(null);
  }
}
