import { catchError, mergeMap, filter, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UsersService } from '../../api/users/users.service';
import { UnsafeAction } from '../unsafe-action.interface';

import {
  GET_ACCOUNTS,
  GetAccountsCompleteAction,
  CREATE_ACCOUNT,
  CreateAccountCompleteAction,
  DELETE_ACCOUNT,
  DeleteAccountCompleteAction,
  GET_ROLES,
  GetRolesCompleteAction,
  GET_PERMISSIONS,
  GetPermissionsCompleteAction,
  CREATE_ROLE,
  CreateRoleCompleteAction,
  DELETE_ROLE,
  DeleteRoleCompleteAction,
  GET_USERS,
  GetUsersCompleteAction,
  UPDATE_ACCOUNT,
  UpdateAccountCompleteAction,
  UPDATE_ROLE,
  UpdateRoleCompleteAction,
  CREATE_USER,
  CreateUserCompleteAction,
  UPDATE_USER,
  UpdateUserCompleteAction,
  UsersFailedAction,
  USERS_ACTION_FAILED,
  DEACTIVATE_USER,
  DeactivateUserCompleteAction,
  UPDATE_USER_PROFILE,
  UpdateUserProfileCompleteAction,
  GET_ROLE_TYPES,
  GetRoleTypesCompleteAction,
  GetUserCompleteAction,
  GET_USER,
  ResetUsersState,
} from './users.actions';
import { ACCOUNT_CHANGED, LoadActiveUserDetailsSuccessAction, LOAD_ACTIVE_USER_DETAILS } from '../auth/auth.actions';
import { AppState } from '../app-reducer';
import { UserPreferencesService } from '../../api/user-preferences/user-preferences.service';
import { AuthService } from '../../api';
import { get } from 'lodash-es';

@Injectable()
export class UsersEffects {

  getAccounts$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_ACCOUNTS)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.usersService.getAccounts().pipe(
        map((accounts) => {
          return new GetAccountsCompleteAction(accounts);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      )
    )
  ));

  createAccount$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_ACCOUNT)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.createAccount(action.payload).pipe(
        map((response) => {
          this.snackBar.open($localize`Account created`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/glide-users/accounts/', response.data.id]);
          return new CreateAccountCompleteAction(response.data);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  updateAccount$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_ACCOUNT)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.updateAccount(action.payload).pipe(
        map((response) => {
          // TODO: After succesfull request refresh account being edited
          this.snackBar.open($localize`Account settings saved`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['/glide-users/accounts/']);
          return new UpdateAccountCompleteAction();
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  deleteAccount$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_ACCOUNT)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.deleteAccount(action.payload).pipe(
        map((response) => {
          return new DeleteAccountCompleteAction({ message: response, accountId: action.payload });
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  getRoles$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_ROLES)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.getRoles().pipe(
        map((response) => {
          return new GetRolesCompleteAction(response);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  createRole$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_ROLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.createRole(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Role Created`, $localize`Close`, { duration: 3000 })),
        map((response) => {
          this.router.navigate(['/glide-users/roles/']);
          return new CreateRoleCompleteAction();
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  updateRole$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_ROLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.updateRole(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Role Updated`, $localize`Close`, { duration: 3000 })),
        map((response) => {
          this.router.navigate(['/glide-users/roles']);
          return new UpdateRoleCompleteAction();
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  getPermissions$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_PERMISSIONS)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.getPermissions().pipe(
        map((response) => {
          return new GetPermissionsCompleteAction(response);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  deleteRole$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_ROLE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.deleteRole(action.payload).pipe(
        map((response) => {
          return new DeleteRoleCompleteAction(action.payload);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .pipe(map(() => new ResetUsersState())));

  getUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_USERS)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.getUsers(action.payload).pipe(
        map((users) => {
          const ids = get(action.payload, 'ids', []);
          if(ids.length > users.data.length) {
            const apiResponseIds = users.data.map(user => user.id);
            const missingIds = ids.filter(id => apiResponseIds.indexOf(id) == -1)

            const deactivatedUsers = missingIds.map(id => ({ id, username: 'Deactivated User', firstName: $localize`Deactivated User`, lastName: '' }))
            return new GetUsersCompleteAction({ users, deactivatedUsers });
          }
          return new GetUsersCompleteAction({ users });
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  createUser$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(CREATE_USER)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.createUser(action.payload).pipe(
        tap(() => this.snackBar.open($localize`User Created`, $localize`Close`, { duration: 3000 })),
        map((response) => {
          this.router.navigate(['/glide-users/users/']);
          return new CreateUserCompleteAction(response.data);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  updateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_USER)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.updateUser(action.payload).pipe(
        tap(() => {
          this.snackBar.open($localize`User Updated`, $localize`Close`, { duration: 3000 });
          this.router.navigate(['/glide-users/users/']);
        }),
        map(({ data }) => {
          if (data.id === this.authService.getUserId()) {
            this.store.dispatch(new LoadActiveUserDetailsSuccessAction(data));
          }
          return new UpdateUserCompleteAction(data);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(USERS_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    }),
    // Note: stop effect propagaion
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  deactivateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DEACTIVATE_USER)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.deactivateUser(action.payload).pipe(
        tap(() => this.snackBar.open($localize`User Deactivated`, $localize`Close`, { duration: 3000 })),
        map((response: any) => {
          return new DeactivateUserCompleteAction(response.data);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  updateUserProfile$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_USER_PROFILE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.updateUserProfile(action.payload).pipe(
        tap(() => this.snackBar.open($localize`User Profile Updated`, $localize`Close`, { duration: 3000 })),
        map(({ data }) => {
          const previousUrlPathValue =
            this.userPreferenceService.getUserPreference('currentUrlPathValue');
          const urlPathValue = previousUrlPathValue ? previousUrlPathValue : '/dashboard';
          this.router.navigate([urlPathValue]);
          this.store.dispatch(new LoadActiveUserDetailsSuccessAction(data));
          return new UpdateUserProfileCompleteAction(data);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  // ensure only one user profile fetch is ever in flight
  private isUserProfileFetchInProgress = false;

  loadActiveUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LOAD_ACTIVE_USER_DETAILS),
    filter(() => !this.isUserProfileFetchInProgress),
    mergeMap((action: UnsafeAction) => {
      this.isUserProfileFetchInProgress = true;
      return this.usersService.getBasicUserProfile(this.authService.getUserId()).pipe(
        map((user) => new LoadActiveUserDetailsSuccessAction(user)),
        tap(() => (this.isUserProfileFetchInProgress = false))
      );
    })
  ));

  getRoleTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_ROLE_TYPES)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.usersService.getRoleTypes().pipe(
        map((roleTypes) => new GetRoleTypesCompleteAction(roleTypes)),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      )
    )
  ));

  getUser$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_USER)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.usersService.getUser(action.payload).pipe(
        map((user) => {
          return new GetUserCompleteAction(user);
        }),
        catchError((e) => of(new UsersFailedAction({ error: e, action })))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private router: Router,
    private store: Store<AppState>,
    public snackBar: MatSnackBar,
    private userPreferenceService: UserPreferencesService,
    private authService: AuthService
  ) {}
}
