export * from './article';
export * from './auth';
export * from './taxonomies';
export * from './articles-list';

export * from './utility-functions';

export function getUniqueArray(arr): any[] {
    return Array.from(new Set(arr));
}

export function valueAsArray(val): any {
    return Array.isArray(val) ? val : [val];
}
