import { debounceTime } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { EmbeddablesDataService } from '../../../shared/editors/embeddables-data.service';
import { EmbeddedType } from '../../../core/store/article/article-content.model';
import { BidiService } from '../../../core/i18n/bidi.service';

declare const moment: any;

@Component({
  selector: 'gd-embed-dialog',
  templateUrl: './embed-dialog.component.html',
  styleUrls: ['./embed-dialog.component.scss'],
})
export class EmbedYouTubeDialogComponent implements OnInit, OnDestroy {
  editingMode = false;
  validatorThrottleTimeout;

  get idControl() {
    return this.embeddableForm.get('id');
  }

  get nameControl() {
    return this.embeddableForm.get('name');
  }

  get descriptionControl() {
    return this.embeddableForm.get('description');
  }

  get autoplayControl() {
    return this.embeddableForm.get('autoplay');
  }

  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    name: [''],
    description: [''],
    autoplay: [false],
    duration: [''],
    durationMillis: [''],
    publishedAt: [''],
    thumbnails: [null],
  });

  blockId;
  patchedValue = false;
  selectedThumbnailImage: any = null;
  validYouTubeId = null;

  itemDetails = null;
  safeUrl = null;
  idSubscription: Subscription;

  dialogInputFormData: any = {
    id: '',
    name: '',
    description: '',
    autoplay: false
  };

  dir$ = this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<EmbedYouTubeDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private embeddablesDataService: EmbeddablesDataService,
    private bidiService: BidiService,
  ) {}

  ngOnInit(): void {
    this.editingMode = this.dialogInputData['editingMode'] ? true : false;
    this.blockId = this.dialogInputData.blockId;
    if (this.editingMode) {
      this.embeddableForm.patchValue(this.dialogInputData);
      this.dialogInputFormData = { ...this.embeddableForm.value };
      this.itemDetails = this.dialogInputData;
      this.safeUrl = this.getSafeUrlForEmbedType(
        this.dialogInputData.embedType,
        this.idControl.value
      );

      this.embeddablesDataService
        .getYouTubeVideoData({ part: 'id,snippet,contentDetails', id: this.idControl.value })
        .subscribe((videoData) => {
          if (videoData.items && videoData.items.length === 0) {
            return;
          }
          const validVideoData = videoData.items[0];
          this.dialogInputFormData = {
            id: validVideoData.id,
            name: validVideoData.snippet.title,
            description: validVideoData.snippet.description,
            autoplay: validVideoData.autoplay
          };
        });
    }

    this.idSubscription = this.idControl.valueChanges.pipe(debounceTime(400)).subscribe((data) => {
      const id = this.getYouTubeId(data);
      const validPatchedForm =
        this.validYouTubeId === this.getYouTubeId(this.idControl.value) && this.patchedValue;
      const emptyEmbedForm =
        !this.idControl.value && this.idControl.hasError('required') && !this.itemDetails;
      if (validPatchedForm || emptyEmbedForm) {
        return;
      }
      if (!id) {
        this.idControl.setErrors(null);
        this.idControl.updateValueAndValidity();
        this.itemDetails = null;
        this.safeUrl = null;
        this.validYouTubeId = null;
        this.patchValue();
        return;
      }
      this.embeddablesDataService
        .getYouTubeVideoData({ part: 'id,snippet,contentDetails', id: id })
        .subscribe((videoData) => {
          if (videoData.items && videoData.items.length === 0) {
            this.itemDetails = null;
            this.safeUrl = null;
            this.idControl.setErrors({ invalidVideoId: true });
            this.validYouTubeId = null;
            this.patchValue();
            return;
          }
          this.itemDetails = videoData.items[0];
          this.safeUrl = this.getSafeUrlForEmbedType(this.dialogInputData.embedType, id);
          this.idControl.setErrors(null);
          this.validYouTubeId = id;
          this.patchValue();
        });
    });
  }

  save() {
    const formModel = {
      id: this.idControl.value,
      name: this.nameControl.value,
      description: this.descriptionControl.value,
      autoplay: this.autoplayControl.value,
      duration: this.embeddableForm.get('duration').value,
      durationMillis: this.embeddableForm.get('durationMillis').value,
      publishedAt: this.embeddableForm.get('publishedAt').value,
      thumbnails: this.embeddableForm.get('thumbnails').value,
    };

    const nodeUpdateData = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel,
    };
    this.dialogRef.close(nodeUpdateData);
    return;
  }

  getSafeUrlForEmbedType(type, urlParam) {
    if (type === EmbeddedType.YouTube && urlParam) {
      const autoplay = this.autoplayControl.value ? 1 : 0;
      const videoURL = 'https://www.youtube-nocookie.com/embed/' + urlParam + '?autoplay=' + autoplay;
      return this.sanitizer.bypassSecurityTrustResourceUrl(videoURL);
    }
    return null;
  }

  ngOnDestroy() {
    if (this.idSubscription) {
      this.idSubscription.unsubscribe();
    }
  }

  patchValue() {
    if (this.itemDetails) {
      this.dialogInputFormData = {
        id: this.itemDetails.id,
        name: this.itemDetails.snippet.title,
        description: this.itemDetails.snippet.description,
        autoplay: this.itemDetails.autoplay,
        duration: this.itemDetails.contentDetails.duration,
        durationMillis: moment.duration(this.itemDetails.contentDetails.duration).asMilliseconds(),
        publishedAt: this.itemDetails.snippet.publishedAt,
        thumbnails: this.itemDetails.snippet.thumbnails,
      };

      this.embeddableForm.patchValue(this.dialogInputFormData);
      this.patchedValue = true;
      return;
    }

    this.embeddableForm.patchValue({
      ...this.idControl.value,
      name: '',
      description: '',
      src: null,
    });

    this.dialogInputFormData = { ...this.embeddableForm.value };
    this.selectedThumbnailImage = null;
    this.patchedValue = false;
  }

  getYouTubeId(url) {
    if (this.isYouTubeUrl(url)) {
      return this.extractYouTubeId(url);
    }
    return url;
  }

  isYouTubeUrl(url) {
    const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  }

  extractYouTubeId(url) {
    const urlObject = new URL(url);
    const params = new URLSearchParams(urlObject.search);
    return params.has('v') ? params.get('v') : '';
  }
}
