<mat-card class="gd-image-crop-edit" fxLayout="column">
  <div fxLayout="column">
    <div fxLayout="row"
         fxLayoutAlign="space-between"
         style="margin-bottom: 12px;">
      <div fxLayout="row"
           fxLayoutGap="10px">
        <img class="gd-image-crop-edit__image-preview"
             [style.height.px]="200 / (image.dimensions.width/image.dimensions.height)"
             [src]="(image.dataURL | sanitazeURL) || image.thumbnail">
        <div class="gd-image-crop-edit__image-details">
          <span i18n>Filename: {{ image.fileData.name }}</span>
          <span i18n>Content type: {{ image.fileData.type }}</span>
          <span i18n>Original image size: {{ image.dimensions.width }}x{{ image.dimensions.height }}</span>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutGap="10px"
           fxLayoutAlign="center end">
        <ng-container *ngIf="showCropPreviews">
          <button mat-button
                  mat-flat-button
                  color="accent"
                  (click)="discardCrop()">
            <span i18n>Discard</span>
          </button>
          <button mat-raised-button
                  color="primary"
                  (click)="saveCropChanges()"
                  i18n>
            Save
          </button>
        </ng-container>
        <div *ngIf="!showCropPreviews"
             class="gd-image-crop-edit__focal-point-info">
          <i class="far fa-dot-circle"></i>
          <span>Click to add focal point</span>
          <mat-icon matTooltip="Focal point gives you the ability to set the point of interest on the image."
                    matTooltipPosition="above"
                    i18n-matTooltip>info_outline</mat-icon>
        </div>
      </div>
    </div>
    <!-- Cropper canvas -->
    <div
      class="gd-image-crop-edit__cropper-canvas"
      fxLayout="column"
      fxLayoutAlign="space-between center"
      [style.display]="!image.cropped && !showCropPreviews ? 'flex' : 'none'"
    >
      <div class="gd-image-crop-edit__canvas-block">
        <gd-image-cropper #mainCropper [image]="image" [extraCropperOptions]="cropOptions">
        </gd-image-cropper>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="gd-image-crop-edit__crop-toolbar"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
          <div>
            <!-- <mat-slide-toggle
              (change)="handleShowPreviews($event)"
              [checked]="showPreviews"
              fxFlexAlign="end"
            >
              Show previews
            </mat-slide-toggle> -->
            <mat-slide-toggle [formControl]="cropByRatioControl"
                              (change)="handleCropByRatioToggleChange($event.checked)"
                              fxFlexAlign="end"
                              class="gd-image-crop-edit__toggle-crop-by-ratio">
              <span i18n>Crop by ratio</span>
              <mat-icon matTooltip="Crop by ratio applies the same crop to all renditions of that ratio and resize method. You can see any other renditions by extending that ratio section. If you want to apply a specific crop to only one rendition of a given ratio, turn this toggle OFF."
                        i18n-matTooltip>
                info_outline
              </mat-icon>
            </mat-slide-toggle>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
          <div>
            <button
              class="gd-image-crop-edit__toolbar-icon"
              mat-flat-button
              color="primary"
              (click)="handleZoomEvent(-zoomStep)"
            >
            <mat-icon fontSet="fal" fontIcon="fa-search-minus"></mat-icon>
            </button>
          </div>
          <div>
            <button
              class="gd-image-crop-edit__toolbar-icon"
              mat-flat-button
              color="primary"
              (click)="handleZoomEvent(zoomStep)"
            >
              <mat-icon fontSet="fal" fontIcon="fa-search-plus"></mat-icon>
            </button>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="flex-end"
          fxLayoutGap="8px"
          style="width: 150px;"
        >
          <!-- <button
            mat-button
            mat-flat-button
            color="primary"
            [disabled]="imageSmartCropped"
            (click)="getSmartcropArea()"
          >
            Smartcrop
          </button> -->
          <button mat-button
                  mat-flat-button
                  color="accent"
                  (click)="resetCropbox()"
                  [disabled]="!hasUnsavedChanges">
            <span i18n>Discard</span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="gd-image-crop-edit__crops-list"
      [class.collapsed]="!showPreviews"
      *ngIf="!showCropPreviews"
    >
      <div fxLayout="column"
           fxLayoutGap="30px"
           style="padding-top: 24px;">
        <!-- <h4 class="gd-image-crop-edit__crop-list-heading">Generated image sizes</h4> -->
        <div class="gd-image-crop-edit__rendition-group --collapsed"
             [style.height.px]="(item.renditionIds.length * 332) + 55"
             #rendition_group
             *ngFor="let item of renditionGroups$ | async; let i = index;">
          <h4 class="gd-image-crop-edit__rendition-group-title">
            <span>
              {{ getRenditionGroupTitle(item.group) }}
              <mat-icon class="gd-image-crop-edit__info-icon"
                        matTooltip="Scale to fit method keeps the original image proportions"
                        i18n-matTooltip
                        *ngIf="item.group.includes('fit')">
                info_outline
              </mat-icon>
            </span>
            <mat-icon fontSet="fal"
                      fontIcon="fa-angle-down"
                      class="gd-image-crop-edit__expand-icon"
                      (click)="handleRenditionGroupExpansion(i)"
                      *ngIf="item.renditionIds.length > 1"></mat-icon>
          </h4>
          <div class="gd-image-crop-edit__crop-item {{ renditionId }}" *ngFor="let renditionId of item.renditionIds">
            <gd-image-cropper #imageCroppers [image]="image" [imageSizeData]="image.cropsMap[renditionId]">
            </gd-image-cropper>

            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </div>

    <!-- Final previews after image is cropped -->
    <div class="gd-image-crop-edit__crop-previews-wrapper"
         *ngIf="showCropPreviews">
      <h4 fxLayout="row"
          fxLayoutGap="10px"
          style="margin: 0 0 20px">
        <span i18n>Generated image sizes</span>
        <mat-spinner diameter="23"
                     *ngIf="!cropPreviewsLayoutCreated"></mat-spinner>
      </h4>
      <div *ngFor="let item of renditionGroups$ | async; let i = index;">
        <h4 class="gd-image-crop-edit__crop-list-heading">
          {{ getRenditionGroupTitle(item.group) }}
          <mat-icon class="gd-image-crop-edit__info-icon"
                    matTooltip="Scale to fit method keeps the original image proportions"
                    i18n-matTooltip
                    *ngIf="item.group.includes('fit')">
            info_outline
          </mat-icon>
        </h4>
        <div class="gd-image-crop-edit__crop-previews">
          <ng-container *ngFor="let renditionId of item.renditionIds">
            <div class="gd-image-crop-edit__crop-preview">
              <ng-container *ngIf="cropPreviewsMap[renditionId] as preview">
                <div class="gd-image-crop-edit__crop-preview-overlay"
                     (click)="preview && handleCropReInit(preview.cropperData.key)">
                  <mat-icon>crop</mat-icon>
                  <div class="gd-image-crop-edit__crop-details">
                    <span i18n>Width: {{ preview.displayData.width }} Height: {{ preview.displayData.height }}</span>
                    <span i18n>Aspect ratio: {{ preview.displayData.formattedRatio }}</span>
                  </div>
                </div>
                <div class="gd-image-crop-edit__crop-preview-img">
                  <img [src]="preview.croppedImage | sanitazeURL" />
                </div>
                <div class="gd-image-crop-edit__crop-preview-label"
                     [style.max-width.px]="cropPreviewsMap[renditionId].width < 180 ? 180 : cropPreviewsMap[renditionId].width">
                  <span style="font-weight: bold;"> {{ preview.label }} </span>
                  <span style="font-size: 13px;"> ({{ preview.displayData.width }}x{{ preview.displayData.height }}) </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!cropPreviewsMap[renditionId] as preview">
                <div class="gd-image-crop-edit__crop-preview-img-placeholder"></div>
                <div class="gd-image-crop-edit__crop-preview-label-placeholder"></div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div fxLayout="row"
           fxFlex="100%"
           fxLayoutAlign="end"
           fxLayoutGap="10px">
        <button mat-button
                mat-flat-button
                color="accent"
                (click)="discardCrop()">
          <span i18n>Discard</span>
        </button>
        <button mat-raised-button
                color="primary"
                (click)="saveCropChanges()"
                i18n>
          Save
        </button>
      </div>
    </div>
  </div>
</mat-card>
