
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { of } from 'rxjs';

@Injectable()
export class ContentTagsService {
  constructor(
    private rest: RestService
  ) {}

  getContentTags({ name = '', pageSize = 20 }) {
    let requestPath = `content-tags?size=${pageSize}`;
    requestPath += name ? `&name=${encodeURIComponent(name)}` : '';
    return this.rest.get(requestPath).pipe(map(response => response.data));
  }

  getContentTag(id) {
    return this.rest.get(`content-tags/${id}`).pipe(map(response => response.data));
  }

  getContentTagsById(contentTagsIds) {
    if (contentTagsIds.length < 1) {
      return of([]);
    }

    const size = contentTagsIds.length <= 200 ? contentTagsIds.length : 200;

    return this.rest.get(`content-tags?ids=${contentTagsIds.join(',')}&size=${size}`).pipe(
                    map(response => response.data));
  }

  createContentTags(payload) {
    return this.rest.post('content-tags', payload).pipe(map(response => response.data));
  }

  updateContentTags({ id, data }) {
    return this.rest.put(`content-tags/${id}`, data).pipe(map(response => response.data));
  }

  deleteContentTags(id) {
    return this.rest.delete(`content-tags/${id}`);
  }

}
