<section class="gd-image-library"
         fxLayout="column">
  <div class="gd-image-library__header"
       fx-Flex="100%"
       fxLayoutAlign="center center">
    <img src="/assets/img/glide-logo.svg"
         class="gd-image-library__gpp-logo">
    <p class="gd-image-library__filters-col-header">
      <img src="../assets/img/getty-logo.svg"
           height="30px"
           *ngIf="provider === 'getty'">
    </p>
    <mat-icon fontSet="far"
              fontIcon="fa-times"
              class="gd-image-library__header-close-icon"
              (click)="close()"></mat-icon>
  </div>
  <div fxFlex="100%"
       class="gd-image-library__main-wrapper"
       fxLayoutGap="20px">
    <div fxFlex="41%"
         class="gd-image-library__col-filters"
         [formGroup]="filtersForm">

      <div #filters>
        <ng-container *ngFor="let filter of primaryFilters">
          <ng-container [ngTemplateOutlet]="filter_field"
                        [ngTemplateOutletContext]="{filter: filter}"></ng-container>
        </ng-container>

        <mat-expansion-panel class="gd-image-library__filters-panel"
                             (expandedChange)="setImagesGridMaxHeight()"
                             *ngIf="secondaryFilters.length">
          <mat-expansion-panel-header>
            <mat-panel-title i18n>
              Filters
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let filter of secondaryFilters">
            <ng-container [ngTemplateOutlet]="filter_field"
                          [ngTemplateOutletContext]="{filter: filter}"></ng-container>
          </ng-container>
        </mat-expansion-panel>
      </div>
      <div #images_grid
           class="gd-image-library__images-grid-wrapper gd-scroller-custom">
        <p class="gd-image-library__col-selection-header"
           i18n>Images</p>
        <div class="gd-image-library__images-grid">
          <div class="gd-image-library__images-grid-item"
               [class.gd-image-library__images-grid-item--previewed]="previewImage?.id === image.id"
               [class.gd-image-library__images-grid-item--selected]="isImageSelected(image.id)"
               *ngFor="let image of filteredImages">
            <img [src]="image.thumbnail"
                 (click)="preview(image)">
            <mat-icon *ngIf="!isImageSelected(image?.id)"
                      fontSet="fal"
                      matTooltip="Select Image"
                      i18n-matTooltip
                      (click)="addImage(image)"
                      fontIcon="fa-plus-circle"
                      class="gd-image-library__selected-image-indicator"></mat-icon>
            <mat-icon *ngIf="isImageSelected(image?.id)"
                      fontSet="fal"
                      fontIcon="fa-check-circle"
                      class="gd-image-library__selected-image-indicator"></mat-icon>
            <mat-icon fontSet="fal"
                      fontIcon="fa-eye"
                      class="gd-image-library__previewed-image-indicator"
                      (click)="preview(image)"></mat-icon>
          </div>
          <div *ngIf="!loading && !filteredImages.length"
               class="gd-image-library__no-images">No search results</div>
          <mat-spinner class="gd-image-library__loader"
                       *ngIf="loading"
                       color="primary"
                       diameter="35">
          </mat-spinner>
          <div class="gd-image-library__btn-load-more"
               *ngIf="canLoadMore()">
            <button mat-raised-button
                    (click)="loadMore()">
              <span i18n>Load More</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="41%"
         class="gd-image-library__col-preview">
      <div class="gd-image-library__col-preview-block-wrapper">
        <p class="gd-image-library__col-selection-header"
           i18n>Image preview</p>
        <div *ngIf="previewImage"
             class="gd-image-library__preview-warning-message-wrapper">
          <mat-icon fontSet="fas"
                    fontIcon="fa-exclamation-triangle">
          </mat-icon>
          <p class="gd-image-library__preview-warning-message"
             i18n>
            There may be charges associated with this image selection. Please check your organisation's Getty account
            status
            for details.
          </p>

        </div>
        <div #imagePreview
             class="gd-image-library__preview-wrapper"
             [style.background]="previewImage ? '#333' : '#fff'"
             [style.cursor]="previewImage ? 'pointer' : 'default'"
             (click)="previewImage && (!isImageSelected(previewImage.id) ? addImage(previewImage) : removeImage(previewImage))">
          <img *ngIf="previewImage"
               [src]="previewImage.preview">
          <mat-icon fontSet="fal"
                    [style.color]="isImageSelected(previewImage?.id) ? '#00950A' : '#FFF'"
                    *ngIf="previewImage"
                    [fontIcon]="isImageSelected(previewImage?.id) ? 'fa-check-circle' : 'fa-plus-circle'"></mat-icon>
          <div class="gd-image-library__no-preview"
               *ngIf="!previewImage">
            <img src="./../../../assets/img/img_icon_mono_grey.svg"
                 alt="Image placeholder"
                 height="42"
                 width="42">
            <span i18n>Select an image for preview</span>
          </div>
        </div>
      </div>
      <div class="gd-image-library__meta-wrapper gd-scroller-custom"
           *ngIf="previewImage"
           [style.height]="setMetaDataMaxHeight()">
        <div class="gd-image-library__meta-item"
             *ngFor="let item of previewImage.meta">
          <mat-form-field appearance="fill"
                          *ngIf="item.key !== 'credit'">
            <mat-label i18n>{{ item.key }}</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="1000"
                      [value]="item.value"
                      readonly></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill"
                          *ngIf="item.key === 'credit'">
            <mat-label i18n>{{ item.key }}</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let credit of item.value"
                        [selectable]="false"
                        [removable]="false">{{ credit }}
              </mat-chip>
              <input matInput
                     [matChipInputFor]="chipList"
                     readonly>
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div class="gd-image-library__no-meta"
             *ngIf="!previewImage.meta?.length"
             i18n>No meta details</div>
      </div>
    </div>
    <div fxFlex="18%"
         class="gd-image-library__col-selection">
      <div class="gd-image-library__selected-images gd-scroller-custom">
        <p class="gd-image-library__col-selection-header"
           i18n
           *ngIf="!multiUpload">Selected image</p>
        <p class="gd-image-library__col-selection-header"
           i18n
           *ngIf="multiUpload">Selected images<span class="gd-image-library__selected-images-counter"
                *ngIf="selectedImages.length > 0">{{selectedImages.length}}</span></p>
        <div class="gd-image-library__selected-images-wrapper gd-scroller-custom">
          <div class="gd-image-library__selected-image"
               [class.gd-image-library__selected-image--previewed]="previewImage?.id === image.id"
               *ngFor="let image of selectedImages"
               (click)="preview(image)">
            <img [src]="image.preview">
            <div class="gd-image-library__selected-image-actions">
              <span matTooltip="Remove Image"
                    i18n-matTooltip
                    (click)="removeImage(image)">
                <mat-icon fontSet="far"
                          fontIcon="fa-times"></mat-icon>
              </span>
            </div>
          </div>
          <div *ngIf="!selectedImages.length"
               class="gd-image-library__no-images">No selected images</div>
        </div>
      </div>
      <div class="gd-image-library__btn-import-wrapper">
        <button mat-raised-button
                *ngIf="!importing"
                (click)="clear()"
                [disabled]="!selectedImages.length"
                i18n>Discard All</button>
        <button color="primary"
                mat-raised-button
                *ngIf="!importing"
                [disabled]="!selectedImages.length"
                (click)="import()">{{ config.importBtnLabel }}</button>
        <mat-spinner class="gd-image-library__loader"
                     color="primary"
                     style="margin: auto"
                     *ngIf="importing"
                     diameter="35">
        </mat-spinner>
      </div>
    </div>
  </div>
</section>

<!-- Create filter field dynamically -->
<ng-template #filter_field
             let-filter="filter">
  <mat-form-field class="gd-image-library__filter-field"
                  appearance="outline"
                  *ngIf="filter.type === 'text'">
    <mat-label>{{ filter.label }}</mat-label>
    <input matInput
           autocomplete="off"
           [formControl]="getFilterControl(filter.key)">
  </mat-form-field>

  <mat-form-field class="gd-image-library__filter-field"
                  appearance="outline"
                  *ngIf="filter.type === 'select'">
    <mat-label>{{ filter.label }}</mat-label>
    <mat-select (selectionChange)="''"
                [formControl]="getFilterControl(filter.key)">
      <mat-option *ngFor="let option of filter.data"
                  [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
