import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AccessBundlesFailedAction, CreateAccessBundleSuccess, CREATE_ACCESS_BUNDLE, DeleteAccessBundleSuccessAction, DELETE_ACCESS_BUNDLE, GetAccessBundlesSuccessAction, GetAccessBundleSuccess, GET_ACCESS_BUNDLES, GET_ACCESS_BUNDLE, UpdateAccessBundle, UPDATE_ACCESS_BUNDLE, UpdateAccessBundleSuccess, ChangeAccessBundleRevisionSuccess, CHANGE_ACCESS_BUNDLE_REVISION, PUBLISH_ACCESS_BUNDLE, PublishAccessBundleSuccess, UNPUBLISH_ACCESS_BUNDLE, UnpublishAccessBundleSuccess, ACCESS_BUNDLES_ACTION_FAILED, ClearAccessBundlesStateAction } from './access-bundles.actions';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { mergeMap, catchError, map, withLatestFrom, tap, switchMap, filter } from 'rxjs/operators';
import { AppState } from '../app-reducer';
import { AccessBundlesService } from '../../api/access-bundles/access-bundles.service';
import { UnsafeAction } from '../unsafe-action.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AccessBundleRevision, ActiveAccessBundle, getAccessBundleRevisionMap } from './access-bundles.reducer';
import { ACCOUNT_CHANGED } from '../auth/auth.actions';

@Injectable()
export class AccessBundlesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private accessBundleService: AccessBundlesService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }


  getAccessBundles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACCESS_BUNDLES),
    mergeMap((action: any) => {
      return this.accessBundleService.getAccessBundlesList(action.payload).pipe(
        map((response: ActiveAccessBundle) => new GetAccessBundlesSuccessAction(response)),
        catchError((e) => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));


  getActiveAccessBundle$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_ACCESS_BUNDLE),
    mergeMap((action: UnsafeAction) => {
      return this.accessBundleService.getAccessBundle(action.payload).pipe(
        map((response: ActiveAccessBundle) => new GetAccessBundleSuccess(response)),
        catchError((e) => {
          this.snackBar.open($localize`Invalid Access Bundle Id`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['verify/access-bundles']);
          return of(new AccessBundlesFailedAction(e));
        })
      );
    })
  ));



  createAccessBundle$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_ACCESS_BUNDLE),
    mergeMap((action: UnsafeAction) => {
      return this.accessBundleService.createAccessBundle(action.payload).pipe(
        tap((accessBundle: ActiveAccessBundle) => {
          this.router.navigate(['verify/access-bundles/', accessBundle.id]);
          this.snackBar.open($localize`Access Bundle created`, $localize`Close`, { duration: 4000 });
        }),
        map((accessBundle) => new CreateAccessBundleSuccess(accessBundle)),
        catchError((e) => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));


  updateAccessBundle$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_ACCESS_BUNDLE),
    mergeMap((action: UnsafeAction) => {
      return this.accessBundleService.updateAccessBundle(action.payload).pipe(
        map((accessBundle: ActiveAccessBundle) => new UpdateAccessBundleSuccess(accessBundle)),
        tap(() => this.snackBar.open($localize`Access Bundle updated`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));


  deleteAccessBundle$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_ACCESS_BUNDLE),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.accessBundleService.deleteAccessBundle(id).pipe(
        map(() => new DeleteAccessBundleSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Access bundle deleted.`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));


  changeRevision$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_ACCESS_BUNDLE_REVISION),
    withLatestFrom(this.store.select(getAccessBundleRevisionMap)),
    mergeMap(([action, revisionMap]: [UnsafeAction, any]) => {
      const id = action.payload;
      const observer = revisionMap[id]
        ? of({ ...revisionMap[id] })
        : this.accessBundleService.getAccessBundleRevision(id);
      return observer.pipe(
        map(revision => new ChangeAccessBundleRevisionSuccess(revision)),
        tap(() => this.snackBar.open($localize`Revision Changed`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));


  publish$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PUBLISH_ACCESS_BUNDLE),
    mergeMap((action: UnsafeAction) => {
      return this.accessBundleService.publishAccessBundle(action.payload).pipe(
        map((accessBundle: ActiveAccessBundle) => ({ ...accessBundle, workingRevision: { id: action.payload.revisionId } })),
        catchError((e) => of(e))
      );
    }),
    mergeMap((accessBundle: any) => {
      if (accessBundle.status === 'ERROR') {
        return of(new AccessBundlesFailedAction(accessBundle));
      }
      return this.accessBundleService.getAccessBundleRevision(accessBundle.workingRevision.id).pipe(
        map((workingRevision: AccessBundleRevision) => new PublishAccessBundleSuccess({ ...accessBundle, workingRevision })),
        tap(() => this.snackBar.open($localize`Access Bundle published`, $localize`Close`, { duration: 4000 })),
        catchError(e => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));


  unpublish$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UNPUBLISH_ACCESS_BUNDLE),
    mergeMap((action: UnsafeAction) => this.accessBundleService.unpublishAccessBundle(action.payload.id).pipe(map(() => action.payload))),
    mergeMap(data => {
      return this.accessBundleService.getAccessBundle(data.id)
        .pipe(map(accessBundle => ({ ...accessBundle, workingRevision: { id: data.revisionId } })));
    }),
    mergeMap(accessBundle => {
      return this.accessBundleService.getAccessBundleRevision(accessBundle.workingRevision.id).pipe(
        map((workingRevision: AccessBundleRevision) => new UnpublishAccessBundleSuccess({ ...accessBundle, workingRevision })),
        tap(() => this.snackBar.open($localize`Access Bundle unpublished`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new AccessBundlesFailedAction(e)))
      );
    })
  ));

  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .pipe(map(() => new ClearAccessBundlesStateAction())));

  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ACCESS_BUNDLES_ACTION_FAILED),
    tap((err: any) => this.snackBar.open($localize`Action failed: ` + (err.payload.message || ''), $localize`Close`, { duration: 4000 }))
  ), { dispatch: false });
}
