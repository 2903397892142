import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CollectionsService } from '../../api/collections/collections.service';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  GET_COLLECTIONS,
  GetCollectionsSuccessAction,
  CollectionFailedAction,
  DELETE_COLLECTION,
  DeleteCollectionSuccessAction,
  COLLECTION_ACTION_FAILED,
  COPY_COLLECTION_URL,
  CopyCollectionUrlSuccessAction,
} from './collection-list.actions';
import { UnsafeAction } from '../unsafe-action.interface';

@Injectable()
export class CollectionListEffects {
  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private collectionService: CollectionsService
  ) {}


  loadCollections$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_COLLECTIONS),
    mergeMap((action: UnsafeAction) => {
      return this.collectionService.getCollections(action.payload).pipe(
        map((editions) => new GetCollectionsSuccessAction(editions)),
        catchError((e) => of(new CollectionFailedAction(e)))
      );
    })
  ));


  deleteCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DELETE_COLLECTION),
    mergeMap((action: UnsafeAction) => {
      const id = action.payload;
      return this.collectionService.deleteCollection(id).pipe(
        map(() => new DeleteCollectionSuccessAction(id)),
        tap(() => this.snackBar.open($localize`Collection deleted`, $localize`Close`, { duration: 4000 })),
        catchError((e) => of(new CollectionFailedAction(e)))
      );
    })
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(COLLECTION_ACTION_FAILED),
    tap((err: any) => {
      const message = (err && err.payload && err.payload.message) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${message}`, $localize`Close`, { duration: 4000 });
    })
  ), { dispatch: false });


  copyCollectionUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(COPY_COLLECTION_URL), mergeMap((action: UnsafeAction) => {
    return this.collectionService.getCollectionUrlPreview({...action.payload}).pipe(
         map((collectionUrl) => {
           if(!collectionUrl) {
              this.snackBar.open($localize`This link is unavailable. Please contact the account administrator to help.`, $localize`Close`, { duration: 4000 });
           } else {
              navigator.clipboard.writeText(collectionUrl);
              this.snackBar.open($localize`Copied to clipboard`, $localize`Close`, { duration: 4000 });
           }
           return new CopyCollectionUrlSuccessAction();
         }),
         catchError((e) => {
            const err = {
              message: $localize`Collection path cannot be generated.`
            };
            return of(new CollectionFailedAction(err));
          })
     );
  })));
}
