import { UnsafeAction } from '../unsafe-action.interface';
import shortid from 'shortid';

import {
  GET_IMAGE_GENERATION_SETTINGS,
  GET_IMAGE_GENERATION_SETTINGS_COMPLETE,
  GET_USER_CONFIGURATION,
  GET_USER_CONFIGURATION_COMPLETE,
  UPDATE_IMAGE_GENERATION_SETTINGS,
  UPDATE_IMAGE_GENERATION_SETTINGS_COMPLETE,
  UPDATE_USER_CONFIGURATION
} from './images-configuration.actions';
import { createSelector } from '@ngrx/store';
import { ACCOUNT_CHANGED } from '../auth/auth.actions';
import { getUniqueArray } from '..';
import { commonRatios, getUserFriendlyRatio } from '../../api';
import { AppState } from '../app-reducer';

export interface ImagesConfigurationState {
  loading: boolean;
  loaded: boolean;
  imageSizes: Array<any>;
  genAiConfiguration?: any;
}

const initialState: ImagesConfigurationState = {
  loading: false,
  loaded: false,
  imageSizes: [],
  genAiConfiguration: {}
};


export function imagesConfigurationReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {

    case GET_USER_CONFIGURATION: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case GET_USER_CONFIGURATION_COMPLETE: {
      const accountSettings = action.payload;
      let imagesSizesMap = {};

      if (accountSettings.settings && accountSettings.settings.image_sizes) {
        imagesSizesMap = accountSettings.settings.image_sizes.reduce((acc, size, i) => {
          return {
            ...acc,
            [size.key]: {
              ...size,
              index: i + 1,
              additionalFormats: size.additionalFormats?.length ? size.additionalFormats : [],
              watermark: size.watermark || { enabled: false }
            }
          };
        }, {});
      }

      return {
        ...state,
        imageSizes: imagesSizesMap,
        loading: false,
        loaded: true
      };
    }

    case UPDATE_USER_CONFIGURATION: {
      const imagesSizesMap = action.payload.image_sizes.reduce((acc, size) => {
        return {
          ...acc,
          [size.key]: size
        };
      }, {});

      const mandatoryImageSizes = Object.values(state.imageSizes)
        .filter((imageSize: any) => imageSize.mandatory)
        .reduce((acc, val) => {
          return { ...acc, [val.key]: val };
        }, {});

      return {
        ...state,
        imageSizes: { ...imagesSizesMap, ...mandatoryImageSizes },
        loading: true,
        loaded: false
      };
    }

    case ACCOUNT_CHANGED: {
      return { ...initialState };
    }

    case GET_IMAGE_GENERATION_SETTINGS:
    case UPDATE_IMAGE_GENERATION_SETTINGS: {
      return { ...state, loading: true };
    }

    case GET_IMAGE_GENERATION_SETTINGS_COMPLETE:
    case UPDATE_IMAGE_GENERATION_SETTINGS_COMPLETE: {
      return { ...state, loading: false, genAiConfiguration: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getImagesConfigurationState = createSelector(
  (state: AppState) => state.imagesConfiguration,
  (imagesConfiguration): ImagesConfigurationState => imagesConfiguration
);

export const getImageSizesArray = (state) => Object.values(state.imagesConfiguration.imageSizes)
  .map((size: any) => ({
    ...size,
    aspectRatio: (size.width / size.height).toFixed(2)
  }));

export const getImageSizesArrayState = createSelector(getImageSizesArray, (sizes) => sizes);

export const getRenditionsGroupedByRatio = createSelector(getImageSizesArray, (renditions) => {
  const sortedRenditions = renditions
    .filter(r => r.cropAllowed)
    .map(r => ({ ...r, group: `${getUserFriendlyRatio(r.width, r.height)}-${r.resize_method}`.replace('*', '') }))
    .sort((a, b) => +a.height > +b.height ? -1 : 1)
    .sort((a, b) => +a.width > +b.width ? -1 : 1);

  return getUniqueArray(sortedRenditions.map(r => r.group))
    .reduce((acc: any, group: string) => {
    const renditionIds = sortedRenditions.filter(r => r.group === group).map(r => r.renditionId);
    acc.push({ group, renditionIds });
    return acc;
  }, [])
  .sort((a, b) => a.group > b.group ? -1 : 1)
  .sort((a, b) => {
    const g1 = a.group.split('-')[0];
    const g2 = b.group.split('-')[0];
    return +(!!commonRatios[g1]) > +(!!commonRatios[g2]) ? -1 : 1;
  });
});

export const isGenerateImageActive = createSelector(getImagesConfigurationState, (state) => state.genAiConfiguration?.enabled || false);

export const getImageGenAiConfiguration = createSelector(getImagesConfigurationState, (state) => state.genAiConfiguration);
