import { Action } from '@ngrx/store';
import { LiveReportType } from './live-report-types.model';

export enum LiveReportTypeActionTypes {
  LOAD = '[LiveReportTypes] Load List',
  LOAD_SUCCESS = '[LiveReportTypes] Load List Success',
  UPDATE = '[LiveReportTypes] Update',
  UPDATE_SUCCESS = '[LiveReportTypes] Update Success',
  FAILURE = '[LiveReportTypes] Load Failure',
}

export class LoadLiveReportTypesAction implements Action {
  readonly type = LiveReportTypeActionTypes.LOAD;
  constructor() {}
}

export class LoadLiveReportTypesSuccessAction implements Action {
  readonly type = LiveReportTypeActionTypes.LOAD_SUCCESS;
  constructor(public payload: LiveReportType) {}
}

export class UpdateLiveReportTypeAction implements Action {
  readonly type = LiveReportTypeActionTypes.UPDATE;
  constructor(public payload: LiveReportType) {}
}

export class UpdateLiveReportTypeSuccessAction implements Action {
  readonly type = LiveReportTypeActionTypes.UPDATE_SUCCESS;
  constructor(public payload: LiveReportType) {}
}

export class FailureLiveReportTypeAction implements Action {
  readonly type = LiveReportTypeActionTypes.FAILURE;
  constructor(public payload: { error: Error }) {}
}

export type LiveReportTypeActions =
  | LoadLiveReportTypesAction
  | LoadLiveReportTypesSuccessAction
  | UpdateLiveReportTypeAction
  | UpdateLiveReportTypeSuccessAction
  | FailureLiveReportTypeAction;
