import { UnsafeAction } from './../unsafe-action.interface';
import { mergeMap, map, tap, catchError } from 'rxjs/operators';
import { TaxonomyConfigurationService } from '../../api/taxonomy-configuration/taxonomy-configuration.service';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  UpdateTaxonomyConfigurationAction,
  UpdateTaxonomyConfigurationSuccessAction,
} from './taxonomy-configuration.actions';
import { TaxonomyConfiguration } from './taxonomy-configuration.model';
import {
  TaxonomyConfigurationActionTypes,
  FailureTaxonomyConfigurationAction,
  LoadTaxonomyConfigurationsSuccessAction,
} from './taxonomy-configuration.actions';

@Injectable()
export class TaxonomyConfigurationsEffects {

  loadTaxonomyConfigurations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaxonomyConfigurationActionTypes.LOAD),
    mergeMap(
      () =>
        this.taxonomyConfigurationService.get().pipe(
          map((configuration: any) => new LoadTaxonomyConfigurationsSuccessAction(configuration)),
          catchError((e) => of(new FailureTaxonomyConfigurationAction({ error: e })))
        ) as Observable<UnsafeAction>
    )
  ));


  updateTaxonomyConfiguration$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaxonomyConfigurationActionTypes.UPDATE),
    mergeMap((action: UpdateTaxonomyConfigurationAction) => {
      return this.taxonomyConfigurationService.update(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Taxonomy Configuration saved`, $localize`Close`, { duration: 2000 })),
        map(
          (newConfiguration: TaxonomyConfiguration) =>
            new UpdateTaxonomyConfigurationSuccessAction(newConfiguration)
        ),
        catchError((e) => of(new FailureTaxonomyConfigurationAction({ error: e })))
      ) as Observable<UnsafeAction>;
    })
  ));

  constructor(
    private actions$: Actions,
    private taxonomyConfigurationService: TaxonomyConfigurationService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}
}
