import { UnsafeAction } from '../unsafe-action.interface';

export const GET_AUTHORS = '[Authors] Get Authors';
export class GetAuthorsAction implements UnsafeAction {
  readonly type = GET_AUTHORS;
  constructor(public payload: any = {}) { }
}

export const GET_AUTHORS_SUCCESS = '[Authors] Get Authors success';
export class GetAuthorsSuccessAction implements UnsafeAction {
  readonly type = GET_AUTHORS_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_AUTHOR = '[Author] Create Author';
export class CreateAuthorAction implements UnsafeAction {
  readonly type = CREATE_AUTHOR;
  constructor(public payload: any) { }
}

export const CREATE_AUTHOR_SUCCESS = '[Author] Create Author success';
export class CreateAuthorSuccessAction implements UnsafeAction {
  readonly type = CREATE_AUTHOR_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_AUTHOR = '[Author] Update Author';
export class UpdateAuthorAction implements UnsafeAction {
  readonly type = UPDATE_AUTHOR;
  constructor(public payload: any) { }
}

export const UPDATE_AUTHOR_SUCCESS = '[Author] Update Author success';
export class UpdateAuthorSuccessAction implements UnsafeAction {
  readonly type = UPDATE_AUTHOR_SUCCESS;
  constructor(public payload: any) { }
}

export const DELETE_AUTHOR = '[Author] Delete Author';
export class DeleteAuthorAction implements UnsafeAction {
  readonly type = DELETE_AUTHOR;
  constructor(public payload: number) { }
}

export const DELETE_AUTHOR_SUCCESS = '[Author] Delete Author success';
export class DeleteAuthorSuccessAction implements UnsafeAction {
  readonly type = DELETE_AUTHOR_SUCCESS;
  constructor(public payload: number) { }
}

export const SET_AUTHORS_PAGE_VIEW_OPTIONS = '[Author] Set page view options';
export class SetAuthorsPageViewOptionsAction implements UnsafeAction {
  readonly type = SET_AUTHORS_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const SET_AUTHOR_LOADING_FLAG = '[Author] Set author loading flag';
export class SetAuthorLoadingFlagAction implements UnsafeAction {
  readonly type = SET_AUTHOR_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const AUTHOR_ACTION_FAILED = '[Author] Generic failure action';
export class AuthorFailedAction implements UnsafeAction {
  readonly type = AUTHOR_ACTION_FAILED;
  constructor(public payload: any) { }
}
