import { CollectionListState } from './collection-list.model';
import { UnsafeAction } from '../unsafe-action.interface';
import { ACCOUNT_CHANGED } from '../auth';
import {
  GET_COLLECTIONS, GET_COLLECTIONS_SUCCESS, DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS, SET_PAGE_VIEW_OPTIONS, COPY_COLLECTION_URL, COPY_COLLECTION_URL_SUCCESS
} from '../collection-list/collection-list.actions';
import { createSelector } from '@ngrx/store';
import { defaultPageSize } from '../constants/default-pagination.constants';

const collectionListInitialState: CollectionListState = {
  loaded: false,
  loading: false,
  error: null,
  collectionView: {
    pageIndex: 0,
    total: 0,
    pageSize: defaultPageSize
  },
  collections: []
};

export function collectionListReducer(state: CollectionListState = collectionListInitialState, action: UnsafeAction) {
  switch (action.type) {
    case ACCOUNT_CHANGED: {
      return { ...collectionListInitialState };
    }

    case GET_COLLECTIONS: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_COLLECTIONS_SUCCESS: {
      const collections = action.payload.data;
      const pageView = action.payload.meta.page;

      const newCollectionView = {
        total : pageView.total,
        pageIndex: pageView.number,
        pageSize: pageView.size
      };

      return {
        loaded: true,
        loading: false,
        error: null,
        collections,
        collectionView: { ...newCollectionView }
      };
    }

    case DELETE_COLLECTION: {
      return { ...state, loading: true };
    }

    case DELETE_COLLECTION_SUCCESS: {
      const deletedId = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        collections: state.collections.filter(collection => collection.id !== deletedId),
        collectionView: {
          ...state.collectionView,
          total: state.collectionView.total - 1
        }
      };
    }


    case SET_PAGE_VIEW_OPTIONS: {
      const { pageIndex, pageSize } = action.payload;
      return {
        ...state,
        collectionView: {
          ...state.collectionView,
          pageIndex: pageIndex || 0,
          pageSize: pageSize || state.collectionView.pageSize
        }
      };
    }

    case COPY_COLLECTION_URL: {
      return {
        ...state,
        loading: true,
      }
    }

    case COPY_COLLECTION_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    default: {
      return state;
    }
  }
}

export const getCollectionListState = (state) => state.collectionList;

export const getCollections = createSelector(getCollectionListState, data => data.collections);

export const getCollectionLoading = createSelector(getCollectionListState, data => data.loading);

export const getCollectionLoaded = createSelector(getCollectionListState, data => data.loaded);

export const getCollectionPageView = createSelector(getCollectionListState, state => state.collectionView);
