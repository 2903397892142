<mat-dialog-content class="gd-icon-picker-modal"
                    [dir]="dir$ | async">
  <div class="gd-icon-picker-modal__header-label">
    <h2 class="mat-h2"
        i18n>Choose an icon</h2>
  </div>
  <div class="gd-icon-picker-modal__name-filter-wrapper">
    <mat-form-field class="gd-icon-picker-modal__name-filter-field">
      <mat-label i18n="Search icon by name">Search by name</mat-label>
      <input matInput
             type="text"
             autocomplete="off"
             i18n-placeholder
             [formControl]="searchFormControl"
             [value]="searchFormControl?.value">
      <mat-hint class="gd-icon-picker-modal__name-filter-hint"
                i18n>Search icon (min. 3 characters)</mat-hint>
    </mat-form-field>
  </div>
  <mat-tab-group class="gd-icon-picker-modal__category"
                 (selectedIndex)="selectedIndex"
                 (selectedTabChange)="handleTabChanged($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="gd-icon-picker-modal__category-tab">
          <mat-icon matSuffix
                    fontSet="fab"
                    fontIcon="fa-font-awesome-flag"></mat-icon>
          <span i18n="Font awesome library of icons">Font Awesome</span>
        </div>
      </ng-template>
      <mat-dialog-content class="gd-icon-picker-modal__tab-content">
        <div class="gd-icon-picker-modal__icon-grid">
          <div class="gd-icon-picker-modal__icon-grid-item"
               *ngFor="let icon of showedIconsList"
               (click)="selectIcon(icon)"
               [class.--selected]="icon?.isSelected">
            <div class="gd-icon-picker-modal__icon-wrapper">
              <mat-icon matSuffix
                        [fontSet]="icon.name.split(' ')[0]"
                        [fontIcon]="icon.name.split(' ')[1]"></mat-icon>
              <span class="gd-icon-picker-modal__icon-name">{{icon.name.split(' ')[1].slice(3, icon.name.split(' ')[1].length)}}</span>
            </div>
            <mat-icon *ngIf="icon.isSelected"
                      class="gd-icon-picker-modal__checked-icon">
              check_circle
            </mat-icon>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions [dir]="dir$ | async"
                          class="gd-icon-picker-modal__actions">
        <div class="gd-icon-picker-modal__list-pagination">
          <mat-paginator [pageIndex]="paginator.pageIndex"
                         [pageSize]="paginator.pageSize"
                         [length]="paginator.total"
                         [pageSizeOptions]="paginator.pageSizeOptions"
                         (page)="pageChanged($event)">
          </mat-paginator>
        </div>
        <div>
          <button mat-button
                  mat-raised-button
                  color="secondary"
                  i18n
                  [mat-dialog-close]>
            Cancel
          </button>
          <button mat-button
                  mat-raised-button
                  color="primary"
                  i18n
                  [disabled]="!selectedIcon"
                  (click)="confirmSelection()">
            Select
          </button>
        </div>
      </mat-dialog-actions>
    </mat-tab>

    <!--THIS WILL BE USED FOR CUSTOM ICONS-->
    <mat-tab *ngIf="false">
      <ng-template mat-tab-label>
        <div>
          <mat-icon matSuffix
                    fontSet="far"
                    fontIcon="fa-wrench"></mat-icon>
          <span i18n="Custom library of icons">Custom Icons</span>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
