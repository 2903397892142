import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent {
  @Input() selectedImageIndex = 0;
  @Input() images = [];
  @Input() itemType = 'IMAGE';
  @Output() changeIndex: EventEmitter<any> = new EventEmitter();

  @HostListener('wheel', ['$event'])
  onWheelScroll(event: WheelEvent) {
    document.getElementById('image-slider').scrollLeft += event.deltaY;
  }

  constructor() {}

  slideLeft() {
    document.getElementById('image-slider').scrollLeft -= 100;
  }

  slideRight() {
    document.getElementById('image-slider').scrollLeft += 100;
  }
}
