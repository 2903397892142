import { ContentTag } from './content-tags.model';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  GET_CONTENT_TAGS, GET_CONTENT_TAGS_SUCCESS, CREATE_CONTENT_TAG,
  UPDATE_CONTENT_TAG, DELETE_CONTENT_TAG, CREATE_CONTENT_TAG_SUCCESS,
  UPDATE_CONTENT_TAG_SUCCESS, DELETE_CONTENT_TAG_SUCCESS
} from './content-tags.actions';
import { createSelector } from '@ngrx/store';

export interface ContentTagsState {
  loaded: boolean;
  loading: boolean;
  error: string;
  items: ContentTag[];
}

const initialState: ContentTagsState = {
  loaded: false,
  loading: false,
  error: null,
  items: []
};

export function contentTypesReducer(state = initialState, action: UnsafeAction) {
  switch (action.type) {

    case GET_CONTENT_TAGS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case GET_CONTENT_TAGS_SUCCESS: {
      const items = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        items
      };
    }

    case CREATE_CONTENT_TAG:
    case UPDATE_CONTENT_TAG:
    case DELETE_CONTENT_TAG: {
      return { ...state, loading: true };
    }

    case CREATE_CONTENT_TAG_SUCCESS: {
      return { ...state, loading: false };
    }

    case UPDATE_CONTENT_TAG_SUCCESS: {
      const updatedTag = action.payload;
      const updatedIndex = state.items.findIndex(el => el.id === updatedTag.id);
      const items = [
        ...state.items.slice(0, updatedIndex),
        updatedTag,
        ...state.items.slice(updatedIndex + 1)
      ];

      return {
        ...state,
        loading: false,
        items
      };
    }

    case DELETE_CONTENT_TAG_SUCCESS: {
      const deleteId = action.payload;
      const items = state.items.filter(tag => tag.id !== deleteId);

      return {
        ...state,
        loading: false,
        error: null,
        items
      };
    }

    default: {
      return state;
    }
  }
}

export const getContentTagsState = (state) => state.contentTags;
export const getContentTags = createSelector(getContentTagsState, contentTags => contentTags.items);
export const getContentTagsLoading = createSelector(getContentTagsState, contentTags => contentTags.loading);
export const getContentTagsLoaded = createSelector(getContentTagsState, contentTags => contentTags.loaded);
