import { flatten } from 'lodash-es';
import { WidgetTypeFieldConfiguration } from '../widget-types/widget-types.model';

export enum FieldGroupType {
  SimpleGroup = 'SimpleGroup',
  ExpansionPanel = 'ExpansionPanel',
  Accordion = 'Accordion',
  Stepper = 'Stepper',
}

export const FieldGroupTypeReverseMap = Object.entries(FieldGroupType).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {}
);

export const FieldGroupTypeLabels = {
  [FieldGroupType.SimpleGroup]: $localize`Simple Group`,
  [FieldGroupType.ExpansionPanel]: $localize`Expansion Panel`,
  [FieldGroupType.Accordion]: $localize`Accordion`,
  [FieldGroupType.Stepper]: $localize`Stepper`,
};

export function typeHasMultipleSteps(type: FieldGroupType) {
  return {
    [FieldGroupType.SimpleGroup]: false,
    [FieldGroupType.ExpansionPanel]: false,
    [FieldGroupType.Accordion]: true,
    [FieldGroupType.Stepper]: true,
  }[type];
}

export interface FieldGroup {
  id: number;
  error?: any;
  name: string;
  type: FieldGroupType;
  fieldDefinitions: FieldGroupDefinition[];
  groupOutputs: { [key: string]: boolean };
  propertyMapping: {
    mapToRoot: string[];
  };
}

export interface FieldGroupDefinition {
  label: string;
  fields: WidgetTypeFieldConfiguration[];
}

export function flattenFieldGroupDefinitions(fieldDefinitions: FieldGroupDefinition[]) {
  const allFieldsPerStep = fieldDefinitions.map(stepFieldDefinition => stepFieldDefinition.fields);
  return flatten(allFieldsPerStep);
}

export function getAllFieldGroupFieldKeys(cfg: FieldGroup) {
  return cfg.fieldDefinitions.reduce((acc, fgd: FieldGroupDefinition) => {
    return [...acc, ...getAllFieldGroupDefinitionKeys(fgd)];
  }, []);
}

export function getAllFieldGroupDefinitionKeys(fgd: FieldGroupDefinition) {
  return fgd.fields.reduce((acc, field: WidgetTypeFieldConfiguration) => {
    acc.push(field.key);
    return acc;
  }, []);
}
