import { UnsafeAction } from '../unsafe-action.interface';

export const GET_REDIRECTS = '[Redirects] Get Redirects';
export class GetRedirectsAction implements UnsafeAction {
  readonly type = GET_REDIRECTS;
  constructor(public payload: any = {}) { }
}

export const GET_REDIRECTS_SUCCESS = '[Redirects] Get Redirects success';
export class GetRedirectsSuccessAction implements UnsafeAction {
  readonly type = GET_REDIRECTS_SUCCESS;
  constructor(public payload: any) { }
}

export const CREATE_REDIRECT = '[Redirect] Create Redirect';
export class CreateRedirectAction implements UnsafeAction {
  readonly type = CREATE_REDIRECT;
  constructor(public payload: any) { }
}

export const CREATE_REDIRECT_SUCCESS = '[Redirect] Create Redirect success';
export class CreateRedirectSuccessAction implements UnsafeAction {
  readonly type = CREATE_REDIRECT_SUCCESS;
  constructor(public payload: any) { }
}

export const UPDATE_REDIRECT = '[Redirect] Update Redirect';
export class UpdateRedirectAction implements UnsafeAction {
  readonly type = UPDATE_REDIRECT;
  constructor(public payload: any) { }
}

export const UPDATE_REDIRECT_SUCCESS = '[Redirect] Update Redirect success';
export class UpdateRedirectSuccessAction implements UnsafeAction {
  readonly type = UPDATE_REDIRECT_SUCCESS;
  constructor(public payload: any) { }
}

export const DELETE_REDIRECT = '[Redirect] Delete Redirect';
export class DeleteRedirectAction implements UnsafeAction {
  readonly type = DELETE_REDIRECT;
  constructor(public payload: number) { }
}

export const DELETE_REDIRECT_SUCCESS = '[Redirect] Delete Redirect success';
export class DeleteRedirectSuccessAction implements UnsafeAction {
  readonly type = DELETE_REDIRECT_SUCCESS;
  constructor(public payload: number) { }
}

export const SET_REDIRECTS_LOADING_FLAG = '[Redirect] Set redirects loading flag';
export class SetRedirectsLoadingFlagAction implements UnsafeAction {
  readonly type = SET_REDIRECTS_LOADING_FLAG;
  constructor(public payload: any) { }
}

export const REDIRECT_ACTION_FAILED = '[Redirect] Generic failure action';
export class RedirectFailedAction implements UnsafeAction {
  readonly type = REDIRECT_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[Redirect] Set page view options';
export class SetPageViewOptions implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}
