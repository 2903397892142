import { flatten } from 'lodash-es';

export const fieldTypeToContentTypeOptions = {
  mediaSelect: [
    { value: 'files', label: $localize`File` },
    { value: 'galleries', label: $localize`Gallery` },
    { value: 'images', label: $localize`Image` },
  ],
  other: [
    { value: 'articleTypes', label: $localize`Article Type` },
    { value: 'articles', label: $localize`Article` },
    { value: 'authors', label: $localize`Author` },
    { value: 'collectionTypes', label: $localize`Collection Type` },
    { value: 'collections', label: $localize`Collection` },
    { value: 'contentTags', label: $localize`Content Tag` },
    { value: 'customFieldGroups', label: $localize`Custom Field Group` },
    { value: 'htmlSnippets', label: $localize`HTML Snippet` },
    { value: 'liveReports', label: $localize`Live Report` },
    { value: 'contentLocales', label: $localize`Locale`},
    { value: 'menus', label: $localize`Menu` },
    { value: 'taxonomies', label: $localize`Taxonomy` },
  ],
};

export const allCmsDataTypeOptions = flatten(Object.values(fieldTypeToContentTypeOptions));

export const cmsDataTypeLabelMap = allCmsDataTypeOptions.reduce((acc, dataType) => {
  acc[dataType.value] = dataType.label;
  return acc;
}, {});
