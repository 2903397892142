import { mergeMap, tap, map, filter, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PagesService } from '../../api/pages/pages.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  CREATE_PAGE,
  CreatePageSuccessAction,
  UPDATE_PAGE,
  DeletePageCompletedAction,
  PagesFailureAction,
  DELETE_PAGE,
  GET_PAGES,
  GetPagesSucessAction,
  UpdatePageSuccessAction,
  PUBLISH_PAGE,
  UNPUBLISH_PAGE,
  PAGE_FAILED_ACTION,
  ClearPagesStateAction,
} from './pages.actions';
import { Page } from './pages.model';
import { ACCOUNT_CHANGED } from '../auth/auth.actions';

// TODO Double check

@Injectable()
export class PageEffects {
  // TODO handle account change


  createNewPage$: Observable<UnsafeAction> = createEffect(() => this.actions$.pipe(
    ofType(CREATE_PAGE),
    mergeMap((action: UnsafeAction) => {
      return this.pageService.createPage(action.payload).pipe(
        tap((page: Page) => {
          this.snackBar.open($localize`Page saved`, $localize`Close`, { duration: 4000 });
          this.router.navigate(['site-builder/pages/', page && page.id]);
        }),
        map((payload: Page) => new CreatePageSuccessAction(payload)),
        catchError((e) => of(new PagesFailureAction({ error: e, action })))
      ) as Observable<UnsafeAction>;
    })
  ));


  updatePage$: Observable<UnsafeAction> = createEffect(() => this.actions$.pipe(ofType(UPDATE_PAGE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.pageService.updatePage(action.payload).pipe(
        tap((newPage: Page) => {
          this.snackBar.open($localize`Page saved`, $localize`Close`, { duration: 4000 });
        }),
        map((updatedPage: Page) => new UpdatePageSuccessAction(updatedPage)),
        catchError((e) => of(new PagesFailureAction({ error: e, action })))
      ) as Observable<UnsafeAction>;
    })
  ));


  loadPage$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(GET_PAGES)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.pageService.getPages().pipe(
        map((pages) => new GetPagesSucessAction(pages)),
        catchError((e) => of(new PagesFailureAction({ error: e, action })))
      ) as Observable<UnsafeAction>;
    })
  ));


  deletePage$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_PAGE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.pageService.deletePage(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Page deleted`, $localize`Close`, { duration: 4000 })),
        map(() => new DeletePageCompletedAction(action.payload)),
        catchError((e) => of(new PagesFailureAction({ error: e, action })))
      );
    })
  ));


  publishPage$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(PUBLISH_PAGE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.pageService.publishPage(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Page published`, $localize`Close`, { duration: 4000 })),
        map((publishedPage) => new UpdatePageSuccessAction(publishedPage)),
        catchError((e) => of(new PagesFailureAction({ error: e, action })))
      ) as Observable<UnsafeAction>;
    })
  ));


  unpublishPage$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UNPUBLISH_PAGE)).pipe(
    mergeMap((action: UnsafeAction) => {
      return this.pageService.unpublishPage(action.payload).pipe(
        tap(() => this.snackBar.open($localize`Page unpublished`, $localize`Close`, { duration: 4000 })),
        map((unpublishedPage) => new UpdatePageSuccessAction(unpublishedPage)),
        catchError((e) => of(new PagesFailureAction({ error: e, action })))
      ) as Observable<UnsafeAction>;
    })
  ));

  accountChanged$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(ACCOUNT_CHANGED))
    .pipe(map(() => new ClearPagesStateAction())));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(PAGE_FAILED_ACTION)).pipe(
    tap((err: any) => {
      console.log(err);
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}`, $localize`Close`, { duration: 4000 });
    }),
    filter((err) => false),
    map((err) => ({ type: 'NULL_ACTION' }))
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    public snackBar: MatSnackBar,
    private pageService: PagesService
  ) {}
}
