import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'gd-info-dialog',
  templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent {
  public title: string;
  public message: string;

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {}
}
