import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlideCreateRoutingModule } from './glide-create-routing.module';

@NgModule({
  imports: [
    CommonModule,
    GlideCreateRoutingModule
  ],
  declarations: [],
  exports: [GlideCreateRoutingModule]
})
export class GlideCreateModule { }
